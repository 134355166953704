<template>
  <div class="py-2 puntofarmaCard h-100 px-2 p-xl-4">
    <h5 class="pl-4 text-left text-capitalize">{{ title }}</h5>
    <canvas :id="idappend"></canvas>
  </div>
</template>
<script>
import { Chart, registerables } from "chart.js";
import "chartjs-adapter-moment"; // or another adapter to avoid moment
Chart.register(...registerables);
export default {
  name: "LineChart",
  props: ["idappend", "datatoshow", "title"],
  data() {
    return {
      chartData: {
        type: "line",
        data: {
          labels: [
            "Gennaio",
            "Febbraio",
            "Marzo",
            "Aprile",
            "Maggio",
            "Giugno",
            "Luglio",
            "Agosto",
            "Settembre",
            "Ottobre",
            "Novembre",
            "Dicembre",
          ],
          datasets: [
            {
              label: "Ordini al mese",
              // data: [650, 590, 800, 810, 560, 55, 400],
              data: this.datatoshow,
              fill: false,
              // borderColor: "rgb(116, 103, 240)",
              // backgroundColor: "rgb(116, 103, 240)",
              borderColor: "#145c8c",
              backgroundColor: "#145c8c",
              tension: 0.1,
            },
          ],
        },
      },
    };
  },
  mounted() {
    const ctx = document.getElementById(this.idappend);
    new Chart(ctx, this.chartData);
  },
};
</script>