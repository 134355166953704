<template>
  <div class="form pb-2">

    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col cols="12" class="mb-2" v-if="editing">
          <b-form-group label="Tipo di contatto" label-for="contacttypes" class="text-left">
            <b-form-radio-group v-model="form.type" name="contacttypes-options" class="d-flex my-3">
              <b-form-radio v-for="contactType in emergencyContactTypes" :value="contactType.value"
                :key="contactType.label" :disabled="!editing">{{ contactType.label }}</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <template v-if="form.type === 0">
          <b-col cols="12" md="6">
            <b-form-group label="Nome*" label-for="name" class="text-left">
              <b-form-input class="my-3 text-capitalize do_primary" name="name" type="text" placeholder="nome..."
                v-model="form.name" required :readonly="!editing"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Cognome*" label-for="surname" class="text-left">
              <b-form-input class="my-3 text-capitalize do_primary" name="surname" type="text" placeholder="cognome..."
                v-model="form.surname" required :readonly="!editing"></b-form-input>
            </b-form-group>
          </b-col>
        </template>
        <b-col v-else cols="12">
          <b-form-group label="Denominazione*" class="text-left">
            <b-form-input class="my-2 do_primary" type="text" v-model="form.name" required
              :readonly="!editing"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row cols="1" cols-md="2" cols-lg="2" cols-xl="2">
        <b-col>
          <b-form-group label="Relazione contatto*" label-for="relationship" class="text-left">
            <b-form-select v-if="editing" class="my-3 custom-select" :options="emergencyContactRelationshipTypes" name="relationship"
              v-model="form.relationship" :disabled="!editing">
              <b-form-select-option :value="null">Seleziona tipo relazione</b-form-select-option>
            </b-form-select>
            <b-form-input v-else  class="my-3" type="text" v-model="form.relationship" required
            readonly></b-form-input>
          </b-form-group>
        </b-col>
        <template v-if="editing">
          <b-col>
            <b-form-group label="Regione*" label-for="regione" class="text-left">
              <b-form-select class="my-3 custom-select text-capitalize" :options="location.regioni" name="regione"
                v-model="selected.regione" @change="getProvince(selected)" :disabled="!editing">
                <b-form-select-option :value="null">Seleziona Regione</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Provincia*" label-for="provincia" class="text-left">
              <b-form-select class="my-3 text-capitalize" :options="location.province" name="provincia" :disabled="!editing"
                v-model="selected.provincia" @change="getComuni(selected)">
                <b-form-select-option :value="null">Seleziona Provincia</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        <b-col>
          <b-form-group label="Comune*" label-for="comune" class="text-left">
            <b-form-select class="my-3 text-capitalize" name="comune" v-model="selected.comune" :disabled="!editing">
              <b-form-select-option v-for="(com, ind) in location.comuni" :key="ind" :value="com" @change="setDomicilioData"
                :selected="findComune(com)">{{ com.comune }}</b-form-select-option>
              <b-form-select-option :value="null">Seleziona Comune</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        </template>
        <b-col v-else>
          <b-form-group label="Comune" label-for="comune" class="text-left">
            <b-form-input class="my-3 text-capitalize" name="comune" type="text" placeholder="comune..."
              :readonly="!editing" v-model="form.comune"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Indirizzo" label-for="address" class="text-left">
            <b-form-input class="my-3 text-capitalize" name="address" type="text" placeholder="indirizzo..."
              :readonly="!editing" v-model="form.address"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Cap" label-for="cap" class="text-left">
            <b-form-input class="my-3" name="cap" type="text" placeholder="cap..." v-model="form.cap" minlength="5"
              :readonly="!editing" maxlength="5"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Numero di telefono*" label-for="tel_number" class="text-left general_border">
            <b-form-input class="my-3" name="tel_number" type="text" placeholder="telefono..." v-model="form.tel_number"
              :readonly="!editing" minlength="9" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col class="text-right align-self-end" v-if="!editing">
          <b-button type="button" size="sm" variant="outline-danger modal-footer-button mx-1 my-1  my-xl-auto" @click="$bvModal.show('confirm-delete'+contact.hashid)">
            Elimina
          </b-button>
          <b-button type="button" size="sm" variant="outline-primary modal-footer-button my-1  my-xl-auto" @click="editing=true">
            Modifica
          </b-button>
        </b-col>
      </b-row>
      <p v-if="editing" class="mb-0 w-100 text-right pt-3">
        <b-button type="button" variant="outline-danger general_border mx-2"
          @click.prevent="handleAnnulla()">Annulla</b-button>
        <b-button type="submit" variant="outline-primary general_border mx-2">Salva Modifiche
        </b-button>
      </p>
    </b-form>

    <b-modal :id="'confirm-delete'+contact.hashid" size="lg" centered title="Conferma eliminazione" button-size="sm"
      cancel-title="No, Annulla" cancel-variant="info mr-2" ok-title="Sì, Conferma" ok-variant="outline-danger"
      footer-class="border-0" @ok="deleteContact" @cancel="$bvModal.hide('confirm-delete'+contact.hashid)">
      Confermi di voler eliminare i dati di contatto di {{contact.name}}  {{contact.surname}}?
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { cittadinoService, utilityService } from "@/_services";
import { mapState, mapActions } from "vuex";
export default {
  name: "EditEmergencyContact",
  props: ["contact"],
  data() {
    return {
      selected: {
        regione: null,
        provincia: null,
        comune: null,
      },
      form: {
        user_id: this.hashid,
        type: 0,
        relationship: null,
        regione: null,
        provincia: null,
        comune: null,
        cap: null,
        address: null,
        name: null,
        surname: null,
        tel_number: null,
      },
      location: {
        comuni: null,
        province: null,
        regioni: null
      },
      emergencyContactRelationshipTypes: [
        "familiare", "amico", "assistenza", "personale medico", "personale sanitario", "altro",
      ],
      emergencyContactTypes: [
        { label: "persona fisica", value: 0 },
        { label: "luogo", value: 1 },
      ],
      editing: false
    };
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),

    getRegioni() {
      const self = this
      utilityService.regioni()
        .then(response => {
          // console.log("regioni success-store",response)
          self.location.regioni = response.data.data
        })
        .catch(error => {

        })
    },
    getProvince(form) {
      const self = this
      utilityService.province(form)
        .then(response => {
          // console.log("regioni success-store",response)
          self.location.province = response.data.data
        })
        .catch(error => {

        })
    },
    getComuni(form) {
      const self = this
      utilityService.comuni(form)
        .then(response => {
          // console.log("regioni success-store",response)
          self.location.comuni = response.data.data
        })
        .catch(error => {

        })
    },
    setDomicilioData() {
      if (this.selected.comune) {
        this.form.comune = this.selected.comune.comune;
        this.form.provincia = this.selected.comune.provincia;
        this.form.regione = this.selected.comune.regione;
      }
    },
    onSubmit() {
      console.log("submit", this.form);
      const self = this;
      cittadinoService.updateEmergencyContact(this.form).then(function (res) {
        self.successAlert("Contatto Aggiornato correttamente");
        self.$emit('saved-contact')
        self.editing = false
      })
        .catch(function (err) {
          console.log(err);
          self.errorAlert("Errore nel salvataggio delle modifiche");
        });
    },
    findComune(comune) {
      if (this.form && this.form.comune) {
        if (comune.comune === this.form.comune) {
          this.selected.comune = comune;
          return true;
        }
      }
      return false;
    },
    handleAnnulla() {
      this.editing = false
      this.setUp()
    },
    setUp() {
      this.form = this.contact
    },
    deleteContact() {
      const self = this;
      cittadinoService.deleteEmergencyContact(this.contact.hashid).then(function (res) {
        self.successAlert("Contatto eliminato correttamente");
        self.$emit('saved-contact')
      })
        .catch(function (err) {
          console.log(err);
          self.errorAlert("Non è stato possibile eliminare il contatto");
        });
    },
    setupPrevs() {
      if (this.contact.regione) {
        this.selected.regione = this.contact.regione;
        this.getProvince(this.selected);
      }
      if (this.contact.provincia) {
        this.selected.provincia = this.contact.provincia;
        this.getComuni(this.selected);
      }
    },
  },
  watch: {
    selected: {
      handler(val) {
        if (val.comune !== null) {
          this.form.comune = this.selected.comune.comune;
          this.form.provincia = this.selected.comune.provincia;
          this.form.regione = this.selected.comune.regione;
        }
      },
      deep: true,
    },
  },

  created() {
    this.getRegioni();
    this.setUp()
    this.setupPrevs()
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
input.form-control:read-only {
  border-bottom: 1px solid darkgrey;
  border-radius: 0px !important;
  box-shadow: none !important;
}

input.form-control.do_primary:read-only {
  border-bottom: 1px solid darkgrey;
  border-radius: 0px !important;
  box-shadow: none !important;
  color: $primary !important;
  font-weight: 600;
}

// select.custom-select:read-only {
//   color: $primary !important;
// }
</style>