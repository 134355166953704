<template>
  <div
    v-if="info.subjectable.company"
    id="requested-by-company-header"
    class="m-0 p-4 detail-modal-card text-left"
  >
    <b-row>
      <b-col
        class="d-flex justify-content-center"
        id="requested-by-company-first-col"
      >
        <span class="d-flex justify-content-center">
          <b-avatar
            :src="info.subjectable.profile_pic"
            size="66px"
            variant="white"
            class="general_border"
            rounded="lg"
          ></b-avatar>
        </span>
      </b-col>
      <b-col class="ml-auto flex-grow-1 pl-3">
        <b-row cols="1" cols-sm="2">
          <b-col
            class="d-flex flex-column flex-nowrap justify-content-start px-0"
          >
            <p class="mb-0 font-weight-bolder detail-modal-card-titles">
              NOME E COGNOME
            </p>
            <p class="font-size-12 mb-1 mg-lg-2">
              {{ info.subjectable.anagrafica.name }}
              {{ info.subjectable.anagrafica.surname }}
            </p>
          </b-col>
          <b-col
            class="d-flex flex-column flex-nowrap justify-content-start px-0"
          >
            <p class="mb-0 font-weight-bolder detail-modal-card-titles">
              DENOMINAZIONE
            </p>
            <p class="font-size-12 mb-1 mg-lg-2">
              {{ info.subjectable.company.denominazione }}
            </p>
          </b-col>
        </b-row>
        <b-row cols="1" cols-sm="2">
          <b-col
            class="d-flex flex-column flex-nowrap justify-content-start px-0"
          >
            <p class="mb-0 font-weight-bolder detail-modal-card-titles">
              EMAIL
            </p>
            <p class="font-size-12 mb-1 mg-lg-2 long__txt">
              {{ info.subjectable.company.email }}
            </p>
          </b-col>
          <b-col
            class="d-flex flex-column flex-nowrap justify-content-start px-0"
          >
            <p class="mb-0 font-weight-bolder detail-modal-card-titles">
              TELEFONO
            </p>
            <p class="font-size-12 mb-1 mg-lg-2">{{ info.subjectable.company.telefono }}</p>
          </b-col>
        </b-row>
        <b-row cols="1">
          <b-col
            class="d-flex flex-column flex-nowrap justify-content-start px-0"
          >
            <p class="mb-0 font-weight-bolder detail-modal-card-titles">
              INDIRIZZO
            </p>
            <p class="font-size-12 mb-1 mg-lg-2">
              {{ info.subjectable.company.indirizzo }}
              {{ info.subjectable.company.cap }}
              {{ info.subjectable.company.comune }}
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "RequestedByCompanyInfoCardComponent",
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {}
}
</script>
<style scoped>
#requested-by-company-header {
  width: 100%;
}

#requested-by-company-first-col {
  max-width: 80px;
}

@media (max-width: 1199px) {
  #requested-by-company-header {
    max-width: 700px;
  }
}

@media (min-width: 1200px) {
  #requested-by-company-header {
    max-width: 450px;
  }
}

@media (min-width: 1201px) and (max-width: 1260px) {
  #requested-by-company-header {
    max-width: 430px;
  }
}

@media (min-width: 1400px) {
  #requested-by-company-header {
    max-width: 500px;
  }
}

@media (min-width: 2000px) {
  #requested-by-company-header {
    max-width: 800px;
  }
}
</style>
