<template>
  <div class="pb-4">
    <b-row cols="1">
      <b-col class="px-0 mb-3 px-xl-4">
        <h4 class="text-left pb-1 page-title">Operatori Azienda</h4>
        <!-- <p class="text-justify pr-4">
          Per abilitare una nuova utenza alla gestione dei tuoi servizi
          DottorFarma puoi inviare un invito via email contenente il codice
          azienda necessario per la sua registrazione.
        </p> -->
        <div
          v-if="operators"
          class="d-flex mr-auto w-100"
          :style="$root.isMobile ? 'overflow-x:scroll;' : ''"
        >
          <b-row
            align-v="stretch"
            class="d-flex flex-nowrap justify-content-start general_border_17"
            id="operators-row"
          >
            <b-col
              v-for="(operator, ind) in operators"
              :key="ind"
              class="pl-0 my-2 text-white general_border_17"
            >
              <div
                id="operator-card"
                class="puntofarmaCard d-flex flex-column w-100 h-100 general_border_17"
              >
                <div
                  class="d-flex justify-content-center p-3 w-100 general_border_17"
                  :class="{ 'bg-info': !operator.profile_pic }"
                >
                  <b-avatar
                    :src="operator.profile_pic"
                    size="7rem"
                    :variant="operator.profile_pic ? 'white' : 'info'"
                    rounded="sm"
                  ></b-avatar>
                </div>

                <section
                  class="bg-secondary general_border_17 w-100 p-3 font-size-08rem flex-grow-1"
                >
                  <h3 class="font-weight-bolder">
                    {{ operator.fullname ? operator.fullname : " -- " }}
                  </h3>
                  <p class="my-1">
                    <span class="font-weight-bolder">Stato:</span>
                    <span>
                      {{ operator.enabled == 1 ? "Abilitato" : "Disabilitato" }}
                    </span>
                  </p>
                  <p class="mb-1">
                    <span class="font-weight-bolder">Ruolo: </span>
                    <span class="text-capitalize">{{
                      operator.ruolo ? operator.ruolo : "--"
                    }}</span>
                  </p>
                  <p class="mb-1 px-4 d-flex justify-content-center">
                    <span class="font-weight-bolder mr-2">Mail: </span>
                    <span class="long__txt"> {{ operator.email }}</span>
                  </p>
                  <div
                    class="d-flex flex-column align-items-center justify-content-center"
                  >
                    <span class="bg-white general_border my-2">
                      <b-button
                        size="sm"
                        class="general_border operatore-action-button"
                        :variant="
                          operator.enabled == 1
                            ? 'outline-danger'
                            : 'outline-success'
                        "
                        @click="selectThis(ind)"
                        >{{
                          operator.enabled == 1 ? "disabilita" : "abilita"
                        }}</b-button
                      >
                    </span>
                    <span class="bg-white general_border">
                      <b-button
                        size="sm"
                        class="general_border operatore-action-button"
                        variant="outline-primary"
                        @click="selectThisForRole(ind)"
                        >modifica ruolo</b-button
                      >
                    </span>
                  </div>
                </section>
              </div>
            </b-col>
          </b-row>
          <p v-if="operators.length == 0" class="text-justify my-2">
            Nessun operatore presente. Per aggiungere un nuovo operatore scorri
            questa pagina fino a "Codice Azienda".
          </p>
        </div>
      </b-col>
      <b-col class="px-0 mb-3 px-xl-4">
        <h4 class="text-left pb-1">Ruoli disponibili</h4>
        <div class="genera_border">
          <b-row
            cols="1"
            class="my-3 bg-white p-3 general_border shadow_2"
            align-v="center"
          >
            <b-col>
              <h6 class="text-left">Amministratore</h6>
            </b-col>
            <b-col>
              <p class="text-justify">
                L'amministratore può visualizzare e gestire tutte le sezioni.
                Può visualizzare il pannello impostazioni e tutte le sue
                sotto-sezioni, gestire tutti i servizi e tutte le richieste,
                oltre a poter scaricare i report dei servizi di prenotazione e
                di screening.
              </p>
            </b-col>
          </b-row>
          <b-row
            cols="1"
            align-v="center"
            class="mb-3 bg-white p-3 general_border shadow_2"
          >
            <b-col>
              <h6 class="text-left">Operatore Avanzato</h6>
            </b-col>
            <b-col>
              <p class="text-justify">
                L'operatore avanzato può visualizzare e gestire tutte le
                richieste del servizio di prenotazione e può anche visualizzare
                e modificare le impostazioni dei servizi (sezione "Gestione
                Servizi" delle impostazioni). Può somministrare questionari,
                creare e gestire raccolte e questionari. Non può visualizzare la
                sezione "Operatori" né la scheda anagrafica dell'azienda.
              </p>
            </b-col>
          </b-row>
          <b-row
            cols="1"
            align-v="center"
            class="bg-white p-3 general_border shadow_2"
          >
            <b-col>
              <h6 class="text-left">Operatore</h6>
            </b-col>
            <b-col>
              <p class="text-justify">
                L'operatore può visualizzare e gestire tutte le richieste del
                servizio di prenotazione, visualizzare raccolte e questionari e
                somministrare questionari di screening. Non può creare né
                gestire raccolte e questionari, non può visualizzare le
                impostazioni né tutte le sotto-sezioni di "impostazioni". Questo
                ruolo è quello assegnato come default a tutte le utenze dei
                dipendenti registratesi tramite codice azienda.
              </p>
            </b-col>
          </b-row>
          <div class="text-left"></div>
        </div>
      </b-col>
    </b-row>
    <b-modal
      hide-footer
      title="Conferma Operazione"
      ref="stat-confirm"
      size="md"
      centered
    >
      <div v-if="selectedIndex !== null">
        <br />
        <h5 class="text-center">
          Sei sicuro di voler
          {{
            operators[selectedIndex].enabled == 1 ? "disattivare" : "attivare"
          }}
          l'utenza di
          {{
            operators[selectedIndex].fullname !== null
              ? operators[selectedIndex].fullname
              : operators[selectedIndex].email
          }}
          ?
        </h5>
        <br />
        <div class="text-right">
          <b-button
            variant="info modal-footer-button mr-3"
            @click="$refs['stat-confirm'].hide()"
            >No, annulla</b-button
          >

          <b-button
            variant="outline-primary modal-footer-button"
            @click="submitChange"
            >Sì, procedi</b-button
          >
        </div>
      </div>
    </b-modal>

    <b-modal
      hide-footer
      title="Modifica Ruolo"
      ref="role-change"
      size="md"
      centered
    >
      <div v-if="selectedIndex !== null">
        <br />
        <h5 class="text-left">
          Seleziona il ruolo che vuoi assegnare a
          {{
            operators[selectedIndex].fullname !== null
              ? operators[selectedIndex].fullname
              : operators[selectedIndex].email
          }}:
        </h5>
        <br />
        <div class="my-2 px-2">
          <b-form-select
            class=" "
            v-model="selectedRole"
            :options="roleOptions"
          ></b-form-select>
        </div>
        <br />
        <div class="text-right pt-3">
          <b-button
            variant="info mr-3 modal-footer-button"
            @click="$refs['role-change'].hide()"
            >Annulla</b-button
          >

          <b-button
            variant="outline-primary modal-footer-button"
            @click="submitRoleChange"
            >Procedi</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { companyService } from "@/_services"
import { mapState, mapActions } from "vuex"
export default {
  name: "OperatorsList",
  data() {
    return {
      operators: null,
      selectedIndex: null,
      selectedRole: null,
      roleOptions: [
        { value: "operatore", text: "operatore" },
        { value: "operatore avanzato", text: "operatore avanzato" },
        { value: "amministratore", text: "amministratore" }
      ]
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    })
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    fetchData() {
      var self = this
      companyService
        .getOperatorsList()
        .then(function (resp) {
          // console.log(resp);
          self.operators = resp.data.data
          self.$emit("total-count", self.operators.length)
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0)
          self.errorAlert(
            "non è stato possibile recuperare la lista di operatori"
          )
        })
    },
    selectThis(ind) {
      this.selectedIndex = ind
      this.$refs["stat-confirm"].show()
    },
    selectThisForRole(ind) {
      this.selectedIndex = ind
      if (this.operators[this.selectedIndex].ruolo !== null) {
        this.selectedRole = this.operators[this.selectedIndex].ruolo
      }
      this.$refs["role-change"].show()
    },
    submitChange() {
      var form = {
        hash: this.operators[this.selectedIndex].hashid,
        form: { enabled: !this.operators[this.selectedIndex].enabled }
      }
      var self = this
      companyService
        .changeOperatorStatus(form)
        .then(function (res) {
          self.$refs["stat-confirm"].hide()
          window.scrollTo(0, 0)
          self.successAlert("Stato operatore aggiornato con successo!")
          self.fetchData()
        })
        .catch(function (err) {
          self.$refs["stat-confirm"].hide()
          window.scrollTo(0, 0)
          self.errorAlert(
            "non è stato possibile modificare lo stato dell'operatore"
          )
        })
    },
    submitRoleChange() {
      var form = {
        hash: this.operators[this.selectedIndex].hashid,
        ruolo: this.selectedRole
      }
      var self = this
      companyService
        .changeOperatorRole(form)
        .then(function (res) {
          self.$refs["role-change"].hide()
          window.scrollTo(0, 0)
          self.successAlert("Ruolo operatore aggiornato con successo!")
          self.fetchData()
        })
        .catch(function (err) {
          self.$refs["role-change"].hide()
          window.scrollTo(0, 0)
          self.errorAlert(
            "non è stato possibile modificare il ruolo dell'operatore"
          )
        })
    }
  },
  created() {
    this.fetchData()
  }
}
</script>

<style lang="scss">
#operators-row {
  overflow-x: auto;

  .col {
    flex-grow: 0;
    margin-right: 1rem;
  }
}

.font-size-07rem {
  font-size: 0.7rem;
}

.operatore-action-button {
  min-width: 150px;
}

#operator-card {
  min-width: 300px;
  max-width: 300px;
}
</style>
