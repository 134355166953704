<template>
  <div class="pt-2">
    <h3 class="mt-2 pt-4 text-left">Accessi e Notifiche</h3>

    <p class="text-left">
      Visualizza e gestisci i tuoi accessi, e scegli quali notifiche riceverere
      nei canali di Puntofarma.
    </p>

    <b-row class="puntofarmaCard">
      <b-col class="col-12 col-md-4 pl-3">
        <!-- <h6 class="text-left fw-bolder px-3">Canale Mail e Push</h6> -->
        <div class="py-2 px-1 mt-3">
          <h6 class="text-left fw-bolder">Notifiche Email</h6>
          <b-row cols="1" class="text-left pb-2">
            <b-col class="my-2 col-12 px-0">
              <b-form-checkbox
                @change="editUserConsense(false)"
                v-model="mailEnabled"
                name="mail-active"
                :value="true"
                switch
              >
                Ricezione notifiche
                {{ mailEnabled == true ? "attiva" : "disattivata" }}
              </b-form-checkbox>
            </b-col>
            <h6 class="text-left fw-bolder mt-3">Notifiche Push</h6>
            <b-col class="my-2 col-12 px-0" v-if="user.push_token">
              <b-form-checkbox
                @change="editUserConsense(true)"
                v-model="pushEnabled"
                name="mail-active"
                :value="true"
                switch
              >
                Ricezione notifiche
                {{ pushEnabled == true ? "attiva" : "disattivata" }}
              </b-form-checkbox>
            </b-col>
            <b-col class="my-2 px-0" v-else>
              Concedi consenso alla ricezione di notifiche push per ricevere
              notifiche in app
            </b-col>
          </b-row>
        </div>
      </b-col>
      <template v-if="bots">
        <b-col class="col-12 col-md-4 mt-4 pl-3">
          <h6 class="text-left fw-bolder px-3">Canale Telegram</h6>
          <b-row class="text-left">
            <b-col
              v-for="(bot, ind) in bots['']"
              :key="'tg' + ind"
              class="my-2 col-12 px-0"
            >
              <b-form-group class="p-4">
                <label for="">Username: {{ bot.bot_username }}</label>
                <b-form-checkbox
                  @change="showModalConfirm(bot, false)"
                  v-model="bot.active"
                  :name="ind + 'active'"
                  :value="1"
                  switch
                >
                  Ricezione notifiche
                  {{ bot.active == true ? "attiva" : "disattivata" }}
                </b-form-checkbox>
                <b-button
                  class="text-center mx-auto my-2"
                  variant="outline-danger "
                  size="sm"
                  @click="showModalConfirm(bot, true)"
                >
                  <span>
                    <b-icon icon="trash" class="ml-1 mr-2"></b-icon> Rimuovi
                    questo accesso
                  </span>
                </b-button>
              </b-form-group>
            </b-col>
            <b-col v-if="!bots[''] || bots[''].length < 1" class="col-12 px-0">
              <b-form-group class="my-2 p-4">
                <label for="">Nessun accesso Telegram attivo</label><br />
                <a
                  :href="tgLink"
                  target="_blank"
                  class="fw-bolder text-prim-grad-1 d-flex align-items-center"
                >
                  <b-iconstack font-scale="1.5" class="mr-2">
                    <b-icon
                      stacked
                      icon="reply-fill"
                      scale="0.7"
                      shift-v="0.5"
                      shift-h="0.5"
                      variant="primary"
                    >
                    </b-icon>
                    <b-icon stacked icon="circle" variant="primary"></b-icon>
                  </b-iconstack>
                  <span>Accedi su Telegram!</span>
                </a>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <!-- <b-col class="col-12 col-md-4 mt-4 pl-3">
          <h6 class="text-left fw-bolder px-3">Canale Whastapp</h6>
          <b-row class="text-left">
            <b-col v-for="(bot, ind) in bots['WB']" :key="'wa' + ind" class="my-2 col-12 px-0">
              <b-form-group class="puntofarmaCard p-4">
                <label for="">Numero: {{ bot.bot_username }}</label>
                <b-form-checkbox @change="showModalConfirm(bot, false)" v-model="bot.active" :name="ind + 'active'"
                  :value="1" switch>
                  Ricezione notifiche
                  {{ bot.active == true ? "attiva" : "disattivata" }}
                </b-form-checkbox>
                <b-button class="text-center mx-auto my-2" variant="outline-danger" size="sm"
                  @click="showModalConfirm(bot, true)">
                  <span>
                    <b-icon icon="x-circle" class="ml-1 mr-2"></b-icon> Rimuovi
                    questo accesso
                  </span>
                </b-button>
              </b-form-group>
            </b-col>
            <b-col v-if="!bots['WB'] || bots['WB'].length < 1" class="col-12 px-0">
              <b-form-group class="my-2 puntofarmaCard p-4">
                <label for="">Nessun accesso WhatsApp attivo</label><br />
                <a :href="waLink" target="_blank" class="fw-bolder text-prim-grad-1 d-flex align-items-center">
                  <b-iconstack font-scale="1.5" class="mr-2">
                    <b-icon stacked icon="reply-fill" scale="0.7" shift-v="0.5" shift-h="0.5" variant="secondary">
                    </b-icon>
                    <b-icon stacked icon="circle" variant="secondary"></b-icon>
                  </b-iconstack>
                  <span>Accedi su WhatsApp!</span>
                </a>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col> -->
      </template>
    </b-row>
    <b-modal
      id="delete-access"
      title="Elimina accesso"
      @cancel="resetDelete"
      @hidden="fetchData"
      @ok="handleOkDelete"
      button-size="sm"
      cancel-title="No, Annulla"
      cancel-variant="info mr-2"
      ok-title="Sì, Conferma"
      ok-variant="outline-danger"
      footer-class="border-0"
      centered
    >
      <template v-if="selectedBot">
        <h6>
          Vuoi rimuovere l'accesso via
          {{ selectedBot.messenger == "WP" ? "whatsapp" : "telegram" }} di
          {{ selectedBot.bot_username }} ?
        </h6>
      </template>
    </b-modal>
    <b-modal
      id="update-access"
      title="Modifica stato ricezione notifiche"
      @cancel="resetUpdate"
      @hidden="fetchData"
      @ok="handleOkUpdate"
      button-size="sm"
      cancel-title="No, Annulla"
      cancel-variant="info mr-2"
      ok-title="Sì, Conferma"
      ok-variant="outline-primary"
      footer-class="border-0"
      centered
    >
      <template v-if="selectedBot">
        <h6>
          Vuoi
          {{ selectedBot.active == true ? "attivare" : "disattivare" }}
          la ricezione di notifiche via
          {{ selectedBot.messenger == "WP" ? "whatsapp" : "telegram" }} da
          {{ selectedBot.bot_username }} ?
        </h6>
      </template>
    </b-modal>
    <b-modal
      id="update-consenses"
      title="Conferma operazione"
      @cancel="cancelUpdateConsense"
      @ok="updateConsense"
      button-size="sm"
      cancel-title="No, Annulla"
      cancel-variant="info mr-2"
      ok-title="Sì, Conferma"
      ok-variant="outline-primary"
      footer-class="border-0"
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <template v-if="pushMessageAlert">
        <h6>
          Vuoi
          {{ pushEnabled == true ? "attivare" : "disattivare" }}
          la ricezione di notifiche push?
        </h6>
      </template>
      <template v-else>
        <h6>
          Vuoi
          {{ mailEnabled == true ? "attivare" : "disattivare" }}
          la ricezione di notifiche via email?
        </h6>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex"
import { cittadinoService } from "@/_services"
export default {
  name: "NotificationChannels",
  data() {
    return {
      bots: null,
      mock: {
        bot_username: null,
        active: false,
        deleted_at: true
      },
      selectedBot: null,
      pushMessageAlert: false,
      pushEnabled: false,
      mailEnabled: false,
      waLink: "https://wa.me/" + process.env.VUE_APP_WA_CHANNEL,
      tgLink: "https://t.me/" + process.env.VUE_APP_TG_CHANNEL
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["status", "user"])
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    fetchData() {
      var self = this
      cittadinoService
        .getUserBots()
        .then(function (res) {
          // console.log(res);
          self.bots = res.data.data
        })
        .catch(function (err) {
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare gli accessi")
        })
    },
    showModalConfirm(bot, doDelete) {
      this.selectedBot = bot
      if (doDelete) {
        this.$bvModal.show("delete-access")
      } else {
        this.$bvModal.show("update-access")
      }
    },
    editUserConsense(push) {
      this.pushMessageAlert = push
      this.$bvModal.show("update-consenses")
    },
    resetDelete() {
      this.selectedBot = null
      this.$bvModal.hide("delete-access")
    },
    resetUpdate() {
      this.selectedBot = null
      this.$bvModal.hide("update-access")
    },
    cancelUpdateConsense() {
      if (this.pushMessageAlert) {
        this.pushEnabled = !this.pushEnabled
      } else {
        this.mailEnabled = !this.mailEnabled
      }
    },
    handleOkDelete() {
      var self = this
      cittadinoService
        .deleteBotAccess(this.selectedBot.hashid)
        .then(function (res) {
          window.scrollTo(0, 0)
          self.$bvModal.hide("delete-access")
          self.successAlert("Accesso eliminato con successo")
          self.selectedBot = null
        })
        .catch(function (err) {
          self.$bvModal.hide("delete-access")
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile eliminare l' accesso")
        })
    },
    handleOkUpdate() {
      var self = this
      var form = {
        active: this.selectedBot.active
      }
      cittadinoService
        .updateUserBots(form, this.selectedBot.hashid)
        .then(function (res) {
          window.scrollTo(0, 0)
          self.$bvModal.hide("update-access")
          self.successAlert("Modifiche effettuate con successo")
          self.selectedBot = null
        })
        .catch(function (err) {
          window.scrollTo(0, 0)
          self.$bvModal.hide("update-access")
          self.errorAlert("Non è stato possibile effettuare la modifica")
          self.selectedBot = null
        })
    },
    updateConsense() {
      var self = this
      var form = {
        push_consense: this.pushEnabled,
        notification_consense: this.mailEnabled
      }
      cittadinoService
        .updateConsense(form)
        .then(function (res) {
          window.scrollTo(0, 0)
          self.$bvModal.hide("update-access")
          self.successAlert("Modifiche effettuate con successo")
          self.selectedBot = null
        })
        .catch(function (err) {
          window.scrollTo(0, 0)
          self.$bvModal.hide("update-access")
          self.errorAlert("Non è stato possibile effettuare la modifica")
          self.selectedBot = null
        })
    }
  },
  created() {
    //TEMPORANEAMENTE DISATTIVATO
    // this.fetchData();
  },
  mounted() {
    this.pushEnabled = this.user.push_consense == true
    this.mailEnabled = this.user.notification_consense == true
  }
}
</script>
