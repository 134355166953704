<template>
  <b-container fluid v-if="user" class="mb-4">
    <b-row :class="buttonGroupOpen ? 'min-width-75' : ''">
      <div class="text-left mt-3" :class="buttonGroupOpen ? 'w-100' : ''">
        <b-button-group
          class=""
          :class="buttonGroupOpen ? 'w-100' : ''"
          size="sm"
        >
          <!-- <h4 class="text-left mr-4 my-auto">Servizi</h4> -->
          <b-button
            key="b-prenotazioni"
            class="text-capitalize buttonbar custom-button-group-measures"
            @click="activePanel = 'prenotazioni'"
            :variant="
              activePanel === 'prenotazioni'
                ? 'secondary'
                : 'outline-secondary '
            "
          >
            <b-icon icon="app-indicator"></b-icon><br />
            <span v-if="buttonGroupOpen" key="b-prenotazioni-t">
              prenotazioni</span
            >
          </b-button>
          <b-button
            key="b-rilevazioni"
            class="text-capitalize buttonbar custom-button-group-measures"
            @click="activePanel = 'rilevazioni'"
            :variant="
              activePanel === 'rilevazioni' ? 'secondary' : 'outline-secondary '
            "
          >
            <b-icon icon="bar-chart"></b-icon><br />
            <span v-if="buttonGroupOpen" key="b-rilevazioni-t">
              rilevazioni</span
            >
          </b-button>
          <b-button
            key="b-patologie"
            class="text-capitalize buttonbar custom-button-group-measures"
            @click="activePanel = 'patologie'"
            :variant="
              activePanel === 'patologie' ? 'secondary' : 'outline-secondary '
            "
          >
            <b-icon icon="bookmark"></b-icon><br />
            <span v-if="buttonGroupOpen" key="b-patologie-t"> patologie</span>
          </b-button>
          <b-button
            key="b-terapie"
            class="text-capitalize buttonbar custom-button-group-measures"
            @click="activePanel = 'terapie'"
            :variant="
              activePanel === 'terapie' ? 'secondary' : 'outline-secondary '
            "
          >
            <b-icon icon="bookmarks"></b-icon><br />
            <span v-if="buttonGroupOpen" key="b-terapie-t"> terapie</span>
          </b-button>
          <b-button
            key="b-notifications"
            class="text-capitalize buttonbar custom-button-group-measures"
            @click="activePanel = 'notifications'"
            :variant="
              activePanel === 'notifications'
                ? 'secondary'
                : 'outline-secondary '
            "
          >
            <b-icon icon="bell"></b-icon><br />
            <span v-if="buttonGroupOpen" key="b-notifications-t">
              Notifiche</span
            >
          </b-button>
          <b-button
            key="b-note"
            class="text-capitalize buttonbar custom-button-group-measures general-right-border"
            @click="activePanel = 'note'"
            :variant="
              activePanel === 'note' ? 'secondary' : 'outline-secondary '
            "
          >
            <b-icon icon="clipboard"></b-icon><br />
            <span v-if="buttonGroupOpen" key="b-notes-t"> Note</span>
          </b-button>
          <!-- <b-button
            key="b-alertevents"
            class="text-capitalize buttonbar custom-button-group-measures general-right-border"
            @click="activePanel = 'alertevents'"
            :variant="
              activePanel === 'alertevents' ? 'secondary' : 'outline-secondary '
            "
          >
            <b-icon icon="exclamation-triangle"></b-icon><br />
            <span v-if="buttonGroupOpen" key="b-alertevents-t"> Alert</span>
          </b-button> -->
          <span key="btab_handler" class="d-flex align-content-center">
            <b-icon
              key="btab_handler-ic"
              :icon="buttonGroupOpen ? ' arrow-bar-left' : 'arrow-bar-right'"
              font-scale="1.5"
              class="ml-2 my-auto point"
              @click="buttonGroupOpen = !buttonGroupOpen"
            >
            </b-icon>
          </span>
        </b-button-group>
      </div>
      <b-col
        class="col-12 col-md-12 col-xl-9 general_border text-center pr-xl-5 pt-2"
      >
      </b-col>
      <b-col> </b-col>
    </b-row>
    <template v-if="user.anagrafica">
      <b-container
        class="p-4 col-12 mt-4 shadow_2 bg-white general__border"
        :class="{ 'd-xl-none': !blockView }"
      >
        <b-row>
          <p
            class="m-0 p-0 text-left text-primary underlined point"
            style="font-size: 1.5rem"
            @click="
              $router.push({
                name: 'profilo paziente',
                params: {
                  hash: user.hashid,
                },
              })
            "
          >
            <b-avatar
              variant="white"
              :src="user.profile_pic"
              rounded="sm"
            ></b-avatar>
            <span class="ml-3 text-capitalize"
              >{{ user.anagrafica.name }} {{ user.anagrafica.surname }}</span
            >
            <span class="ml-2">
              <b-icon
                icon="file-earmark-person-fill"
                variant="primary"
              ></b-icon>
            </span>
          </p>
          <b-button
            v-if="canEditAnagrafica"
            class="ml-auto mr-0"
            variant="secondary"
            @click="$bvModal.show('anagraphic_editor')"
          >
            Modifica anagrafica
          </b-button>
        </b-row>
        <hr />
        <b-row class="m-0 p-0 text-left">
          <b-col class="col-6 col-sm-6 col-lg-4 col-md-6 m-0 p-0"
            ><span class="text-primary">Genere </span>
            <span class="ml-1">{{ user.anagrafica.gender }}</span>
          </b-col>
          <b-col class="col-6 col-sm-6 col-lg-4 col-md-6 m-0 p-0"
            ><span class="text-primary">Regione </span>
            <span class="ml-1 text-capitalize">{{
              user.anagrafica.regione
            }}</span>
          </b-col>
          <b-col class="col-6 col-sm-6 col-lg-4 col-md-6 m-0 p-0"
            ><span class="text-primary">Indirizzo </span>
            <span class="ml-1 text-capitalize">{{
              user.anagrafica.address ? user.anagrafica.address : "Non indicato"
            }}</span>
          </b-col>
          <b-col class="col-6 col-sm-6 col-lg-4 col-md-6 m-0 p-0"
            ><span class="text-primary">Data di Nascita </span>
            <span class="ml-1" v-if="user.anagrafica.birthday"
              >{{ user.anagrafica.birthday | moment("DD/MM/YYYY") }}
            </span>
            <span class="ml-1" v-else>Non indicata</span>
          </b-col>
          <b-col class="col-6 col-sm-6 col-lg-4 col-md-6 m-0 p-0"
            ><span class="text-primary">Provincia </span>
            <span class="ml-1 text-capitalize">{{
              user.anagrafica.provincia
            }}</span>
          </b-col>
          <b-col class="col-6 col-sm-6 col-lg-4 col-md-6 m-0 p-0"
            ><span class="text-primary">Telefono </span>
            <span class="ml-1">{{
              user.anagrafica.tel_number
                ? user.anagrafica.tel_number
                : "Non indicato"
            }}</span>
          </b-col>
          <b-col class="col-6 col-sm-6 col-lg-4 col-md-6 m-0 p-0"
            ><span class="text-primary">Codice Fiscale </span>
            <span class="ml-1 text-uppercase">{{ user.anagrafica.cf }}</span>
          </b-col>
          <b-col class="col-6 col-sm-6 col-lg-4 col-md-6 m-0 p-0"
            ><span class="text-primary">Comune </span>
            <span class="ml-1 text-capitalize"
              >{{ user.anagrafica.comune }}
            </span>
          </b-col>
          <b-col v-if="user.email" class="col-sm-6 col-lg-4 col-md-6 m-0 p-0"
            ><span class="text-primary">Email </span>
            <span class="ml-1">{{ user.email }}</span>
          </b-col>
          <b-col v-else class="col-sm-6 col-lg-4 col-md-6 m-0 p-0"
            ><span class="text-primary">Telefono di riserva </span>
            <span class="ml-1">{{ user.anagrafica.tel_number_secondary }}</span>
          </b-col>
        </b-row>
      </b-container>
      <template v-if="!blockView">
        <b-row cols="2" class="mb-4 mt-4 general_border">
          <div class="col-12 col-md-12 col-xl-9 general_border px-0 mt-0 pt-2">
            <transition-group
              name="slideside"
              mode="out-in"
              tag="div"
              class="pr-xl-5"
            >
              <b-row v-if="activePanel === 'rilevazioni'" key="rilevazioni">
                <RilevazioniComponent :subject="subject" />
                <!-- <ScoresComponent :subject="subject" /> -->
              </b-row>
              <b-row v-if="activePanel === 'patologie'" key="patologie">
                <PatologieComponent :subject="subject" />
                <EsenzioniComponent :subject="subject" />
              </b-row>
              <b-row v-if="activePanel === 'terapie'" key="terapie">
                <AdherenceIndex v-if="subject" />
                <TerapieComponent :subject="subject" />
              </b-row>
              <b-row v-if="activePanel === 'prenotazioni'" key="prenotazioni">
                <PrenotazioniPaziente :subject="subject" :userinfo="user" />
              </b-row>
              <b-row
                v-if="activePanel === 'notifications'"
                key="notifications"
                class="text-left"
              >
                <SettedAlerts :subject="subject" />
              </b-row>
              <b-row v-if="activePanel === 'note'" key="note" class="text-left">
                <PatientAnnotations
                  :subjectHash="subject.hashid"
                  :hidetop="false"
                />
              </b-row>
              <b-row
                v-if="activePanel === 'alertevents'"
                key="alertevents"
                class="text-left"
              >
                <UserAlertEvents :subject="subject" />
              </b-row>
            </transition-group>
          </div>
          <div
            class="text-left d-none d-xl-block col-xl-3 p-2 long__txt"
            style="font-size: 1.15rem; letter-spacing: 0.05rem"
          >
            <div class="shadow_2 bg-white general__border p-3">
              <b-row cols="1" class="px-1 font-size-085rem">
                <p
                  style="font-size: 1.5rem"
                  class="mb-0 text-primary underlined point"
                  @click="
                    $router.push({
                      name: 'profilo paziente',
                      params: {
                        hash: user.hashid,
                      },
                    })
                  "
                >
                  <b-avatar
                    variant="white"
                    :src="user.profile_pic"
                    rounded="sm"
                  ></b-avatar>
                  <span class="ml-3 text-capitalize"
                    >{{ user.anagrafica.name }}
                    {{ user.anagrafica.surname }}</span
                  >
                  <span class="ml-2">
                    <b-icon
                      icon="file-earmark-person-fill"
                      variant="primary"
                    ></b-icon>
                  </span>
                </p>

                <hr class="my-3" />
                <b-col class="px-0 mb-2">
                  <span class="text-primary">Genere <br /></span>
                  <span>{{ user.anagrafica.gender }} </span>
                </b-col>
                <b-col class="px-0 mb-2">
                  <span class="text-primary">Data di nascita</span> <br />
                  <span v-if="user.anagrafica.birthday"
                    >{{ user.anagrafica.birthday | moment("DD/MM/YYYY") }}
                  </span>
                  <span v-else>Non indicata</span>
                </b-col>
                <b-col class="px-0 mb-2">
                  <span class="text-primary">Codice Fiscale</span> <br />
                  <span class="text-uppercase">{{ user.anagrafica.cf }} </span>
                </b-col>
                <b-col class="px-0 mb-2">
                  <span class="text-primary">Regione</span> <br />
                  <span class="text-capitalize"
                    >{{ user.anagrafica.regione }}
                  </span>
                </b-col>
                <b-col class="px-0 mb-2">
                  <span class="text-primary">Provincia</span> <br />
                  <span class="text-capitalize"
                    >{{ user.anagrafica.provincia }}
                  </span>
                </b-col>
                <b-col class="px-0 mb-2">
                  <span class="text-primary">Comune</span> <br />
                  <span class="text-capitalize"
                    >{{ user.anagrafica.comune }}
                  </span>
                </b-col>
                <b-col class="px-0 mb-2">
                  <span class="text-primary">Indirizzo</span> <br />
                  <span class="text-capitalize"
                    >{{
                      user.anagrafica.address
                        ? user.anagrafica.address
                        : "Non indicato"
                    }}
                  </span>
                </b-col>
                <b-col class="px-0 mb-2">
                  <span class="text-primary">Telefono</span> <br />
                  <span
                    >{{
                      user.anagrafica.tel_number
                        ? user.anagrafica.tel_number
                        : "Non indicato"
                    }}
                  </span>
                </b-col>
                <b-col v-if="user.email" class="px-0 mb-4">
                  <span class="text-primary">Email</span> <br />
                  <span>{{ user.email }} </span>
                </b-col>
                <b-col v-else class="px-0 mb-4">
                  <span class="text-primary">Telefono di riserva</span> <br />
                  <span>{{ user.anagrafica.tel_number_secondary }} </span>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-row>
      </template>
      <div v-else class="py-4 text-center">
        <OtpValidation
          :user="user"
          :cause="1"
          :validatable="user.hashid"
          :second="doBackupNumber"
          @otp-validated="handleOtpValidation($event)"
        >
          <template v-slot:instruction>
            <h1>
              <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
            </h1>
            <h2>
              E' necessario verificare il numero di telefono
              {{ doBackupNumber ? " di riserva " : "" }} del paziente.
            </h2>
            <h5 class="my-3">
              Clicca su "invia codice di verifica" per inviare il codice OTP di
              verifica via SMS
            </h5>
          </template>
        </OtpValidation>
      </div>
      <b-modal
        id="anagraphic_editor"
        size="xl"
        title="Modifica anagrafica paziente"
        body-class="pt-0"
        scrollable
        hide-footer
      >
        <p class="mb-0 px-1">
          Se necessario, modifica le informazioni anagrafiche del paziente
          essenziali per abilitarne l'utenza prestando particolare attenzione ai
          dati di contatto ( email e numero di telefono), per i quali è
          richiesta la validazione.
        </p>
        <PazienteAnagraficaEditor
          :user="user"
          @cancel-operation="$bvModal.hide('anagraphic_editor')"
          @edit-done="handleEditDone"
        ></PazienteAnagraficaEditor>
      </b-modal>
    </template>
    <template v-else> <h5>Anagrafica utente assente</h5></template>
  </b-container>
  <h5 v-else-if="notFound">Utente Non trovato</h5>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { cittadinoService } from "@/_services";
import RilevazioniComponent from "@/components/profile/sanitario/RilevazioniComponent.vue";
import PatologieComponent from "@/components/profile/sanitario/PatologieComponent.vue";
import AdherenceIndex from "@/components/profile/sanitario/AdherenceIndex.vue";
import TerapieComponent from "@/components/pazienti/TerapieComponent.vue";
import PrenotazioniPaziente from "@/components/pazienti/PrenotazioniPaziente.vue";
import SettedAlerts from "@/components/profile/sanitario/SettedAlerts.vue";
import PatientAnnotations from "@/components/profile/sanitario/PatientAnnotations.vue";
// import ScoresComponent from "@/components/profile/sanitario/ScoresComponent.vue";
import UserAlertEvents from "@/components/profile/sanitario/UserAlertEvents.vue";
import OtpValidation from "@/components/utilities/OtpValidation.vue";
import EsenzioniComponent from "@/components/profile/sanitario/EsenzioniComponent.vue";
import PazienteAnagraficaEditor from "@/components/profile/PazienteAnagraficaEditor.vue";

export default {
  name: "SchedaPaziente",
  components: {
    RilevazioniComponent,
    PatologieComponent,
    TerapieComponent,
    PrenotazioniPaziente,
    AdherenceIndex,
    SettedAlerts,
    PatientAnnotations,
    // ScoresComponent,
    OtpValidation,
    UserAlertEvents,
    EsenzioniComponent,
    PazienteAnagraficaEditor,
  },
  data() {
    return {
      user: null,
      activePanel: "prenotazioni",
      subject: null,
      notFound: false,
      buttonGroupOpen: true,
      availableSections: [
        "prenotazioni",
        "rilevazioni",
        "patologie",
        "terapie",
        "notifications",
        "alertevents",
      ],
      blockView: false,
      doBackupNumber: false,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    canEditAnagrafica() {
      if (this.user && this.user.is_my_patient) {
        if (this.user.email_verified_at || this.user.number_verified_at) {
          return false;
        }
        return this.user.all_consenses === false;
      }
      if (this.user && this.user.created_by_me) {
        if (this.user.email_verified_at || this.user.number_verified_at) {
          return false;
        }
        return this.user.all_consenses === false;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchUser() {
      const self = this;
      cittadinoService
        .getUserById(this.$route.params.hash)
        .then(function (res) {
          self.user = res.data.data;
          self.subject = {
            hashid: res.data.data.hashid,
            type: "user_in_app",
          };
          self.verifyCredentials(res.data.data);
          // self.memberList = res.data.data;
        })
        .catch(function (err) {
          console.log(err);
          self.notFound = true;
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le informazioni.");
        });
    },
    handleEditDone() {
      this.fetchUser();
      this.$bvModal.hide("anagraphic_editor");
    },
    verifyCredentials(user) {
      if (!user.number_verified_at) {
        return (this.blockView = true);
      }
      if (!user.email && !user.second_number_verified_at) {
        this.doBackupNumber = true;
        return (this.blockView = true);
      }
      this.blockView = false;
    },
    handleOtpValidation(hasVerified) {
      if (hasVerified) {
        return this.fetchUser();
      }
      this.$router.push({ name: "pazienti" });
    },
  },
  created() {
    this.fetchUser();
    if (
      this.$route.query.section &&
      this.availableSections.includes(this.$route.query.section)
    ) {
      this.activePanel = this.$route.query.section;
    }
  },
};
</script>
<style lang="scss" scoped>
.custom-button-group-measures {
  max-width: 150px;
  min-width: 50px;
  height: 50px;
}
</style>
