export const SERVIZI_VARIANTS = {
  prodotti: "orange",
  prodotto: "orange",
  prestazioni: "green",
  prestazione: "green",
  teleconsulti: "purple",
  teleconsulto: "purple",
};

export const CANCELLATION_MESSAGES = {
  1: "Ci spiace comunicarLe che non possiamo gestire ulteriori richieste per la data selezionata. La preghiamo di effettuare una nuova prenotazione per la settimana successiva alla data scelta.",
  2: "Ci spiace comunicarLe che a causa di problematiche relative all'approvvigionamento non possiamo gestire la sua richiesta per la data selezionata.",
  3: "Ci spiace comunicarLe che non possiamo gestire la sua richiesta per mancanza di dati essenziali. La preghiamo di completare la sua scheda anagrafica.",
  4: "Si è verificato un imprevisto che ci costringe a disdire il suo appuntamento. Se lo desidera, ci chiami per ulteriori informazioni.",
};

export const CANCELLATION_MESSAGES_CITTADINO = {
  1: "Non sono più interessato.",
  2: "Non posso rispettare l'impegno.",
};

export const STATUS_VARIANTS = {
  confermato: "secondary",
  ricevuto: "primary",
  annullato: "danger",
  rifiutato: "danger",
  "in attesa di risposta": "warning",
  evaso: "primary",
};

export const MONTHS = [
  { label: "Gennaio", value: 1 },
  { label: "Febbraio", value: 2 },
  { label: "Marzo", value: 3 },
  { label: "Aprile", value: 4 },
  { label: "Maggio", value: 5 },
  { label: "Giugno", value: 6 },
  { label: "Luglio", value: 7 },
  { label: "Agosto", value: 8 },
  { label: "Settembre", value: 9 },
  { label: "Ottobre", value: 10 },
  { label: "Novembre", value: 11 },
  { label: "Dicembre", value: 12 },
];
