<template>
  <div v-if="detail" class="w-100">
    <!-- <b-row
      align-v="stretch"
      id="row-dettaglio-prestazione"
      class="mt-n3 flex-wrap flex-md-nowrap"
    > -->
    <b-row
      align-v="stretch"
      class="mt-n3 flex-wrap"
    >
    <b-col
        cols="12"
        lg="6"
        v-if="allegati && allegati.length > 0"
        class="mt-2"
      >
        <div class="p-3 detail-modal-card h-100">
          <p class="font-weight-bolder detail-modal-card-titles">ALLEGATI SCARICABILI</p>
          <p class="m-0 p-0 text-capitalize ">
            <a
              v-for="(allegato, ind) in allegati"
              :key="ind"
              :href="allegato.full_path"
              target="__blank"
              class="point "
              :title="'apri ' + allegato.name + ' in un\'altra scheda'"
            >
              <b-badge
                variant="primary"
                class="my-2 py-2 px-2 mr-2 text__control"
              >
                <span class="">{{ allegato.name }}</span>
              </b-badge>
            </a>
          </p>
        </div>
      </b-col>
      <b-col cols="12"  xl="8" id="col-questionario" class="mt-3 px-0">
        <div
          class="p-3 mr-4 detail-modal-card d-flex flex-column justify-content-start align-items-start h-100"
        >
          <p class="font-weight-bolder detail-modal-card-titles">
            QUESTIONARIO
          </p>
          <b-button
            v-if="survey !== null && survey.response"
            size="sm"
            variant="outline-primary general_border_11"
            class="px-4"
            @click="$refs['show-filleditem'].show()"
            >apri questionario</b-button
          >
          <p v-else class="m-0 p-0 text-dark-grey text-left">
            Nessun questionario
          </p>
        </div>
      </b-col>
      <b-col
        cols="12"
        xl="8"
        class="mt-3"
        v-if="detail.details && detail.details !== ''"
      >
        <b-row class="p-3 detail-modal-card">
          <b-col class="px-0">
            <p>
              <span class="font-weight-bolder detail-modal-card-titles"
                >ALTRI DETTAGLI</span
              >
            </p>
            <p class="text-dark-grey font-size-085rem m-0 p-0">
              {{ detail.details }} 
            </p>
          </b-col>
        </b-row>
      </b-col>
      <!-- <b-col id="col-details-details" class="mt-3" v-if="detail.details">
        <div
          class="p-3 px-0 mr-4 detail-modal-card d-flex flex-column justify-content-start align-items-start h-100"
        >
          <p class="detail-modal-card-titles text-left font-weight-bolder">
            NOTE SULLA PRESTAZIONE
          </p>
          <p class="text-dark-grey text-left font-size-085rem m-0 p-0">
            {{ detail.details }}
          </p>
        </div>
      </b-col> -->
      <!-- <b-col id="col-bottoni" class="mt-3 col-1 px-0" v-if="thereIsOneButton"> -->
      <b-col class="mt-3 col-1 px-0" v-if="thereIsOneButton" cols="12" xl="4">
        <div
          class="px-0 mr-4 d-flex flex-column justify-content-center align-items-center h-100"
        >
          <b-button
            v-if="stato === 1 || stato === 6"
            size="sm"
            @click="confirmRequest"
            type="button"
            variant="outline-primary modal-body-button mb-2"
            >Conferma Appuntamento</b-button
          >
          <b-button
            v-if="stato === 1"
            size="sm"
            @click="setAction('reject')"
            type="button"
            variant="outline-danger modal-body-button mb-2"
            >Declina Richiesta</b-button
          >
          <b-button
            v-if="stato > 1"
            @click="setAction('cancel')"
            type="button"
            size="sm"
            variant="outline-danger modal-body-button mb-2"
            >Annulla Appuntamento</b-button
          >
        </div>
      </b-col>
    </b-row>

    <b-modal
      size="xl"
      hide-footer
      centered
      id="modal-confirm"
      ref="modal-confirm"
      title="Conferma Annullamento"
    >
      <PickMessageForm
        :messages="cancellationMessages"
        confirm-message="Sei sicuro di voler annullare questo appuntamento?"
        @cancellation-confirmed="cancelRequest($event)"
        @refused-to-confirm="$bvModal.hide('modal-confirm')"
      />
    </b-modal>
    <b-modal
      centered
      ref="show-filleditem"
      hide-footer
      title="Questionario"
      size="lg"
    >
      <FilledSurveyShower v-if="survey" :response="survey.response"  :score="survey.score"/>
    </b-modal>
  </div>
</template>
<script>
import { supportService } from "@/_services";
import FilledSurveyShower from "@/components/attivita/FilledSurveyShower.vue"
import UpdateNotesComponent from "@/components/prenotazioni/dettaglio/UpdateNotesComponent.vue"
import PickMessageForm from "@/components/prenotazioni/form/PickMessageForm.vue"
import { CANCELLATION_MESSAGES } from "@/_utils/constants.js"

export default {
  name: "DetailPrestazione",
  props: ["detail", "stato", "datainizio", "quest", "company_notes", "hash"],
  components: { FilledSurveyShower, UpdateNotesComponent, PickMessageForm },
  data() {
    return {
      cancellationMessages: CANCELLATION_MESSAGES,
      actionName: null,
      survey: null,
      allegati: null,
    }
  },
  computed: {
    thereIsOneButton() {
      return Math.abs(this.stato) !== 4 && this.stato >= 1
    }
  },
  methods: {
    confirmRequest() {
      // console.log(this.detail.hashid);
      // this.$emit("update-request-status", true);
      var data = {
        confirmed: true,
        action: "confirm"
      }
      this.$emit("update-request-status", data)
    },
    setAction(action) {
      this.actionName = action
      this.$refs["modal-confirm"].show()
    },
    cancelRequest(message = null) {
      var data = {
        confirmed: false,
        message,
        action: this.actionName
      }
      // console.log(data);
      this.$emit("update-request-status", data)
      this.$refs["modal-confirm"].hide()
    },
    handleNotesUpdate(data) {
      this.company_notes = data.company_notes
    },
    fetchAllegati() {
      var self = this;
      supportService
        .getHealthServiceAllegati(this.detail.health_service.hashid)
        .then(function (res) {
          self.allegati = res.data.data;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
  },
  created() {
    // this.fetchAllegati();
    if (this.quest) {
      this.survey = this.quest
    }
  }
}
</script>
<style lang="scss" scoped>
.simple__input {
  border-radius: 0 !important;
  font-size: 93% !important;
}

.simple__input:focus {
  box-shadow: none !important;
}

#row-dettaglio-prestazione {
  .col {
    // min-height: 120px;
    padding: 0px !important;
    max-width: 450px;
  }

  button {
    max-width: 90%;
  }
}

// #col-questionario {
//   max-width: 200px !important;
//   min-height: 120px;
// }

.notes-grow-2 {
  flex-grow: 2;
}

@media (min-height: 1200px) {
  #row-dettaglio-prestazione {
    .col {
      min-height: 150px;
      padding: 0px !important;
    }

    button {
      max-width: 90%;
    }
  }
}
</style>
