<template>
  <b-container fluid class="px-0">
    <div class="mb-3">
      <b-row class="justify-content-bewteen pb-2 mb-n3" align-v="center">
        <b-col class="align-self-end px-0 col-12 col-md-3 mt-3">
          <h4 class="text-prim-grad-1 text-left pb-1 ml-3 page-lg-title">
            Terapie in atto
          </h4>
        </b-col>
        <b-col
          class="text-center my-3 my-md-0 text-md-right col-12 col-md-9 mt-3"
        >
          <b-button
            v-if="therapies && therapies.active && therapies.active.length > 0"
            class="text-left mr-4 px-3"
            @click="prospettoDownload()"
            variant="outline-primary"
          >
            <b-icon icon="download" class="mr-md-3"></b-icon>
            <span class="d-none d-md-inline"> Prospetto Terapie </span>
          </b-button>
          <b-button class="text-left" @click="goToNew">
            <b-icon icon="plus"></b-icon> nuova terapia
          </b-button>
        </b-col>
      </b-row>

      <b-row
        align-v="stretch"
        class="my-2 ml-n3 col-12 px-0 align-items-center align-content-center flex-wrap my-2"
        cols-lg="2"
        cols-xl="3"
        cols="1"
      >
        <b-col
          class="col-12 my-2 mx-0 px-3"
          v-for="(ter, ind) in therapies.active"
          :key="ind"
        >
          <div class="text-left py-2 puntofarmaCard col-12 h-100 d-flex">
            <!-- :class="ter.suspended ? 'border-warning' : 'border-success'" -->

            <!-- Box a sx contenente immagine e descrizione -->
            <div
              class="m-0 px-2 d-none d-sm-flex flex-column justify-content-center align-items-center"
              style="flex: 0 0 100px; min-width: 0"
            >
              <b-icon :icon="cycleIcons[ter.tipo]" font-scale="2"></b-icon>
              <span class="text-center text-capitalize fontsize075rem pt-2">{{
                ter.tipo
              }}</span>
            </div>

            <!-- Parte rimanente della card -->
            <div class="m-0 p-0" style="flex: 1">
              <h5 class="mb-0 pt-1 text-uppercase long__txt">
                {{ ter.product_name }}
              </h5>

              <p v-if="ter.pathology" class="mb-0 pt-1 text-capitalize">
                {{ ter.pathology.name }}
              </p>
              <br v-else />
              <p
                class="mb-0 mt-auto d-sm-none pt-1 text-font-grey text-capitalize"
              >
                <span class="mr-auto">{{ ter.tipo }}</span>
              </p>
              <p
                class="mb-0 mt-auto pt-1 d-flex justify-items-between text-font-grey"
              >
                <span v-if="ter.suspended" class="mr-auto text-sec-1"
                  >Sospesa</span
                >
                <span v-else-if="ter.end_at" class="mr-auto text-prim-grad-1"
                  >Fino al {{ formatData(ter.end_at) }}</span
                >
                <span v-else-if="ter.start_at" class="mr-auto text-prim-grad-1"
                  >Dal {{ formatData(ter.start_at) }}</span
                >
                <span v-else class="mr-auto text-white">-</span>
                <span class="ml-auto point">
                  <b-iconstack
                    @click="showDelModal(ter)"
                    v-if="ter.own"
                    font-scale="1.5"
                    class="mr-4"
                    title="elimina"
                  >
                    <!-- <b-icon stacked icon="circle-fill" variant="danger"></b-icon>
                    <b-icon stacked icon="x" variant="white" font-scale="1.5"></b-icon> -->
                    <b-icon
                      stacked
                      icon="trash"
                      variant="danger"
                      font-scale="1.5"
                    ></b-icon>
                  </b-iconstack>
                  <b-icon
                    @click="selectTherapy(ter)"
                    icon="zoom-in"
                    font-scale="1.5"
                    variant="primary"
                    title="vedi"
                  >
                  </b-icon>
                </span>
              </p>
            </div>
          </div>
        </b-col>
        <!-- <b-col class="text-left px-0 mt-2">
          <div
            class="align-items-center align-content-center general_border w-100 point"
            @click="goToNew"
          >
            <p class="ml-3">
              <b-iconstack font-scale="3.5" class="rounded-circle">
                <b-icon
                  class=""
                  stacked
                  icon="circle-fill"
                  variant="secondary"
                ></b-icon>
                <b-icon
                  stacked
                  icon="plus"
                  scale="0.5"
                  variant="white"
                ></b-icon>
              </b-iconstack>
            </p>
          </div>
        </b-col> -->
      </b-row>
    </div>
    <div>
      <h4 class="text-prim-grad-1 text-left pb-1 ml-3 page-lg-title">
        Storico terapie
      </h4>

      <b-row
        align-v="stretch"
        class="my-2 ml-n3 col-12 px-0 align-items-center align-content-center flex-wrap my-2"
        cols-lg="2"
        cols-xl="3"
        cols="1"
      >
        <b-col
          class="col-12 my-2 mx-0 px-3"
          v-for="(ter, ind) in therapies.ended"
          :key="ind"
        >
          <div class="text-left py-2 puntofarmaCard col-12 h-100 d-flex">
            <div
              class="m-0 px-2 d-none d-sm-flex flex-column justify-content-center align-items-center"
              style="flex: 0 0 100px; min-width: 0"
            >
              <b-icon :icon="cycleIcons[ter.tipo]" font-scale="2"></b-icon>
              <span class="text-center text-capitalize fontsize075rem pt-2">{{
                ter.tipo
              }}</span>
            </div>

            <div class="m-0 p-0" style="flex: 1">
              <h5 class="mb-0 pt-1 text-uppercase long__txt">
                {{ ter.product_name }}
              </h5>
              <h5 v-if="ter.pathology" class="mb-0 pt-1 text-capitalize">
                {{ ter.pathology.name }}
              </h5>
              <br v-else />
              <p
                class="mb-0 mt-auto pt-1 d-sm-none text-font-grey text-capitalize"
              >
                <span class="mr-auto">{{ ter.tipo }}</span>
              </p>
              <p
                class="mb-0 mt-auto pt-1 d-flex justify-items-between text-font-grey"
              >
                <span v-if="ter.suspended" class="mr-auto text-sec-1"
                  >Sospesa</span
                >
                <span v-else-if="ter.end_at" class="mr-auto"
                  >Terminata il {{ formatData(ter.end_at) }}</span
                >
                <span v-else class="mr-auto text-white">-</span>
                <span class="ml-auto point">
                  <b-iconstack
                    @click="showDelModal(ter)"
                    v-if="ter.own"
                    font-scale="1.5"
                    class="mr-4"
                    title="elimina"
                  >
                    <!-- <b-icon
                      stacked
                      icon="circle-fill"
                      variant="danger"
                    ></b-icon> -->
                    <b-icon
                      stacked
                      icon="trash"
                      variant="danger"
                      font-scale="1.5"
                    ></b-icon>
                  </b-iconstack>
                  <b-icon
                    @click="selectTherapy(ter)"
                    icon="zoom-in"
                    font-scale="1.5"
                    variant="primary"
                    title="vedi"
                  >
                  </b-icon>
                </span>
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
      <p class="text-left ml-3" v-if="therapies.ended.length === 0">
        Nessun elemento nello storico
      </p>
    </div>
    <b-modal centered size="xl" id="edit-therapy" hide-footer>
      <template #modal-header>
        <div class="w-100 py-0" style="position: relative">
          <b-icon
            icon="x"
            font-scale="1.5"
            class="point"
            variant="darkgrey"
            @click="$bvModal.hide('edit-therapy')"
            style="position: absolute; top: -7px; right: -7px; z-index: 100"
          ></b-icon>

          <div class="w-100 d-flex flex-column border-bottom border-info p-0">
            <h3 class="mb-0 pb-2">
              <!-- {{modalTitle}} -->
              <template v-if="editing">Modifica terapia</template>
              <template v-else>Dettaglio terapia</template>
            </h3>

            <div class="" v-if="selectedTherapy && selectedTherapy.pathology">
              <p class="mb-0 font-size-085rem text-right">per patologia</p>
              <p class="mb-0 text-right">
                {{ selectedTherapy.pathology.name }}
                - patologia {{ selectedTherapy.pathology.tipo }}
              </p>
            </div>
          </div>
        </div>
      </template>

      <div v-if="selectedTherapy">
        <div v-if="editing">
          <EditTherapy
            :therapy="selectedTherapy"
            v-on:cancel-operation="$bvModal.hide('edit-therapy')"
            v-on:save-therapy="$bvModal.hide('edit-therapy')"
          />
        </div>
        <div v-else>
          <RecapTherapyModal :therapy="selectedTherapy" />
          <div class="ml-n3 d-flex flex-column align-items-center">
            <b-row class="pb-4 px-0 justify-items-between col-12">
              <b-col
                xsm="12"
                sm="6"
                class="mb-2 pl-3 pr-0"
                v-if="selectedTherapy.own"
              >
                <!-- Bottone di modifica -->
                <b-button
                  class="col-12 h-100"
                  @click="editing = true"
                  variant="outline-primary font-weight-bolder "
                >
                  <b-icon icon="pencil-fill"></b-icon>
                  Modifica
                </b-button>
              </b-col>
              <b-col
                xsm="12"
                sm="6"
                v-if="selectedTherapy.suspended && !selectedTherapy.terminata"
                class="mb-2 pl-3 pr-0"
              >
                <!-- Bottone di ripresa terapia -->
                <b-button
                  @click="openSuspModal(false)"
                  class="col-12 h-100"
                  variant="outline-sec-1 font-weight-bolder "
                >
                  <b-icon icon="play-circle"></b-icon>
                  Riprendi
                </b-button>
              </b-col>
              <b-col
                xsm="12"
                sm="6"
                v-else-if="!selectedTherapy.terminata"
                class="mb-2 pl-3 pr-0"
              >
                <!-- Bottone sospensione terapia -->
                <b-button
                  @click="openSuspModal(true)"
                  class="col-12 h-100"
                  variant="outline-warning font-weight-bolder "
                >
                  <b-icon icon="pause-circle"></b-icon>
                  Sospendi
                </b-button>
              </b-col>
              <b-col xsm="12" sm="6" class="pl-3 mb-2 pr-0">
                <!-- Bottone download promemoria -->
                <b-button
                  class="col-12 h-100"
                  @click="downloadRecap"
                  variant="primary font-weight-bolder "
                >
                  <b-icon icon="download"></b-icon>
                  Scarica promemoria
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
        <p class="d-flex mb-0 justify-content-start align-items-center">
          Inserita il {{ formatData(selectedTherapy.created_at) }}
        </p>
      </div>
    </b-modal>
    <b-modal
      id="susp-therapy"
      size="lg"
      centered
      title="Conferma "
      button-size="sm"
      cancel-title="No, Annulla"
      cancel-variant="secondary px-3  font-weight-bolder"
      ok-title="Sì, Conferma"
      ok-variant="outline-danger px-3 font-weight-bolder "
      footer-class="border-0"
      @ok="doSuspend"
      @cancel="$bvModal.hide('edit-therapy')"
    >
      Confermi di voler {{ isSuspending ? "sospendere" : "riprendere" }} la
      terapia?
    </b-modal>
    <b-modal
      id="del-therapy"
      size="lg"
      centered
      title="Conferma eliminazione"
      button-size="sm"
      cancel-title="No, Annulla"
      cancel-variant="info modal-footer-button px-3 font-weight-bolder"
      ok-title="Sì, Conferma"
      ok-variant="outline-danger modal-footer-button px-3 font-weight-bolder "
      footer-class="border-0"
      @ok="doDelete"
      @cancel="$bvModal.hide('edit-therapy')"
    >
      Confermi di voler eliminare la terapia?
    </b-modal>
    <b-modal
      id="new_t_inpage"
      size="xl"
      centered
      title="Nuova Terapia"
      hide-footer
      scrollable
      @hidden="fetchTherapies"
    >
      <NewTherapy :subject="subject" @save-therapy="handleNewTherapy($event)" />
    </b-modal>
  </b-container>
</template>
<script>
import { mapState, mapActions } from "vuex"
import { cittadinoService } from "@/_services"
import { supportService } from "../../../_services"
import * as moment from "moment/moment"
import RecapTherapyModal from "@/components/profile/sanitario/RecapTherapyModal.vue"
import EditTherapy from "@/components/profile/sanitario/EditTherapy.vue"
import NewTherapy from "@/components/profile/sanitario/NewTherapy.vue"

export default {
  name: "ArmadiettoComponent",
  props: ["subject", "ricognizione"],
  components: { RecapTherapyModal, EditTherapy, NewTherapy },
  data() {
    return {
      cycleIcons: {
        periodica: "calendar",
        cronica: "watch",
        occasionale: "compass"
      },
      therapies: { active: [], ended: [] },
      typesColors: ["ter-4", "ter-1", "ter-2"],
      selectedTherapy: null,
      editing: false,
      isSuspending: false
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    })
    // modalTitle: function () {
    //   if (this.selectedTherapy && this.selectedTherapy.pathology) {
    //     return "Terapia per " + this.selectedTherapy.pathology.name;
    //   }
    //   return "Terapia";
    // },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    handleNewTherapy(newTp) {
      this.therapies.active.push(newTp)
      this.$bvModal.hide("new_t_inpage")
    },
    goToNew() {
      if (this.ricognizione) {
        return this.$bvModal.show("new_t_inpage")
      }
      var destination = {
        name: "profilo sanitario",
        params: {
          section: "terapie",
          action: "nuova"
        }
      }
      if (this.subject) {
        destination = {
          name: "nuova terapia Paziente",
          query: {
            sub: this.subject.hashid,
            type: this.subject.type
          }
        }
      }
      this.$router.push(destination)
    },
    showDelModal(ter) {
      this.selectedTherapy = ter
      this.$bvModal.show("del-therapy")
    },
    selectTherapy(ter) {
      this.selectedTherapy = ter
      this.$bvModal.show("edit-therapy")
    },
    fetchTherapies() {
      if (this.subject) {
        return this.fetchUserTherapies()
      }
      this.fetchOwnTherapies()
    },
    fetchOwnTherapies() {
      var self = this
      cittadinoService
        .getTherapies()
        .then(function (response) {
          self.therapies = response.data.data
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le terapie")
        })
    },
    fetchUserTherapies() {
      var self = this
      var filters = {
        user: this.subject.hashid
      }
      cittadinoService
        .getTherapies(filters)
        .then(function (response) {
          self.therapies = response.data.data
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le terapie")
        })
    },
    openSuspModal(isSuspending) {
      this.$bvModal.show("susp-therapy")
      this.isSuspending = isSuspending
    },
    doSuspend() {
      var self = this
      var form = this.selectedTherapy
      form.suspended = this.isSuspending
      var term = this.isSuspending ? "sospesa" : "ripresa"
      cittadinoService
        .updateTherapy(form)
        .then(function (response) {
          self.$bvModal.hide("edit-therapy")
          self.successAlert("Terapia " + term)
          self.fetchTherapies()
        })
        .catch(function (error) {
          self.$bvModal.hide("edit-therapy")
          self.errorAlert("Non è stato possibile eseguire l'operazione")
        })
    },
    doDelete() {
      var self = this
      var form = { hashid: this.selectedTherapy.hashid }
      cittadinoService
        .deleteTherapy(form)
        .then(function (response) {
          self.$bvModal.hide("edit-therapy")
          self.successAlert("Terapia eliminata!")
          self.fetchTherapies()
        })
        .catch(function (error) {
          self.$bvModal.hide("edit-therapy")
          self.errorAlert("Non è stato possibile eliminare la terapia")
        })
    },
    formatData(data) {
      return moment(data).format("DD/MM/YYYY")
    },
    downloadRecap() {
      var self = this
      cittadinoService
        .therapyRecap(this.selectedTherapy.hashid)
        .then(function (response) {
          self.$bvModal.hide("edit-therapy")
          let blob = new Blob([response.data])
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = "promemoria_terapia.pdf"
          link.click()
        })
        .catch(function (error) {
          self.$bvModal.hide("edit-therapy")
          self.errorAlert("Non è stato possibile scaricare il promemoria")
        })
    },
    prospettoDownload() {
      var self = this
      supportService
        .downloadProspettoTerapia()
        .then(function (response) {
          let blob = new Blob([response.data])
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = "ProspettoTerapeutico.pdf"
          link.click()
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile scaricare il prospetto.")
        })
    }
  },
  created() {
    this.fetchTherapies()
  },
  mounted() {
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      this.selectedTherapy = null
      this.editing = false
      // console.log(modalId);
    })
  }
}
</script>
