import config from "../_config/config";
import { authHeader, postHeader } from "../_helpers";
import axios from "axios";

axios.defaults.baseURL = config.apiUrl;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.put["X-Requested-With"] = "XMLHttpRequest";

export const monitoringService = {
  getThresholds,
  storeUserThresholds,
  updateUserThreshold,
  deleteUserThreshold,
  getUserExamFrequencies,
  storeUserExamFrequency,
  updateUserExamFrequencyStatus,
  updateUserExamFrequency,
  deleteUserExamFrequency,
  getScoreList,
  getUserScores,
  getUserScoresHistory,
  getUserScoreFrequencies,
  storeUserScoreFrequency,
  updateUserScoreFrequencyStatus,
  updateUserScoreFrequency,
  deleteUserScoreFrequency,
  getScoreDetails,
  updateUserThresholdNotes
};

function getThresholds(exam, filters) {
  const sett = { headers: authHeader(), params: filters };
  const endpoint = "exams/thresholds/" + exam;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeUserThresholds(exam, data) {
  const sett = { headers: authHeader() };
  const endpoint = "exams/thresholds/" + exam;
  return axios
    .post(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateUserThreshold(reference, data) {
  const sett = { headers: authHeader() };
  const endpoint = "exams/thresholds/custom/" + reference;
  return axios
    .put(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function deleteUserThreshold(reference) {
  const sett = { headers: authHeader() };
  const endpoint = "exams/thresholds/custom/" + reference;
  return axios
    .delete(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getUserExamFrequencies(exam, filters) {
  const sett = { headers: authHeader(), params: filters };
  const endpoint = "exams/frequencies/" + exam;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeUserExamFrequency(exam, data) {
  const sett = { headers: authHeader() };
  const endpoint = "exams/frequencies/" + exam;
  return axios
    .post(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateUserExamFrequencyStatus(freq, data) {
  const sett = { headers: authHeader() };
  const endpoint = "exams/frequencies/" + freq;
  return axios
    .patch(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateUserExamFrequency(freq, data) {
  const sett = { headers: authHeader() };
  const endpoint = "exams/frequencies/" + freq;
  return axios
    .put(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function deleteUserExamFrequency(freq) {
  const sett = { headers: authHeader() };
  const endpoint = "exams/frequencies/" + freq;
  return axios
    .delete(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getScoreList(filters) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("scores/", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getUserScores(user, filters) {
  const sett = { headers: authHeader(), params: filters };
  const endpoint = "scores/user/" + user;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getUserScoresHistory(user, score, filters) {
  const sett = { headers: authHeader(), params: filters };
  const endpoint = "scores/user/" + user + "/score/" + score;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getUserScoreFrequencies(exam, filters) {
  const sett = { headers: authHeader(), params: filters };
  const endpoint = "scores/frequencies/" + exam;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeUserScoreFrequency(exam, data) {
  const sett = { headers: authHeader() };
  const endpoint = "scores/frequencies/" + exam;
  return axios
    .post(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateUserScoreFrequencyStatus(freq, data) {
  const sett = { headers: authHeader() };
  const endpoint = "scores/frequencies/" + freq;
  return axios
    .patch(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateUserScoreFrequency(freq, data) {
  const sett = { headers: authHeader() };
  const endpoint = "scores/frequencies/" + freq;
  return axios
    .put(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function deleteUserScoreFrequency(freq) {
  const sett = { headers: authHeader() };
  const endpoint = "scores/frequencies/" + freq;
  return axios
    .delete(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getScoreDetails(score, filters = null) {
  const sett = { headers: authHeader(), params: filters };
  const endpoint = "scores/details/" + score;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateUserThresholdNotes(reference, data) {
  const sett = { headers: authHeader() };
  const endpoint = "exams/thresholds/custom/notes/" + reference;
  return axios
    .put(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}
