<template>
  <div>
    <router-link
      class="my-3 mx-auto"
      :to="homePath + '/attivita'"
      :class="this.$route.name === 'attivita' && 'router-link-exact-active'"
    >
      <b-icon font-scale="1.5" icon="list-check"></b-icon>
      <p class="mb-0 fs-5">Elenco Attività</p></router-link
    >
    <router-link
      v-if="user.type == 1"
      class="my-3 mx-auto"
      :to="homePath + '/attivita/questionari'"
      :class="this.$route.name === 'questionari' && 'router-link-exact-active'"
    >
      <b-icon font-scale="1.5" icon="card-list"></b-icon>
      <p class="mb-0 fs-5">Questionari</p></router-link
    >
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "AttivitaMenu",
  computed: {
    homePath() {
      if (this.user) {
        return "/" + this.user.i_am;
      }
    },
    ...mapState("utente", ["status", "user"]),
  },
  data() {
    return {};
  },
};
</script>