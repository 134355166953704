<template>
  <div class="">
    <div v-if="openings">
      <b-container class="col-12 ml-1 mb-1 px-0">
        <b-row cols="2" cols-sm="2" cols-md="4" cols-lg="5" cols-lgx="6" cols-xl="6" align-v="stretch">
          <b-col v-for="(giorno, ind) in openings" :key="ind" class="my-2 px-1">
            <div class="
                my-2
                puntofarmaCard
                h-100
                d-flex
                flex-wrap
                align-content-start
              ">
              <b-col class="my-1 col-12 pt-3">
                <h5 class="text-left text-capitalize">
                  {{ days[giorno.day] }}
                  <span class="ml-2">
                    <b-icon icon="calendar4"></b-icon>
                  </span>
                </h5>
              </b-col>
              <b-col class="my-3 col-12">
                <b-row cols="1" class="text-left">
                  <b-col class="px-1 mb-1">
                    <h6 class="text-left">
                      <span class="mr-2">
                        <b-icon icon="people-fill"></b-icon>
                      </span>
                      al giorno: {{ giorno.tot_perday }}
                    </h6>
                    <!-- <p class="">
                      <span class="mr-2"
                        ><b-icon icon="people" variant="white"></b-icon>
                      </span>
                      {{ giorno.tot_perday }}
                    </p> -->
                  </b-col>

                  <b-col class="px-1 mb-1">
                    <h6 class="text-left">
                      <span class="mr-2">
                        <b-icon icon="alarm-fill"></b-icon>
                      </span>
                      durata: {{ giorno.interval }}min
                    </h6>
                    <!-- <p class="">
                      <span class="mr-2"
                        ><b-icon icon="people" variant="white"></b-icon>
                      </span>
                      {{ giorno.interval }} min
                    </p> -->
                  </b-col>
                  <b-col class="px-1 mb-1">
                    <template v-if="giorno.ora_inizio_am || giorno.ora_fine_am">
                      <h6 class="text-left">
                        <span class="mr-2">
                          <b-icon icon="clock"></b-icon>
                        </span>
                        mattina:
                      </h6>
                      <p class="">
                        <span class="mr-2">
                          <b-icon icon="clock" variant="white"></b-icon>
                        </span>
                        {{ giorno.ora_inizio_am }} -
                        {{ giorno.ora_fine_am }}
                      </p>
                    </template>
                  </b-col>
                  <b-col class="px-1 mb-1">
                    <template v-if="giorno.ora_inizio_pm || giorno.ora_fine_pm">
                      <h6 class="text-left">
                        <span class="mr-2">
                          <b-icon icon="clock"></b-icon>
                        </span>
                        pomeriggio:
                      </h6>
                      <p class="">
                        <span class="mr-2">
                          <b-icon icon="clock" variant="white"></b-icon>
                        </span>
                        {{ giorno.ora_inizio_pm }} -
                        {{ giorno.ora_fine_pm }}
                      </p>
                    </template>
                  </b-col>
                </b-row>
              </b-col>
              <b-col class="col-12 absolute__bottom">
                <b-row class="my-2 text-right justify-content-end">
                  <b-col class="col-3 px-0">
                    <b-button v-b-tooltip.hover.bottom title="Rimuovi giorno" size="sm" variant="info"
                      @click="removeDay(ind)">
                      <b-icon icon="trash" font-scale="1.3" aria-label="Elimina"></b-icon>
                    </b-button>
                  </b-col>
                  <b-col class="col-3 px-0">
                    <b-button v-b-tooltip.hover.bottom title="Modifica orari" size="sm" variant="info"
                      @click="openEditDay(ind)">
                      <b-icon icon="pencil-fill" font-scale="1.3" aria-label="Modifica"></b-icon>
                    </b-button>
                  </b-col>
                  <b-col class="col-3 px-0">
                    <b-button v-b-tooltip.hover.bottom title="Duplica giorno" size="sm" variant="info"
                      @click="openDuplicateDay(ind)">
                      <b-icon icon="files" font-scale="1.3" aria-label="Duplica"></b-icon>
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <div class="pl-2 mt-3 text-left" v-if="openings && openings.length < 7">
        <!-- <div class="d-flex align-items-center point" @click="openNewDay(1)">
          <span class="ml-2">
            <b-iconstack font-scale="2.5">
              <b-icon stacked icon="circle-fill" variant="primary"></b-icon>
              <b-icon stacked icon="plus" scale="0.8" variant="white"></b-icon>
              <b-icon stacked icon="circle" variant="primary"></b-icon>
            </b-iconstack>
          </span>
          <span class="text-primary ml-2">Aggiungi fascia oraria</span>
        </div> -->
        <b-button variant="secondary" @click="openNewDay(1)">
          <b-icon icon="plus" ></b-icon>
          Aggiungi fascia oraria
        </b-button>
      </div>
      <div v-if="showSave" class="mt-3 text-left">
        <b-button type="button" variant="outline-danger mx-2" @click="showSave = false">Annulla</b-button>
        <b-button variant="primary text-white  mx-2 " type="button" @click="submitChanges">Salva orari
          {{ servicename }}
        </b-button>
      </div>
    </div>
    <div>
      <b-modal centered ref="modal-edit" title="Modifica fascia oraria" hide-footer>
        <div v-if="dayToEdit">
          <div>
            <b-form-group label="Giorno" label-for="day" class="text-left fs-6 text-font-grey mx-auto col">
              <b-form-select class="my-3  text-primary bg-white" :options="stringDays" name="day"
                v-model="dayToEdit" disabled>
              </b-form-select>
            </b-form-group>
          </div>
          <b-form-group label="Minuti per appuntamento" label-for="interval"
            class="text-left fs-6 text-font-grey mx-auto col">
            <span class="col-1 fw-bold text-primary range_value">{{
            form[dayToEdit].interval
            }}</span>
            <b-form-input class="text-right col-10 ml-auto my-3 " type="range" name="tot_perday"
              v-model="form[dayToEdit].interval" min="5" step="5" max="60"></b-form-input>
          </b-form-group>
          <b-form-group label="Numero totale di appuntamenti al giorno" label-for="tot_perday"
            class="text-left fs-6 text-font-grey mx-auto col">
            <b-form-input class="mx-auto my-3 " type="number" name="tot_perday"
              v-model="form[dayToEdit].tot_perday" :min="1" :step="1"></b-form-input>
          </b-form-group>
          <div>
            <div class="col-12 d-flex flex-wrap px-0">
              <b-form-group label="Mattina dalle" label-for="day" class="
                  text-left
                  fs-6
                  text-font-grey
                  mx-auto
                  col-sm-12 col-md-5
                  px-0
                "><span v-if="form[dayToEdit].ora_inizio_am != null" class="text-danger absolute_icon_left"
                  @click="form[dayToEdit].ora_inizio_am = null">
                  <b-icon icon="x-circle-fill" font-scale="1.5"></b-icon>
                </span>
                <b-form-input class="mx-auto my-3 " type="time" placeholder="Enter your name"
                  v-model="form[dayToEdit].ora_inizio_am" :state="
                    validateTime(
                      form[dayToEdit].ora_fine_am,
                      form[dayToEdit].ora_inizio_am
                    )
                  "></b-form-input>
              </b-form-group>
              <b-form-group label="Mattina alle" label-for="day" class="
                  text-left
                  fs-6
                  text-font-grey
                  mx-auto
                  col-sm-12 col-md-5
                  px-0
                ">
                <span v-if="form[dayToEdit].ora_fine_am != null" @click="form[dayToEdit].ora_fine_am = null"
                  class="text-danger absolute_icon_left">
                  <b-icon icon="x-circle-fill" font-scale="1.5"></b-icon>
                </span>
                <b-form-input class="mx-auto my-3 " type="time" placeholder="Enter your name"
                  v-model="form[dayToEdit].ora_fine_am" :state="
                    validateTime(
                      form[dayToEdit].ora_fine_am,
                      form[dayToEdit].ora_inizio_am
                    )
                  "></b-form-input>
              </b-form-group>
            </div>
            <div class="col-12 d-flex flex-wrap px-0">
              <b-form-group label="Pomeriggio dalle" label-for="day" class="
                  text-left
                  fs-6
                  text-font-grey
                  mx-auto
                  col-sm-12 col-md-5
                  px-0
                "><span class="text-danger absolute_icon_left" v-if="form[dayToEdit].ora_inizio_pm != null"
                  @click="form[dayToEdit].ora_inizio_pm = null">
                  <b-icon icon="x-circle-fill" font-scale="1.5"></b-icon>
                </span>
                <b-form-input class="mx-auto my-3 " type="time" placeholder="Enter your name"
                  v-model="form[dayToEdit].ora_inizio_pm" :state="
                    validateTime(
                      form[dayToEdit].ora_fine_pm,
                      form[dayToEdit].ora_inizio_pm
                    )
                  "></b-form-input>
              </b-form-group>

              <b-form-group label="Pomeriggio alle" label-for="day" class="
                  text-left
                  fs-6
                  text-font-grey
                  mx-auto
                  col-sm-12 col-md-5
                  px-0
                "><span class="text-danger absolute_icon_left" v-if="form[dayToEdit].ora_fine_pm != null"
                  @click="form[dayToEdit].ora_fine_pm = null">
                  <b-icon icon="x-circle-fill" font-scale="1.5"></b-icon>
                </span>
                <b-form-input class="mx-auto my-3 " type="time" placeholder="Enter your name"
                  v-model="form[dayToEdit].ora_fine_pm" :state="
                    validateTime(
                      form[dayToEdit].ora_fine_pm,
                      form[dayToEdit].ora_inizio_pm
                    )
                  "></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="text-right">
            <b-button type="button" variant="outline-primary modal-footer-button m-3" @click="saveChanges">Ok
            </b-button>
          </div>
        </div>
      </b-modal>
      <b-modal centered ref="modal-duplicate" title="Duplica fascia oraria" hide-footer>
        <div v-if="dayToEdit">
          <div>
            <b-form-group label="Duplica per" label-for="daydupl" class="text-left fs-6 text-font-grey mx-auto col">
              <b-form-select class="my-3  text-primary bg-white" name="daydupl" v-model="duplicateForDay">
                <b-form-select-option v-for="(day, val) in days" :key="val" :value="val" :disabled="dayToEdit == day">{{
                day
                }}</b-form-select-option>
                <b-form-select-option :value="null">Tutta la settimana</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </div>
          <div class="text-right">
            <b-button type="button" variant="outline-primary modal-footer-button m-3" @click="duplicateDay">Ok
            </b-button>
          </div>
        </div>
      </b-modal>
      <b-modal centered ref="modal-new" title="Nuova fascia oraria" hide-footer>
        <div v-if="creatingNew">
          <div>
            <b-form-group label="Giorno" label-for="day" class="text-left fs-6 text-font-grey mx-auto col">
              <!-- <b-form-select
                class="my-3  text-primary bg-white"
                :options="stringDays"
                name="day"
                v-model="dayToEdit"
              > -->
              <b-form-select class="my-3  bg-white" name="day" v-model="dayToEdit">
                <b-form-select-option v-for="(dd, ndx) in stringDays" :key="dd" :value="dd"
                  :disabled="checkIfPreviouslyAdded(ndx) ? true : false">{{ dd }}</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </div>
          <div v-if="dayToEdit">
            <b-form-group label="Minuti per appuntamento" label-for="interval"
              class="text-left fs-6 text-font-grey mx-auto col">
              <span class="col-1 fw-bold text-primary range_value">{{
              form[dayToEdit].interval
              }}</span>
              <b-form-input class="text-right col-10 ml-auto my-3 " type="range" name="tot_perday"
                v-model="form[dayToEdit].interval" min="5" step="5" max="60"></b-form-input>
            </b-form-group>
            <b-form-group label="Numero totale di appuntamenti al giorno" label-for="tot_perday"
              class="text-left fs-6 text-font-grey mx-auto col">
              <b-form-input class="mx-auto my-3 " type="number" name="tot_perday"
                v-model="form[dayToEdit].tot_perday" :min="1" :step="1"></b-form-input>
            </b-form-group>
            <div>
              <div class="col-12 d-flex flex-wrap px-0">
                <b-form-group label="Mattina dalle" label-for="day" class="
                    text-left
                    fs-6
                    text-font-grey
                    mx-auto
                    col-sm-12 col-md-5
                    px-0
                  "><span v-if="form[dayToEdit].ora_inizio_am != null" class="text-danger absolute_icon_left"
                    @click="form[dayToEdit].ora_inizio_am = null">
                    <b-icon icon="x-circle-fill" font-scale="1.5"></b-icon>
                  </span>
                  <b-form-input class="mx-auto my-3 " type="time" placeholder="Enter your name"
                    v-model="form[dayToEdit].ora_inizio_am" :state="
                      validateTime(
                        form[dayToEdit].ora_fine_am,
                        form[dayToEdit].ora_inizio_am
                      )
                    "></b-form-input>
                </b-form-group>
                <b-form-group label="Mattina alle" label-for="day" class="
                    text-left
                    fs-6
                    text-font-grey
                    mx-auto
                    col-sm-12 col-md-5
                    px-0
                  ">
                  <span v-if="form[dayToEdit].ora_fine_am != null" @click="form[dayToEdit].ora_fine_am = null"
                    class="text-danger absolute_icon_left">
                    <b-icon icon="x-circle-fill" font-scale="1.5"></b-icon>
                  </span>
                  <b-form-input class="mx-auto my-3 " type="time" placeholder="Enter your name"
                    v-model="form[dayToEdit].ora_fine_am" :state="
                      validateTime(
                        form[dayToEdit].ora_fine_am,
                        form[dayToEdit].ora_inizio_am
                      )
                    "></b-form-input>
                </b-form-group>
              </div>
              <div class="col-12 d-flex flex-wrap px-0">
                <b-form-group label="Pomeriggio dalle" label-for="day" class="
                    text-left
                    fs-6
                    text-font-grey
                    mx-auto
                    col-sm-12 col-md-5
                    px-0
                  "><span class="text-danger absolute_icon_left" v-if="form[dayToEdit].ora_inizio_pm != null"
                    @click="form[dayToEdit].ora_inizio_pm = null">
                    <b-icon icon="x-circle-fill" font-scale="1.5"></b-icon>
                  </span>
                  <b-form-input class="mx-auto my-3 " type="time" placeholder="Enter your name"
                    v-model="form[dayToEdit].ora_inizio_pm" :state="
                      validateTime(
                        form[dayToEdit].ora_fine_pm,
                        form[dayToEdit].ora_inizio_pm
                      )
                    "></b-form-input>
                </b-form-group>

                <b-form-group label="Pomeriggio alle" label-for="day" class="
                    text-left
                    fs-6
                    text-font-grey
                    mx-auto
                    col-sm-12 col-md-5
                    px-0
                  "><span class="text-danger absolute_icon_left" v-if="form[dayToEdit].ora_fine_pm != null"
                    @click="form[dayToEdit].ora_fine_pm = null">
                    <b-icon icon="x-circle-fill" font-scale="1.5"></b-icon>
                  </span>
                  <b-form-input class="mx-auto my-3 " type="time" placeholder="Enter your name"
                    v-model="form[dayToEdit].ora_fine_pm" :state="
                      validateTime(
                        form[dayToEdit].ora_fine_pm,
                        form[dayToEdit].ora_inizio_pm
                      )
                    "></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="text-right">
            <b-button type="button" variant="outline-primary modal-footer-button m-3" @click="saveNew">Ok</b-button>
          </div>
        </div>
      </b-modal>
      <!-- <b-modal
        centered
        ref="modal-exitalert"
        title="Vuoi uscire senza salvare?"
        hide-footer
      >
        <p>Le modifiche effettuate andranno perse.</p>
        <div class="text-center">
          <b-button type="button" variant="outline-danger "
            >Procedi comunque</b-button
          >
          <b-button
            @click="$refs['modal-exitalert'].hide()"
            type="button"
            variant="primary text-white  mx-2"
            >Annulla</b-button
          >
        </div>
      </b-modal> -->
    </div>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
export default {
  name: "SettingServiceOrari",
  props: ["orari", "servicename"],
  components: {},
  computed: {
    ...mapState("utente", ["status", "user"]),
    // ...mapState("company", ["companyLoggedIn", "company"]),
  },
  data() {
    return {
      stringDays: [
        "lunedì",
        "martedì",
        "mercoledì",
        "giovedì",
        "venerdì",
        "sabato",
        "domenica",
      ],
      showSave: false,
      dayToEdit: 0,
      newDay: "lunedì",
      creatingNew: false,
      duplicateForDay: null,
      days: {
        1: "lunedì",
        2: "martedì",
        3: "mercoledì",
        4: "giovedì",
        5: "venerdì",
        6: "sabato",
        7: "domenica",
      },
      openings: [],
      form: {
        lunedì: {
          day: 1,
          ora_inizio_am: "08:00",
          ora_fine_am: null,
          ora_inizio_pm: null,
          ora_fine_pm: "20:00",
          interval: 15,
          tot_perday: 1,
          delete: true,
        },
        martedì: {
          day: 2,
          ora_inizio_am: "08:00",
          ora_fine_am: null,
          ora_inizio_pm: null,
          ora_fine_pm: "20:00",
          interval: 15,
          tot_perday: 1,
          delete: true,
        },
        mercoledì: {
          day: 3,
          ora_inizio_am: "08:00",
          ora_fine_am: null,
          ora_inizio_pm: null,
          ora_fine_pm: "20:00",
          interval: 15,
          tot_perday: 1,
          delete: true,
        },
        giovedì: {
          day: 4,
          ora_inizio_am: "08:00",
          ora_fine_am: null,
          ora_inizio_pm: null,
          ora_fine_pm: "20:00",
          interval: 15,
          tot_perday: 1,
          delete: true,
        },
        venerdì: {
          day: 5,
          ora_inizio_am: "08:00",
          ora_fine_am: null,
          ora_inizio_pm: null,
          ora_fine_pm: "20:00",
          interval: 15,
          tot_perday: 1,
          delete: true,
        },
        sabato: {
          day: 6,
          ora_inizio_am: "08:00",
          ora_fine_am: null,
          ora_inizio_pm: null,
          ora_fine_pm: "20:00",
          interval: 15,
          tot_perday: 1,
          delete: true,
        },
        domenica: {
          day: 7,
          ora_inizio_am: "08:00",
          ora_fine_am: null,
          ora_inizio_pm: null,
          ora_fine_pm: "20:00",
          interval: 15,
          tot_perday: 1,
          delete: true,
        },
      },
    };
  },
  methods: {
    ...mapActions("utente", ["me"]),
    setForm() {
      var self = this;
      this.openings.map(function (item, index) {
        var name = self.days[item.day];
        self.form[name].ora_inizio_am = item.ora_inizio_am;
        self.form[name].ora_fine_am = item.ora_fine_am;
        self.form[name].ora_inizio_pm = item.ora_inizio_pm;
        self.form[name].ora_fine_pm = item.ora_fine_pm;
        self.form[name].interval = item.interval;
        self.form[name].tot_perday = item.tot_perday;
        self.form[name].delete = false;
      });
    },
    resetOpenings() {
      this.openings.sort(function (a, b) {
        return a.day - b.day;
      });
      this.duplicateForDay = null;
    },
    removeDay(ind) {
      let dayToRemove = this.openings[ind].day;
      this.openings.splice(ind, 1);
      this.showSave = true;
      this.form[this.days[dayToRemove]].interval = 15;
      this.form[this.days[dayToRemove]].tot_perday = 1;
      this.form[this.days[dayToRemove]].ora_inizio_am = null;
      this.form[this.days[dayToRemove]].ora_inizio_pm = null;
      this.form[this.days[dayToRemove]].ora_fine_am = null;
      this.form[this.days[dayToRemove]].ora_fine_pm = null;
      this.form[this.days[dayToRemove]].delete = true;
    },
    openEditDay(ind) {
      console.log("openEditDay", ind)
      console.log("this.openings[ind]", this.openings[ind])

      this.dayToEdit = this.days[this.openings[ind].day];
      this.form[this.dayToEdit].tot_perday = this.openings[ind].tot_perday;
      this.form[this.dayToEdit].interval = this.openings[ind].interval;
      this.form[this.dayToEdit].ora_inizio_am = this.openings[ind].ora_inizio_am;
      this.form[this.dayToEdit].ora_fine_am = this.openings[ind].ora_fine_am;
      this.form[this.dayToEdit].ora_inizio_pm = this.openings[ind].ora_inizio_pm;
      this.form[this.dayToEdit].ora_fine_pm = this.openings[ind].ora_fine_pm;
      this.form[this.dayToEdit].delete = false;
      this.$refs["modal-edit"].show();
    },
    openNewDay(ind) {
      this.creatingNew = true;
      this.dayToEdit = this.days[ind];
      this.$refs["modal-new"].show();
    },
    saveChanges() {
      this.openings.forEach((element, index) => {
        if (element.day === this.form[this.dayToEdit].day) {
          this.openings[index].interval = this.form[this.dayToEdit].interval;
          this.openings[index].tot_perday =
            this.form[this.dayToEdit].tot_perday;
          this.openings[index].ora_inizio_am =
            this.form[this.dayToEdit].ora_inizio_am;
          this.openings[index].ora_inizio_pm =
            this.form[this.dayToEdit].ora_inizio_pm;
          this.openings[index].ora_fine_am =
            this.form[this.dayToEdit].ora_fine_am;
          this.openings[index].ora_fine_pm =
            this.form[this.dayToEdit].ora_fine_pm;
          this.openings[index].delete = false
        }
      });
      this.$refs["modal-edit"].hide();
      this.showSave = true;
    },
    saveNew() {
      var dayNew = this.form[this.dayToEdit].day;
      var indexMatch = null;
      for (let dd = 0; dd <= 6; dd++) {
        if (this.openings[dd] && this.openings[dd].day == dayNew) {
          indexMatch = dd;
          this.openings[dd].ora_inizio_am =
            this.form[this.dayToEdit].ora_inizio_am;
          this.openings[dd].ora_fine_am = this.form[this.dayToEdit].ora_fine_am;
          this.openings[dd].ora_inizio_pm =
            this.form[this.dayToEdit].ora_inizio_pm;
          this.openings[dd].ora_fine_pm = this.form[this.dayToEdit].ora_fine_pm;
          this.openings[dd].interval = this.form[this.dayToEdit].interval;
          this.openings[dd].tot_perday = this.form[this.dayToEdit].tot_perday;
        }
      }
      if (indexMatch === null) {
        console.log("indexMatch === null")
        var planning = {
          day: dayNew,
          ora_inizio_am: this.form[this.dayToEdit].ora_inizio_am,
          ora_fine_am: this.form[this.dayToEdit].ora_fine_am,
          ora_inizio_pm: this.form[this.dayToEdit].ora_inizio_pm,
          ora_fine_pm: this.form[this.dayToEdit].ora_fine_pm,
          interval: this.form[this.dayToEdit].interval,
          tot_perday: this.form[this.dayToEdit].tot_perday,
        };
        this.openings.push(planning);
        this.form[this.dayToEdit].delete = false;
        this.resetOpenings();
      }
      this.showSave = true;
      this.creatingNew = false;
      this.$refs["modal-new"].hide();
    },
    formatTime(time) {
      if (time) {
        return moment(time, "HH:mm").format("HH:mm");
      }
      return "";
    },
    checkIfPreviouslyAdded(indexday) {
      if (this.orari) {
        // console.log(this.orari);
        var self = this;
        var indxOf = Object.keys(this.orari).filter(
          (element) => self.orari[element].day === indexday + 1
        );
        // console.log("indxOf", indxOf);
        return indxOf.length > 0 ? true : false;
      } else {
        return false;
      }
    },
    validateTime(timeFrom, timeTo) {
      if (!timeFrom && !timeTo) {
        return null;
      }
      var from = moment(timeFrom, "HH:mm");
      var to = moment(timeTo, "HH:mm");
      if (
        moment(to).isSameOrBefore(from) == false &&
        this.form[this.dayToEdit].ora_inizio_am != null &&
        this.form[this.dayToEdit].ora_fine_pm != null
      ) {
        if (
          !this.form[this.dayToEdit].ora_inizio_pm &&
          !this.form[this.dayToEdit].ora_fine_am
        ) {
          return true;
        }
        return false;
      }
      return moment(to).isSameOrBefore(from);
    },
    openDuplicateDay(index) {
      this.dayToEdit = this.days[this.openings[index].day];
      this.$refs["modal-duplicate"].show();
    },
    duplicateDay() {
      if (this.duplicateForDay === null) {
        var newOpenings = [];
        var self = this;
        Object.keys(this.form).map((element, index) => {
          self.form[element].interval = self.form[self.dayToEdit].interval;
          self.form[element].tot_perday = self.form[self.dayToEdit].tot_perday;
          self.form[element].ora_inizio_am =
            self.form[self.dayToEdit].ora_inizio_am;
          self.form[element].ora_inizio_pm =
            self.form[self.dayToEdit].ora_inizio_pm;
          self.form[element].ora_fine_am =
            self.form[self.dayToEdit].ora_fine_am;
          self.form[element].ora_fine_pm =
            self.form[self.dayToEdit].ora_fine_pm;
          self.form[element].delete = false;
          var planning = {
            day: self.form[element].day,
            ora_inizio_am: self.form[self.dayToEdit].ora_inizio_am,
            ora_fine_am: self.form[self.dayToEdit].ora_fine_am,
            ora_inizio_pm: self.form[self.dayToEdit].ora_inizio_pm,
            ora_fine_pm: self.form[self.dayToEdit].ora_fine_pm,
            interval: self.form[self.dayToEdit].interval,
            tot_perday: self.form[self.dayToEdit].tot_perday,
          };
          newOpenings.push(planning);
        });
        this.openings = newOpenings;
      } else {
        var dayName = this.days[this.duplicateForDay];
        this.form[dayName].interval = this.form[this.dayToEdit].interval;
        this.form[dayName].tot_perday = this.form[this.dayToEdit].tot_perday;
        this.form[dayName].ora_inizio_am =
          this.form[this.dayToEdit].ora_inizio_am;
        this.form[dayName].ora_inizio_pm =
          this.form[this.dayToEdit].ora_inizio_pm;
        this.form[dayName].ora_fine_am = this.form[this.dayToEdit].ora_fine_am;
        this.form[dayName].ora_fine_pm = this.form[this.dayToEdit].ora_fine_pm;
        this.form[dayName].delete = false;
        var indexMatch = null;
        for (let dd = 0; dd <= 6; dd++) {
          if (
            this.openings[dd] &&
            this.openings[dd].day == this.duplicateForDay
          ) {
            indexMatch = dd;
            this.openings[dd].ora_inizio_am =
              this.form[this.dayToEdit].ora_inizio_am;
            this.openings[dd].ora_fine_am =
              this.form[this.dayToEdit].ora_fine_am;
            this.openings[dd].ora_inizio_pm =
              this.form[this.dayToEdit].ora_inizio_pm;
            this.openings[dd].ora_fine_pm =
              this.form[this.dayToEdit].ora_fine_pm;
            this.openings[dd].interval = this.form[this.dayToEdit].interval;
            this.openings[dd].tot_perday = this.form[this.dayToEdit].tot_perday;
          }
        }
        if (indexMatch === null) {
          var planning = {
            day: parseInt(this.duplicateForDay),
            ora_inizio_am: this.form[this.dayToEdit].ora_inizio_am,
            ora_fine_am: this.form[this.dayToEdit].ora_fine_am,
            ora_inizio_pm: this.form[this.dayToEdit].ora_inizio_pm,
            ora_fine_pm: this.form[this.dayToEdit].ora_fine_pm,
            interval: this.form[this.dayToEdit].interval,
            tot_perday: this.form[this.dayToEdit].tot_perday,
          };
          this.openings.push(planning);
        }
      }
      this.$refs["modal-duplicate"].hide();
      this.resetOpenings();
      this.showSave = true;
    },
    submitChanges() {
      if (this.form) {
        var data = {
          form: this.form,
          service_name: this.servicename,
          service_hash: this.servicename,
        };
        this.$emit("store-service-openings", data);
      }
      this.showSave = false;
    },
    setupEditForm(orari) {
      const self = this
      Object.keys(orari).map((element, index) => {
        self.form[self.days[orari[element].day]].interval = orari[element].interval;
        self.form[self.days[orari[element].day]].tot_perday = orari[element].tot_perday;
        self.form[self.days[orari[element].day]].ora_inizio_am = orari[element].ora_inizio_am;
        self.form[self.days[orari[element].day]].ora_inizio_pm = orari[element].ora_inizio_pm;
        self.form[self.days[orari[element].day]].ora_fine_am = orari[element].ora_fine_am;
        self.form[self.days[orari[element].day]].ora_fine_pm = orari[element].ora_fine_pm;
        self.form[self.days[orari[element].day]].delete = false;
      });
    }
  },
  watch: {
    orari(val) {
      if (val) {
        // console.log("orari val", val);
        this.openings = val;
        this.setForm();
      }
    },
  },
  created() {
    this.openings = this.orari;
    if (this.orari.length > 0) {
      this.setupEditForm(this.orari)
    }
    console.log("azienda in settind", this.openings);
  },
  mounted() {
    // this.setForm();
  },
  // beforeRouteLeave(to, from, next) {
  //   console.log("eeee", this.showSave);
  //   if (this.showSave) {
  //     $refs["modal-exitalert"].show();
  //   }
  //   // called when the route that renders this component is about to
  //   // be navigated away from.
  //   // has access to `this` component instance.
  // },
};
</script>
<style scoped>
.range_value {
  font-size: 1.1rem;
  vertical-align: super;
}

.absolute_icon_left {
  position: absolute;
  /* left: 3rem; */
  /* color: transparent !important; */
  cursor: pointer;
}
</style>