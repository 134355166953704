
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FBS_KEY,
  authDomain: process.env.VUE_APP_FBS_DOMAIN,
  databaseURL: process.env.VUE_APP_FBS_DB_URL,
  projectId: process.env.VUE_APP_FBS_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FBS_BUCKET,
  messagingSenderId: process.env.VUE_APP_FBS_SENDERID,
  appId: process.env.VUE_APP_FBS_APP_ID,
  measurementId: process.env.VUE_APP_FBS_MEASUREMENT_ID
};

export default firebaseConfig;