<template>
  <div
    v-if="!$root.isMobile"
    class="
      bg-white
      shadow_3
      px-0
      pb-4
      col-1
      d-flex
      flex-column
      justify-content-center
      ml-3
      mr-1
      general_border
      border border-white
    "
  >
    <router-link class="mt-3 mb-0 no__hover" :to="{ name: 'Home' }">
      Logo
    </router-link>
    <router-link class="my-3" :to="{ name: 'Home' }">
      <b-icon font-scale="1.5" icon="house-door"> </b-icon>
    </router-link>
    <router-link v-if="canSeeProfilo" class="my-3" :to="{ name: 'Profilo' }">
      <b-icon font-scale="1.5" icon="person"></b-icon>
    </router-link>

    <!-- <router-link v-if="user.type === 1" class="my-3" :to="{ name: 'Orari' }">
      <b-icon font-scale="1.5" icon="clock"></b-icon>
    </router-link> -->
    <router-link
      v-if="canSeeImpostazioni"
      class="my-3"
      :to="{ name: 'Impostazioni' }"
    >
      <b-icon font-scale="1.5" icon="gear"></b-icon>
    </router-link>
  </div>
  <transition-group
    name="slide"
    v-else
    :class="menuOpen ? 'px-3 shadow_7' : 'px-1'"
    class="
      responsive__menu
      bg-white
      shadow_3
      d-flex
      flex-column
      justify-content-center
      ml-3
      mr-1
      general_border
      border border-white
    "
  >
    <!-- <transition-group name="slide"> -->
    <!-- <span
      key="openicon"
      @click="menuOpen = !menuOpen"
      :class="menuOpen ? 'pb-3 order-5' : 'order-1'"
    >
      <b-icon v-if="menuOpen" font-scale="1.7" icon="menu-down"></b-icon>
      <b-iconstack font-scale="2.5" v-else>
        <b-icon
          stacked
          icon="circle-fill"
          variant="secondary"
          scale="1.4"
        ></b-icon>
        <b-icon stacked icon="menu-up" scale="0.85" variant="white"></b-icon>
      </b-iconstack>
    </span> -->
    <router-link
      v-if="menuOpen"
      key="gohome"
      class="my-3"
      :to="{ name: 'Home' }"
    >
      <b-icon font-scale="1.6" icon="house-door"> </b-icon>
    </router-link>
    <router-link
      v-if="canSeeProfilo && menuOpen"
      key="goprof"
      class="my-3"
      :to="{ name: 'Profilo' }"
    >
      <b-icon font-scale="1.6" icon="person"></b-icon>
    </router-link>

    <!-- <router-link
      key="gotime"
      v-if="menuOpen && user.type === 1"
      class="my-3"
      :to="{ name: 'Orari' }"
    >
      <b-icon font-scale="1.6" icon="clock"></b-icon>
    </router-link> -->
    <router-link
      v-if="menuOpen && canSeeImpostazioni"
      key="gosetts"
      class="my-3"
      :to="{ name: 'Impostazioni' }"
    >
      <b-icon font-scale="1.6" icon="gear"></b-icon>
    </router-link>
    <span
      key="openicon"
      @click="menuOpen = !menuOpen"
      :class="menuOpen ? 'pb-3 order-5' : 'order-1'"
    >
      <b-icon v-if="menuOpen" font-scale="1.7" icon="menu-down"></b-icon>
      <b-iconstack font-scale="2.5" v-else>
        <b-icon
          stacked
          icon="circle-fill"
          variant="secondary"
          scale="1.4"
        ></b-icon>
        <b-icon stacked icon="menu-up" scale="0.85" variant="white"></b-icon>
      </b-iconstack>
    </span>
    <!-- </transition-group> -->
  </transition-group>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "LeftHeader",
  computed: {
    canSeeImpostazioni() {
      if (this.user.type === 1) {
        return (
          this.user.permessi.includes("visualizzare anagrafica azienda") ||
          this.user.permessi.includes("gestire impostazioni dei servizi") ||
          this.user.permessi.includes("gestire operatori")
        );
      }
      return true;
    },
    canSeeProfilo() {
      if (this.user.type === 1 && !Array.isArray(this.user.company)) {
        return this.user.email !== this.user.company.email;
      }
      return true;
    },
    ...mapState("utente", ["status", "user"]),
  },
  data() {
    return {
      menuOpen: false,
    };
  },
};
</script>
<style >
.responsive__menu {
  z-index: 300;
  /* position: absolute;
  bottom: 0; */
  bottom: 3vh;
  position: fixed;
  /* position: -webkit-sticky;
  position: sticky;
  top: calc(90% - 30px);
  top: 90%; */
}
.no__hover {
  color: white;
}
.no__hover:hover,
.no__hover:focus {
  color: white !important;
}
</style>