<template>
  <div class="pb-4 mb-4 text-left">
    <h4 class=" pb-1 mt-1 mb-4">Manuale d'uso</h4>
    <b-row>
      <b-col>
        <h5>
        <a href="/assets/docs/ManualePazienti_1_1.pdf" target="_blank" class="text-primary point"> <u>Scarica versione 1</u></a>
      </h5>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "GuidaCittadino",
};
</script>

<style>

</style>
