<template>
  <div>
    <barcode v-bind:value="barcodeValue">
      {{ barcodeValue !== null ? barcodeValue : "codice non valido" }}
    </barcode>
  </div>
</template>
<script>
import VueBarcode from "vue-barcode";

export default {
  name: "BarcodeShower",
  props: ["tipo", "code"],
  components: {
    barcode: VueBarcode,
  },
  data() {
    return {
      barcodeValue: this.code,
    };
  },
};
</script>