<template>
  <div>
    <router-link
      class="my-3 mx-auto"
      :to="homePath + '/teleconsulto'"
      :class="this.$route.name === 'teleconsulto' && 'router-link-exact-active'"
    >
      <b-icon
        font-scale="1.5"
        :icon="
          this.$route.name === 'teleconsulto' ? 'calendar2-fill' : 'calendar'
        "
      ></b-icon>
      <p class="mb-0 fs-5">Prenotazioni</p></router-link
    >
    <router-link
      v-if="user.type == 1"
      class="my-3 mx-auto"
      :to="homePath + '/teleconsulto/videoconsulto'"
      :class="
        this.$route.name === 'videoconsulto' && 'router-link-exact-active'
      "
    >
      <b-icon
        font-scale="1.5"
        :icon="
          this.$route.name === 'videoconsulto'
            ? 'camera-video-fill'
            : 'camera-video'
        "
      ></b-icon>
      <p class="mb-0 fs-5">Videoconsulto</p></router-link
    >
    <router-link
      v-if="user.type == 1"
      class="my-3 mx-auto"
      :to="{ name: 'telecalendario', query: { rif: 2 } }"
      :class="
        this.$route.name === 'telecalendario' && 'router-link-exact-active'
      "
    >
      <b-icon
        font-scale="1.5"
        :icon="
          this.$route.name === 'telecalendario'
            ? 'calendar-week-fill'
            : 'calendar-week'
        "
      ></b-icon>
      <p class="mb-0 fs-5">Calendario</p></router-link
    >
    <!-- <router-link class="my-3 mx-auto" :to="{ name: 'prenotazioni' }">
      <b-icon
        font-scale="1.5"
        :icon="
          this.$route.name === 'Impostazioni'
            ? 'file-earmark-richtext-fill'
            : 'file-earmark-richtext'
        "
      ></b-icon>telecalendario
      <p class="mb-0 fs-5">Materiale informativo</p></router-link
    > -->
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "PrenotazioniMenu",
  computed: {
    homePath() {
      if (this.user) {
        return "/" + this.user.i_am;
      }
    },
    ...mapState("utente", ["status", "user"]),
  },
  data() {
    return {};
  },
};
</script>