<template>
  <div>
    <b-row
      cols="1"
      cols-md="2"
      class="justify-content-bewteen py-3"
      align-v="center"
    >
      <b-col class="align-self-end">
        <h4 class="text-prim-grad-1 text-left pb-1 page-title mb-0">
          I tuoi appuntamenti in ASL
        </h4>
      </b-col>
      <b-col class="text-left text-md-right">
        <b-button @click="goToNew">
          <b-icon icon="plus"></b-icon> prenota
        </b-button>
      </b-col>
    </b-row>
    <b-row class="justify-content-end px-2" align-v="center">
      <b-col>
        <AslCard @selected-asl="handleSelectedAsl($event)"></AslCard>
      </b-col>
      <b-col cols="5" md="4" class="align-self-end">
        <b-form-group>
          <b-form-checkbox
            id="hide-canceled"
            name="hide-canceled"
            size="sm"
            :value="true"
            :unchecked-value="false"
            @change="fetchData"
            v-model="filters.notCanceled"
          >
            Escludi appuntamenti annullati
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col cols="4" md="3" class="text-left">
        <b-form-group label="Anno">
          <b-form-select
            v-model="filters.year"
            :options="yearsOptions"
            @change="fetchData"
          >
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <template v-if="reservations !== null">
      <b-row class="px-4 mt-2">
        <b-col cols="12" lg="10">
          <b-row
            cols="1"
            cols-md="2"
            v-for="(order, ind) in reservations"
            :key="ind"
            @click="selectedIndex = ind"
            class="puntofarmaCard py-3 my-2"
          >
            <b-col cols="12" md="2">
              <div class="d-flex flex-md-column text-left text-md-center">
                <span>
                  <b-icon
                    font-scale="2"
                    :icon="statusModalIcons[order.stato]"
                    :variant="statusModalVariants[order.stato]"
                  >
                  </b-icon>
                </span>
                <span class="mt-2 text-left text-md-center">{{
                  order.stato
                }}</span>
              </div>
            </b-col>
            <b-col class="text-left" cols="12" md="10">
              <b-row class="justify-content-between">
                <h6 class="text-uppercase">
                  {{ order.orderable.prestazione.name }}
                </h6>
                <span
                  class="text-primary ml-1 point"
                  v-if="order.unreadMessages"
                  @click="$bvModal.show('modal-resdetails-storico-asl')"
                  ><b-icon icon="chat-dots-fill" font-scale="1.4"></b-icon>
                  <b-badge class="fs_badge ml-1" variant="danger">{{
                    order.unreadMessages
                  }}</b-badge>
                </span>
              </b-row>
              <b-row align-v="center" class="mt-2 mt-md-3">
                <h5 class="text-capitalize">
                  <span>{{ formatData(order.data_inizio) }}</span>
                </h5>
                <b-col class="text-right pr-0">
                  <b-button
                    size="sm"
                    variant="info border border-info-grad-1"
                    @click="$refs['modal-resdetails-storico-asl'].show()"
                    class="mt-1"
                    >Vedi dettagli
                  </b-button>

                  <b-dropdown
                    size="sm"
                    right
                    variant="primary text-white"
                    text="Scarica"
                    class="general_border ml-1 mt-1"
                  >
                    <b-dropdown-item
                      class="primary-li-hover"
                      @click="downloadPdf(ind)"
                      >Promemoria</b-dropdown-item
                    >
                    <b-dropdown-item
                      class="primary-li-hover"
                      @click="downloadCalendar(ind)"
                      >Calendar</b-dropdown-item
                    >
                  </b-dropdown>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <h6 v-if="reservations.length === 0" class="text-center mt-3">
        Nessun appuntamento nell'anno {{ filters.year }}.
      </h6>
    </template>

    <b-modal
      size="xl"
      centered
      id="modal-resdetails-storico-asl"
      ref="modal-resdetails-storico-asl"
      hide-footer
      hide-header
      scrollable
      body-class="p-0"
      @hidden="selectedIndex = null"
    >
      <b-spinner variant="secondary" label="loading" v-if="loading"></b-spinner>
      <DettaglioPrenotazioneOperatore
        v-if="selectedIndex !== null"
        :chat-recipient-hash="reservations[selectedIndex].company.hashid"
        chat-recipient-type="company"
        :aslReservationView="true"
        :reservation="reservations[selectedIndex]"
        @close-modal="closeModal()"
        @fetch-data="fetchData()"
        @handle-update-request="handleUpdateRequest()"
        @handle-notes-update="handleNotesUpdate($event)"
        @handle-status-update="handleStatusUpdate($event)"
      />
    </b-modal>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { companyService, reservationService } from "@/_services";
import DettaglioPrenotazioneOperatore from "@/components/prenotazioni/DettaglioPrenotazioneOperatore.vue";
import AslCard from "@/components/appuntamenti/AslCard.vue";

export default {
  name: "StoricoAppuntamentiAsl",
  components: {
    DettaglioPrenotazioneOperatore,
    AslCard,
  },
  data() {
    return {
      reservations: null,
      filters: {
        year: null,
        notCanceled: true,
      },
      yearsOptions: [],
      selectedIndex: null,
      statusPositive: {
        1: "secondary",
        2: "info",
        3: "info",
        4: "primary",
        5: "ter-4",
        6: "ter-4",
      },
      statusNegative: {
        1: "danger",
        2: "ter-2",
        3: "ter-2",
        4: "dark",
      },
      statusModalIcons: {
        confermato: "check-circle",
        ricevuto: "archive",
        annullato: "x-circle",
        rifiutato: "x-circle",
        "in attesa di risposta": "question-circle",
        evaso: "calendar2-check",
      },
      statusModalVariants: {
        confermato: "secondary",
        ricevuto: "primary",
        annullato: "danger",
        rifiutato: "danger",
        "in attesa di risposta": "warning",
        evaso: "primary",
      },
      loading: false,
      ownAsl: null,
      icons: {
        0: "bag",
        1: "camera-video",
        2: "file-ppt",
        3: "chat-text",
        4: "screwdriver",
        5: "droplet-fill",
        6: "flower1",
        7: "emoji-smile",
        8: "exclude",
        9: "egg-fried",
        10: "gem",
        11: "heart-fill",
        12: "hourglass-split",
        13: "journal-medical",
        14: "life-preserver",
        15: "lock-fill",
        16: "moon",
        17: "node-minus-fill",
        18: "x-diamond-fill",
        19: "cart-fill",
        20: "bug-fill",
        21: "brightness-high-fill",
        22: "bell-fill",
      },
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    closeModal() {
      this.$refs["modal-resdetails-storico-asl"].hide();
    },
    goToNew() {
      this.$router.push({
        name: "appuntamentiASL",
        params: {
          section: "nuovo",
        },
      });
    },
    fetchData() {
      const self = this;
      companyService
        .getOwnAslReservations(this.filters)
        .then(function (res) {
          self.reservations = res.data.data;
          self.loading = false;
        })
        .catch(function (error) {
          console.log(error);
          self.errorAlert("non è stato possibile recuperare gli appuntamenti");
          self.loading = false;
        });
    },
    formatData(data) {
      return moment(data).format(" dddd DD MMMM YYYY - HH:mm");
    },
    fetchAsl() {
      var self = this;
      companyService
        .getOwnAsl()
        .then(function (res) {
          self.ownAsl = res.data.data.own;
        })
        .catch(function (err) {
          console.log(err);
          self.notFound = true;
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le informazioni.");
        });
    },
    handleStatusUpdate(
      statusData,
      reservation = this.reservations[this.selectedIndex]
    ) {
      this.loading = true;
      var self = this;
      if (!statusData.confirmed) {
        var form = statusData.message ? { message: statusData.message } : {};
        reservationService
          .userCancelOrReject(form, statusData.action, reservation.hashid)
          .then(function (re) {
            window.scrollTo(0, 0);
            self.successAlert("Richiesta aggiornata con successo");
            self.fetchData();
          })
          .catch(function (error) {
            self.loading = false;
            window.scrollTo(0, 0);
            self.errorAlert("non è stato possibile effettuare l'operazione");
          });
        this.$refs["modal-resdetails-storico-asl"].hide();
      } else {
        reservationService
          .userConfirmOrder(reservation.hashid)
          .then(function (re) {
            window.scrollTo(0, 0);
            self.successAlert("Richiesta aggiornata con successo");
            self.fetchData();
          })
          .catch(function (error) {
            self.loading = false;
            window.scrollTo(0, 0);
            self.errorAlert("non è stato possibile effettuare l'operazione");
          });
        this.$refs["modal-resdetails-storico-asl"].hide();
      }
    },
    downloadPdf(ind) {
      const hash = this.reservations[ind].hashid;
      const fName = this.reservations[ind].codice_richiesta;
      var self = this;
      reservationService
        .scaricaPromemoria(hash)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fName + ".pdf";
          link.click();
        })
        .catch(function (error) {
          self.loading = false;
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile scaricare il promemoria");
        });
    },
    downloadCalendar(ind) {
      const hash = this.reservations[ind].hashid;
      const fName = this.reservations[ind].codice_richiesta;
      var self = this;
      reservationService
        .scaricaCalendar(hash)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fName + ".ics";
          link.click();
        })
        .catch(function (error) {
          self.loading = false;
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile scaricare il promemoria");
        });
    },
    handleNotesUpdate(data) {
      this.reservations[this.selectedIndex].user_notes = data.user_notes;
    },
    handleUpdateRequest() {
      this.$bvModal.hide("modal-resdetails-storico-asl");
      this.successAlert("richiesta inviata con successo");
      this.fetchData();
    },
    handleSelectedAsl(asl) {
      //
    },
    openChatDetail(ind) {
      Promise.resolve(() =>
        this.$bvModal.show("modal-resdetails-storico-asl")
      ).then(() => {});
    },
  },
  created() {
    let now = moment();
    let currentYear = now.year();
    let startYear = 2022;
    for (let i = currentYear + 1; i >= startYear; i--) {
      let y = { value: `${i}`, text: `${i}` };
      this.yearsOptions.push(y);
    }
    this.filters.year = currentYear;
  },
  mounted() {
    this.fetchData();
  },
};
</script>
