<template>
  <div class="puntofarmaCard px-4 py-4">
    <!-- <p class="mb-0 text-left">Annulla tutte le prenotazioni</p> -->
    <b-form method="post" @submit.prevent="submitEvadi">
      <b-row class="">
        <b-col class="col-md-6 col-12 my-2 my-md-3 my-xl-4">
          <p class="text-left">Tipo Prenotazione*</p>
          <b-form-group label-for="type" class="text-left">
            <b-form-select name="type" class="general_border" :options="filterOptions.service" v-model="form.order_type"
              required>
              <b-form-select-option :value="null">scegli tipo</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col class="col-md-6 col-12 my-2 my-md-3 my-xl-4">
          <template v-if="form.order_type === 'prestazione'">
            <p class="text-left">Tipo Prestazione*</p>
            <b-form-group v-if="
              form.order_type === 'prestazione' && company.prestazioni_attive
            " label-for="subtype" class="text-left general_border">
              <b-form-select name="subtype" class="" v-model="form.subtype_hash">
                <b-form-select-option v-for="(hash, name) in company.prestazioni_attive" :key="name" :value="hash">{{
                    name
                }}</b-form-select-option>
                <b-form-select-option :value="null">tutte</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </template>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="col-md-6 col-12 my-2 my-md-3 my-xl-4">
          <p class="text-left">Per il giorno*</p>
          <b-form-group label-for="data" class="text-left">
            <b-form-input name="data" class="mx-auto general_border" type="date" v-model="form.data" required>
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col class="col-6 col-md-3 my-2 my-md-3 my-xl-4">
          <p class="text-left">Dalle</p>
          <b-form-group label-for="dal" class="text-left">
            <b-form-input name="dal" class="mx-auto general_border" type="time" v-model="form.dal"
              :required="form.al !== null" :state="form.al !== null ? validateTime() : null"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col class="col-6 col-md-3 my-2 my-md-3 my-xl-4">
          <p class="text-left">Alle</p>
          <b-form-group label-for="al" class="text-left">
            <b-form-input name="al" class="mx-auto" type="time" v-model="form.al" :required="form.dal !== null"
              :state="form.dal !== null ? validateTime() : null"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="col-12 my-2 my-md-3 my-xl-4">
          <p class="text-left">(*)parametri obbligatori</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="col-12 my-2 my-md-3 my-xl-4">
          <b-button @click="$refs['modal-confirm'].show()" :disabled="form.data == null"
            variant="secondary">Conferma</b-button>
        </b-col>
      </b-row>
      <b-modal size="xl" hide-footer centered ref="modal-confirm" title="Conferma operazione">
        <p class="mb-0">Sei sicuro di voler evadere le prenotazioni selezionate?</p>
        <div>
          <b-form-group label="Allega una nota:" v-slot="{ ariaDescribedby }">
            <!-- <b-form-radio class="my-1" v-model="message" :aria-describedby="ariaDescribedby" :value="1">"{{
                msgOptions[1]
            }}"</b-form-radio>
            <b-form-radio class="my-1" v-model="message" :aria-describedby="ariaDescribedby" :value="2">"{{
                msgOptions[2]
            }}"</b-form-radio>
            <b-form-radio class="my-1" v-model="message" :aria-describedby="ariaDescribedby" :value="3">"{{
                msgOptions[3]
            }}"</b-form-radio>
            <b-form-radio class="my-1" v-model="message" :aria-describedby="ariaDescribedby" :value="4">"{{
                msgOptions[4]
            }}"</b-form-radio> -->
            <b-form-radio v-model="message" :aria-describedby="ariaDescribedby" :value="6">nessuna nota
            </b-form-radio>
            <b-form-radio v-model="message" :aria-describedby="ariaDescribedby" :value="5">aggiungi nota
            </b-form-radio>
            <b-form-input v-if="message == 5" class="simple__input border-top-0 border-left-0 border-right-0"
              name="freeText" type="text" placeholder="nota..." autofocus v-model="freeText"></b-form-input>
          </b-form-group>
        </div>
        <div class="d-flex justify-content-end mt-3">
          <b-button @click="$refs['modal-confirm'].hide()" class="modal-footer-button px-5" type="button" variant="info ">No, chiudi
          </b-button>
          <b-button type="submit" class="modal-footer-button" variant="outline-primary mx-3 px-5" @click="submitEvadi">
            <b-spinner small v-if="submitting"></b-spinner><span v-else>Sì, procedi</span>
          </b-button>
        </div>
      </b-modal>
    </b-form>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { reservationService } from "@/_services";
import { mapState, mapActions } from "vuex";
export default {
  name: "EvadiUtility",
  data() {
    return {
      form: {
        data: null,
        order_type: "prestazione",
        subtype_hash: null,
        message: null,
        dal: null,
        al: null,
      },
      filterOptions: {
        status: ["in corso", "in attesa"],
        // service: ["prestazione"],
        service: ["prestazione", "prodotto", "tutte"],
      },
      message: 5,
      msgOptions: {
        // 1: "Ci spiace comunicarLe che non possiamo gestire ulteriori richieste per la data selezionata. La preghiamo di effettuare una nuova prenotazione per la settimana successiva alla data scelta.",
        // 2: "Ci spiace comunicarLe che a causa di problematiche relative all'approvvigionamento non possiamo gestire la sua richiesta per la data selezionata.",
        // 3: "Ci spiace comunicarLe che non possiamo gestire la sua richiesta per mancanza di dati essenziali. La preghiamo di completare la sua scheda anagrafica.",
        // 4: "Si è verificato un imprevisto che ci costringe a disdire il suo appuntamento. Se lo desidera, ci chiami per ulteriori informazioni.",
        5: null,
        6: null,
      },
      freeText: null,
      submitting: false,
    };
  },
  computed: {
    ...mapState("company", ["company"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    validateTime: function () {
      if (!this.form.dal || !this.form.al) {
        return null;
      }
      var from = moment(this.form.dal, "HH:mm");
      var to = moment(this.form.al, "HH:mm");
      //console.log(moment(from).isSameOrBefore(to));
      return moment(from).isSameOrBefore(to);
    },
    submitEvadi() {
      //console.log("submitting");
      this.submitting = true;
      this.form.message = this.freeText;
      if (this.message !== null && this.message !== 5) {
        this.form.message = this.msgOptions[this.message];
      }
      if (this.message === 6) {
        this.form.message = null;
      }
      var self = this;
      reservationService
        .massiveEvadi(this.form)
        .then(function (response) {
          var data = moment(self.form.data).format("D MMMM");
          window.scrollTo(0, 0);
          self.successAlert(
            "Prenotazioni " +
            self.form.order_type +
            " per il " +
            data +
            " evase correttamente"
          );
          self.$emit("update-prenotazioni");
          self.submitting = false;
          self.$refs["modal-confirm"].hide();
          self.form = {
            data: null,
            // order_type: "tutte",
            order_type: "prestazione",
            subtype_hash: null,
            message: null,
            dal: null,
            al: null,
          };
          self.message = 5;
          self.freeText = null;
        })
        .catch(function (error) {
          self.$refs["modal-confirm"].hide();
          self.submitting = false;
          window.scrollTo(0, 0);
          self.infoAlert("Nessuna prenotazione da evadere");
        });
    },
  },
  watch: {
    $route(val) {
      if (
        val.query.scope &&
        this.filterOptions.service.includes(val.query.scope)
      ) {
        this.form.order_type = val.query.scope;
      } else {
        // this.form.order_type = "tutte";
        this.form.order_type = "prestazione";
      }
    },
  },
  mounted() {
    if (
      this.$route.query.scope &&
      this.filterOptions.service.includes(this.$route.query.scope)
    ) {
      this.form.order_type = this.$route.query.scope;
    } else {
      //in app completa sarà "tutte"
      // this.form.order_type = "tutte";
      this.form.order_type = "prestazione";
    }
  },
};
</script>