<template>
  <b-container v-if="notFound">
    <b-row cols="1" class="text-left mb-4">
      <router-link class="text-primary no__hover" :to="{ name: 'pazienti' }">
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Pazienti
      </router-link>
    </b-row>
    <h5 class="font-weight-bolder" v-if="notFound">
      Nessun Paziente selezionato
    </h5>
  </b-container>
  <div v-else>
    <b-row cols="1" class="text-left mb-2">
      <router-link class="text-primary no__hover" :to="{
        name: 'scheda paziente',
        params: { hash: $route.params.hash },
        query: { section: 'alerts' },
      }">
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Impostazioni del paziente
      </router-link>
    </b-row>

    <b-row v-if="showDone" cols="1" class="text-center my-4">
      <h4 class="font-weight-bolder">Setting notifiche completato</h4>
      <b-button variant="primary primary__gradient text-white font-weight-bolder" class="col-10 col-md-5 mx-auto my-2"
        @click="
          $router.push({
            name: 'scheda paziente',
            params: { hash: $route.params.hash },
          })
        ">Torna alle impostazioni del paziente</b-button>
    </b-row>
    <b-row cols="1" class="text-left my-4" v-else>
      <h6 class="text-prim-grad-1 text-left pb-1 border-bottom border-line-grey" v-if="paziente">
        Replica il piano notifiche di {{ paziente.anagrafica.name }}
        {{ paziente.anagrafica.surname }}
      </h6>
      <b-row cols="1" class="my-2 pt-1" v-if="selectedTherapy">
        <div class="text-left">
          <p class="font-weight-bolder text-medium-grey pb-2">
            La terapia selezionata prevede
            {{ posNum }}
            {{
                posNum > 1
                  ? selectedTherapy.posology_type
                  : typesMap[selectedTherapy.posology_type]
            }}
            di {{ selectedTherapy.product_name }}
            {{ selectedTherapy.posologia.tipo }}
            <span v-if="selectedTherapy.posologia.frequenza">
              {{ selectedTherapy.posology_every_num }}
              {{ selectedTherapy.posologia.frequenza }}
            </span>
            per via
            <span class="text-lowercase">{{ selectedTherapy.posology_via }}.
            </span>
          </p>
          <b-col class="px-0">
            <b-row cols="1" class="font-weight-bolder">
              <b-col class="my-1">
                <b-form-group class="col-12 col-md-8 px-0">
                  <label class="mb-3" for="">Confezioni consegnate al paziente*</label>
                  <b-input-group class=" " style="border-radius: 0.7rem">
                    <b-form-input id="num-conf" name="num-conf" type="number" min="1" v-model="form.confezioni"
                      required></b-form-input>
                    <template #append v-if="!$root.isMobile">
                      <b-input-group-text class="
                          text-white
                          bg-prim-grad-1
                          border-prim-grad-1
                          font-weight-bolder
                        ">Confezioni</b-input-group-text>
                    </template>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col class="my-1">
                <b-form-group class="col-12 col-md-8 px-0">
                  <label class="mb-3" for="">{{ selectedTherapy.posology_type }} per confezione*</label>
                  <b-input-group class=" " style="border-radius: 0.7rem">
                    <b-form-input id="num-conf" name="num-conf" type="number" min="1" v-model="form.uic" required>
                    </b-form-input>
                    <template #append v-if="!$root.isMobile">
                      <b-input-group-text class="
                          text-white
                          bg-prim-grad-1
                          border-prim-grad-1
                          font-weight-bolder
                        ">{{ selectedTherapy.posology_type }} per
                        confezione</b-input-group-text>
                    </template>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col class="my-1">
                <b-form-group class="col-12 col-md-8 px-0">
                  <label class="mb-3" for="">Data inizio terapia*</label>
                  <b-form-input class=" " name="start_at" type="date" v-model="form.start_at" required>
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row cols="2" class="my-3 mt-2">
              <b-col class="font-weight-bolder">
                <b-form-group label="Invia notifica*" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group id="checkbox-freq" v-model="form.schedule" :options="scheduleOptions"
                    :aria-describedby="ariaDescribedby" name="checkbox-freq" stacked></b-form-checkbox-group>
                  <span class="fs-5" :class="
                    form.schedule.length > 3
                      ? 'text-danger'
                      : 'text-font-grey'
                  ">**min 1 opzione, max 3 opzioni</span>
                </b-form-group>
              </b-col>
              <b-col class="font-weight-bolder">
                <b-form-group label="Via*" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group id="checkbox-channel" v-model="form.channels" :options="channels"
                    :aria-describedby="ariaDescribedby" name="checkbox-channel" stacked></b-form-checkbox-group>
                  <span class="fs-5 text-font-grey">**min 1 opzione</span>
                </b-form-group>
              </b-col>
              <b-col class="col-12">
                <b-form-group class="font-weight-bolder" label="Il paziente riceverà la notifica* "
                  :description="timingDescription">
                  <b-form-radio-group id="radio-timing" v-model="form.timing" name="radio-timing">
                    <b-form-radio :value="0">al mattino</b-form-radio>
                    <b-form-radio :value="1">alla sera</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </div>
        <b-row cols="2" class="justify-content-around col-10">
          <b-col class="col-12 col-md-6 my-1 text-center">
            <b-button type="button" variant="outline-danger" class="font-weight-bolder" @click="
              $router.push({
                name: 'scheda paziente',
                params: { hash: $route.params.hash },
                query: { section: 'alerts' },
              })
            ">Annulla</b-button>
          </b-col>
          <b-col class="col-12 col-md-6 my-1 text-center">
            <b-button @click="saveEdits" variant="primary primary__gradient" class="font-weight-bolder text-white">
              Conferma piano</b-button>
          </b-col>
        </b-row>
      </b-row>
    </b-row>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { cittadinoService, supportService } from "@/_services";
import NewAlert from "@/components/profile/sanitario/NewAlert.vue";
export default {
  name: "ReplicaAlertPaziente",
  components: { NewAlert },
  data() {
    return {
      subject: {
        hashid: this.$route.params.hash,
        type: this.$route.query.type,
      },
      notFound: false,
      selectedTherapy: null,
      alerts: null,
      showDone: false,
      paziente: null,
      everyUms: {
        0: "mesi",
        1: "settimane",
        2: "giorni",
      },
      everyPosologyTypes: {
        0: "dì",
        1: "ore",
        2: "bisogno",
      },
      everyPosologyUms: {
        1: "ore",
        2: "giorni",
      },
      typesMap: {
        Compresse: "compressa",
        Capsule: "capsula",
        Gocce: "goccia",
        Inalazioni: "inalazione",
        Buste: "busta",
        Supposte: "supposta",
        Scatole: "scatola",
        Cerotti: "cerotto",
        Fiale: "fiala",
        Fleboclisi: "fleboclisi",
        Ovuli: "ovulo",
        Sacche: "sacca",
        Flaconi: "flacone",
        Confezioni: "confezione",
        Kilogrammi: "kilogrammo",
        Kit: "kit",
        Millilitri: "millilitro",
        Milligrammi: "milligrammo",
      },
      forEveryUmsSing: {
        0: "mese",
        1: "settimana",
        2: "giorno",
      },
      forEveryUmsPlur: {
        0: "mesi",
        1: "settimane",
        2: "giorni",
      },
      scheduleOptions: [
        { text: "1 giorno prima", value: 1, disabled: false },
        { text: "2 giorni prima", value: 2, disabled: false },
        { text: "3 giorni prima", value: 3, disabled: false },
        { text: "5 giorni prima", value: 5, disabled: false },
        // { text: "10 giorni prima", value: 10, disabled: false },
        // { text: "15 giorni prima", value: 15, disabled: false },
        // { text: "20 giorni prima", value: 20, disabled: false },
        // { text: "30 giorni prima", value: 30, disabled: false },
      ],
      channels: [
        { text: "email", value: 0, disabled: false },
        { text: "telegram/whatsapp", value: 2, disabled: false },
        { text: "sms", value: 1, disabled: true },
        { text: "chiamata operatore", value: 3, disabled: true },
      ],
      deliveryChannelMap: {
        via_mail: 0,
        via_sms: 1,
        via_bot: 2,
        via_call: 3,
      },
      form: {
        type: 0,
        user_id: this.$route.params.hash,
        alertable_id: this.$route.query.ter,
        alertable_type: "terapia",
        channels: [0],
        schedule: [1],
        confezioni: 1,
        uic: 10,
        start_at: null,
        timing: 1,
        company_notes: null,
        alerts: null,
      },
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
    posNum: function () {
      return this.selectedTherapy ? this.selectedTherapy.posology_num * 1 : 0;
    },
    timingDescription: function () {
      let time = this.form.timing === 1 ? "20:00" : "8:00";
      return "la notifica verrà inviata intorno alle ore " + time;
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    ...mapActions("utente", ["me"]),
    showRecap(data) {
      this.showDone = true;
    },
    fetchUser() {
      var self = this;
      cittadinoService
        .getUserById(this.$route.params.hash)
        .then(function (res) {
          self.paziente = res.data.data;
          self.subject = {
            hashid: res.data.data.hashid,
            type: "user_in_app",
          };
          // self.memberList = res.data.data;
        })
        .catch(function (err) {
          console.log(err);
          self.notFound = true;
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le informazioni.");
        });
    },
    fetchTherapyAlerts() {
      var self = this;
      supportService
        .getUserTherapyAlerts(this.$route.query.ter)
        .then(function (res) {
          self.selectedTherapy = res.data.data.therapy;
          self.alerts = res.data.data.alerts;
          if (res.data.data.alerts.length > 0) {
            self.setForm(res.data.data.alerts);
          }
        })
        .catch(function (err) {
          console.log(err);
          self.notFound = true;
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le informazioni.");
        });
    },
    setForm(alerts) {
      // this.form.channels = [0];
      // this.form.schedule = [1];
      this.form.confezioni = alerts[0].confezioni;
      this.form.uic = alerts[0].uic;
      this.form.company_notes = alerts[0].company_notes;
      var start = moment().format("YYYY-MM-DD");
      this.form.start_at = start;
      var sched = [];
      var dels = [];
      var prevs = [];
      alerts.map((el, ind) => {
        sched.push(el.t_minus);
        prevs.push(el.hashid);
      });
      Object.keys(this.deliveryChannelMap).map((el, ind) => {
        if (alerts[0][el] == true) {
          dels.push(ind);
        }
      });
      this.form.alerts = prevs;
      this.form.schedule = sched;
      this.form.channels = dels;
      this.form.timing =
        moment(alerts[0].countdown_ends_at).format("HH") == "20" ? 1 : 0;
    },
    saveEdits() {
      // console.log(this.form);
      var self = this;
      supportService
        .copyUserTherapyAlerts(this.form)
        .then(function (res) {
          self.showRecap(res.data.data);
          window.scrollTo(0, 0);
          self.successAlert("Piano salvato con successo.");
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile salvare le informazioni.");
        });
    },
  },
  created() {
    if (this.$route.params.hash && this.$route.query.ter) {
      this.fetchUser();
      this.fetchTherapyAlerts();
    } else {
      this.notFound = true;
    }
  },
};
</script>