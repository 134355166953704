<template>
  <div v-if="response" class="my-2">
    <span class="primary "> Caricamento completato</span>
    <!-- <span class="text-danger  point ml-1" @click="refreshAll"
      >Rimuovi
    </span> -->
  </div>
  <div class="d-flex align-content-center justify-content-start flex-wrap my-3" v-else>
    <span v-if="upError" class="text-danger mt-2"><strong>{{upMessage}}</strong></span>
    <b-form-file v-model="form.file" :accept="acceptedFormat" ref="file-up" placeholder="seleziona file..." class="
        text-primary
        custom-file-input
        border
        col-12 col-md-8
        mt-2
      "></b-form-file>
    <b-button @click="doUpload" :disabled="form.file == null" class="col-4 col-md-2 col-lg-2 ml-md-3 mt-2 py-2" size="sm"
      variant="primary">Carica</b-button>
  </div>
</template>
<script>
import { reservationService } from "@/_services";
export default {
  name: "RefertoUploader",
  props: ["dest", "accepts"],
  data() {
    return {
      form: {
        file: null,
        destination: "referto",
        name: null,
        has_owner: false,
        encrypt: true,
      },
      response: null,
      upError: false,
      upMessage: null,
      acceptedFormat: "image/*,.pdf"
    };
  },
  methods: {
    doUpload() {
      this.upError = false;
      // console.log("form", this.form);
      var form = new FormData();
      form.append("file", this.form.file);
      form.append("destination", this.form.destination);
      form.append("name", this.form.file.name);
      form.append("has_owner", this.form.has_owner);
      form.append("encrypt", this.form.encrypt);
      var self = this;
      reservationService
        .uploadFile(form)
        .then(function (res) {
          // console.log(res);
          self.response = res.data.data;
          self.$emit("upload-result", res.data.data);
        })
        .catch(function (err) {
          console.log(err.data.errors);
          if (err.data.errors && err.data.errors.file) {
            self.upMessage = err.data.errors.file[err.data.errors.file.length - 1]
          }
          self.upError = true;
        });
    },
    handleFile(e) {
      // console.log(this.file);
      // console.log(e.target.files);
    },
    refreshAll() {
      this.form.file = null;
      this.form.destination = "order";
      if (this.dest) {
        this.form.destination = this.dest;
      }
      this.form.name = null;
      this.form.has_owner = false;
      this.form.encrypt = true;
      this.response = null;
      this.$emit("remove-result");
    },
  },
  created() {
    if (this.dest) {
      this.form.destination = this.dest;
    }
    if (this.accepts) {
      this.acceptedFormat = this.accepts;
    }
    // console.log(this.form.destination);
  },
};
</script>
<style lang="scss" scoped>
.custom-file-input:lang(en)~.custom-file-label::after,
.custom-file-input:lang(it)~.custom-file-label::after,
.custom-file-input~.custom-file-label[data-browse]::after {
  content: "Scegli" !important;
  color: $primary;
  border-radius: 4px !important;
}

.custom-file-input,
.custom-file-label {
  opacity: 1 !important;
  color: $primary !important;
  border-radius: 4px !important;
}
</style>