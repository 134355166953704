<template>
  <b-container fluid class="m-0 p-0">
    <div class="mb-3">
      <b-row
        cols="1"
        cols-md="2"
        class="justify-content-bewteen pb-2"
        align-v="center"
      >
        <b-col class="align-self-end px-0">
          <h4 class="text-left py-1 mb-0">Parametri</h4>
        </b-col>
        <b-col class="text-center my-3 my-md-0 text-md-right">
          <b-button @click="openModalNew(false, false)">
            <b-icon icon="plus"></b-icon> nuova rilevazione parametro
          </b-button>
        </b-col>
      </b-row>
      <b-row
        align-v="stretch"
        cols="1"
        cols-sm="2"
        cols-md="3"
        cols-lg="4"
        class="my-2 ml-n2 col-12 px-0 align-items-center align-content-center"
      >
        <b-col
          class="px-2 py-2"
          v-for="(exam, ind) in measurements.misurazioni[0]"
          :key="ind"
        >
          <div
            class="general_border pb-4 pt-3 point puntofarmaCard col-12 d-flex align-items-center justify-content-center h-100"
            @click="selectMeasurement(exam)"
            :class="
              exam.value_level !== null
                ? 'border border__15__2 border-' +
                  valueLevelColors[Math.abs(exam.value_level)]
                : ''
            "
          >
            <div>
              <p class="mb-0 fs-5">
                {{ exam.exam.name }}
                <span
                  v-if="exam.is_observed"
                  title="parametro sotto osservazione"
                >
                  <b-icon
                    font-scale="1.3"
                    icon="eye-fill"
                    variant="primary"
                  ></b-icon>
                </span>
                <!-- <span v-else>
                  <b-icon icon="eye-slash"></b-icon>
                </span> -->
              </p>
              <h4 class="mb-0 pt-1" v-if="exam.value != null">
                <strong class="">{{ exam.value * 1 }}</strong>
                {{ exam.exam.um && exam.exam.um }}
              </h4>
              <br v-else />
              <p class="mb-0 mt-1 fs-5 text-primary text-capitalize">
                {{ formatData(exam.created_at) }}
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="mb-3">
      <b-row
        cols="1"
        cols-md="2"
        class="justify-content-bewteen pb-2"
        align-v="center"
      >
        <b-col class="align-self-end px-0">
          <h4 class="text-left py-1 mb-0">Esami</h4>
        </b-col>
        <b-col class="text-center my-3 my-md-0 text-md-right">
          <b-button @click="openModalNew(true, false)">
            <b-icon icon="plus"></b-icon> nuova rilevazione esame
          </b-button>
        </b-col>
      </b-row>

      <b-row
        align-v="stretch"
        cols="1"
        cols-sm="2"
        cols-md="3"
        cols-lg="4"
        class="my-2 ml-n2 col-12 px-0 align-items-center align-content-center"
      >
        <b-col
          class="px-2 py-2"
          v-for="(exam, ind) in measurements.misurazioni[1]"
          :key="ind"
          @click="selectMeasurement(exam)"
        >
          <div
            class="general_border pb-4 pt-3 px-2 point puntofarmaCard col-12 h-100"
            :class="
              exam.value_level !== null
                ? 'border border__15__2 border-' +
                  valueLevelColors[Math.abs(exam.value_level)]
                : ''
            "
          >
            <p class="mb-0 fs-5 pt-1">
              {{ exam.exam.name }}
              <span
                v-if="exam.is_observed"
                title="parametro sotto osservazione"
              >
                <b-icon
                  font-scale="1.3"
                  icon="eye-fill"
                  variant="primary"
                ></b-icon>
              </span>
            </p>
            <h4 class="mb-0 pt-1" v-if="exam.value != null">
              <strong class="">{{ exam.value * 1 }}</strong>
              {{ exam.exam.um && exam.exam.um }}
            </h4>
            <br v-else />
            <p class="mb-0 mt-1 fs-5 text-primary text-capitalize">
              {{ formatData(exam.created_at) }}
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="my-3 pt-1">
      <b-row
        cols="1"
        cols-md="2"
        class="justify-content-bewteen pb-2"
        align-v="center"
      >
        <b-col class="align-self-end px-0">
          <h4 class="text-left py-1 mb-0">Referti</h4>
        </b-col>
        <b-col class="text-center my-3 my-md-0 text-md-right">
          <b-button @click="openModalNew(true, true)">
            <b-icon icon="plus"></b-icon> nuova rilevazione referto
          </b-button>
        </b-col>
      </b-row>
      <b-row
        align-v="stretch"
        cols="1"
        cols-sm="2"
        cols-md="3"
        cols-lg="4"
        class="my-2 ml-n2 col-12 px-0 align-items-center align-content-center"
      >
        <b-col
          class="py-2 px-2"
          v-for="(exam, ind) in measurements.referti"
          :key="ind"
          @click="selectMeasurement(exam)"
        >
          <div
            class="general_border point puntofarmaCard col-12 h-100 d-flex flex-column align-items-center justify-content-around"
            style="min-height: 116px"
          >
            <p class="mb-0 fs-5 pt-1">
              {{ exam.exam.name }}
              <b-icon
                font-scale="1.5"
                class="ml-2"
                icon="file-earmark-fill"
              ></b-icon>
              <!-- <b-icon font-scale="1.5" class="ml-2" icon="download"></b-icon> -->
            </p>
            <p class="mb-2 mt-1 fs-5 text-primary text-capitalize">
              {{ formatData(exam.created_at) }}
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-modal
      size="xl"
      id="new-measurement"
      hide-footer
      :title="modalTitle"
      centered
    >
      <template #modal-title>
        <h4>
          {{ modalTitle }}
          <span v-if="selectedExam">
            | <span class="text-secondary"> {{ selectedExam.name }}</span></span
          >
        </h4>
      </template>
      <b-row cols="1" cols-md="2" cols-xl="3" v-if="!selectedExam">
        <b-col>
          <b-row cols="1" class="leveled__rows">
            <b-col
              class="text-center text-white fw-bolder bg-secondary general_border pb-2"
              >Trova parametro</b-col
            >
            <b-col class="shadow_1 border border-light general_border bg-white">
              <b-input-group>
                <b-form-input
                  placeholder="Cerca..."
                  style="box-shadow: none !important"
                  size="sm"
                  class="border-0 shadow_0"
                  type="search"
                  v-model="filters.search"
                ></b-form-input>
                <template #append>
                  <span class="m-2">
                    <b-icon
                      icon="search"
                      variant="secondary"
                      font-scale="1.5"
                    ></b-icon>
                  </span>
                </template>
              </b-input-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-form @submit.prevent="onSubmit">
        <!-- <div class="col-12 d-flex flex-wrap justify-content-center"> -->
        <transition-group
          tag="div"
          name="list"
          mode="out-in"
          appear
          class="col-12 pl-0 d-flex flex-wrap justify-content-center align-items-stretch"
        >
          <div
            class="my-1 col-12 col-md-4 col-lgx-3 px-0 px-md-1"
            v-for="(exam, ind) in exams"
            :key="exam.hashid"
          >
            <b-button
              block
              v-if="!selectedExam"
              :variant="
                selectedExam && selectedExam.hashid == exam.hashid
                  ? 'secondary'
                  : 'outline-secondary'
              "
              class="text-break m-2 text-capitalize font-weight-bold py-4 h-100 min__h__ril"
              @click="selectedExam = exam"
              >{{ exam.name }}</b-button
            >
            <!-- <b-button
              block
              v-if="
                !selectedExam ||
                (selectedExam && selectedExam.hashid == exam.hashid)
              "
              :variant="
                selectedExam && selectedExam.hashid == exam.hashid
                  ? 'secondary'
                  : 'outline-secondary'
              "
              class="text-break m-2 text-capitalize font-weight-bold py-4  h-100 min__h__ril"
              @click="selectedExam = exam"
              >{{ exam.name }}</b-button
            > -->
          </div>
          <div
            v-if="selectedExam"
            class="text-left fs-6 text-font-grey mx-auto col-12"
            key="forminputs"
          >
            <b-row>
              <b-col class="px-0 col-12 col-lg-6 col-xl-5">
                <b-form-group
                  v-if="!selectedExam.has_attachment"
                  key="noninstr"
                  :label="selectedExam.name + '*'"
                  class="text-left text-capitalize d-flex text-secondary"
                >
                  <b-input-group
                    :append="selectedExam.um ? selectedExam.um : undefined"
                    class="mb-2 mr-sm-2 mb-sm-0 text-lowercase"
                  >
                    <b-form-input
                      v-model="form.value"
                      type="number"
                      step="0.001"
                      required
                      autofocus
                      placeholder="Inserisci valore*"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
                <RefertoUploader
                  v-else
                  v-on:upload-result="handleUploadResult($event)"
                />
                <b-form-group
                  v-if="form.own"
                  label=""
                  :description="
                    '**se selezioni questa opzione soltanto tu potrai vedere ' +
                    privacyDescription
                  "
                  label-for="private"
                  class="text-left font-weight-bolder text-secondary mx-auto col-12 px-0 px-md-2 my-4"
                >
                  <b-form-checkbox
                    id="private"
                    v-model="form.private"
                    name="private"
                    :value="true"
                    :unchecked-value="false"
                  >
                    visibile solo a me**
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col class="px-0 col">
                <b-form-group
                  label="Annotazioni"
                  label-for="details"
                  class="text-left fs-6 text-font-grey mx-auto col-12 px-0 px-md-2"
                >
                  <b-form-textarea
                    class="my-3"
                    name="details"
                    type="text"
                    rows="5"
                    v-model="form.details"
                  >
                  </b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="mx-auto text-right col-12 px-0 px-md-2">
              <!-- <p class="text-left pl-4">*Campi obbligatori</p> -->
              <b-button
                type="button"
                variant="info modal-footer-button py-2 px-4 mr-3"
                @click.prevent="handleAnnulla"
                >Indietro</b-button
              >
              <b-button
                type="submit"
                :disabled="form.resource_id == null && form.value == null"
                variant="outline-primary modal-footer-button px-4 py-2"
                >Salva
              </b-button>
            </div>
          </div>
          <!-- </div> -->
        </transition-group>
      </b-form>
    </b-modal>
    <b-modal
      centered
      scrollable
      class="text-center"
      size="lg"
      id="detail-measurement"
      hide-footer
    >
      <template class="text-center" #modal-title>
        <h4 v-if="selectedMeasurement && selectedMeasurement.exam">
          {{ selectedMeasurement.exam.name }}
        </h4>
        <h4 v-else>Storico</h4>
      </template>
      <div
        class="text-center"
        v-if="selectedMeasurement && selectedMeasurement.exam.name"
      >
        <!-- <template
          v-if="userCanObserve && !selectedMeasurement.exam.has_attachment"
        >
          <b-row
            v-if="selectedMeasurement.is_observed"
            align-v="center"
            class="justify-content-between"
          >
            <span>
              <b-icon
                font-scale="1.3"
                icon="eye-fill"
                variant="primary"
              ></b-icon>
              <span> Il parametro è sotto osservazione. </span>
            </span>
            <b-button
              class="modal-footer-button"
              variant="outline-primary"
              size="sm"
              @click="$bvModal.show('reference-modal')"
              >Visualizza soglie</b-button
            >
          </b-row>
          <b-row
            v-else
            align-v="center"
            class="justify-content-between"
            @click="$bvModal.show('reference-modal')"
          >
            <span>
              <b-icon icon="eye-slash"></b-icon>
              <span> Parametro non osservato. </span>
            </span>
            <b-button
              class="modal-footer-button"
              variant="outline-primary"
              size="sm"
              >Imposta soglie</b-button
            >
          </b-row>
          <FrequenzeRilevazioni
            :subject="subject.hashid"
            :exam="selectedMeasurement.exam.hashid"
            @close-editmodal="$bvModal.hide('reference-modal')"
            @need-update="closeAndUpdate = true"
          ></FrequenzeRilevazioni>
        </template> -->
        <div v-if="examHistory">
          <!-- filters -->
          <b-col cols="12">
            <b-row
              cols="2"
              cols-lg="4"
              class="text-left justify-content-between my-2"
            >
              <b-form-group class="mx-1">
                <label for="" class="fw-bolder">Anno</label>
                <b-form-select
                  v-model="historyFilters.year"
                  @change="fetchStorico"
                >
                  <b-form-select-option :value="null"
                    >tutti</b-form-select-option
                  >
                  <b-form-select-option
                    v-for="year in historyFilterOptions.year"
                    :value="year + 2021"
                    :key="year + 2021"
                    >{{ year + 2021 }}</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
              <b-form-group class="mx-1">
                <label for="" class="fw-bolder">Mese</label>
                <b-form-select
                  v-model="historyFilters.month"
                  @change="fetchStorico"
                >
                  <b-form-select-option :value="null"
                    >tutti</b-form-select-option
                  >
                  <b-form-select-option
                    v-for="month in historyFilterOptions.month"
                    :value="month.value"
                    :key="month.value"
                    >{{ month.label }}</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
              <b-form-group class="mx-1">
                <label for="" class="fw-bolder">Giorno</label>
                <b-form-select
                  v-model="historyFilters.day"
                  @change="fetchStorico"
                >
                  <b-form-select-option :value="null"
                    >tutti</b-form-select-option
                  >
                  <b-form-select-option
                    v-for="day in historyFilterOptions.day"
                    :value="day"
                    :key="day"
                    >{{ day }}</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-row>
          </b-col>
          <!-- filters -->
          <!-- CHART -->
          <div v-if="!selectedMeasurement.exam.has_attachment">
            <LineChart
              v-if="!selectedMeasurement.exam.has_attachment"
              :title="selectedMeasurement.exam.name"
              :idappend="selectedMeasurement.hashid + '-line-chart'"
              :datatoshow="chartData"
              :chartlabels="chartLabels"
            />
            <!-- CHART -->
            <h5 class="mt-4 mb-2 text-left">Valori rilevati</h5>
          </div>
          <div v-else>
            <h5 class="mt-4 mb-2 text-left">Referti</h5>
          </div>
          <div class="fixed__box__sm">
            <div
              class="my-2 text-left d-flex flex-wrap align-items-center justify-content-between"
              v-for="(hist, ind) in examHistory"
              :key="ind"
            >
              <span class="mt-2 text-capitalize text-primary">
                {{ formatData(hist.created_at) }}
              </span>
              <span v-if="hist.value != null">
                <span>
                  <span v-if="hist.value_level !== null">
                    <b-icon
                      class="mr-1"
                      icon="circle-fill"
                      :variant="valueLevelColors[Math.abs(hist.value_level)]"
                    ></b-icon>
                  </span>
                  <strong class="">{{ hist.value * 1 }}</strong>
                  {{ hist.exam.um && hist.exam.um }}
                </span>
                <span class="mx-2 mr-4 point" @click="openEditModal(hist)">
                  <b-iconstack font-scale="1.3">
                    <b-icon stacked icon="pencil-fill"></b-icon>
                  </b-iconstack>
                </span>
                <span class="mx-2 point" @click="openDelModal(hist)">
                  <b-iconstack font-scale="1.3">
                    <b-icon stacked icon="trash" variant="danger"></b-icon>
                  </b-iconstack>
                </span>
              </span>
              <!-- <span v-else-if="hist.resource && hist.owner" class="mt-2"> -->
              <span v-else-if="hist.resource" class="mt-2">
                <b-button
                  size="sm"
                  variant="primary"
                  @click="downloadResource(hist.resource)"
                >
                  Scarica <b-icon icon="download"></b-icon>
                </b-button>
                <span class="mx-2 mt-2 point" @click="openDelModal(hist)">
                  <b-iconstack font-scale="1.7" style="vertical-align: middle">
                    <b-icon stacked icon="trash" variant="danger"></b-icon>
                  </b-iconstack>
                </span>
              </span>
              <span v-else />
              <p class="col-12 my-0 fs-5 text-right" v-if="hist.details">
                Note: {{ hist.details }}
              </p>
            </div>
          </div>
          <div v-if="examHistory.length == 1" class="my-2 py-2 text-left">
            <p class="text-font-grey">Non ci sono altre rilevazioni</p>
          </div>
        </div>
        <b-img
          v-if="picLink"
          ref="allegato"
          :src="picLink"
          no-body
          fluid-grow
          width="100%"
          height="100%"
        />
        <b-button
          variant="outline-primary"
          class="modal-footer-button"
          @click="newFromDetail"
        >
          <b-icon icon="plus" class="mr-1"></b-icon>aggiungi
        </b-button>
      </div>
    </b-modal>
    <b-modal
      centered
      title="Modifica Valore"
      class="text-center"
      size="md"
      id="edit-measurement"
      ok-title="Salva modifiche"
      ok-variant="outline-danger"
      cancel-title="Annulla"
      cancel-variant="info mr-3"
      footer-class="justify-content-end"
      footer-border-variant="white"
      @ok="updateMeasurement"
      @cancel="$bvModal.hide('edit-measurement')"
    >
      <div v-if="currentMeasurement" class="font-weight-bolder">
        <b-form-group
          v-if="!currentMeasurement.exam.has_attachment"
          key="noninstr"
          :label="currentMeasurement.exam.name + '*'"
          class="text-left text-capitalize d-flex text-secondary"
        >
          <b-input-group
            :append="
              currentMeasurement.exam.um
                ? currentMeasurement.exam.um
                : undefined
            "
            class="mb-2 mr-sm-2 mb-sm-0 text-lowercase"
          >
            <b-form-input
              class=""
              v-model="valueToUpdate"
              type="number"
              step="0.001"
              required
              autofocus
              placeholder="Inserisci valore*"
            ></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group
          v-if="currentMeasurement.is_mine"
          label=""
          :description="
            '**se selezioni questa opzione soltanto tu potrai vedere ' +
            privacyDescription
          "
          label-for="private"
          class="text-left font-weight-bolder text-secondary mx-auto col-12 px-0 px-md-2 my-4"
        >
          <b-form-checkbox
            id="private"
            v-model="privacyValueToUpdate"
            name="private"
            :value="1"
            :unchecked-value="0"
          >
            visibile solo a me**
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          label="Annotazioni"
          label-for="details"
          class="text-left fs-6 text-font-grey mx-auto col-12 px-0 px-md-2"
        >
          <b-form-textarea
            class="my-3"
            name="details"
            type="text"
            v-model="detailValueToUpdate"
          >
          </b-form-textarea>
        </b-form-group>
      </div>
    </b-modal>
    <b-modal
      title="Conferma"
      class="text-center"
      id="del-measurement"
      ok-title="Sì, elimina"
      ok-variant="outline-danger"
      cancel-title="No, annulla"
      cancel-variant="info mr-3"
      footer-border-variant="white"
      @ok="removeMeasurement"
      @cancel="$bvModal.hide('del-measurement')"
      centered
    >
      <h4 v-if="currentMeasurement">
        Vuoi eliminare questo valore di
        <span class="text-lowercase">{{ currentMeasurement.exam.name }}</span> ?
      </h4>
    </b-modal>
    <b-modal
      id="reference-modal"
      size="xl"
      :title="
        'Soglie sul parametro ' +
        (selectedMeasurement ? selectedMeasurement.exam.name.toLowerCase() : '')
      "
      @hide="handleHideRefModal"
      hide-footer
      centered
    >
      <SoglieRilevazioni
        v-if="userCanObserve && selectedMeasurement"
        class="pb-4"
        :subject="subject.hashid"
        :exam="selectedMeasurement.exam.hashid"
        @close-editmodal="$bvModal.hide('reference-modal')"
        @need-update="closeAndUpdate = true"
      >
        <template v-slot:examname>
          <span>"{{ selectedMeasurement.exam.name }}"</span>
        </template>
      </SoglieRilevazioni>
    </b-modal>
  </b-container>
</template>
<script>
import * as moment from "moment/moment";
import { cittadinoService, utilityService } from "@/_services";
import { mapState, mapActions } from "vuex";
import { MONTHS } from "@/_utils/constants.js";
import RefertoUploader from "@/components/utilities/RefertoUploader.vue";
import LineChart from "@/components/profile/sanitario/LineChart.vue";
import SoglieRilevazioni from "@/components/profile/sanitario/SoglieRilevazioni.vue";
import FrequenzeRilevazioni from "@/components/profile/sanitario/FrequenzeRilevazioni.vue";
export default {
  name: "RilevazioniComponent",
  props: ["subject"],
  components: {
    RefertoUploader,
    LineChart,
    SoglieRilevazioni,
    FrequenzeRilevazioni,
  },
  data() {
    return {
      measurements: {
        misurazioni: [[], []],
        referti: null,
      },
      filters: {
        instrumental: false,
        referto: false,
        search: null,
      },
      historyFilters: {
        year: null,
        month: null,
        day: null,
      },
      exams: null,
      selectedExam: null,
      selectedMeasurement: null,
      form: {
        exam_id: null,
        resource_id: null,
        value: null,
        details: null,
        own: true,
        private: false,
        subjectable_id: null,
        subjectable_type: null,
      },
      examHistory: null,
      picLink: null,
      currentMeasurement: null,
      valueToUpdate: null,
      privacyValueToUpdate: null,
      detailValueToUpdate: null,
      chartData: [],
      chartLabels: [],
      valueLevelColors: {
        0: "success",
        1: "sec-3",
        2: "warning",
        3: "danger",
      },
      closeAndUpdate: false,
      historyFilterOptions: {
        year: null,
        // month: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        month: MONTHS,
        day: 31,
      },
    };
  },
  watch: {
    filters: {
      handler(val) {
        // console.log(val);
        this.fetchAvailableExams();
      },
      deep: true,
    },
    "historyFilters.month": function (value) {
      const dayCount = moment().set("month", value - 1);
      this.$nextTick(function () {
        this.historyFilterOptions.day = dayCount.daysInMonth();
      });
    },
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    modalTitle() {
      if (this.filters.instrumental) {
        return this.filters.referto
          ? "Nuova rilevazione referto"
          : "Nuova rilevazione esame";
      }
      return "Nuova rilevazione parametro";
    },
    privacyDescription() {
      if (this.filters.instrumental) {
        return this.filters.referto ? "questo referto" : "questo esame";
      }
      return "questa rilevazione";
    },
    insertType() {
      if (this.filters.instrumental) {
        return this.filters.referto ? "Referto" : "Esame";
      }
      return "Rilevazione";
    },
    userCanObserve() {
      return this.subject !== null;
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    openModalNew(instrumental, referto) {
      this.filters.instrumental = instrumental;
      this.filters.referto = referto;
      this.$bvModal.show("new-measurement");
    },
    fetchMeasurements() {
      if (this.subject) {
        return this.fetchUserMeasurements();
      }
      this.fetchOwnMeasurements();
    },
    fetchOwnMeasurements() {
      var self = this;
      cittadinoService
        .getMeasurements()
        .then(function (response) {
          self.measurements = response.data.data;
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          self.errorAlert("qualcosa è andata storta");
        });
    },
    fetchUserMeasurements() {
      var self = this;
      var filters = {
        user: this.subject.hashid,
      };
      cittadinoService
        .getMeasurements(filters)
        .then(function (response) {
          self.measurements = response.data.data;
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          self.errorAlert("qualcosa è andata storta");
        });
    },
    fetchAvailableExams() {
      this.clearForm();
      var self = this;
      utilityService
        .getAvailableExams(this.filters)
        .then(function (response) {
          self.exams = response.data.data;
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          self.errorAlert("qualcosa è andata storta");
        });
    },
    handleAnnulla() {
      this.clearForm();
    },
    formatData(data) {
      return moment(data).format("DD MMMM YYYY HH:mm");
    },
    onSubmit() {
      this.form.exam_id = this.selectedExam.hashid;
      var self = this;
      cittadinoService
        .postMeasurements(this.form)
        .then(function (res) {
          self.clearForm();
          self.$bvModal.hide("new-measurement");
          self.fetchMeasurements();
          window.scrollTo(0, 0);
          self.successAlert("Misurazione inserita correttamente");
          self.$bvModal.hide("detail-measurement");
        })
        .catch(function (err) {
          self.errorAlert("Non è stato possibile inserire la misurazione");
          self.$bvModal.hide("new-measurement");
          self.$bvModal.hide("detail-measurement");
        });
    },
    clearForm() {
      this.selectedExam = null;
      this.form.resource_id = null;
      this.form.value = null;
      this.form.details = null;
      this.form.private = false;
    },
    handleUploadResult(upload) {
      this.form.resource_id = upload.hashid;
    },
    selectMeasurement(meas) {
      this.picLink = null;
      this.selectedMeasurement = meas;
      this.$bvModal.show("detail-measurement");
      this.fetchStorico();
    },
    newFromDetail() {
      let instrumental = this.selectedMeasurement.exam.instrumental == true;
      let referto = this.selectedMeasurement.exam.has_attachment == true;
      this.selectedExam = this.selectedMeasurement.exam;
      this.openModalNew(instrumental, referto);
    },
    fetchStorico() {
      if (this.subject) {
        return this.fetchUserStorico();
      }
      this.fetchOwnStorico();
    },
    fetchOwnStorico() {
      this.examHistory = null;
      var self = this;
      cittadinoService
        .getExamHistory(
          this.selectedMeasurement.exam.hashid,
          this.historyFilters
        )
        .then(function (response) {
          self.examHistory = response.data.data;
          self.setChartData();
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          self.errorAlert("qualcosa è andata storta");
        });
    },
    fetchUserStorico() {
      this.examHistory = null;
      var self = this;
      var filters = {
        user: this.subject.hashid,
        ...this.historyFilters,
      };
      cittadinoService
        .getExamHistory(this.selectedMeasurement.exam.hashid, filters)
        .then(function (response) {
          self.examHistory = response.data.data;
          self.setChartData();
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          self.errorAlert("qualcosa è andata storta");
        });
    },
    setChartData() {
      this.chartData = [];
      this.chartLabels = [];
      var self = this;
      this.examHistory.map(function (el) {
        self.chartData.unshift(el.value);
        let addedAt = moment(el.created_at).format("DD/MM");
        self.chartLabels.unshift(addedAt);
      });
    },
    openDelModal(hist) {
      this.currentMeasurement = hist;
      this.$bvModal.show("del-measurement");
    },
    openEditModal(hist) {
      this.currentMeasurement = hist;
      this.valueToUpdate = hist.value * 1;
      this.privacyValueToUpdate = hist.private;
      this.detailValueToUpdate = hist.details;
      this.$bvModal.show("edit-measurement");
    },
    updateMeasurement() {
      var self = this;
      var filters = {
        value: this.valueToUpdate,
        private: this.privacyValueToUpdate,
        details: this.detailValueToUpdate,
      };
      cittadinoService
        .updateMeasurement(filters, this.currentMeasurement.hashid)
        .then(function (response) {
          self.successAlert("Misurazione aggiornata correttamente");
          self.$bvModal.hide("edit-measurement");
          self.$bvModal.hide("detail-measurement");
          self.fetchMeasurements();
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile aggiornare la misurazione");
          self.$bvModal.hide("edit-measurement");
          self.$bvModal.hide("detail-measurement");
          self.fetchMeasurements();
        });
    },
    removeMeasurement() {
      var self = this;
      cittadinoService
        .deleteMeasurement(this.currentMeasurement.hashid)
        .then(function (response) {
          self.successAlert("Misurazione eliminata correttamente");
          self.$bvModal.hide("del-measurement");
          self.$bvModal.hide("detail-measurement");
          self.fetchMeasurements();
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile eliminare la misurazione");
          self.$bvModal.hide("del-measurement");
          self.$bvModal.hide("detail-measurement");
          self.fetchMeasurements();
        });
    },
    downloadResource(resource = null) {
      if (!resource) {
        resource = this.selectedMeasurement.resource;
      }
      const fName = resource.name;
      var self = this;
      cittadinoService
        .downloadResource(resource.hashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fName;
          link.click();
        })
        .catch(function (err) {
          console.log(err);
          self.errorAlert("Non è stato possibile scaricare la risorsa");
        });
    },
    handleHideRefModal() {
      if (this.closeAndUpdate) {
        this.fetchMeasurements();
      }
    },
    setFilterOptions() {
      this.historyFilterOptions.year = moment().diff(
        moment("01-01-2021", "DD-MM-YYYY"),
        "years"
      );
      const now = moment();
      // this.historyFilters.day = now.date()
      this.historyFilters.month = now.month() + 1;
      this.historyFilters.year = now.year();
    },
  },
  created() {
    if (this.subject) {
      this.form.subjectable_id = this.subject.hashid;
      this.form.subjectable_type = this.subject.type;
      this.form.own = false;
    }
    this.fetchAvailableExams();
    this.fetchMeasurements();
  },
  mounted() {
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      // console.log("Modal is about to be shown", bvEvent, modalId);
      this.clearForm();
    });
    this.setFilterOptions();
  },
};
</script>
<style>
.min__h__ril {
  min-height: 10vh;
}
</style>
