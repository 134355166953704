<template>
  <div class="py-2 puntofarmaCard h-100 p-xl-4">
    <h5 class="pl-4 text-left text-capitalize">{{ title }}</h5>
    <div style="position: relative; max-height: 90%">
      <canvas :id="idappend"></canvas>
    </div>
    <div v-if="datatoshow" class="w-100 px-3 px-xl-4" style="position: absolute; bottom: 0; left: 0">
      <b-row class="text-left px-4 border-top border-info">
        <b-col class="border-right border-info text-center pt-2">
          <h6>prenotazioni</h6>
          <p>
            {{ Math.round(datatoshow[0] + datatoshow[1] + datatoshow[2]) }}%
          </p>
        </b-col>
        <b-col class="text-center pt-2">
          <h6>altro</h6>
          <p>{{ Math.round(datatoshow[3] + datatoshow[4]) }}%</p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { Chart, registerables } from "chart.js";
import "chartjs-adapter-moment"; // or another adapter to avoid moment
Chart.register(...registerables);
export default {
  name: "PolarChart",
  props: ["idappend", "datatoshow", "title"],
  data() {
    return {
      chartData: {
        type: "polarArea",
        data: {
          labels: [
            "Teleconsulti %",
            "Prestazioni %",
            "Ordini %",
            "Terapie %",
            "Questionari somministrati %",
          ],
          datasets: [
            {
              label: "My First Dataset",
              data: this.datatoshow,
              backgroundColor: [
                "rgb(237, 94, 95)",
                "rgb(75, 192, 192)",
                "rgb(255, 165, 80)",
                "rgb(201, 203, 207)",
                "#145c8c",
                // "rgb(116, 103, 240)",
              ],
            },
          ],
        },
        options: {
          maintainAspectRatio: true,
        },
      },
    };
  },
  mounted() {
    // console.log("polarArea");
    // console.log(this.datatoshow);
    const ctx = document.getElementById(this.idappend);
    new Chart(ctx, this.chartData);
  },
};
</script>