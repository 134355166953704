<template>
  <div class="">
    <!-- <h5 class="pl-4 text-left text-capitalize">{{ title }}</h5> -->
    <canvas :id="idappend"></canvas>
  </div>
</template>
<script>
import { Chart, registerables } from "chart.js";
import "chartjs-adapter-moment"; // or another adapter to avoid moment
Chart.register(...registerables);
export default {
  name: "LineChart",
  props: ["idappend", "datatoshow", "title", "chartlabels"],
  data() {
    return {
      chartData: {
        type: "line",
        data: {
          labels: this.chartlabels,
          datasets: [
            {
              label: this.title,
              data: this.datatoshow,
              fill: false,
              borderColor: "#097fbb",
              backgroundColor: "#097fbb",
              tension: 0.1,
            },
          ],
        },
      },
    };
  },
  mounted() {
    const ctx = document.getElementById(this.idappend);
    new Chart(ctx, this.chartData);
  },
};
</script>