<template>
  <div class="p-1 p-md-2 p-xl-4">
    <template v-if="servicesStatus[1] === null || servicesStatus[2] === null">
      <p class="text-center"><b-spinner></b-spinner></p>
    </template>
    <template
      v-else-if="servicesStatus[1] === true || servicesStatus[2] === true"
    >
      <h1
        class="text-left pb-1 mt-1 mb-4 d-flex justify-content-start align-items-center page-title"
      >
        Calendario
      </h1>
      <b-row>
        <Calendario />
      </b-row>
    </template>
    <template v-else>
      <h4>Servizio non abilitato</h4>
      <h6>
        <a href="https://puntofarma.online/contacts/"> <u> Contattaci</u></a>
        per saperne di più o per abilitare il servizio
      </h6>
    </template>
  </div>
</template>
<script>
import Calendario from "@/components/prenotazioni/calendario/Calendario.vue"
import { companyService } from "@/_services"
export default {
  name: "CompanyAgenda",
  components: { Calendario },
  data() {
    return {
      servicesStatus: {
        1: null,
        2: null
      }
    }
  },
  created() {},
  mounted() {
    this.verifyService(1)
    this.verifyService(2)
    var lib = document.createElement("script")
    lib.setAttribute("id", "ls_lib")
    lib.setAttribute("type", "text/javascript")
    lib.setAttribute(
      "src",
      "https://cdn.jsdelivr.net/npm/fm.liveswitch@latest/fm.liveswitch.js"
    )
    document.head.appendChild(lib)
  },
  beforeDestroy() {
    var lib = document.getElementById("ls_lib")
    document.head.removeChild(lib)
  },
  methods: {
    verifyService(ref) {
      const self = this
      const filter = { ref: ref }
      companyService
        .checkIfServiceIsActive(filter)
        .then(function (response) {
          self.servicesStatus[ref] = response.data.data.active
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>
