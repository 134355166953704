<template>
  <div
    v-if="azienda"
    class="general_border_17 d-flex flex-column general_hover point shadow_2"
    style="width: 250px"
  >
    <section
      @click="selectThis()"
      class="company-card-header border"
      :class="{ 'bg-whitesmoke': !azienda.profile_pic }"
      :style="companyPicStyle"
    ></section>
    <section
      class="w-100 mt-n4 general_border_17 w-100 d-flex flex-column flex-grow-1 px-4 py-2"
      :class="isSelected ? 'bg-secondary text-white' : 'bg-white'"
    >
        <h5 @click="selectThis()" class="mb-0">{{ azienda.denominazione }}</h5>
      <p @click="selectThis()" class="text-capitalize text-center mb-1 mb-md-2 mb-xl-3 mt-auto">
          {{ azienda.indirizzo }}, {{ azienda.frazione }} {{ azienda.comune }}
        </p>
      <p class="mb-0 mt-auto">
      <span  v-if="showHeart" @click="$emit('toggle-preferito', azienda)">
          <b-icon
            font-scale="1.5"
            :icon="
              user.farmacia_preferita &&
              azienda.hashid === user.farmacia_preferita.hashid
                ? 'heart-fill'
                : 'heart'
            "
            :variant="isSelected ? 'white' : 'secondary'"
          ></b-icon>
        </span>
      </p>
    </section>
  </div>
</template>
<script>
import { mapState } from "vuex"
export default {
  name: "CompanyCard",
  props: ["azienda", "isSelected", "showHeart"],
  computed: {
    ...mapState("utente", ["status", "user"]),
    companyPicStyle() {
      if (this.azienda.profile_pic) {
        return "background-image: url(" + this.azienda.profile_pic + ")"
      } else {
        return {
          backgroundImage: `url(${require("@/assets/no_avatar.svg")}`,
          backgroundPosition: "center center",
          backgroundSize: "contain"
        }
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    selectThis() {
      this.$emit("company-selection", this.azienda)
    }
  }
}
</script>
<style lang="scss" scoped>
.company-card-header {
  width: 250px;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  height: 130px;
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
}
</style>
