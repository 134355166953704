<template>
  <div class="d-inline">
    <span
      class="mx-1 point a__effect"
      @click="$bvModal.show('changelog__modal')"
    >
      Note di rilascio
    </span>
    <b-modal
      scrollable
      id="changelog__modal"
      hide-footer
      size="xl"
      title="Note di rilascio"
    >
      <b-row v-for="(log, ind) in logs" :key="ind" class="my-2">
        <b-col cols="4" md="3" lg="2">
          <h4 class="text-center mb-0">
            <b-badge class="px-3 py-2 w-100" :variant="log.versionVariant">{{
              log.version
            }}</b-badge>
          </h4>
        </b-col>
        <b-col>
          <b-row align-v="center">
            <h4 class="mt-1">
              {{ log.releaseDate }}
              <b-icon
                v-if="ind === 0"
                icon="award-fill"
                animation="throb"
                :variant="log.versionVariant"
              ></b-icon>
            </h4>
          </b-row>
          <b-row v-if="log.new.show">
            <b-col cols="5" md="4" lg="3">
              <h6>
                <b-badge class="px-3 py-2 w-100" variant="ter-1"
                  >NOVITA'</b-badge
                >
              </h6>
            </b-col>
            <b-col>
              <p class="text-justify" v-html="log.new.text"></p>
            </b-col>
          </b-row>
          <b-row v-if="log.improved.show">
            <b-col cols="5" md="4" lg="3">
              <h6>
                <b-badge class="px-3 py-2 w-100" variant="ter-2"
                  >MIGLIORAMENTI</b-badge
                >
              </h6>
            </b-col>
            <b-col>
              <p class="text-justify" v-html="log.improved.text"></p>
            </b-col>
          </b-row>
          <b-row v-if="log.fixed.show">
            <b-col cols="5" md="4" lg="3">
              <h6>
                <b-badge class="px-3 py-2 w-100" variant="sec-2"
                  >CORREZIONI</b-badge
                >
              </h6>
            </b-col>
            <b-col>
              <p class="text-justify" v-html="log.fixed.text"></p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "ChangeLogComponent",
  data() {
    return {
      logs: [
        {
          version: "PF01.01.08",
          versionVariant: "secondary",
          releaseDate: "29 Gennaio 2024",
          new: {
            show: false,
            text: ""
          },
          improved: {
            show: true,
            text: "<b>Miglioramenti sulle prenotazioni</b>: aggiunti selettori per agevolare la scelta della data di prenotazione. Miglioramenti sulla gestione dei codici fiscali di <b>soggetti nati all'estero</b>. Introdotti selettori per anno e mese nell'agenda per velocizzarne lo scorrimento."
          },
          fixed: {
            show: false,
            text: ""
          }
        },
        {
          version: "PF01.01.07",
          versionVariant: "primary",
          releaseDate: "05 Dicembre 2023",
          new: {
            show: true,
            text: "<b>Lista pazienti</b>: in area pazienti trovi adesso la lista dei tuoi assistiti, per un accesso alla scheda più rapido. <b>Gestione questionari</b>: puoi adesso modificare ed eliminare i questionari somministrati. <b>Modifica format di prenotazione</b>: introdotta la modifica del format di prenotazione compilato durante la prenotazione di appuntamenti in Asl. <b>Codici esenzione</b>: introdotta la gestione dei codici esenzione nella sezione Patologie della scheda paziente. "
          },
          improved: {
            show: true,
            text: "<b>Migliorata la selezione dello slot di prenotazione</b>: inseriti selettore anno e mese per velocizzare la selezione dello slot di prenotazione. <b>Più opzioni per le notifiche di promemoria</b>: inserite più opzioni per l'invio notifiche di promemoria, per i monitoraggi a lungo termine. <b>Aggiornamento anagrafica paziente</b>: introdotto i campi facoltativi dedicati al medico di base e all'azienda sanitaria locale del paziente."
          },
          fixed: {
            show: true,
            text: "<b>Validazione numero di telefono</b>: verifica sulla formattazione del numero di telefono in caso di inserimento di prefisso. <b>Ricezione notifiche push</b>: puoi ricevere le notifiche push su tutti i dispositivi da cui hai eseguito l'accesso."
          }
        },
        {
          version: "PF01.01.06",
          versionVariant: "primary",
          releaseDate: "16 Agosto 2023",
          new: {
            show: true,
            text: "<b>Nuove misure di sicurezza</b>: necessari adesso inserimento e validazione del numero di telefono. <b>Un'applicazione più sicura</b>: introdotto l'obbligo di modifica password ogni 90 giorni. <b>Recupero password tramite codice fiscale</b>: in caso di impossibilità di accesso alla propria casella email, sarà possibile recuperare la propria password tramite codice fiscale e numero di telefono. <b>Accesso alla scheda del mio assistito</b>: il caregiver può adesso visualizzare il profilo sanitario del proprio assistito."
          },
          improved: {
            show: true,
            text: "<b>Migliorata la sezione terapie sulla scheda del paziente</b>: introdotti campi specifici per la raccolta di interazioni, effetti collaterali e reazioni allergiche sulle terapie. <b>Arruolamento del paziente senza email</b>: è adesso possibile arruolare in piattaforma un paziente sprovvisto di casella email. <b>Ottimizzazioni sulla scheda del paziente</b>: introdotto un collegamento agevolato alla scheda anagrafica del paziente, dove adesso è possibile inserire anche i contatti utili. <b>Aggiornamenti grafici</b>."
          },
          fixed: {
            show: false,
            text: "<b>Corretto il problema che impediva di prenotare un farmaco ed una prestazione nella stessa fascia oraria</b>: l'operatore sanitario potrà, tramite le impostazioni, decidere se consentire o meno la sovrapposizione di più appuntamenti su servizi diversi per la stessa fascia oraria."
          }
        },
        {
          version: "PF01.01.05",
          versionVariant: "primary",
          releaseDate: "10 Marzo 2023",
          new: {
            show: true,
            text: "<b>Nuova grafica</b>: restyle grafico per visualizzazioni più compatte ed un utilizzo più intuitivo per operatori e pazienti."
          },
          improved: {
            show: false,
            text: "<b>Questionari più flessibili</b>: aggiunta la possibilità di dettagliare la tipologia di file attesa in una domanda di questionario. E' inoltre possibile adesso il caricamento multiplo di file nelle domande dei questionari."
          },
          fixed: {
            show: false,
            text: null
          }
        },
        {
          version: "PF01.01.04",
          versionVariant: "primary",
          releaseDate: "20 Gennaio 2023",
          new: {
            show: true,
            text: "<b>Nuovo strumento per le prenotazioni</b>: disponibile su tutte le prenotazioni attive una chat che consente una comunicazione in tempo reale tra le parti."
          },
          improved: {
            show: false,
            text: "<b>Questionari più flessibili</b>: aggiunta la possibilità di dettagliare la tipologia di file attesa in una domanda di questionario. E' inoltre possibile adesso il caricamento multiplo di file nelle domande dei questionari."
          },
          fixed: {
            show: false,
            text: null
          }
        },
        {
          version: "PF01.01.03",
          versionVariant: "primary",
          releaseDate: "25 Novembre 2022",
          new: {
            show: true,
            text: "<b>Nuovi strumenti per l'operatore sanitario</b>: evadi più richieste contemporaneamente accedendo alla vista giornaliera del calendario e selezionando una o più richieste da evadere, oppure tramite lo strumento dedicato in area Prenotazioni.<br/><b>Rischedula gli appuntamenti:</b> adesso è possibile richiedere la modifica di data e/o ora per le richieste di tutti i servizi."
            // text: "<b>Nuovi strumenti per l'operatore sanitario</b>: evadi più richieste contemporaneamente accedendo alla vista giornaliera del calendario e selezionando una o più richieste da evadere, oppure tramite lo strumento dedicato in area Prenotazioni. <b>I tuoi assistiti a portata di mano:</b> adesso puoi visualizzare i tuoi assistiti nell'area pazienti ed accedere velocemente alla scheda del paziente tramite la ricerca in lista per codice fiscale o per cognome.<br/><b>Rischedula gli appuntamenti:</b> adesso è possibile richiedere la modifica di data e/o ora per le richieste di tutti i servizi."
          },
          improved: {
            show: true,
            text: "<b>Miglioramenti sull'agenda dell'operatore sanitario</b>: inserito il campo di ricerca per cognome  e il tasto di inserimento di una nuova prenotazione. <br/><b>Revisione dei report di prenotazioni</b>, l'operatore potà adesso visualizzare in tutti i report di prenotazioni anche i dati di contatto dei pazienti.<br/><b>Autocompletamento in Recall:</b> nell'inserimento dei dati del paziente per un nuovo recall, dopo aver inserito il codice fiscale del soggetto il sistema compilerà per te i dati mancanti se l'utente è già registrato in piattaforma."
          },
          fixed: {
            show: true,
            text: "<b>Corretto il problema che impediva di prenotare un farmaco ed una prestazione nella stessa fascia oraria</b>: l'operatore sanitario potrà, tramite le impostazioni, decidere se consentire o meno la sovrapposizione di più appuntamenti su servizi diversi per la stessa fascia oraria."
          }
        },
        {
          version: "PF01.01.02",
          versionVariant: "primary",
          releaseDate: "27 Ottobre 2022",
          new: {
            show: true,
            text: "<b>Impostazione di date indisponibilità</b> a tutti i servizi: inserita una funzionalità che consente agli operatori sanitari di aggiungere delle date, festive e non, per le quali il sistema non consentirà la prenotazione indipendentemente dalla programmazione settimanale. <br/><b>Aggiornamento note prenotazione</b>, grazie alle quali cittadini ed operatori potranno notificare alla controparte comunicazioni rilevanti per la prenotazione."
          },
          improved: {
            show: true,
            text: "<b>Miglioramenti sull'agenda dell'operatore sanitario</b>: migliorata la visibilità delle prestazioni sanitarie per le quali è possibile associare una sigla da mostrare insieme all'icona ed inserite le funzionalità di ricerca per codice fiscale ed estrazione di report settimanali. <br/><b>Miglioramenti sugli ordini di prodotto</b>: è adesso possibile specificare le quantità per prodotto, segnalare eventuale preferenza del farmaco in versione equivalente/generico e prenotare il ritiro di prodotti fuori-lista inserendoli manualmente."
          },
          fixed: {
            show: false,
            text: ""
          }
        },
        {
          version: "PF01.01.01",
          versionVariant: "primary",
          releaseDate: "25 Agosto 2022",
          new: {
            show: true,
            // text: "<b>E' arrivato il nuovo DottorFARMA!</b><br/> Una nuova grafica, pensata per essere intuitiva e di facile uso, e tante nuove funzionalità. Oltre ai farmaci"
            text: "Rilasciati nella prima versione: <ul><li><b>Modulo core</b>, che garantisce una sicura gestione delle utenze e dei pazienti</li><li><b>Modulo</b> dedicato al <b>teleconsulto</b>, per tenere in contatto operatori sanitari e pazienti anche da remoto</li><li><b>Modulo prenotazioni</b>, che consente ai cittadini di prenotare ordini e prestazioni sanitarie in autonomia secondo le indicazioni di disponibilità degli operatori </li><li><b>Modulo alert</b>, che vuole contribuire a facilitare il monitoraggio e l'aderenza alla terapia per i pazienti</li></ul>"
          },
          improved: {
            show: false,
            text: ""
          },
          fixed: {
            show: false,
            text: ""
          }
        }
      ]
    }
  }
}
</script>
