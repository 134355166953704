<template>
  <!-- <b-row style="min-height: 90vh"> -->
  <b-row>
    <b-col class="col-12 py-0 py-md-1 mobile__hidden"></b-col>
    <b-col
      class="col-12 col-md-5 order-2 order-md-1 pr-lg-4 py-lg-4 pl-0 mr-lg-4 mobile__hidden d-flex"
    >
      <b-col
        class="pr-lg-4 py-lg-4 pl-0 col-11 col-md-12 col-lg d-flex align-items-end"
      >
        <b-img
          :src="require('@/assets/login_img.png')"
          fluid
          center
          alt="Responsive image"
          class="py-lg-4 pr-lg-4"
        >
        </b-img>
      </b-col>
    </b-col>
    <b-col
      class="col-12 col-md order-1 order-md-2 px-0 pr-lg-4 py-lg-4 mx-xl-4"
      :style="bgStyle"
    >
      <b-row class="justify-content-center align-items-center">
        <b-col class="col-12 text-left">
          <div class="mr-auto col-10 col-md-6 col-xl-4 py-2">
            <b-img
              :src="require('@/assets/puntofarma-logo.svg')"
              fluid-grow
              alt="logo puntofarma"
            ></b-img>
          </div>
        </b-col>
        <b-col class="col-12 text-left mb-4">
          <div class="col-12 col-sm-10 mt-5 mt-sm-0 py-2">
            <h4 class="font-weight-light text-center">
              Progettato pensando a te
            </h4>
          </div>
        </b-col>
        <b-col class="col-12 mt-2">
          <b-container>
            <b-overlay
              :show="
                status.loggingIn || $route.query.access_token !== undefined
              "
              spinner-variant="secondary"
              rounded="sm"
              class="puntofarmaCard col-sm-12 col-12 col-md-11 col-lgx-10 col-xl-8"
            >
              <b-form @submit.prevent="onSubmit" class="p-3 p-md-4">
                <div class="mx-auto">
                  <h5 class="my-3">Accedi al tuo account</h5>
                </div>
                <!-- <b-form-group
                  label="Email:"
                  class="col-12 col-md-11 mx-auto my-3 px-0 px-md-2 text-left"
                >
                  <b-form-input
                    name="email"
                    type="email"
                    v-model="form.email"
                    placeholder="email@mail.it"
                    autocomplete="username"
                    required
                  ></b-form-input>
                </b-form-group> -->
                <b-form-group
                  label="Username:"
                  class="col-12 col-md-11 mx-auto my-3 px-0 px-md-2 text-left"
                >
                  <b-form-input
                    name="email"
                    type="text"
                    v-model="form.username"
                    placeholder="email@mail.it"
                    autocomplete="username"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Password:"
                  class="col-12 col-md-11 mx-auto my-3 px-0 px-md-2 text-left"
                >
                  <b-form-input
                    name="password"
                    type="password"
                    v-model="form.password"
                    placeholder="password..."
                    autocomplete="current-password"
                    required
                  ></b-form-input>
                </b-form-group>
                <p v-if="status.loggedIn == false" class="text-danger">
                  <br />
                  Credenziali errate.
                </p>
                <div class="mx-auto my-3 px-3 py-4">
                  <b-button type="submit" variant="secondary">ACCEDI</b-button>
                </div>
                <div class="mx-auto my-3 px-3">
                  <h6 class="my-3">
                    Non sei registrato?
                    <router-link to="/register"> <u>Registrati</u></router-link>
                  </h6>
                  <!-- <h6
                    v-b-modal.modal-1
                    class="my-2 point"
                  >
                    Recupera Password
                  </h6>   -->
                  <h6
                    class="my-2 point"
                    @click="$router.push({ name: 'recupero password' })"
                  >
                    Recupera Password
                  </h6>
                </div>
              </b-form>
            </b-overlay>
            <div>
              <b-modal
                id="modal-1"
                title="Recupera password"
                hide-footer
                centered
              >
                <p class="text-left">
                  Inserisci l'indirizzo email utilizzato in fase di
                  registrazione.
                </p>
                <b-form-input
                  class="my-3"
                  name="email"
                  type="email"
                  v-model="recoverEmail"
                  placeholder="inserisci email..."
                  required
                ></b-form-input>
                <div class="col-12 text-center">
                  <p v-if="status.recoverSent === true">
                    Mail inviata! Controlla la tua posta elettronica.
                  </p>
                  <p
                    v-else-if="status.recoverSent === false"
                    class="text-danger"
                  >
                    Indirizzo non valido
                  </p>
                  <b-button
                    :disabled="!canSubmit"
                    @click="submitRecover"
                    type="button"
                    variant="outline-primary modal-footer-button mt-2 mx-auto col-lg-4 col-sm-7"
                    >Invia</b-button
                  >
                </div>
                <br />
              </b-modal>
            </div>
          </b-container>
        </b-col>
      </b-row>
    </b-col>

    <b-col class="col-12 py-0 py-md-1 mobile__hidden"></b-col>
  </b-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "LoginPage",
  data() {
    return {
      form: {
        // email: null,
        username: null,
        password: null,
      },
      recoverEmail: null,
      canSubmit: false,
      bgStyle: {
        backgroundImage: `url(${require("@/assets/bg_logo.svg")})`,
        backgroundPosition: "right bottom",
        backgroundSize: "70%",
        backgroundRepeat: "no-repeat",
      },
    };
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    ...mapActions("utente", ["login", "logout", "recoverPassword", "me"]),
    onSubmit() {
      // console.log(this.status);
      if (this.form) {
        this.login(this.form)
          .then((res) => {
            // console.log("after login ok", res);
          })
          .catch((error) => {
            console.log("after login error", error);
          });
      }
    },
    submitRecover() {
      this.recoverPassword({ email: this.recoverEmail })
        .then((res) => {
          this.$root.$emit("bv::hide::modal", "modal-1");
        })
        .catch((error) => {
          // this.$root.$emit("bv::hide::modal", "modal-1");
        });
    },
  },
  watch: {
    recoverEmail(val) {
      this.canSubmit = val !== null && val.indexOf("@") > 1;
    },
  },
  mounted() {
    if (this.$route.query.access_token) {
      var self = this;
      this.me(this.$route.query.access_token);
      // .then(function () {
      //   self.$router.push({ name: "Home" });
      // });
    }
  },
};
</script>
<style scoped>
input {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
</style>
<style>
header.modal-header {
  border-color: transparent;
}
</style>
<style lang="scss" scoped>
::placeholder {
  color: #e0e0e0 !important;
}
</style>
