<template>
  <div>
    <div class="d-flex justify-content-between">
      <router-link class=" text-primary no__hover"
        :to="{ name: user.type == 2 ? 'attività e questionari' : 'attivita' }">
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Attività
      </router-link>
    </div>
    <div class="py-3">
      <h6 class="
          text-prim-grad-1
          
          text-left
          pb-1
          border-bottom border-line-grey
        ">
        Terapie
      </h6>
      <p class="text-justify text-medium-grey ">
        Inserisci codice fiscale per visualizzare le terapie di un paziente.
        <span v-if="user && user.type === 1">
          Clicca su "nuova terapia" per inserire una nuova terapia
          farmacologica; per inserire una nuova terapia di altra natura clicca
          su "nuova terapia non farmacologica".</span>
      </p>
      <div class="d-flex mb-3" v-if="opts">
        <b-button v-for="(op, ind) in opts" :key="ind" variant="secondary" class="m-1" disabled>
          <b-icon icon="clipboard-plus"></b-icon>{{ op.name }}
        </b-button>
      </div>
      <div class="text-left d-flex align-items-center">
        <b-form-group label="Inserisci Codice Fiscale*:" class="col-6 ">
          <b-form-input name="cf" type="text" maxlength="16" minlength="16" v-model="filterSetted.cf"
            class="  text-uppercase"></b-form-input>
        </b-form-group>
        <div class="col-4">
          <b-button @click="fetchData" variant="info mt-4"
            :disabled="filterSetted.cf == null || filterSetted.cf.length < 16">Vedi Terapie</b-button>
        </div>
      </div>
      <div class="mt-2" v-if="surveys">
        <h6 class="
            text-prim-grad-1
            
            text-left
            pb-1
            border-bottom border-line-grey
          ">
          Terapie Farmacologiche
        </h6>
        <b-table borderless hover responsive table-variant="white text-info" :fields="fields" :items="surveys.farmaci"
          :small="$root.isMobile" show-empty>
          <template #cell(created_at)="row">
            <span class="text-secondary text-left text-capitalize ">{{
                row.item.created_at | moment("DD/MM/YYYY")
            }}</span>
          </template>
          <template #cell(nome_farmaco)="row">
            <span class="text-secondary text-left ">{{
                row.item.nome_farmaco
            }}</span>
          </template>
          <!-- <template #cell(c_minsan)="row">
            <span class="text-secondary text-left ">{{
              row.item.c_minsan
            }}</span>
          </template> -->
          <template #cell(sent)="row">
            <span class="text-secondary text-left ">{{
                row.item.sent == 1 ? "sì" : "no"
            }}</span>
          </template>
          <template #cell(azioni)="row" v-if="user.type == 1">
            <b-button size="sm" variant="info  px-3" @click="showThis(row)">Vedi</b-button>
            <b-button size="sm" variant="info text-white   px-3" @click="downloadOne(row)">Scarica
            </b-button>
          </template>
          <template #cell(elimina)="row" v-if="user.type == 1">
            <b-button size="sm" variant="danger text-white   px-3" @click="deleteFarmacoIrst(row)">
              Elimina
            </b-button>
          </template>
          <template #empty="scope">
            <h6 v-if="scope" class="text-secondary ">
              Nessuna terapia inserita
            </h6>
          </template>
        </b-table>

        <!-- <p v-if="surveys.farmaci && surveys.farmaci.length < 1">
          Nessuna terapia
        </p> -->
        <h6 class="
            text-prim-grad-1
            
            text-left
            pb-1
            border-bottom border-line-grey
          ">
          Altre terapie
        </h6>
        <b-table borderless hover responsive table-variant="white text-info" :fields="fieldsAltro"
          :items="surveys.altro" :small="$root.isMobile" show-empty>
          <template #cell(created_at)="row">
            <span class="text-secondary text-left text-capitalize ">{{
                row.item.created_at | moment("DD/MM/YYYY")
            }}</span>
          </template>
          <template #cell(tipo_prodotto)="row">
            <span class="text-secondary text-left " v-if="row.item.tipo_prodotto">{{ row.item.tipo_prodotto.descrizione
            }}</span>
          </template>
          <template #cell(sent)="row">
            <span class="text-secondary text-left ">{{
                row.item.sent == 1 ? "sì" : "no"
            }}</span>
          </template>
          <template #cell(azioni)="row" v-if="user.type == 1">
            <b-button size="sm" variant="info info_gradient   px-3" @click="showThis(row)">Vedi</b-button>
            <b-button size="sm" variant="primary  text-white   px-3" @click="downloadOne(row)">Scarica
            </b-button>
          </template>
          <template #cell(elimina)="row" v-if="user.type == 1">
            <b-button size="sm" variant="danger text-white   px-3" @click="deleteAltroIrst(row)">
              Elimina
            </b-button>
          </template>
          <template #empty="scope">
            <h6 v-if="scope" class="text-secondary ">
              Nessuna terapia inserita
            </h6>
          </template>
        </b-table>

        <div class="text-left" v-if="filterSetted.cf !== null && filterSetted.cf.length == 16">
          <b-button v-if="user.type === 1" @click="sendNoVarNotification"
            variant="primary   text-white ">Notifica nessuna variazione</b-button>
        </div>
        <!-- <p v-if="surveys.altro && surveys.length < 1">Nessuna terapia</p> -->
        <b-modal ref="show-filleditem" hide-footer title="Terapia" size="xl">
          <FilledIrstSurveyShower v-if="selectedItem" :response="selectedItem.filled_survey.response" />
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
import { supportService } from "@/_services";
import { mapState, mapActions } from "vuex";
import FilledIrstSurveyShower from "@/components/attivita/FilledIrstSurveyShower.vue";
export default {
  name: "ReportIrst",
  components: { FilledIrstSurveyShower },
  data() {
    return {
      filterSetted: {
        cf: null,
      },
      surveys: null,
      fields: [
        { key: "nome_farmaco", label: "Farmaco" },
        // { key: "c_minsan", label: "Aic" },
        { key: "created_at", label: "Inserita il" },
        { key: "sent", label: "Inviato" },
        { key: "azioni", label: "" },
        { key: "elimina", label: "" },
      ],
      fieldsAltro: [
        { key: "tipo_prodotto", label: "Tipo" },
        { key: "created_at", label: "Inserita il" },
        { key: "sent", label: "Inviato" },
        { key: "azioni", label: "" },
        { key: "elimina", label: "" },
      ],
      template: null,
      selectedItem: null,
      opts: null,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  // watch: {
  //   filterSetted: {
  //     handler(prev, post) {
  //       // console.log("changed prev", prev.status, post);
  //       // console.log("changed post", post.status);
  //       this.fetchData();
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      var self = this;
      supportService
        .getIrstTherapies(this.filterSetted)
        .then(function (res) {
          // console.log(res);
          self.surveys = res.data.data;
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
    showThis(row) {
      this.selectedItem = row.item;
      this.$refs["show-filleditem"].show();
    },
    getReport() {
      var self = this;
      supportService
        .surveyReport(this.$route.params.quest)
        .then(function (response) {
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            self.template.name + "_" + self.template.activity.name + ".pdf";
          link.click();
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
    getReportXsl() {
      var self = this;
      supportService
        .surveyXlsReport(this.$route.params.quest)
        .then(function (response) {
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            self.template.name + "_" + self.template.activity.name + ".xlsx";
          link.click();
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
    downloadOne(row) {
      // console.log(row);
      var self = this;
      var selected = row;
      supportService
        .downloadFilledSurvey(selected.item.filled_survey.hashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = self.filterSetted.cf + "_" + "terapia.pdf";
          link.click();
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
    getOptions() {
      var filters = {
        activity: this.$route.query.activity,
      };
      var self = this;
      supportService
        .getSurveys(filters)
        .then(function (response) {
          // console.log("REST", response);
          self.opts = response.data.data.data;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le attività");
          console.log(err);
        });
    },
    sendNoVarNotification() {
      var self = this;
      var data = { cf: this.filterSetted.cf };
      supportService
        .sendNoVarNotification(data)
        .then(function (response) {
          // console.log("REST", response);
          window.scrollTo(0, 0);
          self.successAlert("Notifica inviata");
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile inviare la notifica.");
          console.log(err);
        });
    },
    deleteFarmacoIrst(rw) {
      var self = this;
      supportService
        .deleteIrstFarmaco(rw.item.hashid)
        .then(function (response) {
          // console.log("REST", response);
          window.scrollTo(0, 0);
          self.successAlert("Farmaco eliminato");
          self.fetchData();
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile inviare la notifica.");
          console.log(err);
        });
    },
    deleteAltroIrst(rw) {
      var self = this;
      supportService
        .deleteIrstAltro(rw.item.hashid)
        .then(function (response) {
          // console.log("REST", response);
          window.scrollTo(0, 0);
          self.successAlert("Terapia eliminata");
          self.fetchData();
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile inviare la notifica.");
          console.log(err);
        });
    },
  },
  created() {
    // this.fetchData();
    if (this.$route.query.activity && this.user.type === 1) {
      this.getOptions();
    }
  },
};
</script>