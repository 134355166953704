<template>
  <div>
    <h5 class="text-prim-grad-1 text-left mt-4 mb-4">Codice utente</h5>

    <p class="text-left">
      Il tuo codice utente ti permette di essere identificato in modo sicuro da
      altri utenti in piattaforma.
    </p>

    <b-row cols="1" class="text-left mx-0">
      <b-col>
        <b-button v-b-toggle.collapse-usercode variant="outline-primary">{{ codeVisible ? "Nascondi" : "Mostra" }}
          codice
          {{ btnLabel }}</b-button>
      </b-col>
      <!-- <b-col>
        <b-button
          v-b-toggle.collapse-csendcode
          variant="primary   text-white"
          >{{ csendcodeVisible ? "Chiudi" : "Invia codice " + btnLabel }}
        </b-button></b-col
      > -->
    </b-row>
    <b-row cols="1" class="mx-0">
      <b-collapse id="collapse-usercode" class="my-2">
        <div class="
            puntofarmaCard mt-4
            border border-light-panel
            general_border
            py-2
            px-3
            col-12
          ">
          <h5 class="">Il tuo codice utente:</h5>
          <p class="card-text text-primary" style="font-size: 1.9rem">
            <span class="mx-auto"> {{ code }}</span>
            <span class="ml-auto mr-2 point" style="position: absolute; right: 0">
              <b-icon id="copier" icon="back" @click="copyCode"></b-icon>
            </span>
          </p>
          <p class="text-justify mb-0">
            Mostra questo codice al tuo caregiver affinché possa trovarti ed
            inserirti nella sua lista di assistiti.
          </p>
          <p class="text-justify">
            Nota: questo codice sarà <u>valido fino alla mezzanotte di oggi</u>.
          </p>
        </div>
      </b-collapse>
      <b-tooltip target="copier" class="bg-primary" variant="primary">
        {{ toolTipText }}
      </b-tooltip>
      <!-- <b-collapse
        id="collapse-csendcode"
        class="mt-2"
        v-model="csendcodeVisible"
      >
        <div
          class="
            panel__box__shadow
            bg-light-panel
            border border-light-panel
            general_border
            py-2
            px-3
          "
        >
          <h5 class="">Invia codice azienda</h5>
          <b-form-group
            label="Inserisci email destinatario a cui inviare il codice:"
            class="text-left"
          >
            <b-form-input
              type="email"
              placeholder="inserisci email.. "
              name="email"
              :state="email == null ? null : validEmail"
              v-model="email"
            ></b-form-input>
          </b-form-group>
          <b-button
            variant="sec-2 px-3 text-white  "
            :disabled="email == null ? true : !validEmail"
            @click="sendCode"
          >
            <span v-if="!sendingMail">Invia codice</span
            ><b-spinner v-else></b-spinner>
          </b-button>
        </div>
      </b-collapse> -->
    </b-row>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { cittadinoService } from "@/_services";
export default {
  name: "ShowUserCode",
  data() {
    return {
      codeVisible: false,
      btnLabel: "utente",
      code: null,
      email: null,
      csendcodeVisible: false,
      sendingMail: false,
      toolTipText: "Copia codice",
    };
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
    ...mapState({
      alert: (state) => state.alert,
    }),
    validEmail() {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return this.email.match(validRegex) ? true : false;
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    getCode() {
      var self = this;
      cittadinoService
        .getCode()
        .then(function (res) {
          self.code = res.data.data.code;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile recuperare il codice");
        });
    },
    copyCode() {
      navigator.clipboard.writeText(this.code);
      this.toolTipText = "Codice copiato!";
      var self = this;
      setTimeout(function () {
        self.toolTipText = "Copia codice";
      }, 3000);
    },
    // sendCode() {
    //   this.sendingMail = true;
    //   var form = {
    //     hash: this.user.company.hashid,
    //     form: { email: this.email },
    //   };
    //   var self = this;
    //   cittadinoService
    //     .sendCode(form)
    //     .then(function (res) {
    //       window.scrollTo(0, 0);
    //       self.successAlert("Codice inviato correttamente!");
    //       self.csendcodeVisible = false;
    //       self.sendingMail = false;
    //     })
    //     .catch(function (err) {
    //       window.scrollTo(0, 0);
    //       self.errorAlert("non è stato possibile recuperare il codice");
    //       self.sendingMail = false;
    //     });
    // },
  },
  mounted() {
    this.getCode();
    this.$root.$on("bv::collapse::state", (collapseId, isJustShown) => {
      if (collapseId == "collapse-usercode") {
        this.codeVisible = isJustShown;
      }
    });
  },
};
</script>