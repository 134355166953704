<template>
  <b-container v-if="notFound">
    <b-row cols="1" class="text-left mb-4">
      <router-link class="text-primary no__hover" :to="{ name: 'pazienti' }">
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Pazienti</router-link
      >
    </b-row>
    <h5 class="" v-if="notFound">Nessun Paziente selezionato</h5>
  </b-container>
  <div v-else>
    <b-row cols="1" class="text-left mb-2">
      <router-link
        class="text-primary no__hover"
        :to="{
          name: 'scheda paziente',
          params: { hash: $route.params.hash },
          query: { section: 'alerts' },
        }"
      >
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Alerts per il paziente</router-link
      >
    </b-row>

    <b-row v-if="showDone" cols="1" class="text-center my-4">
      <h4 class="">
        Setting notifiche completato
        <b-icon icon="check-circle-fill" variant="secondary"></b-icon>
      </h4>

      <b-button
        variant="primary primary__gradient text-white "
        class="col-10 col-md-5 mx-auto my-2"
        @click="
          $router.push({
            name: 'scheda paziente',
            params: { hash: $route.params.hash },
            query: { section: 'alerts' },
          })
        "
        >Torna alle impostazioni del paziente</b-button
      >
    </b-row>
    <b-row cols="1" class="text-left mb-4" v-else>
      <NewAlert :subject="subject" v-on:save-alerts="showRecap($event)" />
    </b-row>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import NewAlert from "@/components/profile/sanitario/NewAlert.vue";
export default {
  name: "NuovoAlertPaziente",
  components: { NewAlert },
  data() {
    return {
      subject: {
        hashid: this.$route.params.hash,
        type: this.$route.query.type,
      },
      notFound: false,
      therapy: null,
      showDone: false,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    ...mapActions("utente", ["me"]),
    showRecap(data) {
      this.showDone = true;
    },
  },
  created() {
    if (!this.$route.params.hash) {
      this.notFound = true;
    }

  },
};
</script>