<template>
  <div class="anagrafica pb-2">
    <div class="d-flex justify-items-between">
      <b-col class="col-12 pl-0 d-flex align-items-center">
        <h4 class="text-left page-title mb-0">Dati anagrafici</h4>
      </b-col>
    </div>
    <h3 class="mt-2 pt-4 text-left">
      <b-icon
        icon="person"
        variant="secondary"
        font-scale="1.2"
        class="mr-1"
      ></b-icon>
      Profilo
      <b-icon
        class="ml-2 mb-1"
        v-if="!editIndexes[0]"
        font-scale="0.8"
        :size="$root.isMobile ? 'sm' : ''"
        type="submit"
        variant="primary"
        @click="setEditIndex(0, true)"
        icon="pencil-fill"
      />
    </h3>

    <b-form @submit.prevent="onSubmit">
      <section
        class="puntofarmaCard border general_border border-white p-2 p-md-3 p-lg-4 mt-1"
      >
        <div
          class="d-flex flex-column flex-md-row h-100 align-items-md-stretch"
        >
          <div
            class="d-flex flex-md-column justify-content-md-between text-center col-12 col-md-3 col-xl-3 mb-4 mb-md-0 p-3 p-md-0"
          >
            <b-avatar
              variant="white"
              :src="avatarPath"
              rounded
              :size="$root.isMobile ? '5rem' : '10rem'"
              class="mx-md-auto"
            >
            </b-avatar>
            <div class="mt-2 d-flex flex-column justify-content-around px-4">
              <b-button
                v-if="avatarPath"
                @click="eliminaAvatarProfilo"
                size="sm"
                class="general_border"
                variant="outline-danger mb-2"
              >
                Elimina
              </b-button>
              <b-button
                @click="$refs.uploadComponentRef.click()"
                size="sm"
                class="general_border mb-2"
                variant="outline-primary"
              >
                Scegli immagine
              </b-button>
              <div class="d-none">
                <UploadComponent
                  dest="profilo"
                  class="w-100 mb-n2 pb-1"
                  @show-carica-button="handleFileSelection($event)"
                  v-on:upload-result="handleUpload($event)"
                  ref="uploadComponentRef"
                />
              </div>
              <b-button
                v-if="showCaricaButton"
                @click="callDoUploadOnUploadComponent()"
                size="sm"
                class="general_border mb-md-3"
                variant="secondary"
              >
                Carica Immagine
              </b-button>
            </div>
          </div>
          <b-row cols="1" cols-md="2" cols-xl="3">
            <b-col class="d-flex flex-wrap">
              <b-form-group
                label="Titolo"
                label-for="title"
                class="text-left fs-6 col-12 col-sm-4 pr-sm-1 text-font-grey mx-auto px-0"
                :class="$root.isMobile ? '' : 'col'"
              >
                <b-form-select
                  v-if="editIndexes[0]"
                  class="mb-3 mt-2 custom-select text-capitalize"
                  name="title"
                  v-model="anagrafica.title"
                  :options="titleOptions"
                  :disabled="!editIndexes[0]"
                >
                  <b-form-select-option disabled :value="null"
                    >Titolo</b-form-select-option
                  >
                </b-form-select>
                <b-form-input
                  v-else
                  class="my-3 text-capitalize px-0"
                  name="title"
                  type="text"
                  placeholder="Sig..."
                  v-model="anagrafica.title"
                  :readonly="!editIndexes[0]"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Nome*"
                label-for="name"
                class="text-left col-12 col-sm-8 fs-6 text-font-grey px-0"
              >
                <b-form-input
                  class="my-3 text-capitalize"
                  name="name"
                  type="text"
                  placeholder="nome..."
                  v-model="anagrafica.name"
                  :readonly="!editIndexes[0]"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Cognome*"
                label-for="surname"
                class="text-left fs-6 text-font-grey"
              >
                <b-form-input
                  class="my-3 text-capitalize"
                  name="surname"
                  type="text"
                  placeholder="cognome..."
                  v-model="anagrafica.surname"
                  :readonly="!editIndexes[0]"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Data di nascita*"
                label-for="birthday"
                class="text-left fs-6 text-font-grey"
              >
                <b-form-input
                  class="my-3"
                  name="birthday"
                  type="date"
                  v-model="anagrafica.birthday"
                  :readonly="!editIndexes[0]"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Genere"
                label-for="gender"
                class="text-left d-flex flex-column justify-content-around fs-6 text-font-grey"
              >
                <b-form-radio-group
                  v-model="anagrafica.gender"
                  name="gender-options"
                  class="d-flex mt-2"
                >
                  <b-form-radio value="maschile">uomo</b-form-radio>
                  <b-form-radio value="femminile">donna</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Codice fiscale*"
                label-for="cf"
                class="text-left fs-6 text-font-grey"
              >
                <b-form-input
                  class="my-3 text-uppercase"
                  name="cf"
                  type="text"
                  placeholder="codice fiscale..."
                  maxlength="16"
                  minlength="16"
                  v-model="anagrafica.cf"
                  :readonly="!editIndexes[0]"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Numero Tessera Sanitaria"
                label-for="ts_number"
                class="text-left fs-6 text-font-grey"
              >
                <b-form-input
                  class="my-3"
                  name="ts_number"
                  type="text"
                  placeholder="numero tessera..."
                  v-model="anagrafica.ts_number"
                  maxlength="20"
                  minlength="20"
                  :readonly="!editIndexes[0]"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Nazione di Nascita"
                label-for="nazione_nascita"
                class="text-left fs-6 text-font-grey"
              >
                <b-form-input
                  v-if="!editIndexes[0]"
                  id="nazione_nascita"
                  class="my-3 text-capitalize"
                  name="ts_number"
                  type="text"
                  placeholder="..."
                  v-model="anagrafica.nazione_nascita"
                  readonly
                ></b-form-input>
                <b-form-select
                  v-else
                  id="nazione_nascita"
                  class="mt-2 custom-select text-capitalize"
                  :options="nazioniOptions"
                  v-model="anagrafica.nazione_nascita"
                >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col v-if="user.type === 0">
              <b-form-group
                label="Medico di base"
                label-for="medico_base"
                class="text-left fs-6 text-font-grey"
              >
                <b-form-input
                  class="my-3"
                  name="ts_number"
                  type="text"
                  placeholder="Medico di base..."
                  v-model="anagrafica.medico_base"
                  :readonly="!editIndexes[0]"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col v-if="user.type === 0">
              <b-form-group
                label="Asl/Asp/Ats di riferimento"
                label-for="denominazione_asl"
                class="text-left fs-6 text-font-grey"
              >
                <b-form-select
                  class="mb-3 mt-2 custom-select text-capitalize"
                  name="denominazione_asl"
                  v-model="anagrafica.denominazione_asl"
                  :disabled="!editIndexes[0]"
                >
                  <b-form-select-option
                    v-for="aslOption in asl"
                    :key="aslOption"
                    :value="aslOption"
                    >{{ aslOption }}</b-form-select-option
                  >
                  <b-form-select-option :value="null"
                    >Scegli azienda sanitaria locale</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
            <template v-else-if="user.type === 1">
              <b-col>
                <b-form-group
                  label="Ordine"
                  label-for="tipo_albo"
                  class="text-left"
                >
                  <b-form-input
                    v-if="!editIndexes[0]"
                    class="my-3 text-capitalize"
                    name="tipo_albo"
                    type="text"
                    placeholder="Albo ordine..."
                    v-model="anagrafica.tipo_albo"
                    :readonly="!editIndexes[0]"
                  ></b-form-input>
                  <b-form-select
                    v-else
                    class="mb-3 mt-2 custom-select text-capitalize"
                    name="tipo_albo"
                    v-model="anagrafica.tipo_albo"
                    :options="tipoAlboOptions"
                  >
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="
                    editIndexes[0]
                      ? 'ID Iscrizione Albo**'
                      : 'ID Iscrizione Albo'
                  "
                  label-for="id_albo"
                  class="text-left"
                  :description="
                    editIndexes[0] ? '**Includere la sigla provincia' : ''
                  "
                >
                  <b-form-input
                    class="my-3 text-capitalize"
                    name="id_albo"
                    type="text"
                    placeholder="ID ordine..."
                    v-model="anagrafica.id_albo"
                    :readonly="!editIndexes[0]"
                    :state="editIndexes[0] ? validIdOrdine : null"
                    @input="
                      (val) =>
                        (anagrafica.id_albo = anagrafica.id_albo.toUpperCase())
                    "
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </template>
          </b-row>
        </div>
        <p class="mb-0 w-100 text-right pt-3" v-if="editIndexes[0]">
          <b-button
            type="button"
            variant="outline-danger general_border mx-2"
            @click.prevent="handleAnnulla()"
            >Annulla</b-button
          >
          <b-button
            type="submit"
            variant="outline-primary general_border mx-2"
            :disabled="!enableBtn"
            >Salva Modifiche
          </b-button>
        </p>
      </section>

      <b-row>
        <b-col class="px-0 col-12 col-lgx-6">
          <div class="mr-lgx-2">
            <h3 class="mt-2 pt-4 text-left">
              <b-icon
                icon="geo"
                variant="secondary"
                font-scale="1.2"
                class="mr-1"
              ></b-icon>
              Residenza
              <b-icon
                class="ml-2 mb-1"
                v-if="!editIndexes[1]"
                font-scale="0.8"
                :size="$root.isMobile ? 'sm' : ''"
                type="submit"
                variant="primary"
                @click="setEditIndex(1, true)"
                icon="pencil-fill"
              />
            </h3>
            <section
              class="bg-white general_border puntofarmaCard p-2 p-md-3 p-lg-4 mt-2"
            >
              <b-row cols="1" cols-md="2" cols-xl="3">
                <b-col>
                  <b-form-group
                    label="Regione*"
                    label-for="regione"
                    class="text-left fs-6 text-font-grey"
                  >
                    <b-form-select
                      class="my-3 custom-select text-capitalize"
                      :options="regioni"
                      name="regione"
                      v-model="selected.regione"
                      @change="getProvince(selected)"
                      :disabled="!editIndexes[1]"
                    >
                      <b-form-select-option :value="null"
                        >Seleziona Regione</b-form-select-option
                      >
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Provincia*"
                    label-for="provincia"
                    class="text-left fs-6 text-font-grey"
                  >
                    <b-form-select
                      class="my-3 text-capitalize"
                      :options="province"
                      name="provincia"
                      v-model="selected.provincia"
                      @change="getComuni(selected)"
                      :disabled="!editIndexes[1]"
                    >
                      <b-form-select-option :value="null"
                        >Seleziona Provincia</b-form-select-option
                      >
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Comune*"
                    label-for="comune"
                    class="text-left fs-6 text-font-grey"
                  >
                    <b-form-select
                      class="my-3 text-capitalize"
                      name="comune"
                      v-model="selected.comune"
                      :disabled="!editIndexes[1]"
                    >
                      <b-form-select-option
                        v-for="(com, ind) in comuni"
                        :key="ind"
                        :value="com"
                        @change="setDomicilioData"
                        :selected="findComune(com)"
                        >{{ com.comune }}</b-form-select-option
                      >
                      <b-form-select-option :value="null"
                        >Seleziona Comune</b-form-select-option
                      >
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Indirizzo e numero civico"
                    label-for="address"
                    class="text-left fs-6 text-font-grey"
                  >
                    <b-form-input
                      class="my-3 text-capitalize"
                      name="address"
                      type="text"
                      placeholder="indirizzo..."
                      v-model="anagrafica.address"
                      :readonly="!editIndexes[1]"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Cap"
                    label-for="cap"
                    class="text-left fs-6 text-font-grey"
                  >
                    <b-form-input
                      class="my-3"
                      name="cap"
                      type="text"
                      placeholder="cap..."
                      v-model="anagrafica.cap"
                      minlength="5"
                      maxlength="5"
                      :readonly="!editIndexes[1]"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <p class="mb-0 w-100 text-right pt-3" v-if="editIndexes[1]">
                <b-button
                  type="button"
                  variant="outline-danger general_border mx-2"
                  @click.prevent="handleAnnulla()"
                  >Annulla</b-button
                >
                <b-button
                  type="submit"
                  variant="outline-primary general_border mx-2"
                  :disabled="!enableBtn"
                  >Salva Modifiche
                </b-button>
              </p>
            </section>
          </div>
        </b-col>
        <b-col class="px-0 col-12 col-lgx-6">
          <div class="ml-lgx-2">
            <h3 class="mt-2 pt-4 text-left">
              <b-icon
                icon="telephone"
                variant="secondary"
                font-scale="1.2"
                class="mr-1"
              ></b-icon>
              Contatti
              <b-icon
                class="ml-2 mb-1"
                v-if="!editIndexes[2]"
                font-scale="0.8"
                :size="$root.isMobile ? 'sm' : ''"
                type="submit"
                variant="primary"
                @click="setEditIndex(2, true)"
                icon="pencil-fill"
              />
            </h3>
            <section
              class="puntofarmaCard p-2 p-md-3 p-lg-4 mt-2 general_border"
            >
              <b-row cols="1" cols-md="2" cols-xl="3">
                <b-col>
                  <b-form-group
                    label="Numero di telefono"
                    label-for="tel_number"
                    class="text-left fs-6 text-font-grey general_border"
                  >
                    <b-form-input
                      required
                      class="my-3"
                      name="tel_number"
                      type="text"
                      placeholder="telefono..."
                      v-model="anagrafica.tel_number"
                      minlength="9"
                      :readonly="!editIndexes[2]"
                      :state="
                        editIndexes[2] && anagrafica.tel_number
                          ? validNumber
                          : null
                      "
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <!-- Il telefono domicilio e il telefono di riserva
                sono presenti solo per l'utenza cittadino
                 -->
                <template v-if="user.type === 0">
                  <b-col>
                    <b-form-group
                      label="Telefono Domicilio"
                      label-for="home_number"
                      class="text-left fs-6 text-font-grey general_border"
                    >
                      <b-form-input
                        class="my-3"
                        name="tel_number"
                        type="text"
                        placeholder="telefono domicilio..."
                        v-model="anagrafica.home_number"
                        minlength="9"
                        :readonly="!editIndexes[2]"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Telefono di Riserva"
                      label-for="tel_number_secondary"
                      class="text-left fs-6 text-font-grey general_border"
                    >
                      <b-form-input
                        class="my-3"
                        name="tel_number"
                        type="text"
                        placeholder="telefono di riserva..."
                        v-model="anagrafica.tel_number_secondary"
                        minlength="9"
                        :readonly="!editIndexes[2]"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </template>
              </b-row>
              <p class="mb-0 w-100 text-right pt-3" v-if="editIndexes[2]">
                <b-button
                  type="button"
                  variant="outline-danger general_border mx-2"
                  @click.prevent="handleAnnulla()"
                  >Annulla</b-button
                >
                <b-button
                  type="submit"
                  variant="outline-primary general_border mx-2"
                  :disabled="!enableBtn"
                  >Salva Modifiche
                </b-button>
              </p>
            </section>
            <b-row class="text-left mt-3">
              <h6
                v-if="!this.user.number_verified_at"
                @click="$router.push({ name: 'Home' })"
                class="ml-4 text-primary underlined point"
              >
                <b-icon icon="arrow-left" font-scale="0.7" class="mx-1"></b-icon
                >torna alla verifica
              </h6>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-form>
    <!-- <div class="col-10 mx-auto">
      <h6
        class="text-prim-grad-1  text-left pb-1 border-bottom border-line-grey"
      >
        Campi Personalizzati
      </h6>
    </div> -->
    <b-modal
      id="delete-propic-confirm"
      size="lg"
      centered
      title="Conferma eliminazione"
      button-size="sm"
      cancel-title="No, Annulla"
      cancel-variant="info mr-2"
      ok-title="Sì, Conferma"
      ok-variant="outline-danger"
      footer-class="border-0"
      @ok="deleteUserPropic()"
      @cancel="$bvModal.hide('delete-propic-confirm')"
    >
      Confermi di voler eliminare l'immagine del profilo?
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { cittadinoService, utilityService } from "@/_services";

import * as moment from "moment/moment";
import UploadComponent from "@/components/utilities/UploadComponent.vue";
import { mapState, mapActions } from "vuex";
import { userService } from "../../_services";
export default {
  name: "AnagraficaComponent",
  computed: {
    ...mapState("utente", ["status", "user"]),
    ...mapState("utility", [
      "searching",
      "comuni",
      "province",
      "regioni",
      "asl",
    ]),
    validNumber() {
      if (this.anagrafica.tel_number) {
        const prefix = this.anagrafica.tel_number.slice(0, 2);
        const hasPrefix =
          prefix === "00" || this.anagrafica.tel_number.indexOf("+") >= 0;
        return !isNaN(this.anagrafica.tel_number) && !hasPrefix;
      }
      return false;
    },
    validIdOrdine() {
      if (this.anagrafica.id_albo) {
        const regx = /^[A-Za-z]{2}/;
        return this.anagrafica.id_albo.match(regx) ? true : false;
      }
      return null;
    },
    nazioniOptions() {
      if (this.nazioni && this.nazioni.length > 0) {
        return [
          { value: null, text: "Seleziona Nazione" },
          { value: "italia", text: "Italia" },
        ].concat(
          this.nazioni.map((nazione) => {
            return {
              value: nazione.nazione,
              text: nazione.nazione
                .toLowerCase()
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" "),
            };
          })
        );
      } else
        return [
          {
            value: null,
            text: "Seleziona Nazione",
          },
        ];
    },
  },
  components: {
    UploadComponent,
  },
  data() {
    return {
      editIndexes: [false, false, false],
      avatarPath: null,
      showCaricaButton: false,
      selected: {
        regione: null,
        provincia: null,
        comune: null,
      },
      anagrafica: {
        regione: null,
        provincia: null,
        comune: null,
        cap: null,
        address: null,
        name: null,
        surname: null,
        cf: null,
        gender: null,
        birthday: null,
        ts_number: null,
        tel_number: null,
        home_number: null,
        tel_number_secondary: null,
        medico_base: null,
        denominazione_asl: null,
        title: null,
        id_albo: null,
        tipo_albo: null,
      },
      titleOptions: ["Sig.", "Sig.ra", "Dott.", "Dott.ssa"],
      tipoAlboOptions: ["Medici", "Dentisti", "Farmacisti"],
      enableBtn: false,
      isPost: true,
      selectingImage: false,
    };
  },
  methods: {
    ...mapActions("utility", [
      "getComuni",
      "getProvince",
      "getRegioni",
      "getAsl",
    ]),
    ...mapActions("utente", ["setAnagrafica", "me"]),
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    setEditIndex(index, value) {
      console.log("index", index);
      this.editIndexes.forEach((el, i) => {
        Vue.set(this.editIndexes, i, false);
      });
      Vue.set(this.editIndexes, index, value);
    },
    setDomicilioData() {
      // console.log("on change", this.selected.comune);
      if (this.selected.comune) {
        this.anagrafica.comune = this.selected.comune.comune;
        this.anagrafica.provincia = this.selected.comune.provincia;
        this.anagrafica.regione = this.selected.comune.regione;
      }
    },
    callDoUploadOnUploadComponent() {
      this.showCaricaButton = false;
      this.$refs.uploadComponentRef.doUpload();
    },
    deleteUserPropic() {
      var self = this;
      cittadinoService
        .deleteUserPropic()
        .then(function (res) {
          // console.log(res);
          self.successAlert("Immagine del profilo eliminata correttamente");
          self.avatarPath = null;
          self.me();
        })
        .catch(function (err) {
          console.log(err);
          self.errorAlert("Errore nell'eliminazione della nota");
        });
    },
    handleUpload(data) {
      // console.log("Handling");
      var self = this;
      this.avatarPath = data.full_path;
      cittadinoService
        .attachUploadedResourceToUser(data.hashid)
        .then(function (res) {
          self.selectingImage = false;
          self.successAlert("Immagine del profilo caricata correttamente");
          self.me();
        })
        .catch(function (err) {
          console.log(err);
          self.errorAlert("Errore nel caricamento dell'immagine del profilo");
        });
    },
    onSubmit() {
      if (this.anagrafica.tel_number && !this.validNumber) {
        this.errorAlert("Numero di telefono non valido");
        return false;
      }
      // console.log("submit", this.anagrafica);
      this.setAnagrafica({ form: this.anagrafica, isPost: this.isPost }).then(
        (res) => {
          window.scrollTo(0, 0);
          this.editIndexes = [false, false, false];
        }
      );
    },
    setupPrevs() {
      if (this.user.anagrafica.regione) {
        this.selected.regione = this.user.anagrafica.regione;
        this.getProvince(this.selected);
      }
      if (this.user.anagrafica.provincia) {
        this.selected.provincia = this.user.anagrafica.provincia;
        this.getComuni(this.selected);
      }
    },
    findComune(comune) {
      if (this.user.anagrafica && this.user.anagrafica.comune) {
        if (comune.comune === this.user.anagrafica.comune) {
          this.selected.comune = comune;
          return true;
        }
      }
      return false;
    },
    handleAnnulla() {
      this.editIndexes.forEach((el, index) => {
        Vue.set(this.editIndexes, index, false);
      });
      if (this.isPost) {
        $router.go(-1);
      }
      this.me();
    },
    handleFileSelection(file) {
      console.log("file", file);
      this.showCaricaButton = true;
      this.selectingImage = true;
      this.avatarPath = URL.createObjectURL(file);
    },
    eliminaAvatarProfilo() {
      if (this.selectingImage) {
        this.avatarPath = null;
        this.showCaricaButton = false;
        return;
      }
      this.$bvModal.show("delete-propic-confirm");
    },
    getNazioni() {
      var self = this;
      utilityService
        .nazioni()
        .then((res) => {
          self.nazioni = res.data;
          // console.log(res.data)
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    anagrafica: {
      handler(prev, post) {
        this.enableBtn = true;
        // console.log(prev);
      },
      deep: true,
    },
    selected: {
      handler(val) {
        if (val.comune !== null) {
          this.anagrafica.comune = this.selected.comune.comune;
          this.anagrafica.provincia = this.selected.comune.provincia;
          this.anagrafica.regione = this.selected.comune.regione;
        }
      },
      deep: true,
    },
  },
  created() {
    this.me();
    this.getRegioni();
    this.getNazioni();
    this.getAsl({ denominazione: true });
    if (this.user.anagrafica !== null) {
      if (this.user.profile_pic) {
        this.avatarPath = this.user.profile_pic;
      }
      this.isPost = false;
      this.editIndexes = [false, false, false];
      this.anagrafica = this.user.anagrafica;
      this.enableBtn = false;
      this.setupPrevs();
    }
  },
  mounted() {
    if (this.user.anagrafica !== null) {
      this.setupPrevs();
    }
  },
};
</script>
<style lang="scss" scoped>
input.form-control:read-only {
  border-bottom: 1px solid darkgrey;
  border-radius: 0px !important;
  box-shadow: none !important;
}
</style>
