<template>
  <b-container v-if="notFound">
    <b-row cols="1" class="text-left mb-4">
      <router-link
        class="text-primary no__hover"
        :to="{ name: 'familiari', params: { section: 'main' } }"
      >
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Pazienti</router-link
      >
    </b-row>
    <h5 class="font-weight-bolder" v-if="notFound">
      Nessun Paziente selezionato
    </h5>
  </b-container>
  <b-container fluid v-else class="pb-5">
    <b-row cols="1" class="text-left mb-4">
      <router-link
        class="text-primary no__hover"
        :to="{
          name: 'familiari',
          params: {
            section: 'assistito',
            hash: $route.query.sub,
          },
        }"
      >
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Profilo sanitario</router-link
      >
    </b-row>
    <div v-if="therapy" class="text-left">
      <h3 class="font-weight-bolder text-center">Riepilogo</h3>
      <RecapTherapy :therapy="therapy" class="fs-5" />
      <!-- <b-button
        @click="infoAlert('Funzionalità in sviluppo')"
        variant="secondary text-white secondary_gradient mx-2"
        >Scarica promemoria
      </b-button>   -->
      <b-button
        @click="downloadRecap"
        variant="secondary text-white secondary_gradient mx-2"
        >Scarica promemoria
      </b-button>
      <b-button
        @click="
          $router.push({
            name: 'familiari',
            params: {
              section: 'assistito',
              hash: $route.query.sub,
            },
          })
        "
        type="button"
        variant="primary text-white  mx-2"
        >Torna al profilo sanitario
        <b-icon icon="arrow-right"></b-icon>
      </b-button>
    </div>
    <NewTherapy
      class="col-12"
      v-else
      :subject="subject"
      v-on:save-therapy="showRecap($event)"
    />
  </b-container>
</template>
<script>
import { cittadinoService } from "@/_services";
import { mapState, mapActions } from "vuex";
import NewTherapy from "@/components/profile/sanitario/NewTherapy.vue";
import RecapTherapy from "@/components/profile/sanitario/RecapTherapy.vue";
export default {
  name: "NuovaTerapiaAssistito",
  components: { NewTherapy, RecapTherapy },
  data() {
    return {
      activePanel: "rilevazioni",
      subject: {
        hashid: this.$route.query.sub,
        type: this.$route.query.type,
      },
      notFound: false,
      therapy: null,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    ...mapActions("utente", ["me"]),
    showRecap(therapy) {
      this.therapy = therapy;
    },
    downloadRecap() {
      var self = this;
      cittadinoService
        .therapyRecap(this.therapy.hashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "promemoria_terapia.pdf";
          link.click();
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile scaricare il promemoria");
        });
    },
  },
  created() {
    if (!this.$route.query.sub) {
      this.notFound = true;
    }
  },
};
</script>
