<template>
  <b-spinner v-if="!company"></b-spinner>
  <div class="px-2 pb-2" v-else>
    <!-- <p class="mb-0 text-left">Annulla tutte le prenotazioni</p> -->
    <h4 class="text-left pb-1 mt-1 mb-4">Annullamento rapido</h4>

    <p class="text-left text-medium-grey mt-2 mb-4">
      Funzionalità per annullamento rapido delle prenotazioni per uno specifico
      giorno.
    </p>
    <b-form
      method="post"
      @submit.prevent="submitCancel"
      class="puntofarmaCard px-4 py-4"
    >
      <b-row class="">
        <b-col class="col-md-6 col-12 my-2 my-md-3 my-xl-4">
          <p class="text-left">Tipo Prenotazione*</p>
          <b-form-group label-for="type" class="text-left">
            <b-form-select
              name="type"
              class="general_border"
              :options="filterOptions.service"
              v-model="form.order_type"
              required
            >
              <b-form-select-option :value="null"
                >scegli tipo</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col class="col-md-6 col-12 my-2 my-md-3 my-xl-4">
          <template v-if="form.order_type === 'prestazione'">
            <p class="text-left">Tipo Prestazione*</p>
            <b-form-group
              v-if="
                form.order_type === 'prestazione' && company.prestazioni_attive
              "
              label-for="subtype"
              class="text-left general_border"
            >
              <b-form-select
                name="subtype"
                class=""
                v-model="form.subtype_hash"
              >
                <b-form-select-option
                  v-for="(hash, name) in company.prestazioni_attive"
                  :key="name"
                  :value="hash"
                  >{{ name }}</b-form-select-option
                >
                <b-form-select-option :value="null">tutte</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </template>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="col-md-6 col-12 my-2 my-md-3 my-xl-4">
          <p class="text-left">Per il giorno*</p>
          <b-form-group label-for="data" class="text-left">
            <b-form-input
              name="data"
              class="mx-auto general_border"
              type="date"
              v-model="form.data"
              required
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col class="col-6 col-md-3 my-2 my-md-3 my-xl-4">
          <p class="text-left">Dalle</p>
          <b-form-group label-for="dal" class="text-left">
            <b-form-input
              name="dal"
              class="mx-auto general_border"
              type="time"
              v-model="form.dal"
              :required="form.al !== null"
              :state="form.al !== null ? validateTime() : null"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col class="col-6 col-md-3 my-2 my-md-3 my-xl-4">
          <p class="text-left">Alle</p>
          <b-form-group label-for="al" class="text-left">
            <b-form-input
              name="al"
              class="mx-auto"
              type="time"
              v-model="form.al"
              :required="form.dal !== null"
              :state="form.dal !== null ? validateTime() : null"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="col-12 my-2 my-md-3 my-xl-4">
          <p class="text-left">(*)parametri obbligatori</p>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="col-12 my-2 my-md-3 my-xl-4">
          <b-button
            @click="$refs['modal-confirm'].show()"
            :disabled="form.data == null"
            variant="secondary text-white secondary-gradient "
            >Conferma</b-button
          >
        </b-col>
      </b-row>

      <b-modal
        size="xl"
        hide-footer
        centered
        ref="modal-confirm"
        title="Conferma Annullamento"
      >
        <PickMessageForm
          :messages="cancellationMessages"
          confirm-message="Confermi di voler cancellare gli appuntamenti nell'arco di tempo prescelto?"
          @cancellation-confirmed="submitCancel($event)"
          @refused-to-confirm="$bvModal.hide('modal-confirm')"
        />
      </b-modal>
    </b-form>
    <h4 class="text-left pb-1 mt-3 mb-4">Evasione Rapida</h4>

    <p class="text-left text-medium-grey mt-2 mb-4">
      Funzionalità per evasione rapida delle prenotazioni per uno specifico
      giorno.
    </p>
    <EvadiUtility />
  </div>
</template>
<script>
import * as moment from "moment/moment"
import { reservationService } from "@/_services"
import { mapState, mapActions } from "vuex"
import EvadiUtility from "@/components/prenotazioni/sections/EvadiUtility.vue"
import { CANCELLATION_MESSAGES } from "@/_utils/constants.js"
import PickMessageForm from "@/components/prenotazioni/form/PickMessageForm.vue"

export default {
  name: "CancelUtility",
  components: { EvadiUtility, PickMessageForm },
  data() {
    return {
      form: {
        data: null,
        order_type: "prestazione",
        subtype_hash: null,
        message: null,
        dal: null,
        al: null
      },
      filterOptions: {
        status: ["in corso", "in attesa"],
        // service: ["prestazione"],
        service: ["prestazione", "prodotto", "tutte"]
      },
      cancellationMessages: CANCELLATION_MESSAGES,
      submitting: false
    }
  },
  computed: {
    ...mapState("company", ["company"])
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    validateTime: function () {
      if (!this.form.dal || !this.form.al) {
        return null
      }
      var from = moment(this.form.dal, "HH:mm")
      var to = moment(this.form.al, "HH:mm")
      // console.log(moment(from).isSameOrBefore(to));
      return moment(from).isSameOrBefore(to)
    },
    submitCancel(message = null) {
      // console.log("submitting");
      this.submitting = true
      this.form.message = message
      var self = this
      reservationService
        .massiveCancel(this.form)
        .then(function (response) {
          var data = moment(self.form.data).format("D MMMM")
          var preposition = ["1", "8", "11"].includes(
            moment(self.form.data).format("D")
          )
            ? "per l'"
            : "per il "
          window.scrollTo(0, 0)
          self.successAlert(
            "Prenotazioni " +
              self.form.order_type +
              ` ${preposition}` +
              data +
              " annullate con successo"
          )
          self.$emit("update-prenotazioni")
          self.submitting = false
          self.$refs["modal-confirm"].hide()
          self.form = {
            data: null,
            // order_type: "tutte",
            order_type: "prestazione",
            subtype_hash: null,
            message: null,
            dal: null,
            al: null
          }
          self.message = 6
          self.freeText = null
        })
        .catch(function (error) {
          self.$refs["modal-confirm"].hide()
          self.submitting = false
          window.scrollTo(0, 0)
          self.errorAlert("Nessuna prenotazione da annullare")
        })
    }
  },
  watch: {
    $route(val) {
      if (
        val.query.scope &&
        this.filterOptions.service.includes(val.query.scope)
      ) {
        this.form.order_type = val.query.scope
      } else {
        // this.form.order_type = "tutte";
        this.form.order_type = "prestazione"
      }
    }
  },
  mounted() {
    if (
      this.$route.query.scope &&
      this.filterOptions.service.includes(this.$route.query.scope)
    ) {
      this.form.order_type = this.$route.query.scope
    } else {
      //in app completa sarà "tutte"
      // this.form.order_type = "tutte";
      this.form.order_type = "prestazione"
    }
  }
}
</script>
