<template>
  <div>
    <div
      :class="$root.isMobile ? 'py-1' : ' py-3'"
      class="col-12 top_header d-flex flex-wrap align-items-center px-0"
    >
      <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
      <div class="col-md-2 col-sm-12 px-0 mx-auto order-1">
        <div :class="$root.isMobile && 'd-flex align-items-center'">
          <router-link
            :to="{ name: 'Home' }"
            class="app__logo"
            :class="$root.isMobile ? 'ml-2' : ' px-3 py-4'"
            :style="cssLogo"
          >
            <!-- <img
            src="http://127.0.0.1:8000/storage/logos/logo-df.png"
            alt="appLogo"
            class=""
          /> -->
            <span class="p-2 my-2"> APP LOGO</span>
          </router-link>
          <div
            v-if="$root.isMobile"
            class="col-sm-5 col-md-2 px-0 ml-auto mr-1 order-1"
          >
            <b-dropdown
              :text="'Benvenuto ' + welcomeName"
              variant="font-grey-outline  bg-transparent text-white border-transparent px-1 text-capitalize"
              v-if="user && user.username"
              id="nav_dd"
            >
              <b-dropdown-item
                variant="font-grey-outline"
                @click="$router.push({ name: 'guida' })"
              >
                <span> <b-icon icon="book"></b-icon> Guida</span>
              </b-dropdown-item>
              <b-dropdown-item
                variant="font-grey-outline"
                @click.prevent="onSubmit"
              >
                <span> <b-icon icon="door-open-fill"></b-icon> Esci</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
      <div
        v-if="!$root.menuLeft"
        :class="$root.isMobile ? 'order-3' : 'order-2'"
        class="
          col-sm-12 col-md-6
          bg-white
          shadow_3
          px-2
          d-flex
          justify-content-center
          mx-auto
          general_border
          border border-white
          mbl__fx
        "
      >
        <router-link
          class="my-3 mx-auto"
          :to="{ name: 'Home' }"
          :class="this.$route.path === homePath && 'router-link-exact-active'"
        >
          <b-icon
            font-scale="1.5"
            :icon="
              this.$route.path === homePath ? 'house-door-fill' : 'house-door'
            "
          >
          </b-icon>
          <p class="mb-0 fs-5">Home</p>
        </router-link>
        <router-link
          v-if="canSeeProfilo"
          class="my-3 mx-auto"
          :to="{ name: 'Profilo' }"
        >
          <b-icon
            font-scale="1.5"
            :icon="this.$route.name === 'Profilo' ? 'person-fill' : 'person'"
          ></b-icon>
          <p class="mb-0 fs-5">Profilo</p></router-link
        >
        <!-- <router-link
          v-if="user.type == 1"
          class="my-3 mx-auto"
          :to="{ name: 'Orari' }"
        >
          <b-icon
            font-scale="1.5"
            :icon="this.$route.name === 'Orari' ? 'clock-fill' : 'clock'"
          ></b-icon>
          <p class="mb-0 fs-5">Orari</p></router-link
        > -->
        <router-link
          v-if="canSeeImpostazioni"
          class="my-3 mx-auto"
          :to="{ name: 'Impostazioni' }"
        >
          <b-icon
            font-scale="1.5"
            :icon="this.$route.name === 'Impostazioni' ? 'gear-fill' : 'gear'"
          ></b-icon>
          <p class="mb-0 fs-5">Impostazioni</p></router-link
        >
      </div>
      <div
        v-else
        class="col-md-6 col-sm-9 px-2 mx-auto mbl__fx"
        :class="$root.isMobile ? 'order-3' : 'order-2'"
      >
        <div v-if="user.type === 1">
          <PrenotazioniMenu
            v-if="
              $route.name === 'prenotazioni' ||
              $route.name === 'calendario prenotazioni'
            "
            class="
              bg-white
              shadow_3
              px-2
              d-flex
              justify-content-center
              mx-auto
              general_border
              border border-white
            "
          />
          <AttivitaMenu
            v-else-if="
              $route.name === 'attivita' || $route.name === 'questionari'
            "
            class="
              bg-white
              shadow_3
              px-2
              d-flex
              justify-content-center
              mx-auto
              general_border
              border border-white
            "
          />
          <TeleconsultoMenu
            v-else-if="
              $route.name === 'teleconsulto' ||
              $route.name === 'videoconsulto' ||
              $route.name === 'telecalendario'
            "
            class="
              bg-white
              shadow_3
              px-2
              d-flex
              justify-content-center
              mx-auto
              general_border
              border border-white
            "
          />
          <div
            v-else
            class="
              bg-white
              shadow_3
              px-2
              d-flex
              justify-content-center
              mx-auto
              general_border
              border border-white
            "
          >
            <p class="mb-0 py-4 text-capitalize ">
              {{ $route.params.type ? $route.params.type : $route.name }}
            </p>
          </div>
        </div>
        <div
          v-else
          class="
            bg-white
            shadow_3
            px-2
            d-flex
            justify-content-center
            mx-auto
            general_border
            border border-white
          "
        >
          <p class="mb-0 py-4 text-capitalize ">
            {{ $route.params.type ? $route.params.type : $route.name }}
          </p>
        </div>
      </div>
      <!-- <PrenotazioniMenu v-else-if="$route.name === 'prenotazioni'" /> -->
      <div
        class="col-sm-5 col-md-2 px-0 order-2"
        :class="$root.isMobile ? 'ml-auto mr-1' : 'ml-0 mr-auto'"
        v-if="!$root.isMobile"
      >
        <b-dropdown
          :text="'Benvenuto ' + welcomeName"
          variant="font-grey-outline  bg-transparent text-white border-transparent px-1 text-capitalize"
          v-if="user && user.username"
          id="nav_dd"
        >
          <b-dropdown-item
            v-if="user.type === 1"
            variant="font-grey-outline"
            @click="$router.push({ name: 'guida' })"
          >
            <span> <b-icon icon="book"></b-icon> Guida</span>
          </b-dropdown-item>
          <b-dropdown-item
            variant="font-grey-outline"
            @click.prevent="onSubmit"
          >
            <span> <b-icon icon="door-open-fill"></b-icon> Esci</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import PrenotazioniMenu from "./PrenotazioniMenu.vue";
import TeleconsultoMenu from "./TeleconsultoMenu.vue";
import AttivitaMenu from "./AttivitaMenu.vue";
export default {
  name: "TopHeader",
  components: {
    // HelloWorld,
    PrenotazioniMenu,
    AttivitaMenu,
    TeleconsultoMenu,
  },
  computed: {
    homePath() {
      if (this.user) {
        return "/" + this.user.i_am + "/home";
      }
    },
    welcomeName() {
      if (this.user && this.user.anagrafica) {
        return this.user.anagrafica.name;
      }
      return this.user.username;
    },
    canSeeImpostazioni() {
      if (this.user.type === 1) {
        return (
          this.user.permessi.includes("visualizzare anagrafica azienda") ||
          this.user.permessi.includes("gestire impostazioni dei servizi") ||
          this.user.permessi.includes("gestire operatori")
        );
      }
      return true;
    },
    canSeeProfilo() {
      if (this.user.type === 1 && !Array.isArray(this.user.company)) {
        //Rimuovere se si vuole dare la possibilità di modificare la psw per i farmacisti ff
        return this.user.email !== this.user.company.email;
      }
      return true;
    },
    ...mapState("utente", ["status", "user"]),
  },
  data() {
    return {
      cssLogo: {
        backgroundImage:
          "url('" +
          process.env.VUE_APP_BE +
          "storage/logos/" +
          process.env.VUE_APP_SCOPE +
          "/logo.png')",
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        // backgroundImage: `url(${require('@/assets/path/to/your/img.jpg')})`
      },
    };
  },
  methods: {
    ...mapActions("utente", ["logout"]),
    onSubmit() {
      // console.log("submit");
      this.logout();
      // .then((res) => {
      //   // console.log("after login ok", res);
      // })
      // .catch((error) => {
      //   // console.log("after login error", error);
      // });
    },
    checkPath() {
      if (this.user) {
        return "/" + this.user.i_am + "/home";
      }
    },
  },
  // created() {
  //   console.log("ROUTE", this.$route);
  //   // console.log("env", process.env.VUE_APP_BE);
  // },
};
</script>
<style>
.dropdown#nav_dd > .btn:focus {
  outline: none;
  box-shadow: none;
}
</style>