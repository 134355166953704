<template>
  <div class="mb-3 puntofarmaCardNew d-flex flex-column h-100">
    <!-- Paragrafo iniziale, con immagine e testo servizio -->
    <p :class="headerParagraphStyles" id="header-paragraph">
      <img
        :src="require(`@/assets/dashboard-operatore/dashboardImpegni.svg`)"
        :alt="`Icona `"
        class="icon"
      />
      <span class="px-4 px-md-3" id="header-text">Promemoria</span>
    </p>

    <section
      id="prenotazioni-container"
      class="d-flex flex-grow-1 general_border_11 m-4 mx-2 position-relative"
      :class="noPrenotazioni ? 'border px-4' : ''"
    >
      <b-row id="prenotazioni-row" class="d-flex mx-4 pt-2 mt-n2 pr-xl-2 w-100">
        <template v-if="!noPrenotazioni || loading">
          <b-skeleton-wrapper :loading="loading" class="col-12 px-2">
            <template #loading>
              <section class="mt-3 mr-lg-3">
                <p class="puntofarmaCard p-4 general_border ml-n2 mr-lg-3">
                  <b-skeleton width="100%" height="50px"></b-skeleton>
                </p>
                <p class="puntofarmaCard p-4 general_border ml-n2 mr-lg-3">
                  <b-skeleton width="100%" height="50px"></b-skeleton>
                </p>
              </section>
            </template>
            <b-col
              v-for="prenotazione in prenotazioni"
              :key="prenotazione.hashid"
              class="prenotazione-col px-0 col-12 col-md-6 col-lg-12 m-0 general_border_11 mb-4"
            >
              <div
                class="d-flex prenotazione-card general_border_11 p-3 mr-0 mr-md-4 mr-lg-0 h-100"
              >
                <img
                  :src="
                    require(`@/assets/dashboard-operatore/${getImageName(
                      prenotazione.tipo_ordine
                    )}.svg`)
                  "
                  class="icon"
                />
                <div class="font-weight-bolder">
                  <p class="text-left">
                    {{ getTitoloCard(prenotazione) }}
                  </p>
                  <p class="mb-0">
                    {{ getData(prenotazione.data_inizio) }}
                  </p>
                </div>
                <b-iconstack
                  @click="
                    $router.push({
                      name: 'appuntamenti',
                      params: { section: 'oggi' }
                    })
                  "
                  font-scale="2"
                  class="point"
                >
                  <b-icon
                    stacked
                    icon="circle-fill"
                    variant="sec-2-lighter"
                  ></b-icon>
                  <b-icon
                    stacked
                    icon="reply-fill"
                    scale="0.8"
                    shift-v="1"
                    shift-h="1"
                    variant="white"
                  ></b-icon>
                  <b-icon
                    stacked
                    icon="circle"
                    variant="sec-2-lighter"
                  ></b-icon>
                </b-iconstack>
              </div>
            </b-col>
          </b-skeleton-wrapper>
        </template>
        <b-col class="p-2 text-left" v-else-if="!loading">
          Nessuna prenotazione per oggi.
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import * as moment from "moment/moment"
import { SERVIZI_VARIANTS } from "@/_utils/constants.js"
export default {
  name: "PromemoriaHomeCittadino",
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    prenotazioni: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      icons: {},
      SERVIZI_VARIANTS: SERVIZI_VARIANTS,
      pClasses:
        "values-card-paragraph py-1 px-4 px-md-3 px-lg-4 general_border",
      headerParagraphStyles:
        "d-flex align-items-center justify-content-md-center justify-content-lg-start my-0 pt-4 pb-2 px-4 px-md-3 px-lg-4 general_border"
    }
  },
  computed: {
    noPrenotazioni() {
      return this.prenotazioni.length === 0
    }
  },

  methods: {
    getData(data) {
      return (
        moment(data).format("D MMM") + " alle " + moment(data).format("HH:mm")
      )
    },
    getTitoloCard(ordine) {
      switch (ordine.tipo_ordine) {
        case "teleconsulto":
          return "Teleconsulto"
        case "prestazione":
          return ordine?.orderable?.prestazione?.name
        case "prodotto":
          return "Ordine"
      }
    },
    getImageName(tipo) {
      switch (tipo) {
        case "teleconsulto":
          return `dashboardTeleconsulto`
        case "prestazione":
          return `dashboardPrestazioni`
        case "prodotto":
          return `dashboardOrdini`
      }
    }
  }
}
</script>
<style lang="scss" scoped>
#prenotazioni-row {
  position: relative;
  overflow-y: scroll;
  max-height: 100%;
}

.prenotazione-card {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #f9f8f8 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px #00000022;
}

.prenotazione-card:hover {
  background: #f9f8f8 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
}

#values-card {
  color: white;
  letter-spacing: 0.5px;
  font-weight: 600;
}

.values-card-paragraph {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
  padding: 0.2rem 0;
}

.values-card-paragraph:hover {
  cursor: pointer;
  transition: all 0.35s ease-in-out;
}

.hover-values-card-prodotti-paragraph:hover {
  background-color: rgba($orange-light, 0.3);
}

.hover-values-card-prestazioni-paragraph:hover {
  background-color: rgba($green-light, 0.3);
}

.hover-values-card-teleconsulti-paragraph:hover {
  background-color: rgba($purple-light, 0.25);
}

#header-text {
  font-size: 1.3rem;
}

@media (max-width: 420px) {
  .icon {
    width: 2.2rem;
    height: 2.2rem;
  }

  #header-text {
    font-size: 1.3rem;
  }
}

@media (max-width: 768px) {
  #prenotazioni-container {
    overflow-y: scroll;
    max-height: 30vh;
  }

  .prenotazione-col {
    min-width: calc(100% - 20px);
    padding: 0px 10px !important;
  }
}

@media (min-width: 421px) and (max-width: 768px) {
  .icon {
    width: 2.5rem;
    height: 2.5rem;
  }

  #header-text {
    font-size: 1.5rem;
  }
}

@media (min-width: 768px) and (max-width: 979px) {
  .icon {
    width: 2.2rem;
    height: 2.2rem;
  }

  #header-text {
    font-size: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 850px) {
  .icon {
    width: 1.8rem;
    height: 1.8rem;
  }
}

@media (min-width: 992px) {
  #prenotazioni-row {
    max-height: 100%;
    width: 100%;
    position: absolute;
  }

  .prenotazione-col {
    min-width: calc(100% - 20px);
    padding: 0px 10px !important;
  }

  .icon {
    width: 2.5rem;
    height: 2.5rem;
  }
}
</style>
