<template>
  <div class="px-md-4">
    <h4 class="text-left pb-1 mb-4">Questionari</h4>

    <div class="d-flex justify-content-between">
      <b-button
        variant="secondary   border-white"
        class="ml-0 mr-auto"
        @click="
          $router.push({
            name: 'screening',
            params: { section: 'questionari', action: 'nuovo' },
          })
        "
      >
        <b-icon icon="plus"></b-icon> nuovo questionario</b-button
      >

      <b-input-group
        size="sm"
        class="mt-2 col-lg-4 col-sm-6 ml-auto mr-0 shadow_3"
      >
        <b-form-input
          class="align-items-center align-content-center"
          v-model="filterSetted.search"
          type="search"
          placeholder="Cerca questionario.."
        >
        </b-form-input>

        <b-input-group-append>
          <span class="mx-2"> <b-icon icon="search"></b-icon></span>
        </b-input-group-append>
      </b-input-group>
    </div>
    <div class="d-flex justify-content-between mt-4 mb-2 align-content-center">
      <div>
        <b-form-group size="sm" class="text-left">
          <b-form-select
            class="
              align-items-center align-content-center
              
              border border-info
              text-info
            "
            name="vedi"
            v-model="filterSetted.take"
            :options="takeOptions"
          >
          </b-form-select>
        </b-form-group>
        <span class="text-info mr-1" v-if="total > 0"
          >{{ fromPage }}-{{ toPage }} di
        </span>
        <span class="text-info"> {{ total }}</span>
      </div>
      <b-form-group size="sm" class="text-left">
        <b-form-select
          name="type"
          class="
            align-items-center align-content-center
            
            border border-info
            text-info
          "
          v-model="filterSetted.activity"
          required
        >
          <b-form-select-option :value="null"
            >tutte le attività</b-form-select-option
          >
          <b-form-select-option
            v-for="(activity, ind) in activities"
            :key="ind"
            :value="activity.name"
            >{{ activity.name }}</b-form-select-option
          >
        </b-form-select>
      </b-form-group>
      <b-form-group size="sm" class="text-left">
        <b-form-select
          name="type"
          class="
            align-items-center align-content-center
            
            border border-info
            text-info
          "
          v-model="filterSetted.position_ref"
          required
        >
          <b-form-select-option :value="null"
            >tutti i servizi</b-form-select-option
          >
          <b-form-select-option :value="1"
            >Prestazioni</b-form-select-option
          >
          <b-form-select-option :value="2">Teleconsulto</b-form-select-option>
          <b-form-select-option :value="3"
            >Screening</b-form-select-option
          ></b-form-select
        >
      </b-form-group>
    </div>

    <div class="mt-3 puntofarmaCard">
      <b-table
        borderless
        hover
        responsive
        :fields="fields"
        :items="surveys"
        :small="$root.isMobile"
      >
        <template #cell(name)="row">
          <span class="text-left text-capitalize">{{ row.item.name }}</span>
        </template>

        <template #cell(description)="row">
          <span class="text-justify">{{ row.item.description }}</span>
        </template>

        <template #cell(attivita)="row">
          <span class="text-justify">{{ row.item.activity.name }}</span>
        </template>

        <template #cell(active)="row">
          <b-form-checkbox
            v-model="row.item.enabled"
            name="check-button"
            :state="row.item.enabled == 1"
            :value="1"
            :unchecked-value="0"
            switch
            @change="changeStatus(row)"
            >{{ row.active }}
          </b-form-checkbox>
        </template>

        <template #cell(tot_filled)="row">
          <span class="text-justify">{{ row.item.tot_filled }}</span>
        </template>
        <template #cell(azioni)="row">
          <b-button
            class="general_border"
            variant="info px-3 mr-1 mb-1"
            size="sm"
            @click="selectThis(row)"
            v-b-tooltip.hover
            title="Visualizza"
            ><b-icon icon="zoom-in"></b-icon
          ></b-button>
          <b-button
            variant="secondary general_border mb-1 mr-1 px-3"
            size="sm"
            @click="
              $router.push({
                name: 'screening',
                params: { 
                  quest: row.item.hashid,
                  section: 'questionari',
                  action: 'report' },
              })
            "
            v-b-tooltip.hover
            title="Report"
            ><b-icon icon="collection-fill"></b-icon
          ></b-button>
          <b-button
            v-if="!row.item.created_by"
            variant="secondary general_border mb-1 px-3"
            size="sm"
            @click="
              $router.push({
                name: 'screening',
                params: {
                  quest: row.item.hashid,
                  section: 'questionario',
                  action: 'modifica',
                },
              })
            "
            v-b-tooltip.hover
            title="Modifica"
            ><b-icon icon="pencil-square"></b-icon
          ></b-button>
          <!-- <router-link
            class="
              info_gradient
              btn btn-info btn-sm
              px-3
              
              
            "
            :to="{ name: 'questionari', params: { quest: row.item.hashid } }"
          >
            Dettaglio</router-link
          > -->
        </template>
      </b-table>

      <p v-if="surveys && surveys.length == 0">Nessun questionario qui</p>

      <b-modal ref="modal-showsurvey" :title="modalTitle" size="xl" hide-footer>
        <SurveyShower
          v-if="selectedRow && selectedRow.item"
          :survey="selectedRow.item"
          class="px-3"
        />
      </b-modal>
    </div>
    <b-pagination
      v-model="filterSetted.page"
      :total-rows="total"
      :per-page="10"
      align="center"
      :size="$root.isMobile ? 'sm' : 'lg'"
    ></b-pagination>
  </div>
</template>
<script>
import { supportService, adminService } from "@/_services";
import { mapState, mapActions } from "vuex";
import SurveyShower from "@/components/admin/attivita/SurveyShower.vue";
export default {
  name: "AdminQuestionari",
  components: { SurveyShower },
  data() {
    return {
      filterSetted: {
        search: null,
        position_ref: null,
        page: 1,
        take: 10,
        activity: null,
      },
      surveys: null,
      fields: [
        { key: "name", label: "Nome" },
        { key: "description", label: "Descrizione" },
        { key: "attivita", label: "Attività" },
        { key: "active", label: "Stato" },
        { key: "tot_filled", label: "Somministrati" },
        { key: "azioni", label: "" },
      ],
      takeOptions: [10, 25, 50, 100],
      activities: null,
      total: 0,
      fromPage: 0,
      toPage: 0,
      selectedRow: null,
      modalTitle: null,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  watch: {
    filterSetted: {
      handler(prev, post) {
        // console.log("changed prev", prev.status, post);
        // console.log("changed post", post.status);
        this.fetchData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      var self = this;
      supportService
        .getSurveys(this.filterSetted)
        .then(function (response) {
          self.surveys = response.data.data.data;
          self.filterSetted.page = response.data.data.current_page;
          self.total = response.data.data.total;
          self.fromPage = response.data.data.from;
          self.toPage = response.data.data.to;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le attività");
          console.log(err);
        });
    },
    fetchAttivita() {
      var self = this;
      supportService
        .getActivities(this.filterSetted)
        .then(function (res) {
          self.activities = res.data.data;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le attività");
          console.log(err);
        });
    },
    selectThis(row) {
      this.selectedRow = row;
      this.modalTitle = row.item.name;
      this.$refs["modal-showsurvey"].show();
    },
    changeStatus(row) {
      var formData = {
        hash: row.item.hashid,
        form: {
          enabled: row.item.enabled,
        },
      };
      var self = this;
      adminService
        .updateSurveyStatus(formData)
        .then(function (res) {
          window.scrollTo(0, 0);
          self.successAlert("Stato questionario aggiornato con successo!");
          // self.fetchData();
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert(
            "Non è stato possibile aggiornare lo stato del questionario"
          );
          self.fetchData();
        });
    },
  },
  created() {
    this.fetchData();
    this.fetchAttivita();
  },
};
</script>
