<template>
  <div class="home h-100 px-md-4">
    <b-container class="mb-3 h-100 px-0" fluid v-if="user">
      <h4 class="text-prim-grad-1 text-left pb-2 mb-4">Dashboard di Amministrazione</h4>

      <DatiApp />
    </b-container>
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
    <!-- {{ user }} -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

import { mapState, mapActions } from "vuex";
import DatiApp from "@/components/admin/DatiApp.vue";

export default {
  name: "AdminHome",
  components: {
    DatiApp,
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
};
</script>
