<template>
  <div v-if="datatoshow" class="py-2 shadow_2 h-100 p-xl-4">
    <h5 class="pl-4 text-left text-capitalize">{{ title }}</h5>
    <b-row :cols="Object.keys(datatoshow).length > 6 ? '2' : '1'" class="mt-auto px-2" align-v="stretch">
      <!-- <b-row cols="1" class="mt-auto px-2" align-v="stretch"> -->
      <b-col class="h-100 my-2" v-for="(serv, ind) in datatoshow" :key="ind">
        <!-- <div class="h-100"> -->
        <p class="text-left fs-5 my-auto">{{ ind }}</p>
        <h5 v-if="serv.tot >0" class="text-left my-auto">
          {{ Math.round((serv.tot / total) * 100 * 100) / 100 }}%
        </h5>
        <h5 v-else class="text-left my-auto">
          0%
        </h5>
        <b-progress variant="dark-purple" :value="Math.round((serv.tot / total) * 100 * 100) / 100" :max="max"
          height="0.3rem" class="my-auto"></b-progress>
        <!-- </div> -->
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { Chart, registerables } from "chart.js";
import "chartjs-adapter-moment"; // or another adapter to avoid moment
Chart.register(...registerables);
export default {
  name: "ProgressChart",
  props: ["idappend", "datatoshow", "title"],
  data() {
    return {
      max: 100,
      total: null,
    };
  },
  mounted() {
    // console.log(this.datatoshow);
    let total = 0;
    Object.keys(this.datatoshow).map(
      (el) => (total += this.datatoshow[el].tot)
    );
    this.total = total;

    // console.log("progress total", total);
  },
};
</script>