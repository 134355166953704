<template>
  <div>
    <b-row :cols="$root.isMobile ? 1 : 3" class="flex-wrap" v-if="servizi">
      <b-col v-for="(service, ind) in servizi" :key="ind">
        <div class="bg-white shadow_7 general_border my-3 pb-3">
          <div class="col-6 mx-auto min__height">
            <p class="h1 mb-2 pt-4 pb-1" v-if="service.logo">
              <img :src="service.logo" :alt="service.name + '_logo'" />
            </p>
            <p class="h1 mt-2 py-4" v-else>
              <b-icon font-scale="1.5" icon="receipt-cutoff"></b-icon>
            </p>
          </div>
          <p class="mb-0 mt-1  text-secondary text-capitalize">
            {{ service.name }}
          </p>

          <div class="col-10 mx-auto">
            <p class="fs-6 text-medium-grey">{{ service.description }}</p>
          </div>
          <div class="col-12 pb-4">
            <router-link class="
                
                btn btn-primary
                py-1
                
                text-white
                
              " :to="positions[service.position_ref]">
              <span class="fs-5 text-white">Accedi</span>
            </router-link>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { adminService } from "@/_services";
export default {
  name: "GestioneServizi",
  data() {
    return {
      servizi: null,
      positions: {
        1: "prenotazioni",
        2: "teleconsulto",
        3: "attivita",
        4: "pazienti",
        5: "agenda",
      },
    };
  },
  methods: {
    fetchData() {
      var self = this;
      adminService
        .getServices()
        .then(function (response) {
          // console.log(response);
          self.servizi = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>