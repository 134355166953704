<template>
  <div>
    <b-row align-v="stretch">
      <b-col class="px-0 col-12 col-lgx-6">
        <div class="mr-lgx-2 d-flex flex-column h-100">
          <h3 class="mt-2 pt-4 text-left">
            <b-icon
              icon="envelope"
              variant="secondary"
              font-scale="1.2"
              class="mr-1"
            ></b-icon>
            Dati di Accesso
          </h3>
          <section
            class="bg-white general_border puntofarmaCard p-2 p-md-3 p-lg-4 h-100"
          >
            <p
              v-if="user.temporary_password"
              class="text-left mb-1 text-danger"
            >
              Modifica la tua password temporanea
            </p>
            <p v-if="user.expired_password" class="text-left mb-1 text-danger">
              La tua password è scaduta: aggiornala per proseguire.
            </p>
            <div ref="changePasswordDiv" class="col-12 d-flex flex-wrap px-0">
              <!-- <b-form-group
                label="Email"
                label-for="email"
                class="text-left fs-6 mx-auto col-12 col-lg-6"
              >
                <b-form-input
                  class="my-3 "
                  name="email"
                  type="text"
                  readonly
                  v-model="user.email"
                ></b-form-input>
              </b-form-group> -->
              <b-form-group
                label="Username"
                label-for="email"
                class="text-left fs-6 mx-auto col-12 col-lg-6"
              >
                <b-form-input
                  class="my-3"
                  name="email"
                  type="text"
                  readonly
                  v-model="user.username"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                label="Password"
                label-for="password"
                class="text-left fs-6 mx-auto col-12 col-lg-6"
                :class="
                  user.temporary_password || user.expired_password
                    ? 'text-danger'
                    : ''
                "
              >
                <b-button
                  type="submit"
                  variant="outline-primary mt-2 "
                  @click="$refs['modal-pswchange'].show()"
                  >Cambia Password</b-button
                >
                <!-- <b-form-input
            class="my-3 "
            name="password"
            type="password"
            placeholder="password..."
            v-model="password"
            readonly
          ></b-form-input> -->
              </b-form-group>
            </div>
          </section>
        </div>
      </b-col>
      <b-col class="px-0 col-12 col-lgx-6">
        <div class="ml-lgx-2">
          <h3 class="mt-2 pt-4 text-left">
            <b-icon
              icon="clipboard-check"
              variant="secondary"
              font-scale="1.2"
              class="mr-1"
            ></b-icon>
            Termini e Condizioni
          </h3>
          <section
            class="bg-white general_border puntofarmaCard p-2 p-md-3 p-lg-4 mt-2"
          >
            <div
              v-if="user.type === 1"
              class="col-12 d-flex flex-wrap general_border"
            >
              <b-col>
                <p class="text-left">
                  <b-icon icon="check-circle-fill" variant="success"></b-icon>
                  Hai accettato le
                  <a
                    target="_blank"
                    class="text-underlined point"
                    :href="infoLinks.operatore"
                  >
                    <u>condizioni generali di contratto</u></a
                  >
                </p>
              </b-col>
              <b-col>
                <p class="text-left">
                  <b-icon icon="check-circle-fill" variant="success"></b-icon>
                  Hai accettato espressamente, ai sensi dell’articolo 1341 e ss
                  c.c. , le previsioni di cui agli articoli 8, 9, 10, 17, 18 del
                  <a
                    target="_blank"
                    class="text-underlined point"
                    :href="infoLinks.operatore"
                  >
                    <u> Contratto</u></a
                  >
                </p>
              </b-col>
            </div>
            <div
              v-else-if="user.type === 0"
              class="col-12 d-flex flex-wrap general_border py-4"
            >
              <b-col>
                <p class="text-left">
                  <b-icon icon="check-circle-fill" variant="success"></b-icon>
                  Hai accettato
                  <a
                    target="_blank"
                    class="text-underlined point"
                    :href="infoLinks.paziente"
                  >
                    <u>Termini e Condizioni d'uso del servizio</u></a
                  >
                </p>
              </b-col>
              <b-col>
                <p class="text-left">
                  <b-icon icon="check-circle-fill" variant="success"></b-icon>
                  Hai accettato espressamente, ai sensi dell’articolo 1341 e ss
                  c.c. , le previsioni di cui agli articoli 2, 7, 8, 10, 12 dei
                  presenti del
                  <a
                    target="_blank"
                    class="text-underlined point"
                    :href="infoLinks.paziente"
                  >
                    <u>Termini e Condizioni d'uso</u></a
                  >
                </p>
              </b-col>
            </div>
          </section>
        </div>
      </b-col>
    </b-row>
    <b-modal
      ref="modal-pswchange"
      title="Modifica la tua  password"
      size="lg"
      hide-footer
      centered
    >
      <b-form @submit.prevent="submitPswChange">
        <div class="col-12 d-flex flex-wrap">
          <b-form-group
            label="Password Attuale*"
            label-for="oldpsw"
            class="text-left fs-6 mx-auto col-12"
          >
            <b-form-input
              v-model="form.old_password"
              placeholder="password attuale..."
              class="my-3"
              name="oldpsw"
              type="password"
              required
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-12 d-flex flex-wrap">
          <b-form-group
            label="Password*"
            label-for="password"
            class="text-left fs-6 mx-auto col-12"
          >
            <b-form-input
              class="my-3"
              name="password"
              type="password"
              v-model="form.password"
              placeholder="password..."
              :state="form.password == null ? null : validatePassword"
              required
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            label="Conferma password*"
            label-for="password"
            class="text-left fs-6 mx-auto"
            :class="$root.isMobile ? '' : 'col'"
          >
            <b-form-input
              class="my-3"
              name="password_confirmation"
              type="password"
              v-model="form.password_confirmation"
              placeholder="conferma password..."
              :state="form.password == null ? null : validateConfirm"
              required
            ></b-form-input>
          </b-form-group>
          <p class="text-left text-medium-grey fs-6">
            La password non può includere il nome né il cognome e deve contenere
            almeno 8 caratteri, di cui almeno un numero, una maiuscola, una
            minuscola ed un
            <span
              v-b-tooltip
              title="Devi usare almeno un caratterere tra ! @ # $ % ^ & * ) ( + = . _ -"
              >carattere speciale</span
            >. La nuova password deve essere diversa da quella attuale.
          </p>
        </div>
        <div
          class="col-12 text-center d-flex justify-content-end my-3"
          :class="$root.isMobile ? 'flex-wrap' : ''"
        >
          <b-button
            type="button"
            class="modal-footer-button mr-3"
            variant="info"
            @click="resetForm"
            >Annulla Operazione</b-button
          >
          <b-button
            type="submit"
            class="modal-footer-button"
            variant="outline-primary"
            :disabled="!enableSecondBtn"
          >
            Conferma Modifica</b-button
          >
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "AccessoEPermessi",
  components: {},
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
    validatePassword() {
      if (this.form.password) {
        if (
          this.form.old_password &&
          this.form.old_password === this.form.password
        ) {
          return false;
        }
        this.has_minimum_lenth = this.form.password.length > 8;
        this.has_number = /\d/.test(this.form.password);
        this.has_lowercase = /[a-z]/.test(this.form.password);
        this.has_uppercase = /[A-Z]/.test(this.form.password);
        this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.form.password);
        // var regName = new RegExp(this.user.anagrafica.name);
        // var regSurname = new RegExp(this.user.anagrafica.surname);
        // this.has_name = !regName.test(this.form.password);
        // this.has_surname = !regSurname.test(this.form.password);
        const regName = new RegExp(this.user.anagrafica.name.toLowerCase());
        const regSurname = new RegExp(
          this.user.anagrafica.surname.toLowerCase()
        );
        this.has_name = !regName.test(this.form.password.toLowerCase());
        this.has_surname = !regSurname.test(this.form.password.toLowerCase());
        return (
          this.has_minimum_lenth &&
          this.has_number &&
          this.has_lowercase &&
          this.has_uppercase &&
          this.has_name &&
          this.has_surname &&
          this.has_special
        );
      }
      return false;
    },
    validateConfirm() {
      if (this.form.password) {
        return this.form.password_confirmation === this.form.password;
      }
      return false;
    },
    enableSecondBtn() {
      if (this.form.old_password !== null) {
        if (this.validatePassword && this.validateConfirm) {
          return true;
        }
        return false;
      }
      return false;
    },
  },
  data() {
    return {
      password: "password...",
      form: {
        old_password: null,
        password: null,
        password_confirmation: null,
      },
      infoLinks: {
        paziente: "/assets/docs/TeC_PT.pdf",
        operatore: "/assets/docs/TeC_OS.pdf",
      },
    };
  },
  methods: {
    ...mapActions("utente", [
      "me",
      "changePassword",
      "setTemporaryPasswordFalse",
      "setExpiredPasswordFalse",
    ]),
    ...mapActions({
      clearAlert: "alert/clear",
      infoAlert: "alert/info",
    }),
    resetForm() {
      this.$refs["modal-pswchange"].hide();
      this.form = {
        old_password: null,
        password: null,
        password_confirmation: null,
      };
    },
    submitPswChange() {
      const self = this;
      this.changePassword(this.form).then(function () {
        window.scrollTo(0, 0);
        self.resetForm();
        self.setTemporaryPasswordFalse();
        self.setExpiredPasswordFalse();
        self.me();
      });
    },
  },
  watch: {},
  created() {
    this.me();
    if (this.$route.query.scope === "temporary") {
      this.$nextTick(function () {
        this.$refs["changePasswordDiv"].scrollIntoView();
        this.infoAlert("Devi ancora modificare la tua password temporanea");
      });
    }
    if (this.$route.query.scope === "expired") {
      this.$nextTick(function () {
        this.$refs["changePasswordDiv"].scrollIntoView();
        this.infoAlert("La tua password è scaduta e deve essere cambiata");
      });
    }
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
input.form-control:read-only {
  border-bottom: 1px solid darkgrey;
  border-radius: 0px !important;
  box-shadow: none !important;
}
</style>
