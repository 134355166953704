<template>
  <b-container fluid class="m-0 p-0">
    <div class="mb-3">
      <b-row class="justify-content-bewteen pb-2" align-v="center">
        <b-col class="align-self-end px-0 col-12 col-md-3">
          <h4 class="text-left py-1 mb-0">Terapie in atto</h4>
        </b-col>
        <b-col class="text-center my-3 my-md-0 text-md-right col-12 col-md-9">
          <b-button v-if="therapies && therapies.active && therapies.active.length > 0" class="text-left mr-4 px-3" @click="prospettoDownload()" variant="outline-primary">
            <b-icon icon="download" class="mr-md-3"></b-icon> <span class="d-none d-md-inline">
              Prospetto Terapie
            </span>
          </b-button>
          <b-button @click="goToNew">
            <b-icon icon="plus"></b-icon> nuova terapia
          </b-button>
        </b-col>
      </b-row>

      <b-row
        cols-xl="3"
        cols-lg="2"
        cols="1"
        align-v="stretch"
        class="col-12 px-0 flex-wrap"
      >
        <b-col
          class="my-2 pl-0"
          v-for="(ter, ind) in therapies.active"
          :key="ind"
        >
          <div class="text-left py-2 puntofarmaCard col-12 h-100 d-flex">
            <!-- :class="ter.suspended ? 'border-warning' : 'border-success'" -->

            <!-- Box a sx contenente immagine e descrizione -->
            <div
              class="m-0 mr-2 d-none d-sm-flex flex-column justify-content-center align-items-center"
              style="flex: 0 0 100px; min-width: 0"
            >
              <b-icon :icon="cycleIcons[ter.tipo]" font-scale="2"></b-icon>
              <span class="text-center text-capitalize fontsize075rem pt-2">{{
                ter.tipo
              }}</span>
            </div>

            <!-- Parte rimanente della card -->
            <div class="m-0 p-0" style="flex: 1">
              <h5 class="mb-0 pt-1 text-uppercase long__txt">
                {{ ter.product_name }}
              </h5>

              <p v-if="ter.pathology" class="mb-0 pt-1 text-capitalize">
                {{ ter.pathology.name }}
              </p>
              <br v-else />
              <p
                class="mb-0 mt-auto d-sm-none pt-1 text-font-grey text-capitalize"
              >
                <span class="mr-auto">{{ ter.tipo }}</span>
              </p>
              <p
                class="mb-0 mt-auto pt-1 d-flex justify-items-between text-font-grey"
              >
                <span v-if="ter.suspended" class="mr-auto text-sec-1"
                  >Sospesa</span
                >
                <span v-else-if="ter.end_at" class="mr-auto text-prim-grad-1"
                  >Fino al {{ formatData(ter.end_at) }}</span
                >
                <span v-else-if="ter.start_at" class="mr-auto text-prim-grad-1"
                  >Dal {{ formatData(ter.start_at) }}</span
                >
                <span v-else class="mr-auto text-white">-</span>
                <span class="ml-auto point">
                  <b-iconstack
                    @click="showDelModal(ter)"
                    v-if="ter.own"
                    font-scale="1.5"
                    class="mr-4"
                    title="elimina"
                  >
                    <!-- <b-icon stacked icon="circle-fill" variant="danger"></b-icon>
                    <b-icon stacked icon="trash" variant="white" font-scale="1.5"></b-icon> -->
                    <b-icon
                      stacked
                      icon="trash"
                      variant="danger"
                      font-scale="1.5"
                    ></b-icon>
                  </b-iconstack>
                  <b-icon
                    @click="selectTherapy(ter)"
                    icon="zoom-in"
                    font-scale="1.5"
                    variant="primary"
                    title="vedi"
                  >
                  </b-icon>
                </span>
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div>
      <h4 class="text-left pb-1">Storico terapie</h4>
      <b-row
        cols-xl="3"
        cols-lg="2"
        cols="1"
        align-v="stretch"
        class="col-12 px-0 flex-wrap"
      >
        <b-col
          class="my-2 pl-0"
          v-for="(ter, ind) in therapies.ended"
          :key="ind"
        >
          <div class="text-left py-2 puntofarmaCard col-12 h-100 d-flex">
            <div
              class="m-0 mr-2 d-none d-sm-flex flex-column justify-content-center align-items-center"
              style="flex: 0 0 100px; min-width: 0"
            >
              <b-icon :icon="cycleIcons[ter.tipo]" font-scale="2"></b-icon>
              <span class="text-center text-capitalize fontsize075rem pt-2">{{
                ter.tipo
              }}</span>
            </div>

            <div class="m-0 p-0" style="flex: 1">
              <h5 class="mb-0 pt-1 text-uppercase long__txt">
                {{ ter.product_name }}
              </h5>
              <h5 v-if="ter.pathology" class="mb-0 pt-1 text-capitalize">
                {{ ter.pathology.name }}
              </h5>
              <br v-else />
              <p
                class="mb-0 mt-auto pt-1 d-sm-none text-font-grey text-capitalize"
              >
                <span class="mr-auto">{{ ter.tipo }}</span>
              </p>
              <p
                class="mb-0 mt-auto pt-1 d-flex justify-items-between text-font-grey"
              >
                <span v-if="ter.suspended" class="mr-auto text-medium-grey"
                  >Sospesa</span
                >
                <span v-else-if="ter.end_at" class="mr-auto"
                  >Terminata il {{ formatData(ter.end_at) }}</span
                >
                <span v-else class="mr-auto text-white">-</span>
                <span class="ml-auto point">
                  <b-iconstack
                    @click="showDelModal(ter)"
                    v-if="ter.own"
                    font-scale="1.5"
                    class="mr-4"
                    title="elimina"
                  >
                    <!-- <b-icon stacked icon="circle-fill" variant="danger"></b-icon>
                    <b-icon stacked icon="trash" variant="white" font-scale="1.5"></b-icon> -->
                    <b-icon
                      stacked
                      icon="trash"
                      variant="danger"
                      font-scale="1.5"
                    ></b-icon>
                  </b-iconstack>
                  <b-icon
                    @click="selectTherapy(ter)"
                    icon="zoom-in"
                    font-scale="1.5"
                    variant="primary"
                    title="vedi"
                  >
                  </b-icon>
                </span>
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
      <p class="text-left" v-if="therapies.ended.length === 0">
        Nessun elemento nello storico
      </p>
    </div>
    <!-- <div class="text-left pb-2 mt-5">
      <h4 class="text-left pb-1 mb-3 ml-3">Piano notifiche</h4>
      <div class="puntofarmaCard p-4">
        <b-button
          variant="outline-prim-grad-1 border border-primary"
          @click="
            $router.push({
              name: 'scheda paziente',
              params: { hash: $route.params.hash },
              query: { from: 'clients' },
            })
          "
        >
          Vai alla pianificazione <b-icon icon="arrow-up-right"></b-icon>
        </b-button>
      </div>
    </div> -->

    <b-modal
      size="xl"
      centered
      id="edit-therapy"
      body-class="pt-0"
      hide-footer
    >
      <div v-if="selectedTherapy">
        <div v-if="editing">
          <EditTherapy
            :therapy="selectedTherapy"
            v-on:cancel-operation="$bvModal.hide('edit-therapy')"
            v-on:save-therapy="$bvModal.hide('edit-therapy')"
          />
        </div>
        <div class="" v-else>
          <RecapTherapy :therapy="selectedTherapy" />
          <div class="d-flex justify-content-between flex-wrap px-3">
            <div>
              <b-button
                v-if="selectedTherapy.own"
                @click="editing = true"
                class="modal-footer-button"
                variant="outline-primary  "
                >Modifica
              </b-button>
            </div>
            <div class="text-center">
              <b-button
                @click="openSuspModal(false)"
                v-if="selectedTherapy.suspended && !selectedTherapy.terminata"
                class="modal-footer-button"
                variant="outline-primary"
                >Riprendi</b-button
              >

              <b-button
                @click="openSuspModal(true)"
                class="modal-footer-button"
                variant="outline-primary"
                v-else-if="!selectedTherapy.terminata"
                >Sospendi</b-button
              >
            </div>
            <div>
              <b-button
                @click="downloadRecap"
                class="modal-footer-button"
                variant="outline-primary"
                >Scarica promemoria
              </b-button>
            </div>
          </div>
          <!-- <b-row class="py-4 justify-items-between col-12">
            <b-col sm="12" md="6" class="m-1" v-if="selectedTherapy.own">
              <b-button @click="editing = true" variant="outline-primary  "
                >Modifica</b-button
              >
            </b-col>
            <b-col
              sm="12"
              md="6"
              v-if="selectedTherapy.suspended && !selectedTherapy.terminata"
              class="m-1"
            >
              <b-button
                @click="openSuspModal(false)"
                variant="sec-1 text-white  "
                >Riprendi</b-button
              >
            </b-col>
            <b-col
              sm="12"
              md="6"
              v-else-if="!selectedTherapy.terminata"
              class="m-1"
            >
              <b-button
                @click="openSuspModal(true)"
                variant="warning text-white  "
                >Sospendi</b-button
              >
            </b-col>
            <b-col sm="12" md="6" class="m-1">
              <b-button @click="downloadRecap" variant="secondary text-white  "
                >Scarica promemoria
              </b-button>
            </b-col>
          </b-row> -->
        </div>
        <h6 class="mb-0 mt-5 ml-0">
          Data di inserimento: {{ formatData(selectedTherapy.created_at) }}
        </h6>
      </div>
    </b-modal>
    <b-modal
      id="susp-therapy"
      size="lg"
      centered
      title="Conferma "
      button-size="sm"
      cancel-title="No, Annulla"
      cancel-variant="info mr-1"
      ok-title="Sì, Conferma"
      ok-variant="outline-primary"
      footer-class="border-0"
      @ok="doSuspend"
      @cancel="$bvModal.hide('edit-therapy')"
    >
      Confermi di voler {{ isSuspending ? "sospendere" : "riprendere" }} la
      terapia?
    </b-modal>
    <b-modal
      id="del-therapy"
      size="lg"
      centered
      title="Conferma eliminazione"
      button-size="sm"
      cancel-title="No, Annulla"
      cancel-variant="info mr-1"
      ok-title="Sì, Conferma"
      ok-variant="outline-danger  "
      footer-class="border-0"
      @ok="doDelete"
      @cancel="$bvModal.hide('edit-therapy')"
    >
      Confermi di voler eliminare la terapia?
    </b-modal>
  </b-container>
</template>
<script>
import { mapState, mapActions } from "vuex"
import { cittadinoService } from "@/_services"
import { supportService } from "../../_services"
import * as moment from "moment/moment"
import RecapTherapy from "@/components/pazienti/RecapTherapy.vue"
import EditTherapy from "@/components/profile/sanitario/EditTherapy.vue"

export default {
  name: "TerapieComponent",
  props: ["subject"],
  components: { RecapTherapy, EditTherapy },
  data() {
    return {
      cycleIcons: {
        periodica: "calendar",
        cronica: "watch",
        occasionale: "lightning"
      },
      therapies: { active: [], ended: [] },
      typesColors: ["ter-4", "ter-1", "ter-2"],
      selectedTherapy: null,
      editing: false,
      isSuspending: false
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["status", "user"])
    // modalTitle: function () {
    //   if (this.selectedTherapy && this.selectedTherapy.pathology) {
    //     return "Terapia per " + this.selectedTherapy.pathology.name
    //   } else if (this.editing && this.selectTherapy) {
    //     return "Terapia per patologia non indicata"
    //   } else return ""
    // }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),

    prospettoDownload() {
      var self = this
      supportService
        .downloadProspettoTerapia(this?.subject?.hashid)
        .then(function (response) {
          let blob = new Blob([response.data])
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download =
            "ProspettoTerapeutico.pdf"
          link.click()
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile scaricare il prospetto.")
        })
    },
    goToNew() {
      var destination = {
        name: "nuova terapia"
      }
      if (this.subject && this.user.type === 1) {
        destination = {
          name: "nuova terapia Paziente",
          query: {
            sub: this.subject.hashid,
            type: this.subject.type
          }
        }
      } else if (this.subject && this.user.type === 0) {
        destination = {
          name: "nuova terapia assistito",
          query: {
            sub: this.subject.hashid,
            type: this.subject.type
          }
        }
      }
      this.$router.push(destination)
    },
    showDelModal(ter) {
      this.selectedTherapy = ter
      this.$bvModal.show("del-therapy")
    },
    selectTherapy(ter) {
      this.selectedTherapy = ter
      this.$bvModal.show("edit-therapy")
    },
    fetchTherapies() {
      if (this.subject) {
        return this.fetchUserTherapies()
      }
      this.fetchOwnTherapies()
    },
    fetchOwnTherapies() {
      var self = this
      cittadinoService
        .getTherapies()
        .then(function (response) {
          self.therapies = response.data.data
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le terapie")
        })
    },
    fetchUserTherapies() {
      var self = this
      var filters = {
        user: this.subject.hashid
      }
      cittadinoService
        .getTherapies(filters)
        .then(function (response) {
          self.therapies = response.data.data
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le terapie")
        })
    },
    openSuspModal(isSuspending) {
      this.$bvModal.show("susp-therapy")
      this.isSuspending = isSuspending
    },
    doSuspend() {
      var self = this
      var form = this.selectedTherapy
      form.suspended = this.isSuspending
      var term = this.isSuspending ? "sospesa" : "ripresa"
      cittadinoService
        .updateTherapy(form)
        .then(function (response) {
          self.$bvModal.hide("edit-therapy")
          self.successAlert("Terapia " + term)
          self.fetchTherapies()
        })
        .catch(function (error) {
          self.$bvModal.hide("edit-therapy")
          self.errorAlert("Non è stato possibile eseguire l'operazione")
        })
    },
    doDelete() {
      var self = this
      var form = { hashid: this.selectedTherapy.hashid }
      cittadinoService
        .deleteTherapy(form)
        .then(function (response) {
          self.$bvModal.hide("edit-therapy")
          self.successAlert("Terapia eliminata!")
          self.fetchTherapies()
        })
        .catch(function (error) {
          self.$bvModal.hide("edit-therapy")
          self.errorAlert("Non è stato possibile eliminare la terapia")
        })
    },
    formatData(data) {
      return moment(data).format("DD/MM/YYYY")
    },
    downloadRecap() {
      var self = this
      cittadinoService
        .therapyRecap(this.selectedTherapy.hashid)
        .then(function (response) {
          self.$bvModal.hide("edit-therapy")
          let blob = new Blob([response.data])
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = "promemoria_terapia.pdf"
          link.click()
        })
        .catch(function (error) {
          self.$bvModal.hide("edit-therapy")
          self.errorAlert("Non è stato possibile scaricare il promemoria")
        })
    }
  },
  created() {
    this.fetchTherapies()
  },
  mounted() {
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      this.selectedTherapy = null
      this.editing = false
    })
  }
}
</script>
