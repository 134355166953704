var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user.permessi.includes('gestire attività e questionari'))?_c('div',{staticClass:"py-3"},[(_vm.loading)?_c('div',{staticClass:"col-12 text-center"},[_c('b-spinner')],1):_c('div',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('router-link',{staticClass:"text-primary no__hover underlined",attrs:{"to":{ name: 'attivita', params: { section: 'questionari' } }}},[_c('b-icon',{attrs:{"icon":"arrow-left-circle-fill","variant":"primary"}}),_vm._v(" Questionari ")],1),(_vm.selectedUser)?_c('router-link',{staticClass:"text-secondary no__hover underlined",attrs:{"to":{
          name: 'scheda paziente',
          params: { hash: _vm.selectedUser.hashid }
        },"target":"_blank"}},[_vm._v(" Scheda Paziente "),_c('b-icon',{attrs:{"icon":"arrow-right-circle-fill","variant":"secondary"}})],1):_vm._e()],1),(_vm.survey && !_vm.complete)?_c('div',{staticClass:"py-3"},[_c('h4',{staticClass:"text-left pb-1"},[_vm._v("Modifica "+_vm._s(_vm.survey.survey_name))]),_c('EditQuestionarioCompilato',{attrs:{"survey":_vm.survey},on:{"filled-form":function($event){return _vm.submitForm($event)}}})],1):(_vm.complete)?_c('div',[_c('h2',{staticClass:"fw-bolder"},[_vm._v("Operazione completata.")]),(_vm.editedSurvey && _vm.editedSurvey.survey.type === 2)?[_vm._m(0),_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('b-button',{attrs:{"variant":"secondary text-white  spacing_1"},on:{"click":function($event){return _vm.$router.push({
                  name: 'attivita',
                  params: {
                    section: 'questionario',
                    action: 'report',
                    quest: _vm.editedSurvey.survey.hashid
                  }
                })}}},[_vm._v("Torna ai report")])],1),_c('b-col',[_c('b-button',{attrs:{"variant":"secondary text-white spacing_1"},on:{"click":function($event){return _vm.downloadPatientVersion()}}},[_c('b-icon',{attrs:{"icon":"download"}}),_vm._v(" scarica pdf per il paziente ")],1)],1)],1)]:_vm._e()],2):_vm._e()])]):_c('div',[_c('h4',{staticClass:"fw-bolder"},[_vm._v("Operazione non consentita.")])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h6',{staticClass:"text-justify py-3"},[_vm._v(" Cliccando sul tasto \"scarica pdf per il paziente\" puoi scaricare il questionario appena compilato in versione stampabile, comprensivo di informativa (laddove presente) e di spazio dedicato per la firma. Potrai comunque scaricare il pdf per il paziente e il questionario in altri formati accendendo alla reportistica dedicata al questionario. "),_c('br'),_vm._v(" Per pianificare un follow up, vai alla scheda del paziente e clicca sul tasto \"+\" che trovi nella sezione \"prenotazioni\". ")])}]

export { render, staticRenderFns }