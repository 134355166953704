<template>
  <b-container
    ref="navmain"
    fluid
    class="bg-white shadow_2 h-100 px-0 text-font-grey d-flex flex-column"
    :class="
      menuVisible && wlistener < 768 ? 'mobile-menu-visible' : 'mobile-menu'
    "
  >
    <transition-group
      name="slideside"
      mode="out-in"
      class="flex-grow-1 d-flex flex-column"
    >
      <!-- Navigation Desktop -->
      <template v-if="wlistener >= 768">
        <b-row align-v="center" class="px-0" key="logo-row">
          <b-col class="px-1 py-2 mt-2" cols="6" md="12">
            <router-link :to="{ name: 'Home' }" class="">
              <img
                v-if="menuIsCompact"
                src="@/assets/puntofarma-dot-logo.svg"
                alt="appLogo"
                class="px-2"
                style="max-width: 75px"
                key="icon_logo"
              />
              <img
                v-else
                src="@/assets/puntofarma-logo.svg"
                alt="appLogo"
                key="full_logo"
              />
            </router-link>
          </b-col>
        </b-row>
        <b-row cols="1" class="px-0" key="menu-row" v-if="menuVisible">
          <b-col
            class="px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-0 text-left"
            key="desk_ham"
          >
            <p class="pb_15 nav__item bg-white">
              <span
                class="d-flex align-items-center pl-1 py-1 general_border point"
                @click="handleMenuSize"
              >
                <b-icon font-scale="1.5" icon="justify"></b-icon>
                <span class="mb-0 ml-2"></span>
              </span>
            </p>
          </b-col>
          <!-- <b-col class="px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-0 text-left">
          <p class="pb_15 nav__item bg-white">
            <router-link
              class="d-flex align-items-center pl-1 py-1 general_border"
              :class="
                this.$route.name === 'CompanyHome'
                  ? 'router-link-exact-active'
                  : ''
              "
              :to="{ name: 'Home' }"
            >
              <b-icon font-scale="1.3" icon="house-door"></b-icon>
              <span class="mb-0 ml-2">Dashboard</span>
            </router-link>
          </p>
        </b-col> -->
          <b-col class="px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-0 text-left">
            <p class="text-font-light-grey">
              <span :class="menuIsCompact ? 'text-white' : ''">APP</span>
            </p>
            <!-- <p>
            <router-link
              class="my-3 mx-auto text-font-grey"
              :to="positions[clientiSet.position_ref]"
            >
              <b-icon font-scale="1.3" :icon="clientiSet.logo"></b-icon>
              <span class="mb-0 ml-2">{{ clientiSet.name }}</span>
            </router-link>
          </p> -->
            <p class="pb_15 nav__item bg-white">
              <router-link
                class="d-flex align-items-center pl-1 py-1 general_border"
                :class="
                  this.$route.name === 'CompanyHome'
                    ? 'router-link-exact-active'
                    : ''
                "
                :to="{ name: 'Home' }"
              >
                <b-icon font-scale="1.3" icon="house-door"></b-icon>
                <span class="mb-0 ml-2">Homepage</span>
              </router-link>
            </p>
            <p class="pb_15 nav__item bg-white">
              <router-link
                class="d-flex align-items-center pl-1 py-1 general_border"
                :to="{ name: agendaSet.routeName }"
              >
                <b-icon font-scale="1.3" :icon="agendaSet.logo"></b-icon>
                <span class="mb-0 ml-2">{{ agendaSet.name }}</span>
              </router-link>
            </p>
            <p class="pb_15 nav__notifications nav__item">
              <NotificationCenter />
              <!-- <span class="mb-0 ml-2">Notifiche</span> -->
            </p>
          </b-col>
          <b-col class="px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-0 text-left">
            <p class="text-font-light-grey">
              <span :class="menuIsCompact ? 'text-white' : ''">SERVIZI</span>
            </p>
            <p
              class="pb_15 nav__item"
              :class="service.submenu ? 'bg-extra-light' : 'bg-white'"
              v-for="(service, ind) in activeServices"
              :key="ind"
            >
              <template v-if="service.submenu">
                <span
                  :key="'sub-m-' + service.routeName"
                  class="d-flex align-items-center pl-1 py-1 general_border point"
                  :class="
                    $route.name === service.routeName
                      ? 'router-link-exact-active'
                      : ''
                  "
                  @click="service.submenuVisible = !service.submenuVisible"
                >
                  <b-icon font-scale="1.3" :icon="service.logo"></b-icon>
                  <span class="mb-0 ml-2 text-capitalize">{{
                    service.name
                  }}</span>
                  <b-icon
                    font-scale="1"
                    :icon="
                      service.submenuVisible ? 'chevron-down' : 'chevron-up'
                    "
                    class="mr-1 ml-auto"
                  ></b-icon>
                </span>
                <b-collapse
                  class="mt-1"
                  :id="service.name + '-sub'"
                  v-model="service.submenuVisible"
                >
                  <p
                    v-for="rt in service.subroutes"
                    :key="'sub-' + rt.name"
                    class="mb-1"
                  >
                    <router-link
                      class="d-flex align-items-center pl-1 py-1 general_border align-items-center"
                      :to="{
                        name: service.routeName,
                        params: { section: rt.path }
                      }"
                      exact-active-class="secondary_bg_gradient shadow_7 text-white submenu__active__class"
                    >
                      <b-iconstack font-scale="1.3">
                        <b-icon
                          stacked
                          icon="circle"
                          variant="font-grey"
                          scale="0.55"
                        ></b-icon>
                        <b-icon
                          stacked
                          icon="circle"
                          variant="extra-light"
                        ></b-icon>
                      </b-iconstack>
                      <span class="mb-0 ml-2 text-capitalize">{{
                        rt.name
                      }}</span>
                    </router-link>
                  </p>
                </b-collapse>
              </template>
              <template v-else>
                <router-link
                  class="d-flex align-items-center pl-1 py-1 general_border"
                  :to="{
                    name: service.routeName
                  }"
                >
                  <b-icon font-scale="1.3" :icon="service.logo"></b-icon>
                  <span class="mb-0 ml-2 text-capitalize">{{
                    service.name
                  }}</span>
                </router-link>
              </template>
            </p>
          </b-col>
          <b-col class="px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-0 text-left">
            <p class="text-font-light-grey">
              <span :class="menuIsCompact ? 'text-white' : ''">PAGINE</span>
            </p>
            <p class="pb_15 nav__item bg-white">
              <router-link
                class="d-flex align-items-center pl-1 py-1 general_border"
                :to="{ name: 'profilo cittadino' }"
              >
                <b-avatar
                  v-if="user && user.profile_pic"
                  variant="white"
                  :src="user.profile_pic"
                  size="sm"
                  rounded
                >
                </b-avatar>
                <b-icon
                  v-else
                  font-scale="1.3"
                  :icon="
                    this.$route.name === 'profilo cittadino'
                      ? 'person-fill'
                      : 'person'
                  "
                ></b-icon>

                <span class="mb-0 ml-2 text-capitalize">{{ welcomeName }}</span>
              </router-link>
            </p>
            <p class="pb_15 nav__item bg-white">
              <router-link
                class="d-flex align-items-center pl-1 py-1 general_border"
                :to="{ name: impostazioniSet.routeName }"
              >
                <b-icon font-scale="1.3" :icon="impostazioniSet.logo"></b-icon>
                <span class="mb-0 ml-2 text-capitalize">{{
                  impostazioniSet.name
                }}</span>
              </router-link>
            </p>
            <p class="pb_15 nav__item bg-white">
              <router-link
                class="d-flex align-items-center pl-1 py-1 general_border"
                :to="{ name: 'guida cittadino' }"
              >
                <b-icon
                  font-scale="1.3"
                  :icon="
                    this.$route.name === 'guida'
                      ? 'info-circle-fill'
                      : 'info-circle'
                  "
                ></b-icon>
                <span class="mb-0 ml-2">Guida</span>
              </router-link>
            </p>
            <SendHelpRequest />
          </b-col>
          <b-col class="px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-0 text-left">
            <p class="text-font-light-grey"></p>

            <p class="pb_15 nav__item bg-white">
              <span
                class="d-flex align-items-center pl-1 py-1 general_border point"
                @click.prevent="$bvModal.show('logout-confirm')"
              >
                <b-icon font-scale="1.3" icon="door-open"></b-icon>
                <span class="mb-0 ml-2 text-capitalize">Esci</span>
              </span>
            </p>
          </b-col>
        </b-row>
      </template>
      <!-- Navigation Mobile -->
      <template v-else>
        <b-row
          align-v="center"
          class="px-0 shadow_2 py-1 general_border w-100"
          key="logo-row"
        >
          <template v-if="menuVisible">
            <b-col
              key="mobile_ham"
              class="text-left"
              cols="2"
              @click="menuVisible = !menuVisible"
            >
              <b-icon
                font-scale="3"
                icon="arrow-left-circle"
                variant="primary"
              ></b-icon>
            </b-col>
            <b-col cols="8" class="px-1 py-2 my-1 text-center" offset="-2">
              <router-link :to="{ name: 'Home' }" class="pl-4">
                <img
                  v-if="menuIsCompact && wlistener.width < 768"
                  src="@/assets/puntofarma-dot-logo.svg"
                  alt="appLogo"
                  class="px-lg-2"
                  style="max-width: 75px"
                  key="icon_logo"
                />
                <img
                  v-else
                  src="@/assets/puntofarma-logo.svg"
                  alt="appLogo"
                  height="43px"
                  key="full_logo"
                />
              </router-link>
            </b-col>
          </template>
          <template v-else>
            <b-col cols="10" class="px-1 py-2 my-1 text-left">
              <router-link :to="{ name: 'Home' }" class="pl-4">
                <img
                  v-if="menuIsCompact && wlistener.width < 768"
                  src="@/assets/puntofarma-dot-logo.svg"
                  alt="appLogo"
                  class="px-lg-2"
                  style="max-width: 75px"
                  height="43px"
                  key="icon_logo"
                />
                <img
                  v-else
                  src="@/assets/puntofarma-logo.svg"
                  alt="appLogo"
                  height="43px"
                  key="full_logo"
                />
              </router-link>
            </b-col>
            <b-col
              key="mobile_ham"
              v-if="wlistener < 768"
              class="text-right"
              cols="2"
              @click="menuVisible = !menuVisible"
            >
              <b-icon
                font-scale="2.8"
                icon="justify"
                variant="secondary"
              ></b-icon>
            </b-col>
          </template>
        </b-row>
        <div
          class="d-flex align-items-center flex-column flex-grow-1 justify-content-around px-0"
          key="menu-row"
          v-if="menuVisible"
        >
          <div>
            <section
              class="general_border shadow_2 general_hover nav__item"
              :class="
                $route.path === '/cittadino/home'
                  ? 'secondary_bg_gradient text-white'
                  : 'bg-info'
              "
            >
              <router-link
                class="d-flex align-items-center pl-1 py-1 general_border mobile-nav-tab"
                :class="$route.path === '/cittadino/home' ? 'text-white' : ''"
                :to="{ name: 'Home' }"
              >
                <b-icon font-scale="1.3" icon="house-door"></b-icon>
                <span class="mb-0 ml-2">Homepage</span>
              </router-link>
            </section>
            <section
              class="general_border shadow_2 general_hover nav__item"
              :class="
                $route.name === agendaSet.routeName
                  ? 'secondary_bg_gradient text-white'
                  : 'bg-info'
              "
            >
              <router-link
                class="d-flex align-items-center pl-1 py-1 general_border"
                :class="{
                  'text-white secondary_bg_gradient mobile-nav-tab':
                    $route.name === agendaSet.routeName
                }"
                :to="{ name: agendaSet.routeName }"
              >
                <b-icon font-scale="1.3" :icon="agendaSet.logo"></b-icon>
                <span class="mb-0 ml-2">{{ agendaSet.name }}</span>
              </router-link>
            </section>
            <p class="general_border shadow_2 general_hover nav__item bg-info">
              <NotificationCenter />
              <!-- <span class="mb-0 ml-2">Notifiche</span> -->
            </p>
          </div>

          <section class="px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-3 text-left">
            <div
              class="general_border shadow_2 general_hover nav__item"
              :class="
                $route.name === service.routeName
                  ? 'secondary_bg_gradient text-white'
                  : 'bg-info'
              "
              v-for="(service, ind) in activeServices"
              :key="ind"
            >
              <router-link
                class="d-flex align-items-center pl-1 py-1 general_border mobile-nav-tab"
                :class="{
                  'text-white secondary_bg_gradient':
                    $route.name === service.routeName
                }"
                :to="{
                  name: service.routeName,
                  params: { section: service.subroutes[0].path }
                }"
              >
                <b-icon font-scale="1.3" :icon="service.logo"></b-icon>
                <span class="mb-0 ml-2 text-capitalize">{{
                  service.name
                }}</span>
              </router-link>
            </div>
          </section>
          <section class="px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-3 text-left">
            <div
              class="general_border shadow_2 general_hover nav__item"
              :class="
                $route.name === 'profilo cittadino'
                  ? 'secondary_bg_gradient text-white'
                  : 'bg-info'
              "
            >
              <router-link
                class="d-flex align-items-center pl-1 py-1 general_border"
                :class="{
                  'text-white secondary_bg_gradient mobile-nav-tab':
                    $route.name === 'profilo cittadino'
                }"
                :to="{ name: 'profilo cittadino' }"
              >
                <b-avatar
                  v-if="user && user.profile_pic"
                  variant="white"
                  :src="user.profile_pic"
                  size="sm"
                  rounded
                >
                </b-avatar>
                <b-icon
                  v-else
                  font-scale="1.3"
                  :icon="
                    this.$route.name === 'profilo cittadino'
                      ? 'person-fill'
                      : 'person'
                  "
                ></b-icon>

                <span class="mb-0 ml-2 text-capitalize">{{ welcomeName }}</span>
              </router-link>
            </div>
            <div
              class="general_border shadow_2 general_hover nav__item"
              :class="
                $route.name === impostazioniSet.routeName
                  ? 'secondary_bg_gradient text-white'
                  : 'bg-info'
              "
            >
              <router-link
                class="d-flex align-items-center pl-1 py-1 general_border"
                :class="{
                  'text-white secondary_bg_gradient mobile-nav-tab':
                    $route.name === impostazioniSet.routeName
                }"
                :to="{ name: impostazioniSet.routeName }"
              >
                <b-icon font-scale="1.3" :icon="impostazioniSet.logo"></b-icon>
                <span class="mb-0 ml-2 text-capitalize">{{
                  impostazioniSet.name
                }}</span>
              </router-link>
            </div>
            <div
              class="general_border shadow_2 general_hover nav__item"
              :class="
                $route.name === 'guida cittadino'
                  ? 'secondary_bg_gradient text-white'
                  : 'bg-info'
              "
            >
              <router-link
                class="d-flex align-items-center pl-1 py-1 general_border mobile-nav-tab"
                :class="{
                  'text-white secondary_bg_gradient':
                    $route.name === 'guida cittadino'
                }"
                :to="{ name: 'guida cittadino' }"
              >
                <b-icon
                  font-scale="1.3"
                  :icon="
                    this.$route.name === 'guida'
                      ? 'info-circle-fill'
                      : 'info-circle'
                  "
                ></b-icon>
                <span class="mb-0 ml-2">Guida</span>
              </router-link>
            </div>
          </section>
          <section class="px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-0 text-left">
            <div
              class="general_border shadow_2 general_hover nav__item bg-info"
            >
              <span
                class="d-flex align-items-center pl-1 py-1 general_border point"
                @click.prevent="
                  menuVisible = false
                  $bvModal.show('logout-confirm')
                "
              >
                <b-icon font-scale="1.3" icon="door-open"></b-icon>
                <span class="mb-0 ml-2 text-capitalize">Esci</span>
              </span>
            </div>
          </section>
        </div>
      </template>
    </transition-group>
    <b-modal
      id="logout-confirm"
      centered
      title="Confermi di voler uscire dall'area privata?"
      ok-title="Sì, esci"
      ok-variant="primary"
      cancel-title="No, annulla"
      cancel-variant="outline-primary"
      footer-border-variant="white"
      @ok="onSubmit"
      @cancel="$bvModal.hide('logout-confirm')"
    >
      <!-- <h3>Confermi di voler uscire dall'area privata?</h3> -->
    </b-modal>
  </b-container>
</template>

<script>
import { mapState, mapActions } from "vuex"
import NotificationCenter from "@/components/layout/NotificationCenter.vue"
import SendHelpRequest from "@/components/utilities/SendHelpRequest.vue"

export default {
  name: "NavigationCittadino",
  props: ["wlistener"],
  components: { NotificationCenter, SendHelpRequest },
  watch: {
    $route(to, from) {
      if (this.wlistener <= 768) {
        this.menuVisible = false
      }
    },
    wlistener: function (newVal, oldVal) {
      this.menuIsCompact = this.$refs.navmain.clientWidth <= 80
      if (newVal < 768 && oldVal >= 768) {
        this.menuIsCompact = false
        this.menuVisible = false
      }
      if (newVal > 768 && oldVal <= 768) {
        this.menuIsCompact = false
        this.menuVisible = true
      }
    }
  },
  computed: {
    welcomeName() {
      if (this.user && this.user.anagrafica) {
        // return (
        //   this.user.anagrafica.name.toLowerCase() +
        //   " " +
        //   this.user.anagrafica.surname.toLowerCase()
        // );
        return this.user.anagrafica.name.toUpperCase()
      }
      return "Profilo"
    },
    ...mapState("utente", ["status", "user"])
  },
  data() {
    return {
      avatarPath: null,
      menuVisible: true,
      showCompanyAlert: null,
      activeServices: [
        {
          name: "Prenotazioni",
          logo: "layout-text-sidebar-reverse",
          disabled: false,
          position_ref: 2,
          routeName: "appuntamenti",
          scope: undefined,
          submenu: true,
          submenuVisible: false,
          subroutes: [
            {
              name: "nuova",
              path: "nuovo"
            },
            {
              name: "oggi",
              path: "oggi"
            },
            {
              name: "Recenti",
              path: "recenti"
            },
            {
              name: "storico",
              path: "storico"
            }

            // {
            //   name: "Oggi",
            //   path: "videocall",
            // },
          ]
        },
        {
          name: "Profilo Sanitario",
          logo: "journal-medical",
          disabled: false,
          position_ref: 4,
          routeName: "profilo sanitario",
          scope: undefined,
          submenu: true,
          submenuVisible: false,
          subroutes: [
            {
              name: "Rilevazioni",
              path: "rilevazioni"
            },
            {
              name: "Patologie",
              path: "patologie"
            },
            {
              name: "Terapie",
              path: "terapie"
            },
            {
              name: "Note",
              path: "note"
            },
            {
              name: "Logs",
              path: "logs"
            }
          ]
        },
        {
          name: "familiari",
          logo: "people",
          disabled: false,
          position_ref: 3,
          routeName: "familiari",
          scope: undefined,
          submenu: true,
          submenuVisible: false,
          subroutes: [
            {
              name: "Tutti",
              path: "main"
            },
            {
              name: "Nuovo",
              path: "nuovo"
            }
          ]
        },
        {
          name: "Circolo di Cura",
          logo: "bookmark-heart",
          disabled: false,
          position_ref: 1,
          routeName: "circolo di cura",
          scope: undefined,
          submenu: false,
          submenuVisible: false,
          subroutes: [
            {
              name: "Gestione",
              path: "prenotazioni"
            },
            {
              name: "Strumenti",
              path: "strumenti"
            },
            {
              name: "Report",
              path: "report"
            }
          ]
        }
      ],
      agendaSet: {
        name: "Agenda",
        position_ref: 5,
        routeName: "agenda",
        logo: "calendar4",
        disabled: false,
        btnText: "Consulta"
      },
      clientiSet: {
        name: "Pazienti",
        position_ref: 6,
        logo: "journal-medical",
        disabled: false,
        btnText: "Consulta"
      },
      impostazioniSet: {
        name: "Impostazioni",
        routeName: "impostazioniCittadino",
        logo: "gear",
        disabled: false,
        submenu: false
      },
      menuIsCompact: false
    }
  },
  methods: {
    ...mapActions("utente", ["logout"]),
    ...mapActions("company", ["logUserCompanyIn", "companyLogin"]),
    onSubmit() {
      this.logout()
    },
    checkPath() {
      if (this.user) {
        return "/" + this.user.i_am + "/home"
      }
    },
    handleMenuSize() {
      var navById = document.getElementById("nav-desktop")
      var currentWid = navById.clientWidth
      if (currentWid <= 80) {
        //
        navById.style.minWidth = "inherit"
        navById.style.flex = "0 0 15em"
        this.menuIsCompact = false
      } else {
        //
        navById.style.minWidth = "0"
        navById.style.flex = "0 0 79px"
        this.menuIsCompact = true
      }
    }
  },
  created() {
    if (this.$root.isCollapsed) {
      this.menuVisible = false
    }
    if (this.user.profile_pic) {
      this.avatarPath = this.user.profile_pic
    }
  },
  mounted() {
    // console.log(this.$refs.navmain.clientWidth);
    this.menuIsCompact = this.$refs.navmain.clientWidth <= 80
  },
  updated() { }
}
</script>

<style lang="scss" scoped>
.mobile-menu-visible {
  min-height: 100vh;
  max-height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3000;
  transition: all;
}

.mobile-menu {
  position: relative;
  transition: all;
}
</style>
