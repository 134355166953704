import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// import './sass/puntofarma/app.scss';
import axios from "axios";
import VueAxios from "vue-axios";
import VueMoment from "vue-moment";
import it from "moment/locale/it";
import vueDebounce from "vue-debounce";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import firebaseConfig from "./_config/firebase";
import VueGeolocation from "vue-browser-geolocation";

require("moment/locale/it");

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueGeolocation);
Vue.use(VueAxios, axios);
Vue.use(VueMoment);
Vue.use(vueDebounce);
Vue.config.productionTip = false;

//Comment inutile
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebaseApp);

navigator.serviceWorker
  .register("/firebase-messaging-sw.js")
  .then((registration) => {
    console.log("registration", registration);
  })
  .catch((err) => {
    console.log(err);
  });

if (process.env.VUE_APP_ENVIRONEMENT !== "prod") {
  require("./sass/puntofarma/app.scss");
}

new Vue({
  router,
  store,
  render: (h) => h(App),
  data() {
    return {
      menuLeft: false,
      isMobile: window.innerWidth <= 576,
      isCollapsed: window.innerWidth <= 767,
      isTablet: window.innerWidth > 576 && window.innerWidth < 1024,
      isLaptop: window.innerWidth >= 1024 && window.innerWidth <= 1440,
      isTabletOrLaptop: window.innerWidth <= 1440 && window.innerWidth > 576,
      isMobileOrTablet: window.innerWidth < 1024,
      prestazioniOnly: process.env.VUE_APP_PRESTAZIONI_ONLY,
    };
  },
}).$mount("#app");
