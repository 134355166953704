<template>
  <div class="register">
    <div>
      <b-container
        fluid
        class="puntofarmaCard p-lg-4 p-3 m-0 mb-4 p-0 col-12 col-md-11"
      >
        <b-form @submit.prevent="OnSubmit">
          <b-row class="mb-4" cols="1" cols-lg="2">
            <b-col class="col-12 col-sm-6 d-flex flex-wrap">
              <b-form-group
                label="Titolo"
                label-for="title"
                class="text-left fs-6 col-12 col-sm-4 pr-sm-1 text-font-grey mx-auto px-0"
                :class="$root.isMobile ? '' : 'col'"
              >
                <b-form-select
                  class="custom-select text-capitalize"
                  name="title"
                  v-model="anagrafica.title"
                  :options="titleOptions"
                >
                  <b-form-select-option :value="null" disabled
                    >Titolo</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
              <b-form-group
                label="Nome*"
                label-for="name"
                class="text-left fs-6 col-12 col-sm-8 pl-sm-1 text-font-grey mx-auto px-0"
                :class="$root.isMobile ? '' : 'col'"
              >
                <b-form-input
                  class="text-capitalize"
                  name="name"
                  type="text"
                  placeholder="nome..."
                  v-model="anagrafica.name"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Cognome*"
                label-for="surname"
                class="text-left fs-6 text-font-grey mx-auto"
              >
                <b-form-input
                  class="text-capitalize"
                  name="surname"
                  type="text"
                  placeholder="cognome..."
                  v-model="anagrafica.surname"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mb-4" cols="1" cols-lg="2">
            <b-col>
              <b-form-group
                label="Codice fiscale*"
                label-for="cf"
                class="text-left fs-6 text-font-grey"
              >
                <b-form-input
                  class="text-uppercase"
                  name="cf"
                  type="text"
                  placeholder="codice fiscale..."
                  maxlength="16"
                  minlength="16"
                  v-model="anagrafica.cf"
                  :state="anagrafica.cf == null ? null : validCf"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="
                  form.nomail
                    ? 'Numero Tessera Sanitaria*'
                    : 'Numero Tessera Sanitaria'
                "
                label-for="ts_number"
                class="text-left fs-6 text-font-grey mx-auto"
              >
                <b-form-input
                  class=" "
                  name="ts_number"
                  type="text"
                  placeholder="numero tessera..."
                  v-model="anagrafica.ts_number"
                  maxlength="20"
                  minlength="20"
                  :required="form.nomail"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mb-4" cols="1" cols-lg="2">
            <b-col>
              <b-form-group
                label="Data di nascita*"
                label-for="birthday"
                class="text-left fs-6 text-font-grey mx-auto"
              >
                <b-form-input
                  class=""
                  name="birthday"
                  type="date"
                  v-model="anagrafica.birthday"
                  required
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Genere"
                label-for="gender"
                class="text-left fs-6 text-font-grey mx-auto"
              >
                <b-form-radio-group
                  v-model="anagrafica.gender"
                  name="gender-options"
                  class="pt-2"
                >
                  <b-form-radio value="maschile">uomo</b-form-radio>
                  <b-form-radio value="femminile">donna</b-form-radio>
                  <b-form-radio :value="null">non indicato</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mb-4" cols="1" cols-lg="2">
            <b-col>
              <b-form-group
                label="Numero di telefono*"
                label-for="tel_number"
                class="text-left fs-6 text-font-grey mx-auto"
              >
                <b-input-group>
                  <b-input-group-prepend>
                    <h6 class="mt-2 pt-2 mr-1">+39</h6>
                  </b-input-group-prepend>
                  <b-form-input
                    name="tel_number"
                    type="text"
                    placeholder="telefono..."
                    v-model="anagrafica.tel_number"
                    minlength="9"
                    :state="anagrafica.tel_number ? validNumber : null"
                    required
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Telefono Domicilio"
                label-for="home_number"
                class="text-left fs-6 text-font-grey mx-auto"
              >
                <b-form-input
                  name="home_number"
                  type="text"
                  placeholder="telefono domicilio..."
                  v-model="anagrafica.home_number"
                  minlength="9"
                  :state="
                    anagrafica.home_number
                      ? anagrafica.home_number.length >= 9
                      : null
                  "
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Nazione di nascita*"
                label-for="nazione_nascita"
                class="text-left fs-6 text-font-grey mx-auto mt-3"
              >
                <b-form-select
                  class="text-capitalize"
                  :options="nazioniOptions"
                  name="nazione_nascita"
                  v-model="anagrafica.nazione_nascita"
                  required
                >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Regione di residenza*"
                label-for="regione"
                class="text-left fs-6 text-font-grey mx-auto mt-3"
              >
                <b-form-select
                  class="text-capitalize"
                  :options="regioni"
                  name="regione"
                  v-model="selected.regione"
                  @change="getProvince(selected)"
                  required
                >
                  <b-form-select-option :value="null"
                    >Seleziona Regione</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mb-4" cols="1" cols-lg="2">
            <b-col>
              <b-form-group
                label="Provincia di residenza*"
                label-for="provincia"
                class="text-left fs-6 text-font-grey mx-auto"
              >
                <b-form-select
                  class="text-capitalize"
                  :options="province"
                  name="provincia"
                  v-model="selected.provincia"
                  @change="getComuni(selected)"
                  required
                >
                  <b-form-select-option :value="null"
                    >Seleziona Provincia</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Comune di residenza*"
                label-for="comune"
                class="text-left fs-6 text-font-grey mx-auto"
              >
                <b-form-select
                  class="text-capitalize"
                  name="comune"
                  v-model="selected.comune"
                  required
                >
                  <b-form-select-option
                    v-for="(com, ind) in comuni"
                    :key="ind"
                    :value="com"
                    @change="setDomicilioData"
                    :selected="findComune(com)"
                    >{{ com.comune }}</b-form-select-option
                  >
                  <b-form-select-option :value="null"
                    >Seleziona Comune</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mb-4" cols="1" cols-lg="2">
            <b-col>
              <b-form-group
                label="Indirizzo e numero civico"
                label-for="address"
                class="text-left fs-6 text-font-grey mx-auto"
              >
                <b-form-input
                  class=" "
                  name="address"
                  type="text"
                  placeholder="indirizzo..."
                  v-model="anagrafica.address"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Cap"
                label-for="cap"
                class="text-left fs-6 text-font-grey mx-auto"
              >
                <b-form-input
                  class=" "
                  name="cap"
                  type="text"
                  placeholder="cap..."
                  v-model="anagrafica.cap"
                  minlength="5"
                  maxlength="5"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <template v-if="form.nomail">
                <b-row class="text-left">
                  <b-col class="col-12 mb-4">
                    <b-form-group
                      label-for="nomail"
                      class="text-left text-font-grey mx-auto"
                    >
                      <b-form-checkbox
                        name="nomail"
                        class="mt-3"
                        v-model="form.nomail"
                        :value="true"
                        :unchecked-value="false"
                      >
                        Il paziente non possiede un indirizzo email
                      </b-form-checkbox>
                    </b-form-group>
                    <p class="text-justify fw-bolder mb-0">
                      Se il paziente non dispone di una casella email è
                      necessario inserire un numero di cellulare di riserva, il
                      numero della tessera sanitaria e gli estremi di un
                      documento di identità.
                    </p>
                  </b-col>
                  <b-col class="col-12 col-lg-6 mb-4">
                    <b-form-group
                      label="Tipo documento*"
                      label-for="id_type"
                      class="text-left fs-6 text-font-grey mx-auto"
                    >
                      <b-input-group>
                        <b-form-select
                          name="id_type"
                          type="text"
                          v-model="anagrafica.id_type"
                          required
                        >
                          <b-form-select-option :value="null" disabled
                            >seleziona tipo documento</b-form-select-option
                          >
                          <b-form-select-option :value="0"
                            >carta di identità</b-form-select-option
                          >
                          <b-form-select-option :value="1"
                            >patente</b-form-select-option
                          >
                          <b-form-select-option :value="2"
                            >passaporto</b-form-select-option
                          >
                        </b-form-select>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-12 col-lg-6">
                    <b-form-group
                      label="Numero documento*"
                      label-for="id_number"
                      class="text-left fs-6 text-font-grey mx-auto"
                    >
                      <b-input-group>
                        <b-form-input
                          name="id_number"
                          type="text"
                          placeholder="ID..."
                          v-model="anagrafica.id_number"
                          minlength="5"
                          required
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-12 col-lg-6 mb-4">
                    <b-form-group
                      label="Telefono di riserva*"
                      label-for="tel_number_secondary"
                      class="text-left fs-6 text-font-grey mx-auto"
                    >
                      <b-input-group>
                        <b-input-group-prepend>
                          <h6 class="mt-2 pt-2 mr-1">+39</h6>
                        </b-input-group-prepend>
                        <b-form-input
                          name="tel_number_secondary"
                          type="text"
                          placeholder="telefono..."
                          v-model="anagrafica.tel_number_secondary"
                          minlength="9"
                          required
                          :state="
                            anagrafica.tel_number_secondary
                              ? !isNaN(anagrafica.tel_number_secondary)
                              : null
                          "
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>

              <template v-else>
                <b-form-group
                  label="Email*"
                  label-for="email"
                  class="text-left fs-6 text-font-grey mx-auto"
                >
                  <b-form-input
                    v-model="form.email"
                    placeholder="email..."
                    class=" "
                    name="email"
                    :state="form.email == null ? null : validEmail"
                    :disabled="!anagrafica.name || !anagrafica.surname"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label-for="nomail"
                  class="text-left text-font-grey mx-auto"
                >
                  <b-form-checkbox
                    name="nomail"
                    class="mt-3"
                    v-model="form.nomail"
                    :value="true"
                    :unchecked-value="false"
                  >
                    Il paziente non possiede un indirizzo email
                  </b-form-checkbox>
                </b-form-group>
              </template>
              <p class="p-4 text-left text-medium-grey fs-6">
                *parametri obbligatori.
              </p>
              <div class="col-12 text-right mt-3">
                <b-button
                  type="button"
                  variant="outline-primary mx-2"
                  @click="$emit('cancel-operation')"
                  >Indietro
                </b-button>
                <b-button type="submit" variant="primary text-white  mx-2"
                  >Registra
                  <b-icon icon="arrow-right"></b-icon>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
    </div>
  </div>
</template>

<script>
import * as moment from "moment/moment"
import { supportService, utilityService } from "@/_services"
import { mapState, mapActions } from "vuex"
export default {
  name: "SchedaArruolamento",
  props: ["params"],
  data() {
    return {
      form: {
        email: null,
        // password: null,
        // password_confirmation: null,
        // data_consense: false,
        // share_consense: false,
        // notification_consense: false,
        // charge_consense: false,
        nomail: false,
        type: 0,
        anagrafica: null
      },
      anagrafica: {
        regione: null,
        provincia: null,
        comune: null,
        cap: null,
        address: null,
        name: null,
        surname: null,
        cf: null,
        gender: null,
        birthday: null,
        ts_number: null,
        tel_number: null,
        tel_number_secondary: null,
        id_number: null,
        id_type: null,
        title: null,
        nazione_nascita: null
      },
      selected: {
        regione: null,
        provincia: null,
        comune: null
      },
      cfMonths: {
        A: "01",
        B: "02",
        C: "03",
        D: "04",
        E: "05",
        H: "06",
        L: "07",
        M: "08",
        P: "09",
        R: "10",
        S: "11",
        T: "12",
        a: "01",
        b: "02",
        c: "03",
        d: "04",
        e: "05",
        h: "06",
        l: "07",
        m: "08",
        p: "09",
        r: "10",
        s: "11",
        t: "12"
      },
      titleOptions: ["Sig.", "Sig.ra", "Dott.", "Dott.ssa"],
      nazioni: []
    }
  },
  watch: {
    selected: {
      handler(val) {
        if (val.comune !== null) {
          this.anagrafica.comune = this.selected.comune.comune
          this.anagrafica.provincia = this.selected.comune.provincia
          this.anagrafica.regione = this.selected.comune.regione
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState("utility", ["searching", "comuni", "province", "regioni"]),
    ...mapState({
      alert: (state) => state.alert
    }),
    validEmail() {
      if (this.form.email) {
        const validRegex =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        return this.form.email.match(validRegex) ? true : false
      }
      return null
    },
    validCf() {
      if (this.anagrafica.cf) {
        const validRegex =
          /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/
        if (this.anagrafica.cf.length === 16) {
          return this.anagrafica.cf.match(validRegex)
            ? this.fillOthers()
            : false
        }
        return this.anagrafica.cf.match(validRegex) ? true : false
      }
      return null
    },
    validNumber() {
      if (this.anagrafica.tel_number) {
        const prefix = this.anagrafica.tel_number.slice(0, 2)
        const hasPrefix =
          prefix === "00" || this.anagrafica.tel_number.indexOf("+") >= 0
        return !isNaN(this.anagrafica.tel_number) && !hasPrefix
      }
      return false
    },
    nazioniOptions() {
      if (this.nazioni && this.nazioni.length > 0) {
        return [
          { value: null, text: "Seleziona Nazione" },
          { value: "italia", text: "Italia" }
        ].concat(
          this.nazioni.map((nazione) => {
            return {
              value: nazione.nazione,
              text: nazione.nazione
                .toLowerCase()
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")
            }
          })
        )
      } else
        return [
          {
            value: null,
            text: "Seleziona Nazione"
          }
        ]
    }
  },
  methods: {
    ...mapActions("utility", ["getComuni", "getProvince", "getRegioni"]),
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    fillOthers() {
      try {
        var cf = this.anagrafica.cf
        let getYear = cf.substr(6, 2)
        let getMonth = this.cfMonths[cf.substr(8, 1)]
        let getDay = parseInt(cf.substr(9, 2))
        let day = getDay > 31 ? getDay - 40 : getDay
        this.anagrafica.gender = getDay > 31 ? "femminile" : "maschile"

        let currentYear = moment().year() - 2000
        let yearPrefix =
          parseInt(getYear) >= 0 && parseInt(getYear) <= currentYear
            ? "20"
            : "19"
        var stringBDate = yearPrefix + getYear + "/" + getMonth + "/" + day
        var isDateValid = moment(stringBDate).isValid()
        this.anagrafica.birthday = moment(stringBDate).format("yyyy-MM-DD")
        if (cf.substr(11, 1) === "Z") {
          this.anagrafica.nazione_nascita = this.nazioni.find(
            (nazione) => nazione.codice === cf.substr(11, 4)
          )?.nazione
        } else {
          this.anagrafica.nazione_nascita = "italia"
          this.searchComuneViaCCatastale(cf.substr(11, 4))
        }
        return isDateValid
      } catch (err) {
        return false
      }
    },
    OnSubmit() {
      // console.log(this.form);
      if (this.form) {
        var self = this
        this.form.anagrafica = this.anagrafica
        supportService
          .arruolaPaziente(this.form)
          .then((res) => {
            window.scrollTo(0, 0)
            self.successAlert("Arruolamento completato con successo")
            setTimeout(function () {
              self.$emit("arruolamento-completed", res.data.data.hashid)
              self.$emit("arruolamento-done", res.data.data)
            }, 3000)
          })
          .catch((error) => {
            // console.log(error);
            var mesg = "Non è stato possibile completare l'operazione"
            if (error.status == 422) {
              mesg = ""
              Object.keys(error.data.errors).map(function (el) {
                mesg += error.data.errors[el]
              })
            } else if (error.status == 400) {
              mesg = error.data.message
            }
            window.scrollTo(0, 0)
            self.errorAlert(mesg)
          })
      }
    },
    setDomicilioData() {
      if (this.selected.comune) {
        this.anagrafica.comune = this.selected.comune.comune
        this.anagrafica.provincia = this.selected.comune.provincia
        this.anagrafica.regione = this.selected.comune.regione
      }
    },
    findComune(comune) {
      if (this.anagrafica.comune) {
        if (comune.comune === this.anagrafica.comune) {
          this.selected.comune = comune
          return true
        }
      }
      return false
    },
    searchComuneViaCCatastale(codice) {
      const self = this
      utilityService
        .searchComuneViaCodiceCatastale(codice)
        .then(function (res) {
          // console.log(res.data.data)
          if (res.data.data) {
            self.selected.regione = res.data.data.regione
            self.getProvince(res.data.data)
            self.selected.provincia = res.data.data.provincia
            self.getComuni(res.data.data)
            self.selected.comune = res.data.data
          }
        })
        .catch(function (err) {
          console.log(err)
        })
    },
    getNazioni() {
      var self = this
      utilityService
        .nazioni()
        .then((res) => {
          self.nazioni = res.data
          // console.log(res.data)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  created() {
    this.getRegioni()
    this.getNazioni()
    if (this.params) {
      this.form.email = this.params.email
      this.anagrafica.cf = this.params.cf
    }
  }
}
</script>
<style lang="scss" scoped>
// label {
//   margin-bottom: 15px !important;
// }

div.card-header {
  background-color: #fff !important;
}

.nav-item > a.bg-white:hover {
  background-color: #fff !important;
}

.info_icon {
  position: absolute;
  left: 3.5rem;
  color: transparent !important;
  cursor: pointer;
}

.fs-5 {
  font-size: 89%;
  // font-weight: 700;
}

.informativa__p {
  overflow-y: scroll;
  padding: 0 5px;
  max-height: 250px;
}
</style>
