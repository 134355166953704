<template>
  <div class="text-white">
    <!-- <b-spinner
      variant="prim-grad-1"
      style="
        width: 3rem;
        height: 3rem;
        position: absolute;
        z-index: 300;
        top: 25%;
      "
      v-if="loading"
      >In attesa che l'utente si colleghi</b-spinner
    > -->
    <p
      v-if="loading"
      class="w-100 text-center text-prim-grad-1"
      style="position: absolute; z-index: 300; top: 25%; font-size: 80%"
    >
      <span>
        <b-spinner font-scale="0.3" type="grow" label="Loading..."></b-spinner>
        <span
          style="vertical-align: super"
          class="fw-bolder mx-2 overlayed__message general_border p-1"
        >
          In attesa che l'altro utente si colleghi...
        </span>
        <b-spinner type="grow" label="Loading..."></b-spinner>
      </span>
    </p>
    <div id="videopreview" style="position: relative"></div>
    <div class="col-12 text-center" id="call__btns">
      <b-button-group>
        <b-button
          @click="toggleMute"
          variant="outline-prim-grad-1 border-white"
        >
          <b-icon :icon="isMute ? 'mic-mute-fill' : 'mic-fill'"></b-icon>
        </b-button>
        <b-button
          @click="toggleVideo"
          variant="outline-prim-grad-1 border-white"
        >
          <b-icon
            :icon="videoMute ? 'camera-video-off-fill' : 'camera-video-fill'"
          ></b-icon>
        </b-button>
      </b-button-group>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "VideoCaller",
  props: ["tcchannel"],
  data() {
    return {
      loading: true,
      fm: null,
      client: null,
      localMedia: null,
      remoteMedia: null,
      videochiamata: null,
      clientName: null,
      token: null,
      isOn: true,
      connection: null,
      chatConnection: null,
      unavailable: false,
      notReady: true,
      layoutManager: null,
      isClosed: false,
      isMute: false,
      videoMute: false,
      endEmitted: false,
      connectionStatusMap: {
        1: "Apertura nuovo canale di connessione sicura...",
        2: "Connessione sicura inizializzata...",
        3: "Connessione in corso...",
        4: "Connesso!",
        5: "Errore durante il collegamento, connessione canale sicuro in chiusura...",
        6: "Errore durante il collegamento, connessione canale sicuro chiusa.",
        7: "L'utente ha abbandonato il consulto. Teleconsulto concluso.",
        8: "L'utente ha abbandonato il consulto. Teleconsulto concluso.",
        // 7: "Richiesta chiusura della connessione sicura, connessione canale sicuro in chiusura...",
        // 8: "Richiesta chiusura della connessione sicura, connessione canale sicuro chiusa .",
      },
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["user"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    start() {
      const fm = window.fm;
      const gateway = process.env.VUE_APP_LIVEWITCH_GATEWAY;
      const secret = process.env.VUE_APP_LIVEWITCH_SECRET;
      const applicationId = process.env.VUE_APP_LIVEWITCH_APP_ID;
      const channelId = this.tcchannel;
      // var client = null;
      // var token = null;
      const userId = fm.liveswitch.Guid.newGuid().toString().replace(/-/g, "");
      const deviceId = this.clientName;
      const client = new fm.liveswitch.Client(
        gateway,
        applicationId,
        userId,
        deviceId
      );
      this.client = client;
      const token = fm.liveswitch.Token.generateClientRegisterToken(
        applicationId,
        client.getUserId(),
        client.getDeviceId(),
        client.getId(),
        null,
        [new fm.liveswitch.ChannelClaim(channelId)],
        secret
      );
      this.token = token;
      const self = this;
      client
        .register(token)
        .then(function (channels) {
          const joinToken = fm.liveswitch.Token.generateClientJoinToken(
            applicationId,
            client.getUserId(),
            client.getDeviceId(),
            client.getId(),
            new fm.liveswitch.ChannelClaim(channelId),
            secret
          );

          // self.client
          client
            .join(channelId, joinToken)
            .then(function (channel) {
              self.channel = channel;
              // channels.forEach((channel) => {
              const audioset = true;
              const videoset = {
                width: 320,
                height: 240,
                frameRate: 30,
              };
              const localMedia = new fm.liveswitch.LocalMedia(
                audioset,
                videoset
              );
              const receiveOnly = false;
              const promise = new fm.liveswitch.Promise();
              const pluginConfig = new fm.liveswitch.PluginConfig();
              pluginConfig.setActiveXPath("./FM.LiveSwitch.ActiveX.cab");
              fm.liveswitch.Plugin.install(pluginConfig)
                .then(() => {
                  if (!fm.liveswitch.Plugin.isReady(!receiveOnly)) {
                    // Check if this browser is supported without local media.
                    if (fm.liveswitch.Plugin.isReady()) {
                      promise.reject(
                        alert(
                          "This browser supports WebRTC, but does not support media capture.\nTry receive-only mode!"
                        )
                      );
                    } else {
                      promise.reject(
                        alert(
                          "This browser does not support WebRTC, and no plugin could be found."
                        )
                      );
                    }
                  }
                })
                .fail(function (ex) {
                  console.log(".Plugin.install error", ex);
                  self.errorAlert("Non è stato possibile collegarsi");
                  self.errorAlert(ex);
                });
              self.localMedia = localMedia;
              localMedia
                .start()
                .then(() => {
                  const layoutManager = new fm.liveswitch.DomLayoutManager(
                    document.getElementById("videopreview")
                  );
                  self.layoutManager = layoutManager;
                  const localView = localMedia.getView();
                  layoutManager.setLocalView(localView);
                })
                .fail(function (ex) {
                  console.log(".localMedia.start error", ex);
                  self.errorAlert(
                    "Non è stato possibile avviare fotocamera e/o microfono"
                  );
                });

              try {
                channel.addOnRemoteClientJoin(function (remoteClientInfo) {
                  //questo evento si scatena sul primo che arriva quando il secondo si collega
                  const remoteMedia = new fm.liveswitch.RemoteMedia();
                  const audioStream = new fm.liveswitch.AudioStream(
                    self.localMedia,
                    remoteMedia
                  );
                  const videoStream = new fm.liveswitch.VideoStream(
                    self.localMedia,
                    remoteMedia
                  );

                  const connection = channel.createPeerConnection(
                    remoteClientInfo,
                    audioStream,
                    videoStream
                  );
                  connection.setTag("peer-offer");
                  self.connection = connection;
                  try {
                    self.layoutManager.addRemoteView(
                      remoteMedia.getId(),
                      remoteMedia.getView()
                    );
                  } catch (err) {
                    console.log(err);
                    self.$emit("tc-start", false);
                    self.errorAlert(
                      'Non è stato possibile connettersi. Clicca su "Termina" e riavvia la sessione.'
                    );
                  }

                  connection.addOnStateChange(function (c) {
                    try {
                      self.infoAlert(self.connectionStatusMap[c.getState()]);
                    } catch (err) {
                      self.errorAlert("stato connessione sconosciuto");
                    }
                    if (
                      c.getState() == fm.liveswitch.ConnectionState.Closing ||
                      c.getState() == fm.liveswitch.ConnectionState.Failing
                    ) {
                      self.layoutManager.removeRemoteView(remoteMedia.getId());
                    }
                  });
                  connection
                    .open()
                    .then(function (result) {
                      self.notReady = false;
                      self.loading = false;
                      self.$emit("tc-start", true);
                      self.infoAlert("Connessione stabilita.");
                    })
                    .fail(function (ex) {
                      console.log(
                        "addOnRemoteClientJoin connection open error",
                        ex
                      );

                      self.errorAlert(
                        "Non è stato possibile stabilire una connessione con il peer remoto. Terminare e riavviare il consulto."
                      );
                    });
                });
                channel.addOnPeerConnectionOffer(function (
                  peerConnectionOffer
                ) {
                  //questo evento si scatena sul secondo che arriva quando si connette al consulto
                  const remoteMedia = new fm.liveswitch.RemoteMedia();
                  const audioStreamA = new fm.liveswitch.AudioStream(
                    self.localMedia,
                    remoteMedia
                  );
                  const videoStreamA = new fm.liveswitch.VideoStream(
                    self.localMedia,
                    remoteMedia
                  );
                  // var audioStreamA;
                  // var videoStreamA;
                  // if (peerConnectionOffer.getHasAudio()) {
                  //   audioStreamA = new fm.liveswitch.AudioStream(
                  //     self.localMedia,
                  //     remoteMedia
                  //   );
                  // }
                  // if (peerConnectionOffer.getHasVideo()) {
                  //   videoStreamA = new fm.liveswitch.VideoStream(
                  //     self.localMedia,
                  //     remoteMedia
                  //   );
                  // }

                  const connection = channel.createPeerConnection(
                    peerConnectionOffer,
                    audioStreamA,
                    videoStreamA
                  );
                  self.layoutManager.addRemoteView(
                    remoteMedia.getId(),
                    remoteMedia.getView()
                  );
                  connection.setTag("peer-answer");
                  self.connection = connection;

                  connection.addOnStateChange(function (c) {
                    try {
                      self.infoAlert(self.connectionStatusMap[c.getState()]);
                    } catch (err) {
                      self.errorAlert("stato connessione sconosciuto");
                    }
                    if (
                      c.getState() == fm.liveswitch.ConnectionState.Closing ||
                      c.getState() == fm.liveswitch.ConnectionState.Failing
                    ) {
                      self.layoutManager.removeRemoteView(remoteMedia.getId());
                    }
                  });
                  connection
                    .open()
                    .then(function (result) {
                      self.notReady = false;
                      self.loading = false;
                      self.$emit("tc-start", true);
                      self.infoAlert("Connessione stabilita.");
                    })
                    .fail(function (ex) {
                      //console.log("an error occurred", ex);
                      self.$emit("tc-start", false);
                      self.errorAlert(
                        'Non è stato possibile connettersi. Clicca su "Termina" e riavvia la sessione.'
                      );
                    });
                });
                //}
                //P2P
              } catch (err) {
                self.errorAlert("Si è verificato un errore");
                console.log("error tru catch", err);
              }
            })
            .fail(function (ex) {
              //console.log("failed to join channel", ex);
              self.errorAlert("Non è stato possibile collegarsi");
            });
        })
        .fail(function (ex) {
          //console.log("registration failed", ex);
          self.errorAlert("Non è stato possibile collegarsi");
        });
    },
    stopCall() {
      const self = this;
      if (this.layoutManager) {
        try {
          this.layoutManager.unsetLocalView();
        } catch (err) {
          //
        }
      }
      this.localMedia
        .stop()
        .then(function (lm) {
          //console.log("media capture stopped");
          self.localMedia.destroy();
        })
        .fail(function (err) {
          console.log(err.message);
        });
      if (this.connection) {
        this.connection
          .close()
          .then(function (result) {
            //console.log("connection closed");
            self.emitEnd();
          })
          .fail(function (ex) {
            //console.log("an error occurred");
          });
      }
      this.client
        .leave(this.tcchannel)
        .then(function (channel) {
          //console.log("left the channel");
        })
        .fail(function (ex) {
          //console.log("failed to leave the channel");
        });
      this.client
        .unregister()
        .then(function (result) {
          //console.log("unregistration succeeded");
        })
        .fail(function (ex) {
          //console.log("unregistration failed");
        });
      this.isOn = false;
      this.isClosed = true;
      this.emitEnd();
      // this.setEndDate();
    },
    emitEnd() {
      if (!this.endEmitted) {
        this.$emit("tc-end", true);
        this.endEmitted = true;
      }
    },
    toggleMute() {
      this.isMute = !this.isMute;
      this.localMedia.setAudioMuted(this.isMute);
    },
    toggleVideo() {
      this.videoMute = !this.videoMute;
      this.localMedia.setVideoMuted(this.videoMute);
    },
  },
  created() {},
  mounted() {
    //console.log(window.fm);
    this.fm = window.fm;
    this.clientName =
      this.user.anagrafica.name + " " + this.user.anagrafica.surname;
    this.start();
  },
  beforeDestroy() {
    this.stopCall();
    this.$emit("tc-end", true);
  },
};
</script>

<style>
#videopreview {
  /* width: 75vw;
  height: 50vh; */
  width: 100%;
  height: 57vh;
}

#call__btns {
  position: absolute;
  top: 50vh;
}

.fm-video > video {
  position: initial !important;
}
.overlayed__message {
  border: 1px solid transparent;
  /* border: 1px solid #fefefee0; */
  background-color: #fefefee0;
}
</style>
