<template>
  <div>
    <b-row class="d-flex mt-n3" align-v="stretch">
      <b-col
        @click="selectedSubject = 0"
        class="mt-3 px-0 font-weight-bolder w-100 point col-12 col-md-5 col-lg-3 col-xl-2"
      >
        <div
          class="mr-3 puntofarmaCard p-2 py-4 h-100 d-flex align-items-center justify-content-center"
          :class="{
            'secondary_bg_gradient text-white': selectedSubject === 0
          }"
        >
          Per me
        </div>
      </b-col>

      <b-col
        @click="pickFamiliare()"
        class="mt-3 px-0 font-weight-bolder w-100 point col-12 col-md-5 col-lg-3 col-xl-2"
      >
        <div
          class="mr-3 puntofarmaCard p-2 py-4 h-100 d-flex align-items-center justify-content-center"
          :class="{
            'secondary_bg_gradient text-white': selectedSubject === 1
          }"
        >
          Per un mio familiare
        </div>
      </b-col>

      <b-col
        class="mt-3 px-0 font-weight-bolder w-100 point col-12 col-md-5 col-lg-3 col-xl-2"
        @click="pickAssistito()"
      >
        <div
          class="mr-3 puntofarmaCard p-2 py-4 h-100 d-flex align-items-center justify-content-center"
          :class="{
            'secondary_bg_gradient text-white': selectedSubject === 2
          }"
        >
          Per un mio assistito
        </div>
      </b-col>
    </b-row>
    <b-row class="my-3 py-2" v-if="selectedSubject === 1">
      <b-col>
        <b-form-group
          label="Seleziona familiare"
          label-for="type"
          class="text-left fw-600"
        >
          <b-form-select
            name="type"
            class="mx-auto  select__dis"
            v-model="subjectable.subject_id"
            required
            :size="$root.isMobile ? 'sm' : ''"
            @change="emitSubject"
          >
            <b-form-select-option
              v-for="(familiare, ind) in familymemberList"
              :key="ind"
              :value="familiare.hashid"
              >{{ familiare.name }} {{ familiare.surname }} ({{
                familiare.relationship
              }})</b-form-select-option
            >
            <b-form-select-option :value="null" disabled
              >scegli familiare</b-form-select-option
            >
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="my-3 py-2" v-else-if="selectedSubject === 2">
      <b-col>
        <b-form-group
          label="Seleziona assistito"
          label-for="type"
          class="text-left fw-600"
        >
          <b-form-select
            name="type"
            class="mx-auto  select__dis"
            v-model="subjectable.subject_id"
            required
            @change="emitSubject"
          >
            <b-form-select-option
              v-for="(assistito, ind) in assisted"
              :key="ind"
              :value="assistito.hashid"
              >{{ assistito.anagrafica.name }}
              {{ assistito.anagrafica.surname }}
            </b-form-select-option>
            <b-form-select-option :value="null" disabled
              >scegli assistito</b-form-select-option
            >
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-modal
      id="modal-register-new-subject"
      ref="modal-register-new-subject"
      hide-footer
      centered
      content-class="border border-danger general_boder"
      body-class="px-4 pb-4 pt-0"
      header-class="pb-0"
    >
      <div>
        <h4 class="text-danger mt-n4">OOPS</h4>
        <p>Non hai registrato alcun {{ newSubject }}.</p>
        <p>Prosegui per registrarlo</p>
        <p class="d-flex justify-content-center mb-0">
          <b-button
            class="text-center"
            variant="secondary"
            @click="
              $router.push({ name: 'familiari', params: { section: 'nuovo' } })
            "
          >
            registralo ora</b-button
          >
        </p>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex"
import { cittadinoService } from "@/_services"
export default {
  name: "SubjectSelection",
  data() {
    return {
      selectedSubject: 0,
      familymemberList: [],
      assisted: [],
      subjectable: {
        subject_id: null,
        subject_type: "user_in_app"
      },
      newSubject: null
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["status", "user"])
  },
  watch: {
    selectedSubject: function (val) {
      switch (val) {
        case 0:
          this.subjectable.subject_id = this.user.hashid
          this.subjectable.subject_type = "user_in_app"
          break
        case 1:
          this.subjectable.subject_id = null
          this.subjectable.subject_type = "familiare"
          break
        case 2:
          this.subjectable.subject_id = null
          this.subjectable.subject_type = "user_in_app"
          break
      }
      this.emitSubject()
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    emitSubject() {
      this.$emit("selected-subject", this.subjectable)
    },
    getFamilyMembers() {
      var self = this
      cittadinoService
        .getFamilyMembers()
        .then(function (res) {
          self.familymemberList = res.data.data
        })
        .catch(function (err) {
          console.log(err)
          self.errorAlert(
            "Non è stato possibile recuperare le informazioni richieste"
          )
        })
    },
    getCaregivers() {
      var parms = {
        only: "given"
      }
      var self = this
      cittadinoService
        .getCaregivers(parms)
        .then(function (res) {
          self.assisted = res.data.data
        })
        .catch(function (err) {
          console.log(err)
          var msg = "Non è stato possibile recuperare le informazioni."
          window.scrollTo(0, 0)
          self.errorAlert(msg)
        })
    },
    pickFamiliare() {
      if (this.familymemberList.length > 0) {
        return (this.selectedSubject = 1)
      }
      this.newSubject = "familiare"
      this.$bvModal.show("modal-register-new-subject")
    },
    pickAssistito() {
      if (this.assisted.length > 0) {
        return (this.selectedSubject = 2)
      } else this.newSubject = "assistito"
      this.$bvModal.show("modal-register-new-subject")
    }
  },
  created() {
    this.getFamilyMembers()
    this.getCaregivers()
  }
}
</script>
