import Vue from "vue";
import Vuex from "vuex";

import { utente } from "./user.module";
import { alert } from './alert.module';
import { utility } from './utility.module';
import { company } from './company.module';

Vue.use(Vuex);

export default new Vuex.Store({
  // state: {},
  // mutations: {},
  // actions: {},
  modules: {
    utente,
    alert,
    utility,
    company
    },
});
