<template>
  <div>
    <b-col cols="12">
      <b-form-group label="Scegli icona" class="text-lefttext-font-grey"
        description="*l'icona viene utilizzata nella visualizzazione compatta del calendario">
        <b-form-radio-group v-model="selectedIcon" name="icon-radios" @change="emitEdit">
          <b-form-radio v-for="(icona, k) in icons" :key="icona" :value="k">
            <b-icon :icon="icona"></b-icon>
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>
    </b-col>
  </div>
</template>
<script>
export default {
  name: "IconSelection",
  props: ["preselected", "colsize"],
  data() {
    return {
      icons: {
        2: "file-ppt",
        3: "chat-text",
        4: "screwdriver",
        5: "droplet-fill",
        6: "flower1",
        7: "emoji-smile",
        8: "exclude",
        9: "egg-fried",
        10: "gem",
        11: "heart-fill",
        12: "hourglass-split",
        13: "journal-medical",
        14: "life-preserver",
        15: "lock-fill",
        16: "moon",
        17: "node-minus-fill",
        18: "x-diamond-fill",
        19: "cart-fill",
        20: "bug-fill",
        21: "brightness-high-fill",
        22: "bell-fill",
      },
      selectedIcon: 2,
    };
  },
  methods: {
    emitEdit() {
      // console.log(this.selectedIcon);
      this.$emit("selected-icon", this.selectedIcon);
    },
  },
  created() {
    if (this.preselected) {
      this.selectedIcon = this.preselected;
    }
    // console.log(this.preselected);
  },
};
</script>