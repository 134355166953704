<template>
  <div>
    <h4 class="text-left general_border mt-5 mb-2">
      Dettagli Raccolta - {{ activity.name }}
    </h4>

    <div>
      <p class="text-left mt-4 text-medium-grey">
        {{ activity.description }}
      </p>
      <b-row
        class="text-left mb-3"
        v-if="
          (asAdmin &&
            !user.permessi.includes('gestire aziende propria regione')) ||
          activity.created_by !== null
        "
      >
        <b-col>
          <b-button
            size="sm"
            variant="outline-primary"
            class="mr-2 my-2"
            @click="openUpdate"
          >
            <b-icon icon="pencil-fill"></b-icon> Modifica raccolta
          </b-button>
          <b-button
            v-if="surveys && surveys.length === 0"
            size="sm"
            variant="outline-danger"
            @click="openDelete()"
          >
            <b-icon icon="bucket"></b-icon> Elimina raccolta
          </b-button>
        </b-col>
      </b-row>
      <b-row
        cols="1"
        cols-md="2"
        class="ml-n2"
        align-v="center"
        v-if="surveys && surveys.length > 0"
      >
        <b-col v-for="(surv, ind) in surveys" :key="ind" class="my-2 px-2">
          <div
            class="puntofarmaCard border border-light-panel general_border text-left p-4 h-100"
          >
            <h5 class="">
              {{ surv.name }}
              <b-icon
                icon="circle-fill"
                :variant="
                  asAdmin == true
                    ? surv.enabled == 1
                      ? 'success'
                      : 'danger'
                    : surv.active == 1
                    ? 'success'
                    : 'danger'
                "
              ></b-icon>
            </h5>
            <p>{{ surv.description }}</p>
            <p class="text-capitalize mb-0">{{ surv.tipo }}</p>
            <p class="text-capitalize">
              somministrati:
              {{ asAdmin == true ? surv.tot_filled : surv.filled }}
            </p>
            <div class="d-flex justify-content-between">
              <span class="text-medium-grey" v-if="surv.created_at">
                {{ surv.created_at | moment("DD/MM/YYYY") }}
              </span>
              <span class="text-medium-grey" v-else>--</span>
              <div>
                <b-button
                  v-if="activity.prof2 !== 1"
                  variant="info  px-3 mr-1"
                  size="sm"
                  @click="selectThis(surv)"
                  v-b-tooltip.hover
                  title="Visualizza"
                >
                  <b-icon icon="zoom-in"></b-icon>
                </b-button>
                <!-- progetto prof2 -->
                <b-button
                  v-if="activity.prof2 === 1"
                  variant="info  mr-1 px-3"
                  size="sm"
                  @click="
                    $router.push({
                      name: 'progetto prof2',
                      query: { activity: activity.name },
                    })
                  "
                  v-b-tooltip.hover
                  title="Report"
                >
                  <b-icon icon="collection-fill"></b-icon>
                </b-button>
                <b-button
                  v-else
                  variant="info mr-1 px-3"
                  size="sm"
                  @click="
                    $router.push({
                      name: asAdmin ? 'screening' : 'attivita',
                      params: asAdmin
                        ? {
                            section: 'questionari',
                            action: 'report',
                            quest: surv.hashid,
                          }
                        : {
                            section: 'questionario',
                            action: 'report',
                            quest: surv.hashid,
                          },
                    })
                  "
                  v-b-tooltip.hover
                  title="Report"
                >
                  <b-icon icon="collection-fill"></b-icon>
                </b-button>
                <b-button
                  v-if="!asAdmin && level === 1"
                  variant="secondary    px-3"
                  size="sm"
                  @click="
                    $router.push({
                      name: 'attivita',
                      params: {
                        section: 'questionario',
                        action: 'somministra',
                        quest: surv.hashid,
                      },
                    })
                  "
                  v-b-tooltip.hover
                  :title="
                    activity.prof2 === 1 ? 'Nuova Terapia' : 'Somministra'
                  "
                  :disabled="surv.active === 0"
                >
                  <b-icon icon="clipboard-plus"></b-icon>
                </b-button>
              </div>
            </div>
          </div>
        </b-col>
        <b-col class="my-2">
          <div class="col-12 col-md-10 col-lg-8">
            <b-button
              block
              v-if="asAdmin"
              variant="secondary"
              class="m-1"
              @click="
                $router.push({
                  name: 'screening',
                  params: { section: 'questionari', action: 'nuovo' },
                  query: { activity: activity.hashid },
                })
              "
            >
              <b-icon icon="plus"></b-icon>
              <span> Nuovo Questionario</span>
            </b-button>
            <b-button
              block
              v-else-if="activity.created_by !== null"
              variant="secondary"
              class="m-1"
              @click="
                $router.push({
                  name: 'attivita',
                  params: { section: 'questionario/nuovo' },
                  query: { activity: activity.hashid },
                })
              "
            >
              <b-icon icon="plus"></b-icon>
              <span> Nuovo Questionario</span>
            </b-button>

            <!-- <p class="mt-3"> -->
            <b-button
              block
              variant="primary"
              class="m-1"
              @click="downloadReport"
              >Scarica report attività in formato pdf
            </b-button>
            <b-button
              block
              variant="primary"
              class="m-1"
              @click="downloadXlsReport"
              >Scarica report attività in formato xlsx
            </b-button>
            <!-- </p> -->
          </div>
        </b-col>
      </b-row>
      <p class="text-left" v-else>
        Nessun questionario per l'attività.
        <b-button
          v-if="asAdmin"
          variant="primary"
          class="d-flex align-items-center justify-content-between"
          @click="
            $router.push({
              name: 'attivita',
              params: { section: 'questionario/nuovo' },
              query: { activity: activity.hashid },
            })
          "
        >
          <b-icon icon="plus"></b-icon>
          <span> Nuovo Questionario</span>
        </b-button>
        <b-button
          v-else-if="activity.created_by !== null"
          variant="secondary"
          class="d-flex align-items-center justify-content-between"
          @click="
            $router.push({
              name: 'attivita',
              params: { section: 'questionario/nuovo' },
              query: { activity: activity.hashid },
            })
          "
        >
          <b-icon icon="plus"></b-icon>
          <span class="ml-2"> Nuovo Questionario</span>
        </b-button>
      </p>
    </div>

    <b-modal
      ref="modal-showsurvey"
      centered
      :title="modalTitle"
      size="xl"
      hide-footer
    >
      <SurveyShower v-if="selectedRow" :survey="selectedRow" class="px-3" />
    </b-modal>
    <b-modal
      id="del-confirm"
      centered
      title="Conferma operazione"
      ok-title="Sì, conferma"
      cancel-title="No, annulla"
      ok-variant="outline-danger "
      cancel-variant="info  mr-2"
      footer-border-variant="white"
      @cancel="$bvModal.hide('del-confirm')"
      @ok="doDelete"
    >
      <h5>Vuoi eliminare l'attività "{{ activity.name }}"?</h5>
      <p>
        L'azione comporta l'eliminazione di tutti i questionari dell'attività e
        gli eventuali relativi questionari compilati.
      </p>
    </b-modal>
    <b-modal
      centered
      id="edit-ativity"
      title="Modifica Attività"
      size="lg"
      ok-title="Salva modifiche"
      cancel-title="Annulla"
      ok-variant="outline-primary "
      cancel-variant="info  mr-2"
      footer-border-variant="white"
      @cancel="$bvModal.hide('edit-ativity')"
      @ok="doUpdate"
    >
      <b-form class="text-left fw-bolder">
        <b-form-group id="name" label="Nome*:" label-for="name">
          <b-form-input
            id="name"
            v-model="editForm.name"
            type="text"
            placeholder="Nome attività.."
            class=" "
            required
            :state="validName"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="description"
          label="Descrizione:"
          label-for="description"
        >
          <b-form-input
            id="description"
            v-model="editForm.description"
            type="text"
            placeholder="Descrizione attività.."
            class=" "
          ></b-form-input>
        </b-form-group>
        <!-- <div class="text-center">
        <b-button
          type="submit"
          variant="primary  text-white fw-bolder spacing_1"
          >Crea attività</b-button
        >
      </div> -->
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import { supportService } from "@/_services";
import { mapState, mapActions } from "vuex";
import SurveyShower from "@/components/attivita/SurveyShower.vue";
export default {
  name: "ActivityDetails",
  props: ["activity", "asAdmin", "level"],
  components: { SurveyShower },
  data() {
    return {
      surveys: null,
      filterSetted: {
        search: null,
        position_ref: null,
        page: 1,
        take: 50,
        activity: null,
      },
      selectedRow: null,
      modalTitle: null,
      editForm: {
        name: null,
        description: null,
      },
    };
  },
  computed: {
    validName: function () {
      var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      if (this.activity.name !== null && this.activity.name.length > 0) {
        return format.test(this.activity.name) ? false : true;
      }
      return false;
    },
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    openUpdate() {
      this.editForm = {
        name: this.activity.name,
        description: this.activity.description,
      };
      this.$bvModal.show("edit-ativity");
    },
    openDelete(item) {
      this.selectedItem = item;
      this.$bvModal.show("del-confirm");
    },
    doDelete() {
      this.$bvModal.hide("del-confirm");
      this.$emit("do-delete", this.activity.hashid);
    },
    doUpdate() {
      this.$bvModal.hide("edit-ativity");
      let data = {
        form: this.editForm,
        hashid: this.activity.hashid,
      };
      this.$emit("do-update", data);
    },

    fetchSurveys() {
      var self = this;
      supportService
        .getSurveys(this.filterSetted)
        .then(function (response) {
          self.surveys = response.data.data.data;
          self.filterSetted.page = response.data.data.current_page;
          // self.total = response.data.data.total;
          // self.fromPage = response.data.data.from;
          // self.toPage = response.data.data.to;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le attività");
          // console.log(err);
        });
    },
    downloadReport() {
      var self = this;
      supportService
        .reportActivityPdf(this.activity.hashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Report_" + self.activity.name + ".pdf";
          link.click();
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert(
            "Non è stato possibile scaricare il report l'attività"
          );
          // console.log(err);
        });
    },
    downloadXlsReport() {
      var self = this;
      supportService
        .reportActivityXls(this.activity.hashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Report_" + self.activity.name + ".xlsx";
          link.click();
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert(
            "Non è stato possibile scaricare il report l'attività"
          );
          // console.log(err);
        });
    },
    selectThis(row) {
      this.selectedRow = row;
      this.modalTitle = row.name;
      this.$refs["modal-showsurvey"].show();
    },
  },
  watch: {
    activity(val) {
      this.filterSetted.activity = val.name;
      this.filterSetted.take = val.tot_surveys + 1;
      this.fetchSurveys();
    },
  },
  created() {
    if (this.activity) {
      this.filterSetted.activity = this.activity.name;
      this.filterSetted.take = this.activity.tot_surveys + 1;
      this.fetchSurveys();
    }
    // console.log(this.activity);
  },
};
</script>
