<template>
  <div class="">
    <div class="d-flex justify-content-between">
      <router-link
        class="text-primary no__hover"
        :to="{ name: 'attivita', params: { section: 'questionari' } }"
      >
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Questionari
      </router-link>
    </div>
    <div class="px-4">
      <div class="py-3" v-if="template">
        <h4 class="text-prim-grad-1 text-left pb-1">
          Questionari {{ template.name }} ({{ template.activity.name }})
          somministrati
        </h4>

        <p class="text-justify text-medium-grey">
          Clicca sul bottone "vedi" per visualizzare l'esito del questionario.
          Per scaricare l'elenco completo in formato pdf, clicca su "scarica
          tutto in formato pdf".
        </p>
        <b-row
          cols="3"
          class="mb-3 flex-wrap align-items-center align-content-center px-0"
        >
          <b-col class="d-flex col-12 text-left my-2">
            <b-button
              variant="primary text-white "
              class="m-1 general_border"
              @click="getReport"
              :disabled="total == 0"
              >Scarica tutto in formato pdf</b-button
            >
            <b-button
              variant="primary text-white "
              class="m-1 general_border"
              @click="getReportXsl"
              :disabled="total == 0"
              >Scarica tutto in formato excel</b-button
            >
          </b-col>
          <b-col class="col-12 col-md-5 col-lg-4 text-right my-2 mr-0 ml-auto">
            <b-form-input
              v-if="template.type > 1"
              class="text-uppercase"
              placeholder="codice fiscale..."
              maxlength="16"
              minlength="16"
              type="search"
              name="cfsearch"
              v-model="filterSetted.cf"
            ></b-form-input>
          </b-col>
        </b-row>
        <div class="d-flex align-items-center">
          <b-form-group size="sm" class="text-left mr-3">
            <b-form-select
              class="align-items-center align-content-center border border-info text-info"
              name="vedi"
              v-model="filterSetted.take"
              :options="takeOptions"
            >
            </b-form-select>
          </b-form-group>
          <span class="mr-1" v-if="total > 0"
            >{{ fromPage }}-{{ toPage }} di
          </span>
          <span class=""> {{ total }}</span>
        </div>
        <div class="mt-2">
          <b-table
            borderless
            hover
            responsive
            table-variant="white"
            :fields="fields"
            :items="surveys"
            :small="$root.isMobile"
          >
            <template #cell(created_at)="row">
              <span class="text-left">{{
                row.item.created_at | moment("DD/MM/YYYY")
              }}</span>
            </template>
            <template #cell(user_mail)="row">
              <p class="mb-0">
                <template v-if="row.item.user_company_denominazione">
                  <span class="text-left">{{
                    row.item.user_company_denominazione
                  }}</span>
                  <br />
                </template>
                <template v-if="row.item.user_fullname">
                  <span class="text-left">{{ row.item.user_fullname }}</span>
                  -
                </template>
                <span class="text-left">{{
                  row.item.user_mail ? row.item.user_mail : "---"
                }}</span>
              </p>
            </template>
            <template #cell(azioni)="row">
              <b-button
                size="sm"
                variant="outline-primary general_border px-3 mr-1"
                @click="showThis(row)"
                >Vedi</b-button
              >
              <template
                v-if="
                  company.level === 1 &&
                  user.permessi.includes('gestire attività e questionari') &&
                  user.permessi.includes('somministrare questionari')
                "
              >
                <b-button
                  size="sm"
                  v-if="!row.item.created_by && !template.has_score"
                  variant="outline-primary general_border px-3 mr-1"
                  @click="
                    $router.push({
                      name: 'attivita',
                      params: {
                        quest: row.item.hashid,
                        section: 'questionario',
                        action: 'edit',
                      },
                    })
                  "
                  >Modifica</b-button
                >
                <b-button
                  size="sm"
                  v-if="!row.item.created_by && !template.has_score"
                  variant="outline-danger general_border px-3 mr-1"
                  @click="showDeleteThis(row)"
                  >Elimina</b-button
                >
              </template>
              <b-dropdown
                size="sm"
                right
                class="m-1 py-2"
                v-b-popover.hover.top="'Scarica'"
              >
                <template #button-content>
                  <b-icon icon="download" font-scale="1.6"></b-icon>
                </template>
                <b-dropdown-item
                  class="secondary-li-hover"
                  @click="downloadOne(row)"
                  >PDF Standard</b-dropdown-item
                >
                <b-dropdown-item
                  class="secondary-li-hover"
                  @click="downloadPatientVersion(row)"
                  >PDF per il paziente</b-dropdown-item
                >
              </b-dropdown>
            </template>
          </b-table>
          <p v-if="surveys && surveys.length < 1">Nessun questionario qui</p>
          <b-pagination
            v-model="filterSetted.page"
            :total-rows="total"
            :per-page="10"
            align="center"
            :size="$root.isMobile ? 'sm' : 'lg'"
          ></b-pagination>
          <b-modal
            ref="show-filleditem"
            centered
            hide-footer
            title="Questionario"
            size="xl"
          >
            <FilledSurveyShower
              v-if="selectedItem"
              :response="selectedItem.response"
              :score="selectedItem.score"
            />
          </b-modal>
          <b-modal
            id="confirm-delete"
            centered
            title="Confermi di voler eliminare il questionario?"
            ok-title="Sì, elimina"
            ok-variant="outline-danger"
            cancel-title="No, annulla"
            cancel-variant="outline-primary"
            footer-border-variant="white"
            @ok="deleteSelectedItem"
            @cancel="cancelDelete"
          >
            <h6 v-if="selectedItem" class="px-2 py-3">
              Se confermi, il questionario selezionato compilato in data
              {{ selectedItem.created_at | moment("DD/MM/YYYY") }} verrà
              eliminato definitivamente.
            </h6>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { supportService } from "@/_services";
import { mapState, mapActions } from "vuex";
import FilledSurveyShower from "@/components/attivita/FilledSurveyShower.vue";
export default {
  name: "ReportQuestionariCompilati",
  components: { FilledSurveyShower },
  data() {
    return {
      filterSetted: {
        take: 10,
        page: 1,
        cf: null,
      },
      surveys: null,
      total: 0,
      fromPage: 0,
      toPage: 0,
      fields: [
        { key: "created_at", label: "Data" },
        { key: "user_mail", label: "Utente" },
        // { key: "active", label: "Stato" },
        { key: "azioni", label: "" },
      ],
      takeOptions: [10, 25, 50, 100],
      template: null,
      selectedItem: null,
    };
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
    ...mapState("company", ["company"]),
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  watch: {
    filterSetted: {
      handler(prev, post) {
        if (prev.cf && prev.cf.length < 16) {
          return;
        }
        this.fetchData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    cancelDelete() {
      this.selectedItem = null;
      this.$bvModal.hide("confirm-delete");
    },
    fetchData() {
      var self = this;
      var dat = {
        hash: this.$route.params.quest,
        filters: this.filterSetted,
      };
      supportService
        .getFilledForSurvey(dat)
        .then(function (res) {
          // console.log(res);
          self.surveys = res.data.data.data.data;
          self.filterSetted.page = res.data.data.data.current_page;
          self.total = res.data.data.data.total;
          self.fromPage = res.data.data.data.from;
          self.toPage = res.data.data.data.to;
          self.template = res.data.data.survey;
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
    showThis(row) {
      this.selectedItem = row.item;
      this.$refs["show-filleditem"].show();
    },

    deleteSelectedItem() {
      const self = this;
      supportService
        .deleteFilledSurvey(this.selectedItem.hashid)
        .then(function (res) {
          self.successAlert("Questionario eliminato correttamente");
          self.fetchData();
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile eliminare il questionario");
        });
    },
    showDeleteThis(row) {
      this.selectedItem = row.item;
      this.$bvModal.show("confirm-delete");
    },
    getReport() {
      var self = this;
      supportService
        .surveyReport(this.$route.params.quest)
        .then(function (response) {
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            self.template.name + "_" + self.template.activity.name + ".pdf";
          link.click();
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
    getReportXsl() {
      var self = this;
      supportService
        .surveyXlsReport(this.$route.params.quest)
        .then(function (response) {
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            self.template.name + "_" + self.template.activity.name + ".xlsx";
          link.click();
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
    downloadOne(row) {
      var self = this;
      var selected = row;
      supportService
        .downloadFilledSurvey(selected.item.hashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            self.template.name + "_" + self.template.activity.name + ".pdf";
          link.click();
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
    downloadPatientVersion(row) {
      const self = this;
      const selected = row;
      supportService
        .downloadFilledSurveyPatientVersion(selected.item.hashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            "patient_" +
            selected.item.user_cf +
            "_" +
            self.template.name +
            ".pdf";
          link.click();
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
<style scoped></style>
