<template>
  <div v-if="user.type === 2">
    <transition-group name="list" mode="out-in" appear>
      <div
        v-if="!$route.params.section || $route.params.section === 'raccolte'"
        key="raccolte"
      >
        <AdminHomeAttivita />
      </div>
      <div
        v-else-if="
          $route.params.section === 'questionari' ||
          $route.params.section === 'questionari/nuovo'
        "
        key="questionari"
      >
        <template
          v-if="
            !$route.params.action &&
            $route.params.section !== 'questionari/nuovo'
          "
        >
          <AdminQuestionari />
        </template>
        <template v-else-if="$route.params.action === 'report'">
          <QuestionariCompilati />
        </template>
        <template v-else>
          <CreaQuestionario />
        </template>
      </div>
      <div
        v-else-if="
          $route.params.section === 'questionario' &&
          $route.params.action === 'modifica'
        "
        key="editquestionario"
      >
        <AggiornaQuestionario />
      </div>
      <div v-else key="crea"></div>
    </transition-group>
  </div>
</template>
<script>
import { mapState } from "vuex";
import AdminHomeAttivita from "@/components/admin/attivita/sections/AdminHomeAttivita.vue";
import CreaQuestionario from "@/components/admin/attivita/sections/CreaQuestionario.vue";
import AdminQuestionari from "@/components/admin/attivita/sections/AdminQuestionari.vue";
import AggiornaQuestionario from "@/components/admin/attivita/sections/AggiornaQuestionario.vue";
import QuestionariCompilati from "@/components/admin/attivita/sections/QuestionariCompilati.vue";

export default {
  name: "ScreeningMain",
  components: {
    QuestionariCompilati,
    AggiornaQuestionario,
    AdminQuestionari,
    AdminHomeAttivita,
    CreaQuestionario,
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
  created() {},
};
</script>
