<template>
  <div v-if="reservation && isEditable">
    <b-button
      @click="$bvModal.show('change_date_modal')"
      variant="outline-primary modal-footer-button"
    >
      Rischedula
    </b-button>
    <b-modal
      id="change_date_modal"
      hide-footer
      centered
      scrollable
      size="xl"
      class="pt-0"
      title="Richiedi modifica data/ora"
    >
      <b-form @submit.prevent="doUpdate" id="change_date_form" class="px-2">
        <b-row cols="1">
          <h6 class="mb-0 text-primary">
            Data e ora attualmente previste:
            <span class="text-medium-grey">
              {{ reservation.data_inizio | moment("DD.MM.YYYY HH: mm") }}
            </span>
          </h6>
          <h6 class="text-primary mt-3">Scegli nuova data ed ora*:</h6>
          <DatetimeSelection
            :isinsidemodal="true"
            :notitle="true"
            :company="reservation.company.hashid"
            :service="reservation.service.hashid"
            :restype="reservation.tipo_ordine"
            :hservice="hService"
            v-on:date-selected="handleDateSelection($event)"
          />
        </b-row>
        <b-row class="mt-4">
          <h6 class="text-primary">Aggiorna note</h6>
          <b-form-input
            type="text"
            v-model="form.notes"
            class="h-100 general_border"
          >
          </b-form-input>
        </b-row>
        <b-row class="mt-4 justify-content-end text-right">
          <b-col>
            <b-button
              @click="$bvModal.hide('change_date_modal')"
              variant="info"
              class="mr-3 modal-footer-button"
            >
              Annulla</b-button
            >
            <b-button
              type="submit"
              :disabled="!checkValidity"
              variant="primary"
              class="modal-footer-button"
              >Invia
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import * as moment from "moment/moment"
import { reservationService } from "@/_services"
import { mapState, mapActions } from "vuex"
import DatetimeSelection from "@/components/prenotazioni/form/DatetimeSelection.vue"
export default {
  name: "UpdateReservationDate",
  props: ["hash"],
  components: { DatetimeSelection },
  data() {
    return {
      form: {
        data_inizio: null,
        notes: null
      },
      reservation: null,
      hService: null
    }
  },
  computed: {
    checkValidity: function () {
      if (this.form && this.form.data_inizio) {
        var data = moment(this.form.data_inizio)
        return moment().isBefore(data)
      }
      return false
    },
    isEditable: function () {
      if (this.reservation) {
        var admittedStates = [1, 2, 3, 5, 6]
        // if (this.reservation.tipo_ordine !== 'prestazione') {
        //   admittedStates = [2, 3];
        // }
        return admittedStates.includes(this.reservation.status)
      }
      return false
    },
    ...mapState({
      alert: (state) => state.alert
    })
  },
  mounted() {
    if (this.hash) {
      this.getReservationDetails()
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    getReservationDetails() {
      var self = this
      reservationService
        .getReservationDetails(this.hash)
        .then(function (response) {
          if (!response.data.data.error) {
            self.reservation = response.data.data
            self.form.data_inizio = response.data.data.data_inizio
            if (
              response.data.data.orderable &&
              response.data.data.orderable.health_service
            ) {
              self.hService = response.data.data.orderable.health_service.hashid
            }
          }
          // else {
          //   self.showAbbError = true;
          // }
        })
        .catch(function (error) {
          self.errorAlert("non è stato possibile prelevare la richiesta")
          console.log(error)
        })
    },
    doUpdate() {
      var self = this
      var finalDate = moment(this.form.data_inizio).format("YYYY-MM-DD HH:mm")
      this.form.data_inizio = finalDate
      reservationService
        .requestReservationDateChange(this.form, this.reservation.hashid)
        .then(function (res) {
          self.$emit("change-requested")
          self.$bvModal.hide("change_date_modal")
        })
        .catch(function (err) {
          console.log(err)
          self.errorAlert("non è stato possibile inoltrare la richiesta")
        })
    },
    handleDateSelection(newDate) {
      // console.log(newDate)
      this.form.data_inizio = newDate
    }
  }
}
</script>
