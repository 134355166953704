<template>
  <div v-if="company">
    <!-- <CRoomHomeDashboard v-if="company.level ===5"></CRoomHomeDashboard>
    <CompanyHomeDashboard v-else></CompanyHomeDashboard> -->
    <CompanyHomeDashboard></CompanyHomeDashboard>
  </div>
  <b-spinner v-else></b-spinner>
</template>
<script>
import { mapState } from "vuex"
import CompanyHomeDashboard from "@/components/home/CompanyHomeDashboard.vue"
// import CRoomHomeDashboard from "@/components/home/CRoomHomeDashboard.vue"

export default {
  name: "HomePrenotazioni",
  components: {
    CompanyHomeDashboard,
    // CRoomHomeDashboard
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("company", ["company"]),
  },
}
</script>
