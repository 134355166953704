<template>
  <div class="pb-2" v-if="form !== null">
    <b-form @submit.prevent="submitAnagrafica" id="anagrafica_form">
      <div class="col-12 d-flex flex-wrap">
        <b-form-group label="Nome Titolare" label-for="name" class="text-left fs-6 text-font-grey mx-auto"
          :class="$root.isMobile ? '' : 'col'">
          <b-form-input class="my-3  text-capitalize" name="name" type="text" placeholder="nome..."
            v-model="form.nome" readonly required></b-form-input>
        </b-form-group>

        <b-form-group label="Cognome Titolare" label-for="surname" class="text-left fs-6 text-font-grey mx-auto"
          :class="$root.isMobile ? '' : 'col'">
          <b-form-input class="my-3  text-capitalize" name="surname" type="text" placeholder="cognome..."
            v-model="form.cognome" readonly required></b-form-input>
        </b-form-group>
      </div>
      <div class="col-12 d-flex flex-wrap">
        <b-form-group label="Denominazione" label-for="denomoinazione" class="text-left fs-6 text-font-grey mx-auto"
          :class="$root.isMobile ? '' : 'col'">
          <b-form-input class="my-3  text-capitalize" name="denomoinazione" type="text"
            placeholder="denomoinazione..." v-model="form.ff_denominazione" :readonly="noEdit" required></b-form-input>
        </b-form-group>

        <b-form-group label="Codice Titolare" label-for="codice_titolare" class="text-left fs-6 text-font-grey mx-auto"
          :class="$root.isMobile ? '' : 'col'">
          <b-form-input class="my-3 " name="codice_titolare" type="text" placeholder="codice titolare..."
            v-model="form.codice_titolare" readonly></b-form-input>
        </b-form-group>
      </div>
      <div class="col-12 d-flex flex-wrap">
        <b-form-group label="Codice asl" label-for="codice_asl" class="text-left fs-6 text-font-grey mx-auto"
          :class="$root.isMobile ? '' : 'col'">
          <b-form-input class="my-3 " name="codice_asl" type="text" placeholder="codice asl..."
            v-model="form.codice_asl" readonly></b-form-input>
        </b-form-group>

        <b-form-group label="Codice Tracciabilità" label-for="codice_tracciabilita"
          class="text-left fs-6 text-font-grey mx-auto" :class="$root.isMobile ? '' : 'col'">
          <b-form-input class="my-3 " name="codice_tracciabilita" type="text"
            placeholder="codice tracciabilità..." v-model="form.codice_tracciabilita" readonly></b-form-input>
        </b-form-group>
      </div>
      <div class="col-12 d-flex flex-wrap">
        <b-form-group label="Email" label-for="email" class="text-left fs-6 text-font-grey mx-auto"
          :class="$root.isMobile ? '' : 'col'">
          <b-form-input class="my-3 " name="email" type="email" placeholder="email..." v-model="form.email"
            readonly required></b-form-input>
        </b-form-group>
        <b-form-group label="Pec" label-for="emailpec" class="text-left fs-6 text-font-grey mx-auto"
          :class="$root.isMobile ? '' : 'col'">
          <b-form-input class="my-3 " name="emailpec" type="email" placeholder="pec..." v-model="form.emailpec"
            readonly></b-form-input>
        </b-form-group>
      </div>
      <div class="col-12 d-flex flex-wrap">
        <b-form-group label="Partita Iva" label-for="partita_iva" class="text-left fs-6 text-font-grey mx-auto"
          :class="$root.isMobile ? '' : 'col'">
          <b-form-input class="my-3 " name="partita_iva" type="text" placeholder="partita iva..."
            v-model="form.partita_iva" readonly required></b-form-input>
        </b-form-group>
        <b-form-group label="Codice Fiscale" label-for="codice_fiscale" class="text-left fs-6 text-font-grey mx-auto"
          :class="$root.isMobile ? '' : 'col'">
          <b-form-input class="my-3 " name="codice_fiscale" type="text" placeholder="codice fiscale..."
            v-model="form.codice_fiscale" readonly></b-form-input>
        </b-form-group>
      </div>
      <div class="col-12 d-flex flex-wrap">
        <b-form-group label="Telefono" label-for="telefono" class="text-left fs-6 text-font-grey mx-auto"
          :class="$root.isMobile ? '' : 'col'">
          <b-form-input class="my-3 " name="telefono" type="text" placeholder="telefono..."
            v-model="form.telefono" readonly minlength="8"></b-form-input>
        </b-form-group>
        <b-form-group label="Fax" label-for="fax" class="text-left fs-6 text-font-grey mx-auto"
          :class="$root.isMobile ? '' : 'col'">
          <b-form-input class="my-3 " name="fax" type="text" placeholder="sito web..." v-model="form.fax"
            readonly></b-form-input>
        </b-form-group>
      </div>
      <div class="col-12 d-flex flex-wrap">
        <b-form-group label="Regione" label-for="regione" class="text-left fs-6 text-font-grey mx-auto"
          :class="$root.isMobile ? 'px-0 col-12' : 'col'">
          <b-form-select class="my-3  text-capitalize" :options="regioni" name="regione"
            v-model="selected.regione" @change="getProvince(selected)" disabled required>
            <b-form-select-option :value="null">Seleziona Regione</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Provincia" label-for="provincia" class="text-left fs-6 text-font-grey mx-auto"
          :class="$root.isMobile ? 'px-0 col-12' : 'col'">
          <b-form-select class="my-3  text-capitalize" :options="province" name="provincia"
            v-model="selected.provincia" @change="getComuni(selected)" disabled required>
            <b-form-select-option :value="null">Seleziona Provincia</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </div>
      <div class="col-12 d-flex flex-wrap">
        <b-form-group label="Comune" label-for="comune" class="text-left fs-6 text-font-grey mx-auto"
          :class="$root.isMobile ? '' : 'col'">
          <b-form-select class="my-3  text-capitalize" name="comune" v-model="selected.comune" disabled
            required>
            <b-form-select-option v-for="(com, ind) in comuni" :key="ind" :value="com" @change="setDomicilioData"
              :selected="findComune(com)">{{ com.comune }}</b-form-select-option>
            <b-form-select-option :value="null">Seleziona Comune</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Frazione" label-for="frazione" class="text-left fs-6 text-font-grey mx-auto"
          :class="$root.isMobile ? '' : 'col'">
          <b-form-input class="my-3  text-capitalize" name="frazione" type="text" placeholder="frazione..."
            v-model="form.frazione" readonly></b-form-input>
        </b-form-group>
      </div>
      <div class="col-12 d-flex flex-wrap">
        <b-form-group label="Indirizzo e numero civico" label-for="indirizzo"
          class="text-left fs-6 text-font-grey mx-auto" :class="$root.isMobile ? '' : 'col'">
          <b-form-input class="my-3  text-capitalize" name="indirizzo" type="text" placeholder="indirizzo..."
            v-model="form.indirizzo" readonly required></b-form-input>
        </b-form-group>
        <b-form-group label="Cap" label-for="cap" class="text-left fs-6 text-font-grey mx-auto"
          :class="$root.isMobile ? '' : 'col'">
          <b-form-input class="my-3 " name="cap" type="text" placeholder="cap..." v-model="form.cap"
            minlength="5" maxlength="5" readonly required></b-form-input>
        </b-form-group>
      </div>
    </b-form>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "AnagraficaCompanyDF",
  props: [],
  computed: {
    ...mapState("utility", ["searching", "comuni", "province", "regioni"]),
    ...mapState("company", ["companyLoggedIn", "company"]),
    ...mapGetters({
      getCompany: "company/getCompany",
    }),
  },
  data() {
    return {
      form: null,
      selected: {
        regione: null,
        provincia: null,
        comune: null,
      },
      noEdit: false,
    };
  },
  methods: {
    ...mapActions("utility", ["getComuni", "getProvince", "getRegioni"]),
    ...mapActions("company", ["updateAnagrafica"]),
    setDomicilioData() {
      // console.log("on change", this.selected.comune);
      if (this.selected.comune) {
        this.form.comune = this.selected.comune.comune;
        this.form.provincia = this.selected.comune.provincia;
        this.form.regione = this.selected.comune.regione;
      }
    },
    findComune(comune) {
      if (this.form && this.form.comune) {
        if (comune.comune === this.form.comune) {
          this.selected.comune = comune;
          return true;
        }
      }
      return false;
    },
    setupPrevs() {
      if (this.form.regione) {
        this.selected.regione = this.form.regione;
        this.getProvince(this.selected);
      }
      if (this.form.provincia) {
        this.selected.provincia = this.form.provincia;
        this.getComuni(this.selected);
      }
    },
    handleAnnulla() {
      this.noEdit = !this.noEdit;
      this.setup();
      // console.log("this.getCompany", this.getCompany);
    },
    setup() {
      var data = this.company;
      this.form = data;
      this.noEdit = true;
      this.setupPrevs();
      // console.log("SETUP", this.form);
    },
    submitAnagrafica() {
      // console.log("Submitting ", this.form);
      var toIgnore = [
        "dottorfarma",
        "enabled",
        "ff_username",
        "hashid",
        "laravel_through_key",
      ];
      var dataToSend = {};
      var self = this;
      Object.keys(self.form).map(function (el, ind) {
        if (typeof self.form[el] !== "object" && !toIgnore.includes(el)) {
          dataToSend[el] = self.form[el];
        }
      });
      this.updateAnagrafica(dataToSend).then(function () {
        window.scrollTo(0, 0);
        self.noEdit = true;
      });
      // console.log("formdata after map", dataToSend);
    },
  },
  watch: {
    selected: {
      handler(val) {
        // console.log("selected changed");
        if (val.comune !== null) {
          this.form.comune = this.selected.comune.comune;
          this.form.provincia = this.selected.comune.provincia;
          this.form.regione = this.selected.comune.regione;
        }
      },
      deep: true,
    },
  },
  created() {
    if (this.company !== null) {
      this.setup();
    }
    this.getRegioni();
  },
};
</script>