<template>
  <div id="week-table-container">
    <b-table
      id="week-table"
      key="week-view"
      class="no-bold-th shadow_2 general_border_11"
      borderless
      small
      responsive
      table-variant="white "
      thead-tr-class="light__effect"
      :fixed="!$root.isMobile"
      :fields="fields"
      :items="items"
      sticky-header
    >
      <template #head(day0)="data">
        <p
          :title="'mostra solo lunedì ' + data.label"
          class="point light__effect px-3 w-100 mb-0"
          @click="showDayDetail(0)"
        >
        <span>lun</span>
         <br>
         <span class="mb-0 bigger__text">
          {{ formatHeadLabel(data.label) }}
        </span>
        </p>
      </template>
      <template #head(day1)="data">
        <p
          :title="'mostra solo martedì ' + data.label"
          class="point light__effect px-3 w-100 mb-0"
          @click="showDayDetail(1)"
        >
        <span>mar</span>
         <br>
         <span class="mb-0 bigger__text">
          {{ formatHeadLabel(data.label) }}
        </span>
        </p>
      </template>
      <template #head(day2)="data">
        <p
          :title="'mostra solo mercoledì ' + data.label"
          class="point light__effect px-3 w-100 mb-0"
          @click="showDayDetail(2)"
        >
        <span>mer</span>
         <br>
         <span class="mb-0 bigger__text">
          {{ formatHeadLabel(data.label) }}
        </span>
        </p>
      </template>
      <template #head(day3)="data">
        <p
          :title="'mostra solo giovedì ' + data.label"
          class="point light__effect px-3 w-100 mb-0"
          @click="showDayDetail(3)"
        >
        <span>gio</span>
         <br>
         <span class="mb-0 bigger__text">
          {{ formatHeadLabel(data.label) }}
        </span>
        </p>
      </template>
      <template #head(day4)="data">
        <p
          :title="'mostra solo venerdì ' + data.label"
          class="point light__effect px-3 w-100 mb-0"
          @click="showDayDetail(4)"
        >
        <span>ven</span>
         <br>
         <span class="mb-0 bigger__text">
          {{ formatHeadLabel(data.label) }}
        </span>
        </p>
      </template>
      <template #head(day5)="data">
        <p
          :title="'mostra solo sabato ' + data.label"
          class="point light__effect px-3 w-100 mb-0"
          @click="showDayDetail(5)"
        >
        <span>sab</span>
         <br>
         <span class="mb-0 bigger__text">
          {{ formatHeadLabel(data.label) }}
        </span>
        </p>
      </template>
      <template #head(day6)="data">
        <p
          :title="'mostra solo domenica ' + data.label"
          class="point light__effect px-3 w-100 mb-0"
          @click="showDayDetail(6)"
        >
        <span>dom</span>
         <br>
         <span class="mb-0 bigger__text">
          {{ formatHeadLabel(data.label) }}
        </span>
        </p>
      </template>
      <template #cell(day0)="row">
        <div
          class="my-1 py-1 general_border"
          v-for="(app, key) in row.item.day0"
          :key="'day0' + key"
          :class="
            calcResidueInCard(app) === 0
              ? `disabled__card`
              : `${cardVariant(app)} thicker__border`
          "
        >
          <p
            class="text-dark-grey mb-0 px-1 d-flex justify-content-around align-items-center"
            v-b-toggle="'day0-' + row.item.time + key"
          >
            <b-icon font-scale="1.3rem" :icon="icons[key]"></b-icon>
            <span
              v-if="app[0] && app[0].etichetta"
              class="text-uppercase text-dark-grey"
            >
              {{ app[0].etichetta }}
            </span>
            <span class="" style="font-size: 1.3rem">
              {{ Object.values(app).length }}
            </span>

            <b-icon font-scale="1.3rem" icon="arrow-down-circle-fill"></b-icon>
          </p>
          <b-collapse
            :id="'day0-' + row.item.time + key"
            class="mt-3 px-1"
            :visible="allVisible"
          >
            <p
              class="border-0 my-2 mb-0 point py-1"
              v-for="(element, ind) in app"
              :key="ind"
              :class="
                element.status > 0
                  ? colorStatus[element.status]
                  : 'rifiutato-annullato'
              "
              @click="setCurrentReserv(element)"
            >
              <b-icon icon="circle-fill"></b-icon>
              <span class="mx-1">
                {{ element.data_inizio | moment("HH:mm") }}
                <b-badge
                  v-if="element.unreadMessages"
                  class="fs_badge_xs"
                  variant="danger"
                  >{{ element.unreadMessages }}</b-badge
                >
              </span>
              <b-iconstack font-scale="1.3">
                <b-icon stacked icon="circle-fill" variant="info"></b-icon>
                <b-icon
                  stacked
                  icon="info"
                  variant="primary"
                  scale="1"
                ></b-icon>
              </b-iconstack>
            </p>
          </b-collapse>
        </div>
      </template>

      <template #cell(day1)="row">
        <div
          class="my-1 py-1 general_border"
          v-for="(app, key) in row.item.day1"
          :key="'day1' + key"
          :class="
            calcResidueInCard(app) === 0
              ? `disabled__card`
              : `${cardVariant(app)} thicker__border`
          "
        >
          <p
            class="text-dark-grey mb-0 px-1 d-flex justify-content-around align-items-center"
            v-b-toggle="'day1-' + row.item.time + key"
          >
            <b-icon font-scale="1.3rem" :icon="icons[key]"></b-icon>
            <span
              v-if="app[0] && app[0].etichetta"
              class="text-uppercase text-dark-grey"
            >
              {{ app[0].etichetta }}
            </span>
            <span class="" style="font-size: 1.3rem">
              {{ Object.values(app).length }}
            </span>

            <b-icon font-scale="1.3rem" icon="arrow-down-circle-fill"></b-icon>
          </p>
          <b-collapse
            :id="'day1-' + row.item.time + key"
            class="mt-3 px-1"
            :visible="allVisible"
          >
            <p
              class="border-0 my-2 mb-0 point py-1"
              v-for="(element, ind) in app"
              :key="ind"
              :class="
                element.status > 0
                  ? colorStatus[element.status]
                  : 'rifiutato-annullato'
              "
              @click="setCurrentReserv(element)"
            >
              <b-icon icon="circle-fill"></b-icon>
              <span class="mx-1">
                {{ element.data_inizio | moment("HH:mm") }}
                <b-badge
                  v-if="element.unreadMessages"
                  class="fs_badge_xs"
                  variant="danger"
                  >{{ element.unreadMessages }}</b-badge
                >
              </span>
              <b-iconstack font-scale="1.3">
                <b-icon stacked icon="circle-fill" variant="info"></b-icon>
                <b-icon
                  stacked
                  icon="info"
                  variant="primary"
                  scale="1"
                ></b-icon>
              </b-iconstack>
            </p>
          </b-collapse>
        </div>
      </template>

      <template #cell(day2)="row">
        <div
          class="my-1 py-1 general_border"
          v-for="(app, key) in row.item.day2"
          :class="
            calcResidueInCard(app) === 0
              ? `disabled__card`
              : `${cardVariant(app)} thicker__border`
          "
          :key="'day2' + key"
        >
          <p
            class="text-dark-grey mb-0 px-1 d-flex justify-content-around align-items-center"
            v-b-toggle="'day2-' + row.item.time + key"
          >
            <b-icon font-scale="1.3rem" :icon="icons[key]"></b-icon>
            <span
              v-if="app[0] && app[0].etichetta"
              class="text-uppercase text-dark-grey"
            >
              {{ app[0].etichetta }}
            </span>
            <span class="" style="font-size: 1.3rem">
              {{ Object.values(app).length }}
            </span>

            <b-icon font-scale="1.3rem" icon="arrow-down-circle-fill"></b-icon>
          </p>
          <b-collapse
            :id="'day2-' + row.item.time + key"
            class="mt-3 px-1"
            :visible="allVisible"
          >
            <p
              class="border-0 my-2 mb-0 point py-1"
              v-for="(element, ind) in app"
              :key="ind"
              :class="
                element.status > 0
                  ? colorStatus[element.status]
                  : 'rifiutato-annullato'
              "
              @click="setCurrentReserv(element)"
            >
              <b-icon icon="circle-fill"></b-icon>
              <span class="mx-1">
                {{ element.data_inizio | moment("HH:mm") }}
                <b-badge
                  v-if="element.unreadMessages"
                  class="fs_badge_xs"
                  variant="danger"
                  >{{ element.unreadMessages }}</b-badge
                >
              </span>
              <b-iconstack font-scale="1.3">
                <b-icon stacked icon="circle-fill" variant="info"></b-icon>
                <b-icon
                  stacked
                  icon="info"
                  variant="primary"
                  scale="1"
                ></b-icon>
              </b-iconstack>
            </p>
          </b-collapse>
        </div>
      </template>

      <template #cell(day3)="row">
        <div
          class="my-1 py-1 general_border"
          v-for="(app, key) in row.item.day3"
          :key="'day3' + key"
          :class="
            calcResidueInCard(app) === 0
              ? `disabled__card`
              : `${cardVariant(app)} thicker__border`
          "
        >
          <p
            class="text-dark-grey mb-0 px-1 d-flex justify-content-around align-items-center"
            v-b-toggle="'day3-' + row.item.time + key"
          >
            <b-icon font-scale="1.3rem" :icon="icons[key]"></b-icon>
            <span
              v-if="app[0] && app[0].etichetta"
              class="text-uppercase text-dark-grey"
            >
              {{ app[0].etichetta }}
            </span>
            <span class="" style="font-size: 1.3rem">
              {{ Object.values(app).length }}
            </span>

            <b-icon font-scale="1.3rem" icon="arrow-down-circle-fill"></b-icon>
          </p>
          <b-collapse
            :id="'day3-' + row.item.time + key"
            class="mt-3 px-1"
            :visible="allVisible"
          >
            <p
              class="border-0 my-2 mb-0 point py-1"
              v-for="(element, ind) in app"
              :key="ind"
              :class="
                element.status > 0
                  ? colorStatus[element.status]
                  : 'rifiutato-annullato'
              "
              @click="setCurrentReserv(element)"
            >
              <b-icon icon="circle-fill"></b-icon>
              <span class="mx-1">
                {{ element.data_inizio | moment("HH:mm") }}
                <b-badge
                  v-if="element.unreadMessages"
                  class="fs_badge_xs"
                  variant="danger"
                  >{{ element.unreadMessages }}</b-badge
                >
              </span>
              <b-iconstack font-scale="1.3">
                <b-icon stacked icon="circle-fill" variant="info"></b-icon>
                <b-icon
                  stacked
                  icon="info"
                  variant="primary"
                  scale="1"
                ></b-icon>
              </b-iconstack>
            </p>
          </b-collapse>
        </div>
      </template>

      <template #cell(day4)="row">
        <div
          class="my-1 py-1 general_border"
          v-for="(app, key) in row.item.day4"
          :key="'day4' + key"
          :class="
            calcResidueInCard(app) === 0
              ? `disabled__card`
              : `${cardVariant(app)} thicker__border`
          "
        >
          <p
            class="text-dark-grey mb-0 px-1 d-flex justify-content-around align-items-center"
            v-b-toggle="'day4-' + row.item.time + key"
          >
            <b-icon font-scale="1.3rem" :icon="icons[key]"></b-icon>
            <span
              v-if="app[0] && app[0].etichetta"
              class="text-uppercase text-dark-grey"
            >
              {{ app[0].etichetta }}
            </span>
            <span class="" style="font-size: 1.3rem">
              {{ Object.values(app).length }}
            </span>

            <b-icon font-scale="1.3rem" icon="arrow-down-circle-fill"></b-icon>
          </p>
          <b-collapse
            :id="'day4-' + row.item.time + key"
            class="mt-3 px-1"
            :visible="allVisible"
          >
            <p
              class="border-0 my-2 mb-0 point py-1"
              v-for="(element, ind) in app"
              :key="ind"
              :class="
                element.status > 0
                  ? colorStatus[element.status]
                  : 'rifiutato-annullato'
              "
              @click="setCurrentReserv(element)"
            >
              <b-icon icon="circle-fill"></b-icon>
              <span class="mx-1">
                {{ element.data_inizio | moment("HH:mm") }}
                <b-badge
                  v-if="element.unreadMessages"
                  class="fs_badge_xs"
                  variant="danger"
                  >{{ element.unreadMessages }}</b-badge
                >
              </span>
              <b-iconstack font-scale="1.3">
                <b-icon stacked icon="circle-fill" variant="info"></b-icon>
                <b-icon
                  stacked
                  icon="info"
                  variant="primary"
                  scale="1"
                ></b-icon>
              </b-iconstack>
            </p>
          </b-collapse>
        </div>
      </template>

      <template #cell(day5)="row">
        <div
          class="my-1 py-1 general_border"
          v-for="(app, key) in row.item.day5"
          :key="'day5' + key"
          :class="
            calcResidueInCard(app) === 0
              ? `disabled__card`
              : `${cardVariant(app)} thicker__border`
          "
        >
          <p
            class="text-dark-grey mb-0 px-1 d-flex justify-content-around align-items-center"
            v-b-toggle="'day5-' + row.item.time + key"
          >
            <b-icon font-scale="1.3rem" :icon="icons[key]"></b-icon>
            <span
              v-if="app[0] && app[0].etichetta"
              class="text-uppercase text-dark-grey"
            >
              {{ app[0].etichetta }}
            </span>
            <span class="" style="font-size: 1.3rem">
              {{ Object.values(app).length }}
            </span>

            <b-icon font-scale="1.3rem" icon="arrow-down-circle-fill"></b-icon>
          </p>
          <b-collapse
            :id="'day5-' + row.item.time + key"
            class="mt-3 px-1"
            :visible="allVisible"
          >
            <p
              class="border-0 my-2 mb-0 point py-1"
              v-for="(element, ind) in app"
              :key="ind"
              :class="
                element.status > 0
                  ? colorStatus[element.status]
                  : 'rifiutato-annullato'
              "
              @click="setCurrentReserv(element)"
            >
              <b-icon icon="circle-fill"></b-icon>
              <span class="mx-1">
                {{ element.data_inizio | moment("HH:mm") }}
                <b-badge
                  v-if="element.unreadMessages"
                  class="fs_badge_xs"
                  variant="danger"
                  >{{ element.unreadMessages }}</b-badge
                >
              </span>
              <b-iconstack font-scale="1.3">
                <b-icon stacked icon="circle-fill" variant="info"></b-icon>
                <b-icon
                  stacked
                  icon="info"
                  variant="primary"
                  scale="1"
                ></b-icon>
              </b-iconstack>
            </p>
          </b-collapse>
        </div>
      </template>

      <template #cell(day6)="row">
        <div
          class="my-1 py-1 general_border"
          v-for="(app, key) in row.item.day6"
          :key="'day6' + key"
          :class="
            calcResidueInCard(app) === 0
              ? `disabled__card`
              : `${cardVariant(app)} thicker__border`
          "
        >
          <p
            class="text-dark-grey mb-0 px-1 d-flex justify-content-around align-items-center"
            v-b-toggle="'day6-' + row.item.time + key"
          >
            <b-icon font-scale="1.3rem" :icon="icons[key]"></b-icon>
            <span
              v-if="app[0] && app[0].etichetta"
              class="text-uppercase text-dark-grey"
            >
              {{ app[0].etichetta }}
            </span>
            <span class="" style="font-size: 1.3rem">
              {{ Object.values(app).length }}
            </span>

            <b-icon font-scale="1.3rem" icon="arrow-down-circle-fill"></b-icon>
          </p>
          <b-collapse
            :id="'day6-' + row.item.time + key"
            class="mt-3 px-1"
            :visible="allVisible"
          >
            <p
              class="border-0 my-2 mb-0 point py-1"
              v-for="(element, ind) in app"
              :key="ind"
              :class="
                element.status > 0
                  ? colorStatus[element.status]
                  : 'rifiutato-annullato'
              "
              @click="setCurrentReserv(element)"
            >
              <b-icon icon="circle-fill"></b-icon>
              <span class="mx-1">
                {{ element.data_inizio | moment("HH:mm") }}
                <b-badge
                  v-if="element.unreadMessages"
                  class="fs_badge_xs"
                  variant="danger"
                  >{{ element.unreadMessages }}</b-badge
                >
              </span>
              <b-iconstack font-scale="1.3">
                <b-icon stacked icon="circle-fill" variant="info"></b-icon>
                <b-icon
                  stacked
                  icon="info"
                  variant="primary"
                  scale="1"
                ></b-icon>
              </b-iconstack>
            </p>
          </b-collapse>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { SERVIZI_VARIANTS } from "@/_utils/constants.js"

export default {
  name: "CalendarWeekComponent",
  props: {
    fields: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    allVisible: {
      type: Boolean,
      default: false
    },
    colorStatus: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      SERVIZI_VARIANTS: SERVIZI_VARIANTS,
      icons: {
        0: "bag",
        1: "camera-video",
        2: "file-ppt",
        3: "chat-text",
        4: "screwdriver",
        5: "droplet-fill",
        6: "flower1",
        7: "emoji-smile",
        8: "exclude",
        9: "egg-fried",
        10: "gem",
        11: "heart-fill",
        12: "hourglass-split",
        13: "journal-medical",
        14: "life-preserver",
        15: "lock-fill",
        16: "moon",
        17: "node-minus-fill",
        18: "x-diamond-fill",
        19: "cart-fill",
        20: "bug-fill",
        21: "brightness-high-fill",
        22: "bell-fill"
      }
    }
  },
  computed: {},
  methods: {
    showDayDetail(day) {
      this.$emit("show-day-detail", day)
    },
    setCurrentReserv(reserv) {
      this.$emit("detail-toshow", reserv)
    },
    calcResidueInCard(obj) {
      const notCanceled = Object.values(obj).filter(function (el) {
        return el.status > 0
      })
      const residual = Object.values(notCanceled).length
      // if (ref && residual === 0) {
      //   console.log("ref", ref)
      //   this.refsToUpdate.push(ref);
      // }
      return residual
    },
    cardVariant(obj) {
      if (Object.values(obj)[0] && Object.values(obj)[0].tipo_ordine) {
        return (
          "bg-" +
          this.SERVIZI_VARIANTS[Object.values(obj)[0].tipo_ordine] +
          "-light"
        )
      } else return ""
    },
    formatHeadLabel(label) {
      try {
        return label.split('/')[0]
      } catch (err) {
        return label
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.b-table-sticky-header {
  min-height: 60vh;
  max-height: 60vh;
}

#week-table-container {
  // overflow-y: auto;
  // max-height: 55vh;
}

td[role="cell"] {
  max-width: 30px !important;
  background-color: blue !important;
}

.rifiutato-annullato {
  color: rgba(253, 39, 75, 1) !important;
  border-radius: 3px;
}

.inoltrato-in-attesa {
  color: rgba(255, 151, 18, 1) !important;
}

.confermato {
  color: rgba(32, 80, 114, 1) !important;
}

.evaso {
  color: rgba(15, 148, 180, 1) !important;
}
</style>
