<template>
  <div v-if="scoreDetail">
    <template v-if="scoreDetail.default_references">
      <template v-for="ref in scoreDetail.default_references">
        <b-row :key="ref.hashid">
          <b-col class="text-right col-3 col-md-2 pr-0">
            <b-icon icon="square-fill" :variant="valueLevelColors[ref.threshold_level]"></b-icon>
             <strong v-if="ref.min_ref === ref.max_ref"> {{ ref.threshold_level < 0 ? "&le;" : "&ge;" }} {{ ref.max_ref *1 }}: </strong>
            <strong v-else> {{ ref.min_ref *1}} - {{ ref.max_ref *1 }}: </strong>
          </b-col>
          <b-col>
            <p>
              {{ ref.description }}
            </p>
          </b-col>
        </b-row>
      </template>
    </template>
  </div>
</template>
<script>
import { monitoringService } from "@/_services";
import { mapState, mapActions } from "vuex";
export default {
  name: "SoglieScore",
  props: ["score"],
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  data() {
    return {
      loading: false,
      scoreDetail: null,
      valueLevelColors: {
        0: "success",
        1: "sec-3",
        2: "warning",
        3: "danger",
      },
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      const self = this;
      monitoringService
        .getScoreDetails(this.score)
        .then(function (response) {
          self.scoreDetail = response.data.data;
          self.loading = false;
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          self.errorAlert("qualcosa è andata storta");
        });
    },
  },
};
</script>
