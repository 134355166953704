<template>
  <b-container fluid class="m-0 p-0">
    <b-row
      cols="1"
      cols-md="2"
      class="justify-content-bewteen pb-2"
      align-v="center"
    >
      <b-col class="align-self-end px-0">
        <h4 class="text-left py-1 mb-0">Patologie</h4>
      </b-col>
      <b-col class="">
        <p
          class="d-flex justify-content-center my-3 my-md-0 justify-content-md-end"
        >
          <b-button class="text-left" @click="$bvModal.show('new-pathology')">
            <b-icon icon="plus"></b-icon> nuova patologia
          </b-button>
        </p>
      </b-col>
    </b-row>
    <div class="my-3">
      <b-row
        align-v="stretch"
        cols="1"
        cols-sm="2"
        cols-lg="3"
        class="my-2 ml-n2 col-12 px-0 align-items-center align-content-center"
      >
        <b-col class="px-2 py-2" v-for="(pat, ind) in pathologies" :key="ind">
          <div
            class="d-flex text-left pl-2 py-3 col-12 puntofarmaCard general_border flex-column"
            :class="
              selectedPathology && pat.hashid === selectedPathology.hashid
                ? 'border border-primary border__2 bg-mdm-light-primary'
                : ' h-100'
            "
            @click="selectPathology(pat, false, ind)"
          >
            <div class="m-0 p-0 d-flex">
              <div
                class="m-0 p-0 d-none d-sm-flex flex-column justify-content-center align-items-center"
                style="flex: 0 0 100px; min-width: 0"
              >
                <b-icon
                  :icon="pathologyTypesIcons[pat.type]"
                  font-scale="2"
                ></b-icon>
                <span class="text-center text-capitalize fontsize075rem pt-2">{{
                  pat.tipo
                }}</span>
              </div>
              <!-- :class="'border-' + typesColors[pat.type]" -->
              <div class="m-0 p-0 pl-2" style="flex: 1">
                <h5
                  class="mb-0 pt-1 text-uppercase long__txt point"
                  @click="selectPathology(pat, true, ind)"
                >
                  {{ pat.name }}
                </h5>

                <!-- Riga per il tipo che spunta solo in visualizzazione mobile -->
                <p
                  class="d-sm-none mb-0 mt-auto pt-1 text-capitalize d-flex justify-items-between"
                >
                  <!-- :class="'text-' + typesColors[pat.type]" -->
                  <span class="mr-auto">{{ pat.tipo }}</span>
                </p>

                <p class="mb-0 mt-auto pt-1 d-flex justify-items-between">
                  <!-- :class="'text-' + typesColors[pat.type]" -->

                  <span class="mr-auto text-primary">
                    <span v-if="pat.start_at && !pat.end_at">
                      inizio: {{ formatDataOnlyNumbers(pat.start_at) }}
                    </span>
                    <span v-else-if="pat.start_at">
                      dal {{ formatDataOnlyNumbers(pat.start_at) }}
                    </span>

                    <span v-if="pat.end_at">
                      al {{ formatDataOnlyNumbers(pat.end) }}
                    </span>
                  </span>
                  <span
                    class="ml-auto point"
                    @click="selectPathology(pat, true, ind)"
                  >
                    <b-icon
                      icon="zoom-in"
                      font-scale="1.5"
                      variant="primary"
                    ></b-icon>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <template
            v-if="selectedPathology && pat.hashid === selectedPathology.hashid"
          >
            <b-row
              class="mt-3 justify-content-center"
              v-if="
                ($route.name == 'profilo sanitario' ||
                  $route.name == 'scheda paziente') &&
                $route.path != '/cittadino/profilosanitario/terapie/nuova'
              "
            >
              <b-button
                type="button"
                variant="secondary"
                class=""
                @click="goToNewTherapy"
                >Crea terapia per {{ selectedPathology.name }}</b-button
              >
            </b-row>
          </template>
        </b-col>
      </b-row>
      <!-- <b-row
        v-if="
          selectedPathology &&
          ($route.name == 'profilo sanitario' ||
            $route.name == 'scheda paziente') &&
          $route.path != '/cittadino/profilosanitario/terapie/nuova'
        "
      >
        <b-button
          type="button"
          variant="secondary"
          class=""
          @click="goToNewTherapy"
          >Crea terapia per {{ selectedPathology.name }}</b-button
        >
      </b-row> -->
    </div>
    <b-modal
      size="xl"
      id="new-pathology"
      centered
      hide-footer
      title="Nuova patologia"
    >
      <b-form @submit.prevent="onSubmit">
        <!-- <div class="col-12 d-flex flex-wrap justify-content-center"> -->
        <div class="general_border py-4 px-3 mb-2">
          <h6 class="text-prim-grad-1 text-left pb-1">Cerca patologia</h6>
          <div class="text-left">
            <!-- <b-form-radio-group
              label="Ricerca per"
              v-model="searchType"
              name="gender-options"
              class="d-flex my-3"
            >
              <b-form-radio :value="0">cerca malattia cronica</b-form-radio>
              <b-form-radio :value="1">cerca malattia rara</b-form-radio>
            </b-form-radio-group>
            <b-input-group label="Cerca " class="col-12 px-0 px-md-2 col-md-10">
              <b-form-input
                class=" "
                type="search"
                placeholder="Cerca patologia per nome o codice esenzione.."
                v-model="filters.term"
                @input="doSearch"
              >
              </b-form-input>
            </b-input-group>
            <b-form-group
              v-if="results.length > 0"
              label="Seleziona patologia"
              label-for="name"
              class="text-left fs-6 text-font-grey col-12 col-md-10 mb-2 mt-4"
            >
              <b-form-select
                class="my-3 "
                name="name"
                v-model="form.name"
              >
                <b-form-select-option
                  v-for="(res, ind) in results"
                  :key="ind"
                  :value="res.valore"
                  >{{ res.valore }}</b-form-select-option
                >
                <b-form-select-option :value="null">-- </b-form-select-option>
              </b-form-select>
            </b-form-group> -->
            <!-- <p class="text-dark-grey mt-4 mb-0 point" @click="notFound = true">
              <b-icon icon="info-circle"></b-icon> Non trovi la patologia?
              Inseriscila manualmente
            </p> -->
            <b-form-group
              label="Cerca "
              class="col-12 px-0 px-md-2 col-md-10"
              description="*fonte: classificazione ICD-10 aggiornata"
            >
              <b-form-input
                class=" "
                type="search"
                placeholder="Cerca patologia per nome o codice..."
                v-model="filters.term"
                v-debounce:500ms="doSearch"
                :debounce-events="'keyup'"
              >
              </b-form-input>
            </b-form-group>
            <b-form-group
              v-if="results.length > 0"
              label="Seleziona patologia"
              label-for="name"
              class="text-left fs-6 text-font-grey col-12 col-md-10 mb-2 mt-4"
            >
              <b-form-select
                v-if="searchType > 0"
                class="my-3"
                name="name"
                v-model="form.name"
              >
                <b-form-select-option
                  v-for="(res, ind) in results"
                  :key="ind"
                  :value="res.valore"
                  >{{ res.valore }}
                </b-form-select-option>
                <b-form-select-option :value="null">-- </b-form-select-option>
              </b-form-select>
              <b-form-select
                v-else
                class="my-3"
                name="name"
                v-model="icdSelected"
              >
                <b-form-select-option
                  v-for="(res, ind) in results"
                  :key="ind"
                  :value="res"
                  >{{ res.full_code }} {{ res.name }} ({{
                    res.category_name
                  }})</b-form-select-option
                >
                <b-form-select-option :value="null">-- </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </div>
        </div>
        <div v-if="form.name || notFound">
          <b-row cols="1" class="text-left mx-auto mt-3">
            <b-col>
              <b-form-group
                class="primary-label text-left"
                label="Nome patologia*"
              >
                <b-form-input
                  class="col-12"
                  v-model="form.name"
                  type="text"
                  required
                  placeholder="..."
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <p class="">Seleziona la tipologia*</p>
              <b-form-radio-group
                v-model="form.type"
                name="type"
                class="d-flex my-3"
              >
                <b-form-radio
                  v-for="(tipo, ind) in types"
                  :key="ind"
                  :value="tipo.value"
                  >{{ tipo.label }}
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
          </b-row>
          <b-row
            cols="1"
            cols-md="2"
            class="text-left mx-auto mt-3"
            v-if="form.type < 2"
          >
            <b-col>
              <b-form-group
                label="Data di inizio"
                label-for="start_at"
                class="text-left primary-label fs-6 mx-auto"
              >
                <b-form-input
                  class="my-3"
                  name="start_at"
                  type="date"
                  v-model="form.start_at"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col v-if="form.type === 1">
              <b-form-group
                label="Data di fine"
                label-for="end_at"
                class="text-left fs-6 primary-label mx-auto"
              >
                <b-form-input
                  class="my-3"
                  name="end_at"
                  type="date"
                  v-model="form.end_at"
                  :state="form.start_at == null ? null : validDate"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row cols="1" class="text-left mx-auto mt-3">
            <b-col class="" v-if="form.own && user.type === 0">
              <b-form-group
                label=""
                description="**se selezioni questa opzione soltanto tu potrai vedere questa patologia"
                label-for="private"
                class="text-left"
              >
                <b-form-checkbox
                  id="private"
                  v-model="form.private"
                  name="private"
                  variant="text-secondary"
                  :value="true"
                  :unchecked-value="false"
                >
                  visibile solo a me**
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col class="mt-2">
              <b-form-group
                label="Annotazioni"
                label-for="details"
                class="text-left primary-label mt-2"
              >
                <b-form-textarea
                  class=" "
                  name="details"
                  type="text"
                  v-model="form.details"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="mx-auto px-2 pb-4 text-right">
            <p class="text-left pl-4 mt-3">*Campi obbligatori</p>
            <b-button
              type="button"
              variant="info mr-3"
              class="modal-footer-button"
              @click.prevent="handleAnnulla"
              >Annulla</b-button
            >
            <b-button
              type="submit"
              class="modal-footer-button"
              :disabled="form.name == null || validDate === false"
              variant="outline-primary"
              >Salva
            </b-button>
          </div>
        </div>
      </b-form>
    </b-modal>
    <b-modal
      size="xl"
      id="edit-pathology"
      centered
      hide-footer
      :title="selectedPathology ? selectedPathology.name : ''"
    >
      <b-form>
        <div v-if="selectedPathology">
          <b-row cols="1" class="text-left mx-auto mt-3">
            <b-col v-if="!noEdit">
              <h6 class="text-left pb-1">Cerca patologia</h6>
              <!-- <b-form-radio-group
                label="Ricerca per"
                v-model="searchType"
                name="gender-options"
                class="d-flex my-3"
              >
                <b-form-radio :value="0"
                  >cerca malattia classificazione icd-10</b-form-radio
                >
                <b-form-radio :value="1">cerca malattia cronica</b-form-radio>
                <b-form-radio :value="2">cerca malattia rara</b-form-radio>
              </b-form-radio-group> -->
              <b-form-group
                label="Cerca "
                class="col-12 px-0 px-md-2 col-md-10 mb-3"
                description="*fonte: classificazione ICD-10 aggiornata"
              >
                <b-form-input
                  class=" "
                  type="search"
                  placeholder="Cerca patologia per nome o codice..."
                  v-model="filters.term"
                  v-debounce:500ms="doSearch"
                  :debounce-events="'keyup'"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group
                v-if="results.length > 0"
                label="Seleziona patologia"
                label-for="name"
                class="text-left fs-6 col-12 col-md-10 mb-2 pb-2 mt-2"
              >
                <b-form-select
                  v-if="searchType > 0"
                  class="my-3"
                  name="name"
                  v-model="selectedPathology.name"
                >
                  <b-form-select-option
                    v-for="(res, ind) in results"
                    :key="ind"
                    :value="res.valore"
                    >{{ res.valore }}
                  </b-form-select-option>
                  <b-form-select-option :value="null">-- </b-form-select-option>
                </b-form-select>
                <b-form-select
                  v-else
                  class="my-3"
                  name="name"
                  v-model="icdSelected"
                >
                  <b-form-select-option
                    v-for="(res, ind) in results"
                    :key="ind"
                    :value="res"
                    >{{ res.full_code }} {{ res.name }}({{
                      res.category_name
                    }})</b-form-select-option
                  >
                  <b-form-select-option :value="null">-- </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Nome patologia*" class="text-left">
                <b-form-input
                  class="col-12"
                  v-model="selectedPathology.name"
                  type="text"
                  required
                  placeholder="..."
                  :readonly="noEdit"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col v-if="noEdit && selectedPathology.classificazione">
              <h6 class="text-left pb-1 pt-3">
                Codice Patologia (ICD-10):
                {{ selectedPathology.classificazione }}
              </h6>
            </b-col>
            <b-col class="mt-2">
              <p class="fw-bolder">Tipologia {{ !noEdit ? "*" : "" }}</p>
              <b-form-radio-group
                v-model="selectedPathology.type"
                name="type"
                class="d-flex my-3"
                :readonly="noEdit"
                :disabled="noEdit"
              >
                <b-form-radio
                  v-for="(tipo, ind) in types"
                  :key="ind"
                  :value="tipo.value"
                  >{{ tipo.label }}
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
          </b-row>
          <b-row
            cols="1"
            cols-md="2"
            class="text-left mx-auto mt-3"
            v-if="selectedPathology.type < 2"
          >
            <b-col>
              <b-form-group
                label="Data di inizio"
                label-for="start_at"
                class="text-left fs-6 mx-auto"
              >
                <b-form-input
                  class="my-3"
                  name="start_at"
                  type="date"
                  v-model="selectedPathology.start_at"
                  :readonly="noEdit"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col v-if="selectedPathology.type === 1">
              <b-form-group
                label="Data di fine"
                label-for="end_at"
                class="text-left fs-6 mx-auto"
              >
                <b-form-input
                  class="my-3 bg-white"
                  name="end_at"
                  type="date"
                  v-model="selectedPathology.end_at"
                  :readonly="noEdit"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row cols="1" class="text-left mx-auto mt-3">
            <b-col class="" v-if="selectedPathology.own && user.type === 0">
              <b-form-group
                label=""
                description="**se selezioni questa opzione soltanto tu potrai vedere questa patologia"
                label-for="private"
                class="text-left"
              >
                <b-form-checkbox
                  id="private"
                  v-model="selectedPathology.private"
                  name="private"
                  :value="1"
                  :unchecked-value="0"
                  :readonly="noEdit"
                  :disabled="noEdit"
                >
                  visibile solo a me**
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col class="mt-2">
              <b-form-group
                label="Annotazioni"
                label-for="details"
                class="text-left mt-2"
              >
                <b-form-textarea
                  class=" "
                  name="details"
                  type="text"
                  v-model="selectedPathology.details"
                  :readonly="noEdit"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <div
            class="mx-auto px-2 pb-4 mb-4 text-right mt-4"
            v-if="selectedPathology.own && noEdit"
          >
            <b-button
              type="button"
              variant="outline-primary m-2 modal-body-button "
              @click.prevent="noEdit = false"
              >Modifica</b-button
            >
          </div>
          <div class="mx-auto px-2 pb-4 text-right" v-if="!noEdit">
            <p class="text-left pl-4 mt-3">*Campi obbligatori</p>
            <b-button
              type="button"
              variant="info mr-3 modal-footer-button "
              @click.prevent="handleAnnulla"
              >Annulla</b-button
            >
            <b-button
              type="button"
              @click.prevent="doUpdate"
              :disabled="selectedPathology.name == null"
              variant="outline-primary modal-footer-button "
              >Salva
            </b-button>
          </div>
          <h6 class="pl-3 mb-0 mt-1 text-capitalize">
            inserita il {{ formatData(selectedPathology.created_at) }}
          </h6>
        </div>
      </b-form>
    </b-modal>
  </b-container>
</template>
<script>
import * as moment from "moment/moment";
import { utilityService, cittadinoService } from "@/_services";
import { mapState, mapActions } from "vuex";
export default {
  name: "PatologieComponent",
  props: ["subject"],
  data() {
    return {
      pathologyTypesIcons: ["calendar4", "lightning", "brightness-high"],
      filters: {
        term: null,
      },
      searchType: 0,
      results: [],
      form: {
        name: null,
        type: 0,
        start_at: null,
        end_at: null,
        details: null,
        own: true,
        private: false,
        subjectable_id: null,
        subjectable_type: null,
      },
      types: [
        { value: 0, label: "cronica" },
        { value: 1, label: "acuta" },
        { value: 2, label: "stagionale" },
      ],
      notFound: false,
      pathologies: null,
      // typesColors: ["ter-4", "ter-1", "ter-2"],
      selectedPathology: null,
      noEdit: true,
      patIndex: null,
      icdSelected: null,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
    validDate() {
      if (this.form.end_at == null || this.form.end_at == "") {
        return null;
      }
      return moment(this.form.start_at).isSameOrBefore(moment(this.form.end_at))
        ? null
        : false;
    },
  },
  watch: {
    icdSelected: {
      handler(val) {
        // console.log(val);
        if (this.noEdit === true) {
          // console.log("No edit true");
          this.form.name = val.name + "(" + val.category_name + ")";
          this.form.icd10_pathology_id = val.hashid;
        } else if (this.selectedPathology) {
          // console.log("updating", val);
          this.selectedPathology.name =
            val.name + "(" + val.category_name + ")";
          this.selectedPathology.classificazione_id = val.hashid;
          // console.log(this.selectedPathology);
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    searchIcd() {
      var self = this;
      utilityService
        .searchPatologiaIcd(this.filters)
        .then(function (response) {
          self.results = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
          self.results = [];
        });
    },
    formatData(data) {
      return moment(data).format("DD MMM YYYY");
    },
    formatDataOnlyNumbers(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    doSearch() {
      if (this.searchType === 0) {
        this.searchIcd();
      } else if (this.searchType === 1) {
        this.searchCronica();
      } else {
        this.searchRara();
      }
    },
    searchRara() {
      var self = this;
      utilityService
        .searchPatologiaRara(this.filters)
        .then(function (response) {
          self.results = response.data.resultObj;
        })
        .catch(function (error) {
          console.log(error);
          self.results = [];
        });
    },
    searchCronica() {
      var self = this;
      utilityService
        .searchPatologiaCronica(this.filters)
        .then(function (response) {
          self.results = response.data.resultObj;
        })
        .catch(function (error) {
          console.log(error);
          self.results = [];
        });
    },
    selectPathology(pathology, doShow, ind) {
      this.selectedPathology = pathology;
      if (doShow) {
        this.$bvModal.show("edit-pathology");
      }
      this.patIndex = ind;
      this.$emit("selected-pathology", pathology);
    },
    onSubmit() {
      var self = this;
      cittadinoService
        .storePathology(this.form)
        .then(function (response) {
          self.successAlert("Patologia memorizzata con successo!");
          self.$bvModal.hide("new-pathology");
          self.fetchPathologies();
          self.clearForm();
        })
        .catch(function (error) {
          var msg = "Non è stato possibile salvare la patologia";
          console.log(error);
          self.errorAlert(msg);
          self.$bvModal.hide("new-pathology");
        });
    },
    doUpdate() {
      var self = this;
      cittadinoService
        .updatePathology(this.selectedPathology)
        .then(function (response) {
          self.successAlert("Modifiche memorizzate con successo!");
          self.$bvModal.hide("edit-pathology");
          self.fetchPathologies();
          self.clearForm();
          self.noEdit = true;
        })
        .catch(function (error) {
          var msg = "Non è stato possibile salvare le modifiche";
          console.log(error);
          self.errorAlert(msg);
          self.$bvModal.hide("edit-pathology");
          self.noEdit = true;
        });
    },
    fetchPathologies() {
      if (this.subject) {
        return this.fetchUserPathologies();
      }
      this.fetchOwnPathologies();
    },
    fetchOwnPathologies() {
      var self = this;
      cittadinoService
        .getPathologies()
        .then(function (response) {
          self.pathologies = response.data.data;
          if (self.$route.query.pax != undefined) {
            var ndx = parseInt(self.$route.query.pax);
            self.hasPathology = 1;
            self.selectPathology(response.data.data[ndx], false, ndx);
          }
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le patologie");
        });
    },
    fetchUserPathologies() {
      var self = this;
      var filters = {
        user: this.subject.hashid,
      };
      cittadinoService
        .getPathologies(filters)
        .then(function (response) {
          self.pathologies = response.data.data;
          if (self.$route.query.pax != undefined) {
            var ndx = parseInt(self.$route.query.pax);
            self.hasPathology = 1;
            self.selectPathology(response.data.data[ndx], false, ndx);
          }
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le patologie");
        });
    },
    clearForm() {
      this.form.name = null;
      this.form.details = null;
      this.filters.term = null;
      this.form.start_at = null;
      this.form.end_at = null;
      this.notFound = false;
    },
    handleAnnulla() {
      if (!this.noEdit) {
        this.noEdit = true;
        this.selectedPathology = null;
        return this.$bvModal.hide("edit-pathology");
      }
      this.$bvModal.hide("new-pathology");
      this.clearForm();
    },
    goToNewTherapy() {
      var destination = {
        path: "/cittadino/profilosanitario/terapie/nuova",
        query: {
          pax: this.patIndex,
        },
      };
      if (this.subject) {
        destination = {
          path: "/operatore/pazienti/terapia",
          query: {
            sub: this.subject.hashid,
            type: this.subject.type,
            pax: this.patIndex,
          },
        };
      }
      this.$router.push(destination);
    },
  },
  created() {
    if (this.subject) {
      this.form.subjectable_id = this.subject.hashid;
      this.form.subjectable_type = this.subject.type;
      this.form.own = false;
    }
    this.fetchPathologies();
  },
  mounted() {
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      // console.log("Modal is about to be shown", bvEvent, modalId);
      this.clearForm();
    });
  },
};
</script>
<style>
.border__2 {
  border-width: 2px !important;
}
</style>
