<template>
  <div style="max-width: 800px">
    <template v-if="step === 1">
      <p class="text-left" v-if="!fullResponse">
        Inserisci il codice fiscale o l'email di registrazione del paziente per
        avviare la ricerca.
      </p>

      <b-container
        v-if="step === 1"
        class="pt-3 pb-4 mx-0 w-100 bg-whitesmoke general_border"
        :class="{ 'mt-3': fullResponse }"
      >
        <b-row class="justify-content-start px-0 w-100">
          <b-col class="col-9 px-0">
            <b-form-group
              label="Cerca utente per codice fiscale"
              label-for="cf"
              class="text-left m-0 fs-6 text-secondary mx-auto"
            >
              <b-form-input
                :size="$root.isMobile || $root.isTablet ? 'sm' : ''"
                class="general_border px-3 text-color-1"
                name="cf"
                placeholder="Codice Fiscale"
                maxlength="16"
                minlength="16"
                v-model="params.cf"
                :state="params.cf == null ? null : validCf"
                @input="
                  (v) => {
                    params.cf = v.toUpperCase();
                    this.nullCf();
                  }
                "
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col
            class="col-3 d-flex align-self-end text-left"
            :class="$root.isMobile ? 'px-1' : 'px-3'"
          >
            <b-button
              class="height: 100%"
              :size="$root.isMobile || $root.isTablet ? 'sm' : ''"
              variant="secondary text-white  general_border"
              :disabled="params.cf == null ? true : !validCf"
              @click="searchUser"
              >Cerca</b-button
            >
          </b-col>
        </b-row>
        <b-row
          v-if="!fullResponse"
          class="justify-content-start pt-4 px-0 w-100"
        >
          <b-col class="col-9 px-0">
            <b-form-group
              label="Cerca utente per email"
              label-for="email"
              class="text-left fs-6 my-0 text-secondary mx-auto"
            >
              <b-form-input
                class="bor_1 general_border px-3 text-color-1"
                :size="$root.isMobile || $root.isTablet ? 'sm' : ''"
                name="email"
                type="email"
                placeholder="E-mail"
                v-model="params.email"
                :state="params.email == null ? null : validEmail"
                @input="nullEmail"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col
            class="col-3 align-self-end text-left"
            :class="$root.isMobile ? 'px-1' : 'px-3'"
          >
            <b-button
              :size="$root.isMobile || $root.isTablet ? 'sm' : ''"
              variant="secondary text-white  general_border"
              :disabled="params.email == null ? true : !validEmail"
              @click="searchUser"
              >Cerca</b-button
            >
          </b-col>
        </b-row>
      </b-container>
    </template>

    <b-container
      :class="fullResponse ? 'puntofarmaCard p-2' : 'mt-4 p-2'"
      v-else-if="step === 2"
    >
      <b-row v-if="!notFound && searchResult" class="justify-content-center">
        <b-col class="col-12 text-center my-4">
          <h5>
            <span> Trovato un utente con </span>
            <span v-if="params.email">
              email <strong>{{ params.email }}</strong>
            </span>
            <span v-if="params.cf">
              codice fiscale <strong>{{ params.cf }}</strong>
            </span>
            <br />
            <br />
            <span class="text-capitalize font-weight-bolder"
              >{{ searchResult.anagrafica.name }}
              {{ searchResult.anagrafica.surname }}</span
            >
          </h5>
        </b-col>
        <div v-if="searchResult.is_my_patient">
          <b-col class="col-12 d-flex justify-content-center my-3">
            <b-button
              class="mx-3"
              variant="info modal-footer-button"
              @click="step = 1"
              >Annulla</b-button
            >
            <b-button
              class="mx-2"
              variant="outline-primary modal-footer-button"
              @click="sendInvite"
              >{{ btnText }}</b-button
            >
          </b-col>
        </div>
        <div v-else>
          <template v-if="!canProceed">
            <OtpValidation
              :user="searchResult"
              @otp-validated="handleOtpValidation($event)"
              class="text-center"
            >
              <template v-slot:instruction>
                <h6 class="text-left">
                  Per accedere alla scheda di questo paziente è necessario
                  ottenere il suo consenso.
                  <br />
                  Clicca su "invia codice di verifica" per inviare il codice OTP
                  di accesso via SMS al numero del paziente.
                </h6>
              </template>
            </OtpValidation>
            <div>
              <p class="my-2">oppure</p>
              <b-button
                class="mx-3"
                variant="info modal-footer-button"
                @click="step = 1"
                >Annulla e torna indietro</b-button
              >
            </div>
          </template>
          <b-col v-else class="col-12 d-flex justify-content-end my-3">
            <b-button
              class="mx-3"
              variant="info modal-footer-button"
              @click="step = 1"
              >Annulla</b-button
            >
            <b-button
              class="mx-2"
              variant="outline-primary modal-footer-button"
              @click="sendInvite"
              >{{ btnText }}</b-button
            >
          </b-col>
        </div>
      </b-row>
      <b-row v-else :class="!fullResponse ? 'col-12 text-center' : 'mt-2'">
        <h5 class="w-100 col" :class="{ 'text-center': !fullResponse }">
          Nessun utente trovato.
        </h5>
        <b-col
          class="col-12 d-flex my-3"
          :class="{ 'justify-content-center': !fullResponse }"
        >
          <b-button
            class="mx-3"
            variant="info modal-footer-button "
            @click="step = 1"
            >Annulla</b-button
          >
          <b-button
            class="mx-2"
            variant="outline-primary modal-footer-button"
            @click="sendInvite"
            >Registra</b-button
          >
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { utilityService } from "@/_services";
import OtpValidation from "@/components/utilities/OtpValidation.vue";
export default {
  name: "SearchUtente",
  props: {
    type: {},
    fullResponse: { default: false },
    custombtn: { default: undefined },
  },
  components: { OtpValidation },
  data() {
    return {
      step: 1,
      params: {
        cf: null,
        email: null,
      },
      searchResult: null,
      notFound: false,
      canProceed: false,
    };
  },
  computed: {
    validEmail() {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return this.params.email.match(validRegex) ? true : false;
    },
    validCf() {
      var validRegex =
        /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;
      return this.params.cf.match(validRegex) ? true : false;
    },
    btnText() {
      if (this.custombtn) {
        return this.custombtn;
      }
      if (this.fullResponse) {
        return "Seleziona paziente";
      }
      return "Vai a scheda paziente";
    },
  },
  methods: {
    searchUser() {
      this.notFound = false;
      this.searchResult = null;
      var self = this;
      utilityService
        .searchUser(this.params)
        .then(function (res) {
          self.searchResult = res.data.data;
          self.step = 2;
          self.$bvModal.show("member-found");
        })
        .catch(function (err) {
          self.step = 2;
          self.$bvModal.show("member-found");
          self.notFound = true;
        });
    },
    sendInvite() {
      if (this.searchResult) {
        this.$emit("invite-user", this.searchResult.hashid);
        this.$emit("select-user", this.searchResult);
      } else {
        this.$emit("arruola-user", this.params);
      }
      this.$bvModal.hide("member-found");
    },
    nullCf() {
      if (this.params.cf.length == 0) {
        this.params.cf = null;
      } else {
        this.params.email = null;
      }
    },
    nullEmail() {
      if (this.params.email.length == 0) {
        this.params.email = null;
      } else {
        this.params.cf = null;
      }
    },
    handleOtpValidation(isValid) {
      this.canProceed = isValid;
      if (isValid) {
        const self = this;
        utilityService
          .setAssistito(this.searchResult.hashid)
          .then(function (res) {
            self.sendInvite();
          })
          .catch(function (res) {
            self.sendInvite();
          });
        //  this.sendInvite();
      }
    },
  },
};
</script>
