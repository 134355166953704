<template>
    <b-badge v-if="counter" class="fs_badge" variant="danger">{{ counter }}</b-badge>
 </template>
<script>
import { reservationService } from "@/_services";
export default {
  name: "UnreadChatBadge",
  props: ["hashid", "updateCounter"],
  data() {
    return {
      counter: null,
      filters: {
        unread: true,
      },
    };
  },
  watch: {
    updateCounter: function (val) {
      this.fetchData();
    }
  },
  methods: {
    fetchData() {
      var self = this;
      reservationService
        .getReservationMessage(this.hashid, this.filters)
        .then(function (response) {
          self.counter = response.data.data.count;
        })
        .catch(function (error) {
          console.log("error fetfetchDatach prenotazioni", error);
        });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
