<template>
  <b-container fluid class="pb-4">
    <h4 class="text-prim-grad-1 text-left py-1 mb-0 page-title">
      Prenota un appuntamento in ASL
    </h4>
    <div v-if="reservationConfirmed && newReservation">
      <div class="">
        <div class="bg-white general_border shadow_2 p-4">
          <h5
            class="text-center d-flex align-items-center justify-content-center mb-4"
          >
            <b-icon
              icon="check-circle"
              variant="secondary"
              font-scale="2"
              class="mr-2"
            ></b-icon
            >Prenotazione inoltrata
          </h5>
          <h6 class="text-left py-2">
            Data prenotazione:
            {{ newReservation.data_inizio | moment("DD/MM/YY HH:mm") }}
          </h6>
          <div
            class="pb-3 text-left"
            v-if="
              newReservation.resources && newReservation.resources.length > 0
            "
          >
            <h6 class="text-left pt-2">Documenti Scaricabili</h6>
            <p class="text-left">
              Sono presenti documenti correlati a questa prenotazione, puoi
              visualizzarli cliccando su ciascuno di essi. <br />
              I documenti contenenti campi da compilare vanno scaricati,
              stampati, compilati e presentati all'appuntamento.
            </p>
            <a
              v-for="(allegato, ind) in newReservation.resources"
              :key="ind"
              :href="allegato.full_path"
              target="__blank"
              class="mr-1"
            >
              <b-badge
                variant="primary"
                class="my-2 py-2 px-3"
                v-b-tooltip.hover
                :title="'apri ' + allegato.name + ' in un\'altra scheda'"
              >
                {{ allegato.name }}
                <b-icon font-scale="1.3" icon="download" class="pl-1"></b-icon>
              </b-badge>
            </a>
          </div>

          <div
            class="pb-3 text-left"
            v-if="
              newReservation.tipo_ordine === 'prestazione' &&
              newReservation.orderable.health_service.custom_notification_text
            "
          >
            <h6 class="text-left pt-2">Raccomandazioni</h6>
            <p class="text-left">
              {{
                newReservation.orderable.health_service.custom_notification_text
              }}
            </p>
          </div>
        </div>
        <div class="col-12 text-center mt-4">
          <router-link
            :to="{ name: 'appuntamentiASL', params: { section: 'lista' } }"
          >
            <b-button
              :size="$root.isMobile ? 'sm' : ''"
              variant="outline-primary m-2"
            >
              Vai allo storico
            </b-button>
          </router-link>
          <b-button
            :size="$root.isMobile ? 'sm' : ''"
            variant="primary  m-2"
            @click="downloadCalendar"
            >Scarica calendar
          </b-button>
          <b-button
            :size="$root.isMobile ? 'sm' : ''"
            variant="primary  m-2"
            @click="downloadPdf"
            >Scarica promemoria
          </b-button>
        </div>
      </div>
      <!-- <h2>
        Prenotazione
        <span v-if="selectedPrestazione">
          {{ selectedPrestazione.name }}
        </span>
        conclusa
      </h2>
      <div>
        <h6
          class="text-prim-grad-1 text-left pb-1"
        >
          Riepilogo
        </h6>
        <div>
          <div class="mt-2">
            <p class="text-left text-capitalize">
              Tipo ordine: {{ form.type }}
              <strong v-if="selectedPrestazione">
                {{ selectedPrestazione.name }}</strong
              >
            </p>
            <p class="text-left">
              Data selezionata:
              <strong>
                {{ form.data_inizio | moment("DD/MM/YY HH:mm") }}</strong
              >
            </p>
          </div>
        </div>
        <div
          class="pb-3 text-left"
          v-if="newReservation.resources && newReservation.resources.length > 0"
        >
          <h6
            class="text-prim-grad-1 text-left pb-1 border-bottom border-line-grey"
          >
            Documenti Scaricabili
          </h6>
          <p class="text-left">
            Sono presenti documenti correlati a questa prenotazione, puoi
            visualizzarli cliccando su ciascuno di essi. I documenti contenenti
            campi da compilare vanno scaricati, stampati, compilati e presentati
            in sede di appuntamento.
          </p>
          <a
            v-for="(allegato, ind) in newReservation.resources"
            :key="ind"
            :href="allegato.full_path"
            target="__blank"
            class="mr-1"
          >
            <b-badge
              variant="primary"
              class="my-2 py-1 px-2"
              v-b-tooltip.hover
              :title="'apri ' + allegato.name + ' in un\'altra scheda'"
              >{{ allegato.name }}</b-badge
            >
          </a>
        </div>
        <div class="col-12 text-center">
          <b-button
            variant="primary   m-2"
            @click="downloadCalendar"
            >Scarica calendar
          </b-button>
          <b-button variant="primary   m-2" @click="downloadPdf"
            >Scarica promemoria
          </b-button>
        </div>
      </div> -->
    </div>
    <b-container fluid class="pt-2 m-0" v-else>
      <b-row cols="1" class="my-1 py-2">
        <div class="p-4 puntofarmaCard">
          <h4 class="text-prim-grad-1 text-left pb-1 pl-4">
            ASL di competenza
          </h4>
          <AslCard @selected-asl="handleSelectedAsl($event)"></AslCard>
          <b-row cols="1" class="mt-4 mb-2 mx-0 col-12 col-md-8">
            <PrestazioneSelection
              v-if="selectedCompany"
              :company="selectedCompany"
              v-on:selected-prestazione="setRequestPrestazione($event)"
              :preselected="selectedPrestazione"
            />
          </b-row>
        </div>
      </b-row>

      <b-row
        cols="1"
        v-if="showSurvey && surveyToFill"
        class="my-2"
        ref="surveystep"
      >
        <h5 class="text-left pb-1">Compila il format di prenotazione</h5>
        <b-col class="puntofarmaCard py-4">
          <FillAslSurvey
            :survey="surveyToFill.template"
            v-on:end-quest="handleFilledSurvey($event)"
          />
        </b-col>
      </b-row>

      <b-row cols="1" v-if="showDisponibilita && calendarChoice" class="my-2">
        <h5 class="mt-3 text-left pb-1">Disponibilità</h5>
        <b-col class="puntofarmaCard p-4">
          <DatetimeSelection
            :notitle="true"
            :company="selectedCompany.hashid"
            :service="form.service_id"
            restype="prestazione"
            :hservice="selectedPrestazione.hashid"
            v-on:date-selected="handleDateSelection($event)"
          />
        </b-col>
      </b-row>
      <b-row cols="1" v-if="form.data_inizio" class="my-2">
        <h5 class="mt-3 text-left pb-1">Conferma</h5>
        <div class="p-4 puntofarmaCard">
          <b-col>
            <div class="mt-2">
              <p class="text-left text-uppercase">
                <span class="text-primary"
                  >Prenotazione
                  <span class="" v-if="selectedPrestazione">
                    per {{ selectedPrestazione.name }}</span
                  >
                </span>
              </p>
              <p class="text-left">
                <span class="text-primary">Data ed ora selezionate:</span>
                <span class="">
                  {{ form.data_inizio | moment("D MMM YYYY HH:mm") }}</span
                >
              </p>
              <b-form-group
                label="Se vuoi, aggiungi una nota"
                label-for="subtype"
                class="text-left fs-6"
              >
                <b-form-input
                  name="subtype"
                  class="mx-auto"
                  v-model="form.user_notes"
                  required
                >
                </b-form-input>
              </b-form-group>
            </div>
          </b-col>
          <b-col ref="orderConfirm" class="text-center">
            <b-button
              variant="outline-danger"
              class="m-2 general_border"
              @click="resetAll"
              >Annulla</b-button
            >
            <b-button
              variant="primary text-white  general_border"
              class="m-2"
              @click="submitRequest"
            >
              <span v-if="!sending">Procedi</span>
              <b-spinner v-else small></b-spinner>
            </b-button>
          </b-col>
        </div>
      </b-row>
    </b-container>
  </b-container>
</template>
<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { reservationService, companyService } from "@/_services";
import FillAslSurvey from "@/components/utilities/FillAslSurvey.vue";
import SubjectSelection from "@/components/prenotazioni/form/SubjectSelection.vue";
import PrestazioneSelection from "@/components/prenotazioni/form/PrestazioneSelection.vue";
import DatetimeSelection from "@/components/prenotazioni/form/DatetimeSelection.vue";
import AslCard from "@/components/appuntamenti/AslCard.vue";

export default {
  name: "NuovoAppuntamentoAsl",
  components: {
    FillAslSurvey,
    SubjectSelection,
    PrestazioneSelection,
    DatetimeSelection,
    AslCard,
  },
  computed: {
    checkPrecedente: function () {
      var today = moment(this.today).week();
      var start = moment(this.filters.from, "D/M/YY").week();
      return start <= today;
    },
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  watch: {
    form: {
      handler(val) {
        if (val.data_inizio !== null) {
          this.$nextTick(function () {
            this.$refs["orderConfirm"].scrollIntoView();
          });
        }
      },
      deep: true,
    },
    // selectedCompany: function (val) {
    //   this.form.company_id = val.hashid;
    // },
  },
  data() {
    return {
      subject: null,
      type: "prestazione",
      form: {
        company_id: null,
        service_id: null,
        data_inizio: null,
        data_fine: null,
        tipologia: "ordine",
        type: "prestazione",
        company_notes: null,
        filled_survey_id: null,
        order: null,
        subject_id: null,
        subject_type: "user_in_app",
      },
      durata: 15,
      health_service_id: null,
      details: null,
      selectedCompany: null,
      selectedPrestazione: null,
      start: null,
      end: null,
      month: null,
      today: null,
      fields: [],
      disponibilita: null,
      selectedSlot: null,
      selectedCol: null,
      filters: {
        from: null,
        to: null,
      },
      showDisponibilita: false,
      reservationConfirmed: false,
      sending: false,
      newReservation: null,
      isTc: false,
      selectTcOptionDisabled: false,
      showSurvey: false,
      surveyToFill: null,
      nowDate: null,
      calendarChoice: false,
      availableASl: null,
      ownAsl: null,
    };
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    ...mapActions("utente", ["me"]),
    validateService() {
      this.form.service_id =
        this.selectedCompany.servizi_attivi["prenotazioni"];
      if (this.selectedCompany.stato_servizi["prenotazioni"] == undefined) {
        window.scrollTo(0, 0);
        this.errorAlert("Servizio non attivo per la struttura scelta.");
      } else if (this.selectedCompany.stato_servizi["prenotazioni"] == 0) {
        window.scrollTo(0, 0);
        this.errorAlert(
          "Orari di disponibilità non impostati per il servizio."
        );
      }
    },
    resetAll() {
      this.setDetail();
    },
    submitRequest() {
      this.sending = true;
      var order = this.form;
      order.order = { health_service_id: this.health_service_id };
      order.service_id = this.user.company.servizi_attivi["appuntamenti ASL"];
      var dataF = this.form.data_inizio;
      order.data_inizio = dataF.format("YYYY-MM-DD HH:mm");
      var self = this;
      reservationService
        .storeReservation(order)
        .then(function (res) {
          window.scrollTo(0, 0);
          self.successAlert("Prenotazione inviata con successo!");
          self.newReservation = res.data.data;
          self.reservationConfirmed = true;
          self.sending = false;
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          var msg = "Non è stato possibile inviare la richiesta";
          if (error.data.errors && error.data.errors.duplicate) {
            msg = error.data.errors.duplicate;
          }
          self.errorAlert(msg);
          self.sending = false;
        });
    },
    downloadPdf() {
      const hash = this.newReservation.hashid;
      const fName = this.newReservation.codice_richiesta;
      var self = this;
      reservationService
        .scaricaPromemoria(hash)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fName + ".pdf";
          link.click();
        })
        .catch(function (error) {
          self.loading = false;
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile scaricare il promemoria");
        });
    },
    downloadCalendar() {
      const hash = this.newReservation.hashid;
      const fName = this.newReservation.codice_richiesta;
      var self = this;
      reservationService
        .scaricaCalendar(hash)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fName + ".ics";
          link.click();
        })
        .catch(function (error) {
          self.loading = false;
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile scaricare il promemoria");
        });
    },
    setDetail() {
      this.form.data_inizio = null;
      this.form.data_fine = null;
      this.selectedSlot = null;
      this.selectedCol = null;
      this.showDisponibilita = false;
      this.calendarChoice = false;
      this.selectedPrestazione = null;
      this.health_service_id = null;
      this.form.filled_survey_id = null;
    },
    setRequestPrestazione(prestazione) {
      this.selectedPrestazione = prestazione;
      this.health_service_id = prestazione.hashid;
      this.form.data_inizio = null;
      this.showSurvey = prestazione.questionario !== null;
      this.calendarChoice = !this.showSurvey;
      this.surveyToFill = prestazione.questionario;
    },
    handleSelectedAsl(asl) {
      this.ownAsl = asl;
      this.selectedCompany = asl;
      this.$nextTick(() => this.validateService());
      // this.validateService();
      this.form.company_id = asl.hashid;
    },
    fetchAsl() {
      var self = this;
      companyService
        .getOwnAsl()
        .then(function (res) {
          self.ownAsl = res.data.data.own;
          self.selectedCompany = res.data.data.own;
        })
        .catch(function (err) {
          console.log(err);
          self.notFound = true;
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le informazioni.");
        });
    },
    formatFullDate(date) {
      return moment(date).format("dddd DD MMM YYYY HH:mm");
    },
    handleDateSelection(newDate) {
      // console.log(newDate)
      this.form.data_inizio = newDate;
    },
    handleFilledSurvey(filled) {
      this.showSurvey = false;
      var temp = {
        info: this.surveyToFill.info,
        nome: this.surveyToFill.name,
        domande: filled,
      };
      var formData = {
        response: JSON.stringify(temp),
        survey_id: this.surveyToFill.hashid,
        company_id: this.form.company_id,
      };
      var self = this;
      reservationService
        .storeFilledAslSurvey(formData)
        .then(function (res) {
          // console.log(res);
          self.form.filled_survey_id = res.data.data.hashid;
          self.calendarChoice = true;
          self.showDisponibilita = true;
          self.showSurvey = false;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile inoltrare il questionario");
          // console.log(err);
        });
    },
  },
  created() {
    this.start = moment().startOf("week");
    this.end = moment().endOf("week");
    this.today = moment();
    this.month = moment().month();
    this.filters.from = moment().startOf("week").format("D/M/YY");
    this.filters.to = moment().endOf("week").format("D/M/YY");
  },
  mounted() {
    this.nowDate = moment().add(5, "minutes");
  },
};
</script>
