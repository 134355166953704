<template>
  <div class="pt-2 puntofarmaCard h-100 pt-xl-4 px-xl-4">
    <h5 class="pl-4 text-left text-capitalize">{{ title }}</h5>
    <div style="position: relative">
      <canvas :id="idappend"></canvas>
    </div>
    <div v-if="datatoshow" class="w-100 mt-1">
      <b-row class="text-left px-4 border-top border-info">
        <b-col class="border-right border-info text-center pt-2">
          <h6>in corso</h6>
          <p>{{ inCorso }}%</p>
        </b-col>
        <b-col class="text-center pt-2">
          <h6>completati</h6>
          <p>{{ completate }}%</p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { Chart, registerables } from "chart.js";
import "chartjs-adapter-moment"; // or another adapter to avoid moment
Chart.register(...registerables);
export default {
  name: "SplittedPieChart",
  props: ["idappend", "datatoshow", "title"],
  data() {
    return {
      chartData: {
        type: "pie",
        data: {
          labels: ["Pianificati", "Completati"],
          datasets: [
            {
              label: "My First Dataset",
              // data: [11, 16, 7, 3, 14],
              data: this.datatoshow,
              backgroundColor: ["rgb(75, 192, 192)", "rgb(201, 203, 207)"],
            },
          ],
        },
        options: {
          maintainAspectRatio: true,
        },
      },
    };
  },
  computed: {
    inCorso: function () {
      if (this.datatoshow) {
        let total = this.datatoshow.reduce(
          (accumulator, curr) => accumulator + curr
        );
        return Math.round((this.datatoshow[0] / total) * 100 * 100) / 100;
      }
      return 0;
    },
    completate: function () {
      if (this.datatoshow) {
        let total = this.datatoshow.reduce(
          (accumulator, curr) => accumulator + curr
        );
        return Math.round((this.datatoshow[1] / total) * 100 * 100) / 100;
      }
      return 0;
    },
  },
  mounted() {
    // console.log("SplittedPieChart");
    // console.log(this.datatoshow);
    const ctx = document.getElementById(this.idappend);
    new Chart(ctx, this.chartData);
  },
};
</script>