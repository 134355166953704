<template>
  <b-container
    ref="navmain"
    fluid
    class="shadow_1 h-100 px-0 text-font-grey bg-white"
  >
    <transition-group name="slideside" mode="out-in">
      <b-row align-v="center" class="px-0" key="logo-row">
        <b-col
          v-if="$root.isCollapsed"
          class="text-right"
          cols="3"
          lg="2"
          key="padspace"
        >
        </b-col>
        <b-col class="px-1 py-2 mt-2" cols="6" md="12">
          <router-link :to="{ name: 'Home' }" class="">
            <img
              v-if="menuIsCompact && !$root.isCollapsed"
              src="@/assets/puntofarma-dot-logo.svg"
              alt="appLogo"
              class="px-lg-2"
              style="max-width: 75px"
              key="icon_logo"
            />
            <img
              v-else
              src="@/assets/puntofarma-logo.svg"
              alt="appLogo"
              key="full_logo"
            />
          </router-link>
        </b-col>
        <b-col
          key="mobile_ham"
          v-if="$root.isCollapsed"
          class="text-right"
          cols="3"
          lg="2"
          @click="menuVisible = !menuVisible"
        >
          <b-icon font-scale="1.3" icon="justify"></b-icon>
        </b-col>
      </b-row>
      <b-row cols="1" class="px-0" key="menu-row" v-if="menuVisible">
        <b-col
          class="px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-0 text-left"
          v-if="!$root.isCollapsed"
          key="desk_ham"
        >
          <p class="pb_15 nav__item bg-white">
            <span
              class="d-flex align-items-center pl-1 py-1 general_border point"
              @click="handleMenuSize"
            >
              <b-icon font-scale="1.5" icon="justify"></b-icon>
              <span class="mb-0 ml-2"></span>
            </span>
          </p>
        </b-col>
        <!-- <b-col class="px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-0 text-left">
          <p class="pb_15 nav__item bg-white">
            <router-link
              class="d-flex align-items-center pl-1 py-1 general_border"
              :class="
                this.$route.name === 'CompanyHome'
                  ? 'router-link-exact-active'
                  : ''
              "
              :to="{ name: 'Home' }"
            >
              <b-icon font-scale="1.3" icon="house-door"></b-icon>
              <span class="mb-0 ml-2">Dashboard</span>
            </router-link>
          </p>
        </b-col> -->
        <b-col class="px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-0 text-left">
          <p class="text-font-light-grey">
            <span :class="menuIsCompact ? 'text-white' : ''"></span>
          </p>
          <!-- <p>
            <router-link
              class="my-3 mx-auto text-font-grey"
              :to="positions[clientiSet.position_ref]"
            >
              <b-icon font-scale="1.3" :icon="clientiSet.logo"></b-icon>
              <span class="mb-0 ml-2">{{ clientiSet.name }}</span>
            </router-link>
          </p> -->
          <p class="pb_15 nav__item bg-white">
            <router-link
              class="d-flex align-items-center pl-1 py-1 general_border croom"
              :class="
                this.$route.name === 'CompanyHome'
                  ? 'router-link-exact-active'
                  : ''
              "
              :to="{ name: 'Home' }"
            >
              <b-icon font-scale="1.3" icon="house-door"></b-icon>
              <span class="mb-0 ml-2">Dashboard</span>
            </router-link>
          </p>
          <p class="pb_15 nav__item bg-white">
            <router-link
              class="d-flex align-items-center pl-1 py-1 general_border croom"
              :to="{ name: agendaSet.routeName }"
            >
              <b-icon font-scale="1.3" :icon="agendaSet.logo"></b-icon>
              <span class="mb-0 ml-2">{{ agendaSet.name }}</span>
            </router-link>
          </p>
          <p v-if="isFirstLevel" class="pb_15 nav__item bg-white">
            <router-link
              class="d-flex align-items-center pl-1 py-1 general_border croom"
              :to="{ name: analiticheSet.routeName }"
            >
              <b-icon font-scale="1.3" :icon="analiticheSet.logo"></b-icon>
              <span class="mb-0 ml-2">{{ analiticheSet.name }}</span>
            </router-link>
          </p>
          <p class="pb_15 nav__notifications">
            <NotificationCenter />
            <!-- <span class="mb-0 ml-2">Notifiche</span> -->
          </p>
        </b-col>
        <b-col class="px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-0 text-left">
          <p class="text-font-light-grey">
            <span :class="menuIsCompact ? 'text-white' : ''">SERVIZI</span>
          </p>
          <p
            class="pb_15 nav__item"
            :class="service.submenu ? 'bg-extra-light' : 'bg-white'"
            v-for="(service, ind) in activeServices"
            :key="ind"
          >
            <template v-if="service.submenu">
              <span
                :key="'sub-m-' + service.routeName"
                class="d-flex align-items-center pl-1 py-1 general_border point"
                :class="
                  $route.name === service.routeName
                    ? 'router-link-exact-active'
                    : ''
                "
                @click="service.submenuVisible = !service.submenuVisible"
              >
                <b-icon font-scale="1.3" :icon="service.logo"></b-icon>
                <span class="mb-0 ml-2 text-capitalize">{{
                  service.name
                }}</span>
                <b-icon
                  font-scale="1"
                  :icon="service.submenuVisible ? 'chevron-down' : 'chevron-up'"
                  class="mr-1 ml-auto"
                ></b-icon>
              </span>
              <b-collapse
                class="mt-1"
                :id="service.name + '-sub'"
                v-model="service.submenuVisible"
              >
                <p
                  v-for="rt in service.subroutes"
                  :key="'sub-' + rt.name"
                  class="mb-1"
                >
                  <router-link
                    class="d-flex align-items-center pl-1 py-1 general_border align-items-center"
                    :to="{
                      name: service.routeName,
                      params: { section: rt.path },
                    }"
                    exact-active-class="terz_bg_gradient shadow_7 text-white submenu__active__class"
                  >
                    <b-iconstack font-scale="1.3">
                      <b-icon
                        stacked
                        icon="circle"
                        variant="font-grey"
                        scale="0.55"
                      ></b-icon>
                      <b-icon
                        stacked
                        icon="circle"
                        variant="extra-light"
                      ></b-icon>
                    </b-iconstack>
                    <span class="mb-0 ml-2 text-capitalize">{{ rt.name }}</span>
                  </router-link>
                </p>
              </b-collapse>
            </template>
            <template v-else>
              <router-link
                class="d-flex align-items-center pl-1 py-1 general_border croom"
                :to="{
                  name: service.routeName,
                }"
              >
                <b-icon font-scale="1.3" :icon="service.logo"></b-icon>
                <span class="mb-0 ml-2 text-capitalize">{{
                  service.name
                }}</span>
              </router-link>
            </template>
          </p>
          <!-- <p v-if="isFirstLevel" class="pb_15 nav__item" :class="appuntamentiSet.submenu ? 'bg-extra-light' : 'bg-white'">
            <template v-if="appuntamentiSet.submenu">
              <span :key="'sub-m-' + appuntamentiSet.routeName" class="d-flex align-items-center pl-1 py-1 general_border point" :class="
                $route.name === appuntamentiSet.routeName
                  ? 'router-link-exact-active'
                  : ''
              " @click="appuntamentiSet.submenuVisible = !appuntamentiSet.submenuVisible">
                <b-icon font-scale="1.3" :icon="appuntamentiSet.logo"></b-icon>
                <span class="mb-0 ml-2 text-capitalize">{{
                    appuntamentiSet.name
                }}</span>
                <b-icon font-scale="1" :icon="appuntamentiSet.submenuVisible ? 'chevron-down' : 'chevron-up'"
                  class="mr-1 ml-auto"></b-icon>
              </span>
              <b-collapse class="mt-1" :id="appuntamentiSet.name + '-sub'" v-model="appuntamentiSet.submenuVisible">
                <p v-for="rt in appuntamentiSet.subroutes" :key="'sub-' + rt.name" class="mb-1">
                  <router-link class="d-flex align-items-center pl-1 py-1 general_border align-items-center" :to="{
                    name: appuntamentiSet.routeName,
                    params: { section: rt.path },
                  }" exact-active-class="terz_bg_gradient shadow_7 text-white submenu__active__class">
                    <b-iconstack font-scale="1.3">
                      <b-icon stacked icon="circle" variant="font-grey" scale="0.55"></b-icon>
                      <b-icon stacked icon="circle" variant="extra-light"></b-icon>
                    </b-iconstack>
                    <span class="mb-0 ml-2 text-capitalize">{{ rt.name }}</span>
                  </router-link>
                </p>
              </b-collapse>
            </template>
            <template v-else>
              <router-link class="d-flex align-items-center pl-1 py-1 general_border croom" :to="{
                name: appuntamentiSet.routeName,
              }">
                <b-icon font-scale="1.3" :icon="appuntamentiSet.logo"></b-icon>
                <span class="mb-0 ml-2 text-capitalize">{{
                    appuntamentiSet.name
                }}</span>
              </router-link>
            </template>
          </p> -->
          <p class="pb_15 nav__item bg-white">
            <router-link
              class="d-flex align-items-center pl-1 py-1 general_border croom"
              :class="
                this.$route.name === 'control room'
                  ? 'router-link-exact-active'
                  : ''
              "
              :to="{ name: 'control room' }"
            >
              <b-icon font-scale="1.3" icon="bullseye"></b-icon>
              <span class="mb-0 ml-2">Monitoraggio</span>
            </router-link>
          </p>
        </b-col>
        <b-col class="px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-0 text-left">
          <p class="text-font-light-grey">
            <span :class="menuIsCompact ? 'text-white' : ''"></span>
          </p>
          <p class="pb_15 nav__item bg-white">
            <router-link
              class="d-flex align-items-center pl-1 py-1 general_border croom"
              :to="{ name: 'profilo operatore' }"
            >
              <b-avatar
                v-if="user && user.profile_pic"
                variant="white"
                :src="user.profile_pic"
                size="sm"
                rounded
              >
              </b-avatar>
              <b-icon
                v-else
                font-scale="1.3"
                :icon="
                  this.$route.name === 'profilo operatore'
                    ? 'person-fill'
                    : 'person'
                "
              ></b-icon>
              <span class="mb-0 ml-2 text-capitalize">{{ welcomeName }}</span>
            </router-link>
          </p>
          <p
            class="pb_15 bg-extra-light nav__item"
            v-if="canSeeImpostazioni"
            :aria-controls="impostazioniSet.name + '-sub'"
          >
            <span
              class="d-flex align-items-center pl-1 py-1 general_border point"
              :class="
                $route.name === 'impostazioniOperatore'
                  ? 'router-link-exact-active'
                  : ''
              "
              @click="
                impostazioniSet.submenuVisible = !impostazioniSet.submenuVisible
              "
            >
              <b-icon font-scale="1.3" :icon="impostazioniSet.logo"></b-icon>
              <span class="mb-0 ml-2">{{ impostazioniSet.name }}</span>
              <b-icon
                font-scale="1"
                :icon="
                  impostazioniSet.submenuVisible ? 'chevron-down' : 'chevron-up'
                "
                class="mr-1 ml-auto"
              ></b-icon>
            </span>
            <template v-if="impostazioniSet.submenu">
              <b-collapse
                class="mt-1"
                :id="impostazioniSet.name + '-sub'"
                v-model="impostazioniSet.submenuVisible"
              >
                <p
                  v-for="rt in impostazioniSet.subroutes"
                  :key="'sub-' + rt.name"
                  class="mb-1"
                >
                  <router-link
                    class="d-flex align-items-center pl-1 py-1 general_border align-items-center"
                    :to="{
                      name: impostazioniSet.routeName,
                      params: { section: rt.path },
                    }"
                    exact-active-class="terz_bg_gradient shadow_7 text-white submenu__active__class"
                  >
                    <b-iconstack font-scale="1.3">
                      <b-icon
                        stacked
                        icon="circle"
                        variant="font-grey"
                        scale="0.55"
                      ></b-icon>
                      <b-icon
                        stacked
                        icon="circle"
                        variant="extra-light"
                      ></b-icon>
                    </b-iconstack>
                    <span class="mb-0 ml-2 text-capitalize">{{ rt.name }}</span>
                  </router-link>
                </p>
              </b-collapse>
            </template>
          </p>
          <p class="pb_15 nav__item bg-white">
            <router-link
              class="d-flex align-items-center pl-1 py-1 general_border croom"
              :to="{ name: 'guida' }"
            >
              <b-icon
                font-scale="1.3"
                :icon="
                  this.$route.name === 'guida'
                    ? 'info-circle-fill'
                    : 'info-circle'
                "
              ></b-icon>
              <span class="mb-0 ml-2">Guida</span>
            </router-link>
          </p>
        </b-col>
        <b-col class="px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-0 text-left">
          <p class="text-font-light-grey"></p>

          <p class="pb_15 nav__item bg-white">
            <span
              class="d-flex align-items-center pl-1 py-1 general_border croom point"
              @click.prevent="$bvModal.show('logout-confirm')"
            >
              <b-icon font-scale="1.3" icon="door-open"></b-icon>
              <span class="mb-0 ml-2 text-capitalize">Esci</span>
            </span>
          </p>
        </b-col>
      </b-row>
    </transition-group>
    <b-modal
      id="logout-confirm"
      centered
      title="Confermi di voler uscire dall'area privata?"
      ok-title="Sì, esci"
      ok-variant="outline-primary"
      cancel-title="No, annulla"
      cancel-variant="info"
      footer-border-variant="white"
      @ok="onSubmit"
      @cancel="$bvModal.hide('logout-confirm')"
    >
      <!-- <h3>Confermi di voler uscire dall'area privata?</h3> -->
    </b-modal>
  </b-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import PrenotazioniMenu from "./PrenotazioniMenu.vue";
import TeleconsultoMenu from "./TeleconsultoMenu.vue";
import AttivitaMenu from "./AttivitaMenu.vue";
import NotificationCenter from "@/components/layout/NotificationCenter.vue";

export default {
  name: "NavigationOperatoreCRoom",
  props: ["wlistener"],
  components: {
    PrenotazioniMenu,
    AttivitaMenu,
    TeleconsultoMenu,
    NotificationCenter,
  },
  watch: {
    $route(to, from) {
      if (this.$root.isCollapsed) {
        this.menuVisible = false;
      }
    },
    wlistener: function () {
      this.menuIsCompact = this.$refs.navmain.clientWidth <= 80;
    },
    company: function (comp) {
      if (comp) {
        this.isFirstLevel = comp.level === 1;
      }
    },
  },
  computed: {
    welcomeName() {
      if (this.user && this.user.anagrafica) {
        return (
          this.user.anagrafica.name.toUpperCase() +
          " " +
          this.user.anagrafica.surname.toUpperCase().slice(0, 1) +
          "."
        );
      }
      return "Profilo";
    },
    canSeeImpostazioni() {
      if (this.user && this.user.type === 1) {
        if (
          !this.user.permessi.includes("gestire attività e questionari") &&
          !this.user.permessi.includes("somministrare questionari")
        ) {
          const activeServices = this.activeServices.filter(
            (service) => service.position_ref !== 3
          );
          this.activeServices = activeServices;
        }
        if (!this.user.permessi.includes("gestire operatori")) {
          this.impostazioniSet.subroutes =
            this.impostazioniSet.subroutes.filter(
              (route) => route.path !== "operatori"
            );
        }
        if (!this.user.permessi.includes("visualizzare anagrafica azienda")) {
          this.impostazioniSet.subroutes =
            this.impostazioniSet.subroutes.filter(
              (route) =>
                route.path !== "anagrafica" && route.path !== "sottoscrizioni"
            );
        }

        return (
          this.user.permessi.includes("visualizzare anagrafica azienda") ||
          this.user.permessi.includes("gestire impostazioni dei servizi") ||
          this.user.permessi.includes("gestire operatori")
        );
      }
      return true;
    },
    ...mapState("utente", ["status", "user"]),
    ...mapState("company", ["companyLoggedIn", "company"]),
  },
  data() {
    return {
      menuVisible: true,
      showCompanyAlert: null,
      isFirstLevel: true,
      positions: {
        1: "prenotazioni",
        2: "teleconsulto",
        3: "attivita",
        4: "pazienti",
        5: "agenda",
        6: "clienti",
      },
      activeServices: [
        {
          name: "Pazienti",
          logo: "journal-medical",
          disabled: false,
          position_ref: 4,
          routeName: "pazienti",
          scope: undefined,
          submenu: false,
        },
        {
          name: "Prenotazioni",
          logo: "calendar2",
          disabled: false,
          position_ref: 1,
          routeName: "prenotazioni",
          scope: undefined,
          submenu: true,
          submenuVisible: false,
          subroutes: [
            {
              name: "Gestione",
              path: "prenotazioni",
            },
            {
              name: "Strumenti",
              path: "strumenti",
            },
            {
              name: "Report",
              path: "report",
            },
            {
              name: "Recall",
              path: "recall",
            },
          ],
        },
        {
          name: "Teleconsulto",
          logo: "chat-left",
          disabled: false,
          position_ref: 2,
          routeName: "teleconsulto",
          scope: undefined,
          submenu: true,
          submenuVisible: false,
          subroutes: [
            {
              name: "Richieste",
              path: "prenotazioni",
            },
            {
              name: "Oggi",
              path: "videocall",
            },
          ],
        },
        {
          name: "Screening",
          logo: "check2-square",
          disabled: false,
          position_ref: 3,
          routeName: "attivita",
          scope: undefined,
          submenu: true,
          submenuVisible: false,
          subroutes: [
            {
              name: "Raccolte",
              path: "raccolte",
            },
            {
              name: "Questionari",
              path: "questionari",
            },
            {
              name: "Crea",
              path: "questionario/nuovo",
            },
          ],
        },
      ],
      agendaSet: {
        name: "Calendario",
        position_ref: 5,
        routeName: "agenda appuntamenti",
        logo: "calendar4",
        disabled: false,
        btnText: "Consulta",
      },
      analiticheSet: {
        name: "Analitiche",
        routeName: "analitiche",
        logo: "graph-up",
        disabled: false,
      },
      clientiSet: {
        name: "Pazienti",
        position_ref: 6,
        logo: "journal-medical",
        disabled: false,
        btnText: "Consulta",
      },
      impostazioniSet: {
        name: "Impostazioni",
        routeName: "impostazioniOperatore",
        logo: "gear",
        disabled: false,
        submenu: true,
        submenuVisible: false,
        subroutes: [
          {
            name: "Servizi",
            path: "servizi",
          },
          {
            name: "Operatori",
            path: "operatori",
          },
          {
            name: "Anagrafica",
            path: "anagrafica",
          },
          {
            name: "Sottoscrizioni",
            path: "sottoscrizioni",
          },
        ],
      },
      appuntamentiSet: {
        name: "Appuntamenti",
        logo: "kanban",
        disabled: false,
        position_ref: 5,
        routeName: "appuntamentiASL",
        submenu: true,
        submenuVisible: false,
        subroutes: [
          {
            name: "Storico",
            path: "lista",
          },
          {
            name: "Nuovo",
            path: "nuovo",
          },
        ],
      },
      menuIsCompact: false,
    };
  },
  methods: {
    ...mapActions("utente", ["logout"]),
    ...mapActions("company", ["logUserCompanyIn", "companyLogin"]),
    onSubmit() {
      this.logout();
    },
    checkPath() {
      if (this.user) {
        return "/" + this.user.i_am + "/home";
      }
    },
    handleMenuSize() {
      var navById = document.getElementById("nav-desktop");
      var currentWid = navById.clientWidth;
      if (currentWid <= 80) {
        //
        navById.style.minWidth = "inherit";
        navById.style.flex = "0 0 15em";
        this.menuIsCompact = false;
      } else {
        //
        navById.style.minWidth = "0";
        navById.style.flex = "0 0 79px";
        this.menuIsCompact = true;
      }
    },
  },
  created() {
    if (this.$root.isCollapsed) {
      this.menuVisible = false;
    }
  },
  mounted() {
    // console.log(this.$refs.navmain.clientWidth);
    this.menuIsCompact = this.$refs.navmain.clientWidth <= 80;
  },
  // updated() {
  //   console.log("updated", this.company)
  //   if (this.company) {
  //     console.log("updated", this.company)
  //     console.log("comp.level", this.company.level)
  //     this.isFirstLevel = this.company.level === 1
  //   }
  // }
};
</script>
