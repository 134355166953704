<template>
  <div>
    <div v-if="!started">
      <div class="col-sm-12 col-lg-5 mx-auto my-4 px-0 px-md-2">
        <p class="text-justify">
          {{ questionario.info }} Maggiori dettagli saranno reperibili alla
          <a
            href="https://teleconsulto.online/privacy"
            class="text-primary"
            target="_blank"
            >Privacy Policy</a
          >. Le condizioni di utilizzo sono consultabili
          <a
            href="https://teleconsulto.online/tec/"
            class="text-primary"
            target="_blank"
            >qui</a
          >
          .
        </p>
        <!-- <p class="text-justify">
          Maggiori dettagli saranno reperibili alla
          <a href="https://teleconsulto.online/privacy" class="text-secondary"
            >Privacy Policy</a
          >. Le condizioni di utilizzo sono consultabili
          <a href="https://teleconsulto.online/tec/" class="text-secondary"
            >qui</a
          >
          .
        </p> -->
      </div>
      <b-button
        variant="primary font-weight-bold "
        class="mx-auto my-2"
        @click="start"
        >Ok, Procedi</b-button
      >
    </div>
    <div v-else>
      <b-tabs
        align="center"
        active-nav-item-class=" text-secondary border-0"
        active-tab-class="text-dark"
        content-class="mt-3"
        nav-class="border-0 text-white"
        no-key-nav
        v-model="questIndex"
      >
        <b-tab
          title
          v-for="(domanda, ind) in domande"
          :key="ind"
          title-item-class="disabledTab"
        >
          <div class="mx-auto my-4">
            <h5 class="">
              <span class="">{{ questionIndex(ind) }}.</span>
              {{ domanda.q }}
            </h5>
            <br />
            <b-form-group :key="'fgrc_' + ind">
              <div
                v-if="domanda.o && domanda.o === 'file'"
                class="align-self-center"
              >
                <SurveyUploader
                  v-on:upload-result="handleUpload($event, ind)"
                  v-on:remove-result="removeUpload(ind)"
                  :accepts="domanda.fa"
                  :isMulti="domanda.fm"
                />
              </div>
              <b-form-checkbox-group
                v-else-if="domanda.cb && domanda.o"
                :name="'q_' + ind"
                v-model="domanda.a"
                :options="domanda.o"
                name="flavour-1"
              ></b-form-checkbox-group>
              <div v-else-if="domanda.o">
                <b-button
                  v-for="(opzione, indx) in domanda.o"
                  :key="indx"
                  class="col-lg-3 col-sm-4 mx-1 my-2 fixed__h text__control"
                  :variant="domande[ind].a ===opzione?'primary':'outline-primary'"
                  @click="selectOption(domanda, opzione,ind)"
                  >{{ opzione }}</b-button
                >
              </div>
              <!-- <b-form-select class="px-3 align-self-center" v-else-if="domanda.o" v-model="domanda.a"
                    :name="'q_' + ind" required>
                    <b-form-select-option v-for="opt in domanda.o" :key="opt" :value="opt">{{ opt }}
                    </b-form-select-option>
                  </b-form-select> -->
              <b-form-textarea
                v-else
                v-model="domanda.a"
                placeholder="..."
                class="my-2 py-2 col-10 mx-auto"
              >
              </b-form-textarea>
              <b-form-input
                v-else
                class="align-self-center"
                placeholder="..."
                type="text"
                :name="'q_' + ind"
                v-model="domanda.a"
                required
              ></b-form-input>
            </b-form-group>
            <!-- <div v-if="domanda.o" class="py-2">
              <div v-if="domanda.o !== 'file'">
                <b-button
                  v-for="(opzione, indx) in domanda.o"
                  :key="indx"
                  class="col-lg-3 col-sm-4 mx-1 my-2 fixed__h text__control"
                  variant="outline-secondary"
                  @click="selectOption(domanda, opzione)"
                  >{{ opzione }}</b-button
                >
              </div>
              <div v-else>
                <SurveyUploader
                  v-on:upload-result="handleUpload($event, ind)"
                  v-on:remove-result="removeUpload(ind)"
                  :accepts="domanda.fa"
                  :isMulti="domanda.fm"
                />
              </div>
              <br />
              <span v-if="domanda.o !== 'file'"
                >Se vuoi, aggiungi un commento</span
              >
            </div>
            <template v-if="!hascore">
              <b-form-textarea
                v-if="domanda.o !== 'file'"
                v-model="domanda.a"
                class="my-2 py-2 col-10 mx-auto"
              >
              </b-form-textarea>
            </template> -->
          </div>
          <b-button
            variant="secondary font-weight-bold "
            class="mx-auto my-2 col-10 col-xsm-5 col-sm-4 col-md-3 col-lg-2"
            @click="next"
            >Procedi</b-button
          >
          <!-- <b-button
            variant="primary"
            class="col-3 mx-auto my-2"
            @click="next"
            :disabled="domanda.a.length <1"
          >Procedi</b-button>-->
          <br />
        </b-tab>
        <b-tab title title-item-class="disabledTab">
          <div class="col-sm-12 col-lg-7 mx-auto">
            <h5 class="text-secondary">
              Grazie per aver compilato il questionario!
            </h5>
            <br />
            <p style="font-weight: 400">Puoi proseguire con la prenotazione.</p>
            <br />
            <b-button
              variant="primary font-weight-bold "
              class="mx-auto my-2"
              :class="$root.isMobile ? 'col-10' : 'col-5'"
              @click="finalizza"
            >
              <b-spinner v-if="sending"></b-spinner>
              <span v-else>Prosegui</span>
            </b-button>
          </div>
          <br />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import { supportService } from "@/_services";
import { mapState, mapActions } from "vuex";
import SurveyUploader from "@/components/utilities/SurveyUploader.vue";
export default {
  name: "FillSurvey",
  props: ["survey", "hascore"],
  components: { SurveyUploader },
  data() {
    return {
      questIndex: 0,
      started: false,
      domande: null,
      file: null,
      uploading: false,
      btnText: "Salva Immagine",
      questionario: null,
      sending: false,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    start() {
      this.started = true;
    },
    selectOption(domanda, opzione, ind) {
      domanda.a = opzione;
    },
    next() {
      this.questIndex++;
    },
    finalizza() {
      this.sending = true;
      this.$emit("end-quest", this.domande);
    },
    handleUpload(data, ind) {
      if (this.domande[ind].a == null) {
        this.domande[ind].a = [];
      }
      this.domande[ind].a.push(data.hashid);
      if (Array.isArray(data)) {
        this.domande[ind].a = data.map((el) => el.hashid);
      }
    },
    removeUpload(ind) {
      this.domande[ind].a = null;
    },
    questionIndex(i) {
      var index = i + 1;
      return index + "/" + this.domande.length;
    },
  },
  created() {
    this.questionario = JSON.parse(this.survey);
    this.domande = this.questionario.domande;
    if (this.questionario.info == null) {
      this.start();
    }
    // console.log(this.domande);
  },
};
</script>
<style scoped>
.custom-file-input:lang(en)~.custom-file-label::after {
  content: "Scegli";
}

.custom-file-input:lang(it)~.custom-file-label::after {
  content: "Scegli";
}

.fixed__h {
  height: 10vh;
}

.text__control {
  white-space: initial;
  word-wrap: break-word;
}
</style>
