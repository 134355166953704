<template>
  <div class="col-12 pb-4">
    <AnagraficaComponent />

    <!-- I contatti utili ci devono essere sul profilo personale solo per l'utenza cittadino -->
    <b-col
      v-if="user && user.type === 0 && user.email_verified_at"
      class="px-0 col-12"
    >
      <ContattiEmergenza :user="user"></ContattiEmergenza>
    </b-col>
    <div v-if="user.type === 1">
      <AccessoEPermessi />
      <NotificationChannels />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AnagraficaComponent from "@/components/profile/AnagraficaComponent.vue";
import AccessoEPermessi from "@/components/profile/AccessoEPermessi.vue";
import NotificationChannels from "@/components/impostazioni/NotificationChannels.vue";
import ContattiEmergenza from "@/components/profile/contatti/ContattiEmergenza.vue";

export default {
  name: "Profilo",
  components: {
    AnagraficaComponent,
    AccessoEPermessi,
    NotificationChannels,
    ContattiEmergenza,
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("utente", ["setAnagrafica", "me"]),
  },
  watch: {},
  created() {
    // this.me();
  },
  mounted() {},
  beforeRouteLeave(to, from, next) {
    if (!this.user) {
      next();
    }
    if (this.user.type === 2) {
      next();
    }
    if (!this.user.expired_password && this.user.number_verified_at) {
      next();
    }
    const isGoingHome = to.name === "NumberVerification" || to.name === "Home";
    console.log("to.name", to.name);
    console.log("is going back home", isGoingHome);
    //se non ha verificato il numero può andare solo alla verifica e alla home
    if (!this.user.number_verified_at && isGoingHome) {
      next();
    }
    //se è un operatore con password scaduta non puo uscire dal profilo finché non la aggiorna
    if (
      this.user.type === 1 &&
      !this.user.expired_password &&
      this.user.number_verified_at
    ) {
      next();
    }
  },
  // beforeRouteLeave(to, from, next) {
  //   if (this.user.type !== 1) {
  //     next();
  //   }
  //   if (
  //     !this.user.expired_password &&
  //     (this.user.number_verified_at ||
  //       to.name === "NumberVerification" ||
  //       to.name === "Home")
  //   ) {
  //     next();
  //   }
  // },
};
</script>
