<template>
  <div v-if="user.type === 1">
    <template v-if="isActive === null">
      <p class="text-center"><b-spinner></b-spinner></p>
    </template>
    <template v-else-if="isActive === true">
      <transition-group name="list" mode="out-in" appear>
        <div
          v-if="!$route.params.section || $route.params.section === 'raccolte'"
          key="raccolte"
        >
          <HomeAttivita />
        </div>

        <div
          v-else-if="$route.params.section === 'questionari'"
          key="questionari"
        >
          <HomeQuestionari />
        </div>

        <div
          v-else-if="$route.params.section === 'questionario/nuovo'"
          key="nuovoquestionario"
        >
          <CreaNuovoQuestionario />
        </div>

        <div
          v-else-if="
            $route.params.section === 'questionario' &&
            $route.params.action === 'modifica'
          "
          key="editquestionario"
        >
          <ModificaQuestionario />
        </div>
        <div
          v-else-if="
            $route.params.section === 'questionario' &&
            $route.params.action === 'somministra'
          "
          key="compilaquestionario"
        >
          <CompilazioneQuestionario />
        </div>
        <div
          v-else-if="
            $route.params.section === 'questionario' &&
            $route.params.action === 'edit'
          "
          key="modificacompilato"
        >
          <ModificaQuestionarioCompilato />
        </div>
        <div
          v-else-if="
            $route.params.section === 'questionario' &&
            $route.params.action === 'report'
          "
          key="reportquestionario"
        >
          <ReportQuestionariCompilati />
        </div>
        <div v-else>
          <h4>Sezione riservata o non trovata.</h4>
        </div>
      </transition-group>
    </template>
    <template v-else>
      <h4>Servizio non abilitato</h4>
      <h6>
        <a href="https://puntofarma.online/contacts/"> <u> Contattaci</u></a>
        per saperne di più o per abilitare il servizio
      </h6>
    </template>
  </div>
</template>
<script>
import { mapState } from "vuex"
import { companyService } from "@/_services"
import HomeAttivita from "@/components/attivita/sections/HomeAttivita"
import HomeQuestionari from "@/components/attivita/sections/HomeQuestionari"
import CreaNuovoQuestionario from "@/components/attivita/sections/CreaNuovoQuestionario"
import ModificaQuestionario from "@/components/attivita/sections/ModificaQuestionario"
import CompilazioneQuestionario from "@/components/attivita/sections/CompilazioneQuestionario"
import ReportQuestionariCompilati from "@/components/attivita/sections/ReportQuestionariCompilati"
import ModificaQuestionarioCompilato from "@/components/attivita/sections/ModificaQuestionarioCompilato";

export default {
  name: "AttivitaMain",
  components: {
    HomeAttivita,
    HomeQuestionari,
    CreaNuovoQuestionario,
    ModificaQuestionario,
    CompilazioneQuestionario,
    ReportQuestionariCompilati,
    ModificaQuestionarioCompilato
  },
  computed: {
    ...mapState("utente", ["status", "user"])
  },
  data() {
    return {
      isActive: null
    }
  },
  mounted() {
    this.verifyService()
  },
  methods: {
    verifyService() {
      const self = this
      const filter = { ref: 3 }
      companyService
        .checkIfServiceIsActive(filter)
        .then(function (response) {
          self.isActive = response.data.data.active
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>
