<template>
  <div>
    <b-row cols="3" align-v="center" class="justify-content-center mb-2">
      <b-col class="text-right">
        <!-- <b-button
          v-if="showBackButton"
          variant="outline-primary"
          @click="subMonth"
          ><b-icon icon="chevron-double-left"></b-icon
        ></b-button> -->
        <b-button variant="outline-primary" @click="subMonth"
          ><b-icon icon="chevron-double-left"></b-icon
        ></b-button>
      </b-col>
      <h5 class="text-primary text-capitalize">
        {{ currentDate | moment("MMMM") }}
        <br />
        {{ currentDate | moment("YYYY") }}
      </h5>
      <b-col class="text-left">
        <b-button variant="outline-primary" @click="addMonth"
          ><b-icon icon="chevron-double-right"></b-icon></b-button
      ></b-col>
    </b-row>
    <b-row class="seven-cols border-left border-top border-primary">
      <b-col
        v-for="dayLabel in daysInWeek"
        class="col-md-1 border-right border-bottom border-primary"
      >
        <div class="px-2">
          {{ dayLabel }}
        </div>
      </b-col>
    </b-row>
    <b-row
      v-if="calendarOptions"
      class="seven-cols border-left border-top border-primary"
    >
      <template v-for="(dayOption, key) in calendarOptions">
        <template v-if="dayOption.date">
          <template v-if="!isBeforeMonth">
            <template
              v-if="
                !isCurrentMonth ||
                (isCurrentMonth && checkIfDateIsBefore(dayOption.date))
              "
            >
              <b-col
                v-if="dayOption.booked"
                @click="selectForRemoval(key)"
                class="point col-md-1 border-right border-bottom border-primary"
                :class="
                  dayOption.remove
                    ? 'bg-extra-light text-orange'
                    : 'bg-white text-primary'
                "
                v-b-tooltip.hover
                :title="'Servizio attivo'+ getBookedInfo(dayOption.date) "
              >
                <b-row cols="1" class="p-4">
                  <span class="booked__icon"
                    ><b-icon
                      :icon="
                        dayOption.remove ? 'dash-square-fill' : 'star-fill'
                      "
                    ></b-icon
                  ></span>
                  <span>{{ dayOption.date | moment("D") }} </span>
                </b-row>
              </b-col>
              <b-col
                v-else-if="dayOption.available && !dayOption.closed"
                @click="selectDate(key)"
                class="point col-md-1 border-right border-bottom border-primary"
                :class="
                  dayOption.selected
                    ? 'bg-primary text-white'
                    : 'bg-white text-primary'
                "
              >
                <div class="p-4">
                  {{ dayOption.date | moment("D") }}
                </div>
              </b-col>
              <b-col
                v-else
                class="col-md-1 border-right border-bottom border-primary bg-light-red"
                v-b-tooltip.hover
                title="Chiuso o festivo"
              >
                <div class="p-4">
                  {{ dayOption.date | moment("D") }}
                </div>
              </b-col>
            </template>
            <b-col
              v-else
              class="col-md-1 border-right border-bottom border-primary text-dark bg-mdm-light-grey"
            >
              <b-row cols="1" class="p-4">
                <span class="booked__icon" v-if="dayOption.booked">
                  <b-icon
                    font-scale="0.5"
                    icon="circle-fill"
                    variant="danger"
                  ></b-icon
                ></span>
                <span>{{ dayOption.date | moment("D") }} </span>
              </b-row>
            </b-col>
          </template>
          <b-col
            v-else
            class="col-md-1 border-right border-bottom border-primary text-dark bg-mdm-light-grey"
          >
            <b-row cols="1" class="p-4">
              <span class="booked__icon" v-if="dayOption.booked">
                <b-icon
                  font-scale="0.5"
                  icon="circle-fill"
                  variant="danger"
                ></b-icon
              ></span>
              <span>{{ dayOption.date | moment("D") }} </span>
            </b-row>
          </b-col>
        </template>
        <b-col
          v-else
          class="col-md-1 border-right border-bottom border-primary bg-mdm-light-grey"
        >
          <div class="p-4 text-primary"></div>
        </b-col>
      </template>
    </b-row>
    <b-row v-else class="seven-cols border-left border-top border-primary">
      <b-spinner variant="primary"></b-spinner>
    </b-row>
    <template v-if="selectedDatesFormatted || selectedDatesToRemoveFormatted">
      <b-row cols="1" class="mt-3 text-left" align-v="center">
        <template v-if="selectedDatesFormatted">
          <h5 class="">Date da aggiungere:</h5>
          <ul class="date__list">
            <li v-for="formattedDate in selectedDatesFormatted">
              {{ formattedDate }}
            </li>
          </ul>
          <b-row cols="2">
            <b-row cols="3" align-v="end" class="mb-4">
              <h6 for="adio-opensAt" class="col-12 pl-0">
                Queste date saranno prenotabili:
              </h6>
              <b-form-group class="col-12 col-md-6">
                <b-form-radio
                  v-model="alwaysBookable"
                  name="radio-opensAt"
                  class="my-1"
                  :value="0"
                  >Da subito</b-form-radio
                >
                <b-form-radio
                  v-model="alwaysBookable"
                  name="radio-opensAt"
                  class="my-1"
                  :value="1"
                  >Dal giorno</b-form-radio
                >
              </b-form-group>
              <template>
                <b-form-input
                  size="sm"
                  class="d-inline col-5 col-md-4"
                  v-model="opensAtDay"
                  type="date"
                  :disabled="alwaysBookable !==1"
                ></b-form-input>
              </template>

              <b-form-group class="col-12 col-md-6">
                <b-form-radio
                  v-model="alwaysBookable"
                  name="radio-opensAt"
                  class="my-1"
                  :value="2"
                  >A partire da
                </b-form-radio>
              </b-form-group>
              <template>
                <b-form-input
                  size="sm"
                  class="d-inline col-3 col-md-2"
                  v-model="opensAt"
                  type="number"
                  :min="1"
                  :disabled="alwaysBookable !==2"
                ></b-form-input>
                <span class="pl-2"> giorni prima</span>
              </template>
            </b-row>
            <b-row cols="3" align-v="end" class="mb-4">
              <b-form-group class="col-12 col-md-6">
                <h6 for="radio-closesAt" class="fs-3">Fino:</h6>
                <b-form-radio
                  v-model="bookableSameDay"
                  name="radio-closesAt"
                  class="my-1"
                  :value="0"
                  >Al giorno stesso</b-form-radio
                >
                <b-form-radio
                  v-model="bookableSameDay"
                  name="radio-closesAt"
                  class="my-1"
                  :value="1"
                  >Al giorno
                </b-form-radio>
              </b-form-group>
              <template>
                <b-form-input
                  size="sm"
                  class="d-inline col-5 col-md-4"
                  v-model="closeAtDay"
                  type="date"
                  :disabled="bookableSameDay !==1"
                ></b-form-input>
              </template>

              <b-form-group class="col-12 col-md-6">
                <b-form-radio
                  v-model="bookableSameDay"
                  name="radio-closesAt"
                  class="my-1"
                  :value="2"
                  >A
                </b-form-radio>
              </b-form-group>
              <template>
                <b-form-input
                  size="sm"
                  class="d-inline col-3 col-md-2"
                  v-model="closeAt"
                  type="number"
                  :disabled="bookableSameDay !==2"
                ></b-form-input>
                <span class="pl-2"> giorni prima</span>
              </template>
            </b-row>
          </b-row>
        </template>
        <b-row
          v-if="selectedDatesToRemoveFormatted"
          cols="1"
          class="mt-1 text-left"
          align-v="center"
        >
          <h5 class="">Date da rimuovere:</h5>
          <ul class="date__list">
            <li v-for="formattedDate in selectedDatesToRemoveFormatted">
              {{ formattedDate }}
            </li>
          </ul>
        </b-row>
        <b-col class="pl-0">
          <b-button variant="outline-danger" class="mx-1" @click="resetForm"
            >Annulla</b-button
          >
          <b-button variant="primary" class="mx-1" @click="saveDates">
            <b-spinner v-if="sending"></b-spinner>
            <span v-else> Conferma operazione</span>
          </b-button>
        </b-col>
      </b-row>
    </template>
  </div>
</template>
<style type="text/css">
.date__list {
  columns: 4;
  -webkit-columns: 4;
  -moz-columns: 4;
}

.booked__icon {
  position: absolute;
  right: 1.5vw;
  top: 1vh;
}

@media (min-width: 768px) {

  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 100%;
    *width: 100%;
  }

  .date__list {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
}

@media (min-width: 992px) {

  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 14.285714285714285714285714285714%;
    *width: 14.285714285714285714285714285714%;
    max-width: 14.285714285714285714285714285714% !important;
    flex: none !important;
  }

  .date__list {
    columns: 3;
    -webkit-columns: 3;
    -moz-columns: 3;
  }
}

@media (min-width: 1200px) {

  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 14.285714285714285714285714285714%;
    *width: 14.285714285714285714285714285714%;
    max-width: 14.285714285714285714285714285714% !important;
    flex: none !important;
  }

  .date__list {
    columns: 4;
    -webkit-columns: 4;
    -moz-columns: 4;
  }
}
</style>
<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { companyService } from "@/_services";
export default {
  name: "DayPlanner",
  props: ["serviceHash"],
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  data() {
    const today = moment();
    return {
      today: today,
      prohibidates: null,
      currentDate: null,
      startOfMonth: null,
      endOfMonth: null,
      daysInMonth: null,
      calendarOptions: null,
      daysInWeek: [
        "lunedì",
        "martedì",
        "mercoledì",
        "giovedì",
        "venerdì",
        "sabato",
        "domenica",
      ],
      selectedOptions: [],
      toBeRemoved: [],
      sending: false,
      previous: null,
      hints: null,
      closedDays: null,
      booked: null,
      alwaysBookable: 0,
      bookableSameDay: 0,
      opensAt: 30,
      closeAt: 1,
      opensAtDay: null,
      closeAtDay: null,
    };
  },
  watch: {
    currentDate(value, before) {
      if (before !== null) {
        this.setCalendar();
      }
    },
  },
  computed: {
    selectedDatesFormatted() {
      if (this.selectedOptions.length > 0) {
        return this.selectedOptions
          .sort((a, b) => {
            return moment(a, "DD-MM-YYYY") - moment(b, "DD-MM-YYYY");
          })
          .map((el) => moment(el, "DD-MM-YYYY").format("dddd D MMMM YYYY"));
      }
      return null;
    },
    showBackButton() {
      if (this.today.year() === this.currentDate.year()) {
        return this.today.month() !== this.currentDate.month();
      }
      return true;
    },
    isBeforeMonth() {
      if (this.currentDate.year() >= this.today.year()) {
        return this.today.month() > this.currentDate.month();
      }
      return true;
    },
    isCurrentMonth() {
      if (this.today.year() >= this.currentDate.year()) {
        return this.today.month() === this.currentDate.month();
      }
      return false;
    },
    selectedDatesToRemoveFormatted() {
      if (this.toBeRemoved.length > 0) {
        return this.toBeRemoved
          .sort((a, b) => {
            return moment(a, "DD-MM-YYYY") - moment(b, "DD-MM-YYYY");
          })
          .map((el) => moment(el, "DD-MM-YYYY").format("dddd D MMMM YYYY"));
      }
      return null;
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    getProhibiDates() {
      var self = this;
      companyService
        .getOwnProhibidates()
        .then(function (resp) {
          //console.log(resp);
          // self.prohibidates =
          const forbiddenDatesObject = resp.data.data.filter(
            (el) => el.active === 1
          );
          if (forbiddenDatesObject) {
            self.prohibidates = forbiddenDatesObject.reduce(
              (allDates, date) => {
                const formatted = moment(
                  date.nextOccurrence,
                  "YYYY-MM-DD"
                ).format("DD-MM");
                return [...allDates, formatted];
              },
              []
            );
          } else {
            self.prohibidates = [];
          }
          self.fetchData();
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le sottoscrizioni");
        });
    },
    setCalendar() {
      var date = moment(this.currentDate);
      this.daysInMonth = date.daysInMonth();
      const weekday = date.weekday();
      this.calendarOptions = null;
      this.startOfMonth = date.startOf("month").format("DD-MM-YYYY");
      this.endOfMonth = date.endOf("month").format("DD-MM-YYYY");
      var arrayOption = [];
      for (let dd = 0; dd < weekday; ++dd) {
        arrayOption.push({
          date: null,
          selected: false,
          available: false,
          closed: false,
          booked: false,
          remove: false,
        });
      }
      for (let d = 0; d < this.daysInMonth; ++d) {
        const addDate = moment(this.currentDate).add(d, "days");
        const strDate = moment(addDate).format("DD-MM-YYYY");
        const isSelected = this.selectedOptions.find((el) => el === strDate)
          ? true
          : false;
        const isBooked = this.booked.find((el) => el === strDate)
          ? true
          : false;
        const dayOfWeek = moment(addDate).day();
        const isClosed = this.closedDays.includes(dayOfWeek);
        const strDateShort = moment(addDate).format("DD-MM");
        const isAvailable = this.prohibidates.find((el) => el === strDateShort)
          ? false
          : true;
        arrayOption.push({
          date: addDate,
          selected: isSelected,
          available: isAvailable,
          closed: isClosed,
          booked: isBooked,
          remove: false,
        });
      }
      this.calendarOptions = arrayOption;
    },
    subMonth() {
      var date = moment(this.currentDate);
      this.currentDate = date.subtract(1, "months");
      console.log(this.currentDate);
    },
    addMonth() {
      var date = moment(this.currentDate);
      this.currentDate = date.add(1, "months");
    },
    selectDate(index) {
      const strDate = moment(this.calendarOptions[index].date).format(
        "DD-MM-YYYY"
      );
      if (this.calendarOptions[index].selected) {
        //da rimuovere
        this.selectedOptions = this.selectedOptions.filter(
          (el) => el !== strDate
        );
        this.calendarOptions[index].selected = false;
      } else {
        //da aggiungere
        this.calendarOptions[index].selected = true;
        this.selectedOptions.push(strDate);
      }
    },
    selectForRemoval(index) {
      const strDate = moment(this.calendarOptions[index].date).format(
        "DD-MM-YYYY"
      );
      if (this.calendarOptions[index].remove) {
        //da rimuovere dalla lista
        this.toBeRemoved = this.toBeRemoved.filter((el) => el !== strDate);
        this.calendarOptions[index].remove = false;
      } else {
        //da aggiungere alla lista
        this.calendarOptions[index].remove = true;
        this.toBeRemoved.push(strDate);
      }
    },
    fetchData() {
      var self = this;
      companyService
        .getDaysOpenings(this.serviceHash)
        .then(function (res) {
          self.previous = res.data.data.days;
          self.hint = res.data.data.hint;
          self.closedDays = res.data.data.closedDays
            ? res.data.data.closedDays.map((el) => (el.day === 7 ? 0 : el.day))
            : [];
          self.booked = res.data.data.days.map((el) =>
            moment(el.day, "YYYY-MM-DD").format("DD-MM-YYYY")
          );
          self.setCalendar();
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le informazioni");
        });
    },
    resetForm() {
      this.selectedOptions = [];
      this.toBeRemoved = [];
      this.alwaysBookable = 0;
      this.bookableSameDay = 0;
      this.opensAt = 30;
      this.closeAt = 1;
      this.opensAtDay = null;
      this.closeAtDay = null;
      this.setCalendar();
      this.$emit("resetted-dates");
    },
    saveDates() {
      var self = this;
      this.sending = true;
      var starts = null;

      const form = {
        dates: this.selectedOptions.map((el) =>
          moment(el, "DD-MM-YYYY").format("YYYY-MM-DD")
        ),
        remove: this.toBeRemoved.map((el) =>
          moment(el, "DD-MM-YYYY").format("YYYY-MM-DD")
        ),
        starts: this.alwaysBookable === 2 ? this.opensAt : undefined,
        ends: this.bookableSameDay === 2 ? this.closeAt : undefined,
        start_day: this.alwaysBookable === 1 ? this.opensAtDay : undefined,
        end_day: this.bookableSameDay === 1 ? this.closeAtDay : undefined,
      };

      companyService
        .storeDaysOpenings(form, this.serviceHash)
        .then(function (res) {
          self.sending = false;
          self.fetchData();
          window.scrollTo(0, 0);
          self.successAlert("Nuova data salvata correttamente");
          self.resetForm();
        })
        .catch(function (err) {
          console.log(err);
          self.sending = false;
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile salvare la data");
        });
    },
    checkIfDateIsBefore(date) {
      return moment(date).isSameOrAfter(this.today);
    },
    getBookedInfo(date) {
      const asString = moment(date).format("YYYY-MM-DD")
      const originalObject = this.previous.find(el => el.day === asString)
      var info = ""
      if (originalObject) {
        if (originalObject.reservation_starts_at) {
          info += " prenotabile dal " + moment(originalObject.reservation_starts_at, "YYYY-MM-DD").format('D/MM/YYYY')
        }
        if (originalObject.reservation_ends_at) {
          info += " fino al " + moment(originalObject.reservation_ends_at, "YYYY-MM-DD").format('D/MM/YYYY')
        }
      }
      return info
    }
  },
  created() {
    this.currentDate = moment().set("date", 1);
  },
  mounted() {
    this.getProhibiDates();
  },
};
</script>
