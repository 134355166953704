import { companyService } from "../_services";
import router from "../router/index";

const azienda = JSON.parse(localStorage.getItem("company"));

const state = azienda
  ? {
      companyLoggedIn: true,
      company: azienda,
      prenotazioni: null,
    }
  : {
      companyLoggedIn: true,
      company: null,
      prenotazioni: null,
    };

const actions = {
  logUserCompanyIn({ dispatch, commit }) {
    companyService
      .logUserCompanyIn()
      .then((response) => {
        // console.log("logUserCompanyIn called");
        commit("setCompany", response.data.data);
        commit("logCompanyIn");
        return Promise.resolve(response);
      })
      .catch((error) => {
        // console.log("login failed-store", error);
        commit("logCompanyOut");
        commit("unsetCompany");
        dispatch("alert/error", "Sessione scaduta", { root: true });
        // localStorage.removeItem("user");
        // localStorage.removeItem("token");
        // localStorage.removeItem("company");
        return Promise.reject(error);
      });
  },
  companyLogin({ dispatch, commit }, token) {
    companyService
      .companyLogin(token)
      .then((response) => {
        // console.log(response);
        commit("setCompany", response.data.data);
        commit("logCompanyIn");
        return Promise.resolve(response);
      })
      .catch((error) => {
        // console.log("login failed-store", error);
        commit("logCompanyOut");
        commit("unsetCompany");
        dispatch("alert/error", "Sessione scaduta", { root: true });
        return Promise.reject(error);
      });
  },
  setCompanyDataFromUser({ commit }, data) {
    commit("setCompany", data);
  },
  storeOpenings({ dispatch, commit }, form) {
    companyService
      .storeOpenings(form)
      .then((res) => {
        // console.log(res);
        commit("updateOpenings", res.data.data);
        dispatch("alert/success", "Orari aggiornati correttamente!", {
          root: true,
        });
      })
      .catch((error) => {
        dispatch("alert/error", error.data.message, { root: true });
      });
  },
  storeServiceOpenings({ dispatch, commit }, data) {
    var serviceName = data.service_name;
    companyService
      .storeServiceOpenings(data)
      .then((res) => {
        // console.log(res);
        commit("updateServiceOpenings", serviceName, res.data.data);
        dispatch("alert/success", "Orari aggiornati correttamente!", {
          root: true,
        });
      })
      .catch((error) => {
        dispatch("alert/error", error.data.message, { root: true });
      });
  },
  updateServiceStatus({ dispatch, commit }, data) {
    const form = data.form;
    var serviceName = data.service_name;
    const serviceStatus = form.status;
    companyService
      .updateServiceStatus(data)
      .then((res) => {
        // console.log(res);
        commit("updateStatoServizi", serviceName, serviceStatus);
        dispatch("alert/success", "Informazioni aggiornate correttamente!", {
          root: true,
        });
      })
      .catch((error) => {
        dispatch("alert/error", error.data.message, { root: true });
      });
  },
  updateAnagrafica({ dispatch, commit }, data) {
    companyService
      .updateAnagrafica(data)
      .then((res) => {
        // console.log(res);
        commit("setCompany", res.data.data);
        dispatch("alert/success", "Dati aggiornati correttamente!", {
          root: true,
        });
      })
      .catch((error) => {
        var msg = "Non è stato possibile aggiornare l'anagrafica";
        if (error.data.errors) {
          msg = "";
          Object.keys(error.data.errors).map(function (el, ind) {
            // console.log("el", el);
            // console.log("ind", ind);
            msg += el + " ,";
          });
          msg += ": formato non corretto";
        }
        dispatch("alert/error", msg, { root: true });
      });
  },
  storePrestazione({ dispatch, commit }, data) {
    companyService
      .storePrestazione(data)
      .then((res) => {
        // console.log(res);
        commit("updatePrestazioni", res.data.data);
        dispatch("alert/success", "Dati aggiornati correttamente!", {
          root: true,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch("alert/error", error.data.message, { root: true });
      });
  },
  storeOrariPrestazione({ dispatch, commit }, data) {
    companyService
      .storeOrariPrestazione(data)
      .then((res) => {
        // console.log(res);
        // commit("setCompany", res.data.data);
        dispatch("alert/success", "Dati aggiornati correttamente!", {
          root: true,
        });
      })
      .catch((error) => {
        // var msg="Non è stato possibile aggiornare l'anagrafica";
        // if(error.data.errors){
        //   msg="";
        //   Object.keys(error.data.errors).map(function(el,ind){
        //   console.log("el",el);
        //   console.log("ind",ind)
        //   msg+=el+" ,";
        //   })
        //   msg+=": formato non corretto";
        // }
        dispatch("alert/error", error.data.message, { root: true });
      });
  },
  updatePrestazione({ dispatch, commit }, data) {
    companyService
      .updatePrestazione(data)
      .then((res) => {
        // console.log(res);
        // commit("setCompany", res.data.data);
        dispatch("alert/success", "Dati aggiornati correttamente!", {
          root: true,
        });
      })
      .catch((error) => {
        dispatch("alert/error", error.data.message, { root: true });
      });
  },
  fetchPrenotazioni({ dispatch, commit }) {
    companyService
      .updatePrestazione(data)
      .then((res) => {
        // console.log(res);
        commit("setPrenotazioni", res.data.data);
        // dispatch("alert/success", "Dati aggiornati correttamente!", {
        //   root: true,
        // });
      })
      .catch((error) => {
        dispatch(
          "alert/error",
          "non è stato possibile recuperare le prenotazioni",
          { root: true }
        );
      });
  },
  unsetCompany({ commit }) {
    commit("unsetCompany");
  },
};

const mutations = {
  logCompanyIn(state) {
    state.companyLoggedIn = true;
  },
  logCompanyOut(state) {
    state.companyLoggedIn = false;
  },
  setCompany(state, data) {
    state.company = data;
  },
  unsetCompany(state) {
    state.company = null;
    localStorage.removeItem("user");
    // localStorage.removeItem("token");
    localStorage.removeItem("company");
  },
  updateOpenings(state, data) {
    state.company.service_openings = data;
  },
  updateServiceOpenings(state, service, data) {
    state.company.servizi_attivi_orari[service] = data;
    // state.company = azienda;
  },
  updateStatoServizi(state, service, data) {
    state.company.stato_servizi[service] = data;
    state.company = azienda;
  },
  updatePrestazioni(state, data) {
    state.company.prestazioni_sanitarie.push(data);
    // state.company = azienda
  },
  setPrenotazioni(state, data) {
    state.prenotazioni = data;
  },
};
const getters = {
  getCompany(state) {
    // console.log("get Company getter");
    return state.company;
  },
  getStatoServizi(state) {
    return state.company.stato_servizi;
  },
  getOpenings(state) {
    return state.company.service_openings;
  },
  getServiceOpenings(state) {
    return state.company.servizi_attivi_orari;
  },
};

export const company = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
