<template>
  <div v-if="prenotazioni" class="p-1 p-md-2 p-xl-4">
    <h1
      class="text-left pb-1 mb-4 d-flex justify-content-start align-items-center page-title"
    >
      Gestione prenotazioni
      <b-icon
        icon="info-circle"
        id="status-explaination"
        variant="primary"
        class="ml-3 mr-4"
        font-scale="1"
      ></b-icon>
      <b-tooltip
        placement="bottom"
        variant="gestioniprenotazioni-status-tooltip"
        target="status-explaination"
        :triggers="$root.isMobile ? 'click' : 'hover'"
      >
        <!-- <p class="text-justify">Filtra le prenotazioni per stato. Gli stati disponibili sono:</p> -->
        <p class="text-justify">
          <b>In attesa</b>: prenotazioni per cui si attende conferma a seguito
          dell'inoltro o di modifiche della prenotazioni
        </p>
        <p class="text-justify">
          <b>Confermate</b>: prenotazioni già confermate e senza modifiche in
          fase di approvazione
        </p>
        <p class="text-justify">
          <b>Annullate</b>: prenotazioni non più valide per cui è stato
          richiesto l'annullamento.
        </p>
        <p class="mb-0 text-justify">
          <b>Evase</b>: prenotazioni per cui è stato completato il processo di
          gestione.
        </p>
      </b-tooltip>
    </h1>
    <b-row class="pt-0 pb-2 mx-auto mb-3 align-items-center">
      <b-row class="d-inline-flex mt-n3 w-100 align-items-center">
        <b-col
          v-for="statusOption in shortStatusOptions"
          :key="statusOption.text"
          class="mt-3 col-6 col-sm-3 col-lg-2 point align-self-start px-0"
        >
          <div
            :class="{
              'bg-secondary text-white':
                filterSetted.status === statusOption.value,
              'bg-white text-black': filterSetted.status !== statusOption.value,
            }"
            class="h-100 py-1 py-md-2 py-lg-3 shadow_2 general_border general_hover mr-3"
            @click="handleStatusOptionChange(statusOption.value)"
          >
            {{ statusOption.text }}
          </div>
        </b-col>
        <b-col class="px-0 mt-3 col-2">
          <b-button-group class="general_border">
            <b-button
              class="text-capitalize buttonbar"
              size="sm"
              @click="showCalendar = false"
              :variant="
                showCalendar == false ? 'secondary ' : 'outline-secondary'
              "
            >
              <b-icon icon="list" class="mx-2"></b-icon>
              <span v-if="buttonGroupOpen"></span>
            </b-button>
            <b-button
              class="text-capitalize buttonbar"
              size="sm"
              @click="showCalendar = true"
              :variant="
                showCalendar == true ? 'secondary ' : 'outline-secondary'
              "
            >
              <b-icon icon="calendar3-week-fill" class="mx-2"></b-icon>
              <span v-if="buttonGroupOpen"></span>
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
    </b-row>
    <b-row class="mb-3 mt-n3 flex-wrap align-items-center" align-v="stretch">
      <b-col class="filter-column mt-3 col-12 col-sm-6 col-md-4 col-xl-3">
        <div class="pl-0">
          <p :class="{ 'mb-1 pb-2': company.level === 1 }">Cerca per</p>
          <LabeledSearchInput
            :only-second="company.level !== 1"
            first-label="Codice Fiscale"
            short-first-label="C. Fiscale"
            :first-value="filterSetted.cf"
            :first-value-uppercase="true"
            first-placeholder="Codice Fiscale..."
            @first-value-changed="filterSetted.cf = $event"
            @switched-to-first="filterSetted.search = null"
            second-label="Codice Richiesta"
            short-second-label="C. Richiesta"
            :second-value="filterSetted.search"
            :second-value-uppercase="true"
            second-placeholder="Codice Richiesta..."
            @second-value-changed="filterSetted.search = $event"
            @switched-to-second="filterSetted.cf = null"
          />
        </div>
      </b-col>
      <b-col
        class="filter-column mt-3 col-12 col-sm-6 col-md-3 col-lg-2"
        id="periodo-col"
        v-if="!showCalendar"
      >
        <div class="pl-sm-4">
          <p>Periodo</p>
          <b-form-group class="col-12 px-0 my-auto">
            <b-form-select
              size="sm"
              class="text-left bor_1 general_border"
              name="show"
              v-model="filterSetted.range"
              :options="showOptions"
            >
            </b-form-select>
          </b-form-group>
        </div>
      </b-col>
      <b-col class="filter-column mt-3 col-12 col-sm-6 col-md-3 col-lg-2">
        <div class="pl-md-4">
          <p>Servizio</p>
          <b-form-select
            class="col-12 general_border"
            size="sm"
            v-model="filterSetted.service"
          >
            <b-form-select-option :value="undefined"
              >tutti</b-form-select-option
            >
            <b-form-select-option
              v-for="(service, ind) in filterOptions.service"
              :key="ind"
              :value="service.value"
            >
              {{ service.label }}</b-form-select-option
            >
          </b-form-select>
        </div>
      </b-col>
      <b-col
        class="filter-column mt-3 col-12 col-sm-6 col-md-2 col-lg-2"
        v-if="showCalendar"
      >
        <div class="pl-sm-4">
          <p>Visualizzazione</p>
          <b-form-group class="m-0 d-flex align-items-center">
            <b-form-checkbox
              class="d-flex align-items-center"
              size="sm"
              name="stato"
              :value="false"
              :unchecked-value="true"
              v-model="compactView"
            >
              <span class="d-flex align-items-center">Per esteso</span>
            </b-form-checkbox>
          </b-form-group>
        </div>
      </b-col>
      <b-col
        v-if="!showCalendar"
        class="filter-column mt-3 col-12 col-sm-6 col-md-2"
      >
        <div class="pl-sm-4">
          <p>Mostrane</p>
          <b-form-group class="m-0 col-md-9 p-0 text-left">
            <b-select
              class="text-left general_border"
              size="sm"
              name="vedi"
              v-model="filterSetted.take"
              :options="shortTakeOptions"
            >
            </b-select>
          </b-form-group>
        </div>
      </b-col>
    </b-row>

    <b-container fluid class="mx-0 px-0 mb-4">
      <b-row class="col-12 px-0 py-2">
        <b-col class="ml-auto general_border px-0 puntofarmaCard p-4">
          <Calendario
            v-if="showCalendar"
            hideFilters="true"
            :servicerif="1"
            :serviceFilter="filterSetted.service"
            :prestazioneFilter="filterSetted.prestazione"
            :statusFilter="filterSetted.status"
            :searchFilter="filterSetted.search"
            :cfFilter="filterSetted.cf"
            :compactView="compactView"
            :startWithDate="startWithDate"
          />
          <template v-else>
            <div class="m-0 p-0 general_border puntofarmaCard"></div>

            <div
              v-if="loading || updatingData"
              class="d-flex w-100 justify-content-center"
              style="position: absolute"
            >
              <p v-if="updatingData" class="mx-1">
                <b-spinner
                  small
                  variant="info"
                  type="grow"
                  label="Aggiornamento in corso"
                  class="ml-2"
                ></b-spinner>
                <span>Aggiornamento in corso...</span>
              </p>
              <p v-else-if="loading">
                <b-spinner
                  variant="secondary"
                  label="loading"
                  class="d-flex justify-content-center"
                ></b-spinner>
              </p>
            </div>

            <div>
              <!-- <Calendario :servicerif="1"></Calendario> -->
              <b-table
                class="general_border_17 text-center"
                borderless
                hover
                responsive
                :fields="fields"
                :items="prenotazioni"
                :sort-by.sync="sortBy"
                :sort-desc.sync="filterSetted.indesc"
                foot-clone
              >
                <template #cell(icona)="row" v-if="!$route.query.scope">
                  <p class="text-right mb-0">
                    <span
                      v-if="row.item.handled"
                      title="prenotazione gestita"
                      class="bg-primary pt-1 px-1 general_border mr-1"
                    >
                      <b-icon
                        icon="check-all"
                        font-scale="1.5"
                        variant="white"
                      ></b-icon>
                    </span>
                    <b-icon
                      :icon="icons[row.item.icona]"
                      font-scale="1.1"
                    ></b-icon>
                  </p>
                </template>
                <template #cell(tipo)="row" v-if="!$route.query.scope">
                  <p class="text-left">
                    {{
                      row.item.orderable.prestazione
                        ? row.item.orderable.prestazione.name
                        : "ordine"
                    }}
                  </p>
                </template>
                <template
                  #cell(tipo)="row"
                  v-else-if="$route.query.scope == 'prestazione'"
                >
                  <span class="" v-if="row.item.orderable.prestazione"
                    >{{ row.item.orderable.prestazione.name }}
                  </span>
                </template>
                <template #cell(codice_richiesta)="row">
                  <span class=""> {{ row.item.codice_richiesta }}</span>
                </template>
                <template #cell(data)="row">
                  <span class="">
                    {{ row.item.data_inizio | moment("DD/MM/YY HH:mm") }}</span
                  >
                </template>
                <template #cell(utente)="row">
                  <template v-if="row.item.subjectable.company">
                    <!-- <span class="text-uppercase">
                      {{ row.item.subjectable.anagrafica.name }}
                      {{ row.item.subjectable.anagrafica.surname }}</span
                    > -->
                    <span class="text-uppercase">
                      {{ row.item.subjectable.company.denominazione }}</span
                    >
                    <span class="" v-if="row.item.subjectable.company.telefono"
                      ><br />
                      tel:
                      {{ row.item.subjectable.company.telefono }}</span
                    >
                  </template>
                  <template v-else>
                    <span class="text-uppercase">
                      {{ row.item.subjectable.anagrafica.cf }}</span
                    ><span
                      class=""
                      v-if="row.item.subjectable.anagrafica.tel_number"
                      ><br />
                      tel:
                      {{ row.item.subjectable.anagrafica.tel_number }}</span
                    >
                  </template>
                </template>

                <template #cell(stato)="row">
                  <span class="pt-1 text-uppercase">{{ row.item.stato }}</span>
                  <!-- <span class="text-danger ml-1" v-if="row.item.unreadMessages"
                    ><b-icon icon="chat-dots-fill" font-scale="1.4"></b-icon>
                    <b-badge
                     
                      class="fs_badge ml-1"
                      variant="danger"
                      >{{ row.item.unreadMessages }}</b-badge
                    >
                  </span> -->
                </template>
                <template #cell(unreadMessages)="row">
                  <span
                    class="text-primary ml-1 point"
                    v-if="row.item.unreadMessages"
                    @click="showDetail(row.item)"
                    ><b-icon icon="chat-dots-fill" font-scale="1.4"></b-icon>
                    <b-badge class="fs_badge ml-1" variant="danger">{{
                      row.item.unreadMessages
                    }}</b-badge>
                  </span>
                </template>
                <template #cell(azioni)="row">
                  <b-row class="justify-content-center mb-n1">
                    <!-- Bottone dettagli -->
                    <b-button
                      v-b-popover.hover.top="'Vedi Dettagli'"
                      size="sm"
                      variant="info px-3 fs-6 mx-1 mt-1"
                      @click="showDetail(row.item)"
                    >
                      <b-icon
                        v-if="row.detailsShowing"
                        icon="x-circle"
                      ></b-icon>
                      <b-icon v-else icon="eye"></b-icon>
                    </b-button>

                    <b-button
                      v-b-popover.hover.top="'Annulla'"
                      v-if="
                        row.item.status >= 1 && Math.abs(row.item.status) !== 4
                      "
                      size="sm"
                      variant="info px-3 fs-6 mx-1 mt-1"
                      @click="openCancel(row.item)"
                    >
                      <b-icon icon="x-circle"></b-icon>
                    </b-button>

                    <!-- Bottone evasione -->
                    <b-button
                      v-b-popover.hover.top="'Evadi'"
                      v-if="row.item.status !== 4 && row.item.status > 0"
                      @click="openEvadi(row.item)"
                      size="sm"
                      variant="secondary px-3 fs-6 mx-1 mt-1"
                    >
                      <b-icon icon="check"></b-icon>
                    </b-button>
                  </b-row>
                </template>
              </b-table>
            </div>
            <p
              class="mb-0"
              v-if="prenotazioni !== null && prenotazioni.length == 0"
            >
              Nessuna richiesta qui.
            </p>

            <div class="d-flex ml-4">
              <span class="" v-if="total > 0"
                >{{ fromPage }}-{{ toPage }} di {{ total }}
              </span>
            </div>

            <b-pagination
              class="customPagination general_border mt-2"
              v-model="filterSetted.page"
              :total-rows="total"
              :per-page="per_page"
              align="center"
              @change="paginationChanged"
              :size="$root.isMobile ? 'sm' : 'lg'"
            >
            </b-pagination>
          </template>
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      size="lg"
      centered
      ref="modal-evasione"
      title="Evadi Richiesta"
      hide-footer
    >
      <div v-if="toEvadere">
        <p class="text-left text-font-grey">
          Numero Richiesta: #{{ toEvadere.numero }} <br />
        </p>
        <p class="text-left text-font-grey">
          Codice Richiesta:
          {{ toEvadere.codice_richiesta }} <br />
        </p>

        <b-form-input
          name="detail"
          class="mx-auto"
          v-model="evasione.hash"
          type="text"
          hidden
        >
        </b-form-input>
        <b-form-group
          label="Aggiungi Annotazione"
          label-for="detail"
          class="text-left fs-6 text-secondary"
        >
          <b-form-input
            name="detail"
            class="mx-auto"
            v-model="evasione.details"
            type="text"
          >
          </b-form-input>
        </b-form-group>
        <div class="text-center my-2 pt-4">
          <!-- <b-button
            type="button"
            class="mx-2"
            variant="outline-danger"
            @click="$refs['modal-evasione'].hide()"
            >Annulla</b-button
          > -->
          <b-button
            type="button"
            class="modal-footer-button"
            variant="outline-primary"
            @click="evadiThis"
          >
            Conferma</b-button
          >
        </div>
      </div>
    </b-modal>

    <!-- modale visualizza dettagli -->
    <b-modal
      size="xl"
      centered
      id="modal-resdetails-prenotazioni"
      ref="modal-resdetails-prenotazioni"
      hide-footer
      hide-header
      scrollable
      body-class="p-0"
      @hidden="selectedRow = null"
    >
      <!-- <b-spinner variant="secondary" label="loading" v-if="loading"></b-spinner> -->
      <DettaglioPrenotazioneOperatore
        v-if="selectedRow"
        :chat-recipient-hash="selectedRow.subjectable.hashid"
        chat-recipient-type="user"
        :reservation="selectedRow"
        @close-modal="closeModal()"
        @fetch-data="fetchData()"
        @handle-update-request="handleUpdateRequest()"
        @handle-status-update="handleStatusUpdate($event)"
        @update-request-response="sendOrderResponse($event)"
      />
    </b-modal>
    <b-modal
      size="xl"
      hide-footer
      centered
      id="modal-delete-confirm"
      title="Conferma Annullamento"
    >
      <PickMessageForm
        :messages="cancellationMessages"
        confirm-message="Sei sicuro di voler annullare questo appuntamento?"
        @cancellation-confirmed="cancelReservation($event)"
        @refused-to-confirm="$bvModal.hide('modal-delete-confirm')"
      />
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { reservationService } from "@/_services";
import { CANCELLATION_MESSAGES } from "@/_utils/constants.js";
import Calendario from "@/components/prenotazioni/calendario/Calendario.vue";
import DettaglioPrenotazioneOperatore from "@/components/prenotazioni/DettaglioPrenotazioneOperatore.vue";
import PickMessageForm from "@/components/prenotazioni/form/PickMessageForm.vue";
import LabeledSearchInput from "@/components/prenotazioni/calendario/LabeledSearchInput.vue";
export default {
  name: "HomePrenotazioni",
  components: {
    Calendario,
    DettaglioPrenotazioneOperatore,
    PickMessageForm,
    LabeledSearchInput,
  },
  data() {
    return {
      pickedFilter: "codiceFiscale",
      statusExplaination: {
        "In corso": "Le prenotazioni ricevute o confermate dall'operatore",
        "In attesa":
          "Le prenotazioni che hanno subìto variazioni che devono essere confermate",
        Annullate:
          "Le prenotazioni non più attive per cui è stato chiesto un annullamento",
        Evase: "Le prenotazioni la cui gestione stata è completata",
      },
      startWithDate: null,
      actionName: null,
      message: null,
      selectedPrestazione: undefined,
      per_page: 10,
      icons: {
        0: "bag",
        1: "camera-video",
        2: "file-ppt",
        3: "chat-text",
        4: "screwdriver",
        5: "droplet-fill",
        6: "flower1",
        7: "emoji-smile",
        8: "exclude",
        9: "egg-fried",
        10: "gem",
        11: "heart-fill",
        12: "hourglass-split",
        13: "journal-medical",
        14: "life-preserver",
        15: "lock-fill",
        16: "moon",
        17: "node-minus-fill",
        18: "x-diamond-fill",
        19: "cart-fill",
        20: "bug-fill",
        21: "brightness-high-fill",
        22: "bell-fill",
      },
      statusModalIcons: {
        confermato: "check-circle",
        ricevuto: "archive",
        annullato: "x-circle",
        rifiutato: "x-circle",
        "in attesa di risposta": "question-circle",
        evaso: "calendar2-check",
      },
      statusModalVariants: {
        confermato: "secondary",
        ricevuto: "primary",
        annullato: "danger",
        rifiutato: "danger",
        "in attesa di risposta": "warning",
        evaso: "primary",
      },

      showFilters: true,
      compactView: false,
      showCalendar: false,
      compactCalendar: true,
      selectedStatusPick: null,
      prenotazioni: null,
      fields: [
        { key: "icona", label: "" },
        { key: "tipo", label: "" },
        { key: "codice_richiesta", label: "C.Richiesta" },
        { key: "data", label: "Data", sortable: true },
        { key: "utente", label: "Cliente" },
        { key: "stato", label: "Stato" },
        { key: "unreadMessages", label: "" },
        { key: "azioni", label: "" },
      ],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      // statusPositive: {
      //   1: "",
      //   2: "",
      //   3: "",
      //   4: "",
      //   5: "",
      //   6: "",
      // },
      // statusNegative: {
      //   1: "",
      //   2: "",
      //   3: "",
      //   4: "",
      // },
      filterOptions: {
        status: ["in attesa", "confermate", "annullate", "evase"],
        service: [
          { label: "prestazione", value: "prestazione" },
          { label: "prodotto", value: "prodotto" },
        ],
        search: null,
      },
      filterSetted: {
        status: "tutte",
        service: undefined,
        search: undefined,
        cf: undefined,
        position_ref: 1,
        page: 1,
        take: 10,
        range: "mese",
        indesc: true,
        prestazione: null,
        unread: true,
      },
      sortBy: "data",
      longTakeOptions: [
        { value: 10, text: "Mostra: 10" },
        { value: 25, text: "Mostra: 25" },
        { value: 50, text: "Mostra: 50" },
        { value: 100, text: "Mostra: 100" },
      ],
      shortTakeOptions: [10, 25, 20, 100],
      shortStatusOptions: [
        { value: "tutte", text: "Tutte" },
        { value: "in attesa", text: "In Attesa" },
        { value: "confermate", text: "Confermate" },
        { value: "annullate", text: "Annullate" },
        { value: "evase", text: "Evase" },
      ],
      showOptions: [
        { value: "oggi", text: "oggi" },
        { value: "domani", text: "domani" },
        { value: "settimana", text: "questa settimana" },
        { value: "mese", text: "questo mese" },
        { value: "30g", text: "ultimi 30 giorni" },
        { value: "next30", text: "prossimi 30 giorni" },
        { value: "anno", text: "quest'anno" },
        { value: null, text: "tutti" },
      ],
      serviceOptions: [
        { value: null, text: "tipo servizio" },
        "prestazione",
        "prodotto",
      ],
      calendarViewOptions: [
        { value: true, text: "Visualizzazione Compatta" },
        { value: false, text: "Visualizzazione Estesa" },
      ],
      cancellationMessages: CANCELLATION_MESSAGES,
      countPrev: 0,
      count: 0,
      dataToCancel: null,
      typeToCancel: null,
      total: 0,
      fromPage: 0,
      toPage: 0,
      timer: null,
      updatingData: false,
      loading: true,
      evasione: {
        details: null,
        hash: null,
      },
      toEvadere: null,
      toCancellare: null,
      titleLabels: {
        prestazione: "Prestazioni",
        ritiro: "Ritiri",
        prodotto: "Ordini",
        tutte: "Tutte",
      },
      selectedRow: null,
      buttonGroupOpen: true,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["user"]),
    ...mapState("company", ["company"]),
    servIcon: function () {
      switch (this.filterSetted.service) {
        case "prestazione":
          return "file-ppt";
        case "prodotto":
          return "bag";
        case "teleconsulto":
          return "camera-video";
        default:
          return "";
      }
    },
    inputVModel() {
      if (this.pickedFilter === "codiceFiscale") {
        return this.filterSetted.cf;
      } else {
        return this.filterSetted.search;
      }
    },
  },
  watch: {
    filterSetted: {
      handler() {
        if (this.filterSetted.service !== "prestazione") {
          this.selectedPrestazione = undefined;
          this.filterSetted.prestazione = undefined;
        }
        if (!this.showCalendar) this.fetchData();
      },
      deep: true,
    },
    $route(val) {
      // console.log("val route change", val);
      if (
        val.query.scope &&
        this.filterOptions.service.includes(val.query.scope)
      ) {
        // console.log("val route change", val);
        this.filterSetted.service = val.query.scope;
        this.filterSetted.status = "in attesa";
        this.filterSetted.prestazione = null;
      } else {
        this.filterSetted.service = undefined;
        this.filterSetted.status = "in attesa";
        this.filterSetted.prestazione = null;
      }
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    handleStatusOptionChange(value) {
      this.filterSetted.status = value;
    },
    closeModal() {
      this.$bvModal.hide("modal-resdetails-prenotazioni");
    },
    cancelReservation(message = null) {
      this.$bvModal.hide("modal-delete-confirm");
      let data = {
        confirmed: false,
        message,
        action: this.actionName,
      };
      this.handleStatusUpdate(data, this.toCancel);
    },
    searchPrestazione() {
      this.filterSetted.prestazione = this.selectedPrestazione
        ? this.selectedPrestazione.name
        : undefined;
      this.fetchData();
    },
    fetchData() {
      // console.log("fetching prenotazioni");
      this.loading = true;
      var self = this;
      reservationService
        .fetchPrenotazioni(this.filterSetted)
        .then(function (response) {
          // console.log("response fetch prenotazioni", response);
          self.prenotazioni = response.data.data.data;
          self.filterSetted.page = response.data.data.current_page;
          self.per_page = response.data.data.per_page;
          self.total = response.data.data.total;
          self.fromPage = response.data.data.from;
          self.toPage = response.data.data.to;
          if (
            !self.$route.query.scope &&
            self.filterSetted.status == "in corso" &&
            self.filterSetted.range == null &&
            !self.filterSetted.service &&
            !self.filterSetted.search &&
            !self.filterSetted.cf
          ) {
            self.count = response.data.data.total;
            var diff = self.count - self.countPrev;
            if (self.countPrev !== 0 && self.countPrev < self.count) {
              self.infoAlert(diff + " nuove richieste");
            }
            self.countPrev = self.count;
          }
          self.updatingData = false;
          self.loading = false;
        })
        .catch(function (error) {
          self.updatingData = false;
          self.errorAlert("non è stato possibile prelevare le richieste");
          // console.log("error fetch prenotazioni", error);
          self.loading = false;
        });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    handleStatusUpdate(statusData, reservation = this.selectedRow) {
      var self = this;
      if (!statusData.confirmed) {
        this.loading = true;
        var form = statusData.message ? { message: statusData.message } : {};
        reservationService
          .companyCancelOrReject(form, statusData.action, reservation.hashid)
          .then(function (re) {
            self.$bvModal.hide("modal-resdetails-prenotazioni");
            window.scrollTo(0, 0);
            self.successAlert("Richiesta aggiornata con successo");
            self.fetchData();
          })
          .catch(function (error) {
            self.$bvModal.hide("modal-resdetails-prenotazioni");
            window.scrollTo(0, 0);
            self.errorAlert("non è stato possibile effettuare l'operazione");
            self.loading = false;
          });
      } else {
        this.loading = true;
        reservationService
          .companyConfirmOrder(reservation.hashid)
          .then(function (re) {
            self.$bvModal.hide("modal-resdetails-prenotazioni");
            window.scrollTo(0, 0);
            self.successAlert("Richiesta aggiornata con successo");
            self.fetchData();
          })
          .catch(function (error) {
            self.$bvModal.hide("modal-resdetails-prenotazioni");
            window.scrollTo(0, 0);
            self.errorAlert("non è stato possibile effettuare l'operazione");
            self.loading = false;
          });
      }
    },
    evadiThis(hash = null) {
      // console.log("richiesta da evadere ", hash);
      hash = this.evasione.hash;
      var self = this;
      reservationService
        .closeOrder(this.evasione)
        .then(function (response) {
          self.$refs["modal-evasione"].hide();
          // self.$refs["resev-details"].hide();
          self.evasione.details = null;
          self.evasione.hash = null;
          window.scrollTo(0, 0);
          self.successAlert("prenotazione evasa con successo");
          // console.log("response fetch prenotazioni", response);
          self.fetchData();
        })
        .catch(function (error) {
          self.$refs["modal-evasione"].hide();
          // self.$refs["resev-details"].hide();
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile evadere la prenotazione");
          // console.log("error fetch prenotazioni", error);
        });
      //call evadi
      //then alert success/error
      //then fetch
    },
    openEvadi(item) {
      this.toEvadere = item;
      this.evasione.hash = item.hashid;
      this.$refs["modal-evasione"].show();
    },
    openCancel(item) {
      this.toCancel = item;
      // console.log(this.toCancel);
      this.$bvModal.show("modal-delete-confirm");
      if (item.status === 1) this.actionName = "reject";
      else if (Number.isInteger(item.status) && item.status > 1)
        this.actionName = "cancel";
      // console.log(this.actionName);
    },
    showDetail(item) {
      this.selectedRow = item;
      this.$bvModal.show("modal-resdetails-prenotazioni");
      // console.log("pagination changed");
    },
    sendOrderResponse(data, reservation = this.selectedRow) {
      var form = {
        hashid: reservation.hashid,
        company_notes: data.company_notes,
        data_inizio: data.newDateTime,
        order: data.detail,
        status: 5,
      };
      // console.log(form);
      var self = this;
      reservationService
        .updateReservation(form)
        .then(function (response) {
          // console.log(response);
          self.$bvModal.hide("modal-resdetails-prenotazioni");
          window.scrollTo(0, 0);
          self.successAlert("risposta inviata con successo");
          self.fetchData();
        })
        .catch(function (error) {
          // console.log(error);
          self.$bvModal.hide("modal-resdetails-prenotazioni");
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile inviare la risposta");
        });
    },
    paginationChanged() {},
    handleUpdateRequest() {
      this.$bvModal.hide("modal-resdetails-prenotazioni");
      this.successAlert("richiesta inviata con successo");
      this.fetchData();
    },
  },
  created() {
    // console.log("created prenotazioni", reservationService);

    if (
      this.$route.query.scope &&
      this.filterOptions.service.includes(this.$route.query.scope)
    ) {
      // console.log("changing scope");
      this.filterSetted.service = this.$route.query.scope;
    }

    if (this.$route.query) {
      if (this.$route.query.status)
        this.filterSetted.status = this.$route.query.status;

      if (this.$route.query.service)
        this.filterSetted.service = this.$route.query.service;

      if (this.$route.query.search)
        this.filterSetted.search = this.$route.query.search;

      if (this.$route.query.startWithCalendar) this.showCalendar = true;

      if (this.$route.query.startWithDate)
        this.startWithDate = this.$route.query.startWithDate;

      if (this.$route.query.servicerif)
        this.servicerif = this.$route.query.servicerif;
    }

    this.fetchData();

    if (this.$root.isTabletOrLaptop || this.$root.isMobile) {
      this.calendarViewOptions = [
        { value: true, text: "Compatta" },
        { value: false, text: "Estesa" },
      ];
    } else
      this.calendarViewOptions = [
        { value: true, text: "Visualizzazione Compatta" },
        { value: false, text: "Visualizzazione Estesa" },
      ];
  },
  mounted() {
    var lib = document.createElement("script");
    lib.setAttribute("id", "ls_lib");
    lib.setAttribute("type", "text/javascript");
    lib.setAttribute(
      "src",
      "https://cdn.jsdelivr.net/npm/fm.liveswitch@latest/fm.liveswitch.js"
    );
    document.head.appendChild(lib);

    // console.log("created prenotazioni");
    this.timer = setInterval(() => {
      if (this.$refs.dett == undefined) {
        this.updatingData = true;
        this.fetchData();
      }
    }, 60000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
    var lib = document.getElementById("ls_lib");
    document.head.removeChild(lib);
  },
};
</script>
<style lang="scss" scoped>
thead {
  border-bottom: 1px solid #e0ecde !important;
}

.search-icon {
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  position: absolute;
  z-index: 100;
}

@media screen and (max-width: 1000px) {
  .second-line-column-reserv-modal {
    margin-right: 1rem;
  }
}

@media screen and (min-width: 1000px) {
  .second-line-column-reserv-modal {
    margin-right: 2rem;
  }
}

@media screen and (min-width: 992px) {
  #periodo-col {
    min-width: 200px;
  }
}
</style>
