<template>
  <div v-if="canBeShown">
    <span @click="$bvModal.show('note-update')" class="point">
      <b-icon icon="pencil-fill" variant="primary"></b-icon>
    </span>
    <b-modal
      id="note-update"
      title="Aggiorna le tue note"
      hide-footer
      centered
      size="lg"
    >
      <b-form @submit.prevent="doNoteUpdate" @reset="resetForm">
        <label cols="2" for="notes" class="">Nuova nota:</label>
        <b-form-input
          cols="8"
          focused
          type="text"
          name="notes"
          v-model="newNotes"
          required
        >
        </b-form-input>
        <div class="text-right pt-3">
          <b-button
            variant="info modal-footer-button"
            title="salva"
            type="reset"
            class="mx-2"
            >Annulla</b-button
          >
          <b-button
            cols="2"
            variant="outline-primary modal-footer-button"
            title="salva"
            type="submit"
            class="mx-2"
            >Salva</b-button
          >
        </div>
      </b-form>
      <p v-if="showError" class="text-danger">
        Non è stato possibile aggiornare le note
      </p>
    </b-modal>
  </div>
</template>
<script>
import * as moment from "moment/moment"
import { reservationService } from "@/_services"

export default {
  name: "UpdateNotesComponent",
  props: ["hashid", "reserdate"],
  data() {
    return {
      newNotes: null,
      showError: false,
      canBeShown: false
    }
  },
  methods: {
    doNoteUpdate() {
      //
      var self = this
      var data = { notes: this.newNotes }
      reservationService
        .updateReservationNotes(data, this.hashid)
        .then(function (res) {
          self.resetForm()
          self.$emit("note-updated", res.data.data)
        })
        .catch(function (err) {
          self.showError = true
        })
    },
    resetForm() {
      this.newNotes = null
      this.$bvModal.hide("note-update")
    }
  },
  mounted() {
    var now = moment()
    var date = moment(this.reserdate)
    this.canBeShown = now.isSameOrBefore(date)
  }
}
</script>
