<template>
  <div class="py-2 puntofarmaCard h-100 px-2 p-xl-4">
    <h5 class="pl-4 text-left text-capitalize">{{ title }}</h5>
    <canvas :id="idappend"></canvas>
  </div>
</template>
<script>
import { Chart, registerables } from "chart.js";
import "chartjs-adapter-moment"; // or another adapter to avoid moment
Chart.register(...registerables);
export default {
  name: "LineBarChart",
  props: ["idappend", "datatoshow", "bardatatoshow", "title"],
  data() {
    return {
      chartData: {
        type: "scatter",
        data: {
          labels: [
            "Gennaio",
            "Febbraio",
            "Marzo",
            "Aprile",
            "Maggio",
            "Giugno",
            "Luglio",
            "Agosto",
            "Settembre",
            "Ottobre",
            "Novembre",
            "Dicembre",
          ],
          datasets: [
            {
              type: "line",
              label: "Terapie",
              data: this.datatoshow,
              fill: true,
              borderColor: "rgb(116, 103, 240)",
              backgroundColor: "rgba(116, 103, 240,0.5)",
              tension: 1,
              // pointStyle: "rect",
              order: 1,
            },
            {
              type: "bar",
              label: "Alerts",
              data: this.bardatatoshow,
              backgroundColor: "rgba(75, 192, 192,0.7)",
              borderColor: "rgb(75, 192, 192)",
              borderWidth: 1,
              order: 2,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      },
    };
  },
  mounted() {
    const ctx = document.getElementById(this.idappend);
    new Chart(ctx, this.chartData);
  },
};
</script>