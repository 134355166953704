<template>
  <b-row
    align-v="center"
    v-if="reference"
    class="bg-primary mt-2 text-white general_border"
  >
    <template v-if="editable">
      <b-col class="px-1 text-center">
        <span
          class="p-1 point"
          title="modifica note"
          @click="$bvModal.show('note_' + reference.hashid + '_' + soglia)"
        >
          <!-- <b-icon icon="pencil-square" variant="primary" font-scale="1.3"></b-icon > -->
          Note soglia
        </span>
      </b-col>
    </template>
    <b-modal
      :id="'note_' + reference.hashid + '_' + soglia"
      title="Note di soglia"
      class="text-center"
      size="lg"
      hide-footer
      centered
    >
      <p class="text-justify px-1">
        In questi spazi puoi inserire delle note per indicare, in caso di
        rilevazione del parametro nella soglia selezionata, eventuali azioni da
        suggerire o intraprendere ai fini diagnostici (Protocollo diagnostico)
        e/o operativi (Protocollo operativo). Valorizzare uno solo oppure
        entrambi i campi.
      </p>

      <b-form @submit.prevent="saveEdits" @reset="resetFields">
        <div class="col-12">
          <h6 class="">Protocollo diagnostico:</h6>
          <b-form-textarea
            v-model="edit.diagnostic_notes"
            placeholder="Si raccomanda l'indagine per, il consulto con..."
            class="p-2 border general_border border-primary shadow_8 bg-extralight2"
            rows="9"
            :required="!edit.action_notes || edit.action_notes.length < 3"
          >
          </b-form-textarea>
          <h6 class="mt-4">Protocollo operativo:</h6>
          <b-form-textarea
            v-model="edit.action_notes"
            placeholder="Si raccomanda il controllo di, l'assunzione di, l'interruzione di..."
            class="p-2 border general_border border-primary shadow_8 bg-extralight2"
            rows="9"
            :required="
              !edit.diagnostic_notes || edit.diagnostic_notes.length < 3
            "
          >
          </b-form-textarea>
        </div>
        <b-row class="justify-content-center mt-2 pt-3">
          <b-button
            size="sm"
            variant="outline-info"
            class="mx-2 modal-footer-button"
            type="reset"
            >Annulla</b-button
          >
          <b-button
            size="sm"
            variant="primary"
            class="mx-2 modal-footer-button"
            type="submit"
            >Salva note</b-button
          >
        </b-row>
      </b-form>
    </b-modal>
  </b-row>
</template>
<script>
export default {
  name: "ThresholdNotesEditor",
  props: ["reference", "editable", "soglia"],
  data() {
    return {
      edit: {
        diagnostic_notes: null,
        action_notes: null,
      },
      editModeOn: false,
      signValue: 0,
      refSignOptions: [
        { text: "&le;", value: 0 },
        { text: "&ge;", value: 1 },
        { text: "-", value: 2 },
      ],
    };
  },
  computed: {
    validMin() {
      console.log(this.signValue === 0 ? "true" : "false");
      if (this.signValue === 0) {
        if (this.edit.min_ref !== null && this.edit.max_ref !== null) {
          return parseFloat(this.edit.max_ref) >= parseFloat(this.edit.min_ref);
        }
      }
      return null;
    },
  },
  methods: {
    saveEdits() {
      this.$emit("save-edits", this.edit);
      this.$bvModal.hide("note_" + this.reference.hashid);
    },
    resetFields() {
      this.$bvModal.hide("note_" + this.reference.hashid);
      // this.edit.diagnostic_notes = this.reference.diagnostic_notes
      // this.edit.action_notes = this.reference.action_notes
    },
  },
  created() {
    this.edit.diagnostic_notes = this.reference.diagnostic_notes;
    this.edit.action_notes = this.reference.action_notes;
  },
  mounted() {},
};
</script>
