<template>
  <div v-if="detail" class="h-100">
    <!-- <b-row
      id="row-dettaglio-appuntamento"
      align-v="stretch"
      class="mt-n2 flex-wrap flex-md-nowrap"
    > -->
    <b-row align-v="stretch" class="mt-n2 flex-wrap">
      <b-col
        cols="12"
        lg="6"
        v-if="allegati && allegati.length > 0"
        class="mt-2"
      >
        <div class="p-3 detail-modal-card h-100">
          <p class="font-weight-bolder detail-modal-card-titles">
            ALLEGATI SCARICABILI
          </p>
          <p class="m-0 p-0 text-capitalize">
            <a
              v-for="(allegato, ind) in allegati"
              :key="ind"
              :href="allegato.full_path"
              target="__blank"
              class="point"
              :title="'apri ' + allegato.name + ' in un\'altra scheda'"
            >
              <b-badge
                variant="primary"
                class="my-2 py-2 px-2 mr-2 text__control"
              >
                <span class="">{{ allegato.name }}</span>
              </b-badge>
            </a>
          </p>
        </div>
      </b-col>
      <b-col
        cols="12"
        lg="6"
        class="mt-2 px-0"
        v-if="survey !== null && survey.response"
      >
        <div
          class="p-3 detail-modal-card d-flex flex-column justify-content-start align-items-start h-100"
        >
          <p class="font-weight-bolder detail-modal-card-titles">
            DOCUMENTAZIONE ALLEGATA
          </p>

          <p class="d-flex justify-content-between">
            <b-button
              variant="outline-primary"
              size="sm"
              class="px-2 mr-2 mt-2"
              @click="$refs['show-filleditem'].show()"
              >Visualizza</b-button
            >
            <b-button
              v-if="enableModificaQuestionario"
              variant="outline-primary"
              size="sm"
              class="px-2 mr-2 mt-2"
              @click="navigateToEdit(survey.hashid)"
              >Modifica</b-button
            >
          </p>
        </div>
      </b-col>
      <b-col
        cols="12"
        xl="8"
        id="col-details-details"
        class="mt-2"
        v-if="detail.details"
      >
        <div
          class="p-3 mr-4 detail-modal-card d-flex flex-column justify-content-start align-items-start h-100"
        >
          <p class="font-weight-bolder detail-modal-card-titles">
            NOTE SULLA PRESTAZIONE:
          </p>
          <p class="text-dark-grey m-0 p-0">
            {{ detail.details }}
          </p>
        </div>
      </b-col>
      <b-col
        cols="12"
        xl="8"
        class="mt-2 long__txt__under__lg"
        v-if="company_notes"
      >
        <div
          class="p-3 detail-modal-card d-flex flex-column justify-content-start align-items-start h-100"
        >
          <p class="d-flex flex-column">
            <span class="font-weight-bolder detail-modal-card-titles"
              >NOTE OPERATORE</span
            >
          </p>
          <p class="m-0 p-0 text-dark-grey text-left">
            {{ company_notes }}
          </p>
        </div>
      </b-col>
      <b-col cols="12" xl="4" class="mt-3" v-if="thereIsOneButton">
        <div
          class="px-0 d-flex flex-column justify-content-start align-items-start h-100"
        >
          <template v-if="stato === 5">
            <b-button
              size="sm"
              @click="setAction('reject')"
              type="button"
              variant="outline-danger modal-body-button mb-2 w-lg-100"
              >Rifiuta Modifiche</b-button
            >
            <b-button
              size="sm"
              @click="confirmRequest"
              type="button"
              variant="outline-primary modal-body-button mb-2"
              >Conferma Prenotazione</b-button
            >
          </template>
          <template v-else>
            <b-button
              v-if="stato > 1 && checkValidity"
              @click="setAction('cancel')"
              type="button"
              size="sm"
              variant="outline-danger modal-body-button mb-2"
            >
              <b-spinner
                variant="primary"
                label="loading"
                v-if="loading"
              ></b-spinner>
              <span v-else> Annulla Appuntamento</span>
            </b-button>
          </template>
        </div>
      </b-col>
    </b-row>

    <b-modal
      size="xl"
      hide-footer
      centered
      id="modal-confirm"
      ref="modal-confirm"
      title="Conferma Annullamento"
    >
      <PickMessageForm
        :messages="cancellationMessages"
        confirm-message="Sei sicuro di voler annullare questo appuntamento?"
        @cancellation-confirmed="cancelRequest($event)"
        @refused-to-confirm="$bvModal.hide('modal-confirm')"
      />
    </b-modal>
    <b-modal
      ref="show-filleditem"
      centered
      hide-footer
      title="Format Documentazione"
      size="xl"
    >
      <FilledSurveyShower
        v-if="survey"
        :response="survey.response"
        :score="survey.score"
      />
    </b-modal>
  </div>
</template>
<script>
import * as moment from "moment/moment"
import { supportService } from "@/_services"
import FilledSurveyShower from "@/components/attivita/FilledSurveyShower.vue"
import { CANCELLATION_MESSAGES_CITTADINO } from "@/_utils/constants.js"
import PickMessageForm from "@/components/prenotazioni/form/PickMessageForm.vue"

export default {
  name: "DetailAppuntamento",
  props: [
    "detail",
    "stato",
    "datainizio",
    "quest",
    "suspendedSurvey",
    "company_notes"
  ],
  components: { FilledSurveyShower, PickMessageForm },
  data() {
    return {
      cancellationMessages: CANCELLATION_MESSAGES_CITTADINO,
      actionName: null,
      loading: false,
      allegati: null,
      survey: null
    }
  },
  computed: {
    checkValidity: function () {
      var data = moment(this.datainizio)
      return moment().isBefore(data)
    },
    thereIsOneButton() {
      return (
        Math.abs(this.stato) !== 4 &&
        (this.stato === 5 || (this.stato > 1 && this.checkValidity))
      )
    },
    enableModificaQuestionario() {
      return this.checkValidity && this.stato > 0 && Math.abs(this.stato) !== 4
    }
  },
  methods: {
    confirmRequest() {
      var data = {
        confirmed: true,
        action: "confirm"
      }
      this.$emit("update-request-status", data)
    },
    navigateToEdit(surveyHash) {
      this.$router.push({
        name: "appuntamentiASL",
        params: {
          quest: surveyHash,
          section: "edit-questionario"
        }
      })
    },
    setAction(action) {
      this.actionName = action
      this.$refs["modal-confirm"].show()
    },
    cancelRequest(message = null) {
      this.loading = true
      var data = {
        confirmed: false,
        message,
        action: this.actionName
      }
      this.$refs["modal-confirm"].hide()
      this.$emit("update-request-status", data)
    },
    fetchAllegati() {
      var self = this
      supportService
        .getHealthServiceAllegati(this.detail.health_service.hashid)
        .then(function (res) {
          self.allegati = res.data.data
        })
        .catch(function (err) {
          console.log(err)
        })
    },
    downloadOne() {
      var self = this
      supportService
        .downloadFilledSurvey(this.quest.hashid)
        .then(function (response) {
          let blob = new Blob([response.data])
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download =
            self.detail.prestazione.name + "_" + "questionario.pdf"
          link.click()
        })
        .catch(function (err) {
          console.log(err)
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare il questionario")
        })
    }
  },
  watch: {
    quest(val) {
      if (val) {
        this.survey = val
      } else {
        this.survey = null
      }
    }
  },
  created() {
    this.fetchAllegati()
    // console.log(this.quest);
    if (this.quest) {
      this.survey = this.quest
    }
  }
}
</script>
<style lang="scss" scoped>
.simple__input {
  border-radius: 0 !important;
  font-size: 93% !important;
}

.simple__input:focus {
  box-shadow: none !important;
}

.notes-grow-2 {
  flex-grow: 2;
}

#row-dettaglio-appuntamento {
  .col {
    min-height: 100px;
    padding: 0px !important;
    max-width: 450px;
  }

  button {
    max-width: 90%;
  }
}

@media (min-height: 1200px) {
  #row-dettaglio-appuntamento {
    .col {
      min-height: 150px;
      padding: 0px !important;
    }

    button {
      max-width: 90%;
    }
  }
}
</style>
