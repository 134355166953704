<template>
  <div v-if="response && !readnre" class="my-2">
    <span class="primary">File </span>
    <b-badge variant="info" class="mx-2 py-1 px-2">{{ response.name }}</b-badge>
    <span class="primary"> caricato correttamente!</span>

    <span class="text-primary point ml-1" v-if="!encr" @click="refreshAll"
      >Carica nuovo file</span
    >
  </div>
  <div v-else-if="response && readnre" class="my-2">
    <span v-if="response.nre" class="primary"
      >Codice rilevato correttamente!
    </span>
    <span v-else class="text-danger"
      >Non è stato possibile rilevare il codice dalla ricetta caricata. Si prega
      di provare a scannerizzare il codice tramite videocamera o inserire
      manualmente il codice nell'apposito campo.</span
    >
  </div>
  <div
    class="d-flex align-content-center justify-content-between flex-wrap my-2"
    v-else
  >
    <b-form-file
      v-model="form.file"
      :accept="acceptedFormats"
      ref="file-up"
      placeholder="seleziona file..."
      class="custom-file-input my-1  border general_border border-info col-9 col-xl-10 px-0"
    ></b-form-file>
    <b-button
      @click="doUpload"
      :disabled="form.file == null"
      class=""
      size="sm"
      variant="secondary px-3  my-1"
    >
      <b-spinner v-if="uploading"></b-spinner>
      <span v-else> Carica </span>
    </b-button>
    <p v-if="errorMessage" class="text-danger col-12 mb-0">{{errorMessage}}</p>
  </div>
</template>
<script>
import { reservationService } from "@/_services"
export default {
  name: "UploadComponent",
  props: ["encr", "dest", "readnre", "accept"],
  computed: {
    acceptedFormats: function () {
      if (this.encr) return ".pdf"
      else if (this.dest == "profilo") return "image/*"
      else return null
    }
  },
  data() {
    return {
      form: {
        file: null,
        destination: "order",
        name: null,
        has_owner: false,
        encrypt: false
      },
      response: null,
      uploading: false,
      errorMessage: null
    }
  },
  watch: {
    // form: {
    //   handler: function (newVal, oldVal) {
    //     if (newVal.file != null && this.dest == "profilo") {
    //       this.form.name = newVal.file.name
    //       this.$emit("show-carica-button", newVal.file)
    //     }
    //   },
    //   deep: true
    // },
    "form.file": function (newVal) {
      this.form.name = newVal.name
      if (this.dest == "profilo") {
        this.$emit("show-carica-button", newVal)
      }
    }
  },
  methods: {
    click() {
      this.$refs["file-up"].$el.querySelector('input[type="file"]').click()
    },
    doUpload() {
      console.log()
      this.uploading = true
      var form = new FormData()
      // if (this.form.file) {
      form.append("file", this.form.file)
      form.append("name", this.form.name)
      // }
      form.append("destination", this.form.destination)
      form.append("has_owner", this.form.has_owner)
      form.append("encrypt", this.form.encrypt)
      if (this.readnre) {
        form.append("read_nre", true)
      }
      this.errorMessage = null
      const self = this
      reservationService
        .uploadFile(form)
        .then(function (res) {
          // console.log(res);
          if (self.dest != "profilo") {
            self.response = res.data.data
          }
          self.$emit("upload-result", res.data.data)
          self.uploading = false
        })
        .catch(function (err) {
          if (err.status && err.status === 422) {
            self.errorMessage = "File non valido o di dimensioni eccessive (max8Mb)"
          }
          console.log(err)
          self.uploading = false
        })
    },
    handleFile(e) {
      // console.log(this.file);
      // console.log(e.target.files);
    },
    refreshAll() {
      this.form.file = null
      this.form.destination = "order"
      if (this.dest) {
        this.form.destination = this.dest
      }
      this.form.name = null
      this.form.has_owner = false
      this.form.encrypt = false
      if (this.encr == true) {
        this.form.encrypt = this.encr
      }
      this.response = null
    }
  },
  created() {
    if (this.encr == true) {
      this.form.encrypt = this.encr
    }
    if (this.dest) {
      this.form.destination = this.dest
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-file-input:lang(en)~.custom-file-label::after,
.custom-file-input:lang(it)~.custom-file-label::after,
.custom-file-input~.custom-file-label[data-browse]::after {
  content: "Scegli" !important;
  color: $primary;
  border-radius: 5px !important;
}

.custom-file-input,
.custom-file-label {
  opacity: 1 !important;
  color: $primary !important;
  border-color: transparent !important;
  border-radius: 5px !important;
}
</style>
