import config from "../_config/config";
import { authHeader, postHeader } from "../_helpers";
import axios from "axios";

axios.defaults.baseURL = config.apiUrl;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.put["X-Requested-With"] = "XMLHttpRequest";

export const cittadinoService = {
  getFamilyMembers,
  storeFamilyMembers,
  updateFamilyMembers,
  getCaregivers,
  inviteCaregiver,
  approveCaregiver,
  disapproveCaregiver,
  getMeasurements,
  postMeasurements,
  getExamHistory,
  downloadResource,
  storePathology,
  getPathologies,
  updatePathology,
  getUserById,
  getCode,
  validateUserCode,
  storeTherapy,
  updateTherapy,
  getTherapies,
  deleteTherapy,
  updateMeasurement,
  deleteMeasurement,
  therapyRecap,
  getUserBots,
  updateUserBots,
  deleteBotAccess,
  updateConsense,
  storeSuspendedFilledSurvey,
  storeFirebaseToken,
  attachUploadedResourceToUser,
  deleteUserPropic,
  storeEmergencyContact,
  getUserEmergencyContacts,
  deleteEmergencyContact,
  updateEmergencyContact,
  suspendUserProfile,
  getUserCaregivers,
  patientStoreFilledSurvey
};

function storeFirebaseToken(data) {
  const sett = { headers: authHeader() };
  return axios
    .post("users/push/token/", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getFamilyMembers() {
  const sett = { headers: authHeader() };
  return axios
    .get("familymembers", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeFamilyMembers(data) {
  const sett = { headers: authHeader() };
  return axios
    .post("familymembers", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateFamilyMembers(data) {
  const sett = { headers: authHeader() };
  var endpoint = "familymembers/" + data.hashid;
  return axios
    .put(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getCaregivers(filters = null) {
  var sett = { headers: authHeader() };
  if (filters) {
    sett = { headers: authHeader(), params: filters };
  }
  return axios
    .get("familymembers/caregivers/own", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function inviteCaregiver(data) {
  const sett = { headers: authHeader() };
  return axios
    .post("familymembers/caregiver/invite", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function approveCaregiver(data) {
  const sett = { headers: authHeader() };
  return axios
    .post("familymembers/caregiver/approve", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function disapproveCaregiver(data) {
  const sett = { headers: authHeader() };
  return axios
    .post("familymembers/caregiver/disapprove", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getMeasurements(filters = null) {
  var sett = { headers: authHeader() };
  if (filters) {
    sett = { headers: authHeader(), params: filters };
  }
  return axios
    .get("exams/measurements/all", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function postMeasurements(data) {
  const sett = { headers: authHeader() };
  return axios
    .post("exams/measurements/add", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getExamHistory(hash, filters = null) {
  var sett = { headers: authHeader() };
  if (filters) {
    sett = { headers: authHeader(), params: filters };
  }
  var endpoint = "exams/measurements/history/" + hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function downloadResource(hash) {
  // const sett = { headers:authHeader(), responseType:'blob', Accept:"application/pdf" };
  const sett = { headers: authHeader(), responseType: "blob" };
  var endpoint = "resources/private/" + hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storePathology(data) {
  const sett = { headers: authHeader() };
  return axios
    .post("pathologies", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updatePathology(data) {
  const sett = { headers: authHeader() };
  var endpoint = "pathologies/" + data.hashid;
  return axios
    .put(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getPathologies(filters = null) {
  var sett = { headers: authHeader() };
  if (filters) {
    sett = { headers: authHeader(), params: filters };
  }
  return axios
    .get("pathologies", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getUserById(hashid) {
  const sett = { headers: authHeader() };
  var endpoint = "users/" + hashid;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getCode() {
  const sett = { headers: authHeader() };
  return axios
    .get("users/own/code", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function validateUserCode(data) {
  const sett = { headers: authHeader() };
  return axios
    .post("users/own/code/validate", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeTherapy(data) {
  const sett = { headers: authHeader() };
  return axios
    .post("therapies", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateTherapy(data) {
  const sett = { headers: authHeader() };
  var endpoint = "therapies/" + data.hashid;
  return axios
    .put(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function deleteTherapy(data) {
  const sett = { headers: authHeader() };
  var endpoint = "therapies/" + data.hashid;
  return axios
    .delete(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getTherapies(filters = null) {
  var sett = { headers: authHeader() };
  if (filters) {
    sett = { headers: authHeader(), params: filters };
  }
  return axios
    .get("therapies", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateMeasurement(data, hash) {
  const sett = { headers: authHeader() };
  var endpoint = "exams/measurements/edit/" + hash;
  return axios
    .put(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function deleteMeasurement(hash) {
  const sett = { headers: authHeader() };
  var endpoint = "exams/measurements/delete/" + hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function therapyRecap(hash) {
  const sett = { headers: authHeader(), responseType: "blob" };
  var endpoint = "therapies/download/" + hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getUserBots() {
  const sett = { headers: authHeader() };
  return axios
    .get("users/bots/access", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateUserBots(data, hash) {
  const sett = { headers: authHeader() };
  var endpoint = "users/bots/status/" + hash;
  return axios
    .post(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function deleteBotAccess(hash) {
  const sett = { headers: authHeader() };
  var endpoint = "users/bots/delete/" + hash;
  return axios
    .delete(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateConsense(data) {
  const sett = { headers: authHeader() };
  return axios
    .post("users/change/consenses", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeSuspendedFilledSurvey(data) {
  const sett = { headers: authHeader() };
  return axios
    .post("activities/survey/suspended/filled", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function attachUploadedResourceToUser(hashid) {
  const sett = { headers: authHeader() };

  return axios
    .post("users/profile/picture", { resource: hashid }, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function deleteUserPropic() {
  const sett = { headers: authHeader() };
  var endpoint = "users/profile/picture/delete";
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeEmergencyContact(data) {
  const sett = { headers: authHeader() };
  return axios
    .post("users/emergencycontacts/", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getUserEmergencyContacts(hash = null) {
  const sett = { headers: authHeader() };
  const hashid = hash ? hash : "";
  var endpoint = "users/emergencycontacts/" + hashid;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateEmergencyContact(data) {
  const sett = { headers: authHeader() };
  var endpoint = "users/emergencycontacts/" + data.hashid;
  return axios
    .put(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function deleteEmergencyContact(hashid) {
  const sett = { headers: authHeader() };
  var endpoint = "users/emergencycontacts/" + hashid;
  return axios
    .delete(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function suspendUserProfile(hashid, formData) {
  const sett = { headers: authHeader() };
  var endpoint = "users/change/suspension/" + hashid;
  return axios
    .patch(endpoint, formData, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getUserCaregivers(user, filters = null) {
  const sett = { headers: authHeader(), params: filters };
  const endpoint = "familymembers/caregivers/user/" + user;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function patientStoreFilledSurvey(data) {
  const sett = { headers: authHeader() }
  return axios
    .post("activities/surveys/filled/patient", data, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}