<template>
  <div>
    <b-row>
      <b-col>
        <h4 class="text-left pb-1">Prestazioni Sanitarie</h4>
        <p class="text-justify">
          Crea e gestisci le tue prestazioni sanitarie.
        </p>
      </b-col>
      <b-col class="text-right">
        <b-button
          variant="secondary"
          @click="$refs['modal-newprestazione'].show()"
        >
          <b-icon icon="plus"></b-icon>
          nuova prestazione
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-left">
        <b-form-group>
          <label for="namesearch">Cerca <b-icon icon="search"></b-icon></label>
          <b-form-input
            class="border border-info text-info"
            name="namesearch"
            placeholder="..."
            v-model="filters.name"
            type="search"
            size="sm"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col class="text-left">
        <b-form-group>
          <label for="orderoption">Ordinamento</label>
          <b-form-select
            class="border border-info text-info"
            name="orderoption"
            v-model="filters.indesc"
            :options="orderOptions"
            size="sm"
          >
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col class="text-left">
        <b-form-group>
          <label for="takeoption">N°elementi mostrati</label>
          <b-form-select
            class="border border-info text-info"
            name="takeoption"
            v-model="filters.take"
            :options="takeOptions"
            size="sm"
          >
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <div v-if="company" class="my-2">
      <transition-group
        name="list"
        mode="out-in"
        class="d-flex flex-wrap justify-content-start align-content-stretch"
      >
        <div
          v-for="prestazione in prestazioni"
          :key="prestazione.hashid"
          class="my-2 my-xl-3 col-12 col-xl-9 mx-xl-auto col-lgx-10 mx-lgx-auto px-lg-1 px-0"
        >
          <div class="puntofarmaCard h-100">
            <b-col class="col-12 p-2">
              <b-row align-v="center">
                <b-col class="col-1 col-md-2">
                  <b-row cols="1">
                    <b-icon :icon="prestazione.icona" font-scale="2.7"></b-icon>
                    <span class="text-uppercase mt-1">{{
                      prestazione.tag
                    }}</span>
                  </b-row>
                </b-col>
                <b-col class="px-0 ml-1">
                  <h5
                    :id="prestazione.name"
                    class="text-uppercase text-left mt-2"
                  >
                    {{ prestazione.name }}
                    <span
                      class="ml-1 point text-lowercase text-secondary"
                      @click="openEdit(prestazione)"
                      ><u>modifica</u></span
                    >
                  </h5>
                  <p class="text-medium-grey text-left mb-0">
                    {{ prestazione.description }}
                  </p>
                  <b-row>
                    <b-col class="text-left px-0">
                      <ServiceStatus
                        notitle="notitle"
                        :statoservizio="prestazione.active == true ? 1 : 0"
                        :servicename="prestazione.name"
                        v-on:store-service-status="
                          submitStatusPrestazioneChange($event, prestazione)
                        "
                      />
                    </b-col>
                    <b-col
                      v-if="prestazione.active && hasFacilePiu"
                      class="text-left col-md-10 px-0"
                    >
                      <ServicePrivacy
                        :statoservizio="prestazione.private == true ? 1 : 0"
                        :servicename="prestazione.name"
                        v-on:store-service-status="
                          submitStatusPrivacyChange($event, prestazione)
                        "
                      />
                    </b-col>
                  </b-row>
                </b-col>
                <b-col class="col-4">
                  <b-row class="justify-content-end">
                    <b-button
                      :disabled="prestazione.active == 0"
                      class="mb-1 mx-1"
                      variant="info"
                      size="sm"
                      @click="emitCollapse('allegati-' + prestazione.hashid)"
                    >
                      <b-icon icon="paperclip" class="mx-2"></b-icon>
                      <b-icon
                        :ref="'icon-collapse-allegati-' + prestazione.hashid"
                        icon="caret-down-fill"
                      ></b-icon>
                    </b-button>
                    <b-button
                      :disabled="prestazione.active == 0"
                      class="mb-1 mx-1"
                      variant="info"
                      size="sm"
                      @click="emitCollapse('survey-' + prestazione.hashid)"
                    >
                      <b-icon icon="clipboard" class="mx-2"></b-icon>
                      <b-icon
                        :ref="'icon-collapse-survey-' + prestazione.hashid"
                        icon="caret-down-fill"
                      ></b-icon>
                    </b-button>
                    <b-button
                      :disabled="prestazione.active == 0"
                      class="mb-1 mx-1"
                      variant="primary"
                      size="sm"
                      v-b-toggle="'collapse-' + prestazione.hashid"
                    >
                      <b-icon icon="clock-fill" class="mx-2"></b-icon>
                      <b-icon
                        :ref="'icon-collapse-' + prestazione.hashid"
                        icon="caret-down-fill"
                      ></b-icon>
                    </b-button>
                    <template v-if="prestazione.plan_type > 0">
                      <b-button
                        :disabled="prestazione.active == 0"
                        class="mb-1 mx-1"
                        variant="primary"
                        size="sm"
                        v-b-toggle="'collapse-dayplan-' + prestazione.hashid"
                      >
                        <b-icon icon="calendar2-fill" class="mx-2"></b-icon>
                        <b-icon
                          :ref="'icon-collapse-dayplan-' + prestazione.hashid"
                          icon="caret-down-fill"
                        ></b-icon>
                      </b-button>
                    </template>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="px-0">
              <b-row cols="1">
                <b-collapse
                  :id="'collapse-allegati-' + prestazione.hashid"
                  class="col-12 p-2 bg-extra-light border-top border-light"
                >
                  <div
                    class="py-2 px-4"
                    v-if="prestazione.active === 1"
                    :key="'active-allegati' + prestazione.hashid"
                  >
                    <AllegatiPrestazioni
                      :ref="'allegati-' + prestazione.hashid"
                      :hash="prestazione.hashid"
                    />
                  </div>
                  <p v-else class="">Prestazione disattivata.</p>
                </b-collapse>
                <b-collapse
                  :id="'collapse-survey-' + prestazione.hashid"
                  class="col-12 p-2 bg-extra-light border-top border-light"
                >
                  <div
                    v-if="prestazione.active === 1"
                    :key="'active-survey' + prestazione.hashid"
                  >
                    <QuestionarioPrestazione
                      :ref="'survey-' + prestazione.hashid"
                      :prestazione="prestazione.hashid"
                    />
                  </div>
                  <p v-else class="">Prestazione disattivata.</p>
                </b-collapse>
                <b-collapse
                  :id="'collapse-' + prestazione.hashid"
                  class="col-12 p-2 bg-extra-light border-top border-light"
                >
                  <div
                    v-if="prestazione.active === 1"
                    :key="'active' + prestazione.hashid"
                  >
                    <SettingServiceOrari
                      v-if="prestazione.openings"
                      :orari="prestazione.openings"
                      :servicename="prestazione.name"
                      v-on:store-service-openings="
                        submitPrestazioneOpenings($event, prestazione)
                      "
                    />
                  </div>
                  <p v-else class="">Prestazione disattivata.</p>
                </b-collapse>
                <b-collapse
                  :id="'collapse-dayplan-' + prestazione.hashid"
                  class="col-12 p-2 bg-extra-light border-top border-light"
                >
                  <div
                    class="py-2 px-4"
                    v-if="prestazione.active === 1 && prestazione.plan_type > 0"
                    :key="'active-dayplan' + prestazione.hashid"
                  >
                    <DayPlanner :serviceHash="prestazione.hashid"></DayPlanner>
                  </div>
                  <p v-else class="">Prestazione disattivata.</p>
                </b-collapse>
              </b-row>
            </b-col>
          </div>
        </div>
      </transition-group>
      <b-pagination
        v-model="filters.page"
        :total-rows="total"
        :per-page="perPage"
        class="mt-2"
        align="center"
        :size="$root.isMobile ? 'sm' : 'lg'"
      ></b-pagination>
    </div>
    <b-modal
      size="lg"
      id="edit-prestazione"
      title="Modifica"
      centered
      hide-footer
    >
      <div v-if="editPrestazione">
        <b-form-group
          label="Nome"
          label-for="nome"
          class="text-left text-font-grey mx-auto col"
        >
          <b-form-input
            class="my-3"
            name="nome"
            type="text"
            placeholder="nome prestazione..."
            v-model="editPrestazione.name"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Descrizione"
          label-for="descrizione"
          class="text-left text-font-grey mx-auto col"
        >
          <b-form-input
            class="my-3"
            name="descrizione"
            type="text"
            placeholder="descrizione..."
            v-model="editPrestazione.description"
          ></b-form-input>
        </b-form-group>
        <b-row>
          <div class="text-left col-6 col-lg-4">
            <b-form-group
              label="Etichetta"
              label-for="tag"
              class="text-left fs-6 text-font-grey mx-auto"
            >
              <b-form-input
                class="my-3"
                name="tag"
                type="text"
                placeholder="etichetta..."
                maxlength="3"
                minlength="1"
                v-model="editPrestazione.tag"
              >
              </b-form-input>
            </b-form-group>
          </div>
          <div class="text-left col-6 col-lg-8">
            <b-form-group
              label="Pianificazione su base"
              label-for="tag"
              class="text-left fs-6 text-font-grey mx-auto"
              :description="planTypes[editPrestazione.plan_type]"
            >
              <b-form-radio-group
                class="mt-2"
                v-model="editPrestazione.plan_type"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-radio
                  :aria-describedby="ariaDescribedby"
                  name="edit_plan_type"
                  :value="0"
                  >settimanale</b-form-radio
                >
                <b-form-radio
                  :aria-describedby="ariaDescribedby"
                  name="edit_plan_type"
                  :value="1"
                  >mensile</b-form-radio
                >
                <b-form-radio
                  :aria-describedby="ariaDescribedby"
                  name="edit_plan_type"
                  :value="2"
                  >occasionale</b-form-radio
                >
              </b-form-radio-group>
            </b-form-group>
          </div>
          <div class="text-left col-12 mb-2">
            <b-form-group
              label="Personalizzazione testo notifica"
              label-for="tag"
              class="text-left text-font-grey mx-auto"
              description="Il contenuto di questo campo verrà incluso in tutte le notifiche di prenotazione e di promemoria delle prenotazioni di questa prestazione."
            >
              <b-form-textarea
                v-model="editPrestazione.custom_notification_text"
                placeholder="Testo notifica personalizzato..."
                class="p-2"
                rows="4"
                required
              >
              </b-form-textarea>
            </b-form-group>
          </div>
        </b-row>
        <IconSelection
          :preselected="editPrestazione.icon"
          v-on:selected-icon="handleIconUpdate($event, editPrestazione)"
        />
        <b-form-group
          label="Conferma automatica"
          label-for="default_status"
          class="text-left text-font-grey mx-auto col pt-2"
        >
          <b-form-checkbox
            id="default_status"
            name="default_status"
            :value="3"
            :unchecked-value="1"
            v-model="editPrestazione.default_status"
          >
            {{
              editPrestazione.default_status == 3
                ? "richieste confermate automaticamente"
                : "le nuove richieste andranno manualmente confermate"
            }}
          </b-form-checkbox>
          <p class="my-1 text-font-grey">
            Nota: tutte le richieste possono sempre e comunque essere annullate
            dall'operatore.
          </p>
        </b-form-group>
        <div class="text-right mt-3 pt-1">
          <b-button
            type="button"
            class="modal-footer-button"
            variant="info mr-3"
            @click="$bvModal.hide('edit-prestazione')"
            >Annulla
          </b-button>
          <b-button
            class="modal-footer-button"
            variant="outline-primary"
            type="button"
            @click="saveEditPrestazione"
            >Salva modifiche
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal
      size="lg"
      ref="modal-newprestazione"
      id="modal-newprestazione"
      title="Crea nuova prestazione"
      centered
      hide-footer
    >
      <!-- <div class="col-12 d-flex"> -->
      <b-form-group
        label="Nome"
        label-for="nome"
        class="text-left text-font-grey mx-auto col"
      >
        <b-form-input
          class="my-3"
          name="nome"
          type="text"
          placeholder="nome prestazione..."
          v-model="nuovaPrestazione.name"
          :state="nuovaPrestazione.name == null ? null : validPrestName"
          required
        >
        </b-form-input>
      </b-form-group>
      <b-form-group
        label="Descrizione"
        label-for="descrizione"
        class="text-left text-font-grey mx-auto col"
      >
        <b-form-input
          class="my-3"
          name="descrizione"
          type="text"
          placeholder="descrizione..."
          v-model="nuovaPrestazione.description"
        ></b-form-input>
      </b-form-group>
      <b-row>
        <div class="text-left col-6 col-lg-4">
          <b-form-group
            label="Etichetta"
            label-for="tag"
            class="text-left fs-6 text-font-grey mx-auto"
          >
            <b-form-input
              class="my-3"
              name="tag"
              type="text"
              placeholder="etichetta..."
              maxlength="3"
              minlength="1"
              v-model="nuovaPrestazione.tag"
            >
            </b-form-input>
          </b-form-group>
        </div>
        <div class="text-left col-6 col-lg-8">
          <b-form-group
            label="Pianificazione su base"
            label-for="tag"
            class="text-left fs-6 text-font-grey mx-auto"
            :description="planTypes[nuovaPrestazione.plan_type]"
          >
            <b-form-radio-group
              class="mt-2"
              v-model="nuovaPrestazione.plan_type"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-radio
                :aria-describedby="ariaDescribedby"
                name="plan_type"
                :value="0"
                >settimanale</b-form-radio
              >
              <b-form-radio
                :aria-describedby="ariaDescribedby"
                name="plan_type"
                :value="1"
                >mensile</b-form-radio
              >
              <b-form-radio
                :aria-describedby="ariaDescribedby"
                name="plan_type"
                :value="2"
                >occasionale</b-form-radio
              >
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="text-left col-12 mb-2">
          <b-form-group
            label="Personalizzazione testo notifica"
            label-for="tag"
            class="text-left text-font-grey mx-auto"
            description="Il contenuto di questo campo verrà incluso in tutte le notifiche di prenotazione e di promemoria delle prenotazioni di questa prestazione."
          >
            <b-form-textarea
              v-model="nuovaPrestazione.custom_notification_text"
              placeholder="Testo notifica personalizzato..."
              class="p-2"
              rows="4"
              required
            >
            </b-form-textarea>
          </b-form-group>
        </div>
      </b-row>
      <IconSelection
        :preselected="2"
        v-on:selected-icon="handleIconUpdate($event)"
      />
      <b-form-group
        v-if="hasFacilePiu"
        label="Apertura al pubblico"
        label-for="privacy"
        class="text-left text-font-grey mx-auto col pt-2"
      >
        <b-form-checkbox
          id="privacy"
          name="privacy"
          :value="false"
          :unchecked-value="true"
          v-model="nuovaPrestazione.private"
        >
          {{
            nuovaPrestazione.private
              ? "la prestazione è prenotabile soltanto da te o da un tuo operatore"
              : "tutti gli utenti in piattaforma possono prenotare la prestazione"
          }}
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        label="Conferma automatica"
        label-for="default_status"
        class="text-left text-font-grey mx-auto col pt-2"
      >
        <b-form-checkbox
          id="default_status"
          name="default_status"
          :value="3"
          :unchecked-value="1"
          v-model="nuovaPrestazione.default_status"
        >
          {{
            nuovaPrestazione.default_status == 3
              ? "richieste confermate automaticamente"
              : "le nuove richieste andranno manualmente confermate"
          }}
        </b-form-checkbox>
        <p class="my-1 text-font-grey">
          Nota: tutte le richieste possono sempre e comunque essere annullate
          dall'operatore.
        </p>
      </b-form-group>
      <!-- </div> -->
      <div class="text-center mt-3 pt-1">
        <b-button
          type="button"
          variant="outline-danger mx-2"
          @click="$refs['modal-newprestazione'].hide()"
          >Annulla
        </b-button>
        <b-button
          variant="primary text-white  mx-2"
          type="button"
          @click="saveNewPrestazione"
          >Conferma
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { companyService } from "@/_services";
import SettingServiceOrari from "@/components/impostazioni/SettingServiceOrari.vue";
import ServiceStatus from "@/components/impostazioni/ServiceStatus.vue";
import ServicePrivacy from "@/components/impostazioni/ServicePrivacy.vue";
import AllegatiPrestazioni from "@/components/impostazioni/AllegatiPrestazioni.vue";
import QuestionarioPrestazione from "@/components/impostazioni/QuestionarioPrestazione.vue";
import IconSelection from "@/components/impostazioni/IconSelection.vue";
DayPlanner;
import DayPlanner from "@/components/impostazioni/DayPlanner.vue";
export default {
  name: "PrestazioniSanitarie",
  props: ["servicename", "statoservizio"],
  components: {
    SettingServiceOrari,
    ServiceStatus,
    AllegatiPrestazioni,
    QuestionarioPrestazione,
    ServicePrivacy,
    IconSelection,
    DayPlanner,
  },
  computed: {
    validPrestName: function () {
      var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      if (this.nuovaPrestazione.name !== null) {
        return format.test(this.nuovaPrestazione.name) ? false : true;
      }
      return false;
    },
    ...mapState("company", ["companyLoggedIn", "company"]),
  },
  data() {
    return {
      nuovaPrestazione: {
        name: null,
        description: null,
        active: true,
        private: false,
        has_limitations: false,
        default_status: 3,
        icon: 2,
        tag: null,
        plan_type: 0,
        custom_notification_text: null,
      },
      hasFacilePiu: true,
      icons: {
        2: "file-ppt",
        3: "chat-text",
        4: "screwdriver",
        5: "droplet-fill",
        6: "flower1",
        7: "emoji-smile",
        8: "exclude",
        9: "egg-fried",
        10: "gem",
        11: "heart-fill",
        12: "hourglass-split",
        13: "journal-medical",
        14: "life-preserver",
        15: "lock-fill",
        16: "moon",
        17: "node-minus-fill",
        18: "x-diamond-fill",
        19: "cart-fill",
        20: "bug-fill",
        21: "brightness-high-fill",
        22: "bell-fill",
      },
      editPrestazione: null,
      filters: {
        page: 1,
        take: 5,
        indesc: true,
        active: null,
        name: undefined,
      },
      total: 0,
      fromPage: 0,
      toPage: 0,
      perPage: 0,
      takeOptions: [5, 10, 25, 50, 100],
      orderOptions: [
        { value: true, text: "Più recenti" },
        { value: false, text: "Meno recenti" },
      ],
      activeOptions: [
        { value: true, text: "Attive" },
        { value: false, text: "Non attive" },
        { value: null, text: "Tutte" },
      ],
      prestazioni: null,
      planTypes: {
        0: "Per servizi continuativi. Per questa prestazione dovrai soltanto impostare giorni della settimana ed orari di disponibilità.",
        1: "Per servizi attivati con cadenza mensile. Per questa prestazione dovrai indicare i giorni del mese in cui il servizio sarà attivo",
        2: "Per servizi attivi con frequenza inferiore alle precedenti. Per questa prestazione dovrai indicare il giorno o i giorni dell'anno in cui il servizio sarà attivo",
      },
    };
  },
  methods: {
    ...mapActions("company", [
      "updateAnagrafica",
      "storePrestazione",
      "storeOrariPrestazione",
      "updatePrestazione",
      "logUserCompanyIn",
    ]),
    handleChange() {
      // console.log("form changed", this.formData.form);
      // this.$refs["modal-confirmupdate"].show();
    },
    submitChanges() {
      // if (this.formData) {
      //   var data = this.formData;
      //   this.$emit("store-service-status", data);
      // }
      // this.$refs["modal-confirmupdate"].hide();
    },
    fetchData() {
      var self = this;
      companyService
        .getOwnHealthServices(this.filters)
        .then(function (res) {
          self.prestazioni = res.data.data.data;
          self.filters.page = res.data.data.current_page;
          self.total = res.data.data.total;
          self.fromPage = res.data.data.from;
          self.toPage = res.data.data.to;
          self.perPage = res.data.data.per_page
            ? res.data.data.per_page
            : self.filters.take;
        })
        .catch(function (err) {
          console.log(err);
          self.errorAlert("Non è stato possibile recuperare le prestazioni");
        });
    },
    submitServiceOpenings(newOpenings) {
      // console.log("new openings to submit", newOpenings);
    },
    emitCollapse(id) {
      this.$refs[id][0].doFetch = true;
      this.$root.$emit("bv::toggle::collapse", "collapse-" + id);
    },
    handleIconUpdate(icon, prestazione = null) {
      if (!prestazione) {
        this.nuovaPrestazione.icon = icon;
      } else {
        this.editPrestazione.icon = icon;
      }
    },
    openEdit(pres) {
      this.editPrestazione = pres;
      this.$bvModal.show("edit-prestazione");
    },
    saveEditPrestazione() {
      var newFormData = {
        service_hash: this.editPrestazione.hashid,
        form: {
          icon: this.editPrestazione.icon,
          name: this.editPrestazione.name,
          description: this.editPrestazione.description,
          default_status: this.editPrestazione.default_status,
          tag: this.editPrestazione.tag,
          plan_type: this.editPrestazione.plan_type,
          custom_notification_text:
            this.editPrestazione.custom_notification_text,
        },
      };
      // console.log("service status data to submit", newFormData);
      var self = this;
      this.updatePrestazione(newFormData).then(function () {
        self.$bvModal.hide("edit-prestazione");
        window.scrollTo(0, 0);
        var scoped = self;
        setTimeout(function () {
          scoped.fetchData();
        }, 3000);
        self.fetchData();
      });
    },
    saveNewPrestazione() {
      // console.log("new prestazione");
      var self = this;
      if (this.nuovaPrestazione.name) {
        this.storePrestazione(this.nuovaPrestazione).then(function () {
          window.scrollTo(0, 0);
          self.$refs["modal-newprestazione"].hide();
          self.nuovaPrestazione = {
            name: null,
            description: null,
            active: true,
            has_limitations: false,
            default_status: 3,
            icon: 2,
            tag: null,
            plan_type: 0,
            custom_notification_text: null,
          };
          self.fetchData();
        });
      }
    },
    submitPrestazioneOpenings(dataToSubmit, prestazione) {
      var self = this;
      dataToSubmit.service_hash = prestazione.hashid;
      this.storeOrariPrestazione(dataToSubmit).then(function () {
        window.scrollTo(0, 0);
        var scoped = self;
        setTimeout(function () {
          scoped.fetchData();
        }, 3000);
        // self.fetchData();
        // self.logUserCompanyIn();
      });
    },
    submitStatusPrestazioneChange(dataToSubmit, prestazione) {
      var newFormData = {
        service_hash: prestazione.hashid,
        form: {
          active: dataToSubmit.form.status,
          name: dataToSubmit.service_name,
        },
      };
      // console.log("service status data to submit", newFormData);
      var self = this;
      this.updatePrestazione(newFormData).then(function () {
        window.scrollTo(0, 0);
        var scoped = self;
        setTimeout(function () {
          scoped.fetchData();
        }, 3000);
        // self.fetchData();
        // self.logUserCompanyIn();
      });
    },
    submitStatusPrivacyChange(dataToSubmit, prestazione) {
      var newFormData = {
        service_hash: prestazione.hashid,
        form: {
          private: dataToSubmit.form.private,
          name: dataToSubmit.service_name,
        },
      };
      // console.log("service status data to submit", newFormData);
      var self = this;
      this.updatePrestazione(newFormData).then(function () {
        window.scrollTo(0, 0);
        var scoped = self;
        setTimeout(function () {
          scoped.fetchData();
        }, 3000);
        self.fetchData();
        // self.logUserCompanyIn();
      });
    },
    scrollUp() {
      window.scrollTo(0, 0);
    },
  },
  watch: {
    filters: {
      handler(prev, post) {
        this.fetchData();
      },
      deep: true,
    },
  },
  created() {
    this.fetchData();
  },
  mounted() {
    if (this.$route.query.action === "createNewHS") {
      this.$bvModal.show("modal-newprestazione");
    }
    var self = this;
    this.$root.$on("bv::collapse::state", function (collapseId, isJustShown) {
      // console.log("collapseId:", collapseId);
      // console.log("isJustShown:", isJustShown);
      if (
        self.$refs["icon-" + collapseId] &&
        self.$refs["icon-" + collapseId][0]
      ) {
        self.$refs["icon-" + collapseId][0].style.transform = isJustShown
          ? "rotate(180deg)"
          : "none";
      }
      if (
        self.$refs["icon-allegati-" + collapseId] &&
        self.$refs["icon-allegati-" + collapseId][0]
      ) {
        self.$refs["icon-allegati-" + collapseId][0].style.transform =
          isJustShown ? "rotate(180deg)" : "none";
      }
      if (
        self.$refs["icon-survey-" + collapseId] &&
        self.$refs["icon-survey-" + collapseId][0]
      ) {
        self.$refs["icon-survey-" + collapseId][0].style.transform = isJustShown
          ? "rotate(180deg)"
          : "none";
      }
    });
  },
};
</script>
<style>
.panel__box__shadow {
  box-shadow: 2px 1px 4px #3867d652;
}
</style>
