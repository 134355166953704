var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{ref:"navmain",staticClass:"shadow_1 h-100 px-0 text-font-grey bg-white",attrs:{"fluid":""}},[_c('transition-group',{attrs:{"name":"slideside","mode":"out-in"}},[_c('b-row',{key:"logo-row",staticClass:"px-0",attrs:{"align-v":"center"}},[(_vm.$root.isCollapsed)?_c('b-col',{key:"padspace",staticClass:"text-right",attrs:{"cols":"3","lg":"2"}}):_vm._e(),_c('b-col',{staticClass:"px-1 py-2 mt-2",attrs:{"cols":"6","md":"12"}},[_c('router-link',{attrs:{"to":{ name: 'Home' }}},[(_vm.menuIsCompact && !_vm.$root.isCollapsed)?_c('img',{key:"icon_logo",staticClass:"px-lg-2",staticStyle:{"max-width":"75px"},attrs:{"src":require("@/assets/puntofarma-dot-logo.svg"),"alt":"appLogo"}}):_c('img',{key:"full_logo",attrs:{"src":require("@/assets/puntofarma-logo.svg"),"alt":"appLogo"}})])],1),(_vm.$root.isCollapsed)?_c('b-col',{key:"mobile_ham",staticClass:"text-right",attrs:{"cols":"3","lg":"2"},on:{"click":function($event){_vm.menuVisible = !_vm.menuVisible}}},[_c('b-icon',{attrs:{"font-scale":"1.3","icon":"justify"}})],1):_vm._e()],1),(_vm.menuVisible)?_c('b-row',{key:"menu-row",staticClass:"px-0",attrs:{"cols":"1"}},[(!_vm.$root.isCollapsed)?_c('b-col',{key:"desk_ham",staticClass:"px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-0 text-left"},[_c('p',{staticClass:"pb_15 nav__item bg-white"},[_c('span',{staticClass:"d-flex align-items-center pl-1 py-1 general_border point",on:{"click":_vm.handleMenuSize}},[_c('b-icon',{attrs:{"font-scale":"1.5","icon":"justify"}}),_c('span',{staticClass:"mb-0 ml-2"})],1)])]):_vm._e(),_c('b-col',{staticClass:"px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-0 text-left"},[_c('p',{staticClass:"text-font-light-grey"},[_c('span',{class:_vm.menuIsCompact ? 'text-white' : ''})]),_c('p',{staticClass:"pb_15 nav__item bg-white"},[_c('router-link',{staticClass:"d-flex align-items-center pl-1 py-1 general_border croom",class:this.$route.name === 'CompanyHome'
                ? 'router-link-exact-active'
                : '',attrs:{"to":{ name: 'Home' }}},[_c('b-icon',{attrs:{"font-scale":"1.3","icon":"house-door"}}),_c('span',{staticClass:"mb-0 ml-2"},[_vm._v("Dashboard")])],1)],1),_c('p',{staticClass:"pb_15 nav__item bg-white"},[_c('router-link',{staticClass:"d-flex align-items-center pl-1 py-1 general_border croom",attrs:{"to":{ name: _vm.agendaSet.routeName }}},[_c('b-icon',{attrs:{"font-scale":"1.3","icon":_vm.agendaSet.logo}}),_c('span',{staticClass:"mb-0 ml-2"},[_vm._v(_vm._s(_vm.agendaSet.name))])],1)],1),(_vm.isFirstLevel)?_c('p',{staticClass:"pb_15 nav__item bg-white"},[_c('router-link',{staticClass:"d-flex align-items-center pl-1 py-1 general_border croom",attrs:{"to":{ name: _vm.analiticheSet.routeName }}},[_c('b-icon',{attrs:{"font-scale":"1.3","icon":_vm.analiticheSet.logo}}),_c('span',{staticClass:"mb-0 ml-2"},[_vm._v(_vm._s(_vm.analiticheSet.name))])],1)],1):_vm._e(),_c('p',{staticClass:"pb_15 nav__notifications"},[_c('NotificationCenter')],1)]),_c('b-col',{staticClass:"px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-0 text-left"},[_c('p',{staticClass:"text-font-light-grey"},[_c('span',{class:_vm.menuIsCompact ? 'text-white' : ''},[_vm._v("SERVIZI")])]),_vm._l((_vm.activeServices),function(service,ind){return _c('p',{key:ind,staticClass:"pb_15 nav__item",class:service.submenu ? 'bg-extra-light' : 'bg-white'},[(service.submenu)?[_c('span',{key:'sub-m-' + service.routeName,staticClass:"d-flex align-items-center pl-1 py-1 general_border point",class:_vm.$route.name === service.routeName
                  ? 'router-link-exact-active'
                  : '',on:{"click":function($event){service.submenuVisible = !service.submenuVisible}}},[_c('b-icon',{attrs:{"font-scale":"1.3","icon":service.logo}}),_c('span',{staticClass:"mb-0 ml-2 text-capitalize"},[_vm._v(_vm._s(service.name))]),_c('b-icon',{staticClass:"mr-1 ml-auto",attrs:{"font-scale":"1","icon":service.submenuVisible ? 'chevron-down' : 'chevron-up'}})],1),_c('b-collapse',{staticClass:"mt-1",attrs:{"id":service.name + '-sub'},model:{value:(service.submenuVisible),callback:function ($$v) {_vm.$set(service, "submenuVisible", $$v)},expression:"service.submenuVisible"}},_vm._l((service.subroutes),function(rt){return _c('p',{key:'sub-' + rt.name,staticClass:"mb-1"},[_c('router-link',{staticClass:"d-flex align-items-center pl-1 py-1 general_border align-items-center",attrs:{"to":{
                    name: service.routeName,
                    params: { section: rt.path },
                  },"exact-active-class":"terz_bg_gradient shadow_7 text-white submenu__active__class"}},[_c('b-iconstack',{attrs:{"font-scale":"1.3"}},[_c('b-icon',{attrs:{"stacked":"","icon":"circle","variant":"font-grey","scale":"0.55"}}),_c('b-icon',{attrs:{"stacked":"","icon":"circle","variant":"extra-light"}})],1),_c('span',{staticClass:"mb-0 ml-2 text-capitalize"},[_vm._v(_vm._s(rt.name))])],1)],1)}),0)]:[_c('router-link',{staticClass:"d-flex align-items-center pl-1 py-1 general_border croom",attrs:{"to":{
                name: service.routeName,
              }}},[_c('b-icon',{attrs:{"font-scale":"1.3","icon":service.logo}}),_c('span',{staticClass:"mb-0 ml-2 text-capitalize"},[_vm._v(_vm._s(service.name))])],1)]],2)}),_c('p',{staticClass:"pb_15 nav__item bg-white"},[_c('router-link',{staticClass:"d-flex align-items-center pl-1 py-1 general_border croom",class:this.$route.name === 'control room'
                ? 'router-link-exact-active'
                : '',attrs:{"to":{ name: 'control room' }}},[_c('b-icon',{attrs:{"font-scale":"1.3","icon":"bullseye"}}),_c('span',{staticClass:"mb-0 ml-2"},[_vm._v("Monitoraggio")])],1)],1)],2),_c('b-col',{staticClass:"px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-0 text-left"},[_c('p',{staticClass:"text-font-light-grey"},[_c('span',{class:_vm.menuIsCompact ? 'text-white' : ''})]),_c('p',{staticClass:"pb_15 nav__item bg-white"},[_c('router-link',{staticClass:"d-flex align-items-center pl-1 py-1 general_border croom",attrs:{"to":{ name: 'profilo operatore' }}},[(_vm.user && _vm.user.profile_pic)?_c('b-avatar',{attrs:{"variant":"white","src":_vm.user.profile_pic,"size":"sm","rounded":""}}):_c('b-icon',{attrs:{"font-scale":"1.3","icon":this.$route.name === 'profilo operatore'
                  ? 'person-fill'
                  : 'person'}}),_c('span',{staticClass:"mb-0 ml-2 text-capitalize"},[_vm._v(_vm._s(_vm.welcomeName))])],1)],1),(_vm.canSeeImpostazioni)?_c('p',{staticClass:"pb_15 bg-extra-light nav__item",attrs:{"aria-controls":_vm.impostazioniSet.name + '-sub'}},[_c('span',{staticClass:"d-flex align-items-center pl-1 py-1 general_border point",class:_vm.$route.name === 'impostazioniOperatore'
                ? 'router-link-exact-active'
                : '',on:{"click":function($event){_vm.impostazioniSet.submenuVisible = !_vm.impostazioniSet.submenuVisible}}},[_c('b-icon',{attrs:{"font-scale":"1.3","icon":_vm.impostazioniSet.logo}}),_c('span',{staticClass:"mb-0 ml-2"},[_vm._v(_vm._s(_vm.impostazioniSet.name))]),_c('b-icon',{staticClass:"mr-1 ml-auto",attrs:{"font-scale":"1","icon":_vm.impostazioniSet.submenuVisible ? 'chevron-down' : 'chevron-up'}})],1),(_vm.impostazioniSet.submenu)?[_c('b-collapse',{staticClass:"mt-1",attrs:{"id":_vm.impostazioniSet.name + '-sub'},model:{value:(_vm.impostazioniSet.submenuVisible),callback:function ($$v) {_vm.$set(_vm.impostazioniSet, "submenuVisible", $$v)},expression:"impostazioniSet.submenuVisible"}},_vm._l((_vm.impostazioniSet.subroutes),function(rt){return _c('p',{key:'sub-' + rt.name,staticClass:"mb-1"},[_c('router-link',{staticClass:"d-flex align-items-center pl-1 py-1 general_border align-items-center",attrs:{"to":{
                    name: _vm.impostazioniSet.routeName,
                    params: { section: rt.path },
                  },"exact-active-class":"terz_bg_gradient shadow_7 text-white submenu__active__class"}},[_c('b-iconstack',{attrs:{"font-scale":"1.3"}},[_c('b-icon',{attrs:{"stacked":"","icon":"circle","variant":"font-grey","scale":"0.55"}}),_c('b-icon',{attrs:{"stacked":"","icon":"circle","variant":"extra-light"}})],1),_c('span',{staticClass:"mb-0 ml-2 text-capitalize"},[_vm._v(_vm._s(rt.name))])],1)],1)}),0)]:_vm._e()],2):_vm._e(),_c('p',{staticClass:"pb_15 nav__item bg-white"},[_c('router-link',{staticClass:"d-flex align-items-center pl-1 py-1 general_border croom",attrs:{"to":{ name: 'guida' }}},[_c('b-icon',{attrs:{"font-scale":"1.3","icon":this.$route.name === 'guida'
                  ? 'info-circle-fill'
                  : 'info-circle'}}),_c('span',{staticClass:"mb-0 ml-2"},[_vm._v("Guida")])],1)],1)]),_c('b-col',{staticClass:"px-2 px-md-4 px-lg-4 pb-xl-2 pb-1 pt-0 text-left"},[_c('p',{staticClass:"text-font-light-grey"}),_c('p',{staticClass:"pb_15 nav__item bg-white"},[_c('span',{staticClass:"d-flex align-items-center pl-1 py-1 general_border croom point",on:{"click":function($event){$event.preventDefault();return _vm.$bvModal.show('logout-confirm')}}},[_c('b-icon',{attrs:{"font-scale":"1.3","icon":"door-open"}}),_c('span',{staticClass:"mb-0 ml-2 text-capitalize"},[_vm._v("Esci")])],1)])])],1):_vm._e()],1),_c('b-modal',{attrs:{"id":"logout-confirm","centered":"","title":"Confermi di voler uscire dall'area privata?","ok-title":"Sì, esci","ok-variant":"outline-primary","cancel-title":"No, annulla","cancel-variant":"info","footer-border-variant":"white"},on:{"ok":_vm.onSubmit,"cancel":function($event){return _vm.$bvModal.hide('logout-confirm')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }