<template>
  <div class="home py-1 py-lg-2">
    <b-alert
      variant="danger"
      :show="!user"
      class="col-lg-8 col-sm-12 mr-0 ml-auto point"
    >
      <span>Effettua il login </span>
    </b-alert>
    <b-container fluid class="px-0 m-0" v-if="$route.name == 'CittadinoHome'">
      <b-col>
        <h1 class="page-title pb-4 text-left" id="welcome-text">
          {{ welcomeText }}
        </h1>
      </b-col>
      <b-row class="mt-n4">
        <b-col class="col-12 col-lg-7 mt-4">
          <b-row class="bg-white general_border mb-4 shadow_2">
            <b-img
              class="bg-white general_border px-4 py-2"
              src="/assets/banner_home_cittadina.png"
              fluid
              alt="puntofarma banner"
            ></b-img>
          </b-row>
          <h3 class="text-left py-0 py-lg-4 mb-4 mb-lg-1">Prenotazioni</h3>
          <section class="mx-n3">
            <b-row cols="1" cols-md="2" class="mt-n4">
              <b-col class="d-flex flex-column mt-4">
                <b-row cols="1" class="h-100">
                  <b-skeleton-wrapper :loading="loading">
                    <template #loading>
                      <b-col
                        class="puntofarmaCard py-3 h-100 d-flex flex-column align-items-center"
                      >
                        <b-skeleton
                          width="50%"
                          height="40%"
                          class="mt-3"
                        ></b-skeleton>
                        <b-skeleton
                          width="50%"
                          height="15%"
                          class="mt-3"
                        ></b-skeleton>
                      </b-col>
                    </template>

                    <b-col class="puntofarmaCard py-3">
                      <p
                        class="prenotazioni-card prenotazioni-card-counter mt-n2 mt-md-2"
                      >
                        {{ prenotazioniCount }}
                      </p>
                      <p
                        class="prenotazioni-card prenotazioni-card-label mt-n2"
                      >
                        Prenotazioni per oggi
                      </p>
                    </b-col>
                  </b-skeleton-wrapper>

                  <!-- <b-col class="d-flex flex-column"> </b-col> -->
                </b-row>
              </b-col>
              <b-col class="d-flex flex-column align-items-stretch mt-4">
                <b-button
                  @click="
                    $router.push({
                      name: 'appuntamenti',
                      params: { section: 'nuovo', type: 'prestazione' }
                    })
                  "
                  class="px-2"
                  variant="secondary"
                >
                  <b-icon scale="1.3" icon="file-ppt" class="mr-2"></b-icon>
                  Nuova Richiesta
                </b-button>
                <b-button
                  @click="
                    $router.push({
                      name: 'appuntamenti',
                      params: { section: 'nuovo', type: 'prodotto' }
                    })
                  "
                  class="mt-2 px-2"
                  variant="secondary"
                >
                  <b-icon scale="1.3" icon="bag" class="mr-2"></b-icon>Nuovo
                  Ordine
                </b-button>
                <b-button
                  @click="
                    $router.push({
                      name: 'appuntamenti',
                      params: { section: 'nuovo', type: 'teleconsulto' }
                    })
                  "
                  class="mt-2 px-2"
                  variant="secondary"
                >
                  <b-icon scale="1.3" icon="camera-video" class="mr-2"></b-icon>
                  Nuovo Teleconsulto
                </b-button>
              </b-col>
            </b-row>
          </section>
        </b-col>
        <b-col class="col-12 col-lg-5 mt-4"
          ><PromemoriaHomeCittadino
            :prenotazioni="prenotazioni"
            :loading="loading"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import * as moment from "moment/moment"
import { mapState } from "vuex"
import { reservationService } from "@/_services"
import PromemoriaHomeCittadino from "@/components/cittadino/PromemoriaHomeCittadino.vue"

export default {
  name: "CittadinoHome",
  components: {
    PromemoriaHomeCittadino
  },
  data() {
    return {
      innerWidth: window.innerWidth,
      greeting: "Ciao",
      services: [
        {
          name: "Il mio profilo sanitario",
          description: "Terapie ed armadietto farmaceutico",
          position_ref: 1,
          logo: "/assets/records.svg",
          disabled: false,
          btnText: "Consulta"
        },
        {
          name: "I miei familiari",
          description: "Consulta e modifica la lista",
          position_ref: 2,
          logo: "/assets/people.svg",
          disabled: false,
          btnText: "Consulta"
        },
        {
          name: "Il mio circolo di cura",
          description: "Farmacie e operatori sanitari",
          position_ref: 3,
          logo: "/assets/pill.svg",
          disabled: false,
          btnText: "Consulta"
        },
        {
          name: "La mia agenda",
          description: "Agenda appuntamenti",
          position_ref: 4,
          logo: "/assets/book.svg",
          disabled: false,
          btnText: "Consulta"
        }
        // {
        //   name: "Nuova Prenotazione",
        //   description: "richiedi una prenotazione",
        //   position_ref: 5,
        //   logo: null,
        //   disabled: false,
        //   btnText: "Prenota",
        // },
      ],
      positions: {
        1: "profilosanitario",
        2: "familiari",
        3: "circolocura",
        4: "agenda",
        5: "prenotazioni/nuova%20prenotazione"
      },
      prenotazioni: [],
      loading: true
      // menuOpen: false,
    }
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
    welcomeText() {
      if (this.innerWidth < 900) return `${this.greeting}!`
      else if (this.user.anagrafica) {
        return `${this.greeting}, ${this.user.anagrafica.name} ${this.user.anagrafica.surname}`
      } else return `${this.greeting}!`
    },
    prenotazioniCount() {
      return this.prenotazioni.length
    }
  },
  created() {
    window.addEventListener("resize", this.innerWidthChanged)
    this.setGreeting()
  },
  mounted() {
    this.fetchData()
  },
  destroyed() {
    window.removeEventListener("resize", this.innerWidthChanged)
  },
  methods: {
    innerWidthChanged() {
      this.innerWidth = window.innerWidth
    },
    setGreeting() {
      const hour = moment().format("HH")
      if (hour >= 6 && hour < 12) {
        this.greeting = "Buongiorno"
      } else if (hour >= 12 && hour < 18) {
        this.greeting = "Buon pomeriggio"
      } else {
        this.greeting = "Buona sera"
      }
    },
    fetchData() {
      this.loading = true
      var self = this
      reservationService
        .todayReservations()
        .then(function (response) {
          self.prenotazioni = response.data.data
        })
        .catch(function (error) {
          self.errorAlert(
            "non è stato possibile recuperare le prenotazioni recenti"
          )
          console.log("error fetch prenotazioni", error)
        })
        .finally(function () {
          self.loading = false
        })
    }
  }
}
</script>
<style scoped lang="scss">
.prenotazioni-card {
  color: $secondary;
  font-weight: bolder;
  margin-bottom: 0px;
}

.prenotazioni-card-counter {
  font-size: 3rem;
}

.prenotazioni-card-label {
  font-size: 0.8rem;
}

.submenu__border {
  border-radius: 0.6rem;
}

.fs-4 {
  font-size: 92%;
}

.home__card {
  height: 66px;
}

.banner__padd {
  min-height: 40vh;
}
</style>
