<template>
  <div v-if="detail" class="w-100">
    <!-- <b-row
      align-v="stretch"
      id="row-dettaglio-teleconsulto"
      class="mt-n3 flex-wrap"
    >  -->
    <b-row align-v="stretch" class="mt-n3 flex-wrap">
      <!-- <b-col id="col-questionario" class="mt-3"> -->
      <b-col cols="12" xl="8" class="mt-3">
        <div
          class="p-3 detail-modal-card d-flex flex-column justify-content-start align-items-start h-100"
        >
          <p class="font-weight-bolder detail-modal-card-titles">
            QUESTIONARIO
          </p>
          <b-button
            v-if="survey !== null && survey.response"
            variant="outline-primary"
            size="sm"
            class="px-4 modal-body-button"
            @click="$refs['show-filleditem'].show()"
            >apri questionario</b-button
          >
          <p v-else class="m-0 p-0 text-dark-grey font-size-085rem">
            Nessun questionario
          </p>
        </div>
      </b-col>
      <b-col
        cols="12"
        xl="8"
        class="mt-3"
        v-if="detail.durata !== null && detail.durata > 0"
      >
        <b-row class="p-3 detail-modal-card">
          <b-col class="px-0">
            <p>
              <span class="font-weight-bolder detail-modal-card-titles"
                >DURATA PREVISTA</span
              >
            </p>
            <p class="text-dark-grey font-size-085rem m-0 p-0">
              {{ detail.durata }} minuti
            </p>
          </b-col>
          <b-col class="px-0" v-if="detail.durata_effettiva">
            <p>
              <span class="font-weight-bolder detail-modal-card-titles"
                >DURATA EFFETTIVA</span
              >
            </p>
            <p class="text-dark-grey font-size-085rem m-0 p-0">
              {{ detail.durata_effettiva }} minuti
            </p>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        xl="8"
        class="mt-3"
        v-if="detail.details && detail.details !== ''"
      >
        <b-row class="p-3 detail-modal-card">
          <b-col class="px-0">
            <p>
              <span class="font-weight-bolder detail-modal-card-titles"
                >ALTRI DETTAGLI</span
              >
            </p>
            <p class="text-dark-grey font-size-085rem m-0 p-0">
              {{ detail.details }} 
            </p>
          </b-col>
        </b-row>
      </b-col>
      <b-col 
        cols="12"
        xl="4" class="mt-3" v-if="thereIsOneButton">
        <div
          class="px-0 mr-4 d-flex flex-column justify-content-around align-items-center h-100"
        >
          <b-button
            v-if="editBtn"
            size="sm"
            @click="updateRequest"
            type="button"
            variant="outline-primary"
            class="modal-body-button mb-2"
            >Invia Risposta</b-button
          >
          <b-button
            v-else-if="stato === 1 || stato === 6"
            size="sm"
            @click="confirmRequest"
            type="button"
            variant="outline-primary"
            class="modal-body-button mb-2"
            >Conferma prenotazione</b-button
          >
          <b-button
            v-if="stato === 1"
            size="sm"
            @click="setAction('reject')"
            type="button"
            variant="outline-danger"
            class="modal-body-button mb-2"
            >Declina prenotazione</b-button
          >
          <b-button
            v-if="stato === 2 || stato === 3"
            class="general_border mb-2"
            size="sm"
            type="button"
            variant="outline-primary modal-body-button"
            @click="
              $router.push({
                name: 'teleconsulto',
                params: { section: 'videocall' },
                query: { reserv: hash },
              })
            "
            >Vai al Teleconsulto</b-button
          >
          <b-button
            v-if="stato > 1"
            size="sm"
            @click="setAction('cancel')"
            type="button"
            variant="outline-danger modal-body-button"
            >Annulla prenotazione</b-button
          >
        </div>
      </b-col>

      <b-col
        cols="12"
        xl="8"
        class="mt-3"
        v-if="detail.operator_notes"
      >
        <b-row class="p-3 detail-modal-card">
          <b-col class="px-0">
            <p>
              <span class="font-weight-bolder detail-modal-card-titles"
                >APPUNTI TELECONSULTO</span
              >
            </p>
            <p class="text-dark-grey font-size-085rem m-0 p-0">
              {{ detail.operator_notes }} 
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-modal
      size="xl"
      hide-footer
      centered
      ref="modal-confirm"
      id="modal-confirm"
      title="Conferma Annullamento"
    >
      <PickMessageForm
        :messages="cancellationMessages"
        confirm-message="Sei sicuro di voler annullare questo appuntamento?"
        @cancellation-confirmed="cancelRequest($event)"
        @refused-to-confirm="$bvModal.hide('modal-confirm')"
      />
    </b-modal>

    <b-modal
      ref="show-filleditem"
      centered
      hide-footer
      title="Questionario"
      size="xl"
    >
      <FilledSurveyShower v-if="survey" :response="survey.response" :score="survey.score" />
    </b-modal>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { reservationService } from "@/_services";
import FilledSurveyShower from "@/components/attivita/FilledSurveyShower.vue";
import UpdateNotesComponent from "@/components/prenotazioni/dettaglio/UpdateNotesComponent.vue";
import PickMessageForm from "@/components/prenotazioni/form/PickMessageForm.vue";
import { CANCELLATION_MESSAGES } from "@/_utils/constants.js";

export default {
  name: "DetailTeleconsulto",
  props: ["dettaglio", "stato", "datainizio", "quest", "hash", "company_notes"],
  components: { FilledSurveyShower, UpdateNotesComponent, PickMessageForm },
  data() {
    return {
      cancellationMessages: CANCELLATION_MESSAGES,
      actionName: null,
      loading: false,
      detail: this.dettaglio,
      editBtn: false,
      newDate: this.datainizio,
      newTime: moment(this.datainizio).format("HH:mm"),
      survey: null,
    };
  },
  computed: {
    checkValidity: function () {
      var data = moment(this.datainizio);
      return moment().isBefore(data);
    },
    thereIsOneButton: function () {
      return Math.abs(this.stato) !== 4 && (this.editBtn || this.stato >= 1);
    },
  },
  watch: {
    dettaglio: {
      handler(val) {
        this.$nextTick(function () {
          this.detail = val;
          if (val.resource_id !== null) {
            this.getAllegato();
          }
        });
      },
      deep: true,
    },
    detail: {
      handler(val) {
        // this.editBtn = true;
        this.editBtn =
          Math.abs(this.stato) !== 4 &&
          Math.abs(this.stato) !== 3 &&
          Math.abs(this.stato) !== 2;
      },
      deep: true,
    },
    newDate(val) {
      this.editBtn = true;
    },
    newTime(val) {
      this.editBtn = true;
    },
    // company_notes(val) {
    //   this.editBtn = true
    // },
    quest(val) {
      if (val) {
        this.survey = val;
      } else {
        this.survey = null;
      }
    },
  },
  methods: {
    getAllegato() {
      var self = this;
      var endpoint =
        this.dettaglio.allegato.full_path +
        "/" +
        this.dettaglio.allegato.hashid;
      reservationService
        .getAllegato(endpoint)
        .then(function (res) {
          self.picLink = window.URL.createObjectURL(new Blob([res.data]));
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    confirmRequest() {
      var data = {
        confirmed: true,
        action: "confirm",
      };
      this.$emit("update-request-status", data);
    },
    updateRequest() {
      var daTe = moment(this.newDate);
      var tiMe = moment(this.newTime, "HH:mm");
      daTe.hours(tiMe.hours());
      daTe.minutes(tiMe.minutes());
      var datetime = daTe.format("YYYY-MM-DD HH:mm");
      // console.log(daTe.format("YYYY-MM-DD HH:mm"));
      var dataToSend = {
        detail: this.detail,
        newDateTime: datetime,
        company_notes: this.company_notes,
      };
      this.$emit("update-request-response", dataToSend);
    },
    setAction(action) {
      this.actionName = action;
      this.$refs["modal-confirm"].show();
    },
    cancelRequest(message = null) {
      this.loading = true;
      var data = {
        confirmed: false,
        message,
        action: this.actionName,
      };
      this.$refs["modal-confirm"].hide();
      this.$emit("update-request-status", data);
    },
    handleNotesUpdate(data) {
      this.company_notes = data.company_notes;
    },
  },
  created() {
    if (this.quest) {
      this.survey = this.quest;
    }
  },
};
</script>

<style lang="scss" scoped>
.simple__input {
  border-radius: 0 !important;
  font-size: 93% !important;
}

.simple__input:focus {
  box-shadow: none !important;
}

#row-dettaglio-teleconsulto {
  .col {
    padding: 0px !important;
    max-width: 300px;
  }

  button {
    max-width: 90%;
  }
}

@media (min-height: 1200px) {
  #row-dettaglio-teleconsulto {
    .col {
      padding: 0px !important;
    }

    button {
      max-width: 90%;
    }
  }
}
</style>
