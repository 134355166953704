<template>
  <div class="py-2 puntofarmaCard px-2 p-xl-4">
    <h5 class="pl-4 text-left text-capitalize">{{ title }}</h5>
    <canvas :id="idappend"></canvas>
  </div>
</template>
<script>
import { Chart, registerables } from "chart.js";
import "chartjs-adapter-moment"; // or another adapter to avoid moment
Chart.register(...registerables);
export default {
  name: "BarChart",
  props: ["idappend", "datatoshow", "title"],
  data() {
    return {
      planetChartData: {
        type: "bar",
        data: {
          labels: [
            "Gennaio",
            "Febbraio",
            "Marzo",
            "Aprile",
            "Maggio",
            "Giugno",
            "Luglio",
            "Agosto",
            "Settembre",
            "Ottobre",
            "Novembre",
            "Dicembre",
          ],
          datasets: [
            {
              label: "Prenotazioni totali (ordini, prestazioni, teleconsulti)",
              type: "bar",
              // data: [
              //   0.166, 2.081, 3.003, 0.323, 954.792, 285.886, 43.662, 51.514,
              // ],
              data: this.datatoshow,
              backgroundColor: "rgba(45, 205,122,1)",
              borderColor: "#2dcd7a",
              borderWidth: 3,
            },
          ],
        },
        options: {
          responsive: true,
          lineTension: 1,
          indexAxis: "y",
        },
      },
    };
  },
  mounted() {
    // console.log("polarArea");
    // console.log(this.datatoshow);
    const ctx = document.getElementById(this.idappend);
    new Chart(ctx, this.planetChartData);
  },
};
</script>