<template>
  <div class="register pb-4">
    <b-col class="col-12 align-content-center py-2 py-md-3 py-xl-4">
      <div class="mx-auto col-10 col-md-4 col-xl-3 py-2">
        <b-img
          :src="require('@/assets/puntofarma-logo.svg')"
          fluid-grow
          alt="logo puntofarma"
        ></b-img>
      </div>
    </b-col>
    <h2>Registrazione</h2>
    <div>
      <b-card no-body class="col-lg-10 col-sm-12 mx-auto border-0">
        <b-tabs
          no-key-nav
          v-model="tab_index"
          class="bg-white"
          nav-class="border-0 mb-4"
        >
          <b-tab
            :title="
              tab_index === 0 ? 'Seleziona il tipo di registrazione' : '   '
            "
            title-link-class="bg-white border-0"
            active-tab-class="bg-white border-0"
            title-item-class="bg-white border-0"
          >
            <div class="col-12 mx-auto d-flex flex-wrap">
              <b-form-radio-group
                label="Individual radios"
                class="col-12 mx-auto d-flex flex-wrap"
              >
                <div class="col-12 col-sm-6 ml-0 mr-auto text-left">
                  <b-form-radio
                    class="font-weight-bold"
                    v-model="form.type"
                    name="user_type"
                    :value="0"
                    >Cittadino
                  </b-form-radio>
                  <p class="">
                    Accedi alla tua cartella clinica, registra i tuoi familiari,
                    mettiti in contatto con il tuo Medico o Farmacista, e molto
                    altro.
                  </p>
                </div>
                <div class="col-12 col-sm-6 ml-0 mr-auto text-left">
                  <b-form-radio
                    class="font-weight-bold"
                    v-model="form.type"
                    name="user_type"
                    :value="1"
                    >Operatore sanitario</b-form-radio
                  >
                  <p class="">
                    Mettiti in contatto con tuoi pazienti e organizza i tuoi
                    Appuntamenti e tele consulti in tutta sicurezza.
                  </p>
                </div>
              </b-form-radio-group>
            </div>
            <br />
            <div class="col-12 text-right mt-3 pb-2">
              <b-button
                type="button"
                variant="outline-primary mx-2"
                @click="$router.push('/login')"
                >Indietro</b-button
              >
              <b-button
                type="button"
                variant="primary mx-2"
                :disabled="form.type == null"
                @click="tab_index++"
                >Avanti
                <b-icon icon="arrow-right"></b-icon>
              </b-button>
            </div>
          </b-tab>
          <b-tab
            :title="tab_index === 1 ? 'Inserisci i tuoi dati' : '   '"
            title-link-class="bg-white border-0"
            active-tab-class="bg-white border-0"
            title-item-class="bg-white border-0"
          >
            <b-form @submit.prevent="tab_index++">
              <div v-if="form.type === 1" class="text-left fs-6 mx-auto col-12">
                <b-col>
                  <label for="codice_azienda">Codice azienda</label>
                  <b-icon
                    class="ml-2"
                    icon="question-circle"
                    variant="primary"
                    id="company-code-explain"
                  ></b-icon>
                </b-col>

                <b-tooltip
                  :placement="$root.isMobile ? 'bottom' : 'rightbottom'"
                  variant="info-tooltip"
                  target="company-code-explain"
                  :triggers="$root.isMobile ? 'click' : 'hover'"
                >
                  Il codice azienda si trova nella sezione impostazioni
                  dell'operatore amministratore
                </b-tooltip>
              </div>
              <b-col>
                <b-form-group
                  v-if="form.type === 1"
                  class="text-left fs-6 mb-3 text-font-grey mx-auto col-12"
                  description="Inserisci il codice di 10 cifre fornito dalla tua azienda."
                >
                  <b-form-input
                    v-model="form.company_code"
                    minlength="10"
                    placeholder="codice azienda..."
                    class="my-3"
                    name="codice_azienda"
                    :state="form.company_code == null ? null : canSubmit"
                    required
                    v-on:input="checkCode"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <!-- <div class="mx-auto"> -->
              <div class="col-12 d-flex flex-wrap">
                <b-col class="col-12 col-sm-6 d-flex flex-wrap">
                  <b-form-group
                    label="Titolo"
                    label-for="title"
                    class="text-left fs-6 col-12 col-sm-4 pr-sm-1 text-font-grey mx-auto px-0"
                    :class="$root.isMobile ? '' : 'col'"
                  >
                    <b-form-select
                      class="mb-3 mt-2 custom-select text-capitalize"
                      name="title"
                      v-model="anagrafica.title"
                      :options="titleOptions"
                    >
                      <b-form-select-option :value="null" disabled
                        >Titolo</b-form-select-option
                      >
                    </b-form-select>
                  </b-form-group>
                  <b-form-group
                    label="Nome*"
                    label-for="name"
                    class="text-left fs-6 col-12 col-sm-8 pl-sm-1 text-font-grey mx-auto px-0"
                    :class="$root.isMobile ? '' : 'col'"
                  >
                    <b-form-input
                      class="my-3 text-capitalize"
                      name="name"
                      type="text"
                      placeholder="nome..."
                      v-model="anagrafica.name"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-form-group
                  label="Cognome*"
                  label-for="surname"
                  class="text-left fs-6 col-12 col-sm-6 text-font-grey mx-auto"
                  :class="$root.isMobile ? '' : 'col'"
                >
                  <b-form-input
                    class="my-3 text-capitalize"
                    name="surname"
                    type="text"
                    placeholder="cognome..."
                    v-model="anagrafica.surname"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <template v-if="form.type === 0">
                <div class="col-12 d-flex flex-wrap">
                  <b-form-group
                    label="Codice fiscale*"
                    label-for="cf"
                    class="text-left fs-6 col-12 col-sm-6 text-font-grey mx-auto"
                    :class="$root.isMobile ? '' : 'col'"
                  >
                    <b-form-input
                      class="my-3 text-uppercase"
                      name="cf"
                      type="text"
                      placeholder="codice fiscale..."
                      maxlength="16"
                      minlength="16"
                      v-model="anagrafica.cf"
                      :state="anagrafica.cf == null ? null : validCf"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Numero Tessera Sanitaria"
                    label-for="ts_number"
                    class="text-left fs-6 col-12 col-sm-6 text-font-grey mx-auto"
                    :class="$root.isMobile ? '' : 'col'"
                  >
                    <b-form-input
                      class="my-3"
                      name="ts_number"
                      type="text"
                      placeholder="numero tessera..."
                      v-model="anagrafica.ts_number"
                      maxlength="20"
                      minlength="20"
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-12 d-flex flex-wrap">
                  <b-form-group
                    label="Data di nascita*"
                    label-for="birthday"
                    class="text-left col-12 col-sm-6 fs-6 text-font-grey"
                    :class="$root.isMobile ? '' : 'col'"
                  >
                    <b-form-input
                      class="my-3"
                      name="birthday"
                      type="date"
                      v-model="anagrafica.birthday"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Genere"
                    label-for="gender"
                    class="text-left fs-6 text-font-grey col-12 col-sm-6"
                  >
                    <b-form-radio-group
                      v-model="anagrafica.gender"
                      name="gender-options"
                      class="d-flex pt-0 pb-2 pb-md-0 pt-sm-3"
                    >
                      <b-form-radio value="maschile" class="col-md-3"
                        >uomo</b-form-radio
                      >
                      <b-form-radio value="femminile" class="col-md-3"
                        >donna</b-form-radio
                      >
                    </b-form-radio-group>
                  </b-form-group>
                </div>
                <!-- </div> -->

                <div class="col-12 d-flex flex-wrap">
                  <b-form-group
                    label="Nazione di nascita*"
                    label-for="nazione_nascita"
                    class="text-left col-12 col-sm-6 fs-6 text-font-grey"
                  >
                    <b-form-select
                      id="nazione_nascita"
                      class="my-3 text-capitalize"
                      :options="nazioniOptions"
                      name="regione"
                      v-model="anagrafica.nazione_nascita"
                      required
                    >
                    </b-form-select>
                  </b-form-group>
                  <b-form-group
                    label="Regione di residenza*"
                    label-for="regione"
                    class="text-left col-12 col-sm-6 fs-6 text-font-grey"
                  >
                    <b-form-select
                      class="my-3 text-capitalize"
                      :options="regioni"
                      name="regione"
                      v-model="selected.regione"
                      @change="getProvince(selected)"
                      required
                    >
                      <b-form-select-option :value="null"
                        >Seleziona Regione</b-form-select-option
                      >
                    </b-form-select>
                  </b-form-group>

                  <b-form-group
                    label="Provincia di residenza*"
                    label-for="provincia"
                    class="text-left col-12 col-sm-6 fs-6 text-font-grey mx-auto"
                  >
                    <b-form-select
                      class="my-3 text-capitalize"
                      :options="province"
                      name="provincia"
                      v-model="selected.provincia"
                      @change="getComuni(selected)"
                      required
                    >
                      <b-form-select-option :value="null"
                        >Seleziona Provincia</b-form-select-option
                      >
                    </b-form-select>
                  </b-form-group>

                  <b-form-group
                    label="Comune di residenza*"
                    label-for="comune"
                    class="text-left fs-6 col-12 col-sm-6 text-font-grey mx-auto"
                  >
                    <b-form-select
                      class="my-3 text-capitalize"
                      name="comune"
                      v-model="selected.comune"
                      required
                    >
                      <b-form-select-option
                        v-for="(com, ind) in comuni"
                        :key="ind"
                        :value="com"
                        @change="setDomicilioData"
                        :selected="findComune(com)"
                        >{{ com.comune }}</b-form-select-option
                      >
                      <b-form-select-option :value="null"
                        >Seleziona Comune</b-form-select-option
                      >
                    </b-form-select>
                  </b-form-group>
                  <!-- </div>
              <div class="col-12 d-flex flex-wrap"> -->
                  <b-form-group
                    label="Indirizzo e numero civico"
                    label-for="address"
                    class="text-left fs-6 col-12 col-sm-6 text-font-grey mx-auto"
                  >
                    <b-form-input
                      class="my-3"
                      name="address"
                      type="text"
                      placeholder="indirizzo..."
                      v-model="anagrafica.address"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Cap"
                    label-for="cap"
                    class="text-left col-12 col-sm-6 fs-6 text-font-grey mr-auto"
                  >
                    <b-form-input
                      class="my-3"
                      name="cap"
                      type="text"
                      placeholder="cap..."
                      v-model="anagrafica.cap"
                      minlength="5"
                      maxlength="5"
                    ></b-form-input>
                  </b-form-group>
                  <!-- telefono domicilio -->
                  <b-form-group
                    label="Telefono Domicilio"
                    label-for="home_number"
                    class="text-left col-12 col-sm-6 fs-6 text-font-grey mr-auto"
                  >
                    <b-form-input
                      class="my-3"
                      name="cap"
                      type="text"
                      placeholder="telefono domicilio..."
                      v-model="anagrafica.home_number"
                      minlength="9"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </template>
              <div class="col-12 d-flex flex-wrap">
                <template v-if="form.type === 1">
                  <b-form-group
                    label="Ordine"
                    label-for="tipo_albo"
                    class="text-left col-12 col-sm-6"
                  >
                    <b-form-select
                      class="mb-3 mt-2 custom-select text-capitalize"
                      name="tipo_albo"
                      v-model="anagrafica.tipo_albo"
                      :options="tipoAlboOptions"
                    >
                    </b-form-select>
                  </b-form-group>
                  <b-form-group
                    label="
                      ID Iscrizione Albo**
                    "
                    label-for="id_albo"
                    class="text-left col-12 col-sm-6"
                    :description="'**Includere la sigla provincia'"
                  >
                    <b-form-input
                      class="my-3 text-capitalize"
                      name="id_albo"
                      type="text"
                      placeholder="ID ordine..."
                      v-model="anagrafica.id_albo"
                      :state="validIdOrdine"
                      @input="
                        (val) =>
                          (anagrafica.id_albo =
                            anagrafica.id_albo.toUpperCase())
                      "
                    ></b-form-input>
                  </b-form-group>
                </template>
                <b-form-group
                  label="Email*"
                  label-for="email"
                  class="text-left pt-2 fs-6 col-12 col-sm-6 text-font-grey"
                >
                  <b-form-input
                    v-model="form.email"
                    placeholder="email..."
                    class="my-3"
                    name="email"
                    :state="form.email == null ? null : validEmail"
                    :disabled="!anagrafica.name || !anagrafica.surname"
                    required
                  ></b-form-input>
                </b-form-group>
                <!-- </div>
              <div class="col-12 d-flex flex-wrap"> -->
                <b-form-group
                  label="Numero di telefono*"
                  label-for="tel_number"
                  class="text-left col-12 pt-2 pt-sm-0 col-sm-6 fs-6 text-font-grey d-flex flex-column"
                >
                  <b-input-group>
                    <b-input-group-prepend>
                      <h6 class="mt-4 pt-2 pr-1 text-prim-grad-1">+39</h6>
                    </b-input-group-prepend>
                    <b-form-input
                      class="my-3"
                      name="tel_number"
                      type="text"
                      placeholder="telefono..."
                      v-model="anagrafica.tel_number"
                      minlength="9"
                      :state="
                        anagrafica.tel_number !== null ? validNumber : null
                      "
                      required
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </div>
              <div class="col-12 d-flex flex-wrap">
                <b-form-group
                  label="Password*"
                  label-for="password"
                  class="text-left col-12 col-sm-6 fs-6 text-font-grey mx-auto"
                >
                  <b-form-input
                    class="my-3"
                    name="password"
                    type="password"
                    v-model="form.password"
                    placeholder="password..."
                    :state="form.password == null ? null : validatePassword"
                    required
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  label="Conferma password*"
                  label-for="password"
                  class="text-left fs-6 col-12 col-sm-6 text-font-grey mx-auto"
                  :class="$root.isMobile ? '' : 'col'"
                >
                  <b-form-input
                    class="my-3"
                    name="password_confirmation"
                    type="password"
                    v-model="form.password_confirmation"
                    placeholder="conferma password..."
                    :state="form.password == null ? null : validateConfirm"
                    required
                  ></b-form-input>
                </b-form-group>
                <p class="text-left text-medium-grey col fs-6">
                  La password non può includere il nome né il cognome e deve
                  contenere almeno 8 caratteri, di cui almeno un numero, una
                  maiuscola, una minuscola ed un
                  <span
                    v-b-tooltip
                    title="Devi usare almeno un carattere tra ! @ # $ % ^ & * ) ( + = . _ -"
                    >carattere speciale</span
                  >.
                </p>
              </div>
              <br />
              <div
                class="col-12 text-right pb-2 d-flex justify-content-between flex-wrap"
              >
                <b-button
                  type="button"
                  variant="outline-primary col-12 col-sm-4 col-md-3 my-2  my-sm-0  col-xl-2 mx-2"
                  @click="tab_index--"
                  >Indietro</b-button
                >
                <b-button
                  type="submit"
                  variant="primary col-12 col-sm-4 col-md-3 my-2 my-sm-0  col-xl-2 mx-2"
                  :disabled="!enableSecondBtn"
                  >Avanti
                  <b-icon icon="arrow-right"></b-icon>
                </b-button>
              </div>
            </b-form>
          </b-tab>
          <b-tab
            title=""
            no-nav-style
            title-link-class="bg-white border-0"
            active-tab-class="bg-white border-0"
            title-item-class="bg-white border-0"
          >
            <template v-if="form.type === 0">
              <div class="col-12">
                <h6
                  class="text-prim-grad-1 text-left pb-1 border-bottom border-line-grey"
                >
                  Termini e Condizioni
                </h6>
                <p class="text-justify informativa__p">
                  Per concludere la registrazione è necessario prendere visione
                  ed accettare le condizioni del
                  <a
                    target="_blank"
                    class="text-underlined point"
                    :href="infoLinks.paziente"
                  >
                    <u
                      >documento di Termini e Condizioni d'uso del servizio</u
                    ></a
                  >
                </p>
                <b-row cols="1" class="text-left">
                  <b-form-checkbox
                    class="text-font-light-grey"
                    name="data_consense"
                    :value="true"
                    :unchecked-value="false"
                    v-model="form.data_consense"
                    :state="form.data_consense == true"
                  >
                    <span class="text-dark"
                      >Ho letto e accetto i
                      <a
                        target="_blank"
                        class="text-underlined point"
                        :href="infoLinks.paziente"
                      >
                        <u>Termini e Condizioni d'uso del servizio</u></a
                      >
                    </span>
                  </b-form-checkbox>
                  <b-form-checkbox
                    class="text-font-light-grey"
                    name="data_consense"
                    :value="false"
                    :unchecked-value="false"
                    v-model="form.data_consense"
                    :state="false"
                  >
                    Non accetto
                  </b-form-checkbox>
                </b-row>
                <p class="text-justify informativa__p my-4">e</p>
                <b-row cols="1" class="text-left">
                  <b-form-checkbox
                    class="text-font-light-grey"
                    name="share_consense"
                    :value="true"
                    :unchecked-value="false"
                    v-model="form.share_consense"
                    :state="form.share_consense == true"
                  >
                    <span class="text-dark"
                      >Ai sensi dell’articolo 1341 e ss c.c. accetto
                      espressamente le previsioni di cui agli articoli 2, 7, 8,
                      10, 12 dei presenti
                      <a
                        target="_blank"
                        class="text-underlined point"
                        :href="infoLinks.paziente"
                      >
                        <u>Termini e Condizioni d'uso</u></a
                      >
                    </span>
                  </b-form-checkbox>
                  <b-form-checkbox
                    class="text-font-light-grey"
                    name="share_consense"
                    :value="false"
                    :unchecked-value="false"
                    v-model="form.share_consense"
                    :state="false"
                  >
                    Non accetto
                  </b-form-checkbox>
                </b-row>
              </div>
            </template>
            <template v-else>
              <div class="col-12">
                <h6
                  class="text-prim-grad-1 text-left pb-1 border-bottom border-line-grey"
                >
                  Termini e Condizioni
                </h6>
                <p class="text-justify informativa__p">
                  Per concludere la registrazione è necessario prendere visione
                  ed accettare le
                  <a
                    target="_blank"
                    class="text-underlined point"
                    :href="infoLinks.operatore"
                  >
                    <u>Condizioni generali di contratto</u></a
                  >
                </p>
                <b-row cols="1" class="text-left">
                  <b-form-checkbox
                    class="text-font-light-grey"
                    name="data_consense"
                    :value="true"
                    :unchecked-value="false"
                    v-model="form.data_consense"
                    :state="form.data_consense == true"
                  >
                    <span class="text-dark"
                      >Ho letto e accetto le
                      <a
                        target="_blank"
                        class="text-underlined point"
                        :href="infoLinks.operatore"
                      >
                        <u>condizioni generali di contratto</u></a
                      >
                    </span>
                  </b-form-checkbox>
                  <b-form-checkbox
                    class="text-font-light-grey"
                    name="data_consense"
                    :value="false"
                    :unchecked-value="false"
                    v-model="form.data_consense"
                    :state="false"
                  >
                    Non accetto
                  </b-form-checkbox>
                </b-row>
                <p class="text-justify informativa__p my-4">e</p>
                <b-row cols="1" class="text-left">
                  <b-form-checkbox
                    class="text-font-light-grey"
                    name="share_consense"
                    :value="true"
                    :unchecked-value="false"
                    v-model="form.share_consense"
                    :state="form.share_consense == true"
                  >
                    <span class="text-dark"
                      >Ai sensi dell’articolo 1341 e ss c.c. accetto
                      espressamente le previsioni di cui agli articoli 8, 9, 10,
                      17, 18 del
                      <a
                        target="_blank"
                        class="text-underlined point"
                        :href="infoLinks.operatore"
                      >
                        <u> Contratto</u></a
                      >
                    </span>
                  </b-form-checkbox>
                  <b-form-checkbox
                    class="text-font-light-grey"
                    name="share_consense"
                    :value="false"
                    :unchecked-value="false"
                    v-model="form.share_consense"
                    :state="false"
                  >
                    Non accetto
                  </b-form-checkbox>
                </b-row>
              </div>
            </template>
            <!-- <div class="col-12" v-if="form.type === 0">
              <h6
                class="text-prim-grad-1 text-left pb-1 border-bottom border-line-grey"
              >
                Dichiarazione di presa in carico
              </h6>
              <p class="text-justify">
                Accetto le condizioni per le finalità indicate al punto 2, lett.
                D) dell’informativa – cessione di dati per finalità di marketing
                <br /><a
                  href="https://www.dottorfarma.it/privacy-policy/"
                  class="text-primary text-left"
                  target="__blank"
                  >Vedi Privacy Policy</a
                >
              </p>
              <b-form-checkbox
                class="text-font-light-grey"
                name="charge_consense"
                :value="true"
                :unchecked-value="false"
                v-model="form.charge_consense"
                :state="form.charge_consense == true"
              >
                Acconsento
              </b-form-checkbox>
            </div> -->
            <!-- <div class="col-12">
              <h6
                class="text-prim-grad-1 text-left pb-1 border-bottom border-line-grey"
              >
                Notifiche
              </h6>
              <p class="text-justify">
              </p>
              <b-form-checkbox
                class="text-font-light-grey"
                name="notification_consense"
                :value="true"
                :unchecked-value="false"
                v-model="form.notification_consense"
                :state="form.notification_consense === true"
              >
                Acconsento
              </b-form-checkbox>
            </div> -->
            <br />
            <div class="col-12 text-right mt-3 pb-2">
              <b-button
                type="button"
                variant="outline-primary mx-2"
                @click="tab_index--"
                >Indietro</b-button
              >
              <b-button
                type="button"
                variant="primary mx-2"
                :disabled="!enableThirdBtn"
                @click.prevent="OnSubmit"
              >
                Completa
              </b-button>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

import { mapState, mapActions } from "vuex";
import * as moment from "moment/moment";
import { utilityService } from "@/_services";
export default {
  name: "RegisterPage",
  components: {
    // HelloWorld,
  },
  data() {
    return {
      form: {
        email: null,
        password: null,
        password_confirmation: null,
        data_consense: false,
        share_consense: false,
        notification_consense: true,
        charge_consense: false,
        type: null,
        // company_code: null,
        anagrafica: null,
      },
      anagrafica: {
        regione: null,
        provincia: null,
        comune: null,
        cap: null,
        address: null,
        name: null,
        surname: null,
        cf: null,
        gender: null,
        birthday: null,
        ts_number: null,
        tel_number: null,
        title: null,
        id_albo: null,
        tipo_albo: null,
        nazione_nascita: "italia",
      },
      titleOptions: ["Sig.", "Sig.ra", "Dott.", "Dott.ssa"],
      tipoAlboOptions: ["Medici", "Dentisti", "Farmacisti"],
      has_minimum_lenth: true,
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      has_name: false,
      has_surname: false,
      tab_index: 0,
      canSubmit: false,
      selected: {
        regione: null,
        provincia: null,
        comune: null,
      },
      cfMonths: {
        A: "01",
        B: "02",
        C: "03",
        D: "04",
        E: "05",
        H: "06",
        L: "07",
        M: "08",
        P: "09",
        R: "10",
        S: "11",
        T: "12",
        a: "01",
        b: "02",
        c: "03",
        d: "04",
        e: "05",
        h: "06",
        l: "07",
        m: "08",
        p: "09",
        r: "10",
        s: "11",
        t: "12",
      },
      infoLinks: {
        // paziente: process.env.VUE_APP_LINK_INFO_REGISTRAZIONE_PAZIENTE,
        // operatore: process.env.VUE_APP_LINK_INFO_REGISTRAZIONE_OPERATORE,
        paziente: "/assets/docs/TeC_PT.pdf",
        operatore: "/assets/docs/TeC_OS.pdf",
      },
      nazioni: [],
    };
  },
  watch: {
    selected: {
      handler(val) {
        if (val.comune !== null) {
          this.anagrafica.comune = this.selected.comune.comune;
          this.anagrafica.provincia = this.selected.comune.provincia;
          this.anagrafica.regione = this.selected.comune.regione;
        }
      },
      deep: true,
    },
    tab_index: function (val) {
      if (val == 2) {
        window.scrollTo(0, 0);
      }
    },
  },
  computed: {
    validatePassword() {
      if (this.form.password) {
        this.has_minimum_lenth = this.form.password.length > 8;
        this.has_number = /\d/.test(this.form.password);
        this.has_lowercase = /[a-z]/.test(this.form.password);
        this.has_uppercase = /[A-Z]/.test(this.form.password);
        this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.form.password);
        const regName = new RegExp(this.anagrafica.name.toLowerCase());
        const regSurname = new RegExp(this.anagrafica.surname.toLowerCase());
        this.has_name = !regName.test(this.form.password.toLowerCase());
        this.has_surname = !regSurname.test(this.form.password.toLowerCase());
        return (
          this.has_minimum_lenth &&
          this.has_number &&
          this.has_lowercase &&
          this.has_uppercase &&
          this.has_name &&
          this.has_surname &&
          this.has_special
        );
      }
      return false;
    },
    validIdOrdine() {
      if (this.anagrafica.id_albo) {
        const regx = /^[A-Za-z]{2}/;
        return this.anagrafica.id_albo.match(regx) ? true : false;
      }
      return null;
    },
    validCf() {
      var validRegex =
        /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;
      if (this.anagrafica.cf.length === 16) {
        return this.anagrafica.cf.match(validRegex) ? this.fillOthers() : false;
      }
      return this.anagrafica.cf.match(validRegex) ? true : false;
    },
    validNumber() {
      if (this.anagrafica.tel_number) {
        const prefix = this.anagrafica.tel_number.slice(0, 2);
        const hasPrefix =
          prefix === "00" || this.anagrafica.tel_number.indexOf("+") >= 0;
        return !isNaN(this.anagrafica.tel_number) && !hasPrefix;
      }
      return false;
    },
    validateConfirm() {
      if (this.form.password) {
        return this.form.password_confirmation === this.form.password;
      }
      return false;
    },
    validEmail() {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return this.form.email.match(validRegex) ? true : false;
    },
    enableSecondBtn() {
      if (this.form.type !== null) {
        if (this.validatePassword && this.validateConfirm && this.validNumber) {
          if (this.form.type == 0) {
            if (this.validCf) return true;
            else return false;
          } else return true;
        }
        return false;
      }
      return false;
    },
    enableThirdBtn() {
      if (this.form.data_consense && this.form.share_consense) {
        // if (this.form.type == 0 && !this.form.charge_consense) {
        //   return false;
        // }
        return true;
      }
      return false;
    },
    ...mapState("utente", ["status", "user"]),
    ...mapState("utility", ["searching", "comuni", "province", "regioni"]),
    nazioniOptions() {
      if (this.nazioni && this.nazioni.length > 0) {
        return [
          { value: null, text: "Seleziona Nazione" },
          { value: "italia", text: "Italia" },
        ].concat(
          this.nazioni.map((nazione) => {
            return {
              value: nazione.nazione,
              text: nazione.nazione
                .toLowerCase()
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" "),
            };
          })
        );
      } else
        return [
          {
            value: null,
            text: "Seleziona Nazione",
          },
        ];
    },
  },
  methods: {
    ...mapActions("utente", ["register"]),
    ...mapActions("utility", ["getComuni", "getProvince", "getRegioni"]),
    OnSubmit() {
      if (this.form.type === 1) {
        this.form.charge_consense = undefined;
      } else {
        // this.form.company_code = undefined;
      }
      // console.log(this.form);
      if (this.form) {
        this.form.anagrafica = this.anagrafica;
        this.register(this.form)
          .then((res) => {
            window.scrollTo(0, 0);
          })
          .catch((error) => {
            console.log("after login error", error);
          });
      }
    },
    checkCode() {
      // console.log(this.form.company_code);
      // console.log(this.form.company_code.length);
      if (this.form.company_code !== null) {
        this.canSubmit = this.form.company_code.length >= 10;
      }
    },
    setDomicilioData() {
      // console.log("on change", this.selected.comune);
      if (this.selected.comune) {
        this.anagrafica.comune = this.selected.comune.comune;
        this.anagrafica.provincia = this.selected.comune.provincia;
        this.anagrafica.regione = this.selected.comune.regione;
      }
    },
    findComune(comune) {
      if (this.anagrafica.comune) {
        if (comune.comune === this.anagrafica.comune) {
          this.selected.comune = comune;
          return true;
        }
      }
      return false;
    },
    fillOthers() {
      // console.log("fillOthers");
      try {
        var cf = this.anagrafica.cf;
        let getYear = cf.substr(6, 2);
        let getMonth = this.cfMonths[cf.substr(8, 1)];
        let getDay = parseInt(cf.substr(9, 2));
        let day = getDay > 31 ? getDay - 40 : getDay;
        this.anagrafica.gender = getDay > 31 ? "femminile" : "maschile";
        // console.log(parseInt(getYear));
        let currentYear = moment().year() - 2000;
        let yearPrefix =
          parseInt(getYear) >= 0 && parseInt(getYear) <= currentYear
            ? "20"
            : "19";
        var stringBDate = yearPrefix + getYear + "/" + getMonth + "/" + day;
        var isDateValid = moment(stringBDate).isValid();
        // console.log(isDateValid);
        this.anagrafica.birthday = moment(stringBDate).format("yyyy-MM-DD");
        if (cf.substr(11, 1) === "Z") {
          this.anagrafica.nazione_nascita = this.nazioni.find(
            (nazione) => nazione.codice === cf.substr(11, 4)
          )?.nazione;
        } else {
          this.anagrafica.nazione_nascita = "italia";
          this.searchComuneViaCCatastale(cf.substr(11, 4));
        }
        return isDateValid;
      } catch (err) {
        // console.log("fillOthers", err);
        return false;
      }
    },
    searchComuneViaCCatastale(codice) {
      const self = this;
      utilityService
        .searchComuneViaCodiceCatastale(codice)
        .then(function (res) {
          console.log(res.data.data);
          if (res.data.data) {
            self.selected.regione = res.data.data.regione;
            self.getProvince(res.data.data);
            self.selected.provincia = res.data.data.provincia;
            self.getComuni(res.data.data);
            self.selected.comune = res.data.data;
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    getNazioni() {
      var self = this;
      utilityService
        .nazioni()
        .then((res) => {
          self.nazioni = res.data;
          // console.log(res.data)
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getRegioni();
    this.getNazioni();
  },
};
</script>
<style>
div.card-header {
  background-color: #fff !important;
}

.nav-item > a.bg-white:hover {
  background-color: #fff !important;
}

.fs-5 {
  font-size: 89%;
  /* font-weight: 700; */
}

.informativa__p {
  overflow-y: scroll;
  padding: 0 5px;
  max-height: 250px;
}
</style>
