<template>
  <div>
    <b-button
      variant="outline-primary modal-footer-button"
      @click="$bvModal.show('handled_btn_' + hashid)"
      >Contrassegna come gestita <b-icon icon="check-all"></b-icon>
    </b-button>
    <b-modal
      :id="'handled_btn_' + hashid"
      centered
      title="Conferma Operazione"
      @close="$bvModal.hide('handled_btn_' + hashid)"
      @ok="markAsHandled"
      ok-title="Sì, conferma"
      ok-variant="outline-primary" cancel-title="No, annulla" cancel-variant="info" footer-border-variant="white"
    >
      <b-row>
      <h6>Vuoi contrassegnare questa richiesta come "gestita"?</h6>
      </b-row>
      <!-- <b-spinner v-else></b-spinner> -->
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex"
import { reservationService } from "@/_services"
import moment from "moment"
export default {
  name: "MarkAsHandledButton",
  props: ["hashid"],
  data() {
    return {
      loading: true,
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    sendMessage() {
      if (this.channel) {
        this.channel.sendMessage(this.textMessage)
        this.storeMessage(this.textMessage)
        this.textMessage = null
      }
    },
    markAsHandled() {
      const self = this
      reservationService
        .markReservationAsHandled(this.hashid)
        .then(function (response) {
          self.$bvModal.hide('handled_btn_' + self.hashid)
          self.$emit('do-update')
          self.successAlert("Operazione eseguita correttamente")
        })
        .catch(function (error) {
          self.errorAlert("non è stato possibile completare l'operazione")
          // console.log("error fetch prenotazioni", error);
          // self.loading = false;
        })
    },
  },
}
</script>

