<template>
  <div v-if="protocols">
    <b-tabs active-nav-item-class="text-secondary" justified>
      <b-tab class="mb-1">
        <template #title>
          <h5 class="pt-2 pl-2">Azioni operatore di monitoraggio</h5>
        </template>
        <div>
          <div
            class="bg-extra-light pb-3 border border-line-grey border-top-0 shadow_2"
          >
            <b-row
              class="mb-2 pt-2 justify-content-between pr-3"
              align-v="center"
            >
              <p class="text-left px-3 pt-1 mb-0">
                Azioni mostrate nel protocollo di gestione dell'alert per
                l'operatore di control room che lo gestisce. Trascina le azioni
                da una lista all'altra per configurare la lista.
              </p>
              <b-button @click="openNewModal(0)" class="modal-footer-button">
                <b-icon icon="plus"></b-icon> nuova azione
                personalizzata</b-button
              >
            </b-row>
            <b-row align-v="stretch">
              <b-col>
                <div class="h-100 bg-extra-light py-4 general_border shadow_8">
                  <h4 class="text-center pb-3">Protocollo</h4>
                  <draggable
                    :list="listOperatore"
                    group="croom"
                    ghost-class="bg-mdm-light-grey"
                  >
                    <b-col
                      v-for="action in listOperatore"
                      class="col-12 py-2 text-center grab"
                    >
                      <p
                        class="shadow_8 py-1 mb-0 rounded-pill border border-primary bg-white text-primary fw-bolder"
                      >
                        {{ action.label }}
                      </p>
                    </b-col>
                  </draggable>
                </div>
              </b-col>
              <b-col>
                <div class="h-100 bg-white py-4 general_border shadow_8">
                  <h4 class="text-center pb-3">Azioni Disponibili</h4>
                  <draggable
                    :list="allOptionsOp"
                    group="croom"
                    ghost-class="bg-mdm-light-grey"
                  >
                    <b-col
                      v-for="action in allOptionsOp"
                      class="col-12 py-2 text-center grab"
                    >
                      <p
                        class="py-1 mb-0 rounded-pill border border-primary bg-mdm-light-grey text-primary fw-bolder"
                      >
                        {{ action.label }}
                      </p>
                    </b-col>
                  </draggable>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-tab>

      <b-tab class="mb-1">
        <template #title>
          <h5 class="pt-2 pl-2">Azioni operatore sanitario</h5>
        </template>
        <div>
          <div
            class="bg-extra-light pb-3 border border-line-grey border-top-0 shadow_2"
          >
            <b-row
              class="mb-2 pt-2 justify-content-between pr-3"
              align-v="center"
            >
              <p class="text-left px-3 pt-1 mb-0">
                Azioni mostrate nel protocollo di gestione dell'alert per
                l'operatore sanitario che lo gestisce. Trascina le azioni da una
                lista all'altra per configurare la lista.
              </p>
              <b-button @click="openNewModal(0)" class="modal-footer-button">
                <b-icon icon="plus"></b-icon> nuova azione
                personalizzata</b-button
              >
            </b-row>
            <b-row align-v="stretch">
              <b-col>
                <div class="h-100 bg-extra-light py-4 general_border shadow_8">
                  <h4 class="text-center pb-3">Protocollo</h4>
                  <draggable
                    :list="listSanitario"
                    group="sanit"
                    ghost-class="bg-mdm-light-grey"
                  >
                    <b-col
                      v-for="action in listSanitario"
                      class="col-12 py-2 text-center grab"
                    >
                      <p
                        class="shadow_8 py-1 mb-0 rounded-pill border border-primary bg-white text-primary fw-bolder"
                      >
                        {{ action.label }}
                      </p>
                    </b-col>
                  </draggable>
                </div>
              </b-col>
              <b-col>
                <div class="h-100 bg-white py-4 general_border shadow_8">
                  <h4 class="text-center pb-3">Azioni Disponibili</h4>
                  <draggable
                    :list="allOptionsSan"
                    group="sanit"
                    ghost-class="bg-mdm-light-grey"
                  >
                    <b-col
                      v-for="action in allOptionsSan"
                      class="col-12 py-2 text-center grab"
                    >
                      <p
                        class="py-1 mb-0 rounded-pill border border-primary bg-mdm-light-grey text-primary fw-bolder"
                      >
                        {{ action.label }}
                      </p>
                    </b-col>
                  </draggable>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-tab>
    </b-tabs>
    <b-row class="pt-3 px-2 justify-content-center">
      <b-button
        variant="info modal-footer-button"
        class="mx-1"
        @click="cancelAndClose"
        >Annulla</b-button
      >
      <b-button
        variant="primary modal-footer-button"
        class="mx-1"
        @click="storeCustomProtocols"
        >Salva modifiche</b-button
      >
    </b-row>
    <b-modal
      id="new_action"
      centered
      size="lg"
      hide-footer
      title="Azione personalizzata"
    >
      <b-form class="px-2" @submit.prevent="storeCustomProtocol">
        <b-row>
          <b-col>
            <b-form-group label="Nome azione*">
              <b-form-input
                type="text"
                v-model="newAction.label"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Tipologia azione" v-slot="{ ariaDescribedby }">
              <b-form-radio
                v-model="newActionStyleType"
                :aria-describedby="ariaDescribedby"
                name="action_style"
                :value="0"
                >Azione semplice</b-form-radio
              >
              <b-form-radio
                v-model="newActionStyleType"
                :aria-describedby="ariaDescribedby"
                name="action_style"
                :value="1"
                >Somministrazione questionario</b-form-radio
              >
            </b-form-group>
          </b-col>
          <template v-if="newActionStyleType === 0">
            <b-col cols="12" class="my-2">
              <b-form-group label="Descrizione azione*">
                <b-form-textarea
                  type="text"
                  class="border general_border border-grey shadow_6 bg-extralight"
                  v-model="newAction.action_description"
                  rows="5"
                  required
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </template>
          <template v-else>
            <b-col cols="12" class="mt-2 mb-4 pb-4">
              <b-form-group label="Scegli questionario*">
                <b-form-select
                  type="text"
                  class="border general_border border-grey shadow_6 bg-extralight"
                  v-model="newAction.survey_id"
                  rows="5"
                  required
                >
                  <b-form-select-option
                    v-for="survey in surveys"
                    :key="survey.hashid"
                    :value="survey.hashid"
                    >{{ survey.name }}</b-form-select-option
                  ></b-form-select
                >
              </b-form-group>
            </b-col>
          </template>
        </b-row>
        <b-row class="pt-3 px-2 justify-content-center">
          <b-button
            variant="info modal-footer-button"
            class="mx-1"
            @click="$bvModal.hide('new_action')"
            >Annulla</b-button
          >
          <b-button
            variant="secondary modal-footer-button"
            class="mx-1"
            type="submit"
            >Salva azione personalizzata</b-button
          >
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { croomService, supportService } from "@/_services";
import { mapState, mapActions } from "vuex";
export default {
  name: "AlertEventProtocols",
  props: ["subject"],
  components: {
    draggable,
  },
  data() {
    return {
      protocols: null,
      protocolTypes: {
        0: "operatore monitoraggio",
        1: "operatore sanitario",
      },
      availableProtocols: null,
      listOperatore: [],
      listSanitario: [],
      allOptionsOp: [],
      allOptionsSan: [],
      newAction: {
        label: null,
        action_description: null,
        survey_id: null,
      },
      newActionStyleType: 0,
      actionStyleTypes: {
        0: "azione semplice",
        1: "somministrazione questionario",
      },
      surveys: [],
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  watch: {
    availableProtocols: function (val) {
      const self = this;
      if (val) {
        Object.keys(val).forEach(function (el) {
          if (!self.findAction(self.listOperatore, val[el])) {
            self.allOptionsOp.push({
              hashid: val[el].hashid,
              label: val[el].label,
              ref: val[el].ref,
            });
          }
          if (!self.findAction(self.listSanitario, val[el])) {
            self.allOptionsSan.push({
              hashid: val[el].hashid,
              label: val[el].label,
              ref: val[el].ref,
            });
          }
        });
      }
    },
    newActionStyleType: function (value) {
      if (value === 1) {
        this.newAction.label = "Somministra questionario";
      } else {
        this.newAction.label = null;
      }
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    findAction(list, val) {
      const retVal = list.find((el) => val.ref === el.ref);
      return retVal;
    },
    fetchData() {
      this.loading = true;
      const self = this;
      croomService
        .getUserAlertEventProtocols(this.subject.hashid)
        .then(function (response) {
          if (response.data.data[0]) {
            self.listOperatore = response.data.data[0].map(function (el) {
              return {
                hashid: el.protocol.hashid,
                label: el.protocol.label,
                ref: el.protocol.ref,
              };
            });
          }
          if (response.data.data[1]) {
            self.listSanitario = response.data.data[1].map(function (el) {
              return {
                hashid: el.protocol.hashid,
                label: el.protocol.label,
                ref: el.protocol.ref,
              };
            });
          }
          self.protocols = response.data.data;
          self.getDefaultProtocols();
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le informazioni");
        });
    },
    getDefaultProtocols() {
      const self = this;
      croomService
        .getAlertEventProtocols()
        .then(function (response) {
          self.availableProtocols = response.data.data;
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le informazioni");
        });
    },
    storeCustomProtocols() {
      var fullList = [];
      this.listOperatore.forEach(function (el, indx) {
        fullList.push({
          order: indx,
          type: 0,
          ...el,
        });
      });
      this.listSanitario.forEach(function (el, indx) {
        fullList.push({
          order: indx,
          type: 1,
          ...el,
        });
      });
      const self = this;
      const formData = { actions: fullList };
      croomService
        .storeUserProtocols(this.subject.hashid, formData)
        .then(function (response) {
          self.cancelAndClose();
          self.successAlert("Modifiche salvate correttamente!");
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le informazioni");
        });
    },
    cancelAndClose() {
      this.$emit("close-modal");
      this.newAction = {
        label: null,
        action_description: null,
        survey_id: null,
      };
      this.newActionStyleType = 0;
    },
    openNewModal(type) {
      this.getAvailableSurveys();
      this.$bvModal.show("new_action");
    },
    getAvailableSurveys() {
      const self = this;
      const filters = { take: 100, position_ref: 3 };
      supportService
        .getSurveys(filters)
        .then(function (response) {
          self.surveys = response.data.data.data;
          // self.successAlert("Modifiche salvate correttamente!");
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le informazioni");
        });
    },
    storeCustomProtocol() {
      const self = this;
      if (this.newActionStyleType === 0) {
        this.newAction.survey_id = null;
      } else {
        this.newAction.action_description = null;
      }
      croomService
        .storeCustomProtocol(this.newAction)
        .then(function (response) {
          self.$bvModal.hide("new_action");
          self.allOptionsOp = [];
          self.allOptionsSan = [];
          self.fetchData();
          self.successAlert("Azione creata correttamente!");
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile salvare la nuova azione");
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
<style>
.grab {
  cursor: grab;
}
</style>
