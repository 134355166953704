<template>
  <transition-group name="slide">
    <div class="d-flex justify-content-between align-items-center" key="create">
      <div class="col-3 text-left">
        <strong>Create: {{ attivita.create }}</strong>
      </div>
      <div class="col-9">
        <b-progress
          :max="attivita.tot"
          :value="attivita.create"
          variant="prim-grad-1"
          show-value
        ></b-progress>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center" key="attive">
      <div class="col-3 text-left">
        <strong>Attive: {{ attivita.attive }}</strong>
      </div>
      <div class="col-9">
        <b-progress
          :max="attivita.tot"
          :value="attivita.attive"
          variant="success"
          show-value
        ></b-progress>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center" key="totali">
      <div class="col-3 text-left">
        <strong>Totale: {{ attivita.tot }}</strong>
      </div>
      <div class="col-9">
        <b-progress
          :max="attivita.tot"
          :value="attivita.tot"
          variant="prim-grad-2"
          show-value
        ></b-progress>
      </div>
    </div>
    <div
      class="d-flex justify-content-between align-items-center"
      key="somministrate"
      v-if="valori.somministrate"
    >
      <div class="col-3 text-left">
        <strong>Somministrati: {{ attivita.somministrate }}</strong>
      </div>
    </div>
  </transition-group>
</template>
<script>
export default {
  name: "ProgressQuestionari",
  props: ["valori"],
  data() {
    return {
      attivita: {
        tot: 0,
        create: 0,
        attive: 0,
        somministrate: 0,
      },
    };
  },
  mounted() {
    if (this.valori) {
      this.attivita.tot = this.valori.tot;
      this.attivita.create = this.valori.create;
      this.attivita.attive = this.valori.attive;
      this.attivita.somministrate = this.valori.somministrate;
    }
  },
};
</script>