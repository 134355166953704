<template>
  <div>
    <div class="ml-lgx-2 pt-3">
      <h3 class="mt-2 text-left">
        <b-icon
          icon="bookmark-heart"
          variant="secondary"
          font-scale="1.2"
          class="mr-1"
        ></b-icon>
        Circolo di cura
      </h3>

      <section class="puntofarmaCard p-2 p-md-3 p-lg-4 mt-2 general_border">
        <b-row cols="1" cols-md="3" cols-lg="5" cols-xl="6" class="justify-content-center" align-v="stretch">
          <template v-for="company in patientCompanies">
            <b-col class="my-2">
              <CompanyMonitorCard
                class="h-100"
                :azienda="company"
                :is-selected="false"
                :show-heart="true"
                @toggle-preferito="togglePreferito($event)"
                @company-selection="selectCompany($event)"
              ></CompanyMonitorCard>
            </b-col>
          </template>
        </b-row>
      </section>
    </div>
  </div>
</template>
<script>
import { croomService } from "@/_services";
import { mapState, mapActions } from "vuex";
import CompanyMonitorCard from "@/components/utilities/CompanyMonitorCard.vue";
export default {
  name: "CircoloCuraPaziente",
  props: ["user"],
  components: { CompanyMonitorCard },
  data() {
    return { patientCompanies: null };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      const self = this;
      const params = {
        // grouped: true,
      };
      croomService
        .getUserCircoloCura(this.user.hashid, params)
        .then(function (response) {
          self.patientCompanies = response.data.data;
        })
        .catch(function (error) {
          self.errorAlert(
            "Non è stato possibile ottenere la lista di pazienti"
          );
          console.log(error);
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
