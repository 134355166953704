<template>
  <div class="w-sm-100 d-md-flex">
    <section id="nav-desktop" style="z-index: 5">
      <NavigationAdmin :wlistener="innerWidth" />
    </section>
    <section id="main-admin" class="bg-extra-light pt-4 px-4">
      <router-view></router-view>
    </section>
  </div>
</template>
<script>
import { mapState } from "vuex";
import NavigationAdmin from "@/components/layout/NavigationAdmin.vue";

export default {
  name: "AdminMain",
  data() {
    return {
      innerWidth: window.innerWidth,
    };
  },
  components: { NavigationAdmin },
  methods: {
    innerWidthChanged() {
      // console.log("resize handler");
      this.innerWidth = window.innerWidth;
    },
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
  created() {
    if (this.user.type !== 2) {
      this.$router.push({ name: "Home" });
    }
  },
};
</script>

<style lang="scss" scoped>
#nav-desktop {
  transition: flex 500ms;
}

@media all and (min-width: 992px) {
  #main-admin {
    flex: 1;
    min-width: 0;
  }

  #nav-desktop {
    flex: 0 0 100%;
    min-width: 100%;
  }
}

@media all and (max-width: 1199px) {
  #main-admin {
    flex: 1;
    min-width: 0;
  }

  #nav-desktop {
    flex: 0 0 79px;
    min-width: 0;
  }
}

@media all and (min-width: 1200px) {
  #main-admin {
    flex: 1;
    min-width: 0;
  }

  #nav-desktop {
    flex: 0 0 15em;
    min-width: 0;
  }
}

@media all and (min-width: 813px) {
  #main-admin {
    min-height: calc(100vh - 37.75px);
  }
}

@media all and (min-width: 448px) and (max-width: 812px) {
  #main-admin {
    min-height: calc(100vh - 59.5px);
  }
}

@media all and (min-width: 312px) and (max-width: 447px) {
  #main-admin {
    min-height: calc(100vh - 81.25px);
  }
}

@media all and (max-width: 311px) {
  #main-admin {
    min-height: calc(100vh - 95px);
  }
}
</style>

