<template>
  <b-row align-v="center" v-if="!editModeOn">
    <b-col class="px-1 fw-bolder text-center">
      <span v-if="reference">
        <span
          v-if="reference.min_ref !== reference.max_ref"
          class="text-justify"
          >{{ reference.min_ref * 1 }}-{{ reference.max_ref * 1 }}
        </span>
        <span v-else class="text-justify">
          <span v-if="reference.threshold_level !== 0">
            {{ reference.threshold_level < 0 ? "&le;" : "&ge;" }}
          </span>
          {{ reference.min_ref * 1 }}
        </span>
      </span>
      <span v-else class="text-transparent">-</span>
    </b-col>
    <!-- <b-col v-if="editable" cols="5" col-lg="4" class="text-left px-1"> -->
    <b-col v-if="editable" cols="12" class="text-center px-1">
      <template>
        <span class="p-1 point" title="modifica soglia" @click="startEdit">
          <!-- <b-icon icon="pencil-fill" variant="primary" font-scale="1.3"></b-icon > -->
          <span class="fw-bolder text-primary underlined">{{
            reference ? "Modifica" : "Aggiungi"
          }}</span>
        </span>
      </template>
    </b-col>
  </b-row>
  <b-row align-v="center" cols="1" v-else>
    <b-form @submit.prevent="saveEdits" @reset="resetFields">
      <div class="col-12">
        <b-form-input
          class="mx-2 col-4 d-inline"
          v-if="signValue === 0"
          v-model="edit.min_ref"
          type="number"
          :state="validMin ? null : false"
        ></b-form-input>
        <b-form-select v-model="signValue" class="mx-2 col-3 col-lg-2 d-inline">
          <b-form-select-option :value="-1">&le;</b-form-select-option>
          <b-form-select-option :value="0"> - </b-form-select-option>
          <b-form-select-option :value="1">&ge;</b-form-select-option>
        </b-form-select>
        <b-form-input
          type="number"
          class="mx-2 col-4 d-inline"
          v-model="edit.max_ref"
          :state="validMin ? null : validMin === null ? null : false"
        ></b-form-input>
      </div>
      <b-row class="justify-content-center mt-2">
        <b-button
          size="sm"
          variant="outline-info"
          class="mx-2 modal-footer-button"
          type="reset"
          >Annulla</b-button
        >
        <b-button
          v-if="reference.hashid"
          size="sm"
          variant="outline-danger"
          class="mx-2 modal-footer-button"
          type="button"
          @click="$bvModal.show('confirm_remove' + reference.hashid)"
          >Rimuovi</b-button
        >
        <b-button
          size="sm"
          variant="primary"
          class="mx-2 modal-footer-button"
          type="submit"
          :disabled="validMin === false"
          >Salva</b-button
        >
      </b-row>
    </b-form>
    <b-modal
      :id="'confirm_remove' + reference.hashid"
      title="Conferma operazione"
      class="text-center"
      ok-title="Sì, elimina"
      ok-variant="outline-danger"
      cancel-title="No, annulla"
      cancel-variant="info mr-3"
      footer-border-variant="white"
      @ok="requestDelete"
      @cancel="$bvModal.hide('confirm_remove' + reference.hashid)"
      centered
    >
      <h6>Sei sicuro di voler eliminare questa soglia?</h6>
    </b-modal>
  </b-row>
</template>
<script>
export default {
  name: "ThresholdEditor",
  props: ["reference", "editable"],
  data() {
    return {
      edit: {
        min_ref: null,
        max_ref: null,
      },
      editModeOn: false,
      signValue: 0,
      refSignOptions: [
        { text: "&le;", value: 0 },
        { text: "&ge;", value: 1 },
        { text: "-", value: 2 },
      ],
    };
  },
  computed: {
    validMin() {
      console.log(this.signValue === 0 ? "true" : "false");
      if (this.signValue === 0) {
        if (this.edit.min_ref !== null && this.edit.max_ref !== null) {
          return parseFloat(this.edit.max_ref) >= parseFloat(this.edit.min_ref);
        }
      }
      return null;
    },
  },
  methods: {
    startEdit() {
      this.edit.min_ref = this.reference ? this.reference.min_ref * 1 : 0;
      this.edit.max_ref = this.reference ? this.reference.max_ref * 1 : 0;
      this.editModeOn = true;
    },
    saveEdits() {
      if (this.signValue !== 0) {
        this.edit.min_ref = this.edit.max_ref;
      }
      this.$emit("save-edits", this.edit);
      this.editModeOn = false;
    },
    resetFields() {
      this.edit.min_ref = this.reference ? this.reference.min_ref * 1 : 0;
      this.edit.max_ref = this.reference ? this.reference.max_ref * 1 : 0;
      this.editModeOn = false;
    },
    requestDelete() {
      this.$emit("remove-reference", this.reference.hashid);
      this.$bvModal.hide("confirm_remove" + this.reference.hashid);
      this.editModeOn = false;
    },
  },
  created() { },
  mounted() { },
};
</script>
