<template>
  <div>
    <p
      class=" text-primary point"
      v-if="!showPic"
      @click="getAllegato"
    >
      <u> scarica allegato {{ indx }}</u>
    </p>
      <p v-if="showError" class="text-danger">
        non è stato possibile recuperare l'allegato
      </p>
    <!-- <div v-else>
      <a :href="picLink" :download="fileName? fileName:'allegato'+indx">Apri</a>
      <b-img
        v-if="fileType==='image' && picLink"
        :ref="'allegato_'+indx"
        :src="picLink"
        no-body
        fluid-grow
        width="100%"
        height="100%"
      />
      <p v-if="showError" class="text-danger">
        non è stato possibile recuperare l'immagine
      </p>
      <b-button
        v-if="picLink"
        size="sm"
        variant="danger   px-3 my-1"
        class="mr-0 ml-auto text-right"
        @click="showPic = false"
      >
        nascondi immagine {{ indx }}
      </b-button>
    </div> -->
  </div>
</template>
<script>
import { supportService } from "@/_services";
export default {
  name: "ImageShower",
  props: ["hash", "indx"],
  data() {
    return {
      picLink: null,
      showPic: false,
      showError: false,
      fileType: null,
      fileName: null
    };
  },
  methods: {
    getAllegato() {
      // this.showPic = true;
      var self = this;
      supportService
        .getAllegato(this.hash)
        .then(function (res) {
          const contentType = res.headers?.['content-type']
          const contentName = res.headers?.['content-disposition']
          self.fileName = "allegato"
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(contentName);
          if (matches != null && matches[1]) {
            self.fileName = matches[1].replace(/['"]/g, '');
          }
          // if (contentType === 'application/pdf') {
          //   self.getPdf()
          // } else {
          let blob = new Blob([res.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = self.fileName;
          link.click();
          // }

          // if (contentType.startsWith('image/')) {
          //   self.fileType = "image";
          // } else if (contentType.startsWith('application/')) {
          //   self.fileType = "application";
          // }
          //TO SHOW IMAGE
          // self.picLink = window.URL.createObjectURL(new Blob([res.data]));
          // self.showError = false;
        })
        .catch(function (err) {
          console.log(err);
          self.showError = true;
        });
    },
    getPdf() {
      // this.showPic = true;
      var self = this;
      supportService
        .getAllegato(this.hash, "blob")
        .then(function (res) {
          console.log(self.fileName)
          let blob = new Blob([res.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = self.fileName;
          link.click();
        })
        .catch(function (err) {
          console.log(err);
          self.showError = true;
        });
    },
  },
};
</script>