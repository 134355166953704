<template>
  <div class="">
    <div v-if="!showOk">
      <h4 class="text-prim-grad-1 text-left pb-2 mb-4 page-title">
        Crea questionario
      </h4>
      <div
        class="puntofarmaCard p-4"
        :class="$root.isMobile ? 'col-12' : 'col-10'"
      >
        <b-form-group
          size="sm"
          class="text-left primary-label px-0"
          label="Seleziona la raccolta in cui vuoi inserire il nuovo questionario"
        >
          <b-form-select
            name="type"
            class="align-items-center align-content-center  border border-info text-info text-capitalize"
            v-model="selectedActivity"
            required
          >
            <b-form-select-option :value="null"></b-form-select-option>
            <b-form-select-option
              v-for="(activity, ind) in activities"
              :key="ind"
              :value="activity.hashid"
              >{{ activity.name }}</b-form-select-option
            >
          </b-form-select>
        </b-form-group>
      </div>
      <NuovoQuestionario
        v-if="selectedActivity"
        :activityhash="selectedActivity"
        v-on:send-questionario="storeNew($event)"
      />
    </div>
    <div v-else>
      <h2 class="pt-4 mt-4">
        Operazione completata
        <b-icon icon="check-circle" variant="secondary"></b-icon>
      </h2>
    </div>
  </div>
</template>
<script>
import { supportService } from "@/_services"
import { mapState, mapActions } from "vuex"
import NuovoQuestionario from "@/components/attivita/NuovoQuestionario.vue"
export default {
  name: "CreaQuestionario",
  components: { NuovoQuestionario },
  data() {
    return {
      activities: null,
      selectedActivity: null,
      showOk: false
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    })
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    fetchAttivita() {
      var self = this
      supportService
        .getActivities(this.filterSetted)
        .then(function (res) {
          self.activities = res.data.data
        })
        .catch(function (err) {
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare le attività")
          // console.log(err);
        })
    },
    storeNew(data) {
      var self = this
      supportService
        .newQuestionario(data)
        .then(function (res) {
          // console.log(res);
          self.successAlert("Questionario creato correttamente!")
          self.showOk = true
        })
        .catch(function (err) {
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile creare il nuovo questionario")
          // console.log(err);
        })
    }
  },
  created() {
    this.fetchAttivita()
    if (this.$route.query.activity) {
      window.scrollTo(0, 0)
      this.selectedActivity = this.$route.query.activity
    }
  }
}
</script>
