<template>
  <b-row v-if="ownAsl">
    <b-col cols="3" md="2" class="px-0 justify-self-start">
        <b-avatar
          variant="white"
          rounded="sm"
          :src="ownAsl.profile_pic"
        ></b-avatar>
      </b-col>
      <b-col class="px-1 text-justify">
        <h5 class="">{{ ownAsl.denominazione }}</h5>
        <p class="mb-0 mt-3 text-capitalize text-justify">
          {{ ownAsl.indirizzo }}, {{ ownAsl.frazione }}
          {{ ownAsl.comune }}
        </p>
      </b-col>
  </b-row>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { companyService } from "@/_services";
export default {
  name: "AslCard",
  data() {
    return {
      ownAsl: null,
    }
  },
  mounted() {
    this.fetchData()
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      var self = this;
      companyService
        .getOwnAsl()
        .then(function (res) {
          self.ownAsl = res.data.data.own;
          self.$emit('selected-asl', res.data.data.own)
        })
        .catch(function (err) {
          console.log(err);
          self.notFound = true;
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le informazioni.");
        });
    },

  }
}
</script>