<template>
  <div>
    <div class="ml-lgx-2">
      <h3 class="mt-2 text-left">
        <b-icon
          icon="battery-charging"
          variant="secondary"
          font-scale="1.2"
          class="mr-1"
        ></b-icon>
        Stato Profilo
      </h3>
      <section class="puntofarmaCard p-2 p-md-3 p-lg-4 mt-2 general_border">
        <b-row>
          <b-col class="py-2">
            <template v-if="subscriptions">
              <div
                class="text-left px-2"
                v-for="sub in subscriptions"
                :key="sub.nome"
              >
                <h5>
                  Sottoscrizione attiva:
                  <span class="text-primary"
                    >{{ sub.nome }} {{ sub.durata }} mesi</span
                  >
                </h5>
                <p class="text-justify">{{ sub.descrizione }}</p>
              </div>
              <b-row
                align-v="center"
                class="px-2"
                v-if="subscriptions.length === 0"
              >
                <h6 class="text-left mb-0">
                  Sottoscrizione non attiva
                  <b-icon icon="x-circle" variant="danger"></b-icon>
                </h6>
                <b-button
                  type="button"
                  size="sm"
                  variant="secondary modal-footer-button m-1 "
                  @click="$bvModal.show('subscribe-user')"
                >
                  Attiva monitoraggio
                </b-button>
              </b-row>
            </template>
            <b-spinner v-else></b-spinner>
          </b-col>
          <b-col class="py-2">
            <b-row align-v="center" v-if="!user.profilo_sospeso">
              <h6 class="text-left mb-0">
                Profilo attivo
                <b-icon icon="check-circle" variant="secondary"></b-icon>
              </h6>
              <b-button
                type="button"
                size="sm"
                variant="outline-danger modal-footer-button m-1 "
                @click="$bvModal.show('confirm-suspension')"
              >
                Sospendi profilo
              </b-button>
            </b-row>
            <b-row align-v="center" v-else>
              <h6 class="text-left mb-0">
                Profilo disattivato
                <b-icon icon="x-circle" variant="danger"></b-icon>
              </h6>
              <b-button
                type="button"
                size="sm"
                variant="outline-primary modal-footer-button m-1 "
                @click="$bvModal.show('confirm-activation')"
              >
                Riattiva profilo
              </b-button>
            </b-row>
          </b-col>
        </b-row>
      </section>
    </div>

    <b-modal
      id="confirm-suspension"
      size="lg"
      centered
      title="Conferma operazione"
      button-size="sm"
      cancel-title="No, Annulla"
      cancel-variant="info mr-2"
      ok-title="Sì, Conferma"
      ok-variant="outline-danger"
      footer-class="border-0"
      @ok="suspendUserProfile(true)"
      @cancel="$bvModal.hide('confirm-suspension')"
    >
      <h6>
        Sei sicuro di voler sospendere il profilo di {{ user.anagrafica.name }}
        {{ user.anagrafica.surname }}?
      </h6>
      <p>
        Una volta sospeso il profilo non sarà più possibile inserire né
        modificare nessun dato sulla scheda del paziente.
      </p>
    </b-modal>

    <b-modal
      id="confirm-activation"
      size="lg"
      centered
      title="Conferma operazione"
      button-size="sm"
      cancel-title="No, Annulla"
      cancel-variant="info mr-2"
      ok-title="Sì, Conferma"
      ok-variant="outline-primary"
      footer-class="border-0"
      @ok="suspendUserProfile(false)"
      @cancel="$bvModal.hide('confirm-activation')"
    >
      <h6>
        Sei sicuro di voler riattivare il profilo di {{ user.anagrafica.name }}
        {{ user.anagrafica.surname }}?
      </h6>
      <p>
        Una volta riattivato il profilo sarà nuovamente possibile inserire e
        modificare dati sulla scheda del paziente.
      </p>
    </b-modal>
    <b-modal
      id="subscribe-user"
      size="lg"
      centered
      title="Conferma operazione"
      button-size="sm"
      cancel-title="No, Annulla"
      cancel-variant="info mr-2"
      ok-title="Sì, Conferma"
      ok-variant="outline-primary"
      footer-class="border-0"
      @ok="activateMonitoring"
      @cancel="$bvModal.hide('subscribe-user')"
    >
      <h6>
        Sei sicuro di voler attivare il servizio di monitoraggio per
       <span class="text-capitalize"> {{ user.anagrafica.name }} {{ user.anagrafica.surname }}</span>?
      </h6>
      <p>
        Con questa operazione verrà avviato il processo di attivazione della
        sottoscrizione al servizio di monitoraggio. Per il monitoraggio dei
        principali parametri verranno impostati i valori-soglia di default; le
        impostazioni relative alla frequenza di rilevazione andranno invece
        impostate manualmente sulla scheda del paziente.
      </p>
    </b-modal>
  </div>
</template>

<script>
import { cittadinoService, croomService } from "@/_services";
import { mapState, mapActions } from "vuex";
export default {
  name: "SospendiProfilo",
  props: ["user"],
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    suspendUserProfile(stat) {
      const self = this;
      const formData = { status: stat };
      cittadinoService
        .suspendUserProfile(this.user.hashid, formData)
        .then(function (res) {
          self.successAlert("Profilo sospeso correttamente.");
          self.$bvModal.hide("confirm-suspension");
          self.$emit("need-update");
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile completare l'operazione.");
        });
    },
    getUserSubscriptions() {
      const self = this;
      const filters = {
        service_ref: 8,
      };
      croomService
        .getUserSubscriptions(this.user.hashid, filters)
        .then(function (res) {
          self.subscriptions = res.data.data;
        })
        .catch(function (err) {
          console.log(err);
          self.errorAlert("Non è stato possibile completare l'operazione.");
        });
    },
    activateMonitoring() {
      const self = this;
      croomService
        .activateMonitoring(this.user.hashid)
        .then(function (res) {
          self.$bvModal.hide('subscribe-user')
          self.successAlert("Attivazione eseguita correttamente!");
        })
        .catch(function (err) {
          console.log(err);
          self.$bvModal.hide('subscribe-user')
          self.errorAlert("Non è stato possibile completare l'operazione.");
        });
    },
  },
  data() {
    return {
      subscriptions: null,
    };
  },
  created() {
    this.getUserSubscriptions();
  },
};
</script>
