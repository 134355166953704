<template>
  <div
    v-if="user && user.permessi.includes('gestire attività e questionari')"
    class="col-12 mt-1 mx-0 px-4"
  >
    <h4 class="text-left pb-1 mt-1 mb-2 page-title">Crea questionario</h4>
    <!-- <div class="d-flex justify-content-between">
      <router-link
        class=" text-primary no__hover"
        :to="{ name: 'attivita' }"
      >
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Attività</router-link
      >
    </div> -->
    <div v-if="!showOk">
      <div class="col-12 col-md-10 text-left mt-3 puntofarmaCard p-4">
        <b-form-group
          size="sm"
          class="text-left primary-label"
          label="Seleziona la raccolta in cui inserire il nuovo questionario"
        >
          <b-form-select
            name="type"
            class="align-items-center align-content-center border border-info text-info text-capitalize"
            v-model="selectedActivity"
            required
          >
            <b-form-select-option :value="null"></b-form-select-option>
            <b-form-select-option
              v-for="(activity, ind) in activities"
              :key="ind"
              :value="ind"
              >{{ activity }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <p v-if="noActivities" class="text-justify">
          <strong> Non hai ancora creato nessuna attività.</strong> Crea una
          nuova raccolta in cui inserire il questionario che intendi creare.
        </p>
      </div>
      <NuovoQuestionario
        v-if="selectedActivity"
        :activityhash="selectedActivity"
        v-on:send-questionario="storeNew($event)"
      />
    </div>
    <div class="d-flex flex-column align-items-center" v-else>
      <h2 class="">
        Operazione completata
        <b-icon icon="check-circle" variant="secondary"></b-icon>
      </h2>
      <b-button
        class="mt-5 text-center"
        @click="
          $router.push({
            name: 'attivita',
            params: { section: 'raccolte' },
          })
        "
        variant="outline-secondary"
        >Torna alle raccolte</b-button
      >
    </div>
  </div>
  <div v-else>
    <h4>Funzionalità non abilitata per questa utenza.</h4>
  </div>
</template>
<script>
import { supportService } from "@/_services";
import { mapState, mapActions } from "vuex";
import NuovoQuestionario from "@/components/attivita/NuovoQuestionario.vue";
export default {
  name: "CreaNuovoQuestionario",
  components: { NuovoQuestionario },
  data() {
    return {
      activities: null,
      selectedActivity: null,
      showOk: false,
      filterSetted: {
        inarray: true,
      },
      noActivities: false,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchAttivita() {
      var self = this;
      supportService
        .getActivities(this.filterSetted)
        .then(function (res) {
          self.activities = res.data.data;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          // self.errorAlert("Non è stato possibile recuperare le attività");
          self.noActivities = true;
          // console.log(err);
        });
    },
    storeNew(data) {
      var self = this;
      supportService
        .newQuestionario(data)
        .then(function (res) {
          // console.log(res);
          self.successAlert("Questionario creato correttamente!");
          self.showOk = true;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile creare il nuovo questionario");
          // console.log(err);
        });
    },
  },
  created() {
    this.fetchAttivita();
    if (this.$route.query.activity) {
      window.scrollTo(0, 0);
      this.selectedActivity = this.$route.query.activity;
    }
  },
};
</script>
