<template>
  <div>
    <b-col class="col-12 align-content-center py-2 py-md-3 py-xl-4">
      <div class="mx-auto col-10 col-md-4 col-xl-3 py-2">
        <b-img
          :src="require('@/assets/puntofarma-logo.svg')"
          fluid-grow
          alt="logo puntofarma"
        ></b-img>
      </div>
    </b-col>
    <div
      class="col-12 col-md-10 col-lg-8 col-xl-6 mx-auto mt-4 puntofarmaCard general_border py-4 px-2"
    >
      <h4 class="pb-4">Reimposta la tua password</h4>
      <br />
      <div class="col-10 mx-auto mb-4">
        <p>
          La nuova password deve contenere almeno 8 caratteri, di cui almeno un
          numero, una maiuscola, una minuscola ed un
          <span
            v-b-tooltip
            title="Devi usare almeno un caratterere tra ! @ # $ % ^ & * ) ( + = . _ -"
            >carattere speciale</span
          >.
        </p>
      </div>
      <b-form @submit.prevent="OnSubmit" class="py-3">
        <b-form-input
          class="col-lg-5 col-sm-8 mx-auto my-3"
          name="password"
          type="password"
          v-model="form.password"
          placeholder="password..."
          :state="validatePassword"
          required
        ></b-form-input>
        <b-form-input
          class="col-lg-5 col-sm-8 mx-auto my-3"
          name="password"
          type="password"
          v-model="form.password_confirmation"
          placeholder="conferma password..."
          :state="validateConfirm"
          required
        >
        </b-form-input>
        <span v-if="status.loggedIn == false" class="text-danger"
          >Credenziali errate.</span
        >
        <b-button
          type="submit"
          :disabled="cantSubmit"
          variant="primary text-white my-3 col-lg-4 col-sm-7"
          >Salva nuova password
        </b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "RecoverPassword",
  data() {
    return {
      form: {
        username: this.$route.query.username,
        password: null,
        password_confirmation: null,
        token: this.$route.query.token,
      },
      has_minimum_lenth: true,
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
    };
  },
  computed: {
    validatePassword() {
      if (this.form.password) {
        this.has_minimum_lenth = this.form.password.length > 8;
        this.has_number = /\d/.test(this.form.password);
        this.has_lowercase = /[a-z]/.test(this.form.password);
        this.has_uppercase = /[A-Z]/.test(this.form.password);
        this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.form.password);
        return (
          this.has_minimum_lenth &&
          this.has_number &&
          this.has_lowercase &&
          this.has_uppercase &&
          this.has_special
        );
      }
      return false;
    },
    validateConfirm() {
      if (this.form.password) {
        return this.form.password_confirmation === this.form.password;
      }
      return false;
    },
    cantSubmit() {
      if (this.form.password && this.form.password_confirmation) {
        return this.form.password_confirmation !== this.form.password;
      }
      return true;
    },

    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    ...mapActions("utente", ["resetPassword"]),
    OnSubmit() {
      // console.log(this.form);
      if (this.form) {
        this.resetPassword(this.form)
          .then((res) => {
            // console.log("after login ok", res);
          })
          .catch((error) => {
            // console.log("after login error", error);
          });
      }
    },
  },
};
</script>
<style scoped>
input {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

input::placeholder {
  color: #f75010;
}
</style>
