<template>
  <div class="py-3">
    <div v-if="template" class="">
      <!-- <p class="text-justify text-medium-grey ">
        {{ template.template.domande.length }} quesiti.
      </p> -->
      <!-- <b-form @submit="sendForm"></b-form> -->
      <b-row cols="1" class="align-items-end">
        <transition-group name="list" mode="out-in" appear>
          <b-col v-for="(domanda, ind) in template.template.domande" :key="'qqst_' +ind">
            <div v-if="canBeShown(ind) && fitoKeys.includes(domanda.k)">
              <b-form-group :label="domanda.q" class="text-left col-10 px-0" v-if="handleFito(ind, domanda.k)"
                :key="'fgr_' + ind" label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="8">
                <b-form-select class="  align-self-center" v-if="domanda.o" v-model="domanda.a"
                  :options="domanda.o" @change="showNext(ind)" required>
                </b-form-select>
                <b-form-input v-else class="  align-self-center" placeholder="..." type="text" :name="'q_' + ind"
                  v-model="domanda.a" @input="showNext(ind)" required></b-form-input>
              </b-form-group>
            </div>
            <div v-else-if="
              prodSelected == 'Integratore' && domanda.k == 'cod_fito'
            ">
              <b-form-group :label="domanda.q" class="text-left col-10 px-0" :key="'fgr_' + ind" label-cols-sm="4"
                label-cols-lg="4" content-cols-sm content-cols-lg="8">
                <b-form-select class="  align-self-center" v-if="domanda.o" v-model="domanda.a"
                  :options="domanda.o" @change="showNext(ind)" required>
                </b-form-select>
              </b-form-group>
            </div>
            <b-form-group :label="domanda.q + (domanda.r === true ? '*' : '')" class="text-left col-10 px-0"
              v-else-if="canBeShown(ind) && domanda.k !== 'cod_fito'" :key="'fgr_' + ind" label-cols-sm="4"
              label-cols-lg="4" content-cols-sm content-cols-lg="8">
              <div v-if="domanda.o && domanda.t === 'multi'" class="align-self-center">
                <b-form-tags v-model="domanda.a" no-outer-focus class="mb-2">
                  <template v-slot="{ tags, disabled, addTag, removeTag }">
                    <b-dropdown size="sm" block variant="prim-grad-1" class="  align-self-center">
                      <b-dropdown-form @submit.stop.prevent="() => { }">
                      </b-dropdown-form>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item-button v-for="option in domanda.o" :key="option"
                        @click="onOptionClick({ ind, option, addTag })">
                        {{ option }}
                      </b-dropdown-item-button>
                    </b-dropdown>
                    <ul v-if="tags.length > 0" class="list-inline d-inline-block mt-2">
                      <li v-for="tag in tags" :key="tag" class="list-inline-item">
                        <b-form-tag @remove="removeTag(tag)" :title="tag" :disabled="disabled"
                          variant="prim-grad-1  px-3">{{ tag }}</b-form-tag>
                      </li>
                    </ul>
                  </template>
                </b-form-tags>
              </div>
              <b-form-select class="  align-self-center" v-else-if="domanda.o" v-model="domanda.a"
                :options="domanda.o" @change="showNext(ind)" required>
              </b-form-select>
              <div v-else-if="domanda.t == 'search'">
                <b-form-input class="  align-self-center" type="search"
                  placeholder="Cerca prodotto per nome o aic.." v-model="domanda.a" @input="searchProds(ind)">
                </b-form-input>
                <div v-if="products !== null">
                  <span class="mt-1">Seleziona dall'elenco:</span>
                  <b-form-select class="mt-1 bg-prim-grad-1 text-white align-self-center" v-model="domanda.a"
                    @change="showNext(ind)" required>
                    <b-form-select-option v-for="prod in products" :value="prod.Nome + ' - ' + prod.Aic"
                      :key="prod.Nome">{{ prod.Nome }}</b-form-select-option>
                  </b-form-select>
                </div>
              </div>
              <b-form-input v-else-if="domanda.t == 'cf'" class="  align-self-center text-uppercase"
                placeholder="..." type="text" :name="'q_' + ind" v-model="domanda.a" maxlength="16" minlength="16"
                :formatter="upFormatter" @input="showNext(ind)" required></b-form-input>

              <b-form-input v-else-if="domanda.t == 'date'" class="  align-self-center" placeholder="..."
                type="date" :name="'q_' + ind" v-model="domanda.a" @input="showNext(ind)" required></b-form-input>
              <b-form-input v-else-if="domanda.t == 'decimal'" class="  align-self-center" type="number"
                step="0.25" min="0.25" :name="'q_' + ind" v-model="domanda.a" @input="showNext(ind)" required>
              </b-form-input>
              <b-form-input v-else class="  align-self-center" placeholder="..." type="text" :name="'q_' + ind"
                v-model="domanda.a" @input="showNext(ind)" required></b-form-input>
            </b-form-group>
            <div class="my-4 py-2 text-right" v-if="
              canBeShown(ind) &&
              domanda.r === false &&
              (!canBeShown(ind + 1) ||
                ind === template.template.domande.length - 1)
            ">
              <b-button type="button" suze="sm" @click="showNext(ind)">Salta</b-button>
            </div>
            <div class="my-4 py-2" v-if="
              ind === template.template.domande.length - 1 &&
              indsToShow.includes(ind + 1)
            ">
              <b-button type="button" variant="primary  text-white " @click="sendForm">
                Invia</b-button>
            </div>
          </b-col>
        </transition-group>
      </b-row>
    </div>
  </div>
</template>
<script>
import { utilityService } from "@/_services";
export default {
  name: "CompilaQuestionarioIrst",
  props: ["survey"],
  data() {
    return {
      template: null,
      consent: false,
      proceed: false,
      prodSearch: { q: null },
      products: null,
      indsToShow: [0],
      fitoKeys: ["prod1", "prod2", "prod3", "prod4"],
      prodSelected: null,
    };
  },
  // watch: {
  //   template: {
  //     handler(val) {
  //       console.log("TEMPLATE CHANGED", this.template.template.domande);
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    sendForm() {
      this.$emit("filled-form", this.template);
    },
    upFormatter(value) {
      return value.toUpperCase();
    },
    canBeShown(ind) {
      return this.indsToShow.includes(ind);
    },
    showNext(ind) {
      if (!this.indsToShow.includes(ind + 1)) {
        var indxs = this.indsToShow;
        indxs.push(ind + 1);
        this.indsToShow = indxs;
      }
      if (this.template.template.domande[ind].k == "tipo_prod") {
        this.prodSelected = this.template.template.domande[ind].a;
      }
    },
    onOptionClick({ ind, option, addTag }) {
      addTag(option);
      this.showNext(ind);
    },
    handleFito(ind, key) {
      var toShow = null;
      switch (this.prodSelected) {
        case "Alimento":
          toShow = "prod1";
          break;
        case "Integratore":
          toShow = "prod2";
          break;
        case "Omeopatico":
          toShow = "prod3";
          break;
        case "Fitoterapico":
          toShow = "prod4";
          break;
      }
      if (toShow == key) {
        return true;
      }
      this.showNext(ind);
      return false;
    },
    searchProds(index) {
      this.prodSearch.q = this.template.template.domande[index].a;
      // console.log(this.prodSearch);
      // console.log("sel prod len", this.selectedProducts.length);
      // if (this.prodSearch.q && this.prodSearch.q.length >= 3) {
      var self = this;
      this.showNotFound = false;
      utilityService
        .products(this.prodSearch)
        .then(function (response) {
          self.products = response.data;
          if (!(response.data instanceof Array)) {
            self.products = [response.data];
          }
        })
        .catch(function (error) {
          // self.showNotFound = true;
        });
      // } else if (this.selectedProducts.length === 0) {
      //   this.products = null;
      // }
    },
  },
  created() {
    // console.log("quest irst");
    if (this.survey) {
      this.template = this.survey;
      try {
        var parsed = JSON.parse(this.survey.template);
        this.template.template = parsed;
      } catch {
        //
      }
    }
  },
};
</script>