<template>
  <div id="app" class="d-flex flex-column">
    <!-- header -->
    <!-- <div v-if="!user" class="text-center" :class="$root.isMobile && 'pt-2'">
      <router-link
        :to="{ name: 'LoginPage' }"
        class="app__logo mx-auto logo__only"
        :style="cssLogo"
      >
        <span class="p-2 my-2"></span>
      </router-link>
    </div> -->
    <!-- notifications -->
    <b-alert
      :variant="alert.type"
      dismissible
      fade
      :show="alert.message !== null"
      @dismissed="clearAlert"
      @dismiss-count-down="clearAlert"
      class="col-12 mr-0 ml-auto fixed__alert"
    >
      {{ alert.message }}
    </b-alert>
    <!-- MAIN -->
    <!-- <b-container fluid class="p-0 custom__content__df main__operatore"> -->
    <b-container fluid class="p-0 custom__content__df">
      <b-row cols="1">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </b-row>
    </b-container>
    <!-- footer -->
    <!-- <b-button @click="setCssPuntofarma">CAMBIA TEMA COLORE</b-button> -->
    <div class="py-0 px-0 mt-auto pb-3" :style="footerStyle">
      <div class="px-0 text-center fs-6 bg-extra-light">
        <!-- :class="showScrollBtn ? 'col-lg-9' : 'col-lg-12'" -->
        <span class="mx-1 line__h">
          Copyright © - Qwince s.r.l. - Via Catania 5, 90141 Palermo - Partita
          IVA: 11801100964
        </span>
        <span>- </span>
        <template v-if="user && user.type === 1">
          <a :href="termsConditionLinks.operatore" target="__blank"
            >Termini e condizioni
          </a>
          <span class="mx-0 px-0"> - </span>
          <a
            :href="privacyPolicyLinks.operatore"
            target="__blank"
            class="mx-0 px-0"
          >
            Privacy Policy
          </a>
        </template>
        <template v-else>
          <a :href="termsConditionLinks.paziente" target="__blank"
            >Termini e condizioni
          </a>
          <span class="mx-0 px-0"> - </span>
          <a
            :href="privacyPolicyLinks.paziente"
            target="__blank"
            class="mx-0 px-0"
          >
            Privacy Policy
          </a>
        </template>
        <span class="mx-0 px-0"> - </span>
        <a class="point" @click="$bvModal.show('about-modal')" to="">About</a>
        <span class="mx-0 px-0"> - </span>
        <ChangeLogComponent />
      </div>
    </div>
    <b-modal id="about-modal" body-class="px-3 pb-4 pt-0" centered hide-footer>
      <div class="px-2 col-12">
        <section class="general_border" style="border: 1px solid lightgrey">
          <section class="text-left px-2 pt-2">
            <h4>PUNTOFARMA</h4>
            <p class="mb-0">Versione: {{ currentPfVersion }}</p>
            <p class="mb-0">EUDAMED Manufacturer ID: IT-MF-000029688</p>
            <p class="mb-0">Identificativo di Registrazione: 2305257</p>
            <!-- <span
              style="position: absolute; right: 5px; bottom: -50px; z-index: 1"
            >
              <img
                height="120px"
                :src="require('@/assets/puntofarma-dot-logo.svg')"
              />
            </span> -->
          </section>
          <b-row align-v="end" class="flex-nowrap">
            <b-col class="pl-0">
              <VueBarcode
                value="8056897340011"
                format="EAN13"
                height="80"
                marginBottom="0"
                textAlign="center"
                font="inherit"
              >
              </VueBarcode>
            </b-col>
            <b-col cols="4" class="text-right px-0">
              <span
                style="position: absolute; right: 7px; bottom: 10px; z-index: 1"
              >
                <img
                  height="120px"
                  :src="require('@/assets/puntofarma-dot-logo.svg')"
                />
              </span>
            </b-col>
          </b-row>
          <section id="lower-iso-card" class="p-2 general_border" style="">
            <article>
              <p class="mb-0 text-left">Fabbricante: Qwince Innovation Srl</p>
              <p class="mb-0 text-left">
                Indirizzo: via Catania, 5 – 90141 Palermo
              </p>
              <p class="mb-0 text-left">P. IVA: 11801100964</p>
            </article>
            <article>
              <p class="mb-1 text-right" style="line-height: 10px">
                <img
                  height="20px"
                  class="mr-1"
                  :src="require('/public/assets/md.png')"
                />
                <img
                  height="10px"
                  :src="require('/public/assets/marchio_ce.svg')"
                />
              </p>
              <p style="font-size: 10px">Dispositivo Medico Classe I</p>
            </article>
          </section>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<style lang="scss">
.indxz {
  z-index: 30;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  min-height: 100vh;
}

#page__bottom {
  background-color: darkgray;
  min-height: 100%;
}

#lower-iso-card {
  display: flex;
  justify-content: space-between;
  background-color: whitesmoke;
  font-size: 13px;
  font-weight: 550;
  z-index: 100;
  position: relative;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  margin-top: -5px;
}

.custom__content__df {
  min-height: 70vh;
}

.line__h {
  line-height: 2.5;
}

.fixed__alert {
  position: fixed !important;
  z-index: 9999999 !important;
}
</style>
<script>
// @ is an alias to /src
import { mapState, mapActions } from "vuex";
import TopHeader from "@/components/layout/TopHeader.vue";
import LeftHeader from "@/components/layout/LeftHeader.vue";
import ChangeLogComponent from "@/components/layout/ChangeLogComponent.vue";
import VueBarcode from "vue-barcode";

export default {
  components: {
    TopHeader,
    LeftHeader,
    ChangeLogComponent,
    VueBarcode,
  },
  data() {
    // console.log("ssss", this);
    // require('/compiled/puntofarma/app.scss');
    return {
      showScrollBtn: false,
      cssLogo: {
        // backgroundImage:
        //   "url('" +
        //   process.env.VUE_APP_BE +
        //   "storage/logos/" +
        //   process.env.VUE_APP_SCOPE +
        //   "/logo.png')",
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${require("@/assets/puntofarma-logo.svg")})`,
      },
      footerStyle: {
        backgroundImage: `url(${require("@/assets/footerbar.svg")})`,
        backgroundPosition: "bottom",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      },
      themeCustomOption: false,
      currentPfVersion: process.env.VUE_APP_PF_VERSION,
      termsConditionLinks: {
        paziente: "/assets/docs/TeC_PT.pdf",
        operatore: "/assets/docs/TeC_OS.pdf",
      },
      privacyPolicyLinks: {
        paziente: "/assets/docs/InformativaPrivacy_PT.pdf",
        operatore: "/assets/docs/InformativaPrivacy_OS.pdf",
      },
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
    }),
    ...mapActions("utente", ["me"]),
    ...mapActions("company", ["logUserCompanyIn"]),
    scrollUp() {
      window.scrollTo(0, 0);
    },
    showScrollUp() {
      var bottom = document.querySelector("#page__bottom");
      if (!bottom) {
        return false;
      }
      var bounding = bottom.getBoundingClientRect();
      if (
        bounding.bottom >
        (window.innerHeight || document.documentElement.clientHeight)
      ) {
        return true;
      }
      return false;
    },
    setCss() {
      //  var  ref=  `url(${require("@/assets/compiled/app.custom.css")})`
      var theme = this.themeCustomOption ? "custom" : "puntofarma";
      this.themeCustomOption = !this.themeCustomOption;
      var link = document.createElement("link");
      link.rel = "stylesheet";
      link.id = "customstyle";
      link.type = "text/css";
      // link.href = `url(${require("@/assets/compiled/app.custom.css")})`;
      link.href = "/compiled/app." + theme + ".css";
      document.head.appendChild(link);
      // console.log(document.head);
    },
    setCssPuntofarma() {
      var scriptel = document.getElementById("customstyle");
      try {
        scriptel.remove();
      } catch (err) {
        // console.log(err)
        return;
      }
      this.setCss();
    },
  },
  watch: {
    $route(to, from) {
      // clear alert on location change
      this.clearAlert();
      this.showScrollBtn = this.showScrollUp();
      // console.log(to);
    },
  },
  created() {
    // this.setCss();

    if (this.user) {
      this.me();
      if (this.user.type === 1) {
        this.logUserCompanyIn();
      }
    }
    const mainPages = [
      "/cittadino/home",
      "/operatore/home",
      "/admin/home",
      "/impostazioni",
      "/profilo",
      "/orari",
    ];
    // console.log(this.$route);
    if (!mainPages.includes(this.$route.path)) {
      this.$root.menuLeft = true;
    }
  },
  mounted() {},
  updated() {
    // console.log("APP HAS UPDATED", this.$root.isMobile);
    this.showScrollBtn = this.showScrollUp();
  },
};
</script>
