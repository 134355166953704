<template>
  <b-container fluid class="m-0 p-0">
    <b-row
      cols="1"
      cols-md="2"
      class="justify-content-bewteen pb-2"
      align-v="center"
    >
      <b-col class="align-self-end px-0">
        <h4 class="text-left py-1 mb-0">Esenzioni</h4>
      </b-col>
      <b-col class="">
        <p
          class="d-flex justify-content-center my-3 my-md-0 justify-content-md-end"
        >
          <b-button class="text-left" @click="$bvModal.show('new-exemption')">
            <b-icon icon="plus"></b-icon> nuova esenzione
          </b-button>
        </p>
      </b-col>
    </b-row>
    <div class="my-3">
      <b-row
        align-v="stretch"
        cols="1"
        cols-sm="2"
        cols-lg="3"
        class="my-2 ml-n2 col-12 px-0 align-items-center align-content-center"
      >
        <b-col
          class="px-2 py-2"
          v-for="(esenzione, ind) in userExemptions"
          :key="ind"
        >
          <div
            class="d-flex text-left pl-2 py-3 col-12 puntofarmaCard general_border flex-column h-100"
          >
            <b-row class="">
              <b-col class="col-2 col-lg-2">
                <h4 class="text-center text-capitalize pt-3">
                  {{ esenzione.code }}
                </h4>
              </b-col>
              <b-col class="col">
                <h5 class="mb-0 pt-3 text-uppercase long__txt point">
                  {{ esenzione.description }}
                </h5>
              </b-col>
            </b-row>
            <p class="mb-0 mt-auto d-flex justify-items-between">
              <span class="ml-auto point" @click="confirmDelete(esenzione)">
                <b-icon icon="trash" font-scale="1.5" variant="danger"></b-icon>
              </span>
            </p>
          </div>
        </b-col>
      </b-row>
      <p
        v-if="userExemptions !== null && userExemptions.length === 0"
        class="text-left"
      >
        Nessuna esenzione inserita
      </p>
    </div>
    <b-modal
      size="xl"
      id="new-exemption"
      centered
      hide-footer
      title="Aggiungi esenzione"
    >
      <b-form @submit.prevent="onSubmit">
        <b-form-group label="Seleziona esenzione*">
          <b-form-select v-model="form.code">
            <b-form-select-option
              v-for="codice in exemptions"
              :value="codice.hashid"
              >{{ codice.code }} -
              {{ codice.description }}</b-form-select-option
            >
            <b-form-select-option disabled :value="null"
              >scegli</b-form-select-option
            >
          </b-form-select>
        </b-form-group>

        <div v-if="form.code">
          <div class="mx-auto px-2 pb-4 text-right">
            <p class="text-left pl-4 mt-3">*Campo obbligatorio</p>
            <b-button
              type="button"
              variant="info modal-footer-button mr-3"
              @click.prevent="$bvModal.hide('new-exemption')"
              >Annulla</b-button
            >
            <b-button
              type="submit"
              variant="outline-primary modal-footer-button"
              >Salva
            </b-button>
          </div>
        </div>
      </b-form>
    </b-modal>
    <b-modal
      id="delete-exemption"
      centered
      title="Confermi di voler eliminare l'esenzione?"
      ok-title="Sì, elimina"
      ok-variant="outline-danger"
      cancel-title="No, annulla"
      cancel-variant="info"
      footer-border-variant="white"
      @ok="doDelete"
      @cancel="$bvModal.hide('delete-exemption')"
    >
      <div class="p-3 text-center" v-if="this.form.code">
        <h5>
          {{ this.form.code.code }} -
          <span class="text-uppercase">{{ this.form.code.description }}</span>
        </h5>
      </div>
    </b-modal>
  </b-container>
</template>
<script>
import { utilityService, supportService } from "@/_services"
import { mapState, mapActions } from "vuex"
export default {
  name: "EsenzioniComponent",
  props: ["subject"],
  data() {
    return {
      filters: {
        description: null,
        code: null
      },
      searchType: 0,
      searchOptions: [
        { value: 0, text: "per odice esenzione" },
        { value: 1, text: "per malattia" }
      ],
      exemptions: [],
      userExemptions: null,
      form: {
        code: null
      },
      loading: false,
      patientId: null
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["status", "user"])
  },
  watch: {},
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    onSubmit() {
      const self = this
      const userId = this.subject ? this.subject.hashid : undefined
      supportService
        .storeUserEsenzione(this.form, userId)
        .then(function (response) {
          self.$bvModal.hide("new-exemption")
          self.successAlert("Esenzione memorizzata con successo!")
          self.fetchUserExemptions()
          self.clearForm()
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile salvare l'esenzione")
          self.$bvModal.hide("new-exemption")
        })
    },
    doDelete() {
      const self = this
      const userId = this.subject ? this.subject.hashid : undefined
      this.form.code = this.form.code.hashid
      supportService
        .deleteUserEsenzione(this.form, userId)
        .then(function (response) {
          self.$bvModal.hide("delete-exemption")
          self.successAlert("Esenzione eliminata con successo!")
          self.fetchUserExemptions()
          self.clearForm()
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile eliminare l'esenzione")
          self.$bvModal.hide("delete-exemption")
        })
    },
    confirmDelete(esenzione) {
      this.form.code = esenzione
      this.$bvModal.show("delete-exemption")
    },
    clearForm() {
      this.form.code = null
    },
    getList() {
      const self = this
      utilityService
        .listaCodiciEsenzione(this.filters)
        .then(function (response) {
          self.exemptions = response.data.data
        })
        .catch(function (error) {
          self.errorAlert(
            "Non è stato possibile prelevare la lista di patologie"
          )
        })
    },
    fetchUserExemptions() {
      const self = this
      const userId = this.subject ? this.subject.hashid : undefined
      supportService
        .getUserEsenzioni(userId)
        .then(function (response) {
          self.userExemptions = response.data.data
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le esenzioni")
        })
    }
  },
  created() {
    this.fetchUserExemptions()
    this.getList()
  },
  mounted() {
    this.getList()
  }
}
</script>
<style>
.border__2 {
  border-width: 2px !important;
}
</style>
