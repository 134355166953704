<template>
  <div class="col-12 m-0 p-0">
    <template v-if="user.type !== 0">
      <template v-if="!hidetop">
        <b-row
          cols="1"
          cols-md="2"
          class="justify-content-bewteen pb-2"
          align-v="center"
        >
          <b-col class="align-self-end px-0">
            <h4 class="text-left py-1 mb-0">Note</h4>
          </b-col>
          <b-col class="">
            <!-- <p
              class="d-flex justify-content-center my-3 my-md-0 justify-content-md-end"
            >
              <b-button class="text-left" @click="$bvModal.show('new-soap')">
                <b-icon icon="plus"></b-icon> nuova nota sanitaria
              </b-button>
            </p> -->
          </b-col>
        </b-row>
        <b-row cols="1">
          <NewAnnotationComponent
            class="my-2"
            v-on:inserted-note="fetchAnnotations()"
            :subjectHash="subjectHash"
          />
          <b-modal
            size="xl"
            id="new-soap"
            title="Nuova nota sanitaria"
            hide-footer
          >
            <div class="p-2">
              <p class="mb-0">
                Inserisci una nota sanitaria nella scheda del paziente. Questa
                nota non sarà mostrata al paziente.
              </p>
              <NewSoapAnnotationComponent
                class="my-2 bg-extra-light general_border"
                v-on:inserted-note="fetchAnnotations()"
                @handle-closure="$bvModal.hide('new-soap')"
                :subjectHash="subjectHash"
              />
            </div>
          </b-modal>
        </b-row>
      </template>
      <b-row class="justify-content-end">
        <span class="mx-2">
          <label for="month_selector" class="mr-1">Mese</label>
          <select
            class="form-select bg-white border border-info general_border py-1"
            v-model="filters.month"
            @change="fetchAnnotations()"
            name="month_selector"
            id="month_selector"
          >
            <option
              v-for="month in historyFilterOptions.month"
              :value="month.value"
            >
              {{ month.label }}
            </option>
            <option :value="null">Tutti</option>
          </select>
        </span>
        <span>
          <label for="pickedYear" class="mr-1">Anno</label>
          <select
            class="form-select bg-white border border-info general_border"
            v-model="filters.year"
            @change="fetchAnnotations()"
            name="pickedYear"
            id="pickedYear"
          >
            <option
              v-for="year in yearsOptions"
              :value="year.value"
              :key="year.value"
            >
              {{ year.text }}
            </option>
          </select>
        </span>
      </b-row>
    </template>
    <div v-else class="d-flex justify-content-between mb-2 pb-2">
      <h4
        v-if="subjectHash && subjectHash !== user.hashid"
        class="page-lg-title"
      >
        Note
      </h4>
      <h4 v-else class="page-lg-title">Le tue note</h4>
      <b-row class="justify-content-end">
        <span class="mx-2">
          <label for="month_selector" class="mr-1">Mese</label>
          <select
            class="form-select bg-white border border-info general_border py-1"
            v-model="filters.month"
            @change="fetchAnnotations()"
            name="month_selector"
            id="month_selector"
          >
            <option
              v-for="month in historyFilterOptions.month"
              :value="month.value"
            >
              {{ month.label }}
            </option>
            <option :value="null">Tutti</option>
          </select>
        </span>
        <span>
          <label for="pickedYear" class="mr-1">Anno</label>
          <select
            class="form-select bg-white border border-info general_border"
            v-model="filters.year"
            @change="fetchAnnotations()"
            name="pickedYear"
            id="pickedYear"
          >
            <option
              v-for="year in yearsOptions"
              :value="year.value"
              :key="year.value"
            >
              {{ year.text }}
            </option>
          </select>
        </span>
      </b-row>
    </div>
    <template v-if="annotations">
      <template v-for="month in enabledMonths">
        <div
          :key="month"
          class="my-4 pb-1"
          v-if="
            filters.month == null ||
            (filters.month !== null && month == filters.month)
          "
        >
          <h4
            v-if="filters.month !== null"
            class="my-4 py-4 text-capitalize bg-primary general_border text-white text-center"
          >
            <span class="mx-2 p-1 point align_middle" @click="subtractMonth">
              <b-icon font-scale="1.3" icon="arrow-left"></b-icon>
            </span>
            <span>
              {{
                mappedMonths[month]
                  ? mappedMonths[month]
                  : mappedMonths[filters.month]
              }}
            </span>
            <span class="mx-2 p-1 point align_middle" @click="addMonth">
              <b-icon font-scale="1.3" icon="arrow-right"></b-icon>
            </span>
          </h4>
          <h4
            v-else
            class="my-4 py-4 text-capitalize bg-primary general_border text-white text-center"
          >
            {{ mappedMonths[month] }}
          </h4>
          <div
            class="negative__margin"
            v-if="annotations[month] && annotations[month].length > 0"
          >
            <template v-for="(singleAnnotation, ind) in annotations[month]">
              <SingleAnnotationComponent
                v-if="singleAnnotation"
                v-on:do-update="fetchAnnotations()"
                :key="ind"
                :annotation="singleAnnotation"
              />
            </template>
          </div>
          <p v-else class="text-center">Nessuna nota per questo mese.</p>
        </div>
      </template>
      <div
        class="my-4 pb-1"
        v-if="filters.month !== null && filterMonthDisabled"
      >
        <h4
          class="my-4 py-4 text-capitalize bg-primary general_border text-white text-center"
        >
          <span class="mx-2 p-1 point align_middle" @click="subtractMonth">
            <b-icon font-scale="1.3" icon="arrow-left"></b-icon>
          </span>
          <span>
            {{ mappedMonths[filters.month.toString().padStart(2, "0")] }}
          </span>
          <span class="mx-2 p-1 point align_middle" @click="addMonth">
            <b-icon font-scale="1.3" icon="arrow-right"></b-icon>
          </span>
        </h4>
        <p class="text-center">Nessuna nota per questo mese.</p>
      </div>
      <!-- </template> -->
    </template>
    <template v-else>
      <p class="text-center pt-4 mt-4">Nessuna nota con questi criteri.</p>
    </template>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { MONTHS } from "@/_utils/constants.js";
import { mapState, mapActions } from "vuex";
import { supportService } from "@/_services";
import SingleAnnotationComponent from "@/components/pazienti/SingleAnnotationComponent.vue";
import NewAnnotationComponent from "@/components/utilities/NewAnnotationComponent.vue";
import NewSoapAnnotationComponent from "@/components/utilities/NewSoapAnnotationComponent.vue";

export default {
  name: "PatientAnnotations",
  props: ["subjectHash", "hidetop"],
  components: {
    NewAnnotationComponent,
    SingleAnnotationComponent,
    NewSoapAnnotationComponent,
  },
  data() {
    return {
      startYear: 2022,
      currentYear: moment().year(),
      yearsOptions: [],
      annotations: null,
      // pickedYear: undefined,
      alreadyFetched: null,
      filters: {
        year: moment().year(),
        month: moment().month() + 1,
      },
      notes: null,
      mappedMonths: {
        "01": "gennaio",
        "02": "febbraio",
        "03": "marzo",
        "04": "aprile",
        "05": "maggio",
        "06": "giugno",
        "07": "luglio",
        "08": "agosto",
        "09": "settembre",
        10: "ottobre",
        11: "novembre",
        12: "dicembre",
      },
      enabledMonths: null,
      historyFilterOptions: {
        year: null,
        month: MONTHS,
      },
    };
  },
  computed: {
    filterMonthDisabled: function () {
      if (this.filters.month !== null) {
        const filterMonth = this.filters.month.toString().padStart(2, "0");
        console.log(this.enabledMonths.includes(filterMonth));
        return this.enabledMonths.includes(filterMonth) ? false : true;
      }
      return false;
    },
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["user"]),
    ...mapState("company", ["company"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchAnnotations() {
      this.setupMonths();
      var self = this;
      var user = this.subjectHash ? this.subjectHash : undefined;
      supportService
        .getUserAnnotations(this.filters, user)
        .then(function (response) {
          self.notes = response.data.data;
          self.annotations = response.data.data;
          // console.log(response.data.data)
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le note");
        });
    },
    addMonth() {
      if (this.filters.month === 12 && this.filters.year >= this.currentYear) {
        return;
      } else if (
        this.filters.month === 12 &&
        this.filters.year < this.currentYear
      ) {
        this.filters.month = 1;
        this.filters.year++;
      } else {
        this.filters.month++;
      }
      this.$nextTick(function () {
        this.fetchAnnotations();
      });
    },
    subtractMonth() {
      if (this.filters.month === 1 && this.filters.year <= this.startYear) {
        return;
      } else if (
        this.filters.month === 1 &&
        this.filters.year > this.startYear
      ) {
        this.filters.month = 12;
        this.filters.year--;
      } else {
        this.filters.month--;
      }
      this.$nextTick(function () {
        this.fetchAnnotations();
      });
    },
    setupMonths() {
      this.enabledMonths = null;
      let now = moment();
      // let this.currentYear = now.year();
      let enabledMonths = [];
      var indx = now.month() + 1;
      if (this.filters.year != this.currentYear) {
        now.year(this.currentYear);
        now.month(11);
        indx = 12;
      }
      for (let x = indx; x >= 1; x--) {
        enabledMonths.push(now.format("MM"));
        now.subtract(1, "months");
      }
      this.enabledMonths = enabledMonths;
    },
  },
  created() {
    // let now = moment();
    // this.currentYear = now.year();
    this.startYear = 2022;
    for (let i = this.currentYear; i >= this.startYear; i--) {
      let y = { value: `${i}`, text: `${i}` };
      this.yearsOptions.push(y);
    }
  },
  mounted() {
    this.fetchAnnotations();
  },
};
</script>
<style>
select[name="pickedYear"] {
  min-width: 70px;
  min-height: 33px;
  padding-left: 5px;
  padding-right: 5px;
}

.negative__margin {
  margin-top: -2.3rem;
}
</style>
