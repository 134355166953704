<template>
  <b-container fluid class="m-0 p-0">
    <!-- <div class="m-0 mb-2 p-0 pb-1 d-flex">
      <div class="m-0 p-0 mx-2 align-self-end text-center">
        <b-icon variant="primary" font-scale="1.3" icon="clock"></b-icon>
      </div>
      <div class="m-0 ml-2 p-0">
        <p class="m-0 p-0 font-size-085rem labels-detail-model">indicazione</p>
        <p class="m-0 p-0 text-capitalize">
          <span v-if="therapy.pathology">
            {{ therapy.pathology.name }} - patologia
            {{ therapy.pathology.tipo }}
          </span>
        </p>
      </div>
    </div> -->

    <!-- <h4 class="text-prim-grad-1 text-left pb-1">Indicazione</h4>

    <div class="my-2">
      <h6 v-if="therapy.pathology" class="my-4 pb-2">
        {{ therapy.pathology.name }} - patologia {{ therapy.pathology.tipo }}
      </h6>
      <p v-else class="my-4 pb-2">Patologia non indicata</p>
    </div> -->
    <h5 class="text-prim-grad-1 text-left pb-1">Posologia</h5>

    <div class="my-2">
      <p class="my-4 pb-2">
        {{ posNum }}
        {{
          posNum > 1 ? therapy.posology_type : typesMap[therapy.posology_type]
        }}
        di {{ therapy.product_name }} {{ therapy.posologia.tipo }}
        <span v-if="therapy.posologia.frequenza">
          {{ therapy.posology_every_num }} {{ therapy.posologia.frequenza }}
        </span>
        per via
        <span class="text-lowercase">{{ therapy.posology_via }}.</span>
      </p>
    </div>
    <h5 class="text-prim-grad-1 text-left pb-1">Durata</h5>

    <div class="my-2">
      <p v-if="therapy.type === 2" class="my-4 pb-2">
        Da {{ formatData(therapy.start_at) }} fino a
        {{ formatData(therapy.end_at) }} .
      </p>
      <p v-else-if="therapy.type === 1" class="my-4 pb-2">
        La terapia va seguita ogni {{ therapy.every_num }}
        {{
          therapy.every_num > 1
            ? forEveryUmsPlur[therapy.every_um]
            : forEveryUmsSing[therapy.every_um]
        }}
        per
        {{ therapy.for_num }}
        {{
          therapy.for_num > 1
            ? forEveryUmsPlur[therapy.for_um]
            : forEveryUmsSing[therapy.for_um]
        }}
        a partire da {{ formatData(therapy.start_at) }}.
      </p>
      <p v-else class="my-4 pb-2">cronica</p>
    </div>

    <h5 class="text-prim-grad-1 text-left pb-1">Prescrittore</h5>
    <p class="mt-4 pb-2">
      {{ therapy.prescrittore ? therapy.prescrittore : "non indicato" }}
    </p>

    <h5 class="text-prim-grad-1 text-left pb-1">Effetti Collaterali</h5>
    <p class="mt-4 pb-2">
      {{
        therapy.effetti_collaterali
          ? therapy.effetti_collaterali
          : "non indicati"
      }}
    </p>

    <h5 class="text-prim-grad-1 text-left pb-1">Reazioni Allergiche</h5>
    <p class="mt-4 pb-2">
      {{
        therapy.reazione_allergica ? therapy.reazione_allergica : "non indicate"
      }}
    </p>

    <h5 class="text-prim-grad-1 text-left pb-1">Interazioni</h5>
    <p class="mt-4 pb-2">
      {{ therapy.interazioni ? therapy.interazioni : "non indicate" }}
    </p>

    <h5 class="text-prim-grad-1 text-left pb-1">Annotazioni</h5>

    <div class="pb-2">
      <p class="my-4 pb-2">
        {{ therapy.details ? therapy.details : "nessuna nota" }}
      </p>
    </div>
  </b-container>
</template>
<script>
import * as moment from "moment/moment";
export default {
  name: "RecapTherapyModal",
  props: ["therapy"],
  data() {
    return {
      typesMap: {
        Compresse: "compressa",
        Capsule: "capsula",
        Gocce: "goccia",
        Inalazioni: "inalazione",
        Buste: "busta",
        Supposte: "supposta",
        Scatole: "scatola",
        Cerotti: "cerotto",
        Fiale: "fiala",
        Fleboclisi: "fleboclisi",
        Ovuli: "ovulo",
        Sacche: "sacca",
        Flaconi: "flacone",
        Confezioni: "confezione",
        Kilogrammi: "kilogrammo",
        Kit: "kit",
        Millilitri: "millilitro",
        Milligrammi: "milligrammo",
      },
      forEveryUmsSing: {
        0: "mese",
        1: "settimana",
        2: "giorno",
      },
      forEveryUmsPlur: {
        0: "mesi",
        1: "settimane",
        2: "giorni",
      },
    };
  },
  computed: {
    posNum: function () {
      return this.therapy.posology_num * 1;
    },
  },
  methods: {
    formatData(data) {
      return moment(data).format("dddd DD MMMM YYYY");
    },
    formatDataShort(data) {
      return moment(data).format("DD/MM/YYYY");
    },
  },
  created() {
    //
  },};</script>