<template>
  <transition-group name="slide">
    <div
      class="d-flex justify-content-between align-items-center"
      key="annullate"
    >
      <div class="col-3 text-left">
        <strong>Annullate: {{ attivita.annullate }}</strong>
      </div>
      <div class="col-9">
        <b-progress
          :max="attivita.tot"
          :value="attivita.annullate"
          variant="font-light-grey"
          show-value
        ></b-progress>
      </div>
    </div>
    <div
      class="d-flex justify-content-between align-items-center"
      key="incorso"
    >
      <div class="col-3 text-left">
        <strong>In Corso: {{ attivita.in_corso }}</strong>
      </div>
      <div class="col-9">
        <b-progress
          :max="attivita.tot"
          :value="attivita.in_corso"
          variant="prim-grad-1"
          show-value
        ></b-progress>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center" key="evase">
      <div class="col-3 text-left">
        <strong>Evase: {{ attivita.evase }}</strong>
      </div>
      <div class="col-9">
        <b-progress
          :max="attivita.tot"
          :value="attivita.evase"
          variant="success"
          show-value
        ></b-progress>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center" key="totali">
      <div class="col-3 text-left">
        <strong>Totale: {{ attivita.tot }}</strong>
      </div>
      <div class="col-9">
        <b-progress
          :max="attivita.tot"
          :value="attivita.tot"
          variant="prim-grad-2"
          show-value
        ></b-progress>
      </div>
    </div>
  </transition-group>
</template>
<script>
export default {
  name: "ProgressAttivita",
  props: ["valori"],
  data() {
    return {
      attivita: {
        tot: 0,
        annullate: 0,
        evase: 0,
        in_corso: 0,
      },
    };
  },
  mounted() {
    if (this.valori) {
      this.attivita.tot = this.valori.tot;
      this.attivita.annullate = this.valori.annullate;
      this.attivita.evase = this.valori.evase;
      this.attivita.in_corso = this.valori.in_corso;
    }
  },
};
</script>