<template>
  <div class="px-md-4">
    <h4 class="text-prim-grad-1 text-left pb-2 mb-2">Modifica Questionario</h4>

    <div v-if="!showOk" :class="$root.isMobile ? 'col-12' : 'col-10'" class="px-0">
      <EditQuestionario v-if="survey" :prevSurvey="survey" v-on:send-questionario="storeNew($event)" />
    </div>
    <div v-else>
      <h2 class="">Operazione completata.</h2>
    </div>
  </div>
</template>
<script>
import { supportService } from "@/_services";
import { mapState, mapActions } from "vuex";
import EditQuestionario from "@/components/attivita/EditQuestionario.vue";
export default {
  name: "AggiornaQuestionario",
  components: { EditQuestionario },
  data() {
    return {
      survey: null,
      showOk: false,
      // noActivities: false,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchSurvey() {
      var self = this;
      supportService
        .getSurveyById(this.$route.params.quest)
        .then(function (res) {
          self.survey = res.data.data;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare il questionario");
          // self.noActivities = true;
          // console.log(err);
        });
    },
    storeNew(data) {
      // console.log("DATA", data);
      var dataToSend = { hash: this.survey.hashid, form: data };
      var self = this;
      supportService
        .editQuestionario(dataToSend)
        .then(function (res) {
          // console.log(res);
          window.scrollTo(0, 0);
          self.successAlert("Questionario modificato correttamente!");
          self.showOk = true;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile creare il nuovo questionario");
          // console.log(err);
        });
    },
  },
  created() {
    if (this.$route.params.quest) {
      this.fetchSurvey();
    }
  },
};
</script>