<template>
  <div class="py-2 puntofarmaCard h-100 p-xl-4">
    <h5 class="pl-4 text-left text-capitalize">{{ title }}</h5>
    <div style="position: relative; max-height: 90%">
      <canvas :id="idappend"></canvas>
    </div>
    <div class="text-left px-4" v-if="datatoshow">
      <b-row v-for="(val, ind) in datatoshow" :key="val + 'p-' + ind">
        <b-col cols="9">
          <b-icon icon="circle" :variant="variants[ind]"></b-icon>
          <span class="ml-2">{{ chartData.data.labels[ind] }}</span>
        </b-col>
        <b-col class="text-right"> {{ val }}</b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { Chart, registerables } from "chart.js";
import "chartjs-adapter-moment"; // or another adapter to avoid moment
Chart.register(...registerables);
export default {
  name: "DoughnutChart",
  props: ["idappend", "datatoshow", "title"],
  data() {
    return {
      chartData: {
        type: "doughnut",
        data: {
          labels: ["Annullati", "Effettuati", "Da confermare"],
          datasets: [
            {
              label: "My First Dataset",
              data: this.datatoshow,
              backgroundColor: [
                "rgb(237, 94, 95)",
                "#145c8c",
                // "rgb(116, 103, 240)",
                "rgb(255, 165, 80)",
              ],
              hoverOffset: 4,
            },
          ],
        },
        options: {
          maintainAspectRatio: true,
        },
      },
      variants: ["dark-red", "dark-purple", "dark-orange"],
    };
  },
  created() { },
  mounted() {
    // console.log("here");
    // console.log(this.datatoshow);
    const ctx = document.getElementById(this.idappend);
    new Chart(ctx, this.chartData);
  },
};
</script>