<template>
  <div v-if="user.type === 1">
    <template v-if="isActive === null">
      <p class="text-center"><b-spinner></b-spinner></p>
    </template>
    <template v-else-if="isActive === true">
      <transition-group name="list" mode="out-in" appear>
        <div v-if="$route.params.section === 'lista'" key="lista">
          <StoricoAppuntamentiAsl />
        </div>
        <div v-else-if="$route.params.section === 'nuovo'" key="nuovo">
          <NuovoAppuntamentoAsl />
        </div>
        <div
          v-else-if="$route.params.section === 'edit-questionario'"
          key="modifica"
        >
          <ModificaQuestionarioCompilatoASL />
        </div>
      </transition-group>
    </template>
    <template v-else>
      <h4>Servizio appuntamenti in ASL non attivo</h4>
      <h6>
        La ASL o ASP di tua competenza non ha attivato il servizio.
        <a href="https://puntofarma.online/contacts/"> <u> Contattaci</u></a>
        per saperne di più
      </h6>
    </template>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex"
import { companyService } from "@/_services"
import NuovoAppuntamentoAsl from "@/components/appuntamenti/sections/NuovoAppuntamentoAsl.vue"
import StoricoAppuntamentiAsl from "@/components/appuntamenti/sections/StoricoAppuntamentiAsl.vue"
import ModificaQuestionarioCompilatoASL from "@/components/attivita/sections/ModificaQuestionarioCompilatoASL"

export default {
  name: "AppuntamentiMain",
  components: {
    NuovoAppuntamentoAsl,
    StoricoAppuntamentiAsl,
    ModificaQuestionarioCompilatoASL
  },
  computed: {
    ...mapState("utente", ["status", "user"])
  },
  data() {
    return {
      isActive: null
    }
  },
  mounted() {
    this.verifyService()
    var lib = document.createElement("script")
    lib.setAttribute("id", "ls_lib")
    lib.setAttribute("type", "text/javascript")
    lib.setAttribute(
      "src",
      "https://cdn.jsdelivr.net/npm/fm.liveswitch@latest/fm.liveswitch.js"
    )
    document.head.appendChild(lib)
  },
  beforeDestroy() {
    var lib = document.getElementById("ls_lib")
    document.head.removeChild(lib)
  },
  methods: {
    verifyService() {
      const self = this
      const filter = { ref: 5 }
      companyService
        .checkIfServiceIsActive(filter)
        .then(function (response) {
          self.isActive = response.data.data.active
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>
