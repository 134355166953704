<template>
  <b-row
    v-if="azienda"
    class="general_border_17 general_hover shadow_2 min_h_15 pt-4 d-flex"
    :class="{ 'bg-whitesmoke': !azienda.profile_pic }"
    :style="companyPicStyle"
  >
  <!-- <b-col class="col-12" style="height: 5vh;"></b-col> -->
    <b-col
      class="col-12 px-4 py-2 mb-0 mt-auto min_h_10 d-flex flex-column"
      :class="isSelected ? 'bg-secondary text-white' : 'opacity_bg'"
    >
      <h5 @click="selectThis()" class="mb-0">{{ azienda.denominazione }}</h5>
      <p
        @click="selectThis()"
        class="text-capitalize text-center mb-1 mb-md-2 mb-xl-3 mt-auto"
      >
        {{ azienda.indirizzo }}, {{ azienda.frazione }} {{ azienda.comune }}
      </p>
      <p class="mb-0 mt-auto">
        <!-- <span class="point" v-if="showHeart" @click="$emit('toggle-monitor', azienda)"> -->
        <span>
          <b-icon
            font-scale="1.5"
            :icon="azienda.is_monitor
                ? 'star-fill'
                : 'star'
            "
            :variant="isSelected ? 'white' : 'secondary'"
          ></b-icon>
        </span>
      </p>
    </b-col>
  </b-row>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "CompanyMonitorCard",
  props: ["azienda", "isSelected", "showHeart"],
  computed: {
    ...mapState("utente", ["status", "user"]),
    companyPicStyle() {
      if (this.azienda.profile_pic) {
        return "background-image: url(" + this.azienda.profile_pic + ")";
      } else {
        return {
          backgroundImage: `url(${require("@/assets/no_avatar.svg")}`,
          backgroundPosition: "center center",
          backgroundSize: "contain",
        };
      }
    },
  },
  data() {
    return {};
  },
  methods: {
    selectThis() {
      // this.$emit("company-selection", this.azienda);
    },
  },
};
</script>
<style lang="scss" scoped>
.monitors_card {
  width: 100%;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  min-height: 10vh;
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
}

.min_h_15 {
  height: 100%;
  width: 100%;
  min-height: 17vh;
}

.min_h_10 {
  height: 100%;
  width: 100%;
  min-height: 10vh;
}

.opacity_bg {
  background-color: #ffffffa8;
}
</style>
