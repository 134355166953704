<template>
  <div class="w-100 d-flex align-items-center">
    <div class="arrow" v-show="showPrevArrow" @click="goToPrev">
      <b-icon icon="chevron-left" font-scale="2"></b-icon>
    </div>
    <div style="overflow: clip" class="h-100 w-100">
      <b-row
        class="d-flex carousel flex-grow-1 py-2 flex-nowrap w-100"
        :style="{ transform: `translateX(-${offset}%)` }"
        align-v="stretch"
      >
        <b-col
          v-for="(message, index) in messages"
          :key="index"
          class="carousel-tile py-3"
          :class="colClass"
        >
          <div
            class="mr-3 p-3 d-flex flex-column align-items-center general_border h-100 shadow_2 general_hover point"
            @click="pickMessage(message)"
            :class="
              pickedMessage === message
                ? 'bg-secondary text-white'
                : 'bg-medium-light-grey'
            "
          >
            {{ message }}
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="arrow" v-show="showNextArrow" @click="goToNext">
      <b-icon icon="chevron-right" font-scale="2"></b-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "PickMessageCarouselComponent",
  props: {
    messages: {
      type: Object,
      required: true
    },
    tilesPerPage: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      offset: 0,
      pickedMessage: null
    }
  },
  computed: {
    messagesLength() {
      return Object.keys(this.messages).length
    },
    showPrevArrow() {
      return this.offset > 0
    },
    showNextArrow() {
      return (
        this.offset <
        100 * (Math.ceil(this.messagesLength / this.tilesPerPage) - 1)
      )
    },
    colClass() {
      if (this.tilesPerPage === 1) {
        return "col-12"
      } else if (this.tilesPerPage === 2) {
        return "col-6"
      } else if (this.tilesPerPage === 3) {
        return "col-4"
      } else if (this.tilesPerPage === 4) {
        return "col-3"
      }
      return "col-12"
    }
  },
  methods: {
    goToPrev() {
      this.offset = Math.max(0, this.offset - 100)
    },
    goToNext() {
      this.offset = Math.min(
        100 * (Math.ceil(this.messagesLength / this.tilesPerPage) - 1),
        this.offset + 100
      )
    },
    pickMessage(message) {
      this.pickedMessage = message
      this.$emit("picked-message", message)
    },
    reset() {
      this.pickedMessage = null
    }
  }
}
</script>

<style scoped>
.carousel {
  display: flex;
  flex-grow: 1;
  transition: all 1s;
  font-size: 0.8rem;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
}
</style>
