<template>
  <div
    class="bg-white p-3 general_border_17 shadow_2 px-1 boxes_max_heigh_60 h-100"
  >
    <h3 class="text-left py-2">Prossimi appuntamenti</h3>

    <b-overlay :show="loading">
      <div class="hide_overflow_y boxes_max_heigh_49 pr-3 pl-1 pb-3" v-if="list">
        <b-row
          class="mx-4 py-2 justify-content-between shadow_8 bg-mdm-light-grey general_border_17 p-3 mr-0 mr-md-4 mr-xl-0 "
          v-for="impegno in list"
          :key="impegno.hashid"
          align-v="center"
        >
          <b-col class="col-2">
            <img
              :src="
                require(`@/assets/dashboard-operatore/${getImageName(
                  impegno.tipo_ordine
                )}.svg`)
              "
              class="icon"
            />
          </b-col>
          <b-col>
            <div class="font-weight-bolder">
                <p class="text-center text-uppercase">
                  {{ getTitoloCard(impegno) }}
                </p>
                <p class="mb-0">
                  {{ getData(impegno.data_ordine) }}
                </p>
              </div>
          </b-col>
          <b-col  class="col-2">
            <b-iconstack
                @click="navigateToCalendar(impegno)"
                font-scale="3"
                class="point"
              >
                <b-icon
                  stacked
                  icon="circle-fill"
                  variant="sec-2-lighter"
                ></b-icon>
                <b-icon
                  stacked
                  icon="reply-fill"
                  scale="0.8"
                  shift-v="1"
                  shift-h="1"
                  variant="white"
                ></b-icon>
                <b-icon stacked icon="circle" variant="sec-2-lighter"></b-icon>
              </b-iconstack>
          </b-col>
        </b-row>
        <b-row class="p-2 text-left" v-if="list.length === 0">
          Nessun impegno per questo giorno.
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import * as moment from "moment/moment";
import { SERVIZI_VARIANTS } from "@/_utils/constants.js";
import { mapState, mapActions } from "vuex";
import { croomService } from "@/_services";
export default {
  name: "ProssimiImpegni",
  props: ["seldate"],
  data() {
    return {
      list: null,
      loading: false,
      filters: {
        date: null,
      },
      icons: {},
      SERVIZI_VARIANTS: SERVIZI_VARIANTS,
      pClasses:
        "values-card-paragraph py-1 px-4 px-md-3 px-lg-4 general_border",
      headerParagraphStyles:
        "d-flex align-items-center justify-content-md-center justify-content-lg-start my-0 pt-4 pb-2 px-4 px-md-3 px-lg-4 general_border",
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  watch: {
    seldate: function (value) {
      console.log("seldate", value);
      this.filters.date = value;
      this.fetchData();
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      this.loading = true;
      const self = this;
      croomService
        .getOperatorReservations(this.filters)
        .then(function (response) {
          console.log(response);
          self.list = response.data.data;
          self.loading = false;
        })
        .catch(function (error) {
          self.errorAlert(
            "Non è stato possibile ottenere la lista degli impegni"
          );
          console.log(error);
          self.loading = false;
        });
    },
    getData(data) {
      return (
        moment(data).format("D MMMM") + " alle " + moment(data).format("HH:MM")
      );
    },
    getTitoloCard(ordine) {
      switch (ordine.tipo_ordine) {
        case "teleconsulto":
          return "Teleconsulto";
        case "prestazione":
          return ordine?.orderable?.prestazione?.name;
        case "prodotto":
          return "Ordine";
      }
    },
    getImageName(tipo) {
      switch (tipo) {
        case "teleconsulto":
          return `dashboardTeleconsulto`;
        case "prestazione":
          return `dashboardPrestazioni`;
        case "prodotto":
          return `dashboardOrdini`;
      }
    },
    navigateToCalendar(prenotazione) {
      const navInfo = {
        service: prenotazione.tipo_ordine,
        search: prenotazione.codice_richiesta,
        status: "tutte",
      };
      this.$emit("navigate", navInfo);
    },
  },
  created() {
    if (this.seldate) {
      this.filters.date = this.seldate;
    }
    this.fetchData();
  },
};
</script>
<style lang="scss" scoped>
#impegni-row {
  position: relative;
  overflow-y: scroll;
  max-height: 100%;
}

.impegno-card {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #f9f8f8 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px #00000022;
}

.impegno-card:hover {
  background: #f9f8f8 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
}

#values-card {
  color: white;
  letter-spacing: 0.5px;
  font-weight: 600;
}

.values-card-paragraph {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
  padding: 0.2rem 0;
}

.values-card-paragraph:hover {
  cursor: pointer;
  transition: all 0.35s ease-in-out;
}

.hover-values-card-prodotti-paragraph:hover {
  background-color: rgba($orange-light, 0.3);
}

.hover-values-card-prestazioni-paragraph:hover {
  background-color: rgba($green-light, 0.3);
}

.hover-values-card-teleconsulti-paragraph:hover {
  background-color: rgba($purple-light, 0.25);
}

#header-text {
  font-size: 1.3rem;
}

@media (max-width: 420px) {
  .icon {
    width: 2.2rem;
    height: 2.2rem;
  }

  #header-text {
    font-size: 1.3rem;
  }
}

@media (min-width: 421px) and (max-width: 768px) {
  .icon {
    width: 2.5rem;
    height: 2.5rem;
  }

  #header-text {
    font-size: 1.5rem;
  }
}

@media (min-width: 768px) and (max-width: 979px) {
  .icon {
    width: 2.2rem;
    height: 2.2rem;
  }

  #header-text {
    font-size: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 850px) {
  .icon {
    width: 1.8rem;
    height: 1.8rem;
  }
}

@media (min-width: 980px) and (max-width: 1099px) {
  .icon {
    width: 2.4rem;
    height: 2.4rem;
  }

  #header-text {
    font-size: 1.3rem;
  }
}

@media (min-width: 1100px) and (max-width: 1180px) {
  .icon {
    width: 2.5rem;
    height: 2.5rem;
  }

  #header-text {
    font-size: 1.4rem;
  }
}

@media (min-width: 1181px) and (max-width: 1259px) {
  .icon {
    width: 2.8rem;
    height: 2.8rem;
  }

  #header-text {
    font-size: 1.8rem;
  }
}

@media (min-width: 1260px) {
  .icon {
    width: 3rem;
    height: 3rem;
  }
}

@media (max-width: 1799px) {
  #impegni-row {
    max-height: 30vh;
    position: relative;
  }
}

@media (min-width: 1800px) {
  #impegni-row {
    max-height: 100%;
    position: absolute;
  }
}
</style>
