<template>
  <div>
    <p class="text-dark text-left pl-3">Chat</p>
    <b-row>
      <b-col cols="12" class="text-left" id="chatbox">
        <p v-for="(mess, ind) in conversationArray" :key="ind">
          <strong class="mr-3 text-uppercase">{{ mess.sender }}:</strong>
          <span class="text-lowercase">{{ mess.msg }}</span>
        </p>
      </b-col>
      <b-col cols="12">
        <b-form @submit.prevent="sendMessage">
          <b-row align-v="center">
            <b-col cols="10">
              <b-form-input type="text" v-model="textMessage"></b-form-input>
            </b-col>
            <b-col cols="2">
              <b-button type="button" variant="primary" size="sm" @click="sendMessage">
                <b-icon icon="forward-fill"></b-icon>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
      <!-- frames record in videocall -->
      <!-- <b-col cols="12" class="mt-1" v-if="recordingStatus === 0">
        <span class="btn btn-danger" v-if="recording"
          ><span class="mr-2">Registrazione... {{ counterTimer }}</span>
          <b-icon icon="record-circle-fill" animation="fade"></b-icon
        ></span>
        <b-button type="button" @click="startRecord" v-else
          ><span class="mr-2">Avvia registrazione</span
          ><b-icon icon="record-circle-fill"></b-icon
        ></b-button>
        <b-button type="button" variant="danger" @click="stopRecord"
          >stop record</b-button
        >
      </b-col>
      <b-col cols="10" class="mt-1 mx-auto text-left" v-else>
        <p>
          Registrazione conclusa...
          <b-icon
            v-if="recordingStatus >= 1"
            icon="check"
            variant="success"
            font-scale="1.3"
          ></b-icon>
          <b-icon
            v-else
            icon="circle"
            variant="warning"
            animation="fade"
            font-scale="1.3"
          ></b-icon>
        </p>
        <p>
          Invio file al server...
          <b-icon
            v-if="recordingStatus >= 2"
            icon="check"
            variant="success"
            font-scale="1.3"
          ></b-icon>
          <b-icon
            v-else-if="recordingStatus === 1"
            icon="circle"
            variant="warning"
            animation="fade"
            font-scale="1.3"
          ></b-icon>
        </p>
        <p>
          Elaborazione in corso...
          <b-icon
            v-if="recordingStatus >= 3"
            icon="check"
            variant="success"
            font-scale="1.3"
          ></b-icon>
          <b-icon
            v-else-if="recordingStatus === 2"
            icon="circle"
            variant="warning"
            animation="fade"
            font-scale="1.3"
          ></b-icon>
        </p>
        <p>
          Elaborazione completata...
          <b-icon
            v-if="recordingStatus >= 4"
            icon="check"
            variant="success"
            font-scale="1.3"
          ></b-icon>
          <b-icon
            v-else-if="recordingStatus === 3"
            icon="circle"
            variant="warning"
            animation="fade"
            font-scale="1.3"
          ></b-icon>
        </p>
      </b-col> -->
      <b-col cols="12" class="mt-1" v-if="recordingStatus === 2"> invio</b-col>
      <b-col cols="12" id="clipss" class="text-left ml-3"> </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "MessagingComponent",
  props: ["tcchannel"],
  data() {
    return {
      loading: true,
      fm: null,
      client: null,
      localMedia: null,
      remoteMedia: null,
      videochiamata: null,
      clientName: null,
      token: null,
      isOn: true,
      connection: null,
      chatConnection: null,
      unavailable: false,
      notReady: true,
      layoutManager: null,
      isClosed: false,
      isMute: false,
      videoMute: false,
      textMessage: null,
      conversationArray: [],
      mediaRecorder: null,
      chunks: [],
      recording: false,
      recordingStatus: 0,
      counterIntervalTimer: null,
      counterTimer: 30,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["user"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    sendMessage() {
      if (this.channel) {
        this.channel.sendMessage(this.textMessage);
        this.textMessage = null;
      }
    },
    setRecordListeners() {
      // console.log("setting listeners");
      var self = this;
      setTimeout(function () {
        self.stopRecord();
      }, 31000);

      this.counterIntervalTimer = setInterval(function () {
        self.handlerCounter();
      }, 1000);
      this.mediaRecorder.ondataavailable = function (e) {
        // console.log("getting data");
        self.chunks.push(e.data);
      };
      this.mediaRecorder.onstop = function (e) {
        // console.log("recorder stopped from listener");

        self.recordingStatus = 1;
        const clipName = prompt("Enter a name for your sound clip");

        const clipContainer = document.createElement("article");
        const clipLabel = document.createElement("p");
        const audio = document.createElement("video");
        // const audio = document.createElement("audio");
        const deleteButton = document.createElement("button");
        const soundClips = document.getElementById("clipss");

        clipContainer.classList.add("clip");
        audio.setAttribute("controls", "");
        deleteButton.innerHTML = "Elimina";
        clipLabel.innerHTML = clipName;

        clipContainer.appendChild(audio);
        clipContainer.appendChild(clipLabel);
        clipContainer.appendChild(deleteButton);
        soundClips.appendChild(clipContainer);

        const blob = new Blob(self.chunks, { type: "video/mp4;" });
        self.chunks = [];
        const audioURL = window.URL.createObjectURL(blob);
        var link = document.createElement("a");
        link.href = audioURL;
        link.download = clipName + ".mp4";
        link.click();
        audio.src = audioURL;

        deleteButton.onclick = function (e) {
          let evtTgt = e.target;
          evtTgt.parentNode.parentNode.removeChild(evtTgt.parentNode);
        };
      };
    },
    stopRecord() {
      // console.log(this.mediaRecorder);
      this.mediaRecorder.stop();
      // console.log(this.mediaRecorder.state);
      // console.log("recorder stopped");
      this.recording = false;
    },
    startRecord() {
      if (this.recording) {
        return;
      }
      var constraints = (window.constraints = {
        audio: true,
        video: true,
      });
      var self = this;
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function (stream) {
          // console.log(stream);
          var videoTracks = stream.getVideoTracks();
          var audioTracks = stream.getAudioTracks();
          console.log("Got stream with constraints:", constraints);
          console.log("Using audio device: " + audioTracks);
          // console.log("Using video device: " + videoTracks[0].label);
          const mediaRecorder = new MediaRecorder(stream);
          self.mediaRecorder = mediaRecorder;
          self.setRecordListeners();
          mediaRecorder.start();
          console.log(mediaRecorder.state);
          console.log("recorder started");

          self.recording = true;
          // self.recordingStatus = 1;
          mediaRecorder.ondataavailable = function (e) {
            console.log("getting data in second listener");
            self.chunks.push(e.data);
          };
        })
        .catch(function (error) {
          if (error.name === "ConstraintNotSatisfiedError") {
            console.log(
              "The resolution " +
              constraints.video.width.exact +
              "x" +
              constraints.video.height.exact +
              " px is not supported by your device."
            );
          } else if (error.name === "PermissionDeniedError") {
            console.log(
              "Permissions have not been granted to use your camera and " +
              "microphone, you need to allow the page access to your devices in " +
              "order for the demo to work."
            );
          }
          console.log("getUserMedia error: " + error.name, error);
        });
    },
    handlerCounter() {
      if (this.counterTimer === 0) {
        clearInterval(this.counterIntervalTimer);
      } else {
        this.counterTimer--;
      }
    },
    start() {
      var fm = window.fm;
      var gateway = process.env.VUE_APP_LIVEWITCH_GATEWAY;
      var secret = process.env.VUE_APP_LIVEWITCH_SECRET;
      var applicationId = process.env.VUE_APP_LIVEWITCH_APP_ID;
      var clientId = this.clientName;
      var channelId = this.tcchannel;
      var client = null;
      var token = null;
      var userId = fm.liveswitch.Guid.newGuid().toString().replace(/-/g, "");
      var deviceId = this.clientName;
      var localMedia = null;
      client = new fm.liveswitch.Client(
        gateway,
        applicationId,
        userId,
        deviceId
      );
      this.client = client;
      token = fm.liveswitch.Token.generateClientRegisterToken(
        applicationId,
        client.getUserId(),
        client.getDeviceId(),
        client.getId(),
        null,
        [new fm.liveswitch.ChannelClaim(channelId)],
        secret
      );
      var self = this;
      client
        .register(token)
        .then(function (channels) {
          var joinToken = fm.liveswitch.Token.generateClientJoinToken(
            applicationId,
            client.getUserId(),
            client.getDeviceId(),
            client.getId(),
            new fm.liveswitch.ChannelClaim(channelId),
            secret
          );

          // self.client
          client
            .join(channelId, joinToken)
            .then(function (channel) {
              self.channel = channel;
              var myself = self;
              channel.addOnMessage((senderClientInfo, message) => {
                // console.log("I'm ", senderClientInfo._deviceId);
                // console.log("New message received");
                // console.log(message);
                var newElem = {
                  msg: message,
                  sender: senderClientInfo._deviceId,
                };
                myself.conversationArray.push(newElem);
                console.log(myself.conversationArray);

                var chatbox = document.getElementById("chatbox");
                console.log(chatbox.scrollHeight);
                chatbox.scrollTop = chatbox.scrollHeight + 50;
              });
              channel.sendMessage("...connesso.");
            })
            .fail(function (ex) {
              console.log("failed to join channel", ex);
              self.errorAlert("Non è stato possibile collegarsi");
            });
        })
        .fail(function (ex) {
          console.log("registration failed", ex);
          self.errorAlert("Non è stato possibile collegarsi");
        });
    },
    stopCall() {
      var self = this;
      if (this.connection) {
        this.connection
          .close()
          .then(function (result) {
            console.log("connection closed");
          })
          .fail(function (ex) {
            console.log("an error occurred");
          });
      }
      this.client
        .leave(this.tcchannel)
        .then(function (channel) {
          console.log("left the channel");
        })
        .fail(function (ex) {
          console.log("failed to leave the channel");
        });
      this.client
        .unregister()
        .then(function (result) {
          console.log("unregistration succeeded");
        })
        .fail(function (ex) {
          console.log("unregistration failed");
        });
    },
  },
  created() { },
  mounted() {
    // console.log(window.fm);
    this.fm = window.fm;
    this.clientName =
      this.user.anagrafica.name + " " + this.user.anagrafica.surname;
    this.start();
  },
  beforeDestroy() {
    // this.stopCall();
    // this.$emit("tc-end", true);
  },
};
</script>

<style>
#chatbox {
  /* width: 75vw;
  height: 50vh; */
  width: 100%;
  height: 8vh;
  overflow: auto;
  /* display: flex;
  flex-direction: column-reverse; */
}

#call__btns {
  position: absolute;
  top: 50vh;
}

.fm-video>video {
  position: initial !important;
}
</style>