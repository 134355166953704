<template>
  <div>
    <b-button @click="showAnnullamento()">Conferma Annullamento</b-button>
    <b-modal
      size="xl"
      centered
      id="modal-annullamento"
      ref="modal-annullamento"
      hide-footer
      hide-header
      body-class="p-0"
      style="overflow: hidden"
    >
      <PickMessageCarousel class="px-4" />
    </b-modal>
  </div>
</template>

<script lang="ts">
import Vue from "vue"
import PickMessageCarousel from "@/components/prenotazioni/form/PickMessageCarousel.vue"

export default Vue.extend({
  name: "ConfermaAnnullamentView",
  components: {
    PickMessageCarousel
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    showAnnullamento() {
      this.$bvModal.show("modal-annullamento")
    }
  }
})
</script>

<style lang="scss" scoped></style>
