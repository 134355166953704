<template>
  <div class="px-4" v-if="alertEvent">
    <b-row align-v="center" class="justify-content-between">
      <b-col class="">
        <h2 class="py-4 text-left"></h2>
      </b-col>
      <b-col class=""> </b-col>
    </b-row>

    <b-row align-v="stretch" cols="1" cols-lg="2" cols-xl="3">
      <b-col class="px-1 my-2 order-1 order-lg-1 order-xl-1">
        <div class="h-100">
          <div class="bg-white p-3 general_border_17 shadow_2 px-1 h-100">
            <b-row class="justify-content-between">
              <h3 class="text-left py-2">Alert</h3>
              <b-iconstack
                font-scale="3"
                class="point"
                v-if="!alertEvent.forwarded_to_operator"
                @click="openForward"
              >
                <b-icon
                  stacked
                  icon="circle-fill"
                  variant="mdm-light-grey"
                ></b-icon>
                <b-icon
                  stacked
                  icon="reply-fill"
                  scale="0.5"
                  variant="primary"
                ></b-icon>
              </b-iconstack>
            </b-row>
            <b-row class="mt-2 mb-4 pb-4">
              <b-col
                :class="'bg-' + severityMap[alertEvent.threshold_level]"
                class="rounded-pill text-white mx-2 px-1 py-1"
              >
                <strong>
                  {{ severityLabelMap[alertEvent.threshold_level] }} Severità
                </strong>
              </b-col>
              <b-col
                @click="$bvModal.show('alert_cause')"
                class="rounded-pill text-primary border border-primary mx-2 px-1 py-1 point"
              >
                <strong> Perché alert </strong>
              </b-col>
              <b-col
                v-if="notClosed"
                @click="openEdit"
                class="rounded-pill text-primary border border-primary mx-2 px-1 py-1 point"
              >
                <strong> Modifica alert </strong>
              </b-col>
              <b-col v-else></b-col>
            </b-row>
            <div class="pr-3 pl-1">
              <b-col class="col-6 d-flex flex-column">
                <span class="d-inline-block mb-3">
                  <b-avatar
                    variant="white"
                    :src="alertEvent.patient.profile_pic"
                    rounded="lg"
                    size="12rem"
                    class="shadow_6"
                  ></b-avatar>
                </span>
                <span
                  @click="
                    $router.push({
                      name: 'scheda paziente',
                      params: { hash: alertEvent.patient.hashid },
                    })
                  "
                  class="rounded-pill text-primary bg-mdm-light-grey my-2 px-1 py-1 point fw-bolder shadow_8"
                >
                  Scheda Paziente</span
                >
              </b-col>
              <b-row cols="1" cols-lg="2" class="text-left mt-3">
                <b-col class="my-2">
                  <h6>Nome e Cognome</h6>
                  <p class="border-bottom border-light mb-0 pb-1">
                    {{ alertEvent.patient.anagrafica.name }}
                    {{ alertEvent.patient.anagrafica.surname }}
                  </p>
                </b-col>
                <b-col class="my-2">
                  <h6>Età</h6>
                  <p class="border-bottom border-light mb-0 pb-1">
                    {{ patientAge }}
                  </p>
                </b-col>
                <b-col class="my-2">
                  <h6>Codice Fiscale</h6>
                  <p class="border-bottom border-light mb-0 pb-1">
                    {{ alertEvent.patient.anagrafica.cf }}
                  </p>
                </b-col>
                <b-col class="my-2">
                  <h6>Telefono</h6>
                  <p class="border-bottom border-light mb-0 pb-1">
                    {{ alertEvent.patient.anagrafica.tel_number }}
                  </p>
                </b-col>
              </b-row>
              <b-row class="text-justify">
                <b-col class="my-2">
                  <h6>Patologie</h6>
                  <p class="border-bottom border-light mb-0 pb-1">
                    <span v-for="patology in pathologies">
                      <b-icon
                        style="vertical-align: middle"
                        icon="square-fill"
                        variant="primary"
                        font-scale="0.4"
                      >
                      </b-icon>
                      {{ patology }}
                    </span>
                  </p>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-col>
      <b-col class="px-1 my-2 px-xl-4 order-2 order-lg-3 order-xl-2">
        <div class="h-100">
          <div class="bg-white p-3 general_border_17 shadow_2 px-1 h-100">
            <h3 class="text-left py-2">Protocollo</h3>
            <div v-if="protocols" class="pr-3 pl-1">
              <b-row cols="1">
                <b-col
                  @click="mainAction"
                  :class="'bg-' + severityMap[alertEvent.threshold_level]"
                  class="general_border_17 text-white my-1 py-4 shadow_6 point"
                >
                  <h3 class="py-3 mb-0 fw-bolder">
                    {{ protocols[0].protocol.label }}
                  </h3>
                </b-col>
                <b-col
                  @click="$bvModal.show('new_alert')"
                  :class="'border-' + severityMap[alertEvent.threshold_level]"
                  class="border border__2 general_border_17 text-primary my-1 py-4 shadow_6 point"
                >
                  <h3 class="py-3 mb-0 fw-bolder">Attiva Alert</h3>
                </b-col>
              </b-row>
              <b-row cols="1" class="mt-4 pt-2">
                <template v-if="alertEvent.alertable_type !== 'annotation'">
                  <b-col
                    v-if="enabledActions.includes(1)"
                    @click="setCallTo"
                    class="rounded-pill text-primary bg-mdm-light-grey my-2 px-1 py-1 point fw-bolder shadow_8"
                    :class="'order-' + getActionOrder(1)"
                  >
                    <h6 class="py-2 mb-0 fw-bolder">Chiama</h6>
                  </b-col>
                  <b-col
                    v-if="enabledActions.includes(2)"
                    @click="$bvModal.show('soglie_esame')"
                    class="rounded-pill text-primary bg-mdm-light-grey my-2 px-1 py-1 point fw-bolder shadow_8"
                    :class="'order-' + getActionOrder(2)"
                  >
                    <h6 class="py-2 mb-0 fw-bolder">Visualizza soglie</h6>
                  </b-col>
                  <b-col
                    v-if="enabledActions.includes(11)"
                    @click="$bvModal.show('note_sanitarie_modal')"
                    class="rounded-pill text-primary bg-mdm-light-grey my-2 px-1 py-1 point fw-bolder shadow_8"
                    :class="'order-' + getActionOrder(11)"
                  >
                    <h6 class="py-2 mb-0 fw-bolder">Aggiungi nota sanitaria</h6>
                  </b-col>
                  <b-col
                    v-if="enabledActions.includes(12)"
                    @click="$bvModal.show('frequenze_esame')"
                    class="rounded-pill text-primary bg-mdm-light-grey my-2 px-1 py-1 point fw-bolder shadow_8"
                    :class="'order-' + getActionOrder(12)"
                  >
                    <h6 class="py-2 mb-0 fw-bolder">
                      Visualizza e modifica frequenza
                    </h6>
                  </b-col>
                  <b-col
                    v-if="enabledActions.includes(13)"
                    @click="$bvModal.show('soglie_esame')"
                    class="rounded-pill text-primary bg-mdm-light-grey my-2 px-1 py-1 point fw-bolder shadow_8"
                    :class="'order-' + getActionOrder(13)"
                  >
                    <h6 class="py-2 mb-0 fw-bolder">
                      Visualizza e modifica soglie
                    </h6>
                  </b-col>
                </template>
                <b-col
                  v-if="enabledActions.includes(4) && caregivers"
                  @click="$bvModal.show('caregivers_modal')"
                  class="rounded-pill text-primary bg-mdm-light-grey my-2 px-1 py-1 point fw-bolder shadow_8"
                  :class="'order-' + getActionOrder(4)"
                >
                  <h6 class="py-2 mb-0 fw-bolder">Contatta caregiver</h6>
                </b-col>
                <b-col
                  v-if="enabledActions.includes(5) && notClosed"
                  @click="$bvModal.show('edit_alert_note')"
                  class="rounded-pill text-primary bg-mdm-light-grey my-2 px-1 py-1 point fw-bolder shadow_8"
                  :class="'order-' + getActionOrder(5)"
                >
                  <h6 class="py-2 mb-0 fw-bolder">Modifica nota alert</h6>
                </b-col>
                <b-col
                  v-if="enabledActions.includes(6)"
                  @click="$bvModal.show('emergencycontact_modal')"
                  class="rounded-pill text-primary bg-mdm-light-grey my-2 px-1 py-1 point fw-bolder shadow_8"
                  :class="'order-' + getActionOrder(6)"
                >
                  <h6 class="py-2 mb-0 fw-bolder">Visualizza contatti utili</h6>
                </b-col>
                <b-col
                  v-if="enabledActions.includes(7)"
                  @click="$bvModal.show('circolocura_modal')"
                  class="rounded-pill text-primary bg-mdm-light-grey my-2 px-1 py-1 point fw-bolder shadow_8"
                  :class="'order-' + getActionOrder(7)"
                >
                  <h6 class="py-2 mb-0 fw-bolder">Contatta circolo di cura</h6>
                </b-col>
                <b-col
                  v-if="enabledActions.includes(9)"
                  @click="
                    $router.push({
                      name: 'prenota servizio',
                      params: {
                        hash: alertEvent.patient.hashid,
                      },
                    })
                  "
                  class="rounded-pill text-primary bg-mdm-light-grey my-2 px-1 py-1 point fw-bolder shadow_8"
                  :class="'order-' + getActionOrder(9)"
                >
                  <h6 class="py-2 mb-0 fw-bolder">Prenota Teleconsulto</h6>
                </b-col>
                <b-col
                  v-if="enabledActions.includes(8)"
                  @click="
                    $router.push({
                      name: 'prenota servizio',
                      params: {
                        hash: alertEvent.patient.hashid,
                      },
                    })
                  "
                  class="rounded-pill text-primary bg-mdm-light-grey my-2 px-1 py-1 point fw-bolder shadow_8"
                  :class="'order-' + getActionOrder(8)"
                >
                  <h6 class="py-2 mb-0 fw-bolder">Prenota appuntamento</h6>
                </b-col>
                <b-col
                  v-if="enabledActions.includes(10)"
                  @click="$bvModal.show('note_sanitarie_modal')"
                  class="rounded-pill text-primary bg-mdm-light-grey my-2 px-1 py-1 point fw-bolder shadow_8"
                  :class="'order-' + getActionOrder(10)"
                >
                  <h6 class="py-2 mb-0 fw-bolder">Visualizza note sanitarie</h6>
                </b-col>
                <template v-for="(action, ind) in protocols">
                  <template v-if="action.protocol.created_by">
                    <b-col
                      v-if="action.protocol.survey"
                      @click="$bvModal.show('note_sanitarie_modal')"
                      class="rounded-pill text-primary bg-mdm-light-grey my-2 px-1 py-1 point fw-bolder shadow_8"
                      :class="'order-' + action.order"
                    >
                      <h6 class="py-2 mb-0 fw-bolder">
                        {{ action.protocol.label }}
                      </h6>
                    </b-col>

                    <b-col
                      v-else
                      @click="openGenericAction(action)"
                      class="rounded-pill text-primary bg-mdm-light-grey my-2 px-1 py-1 point fw-bolder shadow_8"
                      :class="'order-' + action.order"
                    >
                      <h6 class="py-2 mb-0 fw-bolder">
                        {{ action.protocol.label }}
                      </h6>
                    </b-col>
                  </template>
                </template>
              </b-row>
            </div>
          </div>
        </div>
      </b-col>
      <b-col class="px-1 my-2 order-3 order-lg-2 order-xl-3">
        <div class="h-100">
          <div class="bg-white p-3 general_border_17 shadow_2 px-1 h-100">
            <b-row class="justify-content-between">
              <h3 class="text-left py-2">Note operatore</h3>
              <b-iconstack
                font-scale="3"
                class="point"
                @click="$bvModal.show('add_annotation')"
              >
                <b-icon
                  stacked
                  icon="circle-fill"
                  variant="mdm-light-grey"
                ></b-icon>
                <b-icon
                  stacked
                  icon="plus"
                  scale="1.1"
                  variant="primary"
                ></b-icon>
              </b-iconstack>
            </b-row>
            <!--  <b-row class="mt-2 mb-4 pb-4">
              <b-col class="rounded-pill bg-severity-high text-white mx-2 px-1 py-1">
                                <strong>
                                    Alta Severità
                                </strong>
                            </b-col>
                            <b-col class="rounded-pill bg-severity-medium text-white mx-2 px-1 py-1">
                                <strong>
                                    Media Severità
                                </strong>
                            </b-col>
                            <b-col class="rounded-pill bg-severity-low text-white mx-2 px-1 py-1">
                                <strong>
                                    Bassa Severità
                                </strong>
                            </b-col> 
            </b-row>-->
            <div class="pr-3 pl-1 hide_overflow_y boxes_max_heigh_70">
              <b-row cols="1">
                <template v-for="note in notes">
                  <b-col
                    :key="note.hashid"
                    class="text-left bg-info general_border_17 shadow_2 py-3 px-4 my-2"
                  >
                    <h4 class="fw-bolder text-capitalize">
                      {{ getFormattedDate(note.created_at) }}
                    </h4>
                    <p class="text-justify mb-0 fs-5">
                      {{ note.content }}
                    </p>
                  </b-col>
                </template>
              </b-row>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="justify-content-end mt-3" v-if="asControlRoom">
      <b-button
        @click="$router.push({ name: 'control room' })"
        variant="outline-primary general_border_17 fw-bolder"
        class="mx-2"
        >Torna indietro</b-button
      >
      <b-button
        v-if="alertEvent.status !== 3 && !alertEvent.forwarded_to_operator"
        @click="completeEvent"
        variant="primary general_border_17 fw-bolder"
        class="mx-2"
        >Completa</b-button
      >
      <span
        v-else-if="alertEvent.forwarded_to_operator"
        class="terz-grad-2 terz_bg_gradient general_border_17 fw-bolder mx-2 py-3 text-white px-3 text-capitalize"
        >Inoltrato a {{ alertEvent.forwarded_to_operator.anagrafica.name }}
        {{ alertEvent.forwarded_to_operator.anagrafica.surname }}</span
      >
      <span
        v-else
        class="terz-grad-2 terz_bg_gradient general_border_17 fw-bolder mx-2 py-3 text-white px-3"
        >Completato</span
      >
    </b-row>
    <b-row class="justify-content-end mt-3" v-else>
      <b-button
        @click="$router.push({ name: 'control room' })"
        variant="outline-primary general_border_17 fw-bolder"
        class="mx-2"
        >Torna indietro</b-button
      >
      <b-button
        v-if="alertEvent.status !== 3"
        @click="completeEvent"
        variant="primary general_border_17 fw-bolder"
        class="mx-2"
        >Completa</b-button
      >
      <span
        v-else
        class="terz-grad-2 terz_bg_gradient general_border_17 fw-bolder mx-2 py-3 text-white px-3"
        >Completato</span
      >
    </b-row>
    <b-modal
      id="alert_cause"
      size="lg"
      centered
      hide-footer
      title="Perchè alert"
    >
      <div class="px-2">
        <h4 class="pb-4">{{ alertEvent.cause }}</h4>
        <div v-if="alertEvent.alertable_type === 'rilevazione'">
          <h5 class="pb-4">
            <b-icon
              font-scale="1.5"
              icon="exclamation-triangle-fill"
              :variant="severityMap[Math.abs(alertEvent.alertable.value_level)]"
            ></b-icon>
            {{ alertEvent.alertable.exam.name }}
            <span>
              {{ alertEvent.alertable.value * 1 }}
              {{ alertEvent.alertable.exam.um }}
            </span>
          </h5>
          <p>{{ alertEvent.alertable.details }}</p>
          <div class="mb-4 pb-4">
            <h6
              class="text-justify pb-4 pt-2 bg-info px-2 general_border"
              v-if="alertEvent.alertable.reference.action"
            >
              Protocollo operativo: <br />
              {{ alertEvent.alertable.reference.action }}
            </h6>
            <h6
              class="text-justify pb-4 pt-2 bg-info px-2 general_border"
              v-if="alertEvent.alertable.reference.diagnostic"
            >
              Protocollo diagnostico: <br />{{
                alertEvent.alertable.reference.diagnostic
              }}
            </h6>
          </div>
        </div>
        <div v-else-if="alertEvent.alertable_type === 'exam-frequency'">
          <h5 class="pb-4">
            <b-icon
              font-scale="1.5"
              icon="exclamation-triangle-fill"
              :variant="severityMap[Math.abs(alertEvent.threshold_level)]"
            ></b-icon>
            Il paziente non ha rilevato il valore relativo al parametro
            <span class="text-lowercase text-primary">
              {{ alertEvent.alertable.exam.name }}.
            </span>
          </h5>
          <p>{{ alertEvent.alertable.details }}</p>
        </div>
        <div v-else-if="alertEvent.alertable_type === 'score'">
          <h5 class="pb-4">
            <b-icon
              font-scale="1.5"
              icon="exclamation-triangle-fill"
              :variant="severityMap[Math.abs(alertEvent.threshold_level)]"
            ></b-icon>
            {{ alertEvent.alertable.score.name }}:
            <span> {{ alertEvent.alertable.value * 1 }}. </span>
          </h5>
          <p>{{ alertEvent.alertable.details }}</p>
        </div>
        <div v-else-if="alertEvent.alertable_type === 'score-frequency'">
          <h5 class="pb-4">
            <b-icon
              font-scale="1.5"
              icon="exclamation-triangle-fill"
              :variant="severityMap[Math.abs(alertEvent.threshold_level)]"
            ></b-icon>
            Il paziente non ha rilevato il valore relativo allo score
            <span class="text-lowercase text-primary">
              {{ alertEvent.alertable.score.name }}.
            </span>
          </h5>
          <p>{{ alertEvent.alertable.details }}</p>
        </div>
        <div v-else-if="alertEvent.alertable_type === 'annotation'">
          <h5 class="pb-4">
            <b-icon
              font-scale="1.5"
              icon="exclamation-triangle-fill"
              :variant="severityMap[Math.abs(alertEvent.threshold_level)]"
            ></b-icon>
            <span class="text-justify">
              {{ alertEvent.alertable.content }}.
            </span>
          </h5>
          <p>{{ alertEvent.alertable.details }}</p>
        </div>
        <div v-else>
          {{ alertEvent.alertable }}
        </div>

        <div
          class="bg-extra-light pt-1 px-2 pb-4 mb-2"
          v-if="alertEvent.forward_note"
        >
          <h5>Note di inoltro</h5>
          <p class="text-justify">{{ alertEvent.forward_note }}</p>
        </div>

        <h6>
          {{ alertEvent.created_at | moment("DD/MM/YYYY") }}
          {{ alertEvent.created_at | moment("HH:mm") }}
        </h6>
      </div>
    </b-modal>
    <b-modal
      id="add_annotation"
      centered
      hide-footer
      size="lg"
      title="Nuova nota"
    >
      <div class="px-3 pb-2">
        <b-form @submit.prevent="storeNewAnnotation">
          <b-form-textarea
            v-model="newNote.content"
            placeholder="Le tue annotazioni..."
            class="p-2 border general_border border-primary shadow_8 bg-extralight2"
            rows="20"
            required
          >
          </b-form-textarea>
          <br />
          <b-button
            type="submit"
            variant="mdm-light-grey"
            block
            class="rounded-pill text-primary mt-4 mb-2 px-1 py-2 point fw-bolder shadow_8"
            >Salva</b-button
          >
        </b-form>
      </div>
    </b-modal>

    <b-modal
      id="forward_alert"
      centered
      hide-footer
      size="lg"
      title="Inoltra alert"
    >
      <div class="px-3 pb-2">
        <b-overlay :show="patientCompanies === null" rounded="sm">
          <b-form @submit.prevent="forwardAlertEvent" class="px-4 py-3">
            <b-form-group label="Inoltra alert a: " class="mb-4">
              <b-form-select v-model="forwardForm.company_id" required>
                <b-form-select-option
                  v-for="company in patientCompanies"
                  :key="company.hashid"
                  :value="company"
                  >{{ company.denominazione }} ({{
                    company.sottotipo
                  }})</b-form-select-option
                >
                <b-form-select-option :value="null" disabled
                  >scegli...</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
            <b-form-group v-if="forwardForm.company_id" class="mb-4">
              <b-form-select
                v-model="forwardForm.operator_id"
                required
                class="text-capitalize"
              >
                <b-form-select-option
                  v-for="operator in forwardForm.company_id.operators"
                  :key="operator.hashid"
                  :value="operator.hashid"
                  :disabled="operator.hashid === user.hashid"
                  >{{ operator.anagrafica.name }}
                  {{ operator.anagrafica.surname }}</b-form-select-option
                >
                <b-form-select-option :value="null" disabled
                  >scegli operatore...</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
            <b-form-textarea
              v-model="forwardForm.forward_note"
              placeholder="Le tue annotazioni..."
              class="p-2 border general_border border-primary shadow_8 bg-extralight2"
              rows="10"
              required
            >
            </b-form-textarea>
            <br />
            <b-button
              type="submit"
              variant="mdm-light-grey"
              block
              class="rounded-pill text-primary mt-4 mb-2 px-1 py-2 point fw-bolder shadow_8"
              >Inoltra alert</b-button
            >
          </b-form>
        </b-overlay>
      </div>
    </b-modal>

    <b-modal
      id="edit_alert"
      centered
      hide-footer
      size="lg"
      title="Modifica severità alert"
    >
      <div class="px-3 pb-2">
        <b-form @submit.prevent="editAlertLevel" class="px-4 py-3">
          <b-form-group label="Livello di severità appropriato:" class="mb-4">
            <b-form-select v-model="editForm.threshold_level" required>
              <b-form-select-option
                v-for="(label, level) in severityLabelMap"
                :key="label"
                :value="level"
                :disabled="alertEvent.threshold_level == level"
                >{{ label }}</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
          <b-form-textarea
            v-model="editForm.operator_notes"
            placeholder="Le tue annotazioni..."
            class="p-2 border general_border border-primary shadow_8 bg-extralight2"
            rows="10"
            required
          >
          </b-form-textarea>
          <br />
          <b-button
            type="submit"
            variant="mdm-light-grey"
            block
            class="rounded-pill text-primary mt-4 mb-2 px-1 py-2 point fw-bolder shadow_8"
            >Salva modifica</b-button
          >
        </b-form>
      </div>
    </b-modal>
    <b-modal
      id="edit_alert_note"
      centered
      hide-footer
      size="lg"
      title="Modifica nota alert"
      @show="alertNoteEditForm.operator_notes = alertEvent.operator_notes"
    >
      <b-form @submit.prevent="editAlertNote" class="px-4 py-3">
        <b-form-textarea
          v-model="alertNoteEditForm.operator_notes"
          placeholder="Le tue annotazioni..."
          class="p-2 border general_border border-primary shadow_8 bg-extralight2"
          rows="10"
          required
        >
        </b-form-textarea>
        <br />
        <b-button
          type="submit"
          variant="mdm-light-grey"
          block
          class="rounded-pill text-primary mt-4 mb-2 px-1 py-2 point fw-bolder shadow_8"
          >Salva modifica</b-button
        >
      </b-form>
    </b-modal>

    <b-modal
      id="new_alert"
      centered
      hide-footer
      size="lg"
      title="Attiva nuovo alert"
    >
      <div class="px-3 pb-2">
        <b-form @submit.prevent="newAlertEvent" class="px-4">
          <b-form-group class="mb-4 col-8 mx-auto">
            <b-form-select
              v-model="newEventForm.threshold_level"
              class="border border-primary shadow_8 general_border_17"
              required
            >
              <b-form-select-option
                v-for="(label, level) in severityLabelMap"
                :key="label"
                :value="level"
                >{{ label }}</b-form-select-option
              >
              <b-form-select-option :value="null" disabled
                >Severità</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
          <b-form-group class="mb-4 col-8 mx-auto">
            <b-form-input
              placeholder="Causa"
              class="border border-primary shadow_8 general_border_17"
              type="text"
              v-model="newEventForm.cause"
              required
            >
            </b-form-input>
          </b-form-group>
          <b-form-group class="mb-4 col-8 mx-auto">
            <b-form-textarea
              v-model="newEventForm.operator_notes"
              placeholder="Annotazioni..."
              class="p-2 general_border border border-primary shadow_8 general_border_17 bg-extralight2"
              rows="10"
              required
            >
            </b-form-textarea>
          </b-form-group>
          <br />
          <b-button
            type="submit"
            variant="mdm-light-grey"
            block
            class="rounded-pill text-primary mt-4 mb-2 px-1 py-2 point fw-bolder shadow_8"
            >Salva</b-button
          >
        </b-form>
      </div>
    </b-modal>
    <b-modal
      id="frequenze_esame"
      size="lg"
      centered
      hide-footer
      title="Visualizza frequenza rilevazione"
    >
      <div
        class="px-3"
        v-if="
          alertEvent.alertable_type === 'rilevazione' ||
          alertEvent.alertable_type === 'exam-frequency'
        "
      >
        <h6 class="text-uppercase mb-4">
          {{ alertEvent.alertable.exam.name }}
        </h6>
        <FrequenzeRilevazioni
          :exam="alertEvent.alertable.exam.hashid"
          :subject="alertEvent.patient.hashid"
        ></FrequenzeRilevazioni>
      </div>

      <div
        class="px-3"
        v-else-if="
          alertEvent.alertable_type === 'score' ||
          alertEvent.alertable_type === 'score-frequency'
        "
      >
        <h6 class="text-uppercase mb-4">
          {{ alertEvent.alertable.score.name }}
        </h6>
        <FrequenzaValutazioneScore
          :subject="alertEvent.patient.hashid"
          :score="alertEvent.alertable.score.hashid"
          @close-editmodal="$bvModal.hide('frequenze_esame')"
          @need-update="$bvModal.hide('frequenze_esame')"
        ></FrequenzaValutazioneScore>
      </div>
    </b-modal>

    <b-modal
      id="soglie_esame"
      size="lg"
      centered
      hide-footer
      title="Visualizza soglie"
    >
      <div
        class="px-3"
        v-if="
          alertEvent.alertable_type === 'rilevazione' ||
          alertEvent.alertable_type === 'exam-frequency'
        "
      >
        <h6 class="text-uppercase mb-4">
          {{ alertEvent.alertable.exam.name }}
        </h6>
        <SoglieRilevazioni
          class="pb-4"
          :exam="alertEvent.alertable.exam.hashid"
          :subject="alertEvent.patient.hashid"
          @close-editmodal="$bvModal.hide('soglie_esame')"
          @need-update="$bvModal.hide('soglie_esame')"
        >
          <template v-slot:examname>
            <span>"{{ alertEvent.alertable.exam.name }}"</span>
          </template>
        </SoglieRilevazioni>
      </div>

      <div
        class="px-3"
        v-else-if="
          alertEvent.alertable_type === 'score' ||
          alertEvent.alertable_type === 'score-frequency'
        "
      >
        <h6 class="text-uppercase mb-4">
          {{ alertEvent.alertable.score.name }}
        </h6>
        <SoglieScore
          :subject="alertEvent.patient.hashid"
          :score="alertEvent.alertable.score.hashid"
        ></SoglieScore>
      </div>
    </b-modal>
    <b-modal
      id="caregivers_modal"
      title="Chiama caregiver"
      centered
      hide-footer
    >
      <div v-if="caregivers" class="px-3">
        <h5 class="mb-3 text-uppercase">Caregeivers del paziente</h5>
        <h6 class="py-1" v-for="giver in caregivers">
          <span class="text-capitalize">
            {{ giver.anagrafica.name }} {{ giver.anagrafica.surname }}:
          </span>
          tel {{ giver.anagrafica.tel_number }}
          <b-button
            size="sm"
            class="ml-2"
            variant="outline-primary modal-footer-button"
            @click="setCallTo(giver.anagrafica.tel_number)"
            >Chiama <b-icon icon="telephone-fill" flip-h rotate="25"></b-icon>
          </b-button>
        </h6>
        <h6 class="font-italic" v-if="caregivers.length === 0">
          Nessun caregiver per il paziente
        </h6>
      </div>
    </b-modal>
    <b-modal
      id="emergencycontact_modal"
      title="Visualizza contatti di utili"
      size="lg"
      centered
      hide-footer
    >
      <div v-if="emergencyContacts" class="px-3">
        <h5 class="mb-3 text-uppercase">Contatti utili del paziente</h5>
        <h6 class="py-1" v-for="contact in emergencyContacts">
          <span class="text-capitalize">
            {{ contact.name }} {{ contact.surname }}
          </span>
          ({{ contact.relationship }}) : tel {{ contact.tel_number }}
          <b-button
            size="sm"
            class="ml-2"
            variant="outline-primary modal-footer-button"
            @click="setCallTo(contact.tel_number)"
            >Chiama <b-icon icon="telephone-fill" flip-h rotate="25"></b-icon>
          </b-button>
        </h6>
        <h6 class="font-italic" v-if="emergencyContacts.length === 0">
          Nessun contatto utile per il paziente
        </h6>
      </div>
    </b-modal>
    <b-modal
      id="circolocura_modal"
      title="Visualizza circolo cura"
      size="lg"
      centered
      hide-footer
    >
      <div v-if="patientCompanies" class="px-3">
        <h5 class="mb-3 text-uppercase">Circolo di cura del paziente</h5>
        <h6 class="py-1" v-for="company in patientCompanies">
          <span class="text-capitalize">
            {{ company.denominazione }}
          </span>
          ({{ company.sottotipo }}) : tel {{ company.telefono }}
          <b-button
            size="sm"
            class="ml-2"
            variant="outline-primary modal-footer-button"
            @click="setCallTo(company.telefono)"
            >Chiama <b-icon icon="telephone-fill" flip-h rotate="25"></b-icon>
          </b-button>
        </h6>
      </div>
    </b-modal>
    <b-modal
      id="note_sanitarie_modal"
      centered
      hide-footer
      size="xl"
      title="Visualizza note sanitarie"
      scrollable
    >
      <div class="px-4 py-3">
        <PatientAnnotations
          :subjectHash="alertEvent.patient.hashid"
          :hidetop="true"
        />
      </div>
    </b-modal>
    <b-modal
      id="generic_action"
      centered
      hide-footer
      size="xl"
      title="Azione personalizzata"
      scrollable
    >
      <div class="px-4 py-3" v-if="selectedAction">
        <h6 class="text-justify">
          {{ selectedAction.protocol.action_description }}
        </h6>
      </div>
      <p v-else>Azione non indicata</p>
    </b-modal>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import {
  croomService,
  companyService,
  supportService,
  cittadinoService,
} from "@/_services";
import FrequenzeRilevazioni from "@/components/profile/sanitario/FrequenzeRilevazioni.vue";
import SoglieRilevazioni from "@/components/profile/sanitario/SoglieRilevazioni.vue";
import FrequenzaValutazioneScore from "@/components/profile/sanitario/FrequenzaValutazioneScore.vue";
import SoglieScore from "@/components/profile/sanitario/SoglieScore.vue";
import PatientAnnotations from "@/components/profile/sanitario/PatientAnnotations.vue";
export default {
  name: "AlertEventDetail",
  components: {
    FrequenzeRilevazioni,
    SoglieRilevazioni,
    FrequenzaValutazioneScore,
    SoglieScore,
    PatientAnnotations,
  },
  data() {
    return {
      alertEvent: null,
      severityOptions: ["bassa", "media", "alta"],
      statusOptions: ["nuovi", "aperti", "chiusi", "posticipati"],
      thresholdValues: [
        { key: "1", label: "Bassa severità", class: "bg-severity-1" },
        { key: "2", label: "Media severità", class: "bg-severity-2" },
        { key: "3", label: "Alta severità", class: "bg-severity-3" },
      ],
      severityMap: {
        1: "severity-low",
        2: "severity-medium",
        3: "severity-high",
      },
      severityLabelMap: {
        1: "Bassa",
        2: "Media",
        3: "Alta",
      },
      newNote: {
        content: null,
        shared: false,
        is_soap: false,
        user_id: null,
      },
      filters: {
        year: moment().year(),
        // month: moment().month() + 1,
        array: true,
      },
      notes: null,
      patientCompanies: null,
      forwardForm: {
        company_id: null,
        operator_id: null,
        forward_note: null,
      },
      editForm: {
        threshold_level: null,
        operator_notes: null,
      },
      newEventForm: {
        user_id: null,
        threshold_level: null,
        operator_notes: null,
        cause: null,
      },
      alertNoteEditForm: {
        operator_notes: null,
      },
      pathologies: null,
      caregivers: null,
      emergencyContacts: null,
      protocols: null,
      enabledActions: [],
      selectedAction: null,
    };
  },
  computed: {
    ...mapState("utente", ["user"]),
    ...mapState("company", ["company"]),
    ...mapState({
      alert: (state) => state.alert,
    }),
    patientAge: function () {
      if (this.alertEvent && this.alertEvent.patient) {
        const bday = this.alertEvent.patient.anagrafica.birthday;
        return moment().diff(bday, "years");
      }
      return "";
    },
    notClosed: function () {
      if (this.alertEvent) {
        return (
          this.alertEvent.status !== 3 && !this.alertEvent.forwarded_to_operator
        );
      }
      return true;
    },
    asControlRoom: function () {
      if (this.company) {
        return this.company.level === 5;
      }
      return true;
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    padPatientNumber(ndx) {
      const pseudName = ndx + 1;
      return pseudName.toString().padStart(3, 0);
    },
    fetchData() {
      const self = this;
      croomService
        .getAlertEventDetails(this.$route.params.hash)
        .then(function (response) {
          self.alertEvent = response.data.data;
          self.fetchAllData(response.data.data.patient.hashid);
        })
        .catch(function (error) {
          self.errorAlert(
            "Non è stato possibile ottenere la lista di pazienti"
          );
          console.log(error);
        });
    },
    fetchAllData(hashid) {
      this.getNotes(hashid);
      this.fetchUserPathologies(hashid);
      this.fetchUserProtocol(hashid);
      this.fetchUserCaregivers(hashid);
      this.fetchUserContacts(hashid);
      this.getUserCircoloCura(hashid);
    },
    getNotes(userHash) {
      const self = this;
      supportService
        .getUserAnnotations(this.filters, userHash)
        .then(function (response) {
          self.notes = response.data.data;
          // console.log(response.data.data)
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le note");
        });
    },
    storeNewAnnotation() {
      this.newNote.user_id = this.alertEvent.patient.hashid;
      const self = this;
      companyService
        .storeNewAnnotation(this.newNote)
        .then(function (response) {
          self.newNote.content = null;
          self.successAlert("Nota inserita correttamente");
          self.getNotes(self.alertEvent.patient.hashid);
          self.$bvModal.hide("add_annotation");
        })
        .catch(function (error) {
          self.errorAlert("Errore nell'inserimento della nota");
        });
    },
    completeEvent() {
      const self = this;
      const formData = {
        status: 3,
      };
      croomService
        .updateAlertEvent(this.$route.params.hash, formData)
        .then(function (response) {
          self.successAlert("Alert impostato come completato!");
          self.fetchData();
        })
        .catch(function (error) {
          self.errorAlert(
            "Non è stato possibile ottenere la lista di pazienti"
          );
          console.log(error);
        });
    },
    forwardAlertEvent() {
      const self = this;
      const selectedCompany = this.forwardForm.company_id;
      this.forwardForm.company_id = selectedCompany.hashid;
      croomService
        .updateAlertEvent(this.$route.params.hash, this.forwardForm)
        .then(function (response) {
          self.fetchData();
          self.$bvModal.hide("forward_alert");
          self.successAlert("Alert inoltrato correttamente!");
          self.forwardForm = {
            company_id: null,
            forward_note: null,
          };
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile inoltrare l'alert");
          console.log(error);
        });
    },
    editAlertLevel() {
      const self = this;
      croomService
        .updateAlertEvent(this.$route.params.hash, this.editForm)
        .then(function (response) {
          self.fetchData();
          self.$bvModal.hide("edit_alert");
          self.successAlert("Alert modificato correttamente!");
          self.editForm = {
            threshold_level: null,
            operator_notes: null,
          };
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile modificare l'alert");
          console.log(error);
        });
    },
    editAlertNote() {
      const self = this;
      croomService
        .updateAlertEvent(this.$route.params.hash, this.alertNoteEditForm)
        .then(function (response) {
          self.fetchData();
          self.$bvModal.hide("edit_alert_note");
          self.successAlert("Nota alert modificata correttamente!");
          self.alertNoteEditForm.operator_notes = null;
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile modificare l'alert");
          console.log(error);
        });
    },
    getFormattedDate(date) {
      return moment(date).format("D MMMM");
    },
    openForward() {
      this.$bvModal.show("forward_alert");
    },
    openEdit() {
      this.editForm.threshold_level = this.alertEvent.threshold_level;
      this.$bvModal.show("edit_alert");
    },
    getUserCircoloCura(hashid) {
      const self = this;
      const params = {
        // grouped: true,
      };
      croomService
        .getUserCircoloCura(hashid, params)
        .then(function (response) {
          self.patientCompanies = response.data.data;
        })
        .catch(function (error) {
          self.errorAlert(
            "Non è stato possibile ottenere la lista di pazienti"
          );
          console.log(error);
        });
    },
    newAlertEvent() {
      this.newEventForm.user_id = this.alertEvent.patient.hashid;
      const self = this;
      croomService
        .storeNewAlertEvent(this.newEventForm)
        .then(function (response) {
          self.fetchData();
          self.$bvModal.hide("new_alert");
          self.successAlert("Alert salvato correttamente!");
          self.newEventForm = {
            threshold_level: null,
            operator_notes: null,
            cause: null,
          };
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile salvare l'alert");
          console.log(error);
        });
    },
    fetchUserPathologies(hashid) {
      const self = this;
      const filters = {
        user: hashid,
        only: "name",
      };
      cittadinoService
        .getPathologies(filters)
        .then(function (response) {
          // self.pathologies = response.data.data.join(", ");
          self.pathologies = response.data.data;
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le patologie");
        });
    },
    fetchUserCaregivers(hashid) {
      const self = this;
      cittadinoService
        .getUserCaregivers(hashid)
        .then(function (response) {
          self.caregivers = response.data.data;
        })
        .catch(function (error) {
          self.errorAlert(
            "Non è stato possibile recuperare i caregivers del paziente"
          );
        });
    },
    fetchUserContacts(hashid) {
      const self = this;
      cittadinoService
        .getUserEmergencyContacts(hashid)
        .then(function (res) {
          self.emergencyContacts = res.data.data;
          // self.memberList = res.data.data;
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le informazioni.");
        });
    },
    fetchUserProtocol(hashid) {
      const self = this;
      croomService
        .getUserAlertEventProtocols(hashid)
        .then(function (response) {
          if (self.asControlRoom) {
            self.protocols = response.data.data[0];
            self.enabledActions = response.data.data[0].map(
              (val) => val.protocol.ref
            );
          } else {
            self.protocols = response.data.data[1];
            self.enabledActions = response.data.data[1].map(
              (val) => val.protocol.ref
            );
          }
          if (self.enabledActions.length > 0) {
            self.enabledActions.shift();
          }
        })
        .catch(function (error) {
          self.errorAlert("Nessun protocollo per il paziente");
        });
    },
    setCallTo(num = null) {
      var phoneTo = num;
      if (!num) {
        phoneTo = this.alertEvent.patient.anagrafica.tel_number;
      }
      console.log(phoneTo);
      let link = document.createElement("a");
      link.href = "tel:+39" + phoneTo;
      console.log(link.href);
      link.click();
    },
    mainAction() {
      const action = this.protocols[0];
      console.log("MAIN ACTION ", action);
      switch (action.protocol.ref) {
        case 0:
          this.$router.push({
            name: "attivita",
            params: {
              section: "questionario",
              action: "somministra",
              quest: this.protocols[0].protocol.survey.hashid,
            },
            query: {
              user: this.alertEvent.patient.hashid,
            },
          });
          break;
        case 1:
          this.setCallTo();
          break;
        case 2:
          this.$bvModal.show("soglie_esame");
          break;
        case 3:
          break;
        case 4:
          // this.$bvModal.show('caregivers_modal')
          if (this.caregivers) {
            this.$bvModal.show("caregivers_modal");
          }
          break;
        case 5:
          this.$bvModal.show("edit_alert_note");
          break;
        case 6:
          this.$bvModal.show("emergencycontact_modal");
          break;
        case 7:
          this.$bvModal.show("circolocura_modal");
          break;
        case 8:
        case 9:
          this.$router.push({
            name: "prenota servizio",
            params: {
              hash: this.alertEvent.patient.hashid,
            },
          });
          break;
        case 10:
          this.$bvModal.show("note_sanitarie_modal");
          break;
        case 11:
          this.$bvModal.show("note_sanitarie_modal");
          break;
        case 12:
          this.$bvModal.show("frequenze_esame");
          break;
        case 13:
          this.$bvModal.show("soglie_esame");
          break;
        default:
          this.openGenericAction(this.protocols[0]);
      }
    },
    getActionOrder(ref) {
      const action = this.protocols.find((el) => el.protocol.ref === ref);
      return action.order;
    },
    openGenericAction(protocol) {
      this.selectedAction = protocol;
      this.$bvModal.show("generic_action");
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
<style>
.boxes_max_heigh_70 {
  max-height: 77vh;
}
</style>
