<template>
  <div
    v-if="info"
    id="requested-for-asl-header"
    class="m-0 p-3 detail-modal-card text-left"
  >
    <b-row>
      <b-col
        class="d-flex justify-content-center"
        id="requested-for-asl-first-col"
      >
        <span class="d-flex justify-content-center">
          <b-avatar
            :src="info.profile_pic"
            size="66px"
            variant="white"
            class="general_border"
            rounded="lg"
          ></b-avatar>
        </span>
      </b-col>
      <b-col class="ml-auto flex-grow-1 pl-3">
        <b-row>
          <b-col
            class="d-flex flex-column flex-nowrap justify-content-start px-0"
          >
            <p class="mb-0 font-weight-bolder detail-modal-card-titles">
              DENOMINAZIONE
            </p>
            <p class="font-size-12 mb-1 mg-lg-2">
              {{ info.ff_denominazione }}
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            class="d-flex flex-column flex-nowrap justify-content-start px-0 w-100"
          >
            <p class="mb-0 font-weight-bolder detail-modal-card-titles">
              INDIRIZZO
            </p>
            <p class="font-size-12 w-100 text-capitalize">
              {{ info.indirizzo }} - {{ info.comune }}
            </p>
          </b-col>
          <b-col
            class="d-flex flex-column flex-nowrap justify-content-start px-0"
            v-if="info.telefono"
          >
            <p class="mb-0 font-weight-bolder detail-modal-card-titles">
              TELEFONO
            </p>
            <p class="font-size-12 mb-1 mg-lg-2">
              {{ info.telefono }}
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "RequestedforAslInfoCardComponent",
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {}
}
</script>
<style scoped>
#requested-for-asl-header {
  width: 100%;
}

#requested-for-asl-first-col {
  max-width: 80px;
}

.font-size-12 mb-1 mg-lg-2 {
  font-size: 12px;
}

@media (max-width: 1199px) {
  #requested-for-asl-header {
    max-width: 700px;
  }
}

@media (min-width: 1200px) {
  #requested-for-asl-header {
    max-width: 450px;
  }
}

@media (min-width: 1201px) and (max-width: 1260px) {
  #requested-for-asl-header {
    max-width: 430px;
  }
}

@media (min-width: 1400px) {
  #requested-for-asl-header {
    max-width: 500px;
  }
}

@media (min-width: 2000px) {
  #requested-for-asl-header {
    max-width: 800px;
  }
}
</style>
