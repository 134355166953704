<template>
  <div>
    <p>{{ survey.description }}</p>
    <div class="d-flex align-items-center mb-3">
      <h6 class="mb-0">Tipologia:&nbsp;</h6>
      <span>{{ survey.tipo }}</span>
    </div>
    <h6 class="">Informativa</h6>
    <p v-if="survey.info" class="border border-primary text-justify px-2">
      {{ survey.info }}
    </p>
    <p v-else>---</p>
    <h6 class="mt-2">Domande</h6>
    <div v-for="(domanda, ind) in quest.domande" :key="ind">
      <h6 class="">{{ ind + 1 }}. {{ domanda.q }}</h6>
      <p
        class="bg-sec-4 border-sec-4 min_border text-justify px-2"
        v-if="domanda.o == 'file'"
      >
        <strong>Risposta: <br /></strong> opzione caricamento
        <span v-if="domanda.fa"> file di tipo {{ getFileAcceptsType(domanda.fa) }}</span>
        <span v-else> immagine </span>
        <span v-if="domanda.fm"> multiplo</span>
      </p>
      <p
        class="bg-sec-4 border-sec-4 min_border text-justify px-2"
        v-else-if="domanda.o"
      >
        <strong>Opzioni risposta: <br /></strong>
        <span v-for="option in domanda.o" :key="option"
          >{{ option }} <br
        /></span>
      </p>
      <p class="bg-sec-4 border-sec-4 min_border text-justify px-2" v-else>
        <strong>Risposta: <br /></strong> campo di testo libero
      </p>
    </div>
    <!-- {{ survey.template }} -->
  </div>
</template>
<script>
export default {
  name: "SurveyShower",
  props: ["survey"],
  data() {
    return {
      quest: null,
      mappedFileAccepts: [".pdf,.zip", "image/*", "*"],
      mappedFileAcceptsTag: ["PDF e zip", "immagine", "qualsiasi"]
    };
  },
  created() {
    this.quest = JSON.parse(this.survey.template);
  },
  methods: {
    getFileAcceptsType(fa) {
      try {
        return this.mappedFileAcceptsTag[this.mappedFileAccepts.indexOf(fa)]
      } catch (er) {
        return "immagine"
      }

    }
  }
};
</script>
<style>
.min_border {
  border-radius: 5px;
}
</style>
