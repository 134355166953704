<template>
  <div>
    <!-- <h6 class="">Terapia</h6> -->
    <div v-for="(domanda, ind) in quest.domande" :key="ind" class="my-1">
      <h6 class="">{{ domanda.q }}:</h6>

      <p
        class="
          panel__box__shadow
          bg-light-panel
          border border-light-panel
          min_border
          text-justify
          p-2
        "
      >
        <strong>{{ domanda.a }} <br /></strong>
      </p>
    </div>
    <!-- {{ survey.template }} -->
  </div>
</template>
<script>
import ImageShower from "@/components/utilities/ImageShower.vue";
export default {
  name: "FilledIrstSurveyShower",
  props: ["response"],
  components: { ImageShower },
  data() {
    return {
      quest: null,
    };
  },
  created() {
    this.quest = JSON.parse(this.response);
  },
};
</script>
<style>
.min_border {
  border-radius: 5px;
}
</style>