<template>
  <div v-if="patientList" class="puntofarmaCard general_border">
    <b-form
      @submit.prevent="fetchData"
      name="listsearch"
      class="general_border"
    >
      <b-row
        align-v="center"
        class="bg-whitesmoke py-3 general_border border-bottom border-info flex-wrap"
      >
        <b-col class="px-0" cols="12" md="2" lg="1">
          <h6 class="mt-2">
            <span class="mx-2">
              <b-icon icon="search"></b-icon>
            </span>
            Ricerca
          </h6>
        </b-col>
        <b-col>
          <b-input-group size="sm">
            <b-form-input
              class="align-items-center align-content-center border-right-0 text-uppercase"
              name="cfsearch"
              v-model="filters.cf"
              type="search"
              placeholder="Codice Fiscale.."
              maxlength="16"
              @input="filters.surname = undefined"
            >
            </b-form-input>
          </b-input-group>
        </b-col>
        <b-col>
          <b-input-group size="sm">
            <b-form-input
              class="align-items-center align-content-center border-right-0 text-capitalize"
              name="surnamesearch"
              v-model="filters.surname"
              type="search"
              placeholder="Cognome.."
              @input="filters.cf = undefined"
            >
            </b-form-input>
          </b-input-group>
        </b-col>
        <b-col cols="12" md="2">
          <b-button type="submit" variant="secondary" size="sm" class="px-4"
            >Cerca</b-button
          >
        </b-col>
      </b-row>
    </b-form>
    <b-table
      hover
      borderless
      responsive
      table-variant="white text-left row__font"
      :fields="fields"
      :items="patientList"
      :small="$root.isMobile"
      selectable
      select-mode="multi"
      selected-variant="sec-3"
      :busy="busyTable"
      thead-class=" border-medium-grey text-left"
      tbody-class=""
      ref="companyTable"
      tbody-tr-class="border-bottom"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle" variant="info"></b-spinner>
        </div>
      </template>
      <template #cell(index)="row">
        <span>{{ fromPage + row.index }}</span>
      </template>
      <template #cell(fullname)="row">
        <span v-if="row.item.anagrafica && row.item.anagrafica.name">{{
          row.item.anagrafica.name
        }}</span>
        <span v-if="row.item.anagrafica && row.item.anagrafica.surname">
          {{ row.item.anagrafica.surname }}</span
        >
      </template>
      <template #cell(azioni)="row">
        <span>
          <router-link
            class="no__hover"
            :to="{
              name: 'scheda paziente',
              params: { hash: row.item.hashid },
            }"
          >
            <b-button
              variant="outline-primary"
              class="modal-body-button p-1 px-md-2 px-lg-3"
              size="sm"
            >
              Scheda Paziente
              <b-icon
                icon="journal-medical"
                class="d-none d-lg-inline"
              ></b-icon>
            </b-button>
          </router-link>
        </span>
      </template>
    </b-table>
    <b-pagination
      @change="handlePagination"
      v-model="filters.page"
      :total-rows="total"
      :per-page="filters.take"
      size="lg"
      class="pb-3"
      align="center"
    >
    </b-pagination>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { supportService } from "@/_services";
export default {
  name: "ListaPazienti",
  data() {
    return {
      patientList: null,
      filters: {
        page: 1,
        take: 10,
        cf: undefined,
        surname: undefined,
        number: undefined,
      },
      fields: [
        { key: "index", label: "" },
        {
          key: "fullname",
          label: "Nome e Cognome",
          class: "text-uppercase",
        },
        {
          key: "anagrafica.cf",
          label: "Codice Fiscale",
          class: "text-uppercase",
        },
        {
          key: "anagrafica.birthday",
          label: "Data Nascita",
          class: "text-justify text-uppercase",
          formatter(value, key, item) {
            if (!value) {
              return "";
            }
            return moment(value).format("DD-MM-YYYY");
          },
        },
        {
          key: "anagrafica.tel_number",
          label: "Telefono",
          class: "text-justify text-uppercase",
        },
        {
          key: "email",
          label: "Email",
          class: "text-justify text-uppercase",
        },
        { key: "azioni", label: "" },
      ],
      total: 0,
      fromPage: 0,
      toPage: 0,
      busyTable: false,
    };
  },
  watch: {
    filters: {
      handler(value) {
        if (value.cf === "") {
          this.filters.cf = undefined;
          this.fetchData();
        }
        if (value.surname === "") {
          this.filters.surname = undefined;
          this.fetchData();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("company", ["companyLoggedIn", "company"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    goToScheda(hashid) {
      //console.log(hashid);
      this.$router.push({ name: "scheda Paziente", params: { hash: hashid } });
    },
    fetchData() {
      this.busyTable = true;
      var self = this;
      supportService
        .getPatientsList(this.filters)
        .then(function (res) {
          if (Array.isArray(res.data.data.data)) {
            self.patientList = res.data.data.data;
          } else {
            self.patientList = Object.values(res.data.data.data);
          }
          self.filters.page = res.data.data.current_page;
          self.total = res.data.data.total;
          self.fromPage = res.data.data.from;
          self.toPage = res.data.data.to;
          self.busyTable = false;
          // self.setCounterTimer();
        })
        .catch(function (err) {
          self.errorAlert("Non è stato possibile prelevare la lista pazienti");
          console.log(err);
          self.busyTable = false;
        });
    },
    handlePagination(page) {
      this.filters.page = page;
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
