<template>
  <div class="my-3 mr-md-3 puntofarmaCardNew">
    <!-- Paragrafo iniziale, con immagine e testo servizio -->
    <p :class="headerParagraphStyles" id="header-paragraph">
      <img
        :src="require(`@/assets/dashboard-operatore/${cardData.icon}.svg`)"
        :alt="`Icona ${cardData.title}`"
        id="header-icon"
      />
      <span class="px-4 px-md-3" id="header-text">{{ title }}</span>
    </p>
    <!-- Valore principale -->
    <h1 id="dashboard-card-main-value" class="my-0 mb-3 my-md-4 my-lg-5">
      {{ cardData.stats.dagestire }}
    </h1>
    <!-- Paragrafi con valori -->
    <section
      id="values-card"
      :class="`bg-${SERVIZI_VARIANTS[cardData.service]}-accent`"
      class="general_border_17 py-0 py-md-1"
    >
      <p :class="paragraphClasses" @click="navigateToCalendar('in attesa')">
        <span>In Attesa</span><span>{{ cardData.stats.inattesa }}</span>
      </p>
      <p :class="paragraphClasses" @click="navigateToCalendar('confermate')">
        <span>{{ confermatiText }}</span
        ><span>{{ cardData.stats.confermate }}</span>
      </p>
      <p :class="paragraphClasses" @click="navigateToCalendar('annullate')">
        <span>{{ annullatiText }}</span
        ><span>{{ cardData.stats.annullati }}</span>
      </p>
      <p :class="paragraphClasses" @click="navigateToCalendar('evase')">
        <span>{{ evasiText }}</span
        ><span>{{ cardData.stats.evasi }}</span>
      </p>
    </section>
  </div>
</template>

<script>
import { SERVIZI_VARIANTS } from "@/_utils/constants.js"
export default {
  name: "OperatoreDashboardCardComponent",
  props: {
    cardData: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data() {
    return {
      SERVIZI_VARIANTS: SERVIZI_VARIANTS,
      pClasses:
        "values-card-paragraph py-1 px-4 px-md-3 px-lg-4 general_border",
      headerParagraphStyles:
        "d-flex align-items-center justify-content-md-center justify-content-lg-start my-0 pt-4 pb-2 px-4 px-md-3 px-lg-4 general_border"
    }
  },
  computed: {
    title: function () {
      return (
        this.cardData.service.charAt(0).toUpperCase() +
        this.cardData.service.slice(1)
      )
    },
    confermatiText: function () {
      return this.cardData.service === "prestazioni"
        ? "Confermate"
        : "Confermati"
    },
    evasiText: function () {
      return this.cardData.service === "prestazioni" ? "Evase" : "Evasi"
    },
    annullatiText: function () {
      return this.cardData.service === "prestazioni" ? "Annullate" : "Annullati"
    },
    paragraphClasses: function () {
      return `${this.pClasses} hover-values-card-${this.cardData.service}-paragraph`
    }
  },
  methods: {
    getSingularServiceName(service) {
      const services = {
        prodotti: "prodotto",
        prestazioni: "prestazione",
        teleconsulti: "teleconsulto"
      }
      return services[service]
    },
    navigateToCalendar: function (status) {
      const navInfo = {
        service: this.getSingularServiceName(this.cardData.service),
        status: status
      }
      this.$emit("navigate", navInfo)
      console.log("Navigating to calendar", navInfo)
    }
  }
}
</script>
<style lang="scss" scoped>
#dashboard-card-main-value {
  font-size: 3rem;
  font-weight: 600;
}

#values-card {
  color: white;
  letter-spacing: 0.5px;
  font-weight: 600;
}

.values-card-paragraph {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
  padding: 0.2rem 0;
}

.values-card-paragraph:hover {
  cursor: pointer;
  transition: all 0.35s ease-in-out;
}

.hover-values-card-prodotti-paragraph:hover {
  background-color: rgba($orange-light, 0.3);
}

.hover-values-card-prestazioni-paragraph:hover {
  background-color: rgba($green-light, 0.3);
}

.hover-values-card-teleconsulti-paragraph:hover {
  background-color: rgba($purple-light, 0.25);
}

#header-text {
  font-size: 2rem;
}

@media (max-width: 420px) {
  #header-icon {
    width: 2.2rem;
    height: 2.2rem;
  }

  #header-text {
    font-size: 1.3rem;
  }

  .values-card-paragraph {
    font-size: 0.8rem;
  }

  #dashboard-card-main-value {
    font-size: 2.5rem;
    margin: 2rem !important;
  }
}

@media (min-width: 421px) and (max-width: 768px) {
  #header-icon {
    width: 2.5rem;
    height: 2.5rem;
  }

  #header-text {
    font-size: 1.5rem;
  }

  .values-card-paragraph {
    font-size: 0.9rem;
  }

  #dashboard-card-main-value {
    font-size: 2.8rem;
    margin: 2rem !important;
  }
}


@media (min-width: 768px) and (max-width: 979px) {
  #header-icon {
    width: 2.2rem;
    height: 2.2rem;
  }

  #header-text {
    font-size: 1rem;
  }

  .values-card-paragraph {
    font-size: 0.8rem;
  }

  #dashboard-card-main-value {
    font-size: 2rem;
  }
}

@media (min-width: 768px) and (max-width: 850px) {
  #header-icon {
    width: 1.8rem;
    height: 1.8rem;
  }
}

@media (min-width: 980px) and (max-width: 1099px) {
  #header-icon {
    width: 2.4rem;
    height: 2.4rem;
  }

  #header-text {
    font-size: 1.3rem;
  }
}

@media (min-width: 1100px) and (max-width: 1180px) {
  #header-icon {
    width: 2.5rem;
    height: 2.5rem;
  }

  #header-text {
    font-size: 1.4rem;
  }
}

@media (min-width: 1181px) and (max-width: 1259px) {
  #header-icon {
    width: 2.8rem;
    height: 2.8rem;
  }

  #header-text {
    font-size: 1.8rem;
  }
}

@media (min-width: 1260px) {
  #header-icon {
    width: 3rem;
    height: 3rem;
  }
}
</style>
