<template>
  <div class="py-3">
    <div v-if="template" class="">
      <!-- <p class="text-justify text-medium-grey ">
        {{ template.template.domande.length }} quesiti.
      </p> -->
      <!-- <b-form @submit="sendForm"></b-form> -->
      <b-form @submit.prevent="sendForm">
        <b-row cols="1" class="align-items-end">
          <transition-group name="list" mode="out-in" appear>
            <b-row
              cols="1"
              cols-md="2"
              class="mb-3"
              v-for="(domanda, ind) in template.template.domande"
              :key="ind"
            >
              <b-col md="10" lg="6" xl="5" cols="12" class="text-justify">
                <h6 :for="'q_' + ind">
                  <span class="text-secondary"> {{ ind + 1 }}.</span>
                  {{ domanda.q }}
                  <span v-if="domanda.h" class="mb-0 text-secondary">
                    - domanda per l'operatore</span
                  >
                </h6>
              </b-col>
              <b-col md="8" lg="6" xl="7" cols="12" class="text-justify">
                <template v-if="domanda.t">
                  <!-- questionario con definizione di tipo input -->
                  <b-form-group :key="'fgr_' + ind">
                    <template v-if="domanda.t === 'text'">
                      <b-form-input
                        class="align-self-center"
                        placeholder="..."
                        type="text"
                        :name="'q_' + ind"
                        v-model="domanda.a"
                      ></b-form-input>
                    </template>
                    <template v-else-if="domanda.t === 'select'">
                      <b-form-select
                        class="px-3 align-self-center"
                        v-model="domanda.a"
                        :name="'q_' + ind"
                        required
                      >
                        <b-form-select-option
                          v-for="opt in domanda.o"
                          :key="opt"
                          :value="opt"
                          >{{ opt }}
                        </b-form-select-option>
                        <!-- <b-form-select-option :value="null" disabled>seleziona</b-form-select-option> -->
                      </b-form-select>
                    </template>
                    <template v-else-if="domanda.t === 'select-multi'">
                      <b-form-tags
                        :name="'q_' + ind"
                        v-model="domanda.a"
                        size="lg"
                        class="mb-2"
                        add-on-change
                        no-outer-focus
                        @focus="hasFocus = ind"
                        @focusin="hasFocus = ind"
                      >
                        <template
                          v-slot="{
                            tags,
                            inputAttrs,
                            inputHandlers,
                            disabled,
                            removeTag,
                            addTag
                          }"
                        >
                          <b-form-select
                            v-bind="inputAttrs"
                            v-on="inputHandlers"
                            :disabled="disabled || domanda.o.length === 0"
                            :options="domanda.o"
                          >
                            <template #first>
                              <!-- This is required to prevent bugs with Safari -->
                              <option disabled value="">
                                scegli una o più opzioni...
                              </option>
                            </template>
                          </b-form-select>
                          <ul
                            v-if="tags.length > 0"
                            class="list-inline d-inline-block mt-2"
                          >
                            <li
                              v-for="tag in tags"
                              :key="tag"
                              class="list-inline-item"
                            >
                              <b-form-tag
                                @remove="removeTag(tag)"
                                :title="tag"
                                :disabled="disabled"
                                variant="secondary"
                                >{{ tag }}</b-form-tag
                              >
                            </li>
                          </ul>
                        </template>
                      </b-form-tags>

                      <template
                        v-if="
                          Array.isArray(domanda.a) &&
                          domanda.a.includes('altro')
                        "
                      >
                        <b-input-group v-if="hasFocus === ind">
                          <b-form-input
                            class="align-self-center input_with_append"
                            placeholder="altra opzione personalizzata"
                            type="text"
                            :name="'qti_' + ind"
                            v-model="tempTag"
                          ></b-form-input>
                          <b-input-group-append>
                            <b-button @click="addCustom(ind)"
                              >aggiungi altro</b-button
                            >
                          </b-input-group-append>
                        </b-input-group>
                      </template>
                    </template>
                    <template v-else-if="domanda.t === 'date'">
                      <b-form-input
                        class="align-self-center"
                        placeholder="GG/MM/AAAA"
                        type="date"
                        :name="'q_' + ind"
                        v-model="domanda.a"
                      ></b-form-input>
                    </template>
                  </b-form-group>

                  <!-- questionario con definizione di tipo input -->
                </template>
                <template v-else>
                  <b-form-group :key="'fgr_' + ind">
                    <div
                      v-if="domanda.o && domanda.o === 'file'"
                      class="align-self-center"
                    >
                      <SurveyUploader
                        v-on:upload-result="handleUpload($event, ind)"
                        v-on:remove-result="removeUpload(ind)"
                        :accepts="domanda.fa"
                        :isMulti="domanda.fm"
                      />
                    </div>
                    <b-form-checkbox-group
                      v-else-if="domanda.cb && domanda.o"
                      :name="'q_' + ind"
                      v-model="domanda.a"
                      :options="domanda.o"
                      name="flavour-1"
                    ></b-form-checkbox-group>

                    <b-form-select
                      class="px-3 align-self-center"
                      v-else-if="domanda.o"
                      v-model="domanda.a"
                      :name="'q_' + ind"
                      required
                    >
                      <b-form-select-option
                        v-for="opt in domanda.o"
                        :key="opt"
                        :value="opt"
                        >{{ opt }}
                      </b-form-select-option>
                      <!-- <b-form-select-option :value="null" disabled>seleziona</b-form-select-option> -->
                    </b-form-select>
                    <b-form-input
                      v-else
                      class="align-self-center"
                      placeholder="..."
                      type="text"
                      :name="'q_' + ind"
                      v-model="domanda.a"
                      required
                    ></b-form-input>
                  </b-form-group>
                </template>
              </b-col>
            </b-row>
            <b-col class="my-4 py-2" key="btsubmt">
              <b-button type="submit" variant="secondary fw-bolder spacing_1">
                Invia</b-button
              >
            </b-col>
          </transition-group>
        </b-row>
      </b-form>
    </div>
  </div>
</template>
<script>
import SurveyUploader from "@/components/utilities/SurveyUploader.vue"
export default {
  name: "CompilaQuestionario",
  props: ["survey"],
  components: { SurveyUploader },
  data() {
    return {
      template: null,
      consent: false,
      proceed: false,
      file: null,
      tempTag: null,
      hasFocus: null
    }
  },
  methods: {
    onFileChange(e) {
      this.file = e.target.files[0]
    },
    sendForm() {
      this.$emit("filled-form", this.template)
    },
    handleUpload(data, ind) {
      if (this.template.template.domande[ind].a == null) {
        this.template.template.domande[ind].a = []
      }
      this.template.template.domande[ind].a.push(data.hashid)
      if (Array.isArray(data)) {
        this.template.template.domande[ind].a = data.map((el) => el.hashid)
      }
    },
    removeUpload(ind) {
      this.template.template.domande[ind].a = null
    },
    addCustom(ind) {
      this.template.template.domande[ind].a.push(this.tempTag)
      this.tempTag = null
    }
  },
  created() {
    if (this.survey) {
      this.template = this.survey
      try {
        var parsed = JSON.parse(this.survey.template)
        this.template.template = parsed
      } catch {
        //
      }
    }
  }
}
</script>
