<template>
  <div class="home">
    <b-alert
      variant="danger"
      :show="showVerificationAlert"
      class="col-lg-8 col-sm-12 mr-0 ml-auto point"
    >
      <span id="ver__al"
        >Non hai ancora verificato il tuo indirizzo email

        <b-icon icon="info-circle" variant="info"></b-icon>
      </span>
      <b-tooltip target="ver__al" class="bg-info"
        >Per potere utilizzare la piattaforma è necessario verificare
        l'indirizzo email</b-tooltip
      >
    </b-alert>
    <b-alert
      variant="danger"
      :show="showOtpVerificationAlert"
      class="col-lg-8 col-sm-12 mr-0 ml-auto point"
    >
      <span id="ver__al"
        >Non hai ancora verificato il tuo numero di telefono

        <b-icon icon="info-circle" variant="info"></b-icon>
      </span>
      <b-tooltip target="ver__al" class="bg-info"
        >Per potere utilizzare la piattaforma è necessario verificare
        il numero di telefono</b-tooltip
      >
    </b-alert>
    
    <b-alert
      variant="danger"
      :show="showAnagraficaAlert"
      class="col-lg-8 col-sm-12 mr-0 ml-auto point"
    >
      <span id="angr__al"
        >Non hai ancora compilato la tua anagrafica

        <b-icon icon="info-circle" variant="info"></b-icon>
      </span>
      <b-tooltip target="angr__al" class="bg-info"
        >Per usufruire a pieno di tutti i servizi messi a disposizione da questa
        piattaforma è necessario compilare la propria scheda
        anagrafica</b-tooltip
      >
    </b-alert>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Home",
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
  data() {
    return {
      showVerificationAlert: false,
      showAnagraficaAlert: false,
      showOtpVerificationAlert: true
    };
  },
  mounted() {
    // if (this.$route.name === "Home") {
    this.checks();
    // }
  },
  methods: {
    ...mapActions("utente", ["unMe", "me"]),
    checks() {
      if (!this.user) {
        return this.unMe();
      }
      if (!this.user.number_verified_at) {
        this.showOtpVerificationAlert = true;
        this.$router.push({
          name: "NumberVerification",
        });
        return;
      }
      if (this.user.email && !this.user.email_verified_at) {
        this.showVerificationAlert = true;
        this.$router.push({
          name: "EmailVerification",
          params: { status: "invalid" },
        });
      }
      if (!this.user.anagrafica && this.user.type !== 2) {
        this.showAnagraficaAlert = true;
        return;
      }
      if (this.user.i_am !== 'admin' && this.user.temporary_password) {
        if (this.user.i_am === 'operatore') {
          this.$router.push("/operatore/profilo?scope=temporary");
          return;
        }
        this.$router.push("/" + this.user.i_am + "/impostazioni?scope=temporary");
      } else if (this.user.expired_password == 1) {
        var routeName = "Impostazioni";
        if (this.user.i_am === 'operatore') {
          this.$router.push("/operatore/profilo?scope=expired");
          return;
        }
        this.$router.push("/" + this.user.i_am + "/impostazioni?scope=expired");
      }
      if (this.$route.name === "Home") {
        this.$router.push("/" + this.user.i_am + "/home");
      }
    },
  },
};
</script>
