<template>
  <div id="per-conto-di-header" class="m-0 p-3 detail-modal-card text-left">
    <b-row>
      <b-col class="d-flex justify-content-center" id="per-conto-di-first-col">
        <span class="d-flex justify-content-center">
          <b-avatar
            :src="info.subjectable.profile_pic"
            size="66px"
            variant="white"
            class="general_border"
            rounded="lg"
          ></b-avatar>
        </span>
      </b-col>
      <b-col class="flex-grow-1 w-100 pl-3">
        <b-row cols="1">
          <b-col
            class="co-12 d-flex flex-column flex-nowrap justify-content-start px-0"
          >
            <p class="mb-0 font-weight-bolder detail-modal-card-titles">
              NOME E COGNOME
            </p>
            <p class="anagraphic-data-text text-capitalize">
              <span v-if="info.subjectable.anagrafica"
                >{{ info.subjectable.anagrafica.name }}
                {{ info.subjectable.anagrafica.surname }}
              </span>
              <span v-else
                >{{ info.subjectable.name }} {{ info.subjectable.surname }},
              </span>
            </p>
          </b-col>
          <b-col
            class="col-12 d-flex flex-column flex-nowrap justify-content-start px-0"
          >
            <p class="mb-0 font-weight-bolder detail-modal-card-titles">
              CODICE FISCALE
            </p>
            <p class="anagraphic-data-text text-uppercase">
              {{
                info.subjectable.anagrafica
                  ? info.subjectable.anagrafica.cf
                  : info.subjectable.cf
              }}
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "PerContoDiInfoCardComponent",
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {}
}
</script>
<style scoped>
#per-conto-di-first-col {
  max-width: 80px;
}
</style>
