<template>
  <section class="px-4" v-if="services[8]">
    <b-row align-v="center" class="justify-content-between">
      <b-col class="px-0">
        <h2 class="py-4 text-left">
          Centro Monitoraggio Clinico
        </h2>
      </b-col>
      <b-col class="pl-0">
        <div class="d-flex justify-content-end align-items-center flex-wrap">
          <!-- <h4 class="mr-4" id="current-hour">{{ currentHour }}</h4> -->
          <b-form-datepicker
            label-help="Utilizza i cursori per navigare tra le date"
            start-weekday="1"
            id="dashboard-croom-datepicker"
            v-model="currentDate"
            class="mb-2 col-4 col-md-6 col-lg-5"
            :date-format-options="dateFormat"
            locale="it"
          ></b-form-datepicker>
          <span class="ml-2">
            <b-icon
              id="dashboard-explain"
              icon="info-circle"
              variant="info-circle-dashboard"
              font-scale="2"
            ></b-icon>
          </span>

          <b-tooltip
            :placement="$root.isMobile ? 'bottom' : 'rightbottom'"
            variant="info-tooltip"
            target="dashboard-explain"
            :triggers="$root.isMobile ? 'click' : 'hover'"
          >
            <template>
              <p class="text-justify">
               Seleziona una data per visualizzare Alert, Appuntamenti e Liste Pazienti del giorno
              </p>
            </template>
          </b-tooltip>
        </div>
      </b-col>
    </b-row>
    <b-row align-v="stretch" cols="1" cols-lg="2" cols-xl="3">
      <b-col class="px-1 my-2 order-1 order-lg-1 order-xl-1">
        <AlertList class="h-100" :seldate="currentDate"></AlertList>
      </b-col>
      <template  v-if="company.level === 5">
      <b-col class="px-1 my-2 px-xl-4 order-2 order-lg-3  order-xl-2">
        <ProssimiImpegni class="h-100"
            @navigate="navigateToCalendar($event)"
            :seldate="currentDate"
          />
      </b-col>
      <b-col class="px-1 my-2  order-3 order-lg-2 order-xl-3">
        <PatientList class="h-100" :seldate="currentDate"></PatientList>
        <b-row class="mt-3 justify-content-between text-left px-2" >
            <span class="col-6 my-1">
                <b-icon variant="dark-grey" font-scale="1.7" icon="journal-medical"></b-icon> <span>Scheda Paziente</span>
            </span>
            <span class="col-6 my-1">
                <b-icon variant="dark-grey" font-scale="1.7" icon="check-circle-fill"></b-icon> <span>Attività da effettuare</span>
            </span>
            <span class="col-6 my-1">
                <b-icon variant="dark-grey" font-scale="1.7" icon="eye"></b-icon> <span>Attività da visualizzare</span>
            </span>
            <span class="col-6 my-1">
                <b-icon variant="secondary" font-scale="1.7" icon="check-circle-fill"></b-icon> <span>Attività effettuata</span>
            </span>
            <span class="col-6 my-1">
                <b-icon variant="dark-grey" font-scale="1.7" icon="eye-slash"></b-icon> <span>Attività visualizzata</span>
            </span>
        </b-row>
      </b-col>
    </template>
    </b-row>
  </section>
  <h5 v-else-if="services[8] === null"> <b-spinner></b-spinner> </h5>
    <div v-else>
      <h4>Servizio non abilitato</h4>
      <h6>
        <a href="https://puntofarma.online/contacts/"> <u> Contattaci</u></a>
        per saperne di più o per abilitare il servizio
      </h6>
    </div>
</template>
<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { companyService } from "@/_services";
import PatientList from "@/components/croom/PatientList.vue"
import ProssimiImpegni from "@/components/croom/ProssimiImpegni.vue"
import AlertList from "@/components/croom/AlertList.vue"

export default {
  name: "CRoomHomeDashboard",
  components: {
    PatientList,
    ProssimiImpegni,
    AlertList
  },
  data() {
    return {
      innerWidth: window.innerWidth,
      // currentHour: undefined,
      services: {
        // 1: false,
        // 2: false,
        8: null
      },
      currentDate: null
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["user"]),
    ...mapState("company", ["company"]),
    dateFormat() {
      if (this.innerWidth < 500)
        return {
          day: "numeric",
          month: "short",
          year: "numeric",
        };
      else if (this.innerWidth < 650)
        return {
          weekday: "long",
          day: "numeric",
          month: "long",
          year: "numeric",
        };
      else
        return {
          weekday: "long",
          day: "numeric",
          month: "long",
          year: "numeric",
        };
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    innerWidthChanged() {
      // console.log("resize handler");
      this.innerWidth = window.innerWidth;
    },
    navigateToCalendar(navigationInfo) {
      const reservationType = navigationInfo.service
      const reservationStatus = navigationInfo.status
      const servicerif = reservationType === "teleconsulto" ? 2 : 1
      const search = navigationInfo.search ? navigationInfo.search : null
      let routeName = servicerif == 1 ? "prenotazioni" : "teleconsulto"
      //to do: handle rif 5 per gli appuntamenti se il level è>1
      this.$router.push({
        name: routeName,
        params: {
          section: "prenotazioni"
        },
        query: {
          startWithCalendar: "yes",
          startWithDate: this.currentDate,
          service: reservationType,
          status: reservationStatus,
          servicerif: servicerif,
          search: search
        }
      })
    },
    verifyService(ref) {
      const self = this;
      const filter = { ref: ref };
      companyService
        .checkIfServiceIsActive(filter)
        .then(function (response) {
          self.services[ref] = response.data.data.active;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  created() {
    this.verifyService(8)
    this.currentDate = moment().format("YYYY-MM-DD");
    window.addEventListener("resize", this.innerWidthChanged);
  },
  destroyed() {
    window.removeEventListener("resize", this.innerWidthChanged);
  },
  mounted() {
    // this.verifyService(1);
    // this.setCurrentHour();
    // this.currentHourTimer = setInterval(() => {
    //   this.setCurrentHour();
    // }, 10000);
  },
  beforeDestroy() {
    // clearInterval(this.currentHourTimer);
    // this.currentHourTimer = null;
  },
};
</script>
<style>
.boxes_max_heigh_60 {
  max-height: 75vh;
}

.boxes_max_heigh_49 {
  max-height: 49vh;
}

.hide_overflow_y {
  overflow-y: scroll;
}

#dashboard-croom-datepicker__value_ {
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-weight: 600;
  color: black;
  font-size: 20px;
}

button#dashboard-croom-datepicker {
  padding: 0 !important;
}

div#dashboard-croom-datepicker__outer_ {
  flex-basis: initial;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px #00000022;
  border-radius: 8px;
  border-radius: 0.15rem;
  opacity: 1;
  transition: all 200ms ease-in;
  border: 0;
}

.form-control#dashboard-croom-datepicker__outer_ {
  width: 300px;
}

@media (min-width: 1500px) {}

@media (max-width: 500px) {

  #current-hour {
    font-size: 1.6rem;
  }

  #dashboard-croom-datepicker__value_ {
    font-size: 0.8rem;
  }

  .form-control#dashboard-croom-datepicker__outer_ {
    min-width: 110px;
  }
}

@media (min-width: 501px) {
  #dashboard-croom-datepicker__value_ {
    font-size: 15px;
  }

  .form-control#dashboard-croom-datepicker__outer_ {
    min-width: 230px;
  }
}

@media (min-width: 600px) {
  #dashboard-croom-datepicker__value_ {
    font-size: 17px;
  }

  .form-control#dashboard-croom-datepicker__outer_ {
    min-width: 270px;
  }
}
</style>
