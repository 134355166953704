<template>
  <b-container fluid class="p-0" id="month-calendar-container">
    <b-row v-show="reservations" id="month-calendar-row">
      <b-col
        v-for="(dayRes, date, index) in reservations"
        :key="index"
        class="border border-light-grey bg-white px-2 pb-4"
        :class="hasBorder(index)"
        @click="$emit('go-to-day', date)"
      >
        <template v-if="dayIsInPickMonth(date)">
          <h5
            class="d-flex align-items-center justify-content-center pt-2 pb-2"
          >
            <span class="weekday-label">{{ getSingleDayWeekDay(date) }}</span>
            <span class="day-label"> {{ getSingleDayDay(date) }}</span>
          </h5>

          <p
            class="calendar-month-card my-3"
            v-if="Object.keys(dayRes).includes('teleconsulto')"
            :class="
              dayRes.teleconsulto.all_inactive
                ? 'disabled__card'
                : `bg-${SERVIZI_VARIANTS['teleconsulto']}-light`
            "
          >
            <span>
              <b-icon icon="camera-video" class="mr-2"></b-icon>
              <span class="font-weight-bolder"></span>
            </span>
            <span class="mr-2">
              {{ dayRes.teleconsulto.count }}
            </span>
          </p>
          <p
            class="calendar-month-card my-3"
            v-if="Object.keys(dayRes).includes('prodotto')"
            :class="
              dayRes.prodotto.all_inactive
                ? 'disabled__card'
                : `bg-${SERVIZI_VARIANTS['prodotto']}-light`
            "
          >
            <span>
              <b-icon icon="bag" class="mr-2"></b-icon>
              <span class="font-weight-bolder"></span>
            </span>
            <span class="mr-2">
              {{ dayRes.prodotto.count }}
            </span>
          </p>
          <template v-if="Object.keys(dayRes).includes('prestazione')">
            <p
              class="calendar-month-card my-3"
              :class="
                prestazioneInfo.all_inactive
                  ? 'disabled__card'
                  : `bg-${SERVIZI_VARIANTS['prestazione']}-light`
              "
              v-for="(prestazioneInfo, prestazioneName) in dayRes.prestazione"
              :key="prestazioneName"
            >
              <span>
                <b-icon :icon="prestazioneInfo.icona" class="mr-2"></b-icon>
                <span class="text-uppercase font-weight-bolder">
                  {{ prestazioneInfo.tag }}
                </span>
              </span>
              <span class="mr-2">
                {{ prestazioneInfo.count }}
              </span>
            </p>
          </template>
          <!-- <div v-for="(resValues, resType) in dayRes" :key="resType">
          <p v-if="resType === 'teleconsulto'">
            <b-icon icon="camera-video" font-scale="1" class="mr-2"></b-icon>
            {{ resValues.count }}
          </p>
          <p v-if="resType === 'prodotto'">
            <b-icon icon="cart" font-scale="1" class="mr-2"></b-icon>
            {{ resValues.count }}
          </p>
          <template v-if="resType">
          </template> -->
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import * as moment from "moment/moment"
import { SERVIZI_VARIANTS } from "@/_utils/constants.js"

export default {
  name: "CalendarMonthComponent",
  props: ["reservations", "pickedMonth"],
  data() {
    return {
      SERVIZI_VARIANTS: SERVIZI_VARIANTS
    }
  },
  computed: {
    reservationsLength() {
      return Object.keys(this.reservations).length
    }
  },
  methods: {
    getSingleDayDay(index) {
      return moment(index).format("D")
    },
    getSingleDayWeekDay(index) {
      return moment(index).format("ddd")
    },
    dayIsInPickMonth(index) {
      return Number(moment(index).format("M")) === Number(this.pickedMonth)
    },
    getImageName(tipo) {
      switch (tipo) {
        case "teleconsulto":
          return `dashboardTeleconsulto`
        case "prestazione":
          return `dashboardPrestazioni`
        case "prodotto":
          return `dashboardOrdini`
      }
    },
    hasBorder(index) {
      if (index === 0) {
        return `border-top-left`
      } else if (index === 6) {
        return `border-top-right`
      } else if (index === this.reservationsLength - 7) {
        return `border-bottom-left`
      } else if (index === this.reservationsLength - 1) {
        return `border-bottom-right`
      } else {
        return ``
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.col {
  flex-basis: 14.285%;
  transition: all 0.3s ease;
  cursor: pointer;
  transition: all 100ms ease-in;
}

.col:hover {
  background-color: $spl-blue !important;
}

.border-top-left {
  border-top-left-radius: 17px;
}

.border-top-right {
  border-top-right-radius: 17px;
}

.border-bottom-left {
  border-bottom-left-radius: 17px;
}

.border-bottom-right {
  border-bottom-right-radius: 17px;
}

.weekday-label {
  font-size: 0.8rem;
  font-weight: 580;
  margin-right: 0.5rem;
}

.day-label {
  font-size: 1.2rem;
}

#month-calendar-container {
  max-height: 55vh;
  overflow: auto;
  min-width: 100%;
  padding: 0;
}

@media (max-width: 600px) {
  #month-calendar-row {
    min-width: 570px;
    overflow-x: auto;
  }
}

::-webkit-scrollbar {
  width: 15px;
  border-radius: 11px;
}

::-webkit-scrollbar-thumb {
  border-radius: 11px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 11px;
  background-color: $secondary;
}

.calendar-month-card {
  background-color: #e7f3fd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 11px;
  padding: 0.5rem;
  margin: 0.5rem;
  font-size: 0.8rem;
  display: flex;
}
</style>
